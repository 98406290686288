import React, { Component } from 'react';
import {
    Row,
    Breadcrumb,
    Button,
    Form,
    message,
    Modal,
    Input,
    Select,
    Col,
    Radio,
    Popover,
    Table,
    TreeSelect,
    Alert,
    DatePicker
} from 'antd';
import { AreaSelect, popoverTable } from "../../common";
import Filter from "../../common/filter";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Axios } from '../../axios';
import filter from "../../common/filter";
import moment from 'moment'

const { Option } = Select;
let setPageData = {}, sendBody = {};

class CustomerQuery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tData: [],
            loading: false,
            totalNum: 0,
            currentPage: window.routerCache.CustomerQuery.currentPage || 1,
        }
        sendBody = window.routerCache.CustomerQuery.sendBody || {};
        setPageData = {};
    }

    getList = async (page) => {
        try {
            this.setState({
                loading: true,
            });

            let send = sendBody;
            sendBody.queryType = window.routerCache.CustomerQuery.workType_queryType || '';
            send.pageNum = page || this.state.currentPage + '';
            send.pageSize = '10';
            let res = await Axios('get', '/customer/page', send)
            if (res.code == '200') {
                this.setState({
                    loading: false,
                    tData: res.data.dataList,
                    totalNum: res.data.totalNum,
                    currentPage: send.pageNum,
                });
            } else {
                message.error(res.message)
                this.setState({ loading: false })
            }
        } catch (err) {
            // message.error(err);
            this.setState({ loading: false })
        }

    }

    handleTableChange = async (pagination) => {
        this.getList(pagination.current)
    }

    // 组件渲染后获取外界数据(GET)
    componentDidMount() {
        this.getList();
    }

    componentWillUnmount() {
        window.routerCache.CustomerQuery.currentPage = this.state.currentPage;
        window.routerCache.CustomerQuery.sendBody = sendBody;
    }

    render() {
        let columns = [{
            title: 'ID',
            dataIndex: 'id',
            fixed: 'left',
            width: 60
        }, popoverTable(13, {
            title: '客户名称',
            dataIndex: 'companyName',
            fixed: 'left',
        }), popoverTable(6, {
            title: '联系人',
            dataIndex: 'linkman',
        }), {
            title: '联系电话',
            dataIndex: 'mobileNo',
            width: 120
        }, popoverTable(8, {
            title: '所属地区',
            dataIndex: 'province',
            fn: (t, r) => `${t || ''} ${r.city || ''}`
        }), popoverTable(13, {
            title: '代理商',
            dataIndex: 'brokerName',
        }), popoverTable(5, {
            title: '业务员',
            dataIndex: 'userName',
        }), {
            title: '套餐',
            dataIndex: 'purchaseSpec',
            render: t => Filter.legalspecification(t),
            width: 100
        }, {
            title: '购买日期',
            dataIndex: 'legalStartTime',
            render: t => Filter.timetrans(t),
            width: 160
        }, {
            title: '会员到期时间',
            dataIndex: 'legalEndTime',
            render: t => Filter.timetrans(t),
            width: 160
        }, {
            title: '试用到期时间',
            dataIndex: 'expireTime',
            render: t => Filter.timetrans(t),
            width: 160
        }, {
            title: '状态',
            dataIndex: 'legalServiceStatus',
            render: t => t == '1' ? <span style={{ color: '#52c41a' }}>已购买</span> : t == '2' ? '未购买' : '',
            fixed: 'right',
            width: 60
        }, {
            title: '操作',
            dataIndex: 'operate',
            width: 60,
            fixed: 'right',
            render: (text, record, index) => <Link to={"/CustomerDetails/" + record.userId}>查看</Link>
        }];
        return (
            <div className="pageContainer">
                <Row className="headNav">
                    <Row>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>客户管理</Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>
                    <Row className='title'>
                        <span className='name'>客户管理</span>
                    </Row>
                </Row>
                <div className='scrollContainer'>
                    <div className="scrollMain">
                        <Search getList={this.getList} />
                        <div style={{ backgroundColor: "#fff" }}>
                            <Table
                                dataSource={this.state.tData}
                                columns={columns}
                                size="middle"
                                scroll={{ x: 'max-content' }}
                                pagination={{
                                    size: 'large',
                                    showQuickJumper: true,
                                    pageSize: 10,
                                    total: parseInt(this.state.totalNum),
                                    current: Number(this.state.currentPage),
                                    showTotal: (total) => {
                                        return '共 ' + total + ' 条记录 '
                                    }
                                }}
                                loading={this.state.loading}
                                onChange={this.handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return state;
},
    (dispatch) => ({})
)(CustomerQuery);


class SearchM extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            province: sendBody.province || "",
            city: sendBody.city || "",
            qingkong: false,
            workTypeStr: window.routerCache.CustomerQuery.workTypeStr || ''
        }
    }

    // 查询
    query = async () => {
        sendBody = this.props.form.getFieldsValue();
        sendBody.province = this.state.province;
        sendBody.city = this.state.city;
        this.props.getList(1)
    }
    clearOptions = async () => {
        this.props.form.resetFields();
        // 清空的时候清空地区
        this.setState({
            province: '',
            city: '',
            qingkong: true
        }, () => {
            this.setState({
                qingkong: false
            })
        })
        sendBody = {};
        this.props.getList(1);
    }
    clear = async () => {
        window.routerCache.CustomerQuery.workTypeStr = '';
        window.routerCache.CustomerQuery.workType_queryType = '';
        this.setState({
            workTypeStr: ''
        })
        this.props.getList(1);
    }


    // 组件渲染后获取外界数据(GET)
    componentDidMount() {

    }

    render() {
        /*控制查询按钮状态*/
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="search_like_antD" style={{ marginTop: "-8px" }}>
                <Form layout='inline' labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                    <Row type="flex" justify="space-between" align="middle" style={{ padding: "8px 0" }}>
                        <Col span={8}>
                            <Form.Item label="客户名称：" style={{ width: "100%" }}>
                                {getFieldDecorator('companyName', {
                                    initialValue: sendBody.companyName
                                })(
                                    <Input placeholder='请输入客户名称' type="text" style={{ width: "90%" }} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="手机号" style={{ width: "100%" }}>
                                {getFieldDecorator('mobileNo', {
                                    initialValue: sendBody.mobileNo
                                })(
                                    <Input placeholder='请输入手机号' type="text" style={{ width: "90%" }} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="所属地区：" style={{ width: "100%" }}>
                                <AreaSelect province={this.state.province} city={this.state.city} hiddenCountry
                                    bodyStyle={{ width: "90%" }}
                                    allowClear qingkong={this.state.qingkong}
                                    select={(province, city, county) => {
                                        console.log(province, city, county)
                                        sendBody.province = province;
                                        sendBody.city = city;
                                        this.setState({
                                            province: province || '',
                                            city: city || '',
                                        })
                                    }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row type="flex" justify="space-between" align="middle" style={{ padding: "0px 0" }}>
                        <Col span={8}>
                            <Form.Item label={<span>代理商</span>} style={{ width: "100%" }}>
                                {getFieldDecorator('brokerName', {
                                    initialValue: sendBody.brokerName
                                })(
                                    <Input placeholder='请输入' type="text" style={{ width: "90%" }} />
                                )}
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item label="业务员：" style={{ width: "100%" }}>
                                {getFieldDecorator('userName', {
                                    initialValue: sendBody.userName
                                })(
                                    <Input placeholder='请输入' type="text" style={{ width: "90%" }} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="状态：" style={{ width: "100%" }}>
                                {getFieldDecorator('legalServiceStatus', {
                                    initialValue: sendBody.legalServiceStatus
                                })(
                                    <Select placeholder='请选择' style={{ width: '90%' }} allowClear>
                                        <Option key="1">已购买</Option>
                                        <Option key="2">未购买</Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row type="flex" justify="space-between" align="middle" style={{ padding: "0px 0" }}>
                        <Col span={8}></Col>
                        <Col span={8}></Col>
                        <Col span={8}>
                            <Row type="flex" justify="end" align="middle" style={{ width: '95%' }}>
                                <div>
                                    <Form.Item><Button type="primary" onClick={this.query}>查询</Button></Form.Item>
                                    <Form.Item><Button onClick={this.clearOptions}>重置</Button></Form.Item>
                                </div>
                            </Row>

                        </Col>
                    </Row>
                    <div>
                        <Row type="flex" justify="space-between" align="middle"
                            style={this.state.workTypeStr ? {
                                paddingBottom: "10px"
                            } : { paddingBottom: "40px" }}>
                            {this.state.workTypeStr ? <Alert message={<div>
                                <span>{this.state.workTypeStr}</span>&nbsp;&nbsp;<a
                                    onClick={this.clear}>清空</a>&nbsp;&nbsp;
                            </div>} type="info" showIcon style={{ marginTop: '12px' }} /> : ''}
                        </Row>
                    </div>
                </Form>
                <style>{`
               .search_like_antD .ant-form ant-form-inline .ant-row-flex .ant-row-flex-space-between .ant-row-flex-middle{
                    padding: 0px !important;
                }
                `}</style>
            </div>


        )
    }

}

let Search = connect((state) => {
    return state;
},
    (dispatch) => ({})
)(SearchM)
