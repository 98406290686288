import React, { Component } from "react";
import {
    Row,
    Breadcrumb,
    Button,
    Form,
    message,
    DatePicker,
    Modal,
    Input,
    Select,
    Col,
    Radio,
    Alert,
    Table,
    TreeSelect,
} from "antd";
import { AreaSelect, popoverTable } from "../../common";
import Filter from "../../common/filter";
import { connect } from "react-redux";
import { specialProvinceList } from "../../common/cityListBigData";
import { change_operInfo } from "../../redux/actions/operInfo";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Axios } from "../../axios";
import moment from "moment";

const { Option } = Select;
const RangePicker = DatePicker.RangePicker;
let sendBody = {},
    Timer;

let StatuspageNum = 1;
class SubmitCustomerManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tData: [],
            loading: false,
            totalNum: 0,
            currentPage:
                window.routerCache.SubmitCustomerManage.currentPage || 1,
        };
        sendBody = window.routerCache.SubmitCustomerManage.sendBody || {};
    }

    // 获取表格数据
    refreshTableByData = (data, totalNum, currentPage) => {
        this.setState({
            tData: data,
            totalNum: totalNum,
            currentPage: currentPage,
        });
    };
    // 获取表格数据
    refreshTable = async () => {
        try {
            this.setState({
                loading: true,
            });
            let send = sendBody;
            send.workType =
                window.routerCache.SubmitCustomerManage.workTypeStr || "";
            send.pageNum = this.state.currentPage + "";
            send.pageSize = "10";
            let res = await Axios("get", "/legalRenew/list", send);
            console.log(res.body, "resresresresres");
            this.setState({
                loading: false,
                tData: res.body,
                totalNum: res.totalNum,
            });
        } catch (err) {
            // message.error(err);
            this.setState({ loading: false });
        }
    };

    handleTableChange = async (pagination) => {
        this.setState({ loading: true });
        try {
            let send = sendBody;
            send.pageNum = pagination.current + "";
            send.pageSize = "10";
            let res = await Axios("get", "/legalRenew/list", send);
            this.setState({
                loading: false,
                tData: res.body,
                totalNum: res.totalNum,
                currentPage: pagination.current,
            });
        } catch (err) {
            // message.error(err.message);
            this.setState({ loading: false });
        }
    };

    // 组件渲染后获取外界数据(GET)
    componentDidMount() {
        this.refreshTable();
        window.winhcFn.changeDept = () => {
            this.setState(
                {
                    currentPage: 1,
                },
                () => {
                    this.refreshTable();
                }
            );
        };
    }

    componentWillUnmount() {
        window.routerCache.SubmitCustomerManage.currentPage =
            this.state.currentPage;
        window.routerCache.SubmitCustomerManage.sendBody = sendBody;
    }
    render() {
        const { refreshTable } = this;
        let columns = [
            {
                title: "ID",
                dataIndex: "id",
                fixed: "left",
                width: 50,
            },
            popoverTable(10, {
                title: "企业名称",
                dataIndex: "companyName",
                width: 200,
                fixed: "left",
            }),
            {
                title: "联系人",
                dataIndex: "purchaseSpec",
                width: 100,
            },
            {
                title: "手机号",
                dataIndex: "expiresTime",
                width: 120,
            },
            {
                title: "企业经营地",
                dataIndex: "followUserName",
                width: 120,
                render: (text, record) => Filter.timetrans(text, "day"),
            },
            {
                title: "报备状态",
                dataIndex: "renewIdea",
                width: 60,
                render: (text, record) => Filter.timetrans(text, "day"),
            },
            {
                title: "添加时间",
                dataIndex: "followDate",
                width: 120,
                render: (text, record) => Filter.timetrans(text, "day"),
            },
            {
                title: "操作",
                dataIndex: "operate",
                width: 100,
                fixed: "right",
                render: (text, record) =>
                    0 ? (
                        <span>
                            <span>同意</span> | <span>拒绝</span>
                        </span>
                    ) : (
                        <span>报备成功</span>
                    ),
            },
        ];
        return (
            <div className="pageContainer">
                <Row className="headNav">
                    <Row>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>客户管理</Breadcrumb.Item>
                            <Breadcrumb.Item>报备客户审核</Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>
                    <Row className="title">
                        <span className="name">报备客户审核</span>
                        <AddBroker
                            record={{}}
                            refreshTable={this.refreshTable}
                        />
                    </Row>
                </Row>
                <div className="scrollContainer">
                    <div className="scrollMain">
                        <Search refreshTableByData={this.refreshTableByData} />
                        <div style={{ backgroundColor: "#fff" }}>
                            <Row
                                type="flex"
                                justify="space-between"
                                align="middle"
                            ></Row>
                            <Table
                                scroll={{ x: 1200 }}
                                dataSource={this.state.tData}
                                columns={columns}
                                size="middle"
                                pagination={{
                                    size: "large",
                                    showQuickJumper: true,
                                    pageSize: 10,
                                    total: parseInt(this.state.totalNum),
                                    current: Number(this.state.currentPage),
                                }}
                                loading={this.state.loading}
                                onChange={this.handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return state;
    },
    (dispatch) => ({})
)(SubmitCustomerManage);

class SearchM extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            province: "",
            city: "",
            qingkong: false,
        };
    }

    // 查询
    query = async () => {
        let _this = this;
        sendBody = this.props.form.getFieldsValue();
        _this.props.refreshTableByData([], 1, 1);
        sendBody.pageNum = "1";
        sendBody.pageSize = "10";
        if (this.state.province) {
            sendBody.province = this.state.province;
        }
        if (this.city) {
            sendBody.city = this.state.city;
        }
        sendBody.workType =
            window.routerCache.SubmitCustomerManage.workTypeStr || "";
        try {
            let res = await Axios("get", "/legalRenew/list", sendBody);

            _this.props.refreshTableByData(res.body, res.totalNum, 1);
        } catch (err) {
            // message.error(err.message);
        }
    };
    clearOptions = async () => {
        this.props.form.resetFields();

        sendBody = {};
        sendBody.pageNum = "1";
        sendBody.pageSize = "10";
        sendBody.workType =
            window.routerCache.SubmitCustomerManage.workTypeStr || "";
        this.props.refreshTableByData([], 1, 1);

        try {
            let res = await Axios("get", "/legalRenew/list", sendBody);

            this.props.refreshTableByData(res.body, res.totalNum, 1);
        } catch (err) {
            // message.error(err.message);
        }
    };
    clearLawfirm = async () => {
        window.routerCache.SubmitCustomerManage.workTypeStr = "";
        window.routerCache.SubmitCustomerManage.workType_status = "";
        this.props.refreshTableByData([], 1, 1);
        this.setState({
            workType_status: "",
        });
        delete sendBody.workType;
        try {
            let res = await Axios("get", "/legalRenew/list", sendBody);
            this.props.refreshTableByData(res.body, res.totalNum, 1);
        } catch (err) {
            // message.error(err.message);
        }
    };
    // 组件渲染后获取外界数据(GET)
    componentDidMount() { }
    render() {
        /*控制查询按钮状态*/
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="search_like_antD" style={{ marginTop: "-8px" }}>
                <Form
                    layout="inline"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                >
                    <Row
                        type="flex"
                        align="middle"
                        style={{ padding: "8px 0" }}
                    >
                        <Col span="8">
                            <Form.Item
                                label="企业名称"
                                style={{ width: "100%" }}
                            >
                                {getFieldDecorator("companyName", {
                                    initialValue: sendBody.companyName,
                                })(
                                    <Input
                                        placeholder="请输入"
                                        type="text"
                                        style={{ width: "80%" }}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span="8">
                            <Form.Item label="联系人" style={{ width: "100%" }}>
                                {getFieldDecorator("followUserName", {
                                    initialValue: sendBody.followUserName,
                                })(
                                    <Input
                                        placeholder="请输入"
                                        type="text"
                                        style={{ width: "80%" }}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span="8">
                            <Form.Item label="手机号" style={{ width: "100%" }}>
                                {getFieldDecorator("followUserName", {
                                    initialValue: sendBody.followUserName,
                                })(
                                    <Input
                                        placeholder="请输入"
                                        type="text"
                                        style={{ width: "80%" }}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row
                        type="flex"
                        justify="space-between"
                        align="middle"
                        style={{ padding: "4px 0" }}
                    >
                        <Col span="8">
                            <Form.Item
                                label="企业经营地："
                                style={{ width: "100%" }}
                            >
                                <AreaSelect
                                    bodyStyle={{ width: "80%" }}
                                    province={this.state.province}
                                    city={this.state.city}
                                    hiddenCountry
                                    Hadoop={{}}
                                    allowClear
                                    qingkong={this.state.qingkong}
                                    select={(province, city, county) => {
                                        console.log(province, city, county);
                                        sendBody.province = province;
                                        sendBody.city = city;
                                        this.setState({
                                            province: province || "",
                                            city: city || "",
                                        });
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span="8">
                            <Form.Item
                                label="报备状态"
                                style={{ width: "100%" }}
                            >
                                {getFieldDecorator("renewIdea", {
                                    initialValue: sendBody.renewIdea,
                                })(
                                    <Select
                                        placeholder="请选择"
                                        allowClear
                                        style={{ width: "80%" }}
                                    >
                                        <Option key="1">报备中</Option>
                                        <Option key="2">报备成功</Option>
                                        <Option key="3">报备失败</Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span="8">
                            <Row
                                type="flex"
                                justify="end"
                                align="middle"
                                style={{ width: "88%" }}
                            >
                                <div>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            onClick={this.query}
                                        >
                                            查询
                                        </Button>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button onClick={this.clearOptions}>
                                            重置
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                    <div>
                        <Row
                            type="flex"
                            justify="space-between"
                            align="middle"
                            style={
                                window.routerCache.SubmitCustomerManage
                                    .workTypeStr
                                    ? {
                                        paddingBottom: "10px",
                                    }
                                    : { paddingBottom: "40px" }
                            }
                        >
                            {window.routerCache.SubmitCustomerManage
                                .workTypeStr ? (
                                <Alert
                                    message={
                                        <div>
                                            <span>
                                                {
                                                    window.routerCache
                                                        .SubmitCustomerManage
                                                        .workTypeStr
                                                }
                                            </span>
                                            &nbsp;&nbsp;
                                            <a onClick={this.clearLawfirm}>
                                                清空
                                            </a>
                                            &nbsp;&nbsp;
                                        </div>
                                    }
                                    type="info"
                                    showIcon
                                    style={{ marginTop: "12px" }}
                                />
                            ) : (
                                ""
                            )}
                        </Row>
                    </div>
                </Form>
                <style>{`
               .search_like_antD .ant-form ant-form-inline .ant-row-flex .ant-row-flex-space-between .ant-row-flex-middle{
                    padding: 0px !important;
                }
                `}</style>
            </div>
        );
    }
}

let Search = connect(
    (state) => {
        return state;
    },
    (dispatch) => ({})
)(SearchM);
class AddBrokerM extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            selectOption: [],
            loading: false,
            confirmLoading: false,
            province: this.props.record.province,
            city: this.props.record.city,
            qingkong: false,
            custList: [],
        };
    }

    componentWillMount() { }

    // 单击确定按钮提交表单
    handleSubmit = (e) => {
        const { userRole } = this.props.operInfo;
        const brokerIds = localStorage.getItem("brokerIds");

        e.preventDefault();
        let arr = ["companyName", "linkman", "mobileNo", "category"];

        this.props.form.validateFieldsAndScroll(arr, async (err, values) => {
            console.log(err, values);
            if (!err) {
                let sendData = values;
                if (this.state.province) {
                    sendData.province = this.state.province;
                } else {
                    message.warning("请选择地区");
                    return;
                }
                if (this.state.city) {
                    sendData.city = this.state.city;
                } else {
                    message.warning("请选择城市");
                    return;
                }
                try {
                    this.setState({ confirmLoading: true });
                    let res = await Axios(
                        "post",
                        "/brokeruser/upload/clue",
                        sendData
                    );
                    if (res.code == "200" || res.code == "00") {
                        this.props.refreshTable();
                        this.props.form.resetFields();
                        message.success(res.message);

                        this.setState(
                            {
                                visible: false,
                                qingkong: true,
                                confirmLoading: false,
                                province: "",
                                city: "",
                            },
                            () => {
                                this.setState({
                                    qingkong: false,
                                });
                            }
                        );
                    } else {
                        message.error(res.data);
                        this.setState({ confirmLoading: false });
                    }
                } catch (err) {
                    message.error(err.msg);
                    this.setState({ confirmLoading: false });
                }
            }
        });
    };
    // 弹出框设置
    showModal = () => {
        this.setState({ visible: true });
    };
    handleCancel = (e) => {
        this.setState({ visible: false });
    };
    handleSelectChange = (value) => {
        console.log("select=" + value);
    };
    getCustList = async (v) => {
        if (Timer) {
            clearTimeout(Timer);
        }
        Timer = setTimeout(async () => {
            try {
                let res = await Axios(
                    "get",
                    "/custInfos/open/Eci/simple/v2",
                    { companyName: v },
                    "fireflyErp"
                );
                console.log("企业列表=", res);
                if (res.isSuccess == "T" && res.body) {
                    let custList = [];
                    JSON.parse(res.body).result.forEach((item) => {
                        if (item.name) {
                            let names = [];
                            for (let i = 0; i < item.name.length; i++) {
                                let yes = false;
                                for (let j = 0; j < v.length; j++) {
                                    if (v[j] == item.name[i]) {
                                        yes = true;
                                        break;
                                    }
                                }
                                if (yes) {
                                    names.push(
                                        <span style={{ color: "#FD485E" }}>
                                            {item.name[i]}
                                        </span>
                                    );
                                } else {
                                    names.push(item.name[i]);
                                }
                            }
                            custList.push(
                                <Option key={item.keyNo} value={item.name}>
                                    {names}
                                </Option>
                            );
                        }
                    });
                    this.setState({
                        custList,
                    });
                } else {
                    this.setState({
                        custList: [],
                    });
                }
            } catch (err) {
                if (err && err.message) {
                    message.error(err.message);
                }
            }
        }, 700);
    };
    searchCust = async (companyId) => {
        try {
            let res = await Axios(
                "get",
                `/legalCloud/open/area/${companyId}`,
                {},
                "cloud-legal"
            );
            if (res.isSuccess == "T" && res.body) {
                if (res.body.province) {
                    res.body.province = specialProvinceList.find((e) =>
                        e.includes(res.body.province)
                    );
                }
                //设置相应数据
                this.setState({
                    province: res.body.province,
                    city: res.body.city,
                });
                this.props.form.setFieldsValue({
                    category: [
                        res.body.cateFirst,
                        res.body.cateSecond,
                        res.body.cateThird,
                    ],
                });
            } else {
                this.setState({
                    province: "",
                    city: "",
                });
                this.props.form.setFieldsValue({
                    category: undefined,
                });
            }
        } catch (err) { }
    };

    render() {
        const { userRole } = this.props.operInfo;
        const { getFieldDecorator } = this.props.form;
        const { priceData } = this.state;
        const { type, record } = this.props;
        const brokerIds = localStorage.getItem("brokerIds");

        return (
            <span>
                <Button
                    type="primary"
                    icon="plus"
                    style={{ float: "right" }}
                    onClick={this.showModal}
                >
                    新增
                </Button>

                <Modal
                    title="新增报备客户"
                    open={this.state.visible}
                    onOk={this.handleSubmit}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={() => {
                        this.setState({ visible: false });
                    }}
                >
                    <Form
                        layout="horizontal"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 16 }}
                    >
                        <Form.Item label="企业名称：">
                            {getFieldDecorator("companyName", {
                                rules: [
                                    {
                                        required: true,
                                        message: "企业名称不能为空",
                                    },
                                ],
                                initialValue:
                                    type === "edit"
                                        ? record.companyName
                                        : undefined,
                            })(
                                <Select
                                    showSearch
                                    optionLabelProp="value"
                                    placeholder="请输入企业名称"
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    onSearch={(v) => {
                                        setTimeout(() => {
                                            if (v) {
                                                v = v.replace(/\s/g, "");
                                                this.props.form.setFieldsValue({
                                                    companyName: v,
                                                });
                                                this.getCustList(v);
                                            }
                                        });
                                    }}
                                    notFoundContent={null}
                                    onSelect={(v, options) => {
                                        if (v) {
                                            v = v.replace(/\s/g, "");
                                        }
                                        if (v) {
                                            this.searchCust(options.key);
                                        }
                                    }}
                                >
                                    {this.state.custList}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="联系人：">
                            {getFieldDecorator("linkman", {
                                rules: [
                                    {
                                        required: true,
                                        message: "联系人不能为空",
                                    },
                                ],
                                initialValue: undefined,
                            })(
                                <Input placeholder="请输入联系人" type="text" />
                            )}
                        </Form.Item>
                        <Form.Item label="手机号：">
                            {getFieldDecorator("mobileNo", {
                                rules: [
                                    {
                                        required: true,
                                        message: "手机号不能为空",
                                    },
                                    {
                                        pattern:
                                            /^(?:(?:\+|00)86)?1[2-9]\d{9}$/,
                                        message: "手机号格式不正确",
                                    },
                                ],
                                initialValue: undefined,
                            })(
                                <Input placeholder="请输入手机号" type="text" />
                            )}
                        </Form.Item>
                        <Form.Item label="企业经营地：">
                            {getFieldDecorator("edit", {
                                rules: [
                                    {
                                        required: true,
                                        message: "企业经营地不能为空",
                                    },
                                ],
                            })(
                                <AreaSelect
                                    province={this.state.province}
                                    city={this.state.city}
                                    controlled={true}
                                    hiddenCountry
                                    Hadoop={{}}
                                    allowClear
                                    qingkong={this.state.qingkong}
                                    select={(province, city, county) => {
                                        this.setState({
                                            province: province || "",
                                            city: city || "",
                                        });
                                    }}
                                />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </span>
        );
    }
}

let AddBroker = connect(
    (state) => {
        return state;
    },
    (dispatch) => ({
        change_operInfo: bindActionCreators(change_operInfo, dispatch),
    })
)(AddBrokerM);
