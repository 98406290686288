import React, { useState, useEffect } from "react";
import {
    Row,
    Breadcrumb,
    message,
    Button,
    Form,
    Popconfirm,
    Input,
    Select,
    Tooltip,
    Table,
    notification,
} from "antd";
import { FlieList } from "../../common";
import { Axios } from "../../axios";
import { AreaSelect, Imgbox } from "../../common";
import { Link } from "react-router-dom";
import Filter from "../../common/filter";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
const { Option } = Select;
let loading_exportOrders = false,
    message_loading = "";

function WindControlDetails(props) {
    const [state, setState] = useState({
        tData: [],
        loading: false,
        ROLE_PreCheck: props.operInfo.operInfo.roles.includes("ROLE_PreCheck"),
    });

    let columns = [
        {
            title: "导入批次号",
            dataIndex: "batchNo",
            key: "1",
            width: 130,
        },
        {
            title: "原告",
            dataIndex: "creditorName",
            key: "2",
        },
        {
            title: "被告",
            dataIndex: "debtorName",
            key: "3",
        },
        {
            title: "风控原因",
            dataIndex: "riskReason",
            key: "5",
        },
        {
            title: "风控状态",
            dataIndex: "riskStatus",
            key: "4",
            render: (text, record, index) => {
                let str = "";
                switch (text + "") {
                    case "0":
                        str = "智能风控根据标准无法判断";
                        break;
                    case "1":
                        str = "已通过智能风控";
                        break;
                    case "2":
                        str = "未通过智能风控";
                        break;
                    case "3":
                        str = "未处理";
                        break;
                    case "4":
                        str = "优质案件";
                        break;

                    default:
                        break;
                }

                return <span>{str}</span>;
            },
        },
        {
            title: "操作",
            dataIndex: "operate",
            key: "6",
            width: 60,
            render: (text, record, index) => {
                // 储存全局数据，用于风控预检原告被告搜索的初始数据；
                window.routerCache.CompanyCheck.fromListData = record;
                return (
                    <span>
                        {state.ROLE_PreCheck ? (
                            <Link to={"/companyCheckOld"}>查看</Link>
                        ) : (
                            <span>--</span>
                        )}
                    </span>
                );
            },
        },
    ];

    useEffect(() => {
        console.log("props===", props);
        async function fetchData() {
            setState((state) => {
                return {
                    ...state,
                    loading: true,
                };
            });
            // 调表格数据
            try {
                let res = await Axios(
                    "get",
                    "/crmEciBatchs/" + props.match.params.id,
                    {
                        pageNum: "1",
                        pageSize: "10000",
                    }
                );
                setState((state) => {
                    return {
                        ...state,
                        tData: res.data.dataList,
                        loading: false,
                    };
                });
                console.log("res==详情", res);
            } catch (e) {
                console.log(e);
                setState((state) => {
                    return {
                        ...state,
                        loading: false,
                    };
                });
            }
        }
        fetchData();
        console.log("props===", props);
    }, []); // Or [] if effect doesn't need props or state

    const exportOrders = async () => {
        if (!loading_exportOrders) {
            loading_exportOrders = true;
            message_loading = message.loading("生成中", 0);
            try {
                let res = await Axios(
                    "get",
                    "/autoCallPreCheckPois",
                    {
                        batchNo: props.match.params.id,
                    },
                    "mis"
                );
                if (res.code == "00") {
                    notification.success({
                        message: res.msg,
                    });
                } else {
                    notification.warning({
                        message: res.msg,
                    });
                }
                loading_exportOrders = false;
                message_loading();
                message_loading = "";
            } catch (error) {
                console.log(error);
                loading_exportOrders = false;
                message_loading();
                message_loading = "";
            }
        }
    };
    return (
        <div className="pageContainer">
            <Row className="headNav" type="flex" justify="space-between">
                <Row>
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item>客户管理</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {" "}
                            <Link to="/staffNameListManage">名单资料管理</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>风控预检详情</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row className="title">
                        <span className="name">导入风控预检数量列表</span>
                        {/* <span className='desc'>查询企业对象为公开案件信息的企业</span> */}
                    </Row>
                </Row>
                <Row style={{ paddingTop: "30px" }}>
                    <Button onClick={exportOrders}>智能预检导出</Button>
                </Row>
            </Row>
            <div className="scrollContainer">
                <div className="scrollMain">
                    <Table
                        dataSource={state.tData}
                        columns={columns}
                        size="middle"
                        loading={state.loading}
                        pagination={{
                            size: "large",
                            showQuickJumper: true,
                            pageSize: 10,
                            showTotal: (total) => {
                                return "共 " + total + " 条记录 ";
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default connect(
    (state) => {
        return state;
    },
    (dispatch) => ({})
)(WindControlDetails);
