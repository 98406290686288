import React from "react";
import {
    Button,
    Table,
    Row,
    Col,
    message,
    Form,
    Breadcrumb,
    Modal,
    Input,
} from "antd";
import { Axios } from "../../axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, HashRouter } from "react-router-dom";
import { FormOutlined } from "@ant-design/icons";
import G2 from "@antv/g2";
import { AreaSelectForm, ImgViewer, popoverTable, rules } from "../../common";
import { Shengpi2, Tables } from "./Common";

import filter from "../../common/filter";
import time from "../../common/filter";

let detailCustId;
let BackGroundColor = {
    1: "rgba(250, 141, 0, 1)",
    2: "rgba(43, 208, 90, 1)",
    3: "rgba(86, 86, 86, 1)",
    5: "rgba(86, 86, 86, 1)",
    7: "rgba(227, 77, 89, 1)",
    0: "rgba(227, 77, 89, 1)",
};
class MainM extends React.Component {
    TablesRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingList: false,
            pageVO: {},
            historyList: [],
            legalCloudDocs: [],
        };
    }

    componentDidMount () {
        detailCustId = this.props.match.params.id;
        this.getData();
    }

    getData = async () => {
        try {
            this.setState({
                loading: true,
            });
            let res = await Axios("get", "/esign/" + detailCustId, {});
            if (res.code === "200") {
                this.setState({
                    legalCloudDocs: res.data.fileInfos || [],
                    historyList: res.data.historyList || [],
                    pageVO: res.data.pageVO || [],
                });
                this.TablesRef.current.getOperhisList();
            } else {
                message.error(res.message);
            }
        } catch (err) {
            // message.error(err);
            this.setState({ loading: false });
        }
    };
    render () {
        const cStyle = {
            padding: "12px 24px 12px 24px",
            marginBottom: "15px",
            borderRadius: "4px",
            background: "#fff",
            fontSize: "14px",
        };
        const tStyle = {
            color: "#222",
            lineHeight: "30px",
            fontWeight: "650",
            fontSize: "16px",
            margin: "0px -24px 15px -24px",
            borderBottom: "1px solid #efe5e5",
            padding: "0 24px 8px 24px",
        };
        const dStyle = {
            color: "#222",
            lineHeight: "30px",
            margin: "10px 0px 0px 0px",
        };
        const { pageVO, legalCloudDocs } = this.state;
        return (
            <div className="pageContainer">
                <Row className="headNav">
                    <Col span={24}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>电子合同</Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to="/ElectronicContract">电子合同</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>电子合同详情</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={24} className="title">
                        <span className="name">电子合同详情</span>
                        <Shengpi2
                            refreshTable={this.getData}
                            index={"6"}
                            detailCustId={detailCustId}
                            brokerUserId={pageVO.brokerUserId}
                        >
                            <Button type="primary">分配业务员</Button>
                        </Shengpi2>
                    </Col>
                </Row>
                <div className="scrollContainer">
                    <div style={cStyle}>
                        <div style={tStyle}>企业基本信息</div>
                        <Row style={dStyle}>
                            <Col span={8}>
                                合同名称：{pageVO.contractName || "-"}
                            </Col>
                            <Col span={8}>
                                关联订单：{pageVO.orderId || "-"}{" "}
                                {/* <Shengpi1
                                    refreshTable={this.getData}
                                    orderId={pageVO.orderId}
                                /> */}
                            </Col>
                            <Col span={8}>
                                状态：
                                {filter.ElectroniCesignStatus(
                                    pageVO.esignStatus
                                ) || "-"}
                            </Col>
                        </Row>
                        <Row style={dStyle}>
                            <Col span={8}>
                                客户名称：{pageVO.custName || "-"}
                            </Col>
                            <Col span={8}>
                                套餐：{" "}
                                {pageVO.specification
                                    ? filter.legalspecification(
                                        pageVO.specification
                                    )
                                    : "-"}
                            </Col>
                            <Col span={8}>
                                价格：
                                {pageVO.price ? pageVO.price + "元" : "-"}
                            </Col>
                        </Row>
                        <Row style={dStyle}>
                            <Col span={8}>
                                申请人：{pageVO.applyName || "-"}
                            </Col>
                            <Col span={8}>
                                申请时间： {pageVO.createdTime || "-"}
                            </Col>
                            <Col span={8}></Col>
                        </Row>
                    </div>

                    <div style={cStyle}>
                        <div style={tStyle}>合同附件</div>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {legalCloudDocs.map((v) => {
                                return (
                                    <a
                                        target="_blank"
                                        href={v.fileDownloadUrl}
                                        style={{
                                            width: "391px",

                                            background: "#FAFAFA",
                                            borderRadius: "8px",
                                            color: "#242A32",
                                            border: "1px solid #EEEEEE",
                                            padding: "8px 12px",
                                            position: "relative",
                                            margin: "0 16px 12px 0",
                                            cursor: "pointer",
                                            display: "flex",
                                        }}
                                    >
                                        <img
                                            style={{
                                                width: "45px",
                                                height: "48px",
                                                marginRight: "4px",
                                            }}
                                            src={require("../../assets/images/p.png")}
                                        />
                                        <span
                                            style={{
                                                position: "absolute",

                                                color: " #FFFFFF",
                                                padding: "2px 4px",
                                                background:
                                                    BackGroundColor[
                                                    pageVO.esignStatus
                                                    ],
                                                fontFamily:
                                                    "PingFangSC-Medium, PingFang SC",
                                                fontSize: "12px",
                                                top: "0",
                                                left: "0",
                                                borderRadius: "4px 4px 2px 2px",
                                            }}
                                        >
                                            {filter.ElectroniCesignStatus(
                                                pageVO.esignStatus
                                            )}
                                        </span>
                                        <div>{v.fileName}</div>
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                    <div style={cStyle}>
                        <div style={tStyle}>操作日志</div>
                        <Table
                            dataSource={this.state.historyList}
                            columns={[
                                {
                                    title: "操作时间",
                                    dataIndex: "operateTime",
                                },
                                {
                                    title: "操作人",
                                    dataIndex: "operateName",
                                },
                                {
                                    title: "内容",
                                    dataIndex: "operateDesc",
                                },
                            ]}
                            size="middle"
                            pagination={
                                this.state.historyList.length > 10
                                    ? {
                                        size: "large",
                                        showQuickJumper: true,
                                        pageSize: 10,
                                    }
                                    : false
                            }
                            bordered={true}
                        />
                    </div>
                    <div style={cStyle}>
                        <div style={tStyle}>操作记录</div>
                        <Tables
                            code={6}
                            detailCustId={this.props.match.params.id}
                            ref={this.TablesRef}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

let Main = connect(
    (state) => {
        return state;
    },
    (dispatch) => ({
        actions: bindActionCreators({}, dispatch),
    })
)(MainM);
export default Main;

class Shengpi1M extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            confirmLoading: false,
        };
    }

    componentDidMount () { }

    // 单击确定按钮提交表单
    handleSubmit = (e) => {
        e.preventDefault();
        this.formRef.current
            .validateFields()
            .then(async (values) => {
                values.id = detailCustId;
                try {
                    this.setState({ confirmLoading: true });
                    let res = await Axios("POST", "/esign/modify", values);
                    if (res.code == "200" || res.isSuccess == "T") {
                        this.props.refreshTable();
                        message.success(res.message);
                        this.setState({
                            visible: false,
                            confirmLoading: false,
                        });
                        this.formRef.current.resetFields();
                    } else {
                        message.error(res.message);
                        this.setState({ confirmLoading: false });
                    }
                } catch (err) {
                    message.error(err.message);
                    this.setState({ confirmLoading: false });
                }
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    };
    render () {
        const orderId = this.props.orderId;
        return (
            <span>
                <FormOutlined
                    onClick={() => this.setState({ visible: true })}
                />
                <Modal
                    confirmLoading={this.state.confirmLoading}
                    title="修改关联订单"
                    open={this.state.visible}
                    onOk={this.handleSubmit}
                    onCancel={() => this.setState({ visible: false })}
                >
                    <Form
                        ref={this.formRef}
                        layout="horizontal"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 16 }}
                    >
                        <Form.Item
                            label="订单ID"
                            name="orderId"
                            initialValue={orderId}
                            rules={[
                                {
                                    required: true,
                                    message: "请输入订单ID",
                                },
                            ]}
                        >
                            <Input
                                placeholder="请输入订单ID"
                                type="text"
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </span>
        );
    }
}

let Shengpi1 = connect(
    (state) => {
        return state;
    },
    (dispatch) => ({})
)(Shengpi1M);
