/* eslint-disable no-useless-constructor */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, connect } from 'react-redux';
import { ConfigProvider, message } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
// import 'antd/lib/style/v2-compatible-reset';
import { BrowserRouter, Route, Switch, withRouter, Redirect ,HashRouter} from 'react-router-dom';
import url from 'url';
import 'whatwg-fetch';
import 'es6-promise';
import 'fetch-ie8/fetch.js';
import './assets/css/index.css';
import store from "./redux/reducers/store";
import routes from './routes/router.js';
// import 'react-viewer/dist/index.css';
import {GetDepartList,} from './redux/actions/team.js';
import {change_operInfo} from './redux/actions/operInfo.js';
import { bindActionCreators } from 'redux';

import { Axios } from './axios';
import App from './App';
// 引入垫片兼容IE
require('es5-shim');
require('es5-shim/es5-sham');
require('console-polyfill');

class IndexM extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            isGetToken:false,
        }
    }

    componentDidMount() {
        // 升级到全局变量，
        window.HashHistory = this.props.history;
    }
    render() {
        return (

               <Switch>
                {
                    routes.map((r, key) => {
                        return (
                            <Route
                                render={props => (<r.component {...props} routes={r.routes} />)}
                                exact={r.exact}
                                key={r.path + key}
                                path={r.path}
                            />
                        )
                    })
                }
                </Switch>

        )
    }
}
let Index = connect((state) => {
    return state
},
    (dispatch) => ({
        change_operInfo: bindActionCreators(change_operInfo, dispatch),
        GetDepartList: bindActionCreators(GetDepartList, dispatch),
    })
)(withRouter(IndexM))



ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider locale={zhCN}>
            <HashRouter basename={'/'}>
                <Index />
            </HashRouter>
        </ConfigProvider>
    </Provider>
    , document.querySelector('#root')
);
