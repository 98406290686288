import React, { useState, useEffect } from "react";
import { Button, Table, Row, Col, message, Form, Breadcrumb, Modal, Select, DatePicker, Input, List, Drawer, Dropdown, AutoComplete, Popover, Cascader } from "antd";
import { Axios } from "../../axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, HashRouter } from "react-router-dom";
import G2 from "@antv/g2";
import { AreaSelectForm, ImgViewer, rules, FlieList, popoverTable } from "../../common";
import { industryLevel3 } from "../../common/industryLevel3";
import filter from "../../common/filter";
import time from "../../common/filter";
import Filter from "../../common/filter";
import CareaSelect from "../../common/CareaSelect";
import { Ossupload } from "../../common/upLoad";
import moment from "moment";
import "./acustomerManage.scss";
import { ExclamationCircleOutlined, CloseOutlined, DownOutlined, UserOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { confirm } = Modal;
const { Option } = Select;
let StatuspageNum = 1,
  timeout;
let detailCustId;
const cStyle = {
  padding: "12px 24px 12px 24px",
  marginBottom: "15px",
  borderRadius: "4px",
  background: "#fff",
  fontSize: "14px",
};
const tStyle = {
  color: "#222",
  lineHeight: "30px",
  fontWeight: "650",
  fontSize: "16px",
  margin: "0px -24px 15px -24px",
  borderBottom: "1px solid #efe5e5",
  padding: "0 24px 8px 24px",
};
const dStyle = {
  color: "#222",
  lineHeight: "30px",
  margin: "10px 0px 0px 0px",
};
const tStyle1 = {
  color: "#222",
  lineHeight: "30px",
  fontWeight: "650",
  fontSize: "16px",
  margin: "0px -24px 15px -24px",
  borderBottom: "1px solid #efe5e5",
  padding: "0 0 8px 0",
};
const TabsStyle = {
  fontWeight: "650",
  fontSize: "16px",
};
class Shengpi2M extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      selectOption: [],
      loading: false,
      confirmLoading: false,
      StatusList: [],
      SearchBrokerName: "",
    };
  }

  componentDidMount () { }
  handleSearch = value => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(
      this.setState(
        {
          SearchBrokerName: value,
        },
        () => {
          StatuspageNum = 1;
          this.StatusListFun(1, this.state.SearchBrokerName);
        }
      ),
      300
    );
  };
  // 单击确定按钮提交表单
  handleSubmit = e => {
    e.preventDefault();
    const { index, detailCustId } = this.props;
    console.log(detailCustId);
    this.formRef.current
      .validateFields()
      .then(async values => {
        values.bizId = detailCustId;
        values.code = index;
        try {
          this.setState({ confirmLoading: true });
          let res = await Axios("POST", "/brokeruser/module/transfer", values);
          if (res.code == "200") {
            this.props.refreshTable();
            message.success(res.message);
            this.setState({ visible: false, confirmLoading: false });
            this.formRef.current.resetFields();
          } else {
            message.error(res.message);
          }
        } catch (err) {
          message.error(err.message);
          this.setState({ confirmLoading: false });
        }
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };
  StatusListFun = async (pageNum, value) => {
    try {
      let obj = {
        pageNum: pageNum,
        pageSize: 10,
        status: "0",
      };
      if (value) obj.brokerUserName = value;
      let { userInfo = {} } = this.props;
      if ("{}" == JSON.stringify(userInfo)) userInfo = JSON.parse(localStorage.getItem("legal_userInfo") || {});
      if (userInfo.job == "director") obj.departId = userInfo.departId;
      if (this.props.operInfo.userRole === "1" || userInfo.job == "senior" || userInfo.job == "director") {
        Axios("get", "/brokeruser/all/page", obj).then(val => {
          this.setState({
            StatusList: this.state.StatusList.concat(val.data.dataList),
          });
        });
      }
    } catch (err) {
      // message.error(err.message);
    }
  };
  // 弹出框设置
  showModal = () => {
    this.setState({
      StatusList: [],
      visible: true,
    });
    StatuspageNum = 1;
    this.StatusListFun(1);
  };
  handleCancel = e => {
    this.setState({ visible: false });
  };
  handleSelectChange = value => {
    console.log("select=" + value);
  };

  render () {
    const { brokerUserId } = this.props;
    let { userInfo = {} } = this.props;
    if ("{}" == JSON.stringify(userInfo)) userInfo = JSON.parse(localStorage.getItem("legal_userInfo") || {});
    const { userRole } = this.props.operInfo;
    const { StatusList } = this.state;
    return (
      <span>
        {userRole === "1" || userInfo.job == "senior" || userInfo.job == "director" ? (
          <span onClick={this.showModal} style={{ float: "right" }}>
            {" "}
            {this.props.children}{" "}
          </span>
        ) : null}
        <Modal confirmLoading={this.state.loading} title="分配业务员" open={this.state.visible} onOk={this.handleSubmit} onCancel={this.handleCancel}>
          <Form ref={this.formRef} layout="horizontal" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
            <Form.Item
              label="业务员"
              style={{ width: "100%" }}
              name="toBrokerUserId"
              // initialValue={brokerUserId}
              rules={[
                {
                  required: true,
                  message: "请选择业务员",
                },
              ]}
            >
              <Select
                onPopupScroll={(e, value) => {
                  e.persist();
                  const { target } = e;
                  if (target.scrollTop + target.offsetHeight + 2 >= target.scrollHeight) {
                    StatuspageNum = StatuspageNum + 1;
                    this.StatusListFun(StatuspageNum, this.state.SearchBrokerName);
                  }
                }}
                placeholder="请选择"
                style={{ width: "100%" }}
                allowClear
                // showSearch
                onSearch={e => {
                  this.handleSearch(e);
                }}
                optionFilterProp="children"
                filterOption={(input, option) => String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Select.Option key={this.props.userInfo.id} value={this.props.userInfo.id}>
                  {this.props.userInfo.userName || this.props.userInfo.brokerName || ""}
                  （当前自己）
                </Select.Option>

                {StatusList.map(v => {
                  if (this.props.userInfo.id != v.id)
                    return (
                      <Select.Option key={v.id} value={v.id}>
                        {v.userName}（{v.userRole == "1" ? "代理商" : filter.JobType(v.job) || "员工"}）
                      </Select.Option>
                    );
                })}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </span>
    );
  }
}

let Shengpi2 = connect(
  state => {
    return state;
  },
  dispatch => ({})
)(Shengpi2M);
class Tables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      OperhisList: [],
    };
  }
  componentDidMount () {
    if (this.props.code == "7") {
      this.getOperhisList();
    }
  }
  // 单击确定按钮提交表单
  getOperhisList = async () => {
    let { code, detailCustId } = this.props;
    console.log(this.props, "this.props");
    try {
      let res = await Axios("get", "/brokeruser/module/operhis", {
        code: code,
        bizId: detailCustId,
      });
      if (res.code === "200") {
        this.setState({
          OperhisList: res.data || [],
        });
      } else {
        message.error(res.message);
      }
    } catch (err) {
      this.setState({ loading: false });
    }
  };
  render () {
    return (
      <Table
        dataSource={this.state.OperhisList}
        columns={[
          {
            title: "ID",
            dataIndex: "id",
          },
          {
            title: "操作类型",
            dataIndex: "operAction",
          },
          {
            title: "操作人",
            dataIndex: "operName",
          },
          {
            title: "操作时间",
            dataIndex: "operDate",
            render: (t, r) => filter.dateTime_HM(t + r.operTime),
          },
          {
            title: "备注",
            dataIndex: "memo",
          },
        ]}
        size="middle"
        pagination={
          this.state.OperhisList.length > 10
            ? {
              size: "large",
              showQuickJumper: true,
              pageSize: 10,
            }
            : false
        }
        bordered={true}
      />
    );
  }
}
class ContactList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      confirmLoading: false,
      childrenList: [],
    };
  }
  render () {
    return (
      <span>
        <span
          onClick={() => {
            this.setState({ visible: true });
          }}
        >
          {this.props.children}
        </span>
        <Modal
          confirmLoading={this.state.confirmLoading}
          title="其他联系人"
          visible={this.state.visible}
          width="70%"
          onCancel={() => {
            this.setState({
              visible: false,
            });
          }}
          footer={null}
        >
          <Table
            dataSource={this.props.Infos}
            rowKey={record => record.id}
            columns={[
              {
                title: "姓名",
                dataIndex: "otherDebtName",
                render: (t, r) => <span>{t || "-"}</span>,
              },
              {
                title: "关系",
                dataIndex: "otherDeptRelation",
                render: (t, r) => <span>{t || "-"}</span>,
              },
              {
                title: "手机号",
                dataIndex: "otherDebtMobile",
                render: (t, r) => <span>{t || "-"}</span>,
              },
              {
                title: "身份证",
                dataIndex: "otherDebtIdCard",
                render: t => t || "-",
              },
              {
                title: "家庭地址",
                dataIndex: "otherDebtHomeAddress",
                render: t => t || "-",
              },
              {
                title: "工作地址",
                dataIndex: "otherDebtWorkAddress",
                render: t => t || "-",
              },
            ]}
            size="middle"
            pagination={{
              size: "large",
              showQuickJumper: true,
              pageSize: 10,
            }}
            bordered={true}
          />
        </Modal>
      </span>
    );
  }
}
const LegalCaseRecoveriesDetail = ({ id, type }) => {
  const [loading, setLoading] = useState(false);
  const [valueInfo, setValueInfo] = useState({
    batchInfo: {},
    caseInfo: {},
    propertyReturnInfos: [],
  });
  const getDataDetail = async () => {
    try {
      setLoading(true);
      let res = await Axios("get", "/property/batch/case/detail", { caseId: id });
      if (res.code == "200") {
        setValueInfo(res.data || {});
      } else {
        message.error(res.message);
      }
      setLoading(false);
    } catch (err) {
      // message.error(err.message)
      setLoading(false);
    }
  };
  useEffect(() => {
    getDataDetail();
  }, []);
  return (
    <div className="pageContainer">
      <Row className="headNav">
        <Col span={24}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={type == 1 ? "/LegalCaseManage" : "/RecoveriesManage"}>{type == 1 ? "案件管理" : "回款管理"}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{type == 1 ? "案件详情" : "回款详情"}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={24} className="title">
          <span className="name">{type == 1 ? "案件详情" : "回款详情"}</span>
        </Col>
      </Row>
      <div className="scrollContainer">
        <div style={cStyle}>
          <div style={tStyle}>基本信息</div>
          <Row style={dStyle}>
            <Col span={8}>案件ID：{valueInfo.caseInfo.caseId || "-"}</Col>
          </Row>
          <Row style={dStyle}>
            <Col span={8}>房号：{valueInfo.caseInfo.roomNo || "-"}</Col>
            <Col span={8}>姓名：{valueInfo.caseInfo.name || "-"}</Col>
            <Col span={8}>联系电话：{valueInfo.caseInfo.mobileNo || "-"}</Col>
          </Row>
          <Row style={dStyle}>
            <Col span={8}>欠费年限：{valueInfo.caseInfo.oweAmtYear}</Col>
            <Col span={8}>
              欠费金额：
              {valueInfo.caseInfo.oweAmt ? valueInfo.caseInfo.oweAmt + ((valueInfo.caseInfo.oweAmt + "").includes("元") ? "" : "元") : "-"}
            </Col>
            <Col span={8}>
              案件类型：
              {valueInfo.caseInfo.caseType == "1" ? "催收案件" : valueInfo.caseInfo.caseType == "2" ? "诉讼案件" : "-"}
            </Col>
          </Row>
          <Row style={dStyle}>
            <Col span={8}>案件备注：{valueInfo.caseInfo.caseMemo || "-"}</Col>
          </Row>
        </div>
        <div style={cStyle}>
          <div style={tStyle}>批次信息</div>
          <Row style={dStyle}>
            <Col span={8}>批次号：{valueInfo.batchInfo.batchNo || "-"}</Col>
            <Col span={8}>批次ID：{valueInfo.batchInfo.batchId || "-"}</Col>
            <Col span={8}>
              催收区域：{valueInfo.batchInfo.collectionProvince || "-"} {valueInfo.batchInfo.collectionCity || ""}
            </Col>
          </Row>
          <Row style={dStyle}>
            <Col span={8}>客户名称：{valueInfo.batchInfo.customName || "-"}</Col>
            <Col span={8}>案件等级：{valueInfo.batchInfo.caseLevel || "-"}</Col>
            <Col span={8}>业务渠道：{valueInfo.batchInfo.channel || "-"}</Col>
          </Row>
          <Row style={dStyle}>
            <Col span={8}>销售专员：{valueInfo.batchInfo.saleMan || "-"}</Col>
            <Col span={8}>物业市场运维：{valueInfo.batchInfo.propertyMarketOperName || "-"}</Col>
            <Col span={8}>委案日期：{valueInfo.batchInfo.entrustDateStr || "-"}</Col>
          </Row>
          <Row style={dStyle}>
            <Col>备注：{valueInfo.batchInfo.batchMemo || "-"}</Col>
          </Row>
        </div>
        <div style={cStyle}>
          <div style={tStyle}>回款信息</div>
          <Table
            dataSource={valueInfo.propertyReturnInfos || []}
            columns={[
              {
                title: "序号",
                dataIndex: "index",
                render: (text, record, index) => index + 1,
              },
              {
                title: "回款金额（元）",
                dataIndex: "returnAmt",
              },
              {
                title: "回款日期",
                dataIndex: "returnDate",
                render: (text, record) => (text ? text.slice(0, 10) : "-"),
              },
              popoverTable(6, {
                title: "备注",
                dataIndex: "returnMemo",
              }),
              {
                title: "操作人",
                dataIndex: "createdName",
              },
              {
                title: "审核状态",
                dataIndex: "processStatus",
                render: (text, record) => (text == "1" ? "未审核" : text == "2" ? "审核通过" : "-"),
              },
            ]}
            size="middle"
            pagination={false}
            bordered={true}
          />
        </div>
      </div>
    </div>
  );
};
const FollowUpRecords = ({ DataList = [], refreshTable = () => { }, id, record = {}, type = '' }) => {
  useEffect(() => { }, []);
  let time = "",
    isTime = "",
    isShow = true;
  return (
    <div style={cStyle} id="FollowUpRecords">
      <div style={tStyle}>
        销售动态
        <FollowUp getDataDetail={refreshTable} id={id} record={record} type={type}>
          <Button type="primary" style={{ float: "right" }}>
            跟进
          </Button>
        </FollowUp>
      </div>
      <List
        itemLayout="horizontal"
        dataSource={DataList || []}
        pagination={(DataList || []).length ? {
          pageSize: 6,
          onChange: page => {
            isTime = "";
            isShow = true;
          },
        } : false}
        renderItem={value => {
          time = value.followTime ? (value.followTime || "").slice(0, 10) : value.operDate ? filter.date(value.operDate || "") : "";
          if (isTime != time) {
            isTime = time;
            isShow = true;
          } else isShow = false;
          return (
            <>
              {isShow && <p>{time}</p>}
              <div className="div">
                <div style={{ textAlign: "center" }}>
                  <p className="r">{value.followTime ? (value.followTime || "").slice(8, 10) : value.operDate ? (value.operDate || "").slice(6, 8) : ""}日</p>
                  {(value.followTime || "").slice(11, 16)}
                </div>
                <div className="l">
                  <p>
                    {value.followType && <span>{filter.FollowType(value.followType)}</span>}
                    {value.bizType == "clueFollowRecord" ? value.operContent || value.memo : value.followInfo || value.memo}
                  </p>
                  <p className="c">{value.bizType == "clueFollowRecord" ? "来自客户：" + (value.companyName || value.followCustom) : "操作人：" + value.operName + " " + (value.departName || "")} </p>
                </div>
              </div>
            </>
          );
        }}
      />
    </div>
  );
};
const FollowUp = ({ children, getDataDetail, id = undefined, record = {}, mobileList = [], type = '' }) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const handleOk = () => {
    form
      .validateFields()
      .then(async values => {
        console.log(values, "values");
        values.id = id;
        values.followMaster = type;
        values.followTime = moment(values.followTime).format("YYYY-MM-DD HH:mm") + ":00";
        values.nextFollowTime = moment(values.nextFollowTime).format("YYYY-MM-DD HH:mm") + ":00";
        try {
          setConfirmLoading(true);
          let res = await Axios("post", "/brokeruser/save/followRecord", values);
          if (res.errorCode == "200" || res.isSuccess == "T") {
            message.success(res.errorMsg);
            setOpen(false);
            getDataDetail();
            form.resetFields();
          } else {
            message.error(res.errorMsg);
          }
          setConfirmLoading(false);
        } catch (err) {
          message.error(err.errorMsg);
          setConfirmLoading(false);
        }
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };
  return (
    <>
      <span onClick={() => setOpen(true)}>{children} </span>
      <Modal title="跟进记录" open={open} onOk={handleOk} onCancel={() => setOpen(false)} confirmLoading={confirmLoading}>
        <Form {...{ labelCol: { span: 6 }, wrapperCol: { span: 16 } }} form={form}>
          <Form.Item
            name="followCustom"
            label="跟进客户"
            rules={[
              {
                required: true,
                message: "跟进客户不能为空",
              },
            ]}
            initialValue={record.companyName}
          >
            <Input placeholder="请输入跟进客户" disabled />
          </Form.Item>
          {/* 新增机会初次添加跟进记录 */}
          {!id && (
            <>
              <Form.Item
                name="linkman"
                label="联系人"
                rules={[
                  {
                    required: true,
                    message: "联系人不能为空",
                  },
                ]}
              >
                <Input placeholder="请输入该客户的联系人名称" />
              </Form.Item>
              <Form.Item
                name="mobileNo"
                label="联系电话"
                rules={[
                  {
                    required: true,
                    message: "联系电话不能为空",
                  },
                  {
                    message: "联系电话不合法",
                    pattern: /^((1[0-9]{2})+\d{8})$/,
                  },
                ]}
              >
                <AutoComplete placeholder="请输入该客户的联系电话号码" maxLength={11} options={mobileList} />
              </Form.Item>
            </>
          )}
          <Form.Item
            name="followType"
            label="跟进类型"
            rules={[
              {
                required: true,
                message: "跟进类型不能为空",
              },
            ]}
          >
            <Select placeholder="请选择跟进类型" allowClear>
              <Option value="1">电话</Option>
              <Option value="2">微信</Option>
              <Option value="3">拜访</Option>
              <Option value="4">短信</Option>
              <Option value="5">其他</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="followTime"
            label="跟进时间"
            rules={[
              {
                required: true,
                message: "跟进时间不能为空",
              },
            ]}
          >
            <DatePicker placeholder="请选择跟进时间" format="YYYY-MM-DD HH:mm" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="nextFollowTime"
            label="下次跟进时间"
            rules={[
              {
                required: false,
                message: "下次跟进时间不能为空",
              },
            ]}
          >
            <DatePicker placeholder="请选择下次跟进时间" format="YYYY-MM-DD HH:mm" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="followInfo"
            label="跟进情况"
            rules={[
              {
                required: true,
                message: "跟进情况不能为空",
              },
            ]}
          >
            <TextArea rows={2} placeholder="请输入跟进情况" maxLength={300} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
const FollowConversion = ({ children, record = {} }) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [infoData, setInfoData] = useState({});
  const [DataList, setDataList] = useState([]);
  const [transferType, setTransferType] = useState("");
  const [pickerDate, setPickerDate] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  let time = "",
    isTime = "",
    isShow = true;
  const items = [
    {
      key: "1",
      label: <a onClick={() => onChange("clue")}>线索客户内</a>,
    },
    {
      key: "2",
      label: <a onClick={() => onChange("intention")}>意向客户内</a>,
    },
  ];
  const onChange = type => {
    //初次添加客户
    if (JSON.stringify(infoData) == "{}") {
      setTransferType(type);
      setOpenModal(true);
    } else {
      //已有客户
      if (infoData.legalCloudId && type == 'clue') {
        message.error('意向客户不可转为线索客户')
        return
      }
      if ((infoData.clueId && type == 'clue') || (infoData.legalCloudId && type == 'intention')) {
        message.error('该客户已被添加在' + (type == 'clue' ? '线索' : '意向') + '客户管理内')
        return
      }
      if ((infoData.clueId && type == 'intention')) {
        confirm({
          title: '该客户已被添加到“线索客户管理”内，是否要将客户转移到“意向客户管理”内？',
          icon: <ExclamationCircleOutlined />,
          maskClosable: true,
          content: null,
          onOk: () => {
            let obj = { ...infoData };
            obj.transferType = type;
            obj.companyName = record.potentialCustomers;
            ChangeClient(obj);
          },
          onCancel () {
            console.log('Cancel');
          },
        });
      }
    }
  };
  //获取跟进列表
  const getList = async ({ date = undefined, clueId = undefined, legalCloudId = undefined }) => {
    try {
      let obj = { clueId: clueId || (infoData || {}).clueId, legalCloudId: legalCloudId || (infoData || {}).legalCloudId };
      if ((date || pickerDate || []).length) {
        obj.startTime = (date || pickerDate || [])[0].format("YYYY-MM-DD") + " 00:00:00";
        obj.endTime = (date || pickerDate || [])[1].format("YYYY-MM-DD") + " 23:59:59";
      }
      let res = await Axios("get", "/brokeruser/operhis", obj);
      if (res.code == "200" || res.isSuccess == "T") {
        setDataList(res.body || []);
      } else {
        message.error(res.errorMsg);
      }
    } catch (err) { }
  };
  //获取客户信息ID
  const getInfo = async () => {
    try {
      let res = await Axios("get", "/brokeruser/toIntention/" + record.potentialCustomers, { companyName: record.potentialCustomers });
      if (res.code == "200" || res.isSuccess == "T") {
        setInfoData(res.body || {});
        if (JSON.stringify(res.body || {}) != "{}") {
          getList({ clueId: (res.body || {}).clueId, legalCloudId: (res.body || {}).legalCloudId });
        }
      } else {
        message.error(res.errorMsg);
      }
    } catch (err) { }
  };
  //客户转移
  const ChangeClient = async values => {
    try {
      let res = await Axios("post", "/brokeruser/transfer/customer", values);
      if (res.errorCode == "200" || res.isSuccess == "T") {
        message.success(res.errorMsg);
        setOpenModal(false)
        form.resetFields()
        getInfo();
      } else {
        message.error(res.errorMsg);
      }
    } catch (err) {
      message.error(err.errorMsg);
    }
  };
  //填写客户信息后转移
  const handleOk = () => {
    form
      .validateFields()
      .then(async values => {
        console.log(values, "values");
        values.companyName = record.potentialCustomers;
        values.transferType = transferType;
        ChangeClient(values);
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };
  const onClose = () => {
    setOpen(false);
    setPickerDate([]);
    setInfoData([]);
    setDataList([]);
  };
  //ID，联系电话下拉配置
  const ID = (infoData || {}).legalCloudId || (infoData || {}).clueId,
    mobileList = (record.potentialCustomersPhones || [])
      .filter(v => {
        if (/^((1[0-9]{2})+\d{8})$/.test(v)) return v;
      })
      .map(v => {
        return { value: v };
      });
  return (
    <>
      <span
        onClick={() => {
          setOpen(true);
          getInfo();
        }}
      >
        {children}{" "}
      </span>
      <Drawer title="跟进转化" placement="right" onClose={onClose} open={open} width={"40%"} maskClosable={true} closable={false} extra={<CloseOutlined onClick={onClose} />}>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomLeft"
        >
          <Button type="primary"><UserOutlined />&nbsp; 转移到 &nbsp;<DownOutlined /></Button>
        </Dropdown>
        <div style={{ borderRadius: "1px solid #E7E7E7" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>销售动态</h3>
            <FollowUp
              getDataDetail={() => {
                if (ID) {
                  getList({});
                } else {
                  getInfo();
                }
              }}
              id={ID}
              record={{ companyName: record.potentialCustomers }}
              mobileList={mobileList || []}
            >
              <Button type="primary">写跟进</Button>
            </FollowUp>
          </div>
          <RangePicker
            format={"YYYY/MM/DD"}
            onChange={v => {
              setPickerDate(v);
              getList({ date: v || [] });
            }}
          />
          <div style={cStyle} id="FollowUpRecords">
            <List
              itemLayout="horizontal"
              dataSource={DataList || []}
              pagination={(DataList || []).length ? {
                pageSize: 6,
                onChange: page => {
                  isTime = "";
                  isShow = true;
                },
              } : false}
              renderItem={value => {
                time = value.followTime ? (value.followTime || "").slice(0, 10) : value.operDate ? filter.date(value.operDate || "") : "";
                if (isTime != time) {
                  isTime = time;
                  isShow = true;
                } else isShow = false;
                return (
                  <>
                    {isShow && <p>{time}</p>}
                    <div className="div">
                      <div style={{ textAlign: "center" }}>
                        <p className="r">{value.followTime ? (value.followTime || "").slice(8, 10) : value.operDate ? (value.operDate || "").slice(6, 8) : ""}日</p>
                        {(value.followTime || "").slice(11, 16)}
                      </div>
                      <div className="l">
                        <p>
                          {value.followType && <span>{filter.FollowType(value.followType)}</span>}
                          {value.bizType == "clueFollowRecord" ? value.operContent || value.memo : value.followInfo || value.memo}
                        </p>
                        <p className="c">{value.bizType == "clueFollowRecord" ? "来自客户：" + (value.companyName || value.followCustom) : "操作人：" + value.operName + " " + (value.departName || "")} </p>
                      </div>
                    </div>
                  </>
                );
              }}
            />
          </div>
        </div>
      </Drawer>
      <Modal title="填写客户信息" open={openModal} onOk={handleOk} onCancel={() => setOpenModal(false)} confirmLoading={confirmLoading}>
        <Form {...{ labelCol: { span: 6 }, wrapperCol: { span: 16 } }} form={form}>
          <Form.Item
            name="linkman"
            label="联系人"
            rules={[
              {
                required: true,
                message: "联系人不能为空",
              },
            ]}
          >
            <Input placeholder="请输入该客户的联系人名称" maxLength={10} />
          </Form.Item>
          <Form.Item
            name="mobileNo"
            label="联系电话"
            rules={[
              {
                required: true,
                message: "联系电话不能为空",
              },
              {
                message: "联系电话不合法",
                pattern: /^((1[0-9]{2})+\d{8})$/,
              },
            ]}
          >
            <AutoComplete placeholder="请输入该客户的联系电话号码" maxLength={11} options={mobileList} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
const EditMobileNo = ({ refreshTable = () => { }, ExtraMobileNo = "", clueId, legalCloudId }) => {
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false);
  const [MobileNoList, setMobileNoList] = useState(ExtraMobileNo);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const handleOk = () => {
    form
      .validateFields()
      .then(async values => {
        values.extraMobileNo = MobileNoList ? MobileNoList + "," + values.extraMobileNo : values.extraMobileNo;
        if (((MobileNoList || "").split(",") || []).length >= 5) {
          message.error("最多添加5个手机号");
          return;
        }
        ChangeMobileNo(values, true);
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };
  useEffect(() => {
    setMobileNoList(ExtraMobileNo);
  }, [ExtraMobileNo]);
  const ChangeMobileNo = async (values, type) => {
    try {
      values.clueId = clueId;
      values.legalCloudId = legalCloudId;
      setConfirmLoading(true);
      let res = await Axios("post", "/brokeruser/edit/info", values);
      if (res.errorCode == "200" || res.isSuccess == "T") {
        message.success(res.errorMsg);
        setOpen(false);
        type && setEdit(false)
        refreshTable();
        form.resetFields();
      } else {
        message.error(res.errorMsg);
      }
      setConfirmLoading(false);
    } catch (err) {
      message.error(err.errorMsg);
      setConfirmLoading(false);
    }
  };
  return (
    <>
      {((MobileNoList || "").split(",") || [])[0] ? <Popover
        content={
          <div>
            {((MobileNoList || "").split(",") || []).map((v, i) => (
              <div>
                {" "}
                <a>
                  {v} &emsp;{" "}
                  {edit && v && (
                    <CloseOutlined
                      onClick={() => {
                        let arr = (MobileNoList || "").split(",") || [] || [];
                        arr.splice(i, 1);
                        ChangeMobileNo({ extraMobileNo: arr.join(",") });
                      }}
                    />
                  )}{" "}
                </a>
              </div>
            ))}
            <div>
              {edit ? (
                <>
                  <a onClick={() => setOpen(true)}>新增</a>
                  &emsp;
                  <a onClick={() => setEdit(false)}>完成</a>
                </>
              ) : (
                <a onClick={() => setEdit(true)}>编辑</a>
              )}
            </div>
          </div>
        }
        title={null}
        trigger="hover"
      >
        <a>{((MobileNoList || "").split(",") || [])[0]}（{((MobileNoList || "").split(",") || []).length} ）</a>
      </Popover>
        : <a onClick={() => setOpen(true)}>- 新增</a>}
      <Modal title="补充电话" open={open} onOk={handleOk} onCancel={() => setOpen(false)} confirmLoading={confirmLoading}>
        <Form {...{ labelCol: { span: 6 }, wrapperCol: { span: 16 } }} form={form}>
          <Form.Item
            name="extraMobileNo"
            label="手机号"
            rules={[
              {
                required: true,
                message: "联系电话不能为空",
              },
              {
                message: "联系电话不合法",
                pattern: /^((1[0-9]{2})+\d{8})$/,
              },
            ]}
          >
            <Input placeholder="请输入该客户的其他手机号码" maxLength={11} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
const EditModule = ({ children, refreshTable = () => { }, clueId, legalCloudId, type }) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const handleOk = () => {
    form
      .validateFields()
      .then(async values => {
        values.clueId = clueId;
        values.legalCloudId = legalCloudId;
        if (values.category) {
          values.firstCategory = values.category[0];
          values.secondCategory = values.category[1];
          values.thirdCategory = values.category[2];
          delete values.category;
        }
        try {
          setConfirmLoading(true);
          let res = await Axios("post", "/brokeruser/edit/info", values);
          if (res.errorCode == "200" || res.isSuccess == "T") {
            message.success(res.errorMsg);
            setOpen(false);
            refreshTable();
            form.resetFields();
          } else {
            message.error(res.errorMsg);
          }
          setConfirmLoading(false);
        } catch (err) {
          message.error(err.errorMsg);
          setConfirmLoading(false);
        }
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };
  return (
    <>
      <a onClick={() => setOpen(true)}>编辑 </a>
      <Modal title="编辑客户信息" open={open} onOk={handleOk} onCancel={() => setOpen(false)} confirmLoading={confirmLoading}>
        <Form {...{ labelCol: { span: 6 }, wrapperCol: { span: 16 } }} form={form}>
          {type == 1 ? (
            <CareaSelect form={form} label="企业经营地" province="province" city="city" rules={true} />
          ) : type == 2 ? (
            <Form.Item
              name="category"
              label="所属行业"
              rules={[
                {
                  required: true,
                  message: "所属行业不能为空",
                },
              ]}
            >
              <Cascader options={industryLevel3} placeholder="请选择" />
            </Form.Item>
          ) : type == 3 ? (
            <Form.Item
              name="customerLevel"
              label="客户等级"
              rules={[
                {
                  required: true,
                  message: "客户等级不能为空",
                },
              ]}
            >
              <Select placeholder="请选择" allowClear style={{ width: "100%" }}>
                <Option value="A" key="A">
                  A（关键客户）
                </Option>
                <Option value="B" key="B">
                  B（重点客户）
                </Option>
                <Option value="C" key="C">
                  C（一般客户）
                </Option>
                <Option value="D" key="D">
                  D（低价值客户）
                </Option>
              </Select>
            </Form.Item>
          ) : (
            ""
          )}
        </Form>
      </Modal>
    </>
  );
};
export { Shengpi2, Tables, ContactList, LegalCaseRecoveriesDetail, FollowUpRecords, FollowConversion, EditMobileNo, EditModule };
