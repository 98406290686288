/**
 * Created by Administrator on 2016/7/2.
 */
// 初始化状态
let info={};
if(localStorage.getItem('legal_operInfo')){
    info=JSON.parse(window.localStorage.getItem('legal_operInfo'))
}
let operAuth={};
if(localStorage.getItem('legal_operAuth')){
    operAuth=JSON.parse(window.localStorage.getItem('legal_operAuth'))
}

let userInfoState = {
    ...info,
    ...operAuth,
}

// console.log(JSON.parse(window.localStorage.getItem('operInfo')))
export function operInfo(state = userInfoState, action) {
    switch (action.type) {
        case 'change_operInfo':
            localStorage.setItem('legal_operInfo',JSON.stringify(action.operInfo))
            return {
                ...state,   //三个点是展开符
                ...action.operInfo
            }
        case 'change_operAuth':
            localStorage.setItem('legal_operAuth',JSON.stringify(action.operAuth))
            return {
                ...state,   //三个点是展开符
                ...action.operAuth
            }
        default:
            return {...state};
    }
}
