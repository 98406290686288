import React, { Component } from 'react';
import {
  Row,
  Col,
  Breadcrumb,
  Button,
  Form,
  Input,
  Table,
  message,
  Modal,
  Radio,
  notification,
  Select,
  Card,
  Empty,
  Dropdown,
  Popconfirm,
  Divider,
  TreeSelect, Drawer,
  Tooltip, Carousel,
  Popover,
  List,
  Avatar,
  Alert,
  Calendar,
  Tabs
} from 'antd';
import filter from "../../common/filter";
import { Axios } from '../../axios';
import { connect } from 'react-redux';
import "./workTable.scss";
import headPhoto from "../../assets/images/headPhoto.png"
import gold from "../../assets/images/gold.png"
import silver from "../../assets/images/silver.png"
import copper from "../../assets/images/copper.png"
import { CloseOutlined } from '@ant-design/icons'

const Option = Select.Option;
const { confirm } = Modal;

class workTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data1: '',
      data2: [],
      timeCode2: '5',
      data3: {},
      rank0: [],
      rank1: [],
      rank2: [],
      rank6: [],
      timeCodeRank: '3',
      DrawerOpen: false,
      BrokerUserInfo: {},
    }
  }

  componentDidMount () {
    this.getData1();
    this.getData2();
    this.getData3();
    this.getData6();
    this.getRank();
    this.GetUserInfo();
  }

  // 概览
  getData1 = async () => {
    try {
      let res = await Axios('get', '/index/month/success', {})
      if (res.code == '200') {
        this.setState({
          data1: res.data
        })
      } else {
        message.error(res.message);
      }
    } catch (err) {
      // message.error(err.message);
    }

  }
  GetUserInfo = async () => {
    try {
      const res = await Axios("get", "/brokeruser/info", {});
      if (res.code == "200") {
        this.setState({
          BrokerUserInfo: res.data || {},
        });
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };
  // 销售简报
  getData2 = async () => {
    try {
      let res = await Axios('get', '/index/report', {
        timeCode: this.state.timeCode2
      })
      if (res.code == '200') {
        this.setState({
          data2: res.data.saleReportTotalVOS
        })
      } else {
        message.error(res.message);
      }
    } catch (err) {
      // message.error(err.message);
    }
  }
  // 公告
  getData6 = async () => {
    try {
      let res = await Axios('get', '/brokeruser/notice', {})
      if (res.errorCode == '200' || res.isSuccess == 'T') {
        this.setState({
          rank6: res.body || []
        }, () => {
          let id = localStorage.getItem('brokerIds')
          let data = (res.body || []).find(v => v.isEject == 1) || {}, notice = JSON.parse(localStorage.getItem('notice' + id) || '{}')
          if (data.id && ((data.id != notice.id) || (data.id == notice.id && data.ejectTime != notice.ejectTime))) {
            localStorage.setItem('notice' + id, JSON.stringify(data))
            this.OpenRank(data)
          }
        })
      } else {
        message.error(res.message);
      }
    } catch (err) {
      // message.error(err.message);
    }
  }
  // 销售简报
  getData3 = async () => {
    try {
      let res = await Axios('get', '/index/todo', {})
      if (res.code == '200') {
        this.setState({
          data3: res.data
        })
      } else {
        message.error(res.message);
      }
    } catch (err) {
      // message.error(err.message);
    }

  }
  getRank = () => {
    this.getData4(0);
    this.getData4(1);
    this.getData4(2);
  }
  // 排行榜
  getData4 = async (rankType) => {
    try {
      let res = await Axios('get', '/index/rank', {
        rankType,
        timeCode: this.state.timeCodeRank
      })
      if (res.code == '200') {
        let obj = {}
        obj['rank' + rankType] = res.data
        this.setState(obj)
      } else {
        message.error(res.message);
      }
    } catch (err) {
      // message.error(err.message);
    }
  }

  getLanguage = () => {
    let str = "欲望以提升热忱，毅力以磨平高山"
    var myDate = new Date();


    if (localStorage.getItem('jilihuashuTime') == myDate.getDate()) {
      str = localStorage.getItem('jilihuashu');


    } else {
      localStorage.setItem('jilihuashuTime', myDate.getDate());
      str = window.language[Math.floor(Math.random() * window.language.length)];
      localStorage.setItem('jilihuashu', str);


    }

    return str
  }
  goCustomer = (num, str, type) => {
    if (!num) {
      return
    }
    if ([3, 4].includes(type)) {
      window.HashHistory.push('/RenewCompanyManage');
      window.routerCache.RenewCompanyManage.workTypeStr = type == 4 ? '1' : str
      window.routerCache.RenewCompanyManage.type = type == 4 ? '1' : undefined;
      return
    }
    if ([1, 2].includes(type)) {
      window.routerCache.EmployCompanyManage.workTypeStr = str;
      window.routerCache.EmployCompanyManage.workType_queryType = type;
    }
    window.HashHistory.push('/EmployCompanyManage');
  }
  OpenRank = (value) => {
    confirm({
      className: 'notice',
      title: <h3> {value.noticeTitle || '-'}  {value.roleSign == 1 && <span style={{ position: 'absolute', top: '20px', left: '-2px', transform: 'rotate(-45deg)', fontSize: '12px', color: '#333333', background: 'rgb(237,246,252)', }}>仅老板可见</span> || ''}</h3>,
      content: <div>
        <p style={{ margin: '-2px 0 32px', color: '#AAAAAA', display: 'flex', justifyContent: 'space-evenly' }}> <span>总部云法务</span>  {(value.publishTime).replace('T', ' ')}</p>
        <div style={{ maxHeight: '600px', minHeight: '400px', overflow: 'auto', whiteSpace: 'pre-wrap' }} className="ql-editor" dangerouslySetInnerHTML={{
          __html: value.noticeContent,
        }} />
      </div>,
      width: '55%',
      icon: null,
      cancelButtonProps: null,
      maskClosable: true,
      okText: "我已知晓",
      onOk () { console.log('OK') },
      onCancel () { console.log('Cancel') },
    });
  }
  CloseDrawer = () => {
    this.setState({ DrawerOpen: false })
  }
  render () {
    const operInfo = this.props.operInfo;
    const colorArr = ["#FFA500", "#30BD73", "#00a0a0", "#1890ff", "#f68811", "#F57375", "#30BD73"];

    let columns = [{
      title: '手机号',
      dataIndex: 'mobileNo',
      key: '3',
    }, {
      title: '姓名',
      dataIndex: 'userName',
      key: '4',
    }]

    const { data3, rank6, DrawerOpen, BrokerUserInfo } = this.state;
    return (
      <div className="pageContainer workTable">
        <Row className="headNav" style={{ width: '100%' }}>
          {(BrokerUserInfo.topNotice || '') && <Alert message={BrokerUserInfo.topNotice || ''} type="info" style={{ width: "100%" }} />}
          <Row type="flex" justify="space-between" style={{ width: '100%' }}>
            <Row className='title' type="flex" >
              <Avatar src={operInfo.avatar ? operInfo.avatar : headPhoto} size={55}
                style={{ fontSize: "26px" }}>{operInfo.realName && operInfo.realName.substr(0, 1)}</Avatar>
              <div style={{ padding: "0 15px", flex: '1', display: 'flex', alignItems: 'center' }}>
                <div className="tip" style={{ marginTop: "-6px" }}> {this.getLanguage()}</div>
              </div>
            </Row>
            <Row type="flex" className="right">
              <div style={{ paddingTop: "18px" }}>
                <span className="jc">本月概览</span>
              </div>
              <div className="line"></div>
              <div style={{ paddingTop: "6px" }}>
                <span>时间进度(工作日)</span>
                <p><span
                  className="jc">{filter.getweekDays().nowNum}</span> / {filter.getweekDays().total}天
                </p>
              </div>
              <div className="line"></div>
              <div style={{ paddingTop: "6px" }}>
                <span>成交单数</span>
                <p><span className="jc">{this.state.data1}</span></p>
              </div>
            </Row>
          </Row>
        </Row>
        <div className='scrollContainer'>
          <div className="scrollMain">
            <div style={{ display: 'flex', height: '410px' }}>
              <div style={{ borderRight: '1px solid #e8e8e8', flex: '1' }}>
                {rank6 && rank6.length ? <Row>
                  <div className="middleTitle" style={{
                    borderBottom: '1px solid rgb(232, 232, 232)', width: '98%',
                    display: 'flex', justifyContent: 'space-between', alignItems: 'center'
                  }}>
                    <span className="jc">系统公告</span>
                    <span className='js' onClick={() => this.setState({ DrawerOpen: true })} >更多{'>'}</span>
                  </div>
                  <div style={{ background: 'white', margin: '12px 0 24px' }} className='noticeDiv'>
                    <Carousel autoplay={true} dots={false} vertical={true}>
                      {rank6.map((item, index) => (
                        <div key={index} className='CarouselDiv' onClick={() => this.OpenRank(item)}>
                          {item.id}.&nbsp;&nbsp;<span>{item.noticeTitle || '-'}</span> {(item.publishTime || '').replace('T', ' ')}
                        </div>
                      ))}
                    </Carousel>
                  </div>
                  <Drawer title="系统公告"
                    extra={<CloseOutlined onClick={this.CloseDrawer} style={{ cursor: 'pointer' }} />}
                    destroyOnClose placement="right" closable={false} width='45%' onClose={this.CloseDrawer} open={DrawerOpen}>
                    <List
                      itemLayout="horizontal"
                      className='rank6List'
                      dataSource={rank6}
                      pagination={((rank6 || []).length > 10) ? { pageSize: 10 } : null}
                      renderItem={(item) => (
                        <List.Item onClick={() => {
                          this.OpenRank(item)
                          this.CloseDrawer()
                        }}>
                          <List.Item.Meta
                            title={item.noticeTitle}
                            description={(item.publishTime || '').replace('T', ' ')}
                          />
                        </List.Item>
                      )}
                    />
                  </Drawer>
                  <style>{`
                                        .noticeDiv .slick-list {
                                            height: ${22 * (rank6.length > 5 ? 5 : rank6.length)}px !important;
                                        }
                                    `}</style>
                </Row> : ''}
                <Row type="flex">
                  <Row type="flex" justify="space-between">
                    <div className="middleTitle" style={{ paddingBottom: '20px' }}>
                      <span className="jc">销售简报</span>
                    </div>

                    <div style={{ marginRight: "15px", marginLeft: '30px' }}>
                      <Select defaultValue="5" style={{ width: 120 }} onChange={(e) => {
                        this.setState({
                          timeCode2: e
                        }, this.getData2)
                      }}>
                        <Option value="1">今天</Option>
                        <Option value="2">昨天</Option>
                        <Option value="3">本周</Option>
                        <Option value="4">上周</Option>
                        <Option value="5">本月</Option>
                        <Option value="6">上月</Option>
                        <Option value="7">本季度</Option>
                        <Option value="8">上季度</Option>
                        <Option value="9">今年</Option>
                      </Select>
                    </div>
                  </Row>
                </Row>
                <Row type="flex" style={{ minHeight: "120px" }}>
                  <Row style={{ width: "100%" }}>
                    {this.state.data2.map((item, index) => <Col span={6} style={{ marginBottom: '15px' }} key={index}>
                      <Card style={{
                        margin: '0px 15px 15px 0px',
                        backgroundColor: colorArr[index],
                        color: "white",
                        paddingTop: "25px",
                        paddingBottom: "10px"
                      }} hoverable onClick={() => {

                      }}>
                        <div className="custom-top-det">
                          <div className='custom-top-name'>
                            <div className='custom-top-name-det'
                              style={{ color: "white" }}>
                              <span style={{ color: "white" }}>{item.reportType}</span>
                            </div>
                            <div className='custom-top-name-mum'
                              style={{ color: "white" }}>
                              {item.count || ''}
                            </div>
                          </div>
                        </div>
                      </Card>
                    </Col>)}
                  </Row>
                </Row>
              </div>
              <div style={{ width: '330px', height: '100%' }}>
                <Row type="flex">
                  <div className="middleTitle" style={{ marginRight: "120px" }}>
                    <span className="jc" style={{ paddingLeft: '18px' }}>待办事项</span>
                  </div>
                </Row>
                <Divider style={{ marginBottom: '30px' }}></Divider>
                <Row style={{ width: "100%", height: '340px', overflow: 'auto' }} type='flex'
                  justify='space-between'>
                  <Col style={{ width: '50%', padding: '0 30px 30px 30px' }}>
                    <div style={{ cursor: 'pointer' }} onClick={() => {
                      this.goCustomer(data3.applyTrialCount, '申请试用客户', 1)
                    }}>
                      <div style={{ fontSize: '14px', color: '#666' }}>申请试用客户</div>
                      <div style={data3.applyTrialCount ? {
                        fontSize: '30px',
                        color: '#1890ff'
                      } : { fontSize: '30px', color: '#666' }}>{data3.applyTrialCount}</div>
                    </div>
                  </Col>
                  <Col style={{ width: '50%', padding: '0 30px 30px 30px' }}>
                    <div style={{ cursor: 'pointer' }} onClick={() => {
                      this.goCustomer(data3.waitDealCount, '待成交客户', 2)
                    }}>
                      <div style={{ fontSize: '14px', color: '#666' }}>待成交客户</div>
                      <div style={data3.waitDealCount ? {
                        fontSize: '30px',
                        color: '#1890ff'
                      } : { fontSize: '30px', color: '#666' }}>{data3.waitDealCount}</div>
                    </div>
                  </Col>
                  <Col style={{ width: '50%', padding: '0 30px 30px 30px' }}>
                    <div style={{ cursor: 'pointer' }} onClick={() => {
                      this.goCustomer(data3.adoptCount, '待认领客户', 3)
                    }}>
                      <div style={{ fontSize: '14px', color: '#666' }}>待认领客户</div>
                      <div style={data3.adoptCount ? {
                        fontSize: '30px',
                        color: '#1890ff'
                      } : { fontSize: '30px', color: '#666' }}>{data3.adoptCount}</div>
                    </div>
                  </Col>
                  <Col style={{ width: '50%', padding: '0 30px 30px 30px' }}>
                    <div style={{ cursor: 'pointer' }} onClick={() => {
                      this.goCustomer(data3.waitNewCount, '待续费客户', 4)
                    }}>
                      <div style={{ fontSize: '14px', color: '#666' }}>待续费客户</div>
                      <div style={data3.waitNewCount ? {
                        fontSize: '30px',
                        color: '#1890ff'
                      } : { fontSize: '30px', color: '#666' }}>{data3.waitNewCount}</div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            {/*排行榜*/}
            <Row style={{ marginTop: "30px" }} className="ranking">
              <Row type="flex" justify="space-between" style={{ width: '100%' }}>
                <div className="middleTitle">
                  <span className="jc">排行榜</span>
                </div>
                <div style={{ marginRight: "50px" }}>
                  <Select defaultValue="3" style={{ width: 120 }} onChange={async (e) => {
                    this.setState({
                      timeCodeRank: e
                    }, this.getRank)
                  }}>
                    <Option value="3">本周</Option>
                    <Option value="4">上周</Option>
                    <Option value="5">本月</Option>
                    <Option value="6">上月</Option>
                    <Option value="7">本季度</Option>
                    <Option value="8">上季度</Option>
                    <Option value="9">今年</Option>
                  </Select>
                </div>
              </Row>
              <Divider />
              <Row type="flex" style={{ paddingTop: "30px", paddingLeft: "30px", paddingBottom: '30px', width: '100%' }}>
                <Col span={8}>
                  <p className="bold">试用客户</p>
                  {this.state.rank0.length ? this.state.rank0.map((val, i) => {
                    return <Row key={i} type="flex" style={{ marginBottom: "15px", position: 'relative' }}>
                      <Avatar className="rangeNumBg">{i + 1}</Avatar>
                      <div className="rankName">{val.userName || '暂无姓名'}</div>
                      <div className="icon_icon"> <img src={i == 0 ? gold : i == 1 ? silver : i == 2 ? copper : ""} /> </div>
                      <div className="num">{val.count}</div>
                    </Row>
                  }) : <Row type="flex" style={{ marginLeft: "13px" }}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </Row>}
                </Col>

                <Col span={8}>
                  <p className="bold">成交客户</p>
                  {this.state.rank1.length ? this.state.rank1.map((val, i) => {
                    return <Row key={i} type="flex" style={{ marginBottom: "15px", position: 'relative' }}>
                      <Avatar className="rangeNumBg">{i + 1}</Avatar>
                      <div className="rankName">{val.userName || '暂无姓名'}</div>
                      <div className="icon_icon"> <img src={i == 0 ? gold : i == 1 ? silver : i == 2 ? copper : ""} /> </div>
                      <div className="num">{val.count}</div>
                    </Row>
                  }) : <Row type="flex" style={{ marginLeft: "13px" }}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </Row>}
                </Col>
                <Col span={8}>
                  <p className="bold">跟进次数</p>
                  {this.state.rank2.length ? this.state.rank2.map((val, i) => {
                    return <Row key={i} type="flex" style={{ marginBottom: "15px", position: 'relative' }}>
                      <Avatar className="rangeNumBg">{i + 1}</Avatar>
                      <div className="rankName">{val.userName || '暂无姓名'}</div>
                      <div className="icon_icon"> <img src={i == 0 ? gold : i == 1 ? silver : i == 2 ? copper : ""} /> </div>
                      <div className="num">{val.count}</div>
                    </Row>
                  }) : <Row type="flex" style={{ marginLeft: "13px" }}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </Row>}
                </Col>

              </Row>
              <style>
                {`
                                .ant-empty-normal {
                                    margin: 0px !important; 
                        
                                }
                                .ant-divider-horizontal{
                                    margin: 0px 0;
                                }
                                .ant-tabs-bar {
                                    margin: 0 0 16px 0;
                                   border-bottom: none; 
                                }
                                .ant-card-body {
                                    padding: 24px;
                                    padding-top: 5px;
                                    padding-bottom: 0px;
                                    zoom: 1;
                                }
                            `}
              </style>
            </Row>
          </div>
        </div>
      </div>
    )
  }
}

export default connect((state) => {
  return state
})(workTable)
