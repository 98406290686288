
// 初始化状态
let initStatus = {
    departList: [],
    operation: {
        getDeptNameById: (deptId) => {
            return false;
        }
    }
}
export function team(state = initStatus, action) {
    switch (action.type) {
        case 'GetDepartList':
            return {
                ...state,
                departList: action.value,
                operation: {
                    getDeptNameById: (deptId) => {
                        let depart = {};
                        action.value.forEach((item) => {
                            if (item.deptId == deptId) {
                                depart = item;
                                return
                            }
                        });
                        return depart
                    }
                }
            }
        default:
            return { ...state };
    }
}