import React from "react";
import {
    Table,
    Select,
    Form,
    Row,
    Input,
    Col,
    Button,
    message,
    Switch,
    Radio,
    Upload,
    Checkbox,
    Breadcrumb,
    notification,
    Modal,
    TreeSelect,
    Popconfirm
} from 'antd';
import { Axios } from "../../axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import * as tabWindowActions from "../../redux/actions/tabwindow";
import { Link, HashRouter } from "react-router-dom";
import { AreaSelect } from "../../common";
import { AreaSelectForm, ImgViewer, popoverTable, rules } from "../../common";
import { UploadOutlined } from '@ant-design/icons';

import filter from "../../common/filter";
import time from "../../common/filter";

const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;
const Option = Select.Option;
const FormItem = Form.Item;
let confirmLoadings = { len: 0 };
let Timer = '';
let Timer2 = '';
let custList = [];
let creditorList = [];
let selectCustId = '', selectDebtorCustId = '';
let timeout;
let currentValue;
let docKind_applyDoc = '';

function fetch(value, callback) {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    currentValue = value;

    async function fake() {
        let res = await Axios("get", "/caseBase/caseReson", {
            caseReasonName: value,
            pageNum: "1",
            pageSize: "10",
        });
        console.log(res.body)
        if (res.code === "00") {
            callback(res.body);
        }
    }

    timeout = setTimeout(fake, 300);
}

class MainM extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingList: false,
            companyInfo: {},
            bizOperHis: [],
            trailCompanyVO: {},
            province: '',
            city: '',
            dataList: [],
            county: '',
            StatusList: [],
            show_caseNo: false,
            channelInfos: [],
            show_referCaseNo: false,
            applyUser: '',
            isBatch: false,
            caseStages:
                [{ pageNum: null, pageSize: null, caseStageId: 1, caseStageName: "未起诉", status: "0" },
                { pageNum: null, pageSize: null, caseStageId: 2, caseStageName: "已起诉尚未判决", status: "0" },
                { pageNum: null, pageSize: null, caseStageId: 3, caseStageName: "一审", status: "0" },
                { pageNum: null, pageSize: null, caseStageId: 4, caseStageName: "二审", status: "0" },
                { pageNum: null, pageSize: null, caseStageId: 5, caseStageName: "已判决尚未执行", status: "0" },
                { pageNum: null, pageSize: null, caseStageId: 6, caseStageName: "正在强制执行", status: "0" },
                { pageNum: null, pageSize: null, caseStageId: 7, caseStageName: "终结本次执行", status: "0" },
                { pageNum: null, pageSize: null, caseStageId: 8, caseStageName: "商事仲裁中", status: "0" },
                { pageNum: null, pageSize: null, caseStageId: 9, caseStageName: "其他", status: "0" },]
        };
    }

    componentDidMount() {
        const { userRole } = this.props.operInfo;
        const job = window.globalData.job
        this.channelInfos()
        if (userRole === "1" || job == 'senior') {
            Axios("get", "/brokeruser/page", { status: "0", }).then((val) => {
                this.setState({
                    StatusList: (val.data || {}).dataList || [],
                });
            });
        }
    }

    checkcaseNo = (caseStage) => {
        let show_caseNo = false, show_referCaseNo = false;
        if (caseStage == '2' || caseStage == '3' || caseStage == '4' || caseStage == '5') {
            show_referCaseNo = true
        } else if (caseStage == '6' || caseStage == '7') {
            show_referCaseNo = true;
            show_caseNo = true;
        }
        this.setState({ show_caseNo, show_referCaseNo })
    }

    onChange_batchCaseSign = (e) => {
        if (e) {
            this.setState({ isBatch: e })
        } else {
            this.setState({ isBatch: e })
        }
    }

    handleSubmit = async () => {
        console.log(this.state.province)
        this.formRef.current.
            validateFields(['appid', 'diagnosisSign', 'caseBizMode', 'applyUserType', 'mobileNo'
                , 'applyUserName', 'idType', 'idNo', 'caseReasonName', 'creditorName', 'agentSide',
                'debtorName', 'caseAmt', 'caseStageId', 'referCaseNo', 'caseNo', 'courtName', 'caseMemo', 'businessUserId'])
            .then(async (values) => {
                let sendData = values;
                if (sendData.appid == 'offline') {
                    sendData.appid = "BROKER_CRM"
                }
                try {
                    this.setState({
                        loading: true,
                    });
                    if (this.state.province) {
                        sendData.creditProvince = this.state.province;
                    } else {
                        message.info('请选择省');

                        return
                    }
                    if (this.state.city) {
                        sendData.creditCity = this.state.city;
                    } else {
                        message.info('请选择市');

                        return
                    }
                    if (this.state.county) {
                        sendData.creditCounty = this.state.county;
                    } else {
                        message.info('请选择区');

                        return
                    }
                    sendData.saleOperId = 60;
                    sendData.saleOperName = '魏晓娟';
                    console.log(sendData)
                    let res = await Axios(
                        "post",
                        "/caseBase",
                        sendData
                    );
                    if (res.code === "200") {
                        message.success(res.message);
                        this.props.history.push('/CaseManagement')
                    } else {
                        message.error(res.message);
                    }
                    this.setState({ loading: false });

                } catch
                (err) {
                    // message.error(err);
                    this.setState({ loading: false });
                }
            })
            .catch((info) => {
                console.log('Validate Failed:', info)
            })
    }
        ;
    handleChange = (value) => {
        this.setState({ value });
    };
    handleSearch = (value) => {
        if (value) {
            fetch(value, (dataList) => this.setState({ dataList }));
        } else {
            this.setState({ dataList: [] });
        }
    };
    channelInfos = () => {
        Axios("get", "/caseBase/base/data", {}).then((val) => {
            this.setState({
                channelInfos: (val.body || {}).channelInfos || [],
            });
        });
    }
    applyUserTypeFun = (value) => {
        this.setState({
            applyUser: value
        })
        console.log(value, 'valuevaluevalue');
    }

    render() {
        const cStyle = {
            // padding: "12px 24px 12px 24px",
            paddingTop: "12px",
            marginBottom: "15px",
            borderRadius: "4px",
            background: "#fff",
            fontSize: "14px",
        };
        const tStyle = {
            color: "#fff",
            fontSize: "16px",
            width: "100%",
            padding: "12px 24px 12px 24px",
            borderBottom: "1px solid #efe5e5",
            background: "#797992"
        };
        const titleStyle = {
            fontSize: '16px',
            color: 'rgba(0,0,0,0.85)',
            fontWeight: '700',
            lineHeight: '40px',
            paddingLeft: '20px'
        };

        const leftLine = {
            display: 'flex',
            width: '580px'
        };
        const dStyle = {
            color: "#222",
            lineHeight: "30px",
            margin: "10px 0px 0px 0px",
        };
        const { companyInfo, trailCompanyVO, StatusList } = this.state;
        const props = {
            name: 'file',
            action: window.CMS_systemUrl + '/addCasesByXls',
            data: {
                diagnosisSign: '1'
            },
            headers: { "Auth": "Bearer" + localStorage.getItem('token') },
            onChange: this.changeFile,
            onRemove: () => {
                return false
            },
            fileList: this.state.fileList
        };
        return (
            <div className="pageContainer">
                <Row className="headNav">
                    <Col span="24">
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>
                                <Link to="/CaseManagement">
                                    案件管理
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>添加案件</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span="24" className="title">
                        <span className="name">添加案件</span>
                    </Col>
                </Row>

                <div className="scrollContainer">
                    <div style={tStyle}>添加案件
                    </div>
                    <div style={cStyle}>

                        <Form
                            layout="horizontal"
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 16 }}
                            ref={this.formRef}
                        >
                            <Row>
                                <Col span="12">
                                </Col>
                                <Col span="12">
                                    <div style={{ paddingLeft: '50px', paddingTop: '2px', display: 'flex' }}>
                                        <div style={this.state.isBatch ? {} : { display: 'none' }}>
                                            <a style={{ lineHeight: '30px', paddingRight: '16px' }}
                                            // href={window.globalVariables.fileTemplate.addCase}
                                            >Excel模板下载</a>
                                            <Upload {...props}>
                                                <Button>
                                                    <UploadOutlined />
                                                    导入Excel
                                                </Button>
                                            </Upload>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                            <div style={titleStyle}>申请信息</div>
                            <Row
                            >
                                <Col span="12">
                                    <FormItem label="案件来源：" style={{ width: "100%" }}
                                        name="appid"
                                        initialValue={'offline'}
                                        rules={[
                                            {
                                                required: true,
                                                message: '请选择案件来源'
                                            }
                                        ]}
                                    >

                                        <Select
                                            placeholder="请选择"
                                            disabled
                                            allowClear
                                            style={{ width: "37%" }}
                                        >
                                            <Option key='online'>线上</Option>
                                            <Option key='offline'>线下</Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span="12">
                                    <FormItem label="案件类型：" style={{ width: "100%" }}
                                        name="diagnosisSign"
                                        initialValue={'1'}
                                        rules={[
                                            {
                                                required: true,
                                                message: '案件诊断类型不能为空'
                                            }
                                        ]}
                                    >
                                        <RadioGroup style={{ width: "80%" }}>
                                            <RadioButton value='1'>一般案件</RadioButton>
                                        </RadioGroup>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="12">
                                    <FormItem label="案件业务模式：" style={{ width: "100%" }}
                                        name="caseBizMode"
                                        rules={[
                                            {
                                                required: true,
                                                message: '请选择业务模式'
                                            }
                                        ]}
                                    >
                                        <Select placeholder="请选择业务模式" allowClear style={{ width: "80%" }}>
                                            <Option key='1'>诉讼</Option>
                                            <Option key='3'>执行</Option>
                                            <Option key='5'>债权收购</Option>
                                            <Option key='6'>非诉清收</Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span="12">
                                    <FormItem label="申请人类型：" style={{ width: "100%" }}
                                        name="applyUserType"
                                        rules={[
                                            {
                                                required: true,
                                                message: '申请人类型不能为空'
                                            }
                                        ]}
                                    >
                                        <Select placeholder="请选择申请人类型" allowClear style={{ width: "80%" }}
                                            onChange={this.applyUserTypeFun}
                                        >

                                            <Option key='0' value='0'>当事人</Option>
                                            <Option key='2' value='2'>合作商</Option>
                                            <Option key='1' v3ue='1'>律师</Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                            </Row> <Row>
                                <Col span="12">
                                    <FormItem label="手机号：" style={{ width: "100%" }}
                                        name="mobileNo"
                                        rules={[
                                            {
                                                required: true,
                                                message: '手机号不能为空'
                                            }, {
                                                message: '手机号不合法',
                                                pattern: /^1\d{10}$/
                                            }
                                        ]}
                                    >
                                        <Input placeholder="请输入手机号" type="text"
                                            style={{ width: "80%" }}
                                            maxLength='11' />
                                    </FormItem>
                                </Col>
                                <Col span="12">
                                    <FormItem label="申请人姓名：" style={{ width: "100%" }}
                                        name="applyUserName"
                                        rules={[
                                            {
                                                required: true,
                                                message: '申请人姓名不能为空'
                                            }
                                        ]}
                                    >
                                        <Input placeholder="请输入申请人姓名" type="text" style={{ width: "80%" }} />
                                    </FormItem>
                                </Col>
                            </Row> <Row>
                                <Col span="12">
                                    <FormItem label={<span><span style={{ opacity: 0 }}>1</span>证件类型</span>}
                                        style={{ width: "100%" }}
                                        name="idType"
                                    >
                                        <Select placeholder="请选择证件类型" allowClear style={{ width: "80%" }}>
                                            <Option key='1' value='1'>身份证</Option>
                                            <Option key='2' value='2'>营业执照</Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span="12">
                                    <FormItem label={<span><span style={{ opacity: 0 }}>1</span>证件号</span>}
                                        style={{ width: "100%" }}
                                        name="idNo"
                                        rules={[
                                            {
                                                required: false,
                                                message: '证件号不能为空'
                                            }
                                        ]}
                                    >
                                        <Input placeholder="请输入证件号" type="text" style={{ width: "80%" }} />
                                    </FormItem>
                                </Col>
                            </Row>
                            <div style={this.state.applyUser == '1' ? {
                                height: '64px',
                                overflow: 'hidden',
                                transition: 'all 0.3s'
                            } : { height: '0px', overflow: 'hidden', transition: 'all 0.3s' }}>
                                <Row>
                                    <Col span="12">
                                        <FormItem label='律所名称'
                                            style={{ width: "100%" }}
                                            name="lawfirmName"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: '证件号不能为空'
                                                }
                                            ]}
                                        >

                                            <Input placeholder="请输入律所名称" type="text" style={{ width: "80%" }} />
                                        </FormItem>
                                    </Col>     <Col span="12">

                                    </Col>

                                </Row>
                            </div>
                            <div style={titleStyle}>案件信息</div>
                            <div style={this.state.isBatch ? {
                                height: '0px',
                                overflow: 'hidden',
                                transition: 'all 0.3s',
                                display: 'none'
                            } : { height: '60px', overflow: 'hidden', transition: 'all 0.3s' }}>
                                <Row>

                                    <Col span="12">
                                        <Form.Item
                                            label="案由：" style={{ width: "100%" }}
                                            name="caseReasonName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '案由不能为空'
                                                }
                                            ]}
                                        >
                                            <Select
                                                style={{ width: "80%" }}
                                                showSearch
                                                allowClear
                                                placeholder="请输入"
                                                // defaultActiveFirstOption={false}
                                                showArrow={true}
                                                filterOption={false}
                                                onSearch={this.handleSearch}
                                                onChange={this.handleChange}
                                                notFoundContent={null}
                                            >
                                                {this.state.dataList.map((v) => (
                                                    <Option key={v.caseReasonName}
                                                        value={v.caseReasonName}>
                                                        {v.caseReasonName}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>


                                    </Col>
                                    <Col span="12">
                                        <FormItem label="原告：" style={{ width: "100%" }}
                                            name="creditorName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '原告不能为空'
                                                }
                                            ]}
                                        >
                                            <Input placeholder="请输入原告，多个原告用、隔开" type="text" style={{ width: "80%" }} />
                                        </FormItem>
                                    </Col>

                                </Row></div>
                            <Row>

                                <Col span="12">
                                    <div style={this.state.isBatch ? {
                                        height: '0px',
                                        overflow: 'hidden',
                                        transition: 'all 0.3s',
                                        display: 'none'
                                    } : { height: '60px', overflow: 'hidden', transition: 'all 0.3s' }}>
                                        <FormItem label="被告：" style={{ width: "100%" }}
                                            name="debtorName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '被告不能为空'
                                                }
                                            ]}
                                        >
                                            <Input placeholder="请输入被告，多个原告用、隔开" type="text" style={{ width: "80%" }} />
                                        </FormItem>
                                    </div>
                                </Col>

                                <Col span="12">
                                    <FormItem label={<span className="ant-form-item-required">原告所在地</span>}
                                        style={{ width: "100%" }}
                                        name="province"
                                        rules={[
                                            {
                                                required: true,
                                                message: '请选择地区'
                                            }
                                        ]}
                                    >
                                        <AreaSelect province={this.state.province} city={this.state.city}
                                            county={this.state.county}
                                            bodyStyle={{ width: "80%" }}
                                            select={(province, city, county) => {
                                                console.log(province, city, county)
                                                this.setState({
                                                    province: province ? province : '',
                                                    city: city ? city : '',
                                                    county: county ? county : '',

                                                })
                                            }} />
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="12">
                                    <div style={this.state.isBatch ? {
                                        height: '0px',
                                        overflow: 'hidden',
                                        transition: 'all 0.3s',
                                        display: 'none'
                                    } : { height: '60px', overflow: 'hidden', transition: 'all 0.3s' }}>
                                        <FormItem label="案件标的：" style={{ width: "100%" }}
                                            name="caseAmt"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '案件标的不能为空'
                                                }, rules.amount
                                            ]}
                                        >

                                            <Input placeholder="请输入案件标的" type="text" suffix="元"
                                                style={{ width: "80%" }} />
                                        </FormItem></div>
                                </Col>
                                <Col span="12">
                                    <div style={this.state.isBatch ? {
                                        height: '0px',
                                        overflow: 'hidden',
                                        transition: 'all 0.3s',
                                        display: 'none'
                                    } : { height: '60px', overflow: 'hidden', transition: 'all 0.3s' }}>
                                        <FormItem label="案件阶段：" style={{ width: "100%" }}
                                            name="caseStageId"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '案件阶段不能为空'
                                                }
                                            ]}
                                        >
                                            <Select style={{ width: "80%" }} placeholder="请选择案件阶段" allowClear
                                                onChange={this.checkcaseNo}>
                                                {this.state.caseStages ? this.state.caseStages.map((item, i) => {
                                                    return <Option disabled={item.status == '1' ? true : false}
                                                        key={i}
                                                        value={item.caseStageId + ''}>{item.caseStageName}</Option>
                                                }) : ''}
                                            </Select>
                                        </FormItem></div>
                                </Col>
                            </Row>

                            <div
                                style={(!this.state.isBatch) && (this.state.show_caseNo || this.state.show_referCaseNo) ? {
                                    height: '64px',
                                    overflow: 'hidden',
                                    transition: 'all 0.4s'
                                } : { height: '0px', overflow: 'hidden', transition: 'all 0.4s' }}>
                                <Row> <Col span="12">
                                    <div style={this.state.show_referCaseNo ? {} : {
                                        height: '0px',
                                        width: '0',
                                        overflow: 'hidden'
                                    }}>
                                        <FormItem label="诉讼案号" style={{ width: "100%" }}
                                            name="referCaseNo"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: '诉讼案号不能为空'
                                                }
                                            ]}
                                        >

                                            <Input placeholder="请输入诉讼案号" type="text" style={{ width: "80%" }} />
                                        </FormItem>
                                    </div>
                                </Col>
                                    <Col span="12">
                                        <div style={this.state.show_caseNo ? {} : {
                                            height: '0px',
                                            width: '0',
                                            overflow: 'hidden'
                                        }}>
                                            <FormItem label='执行案号' style={{ width: "100%" }}
                                                name="caseNo"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: '执行案号不能为空'
                                                    }
                                                ]}
                                            >
                                                <Input placeholder="请输入执行案号" type="text" style={{ width: "80%" }} />
                                            </FormItem>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <Row>
                                <Col span="12">
                                    <FormItem label="管辖法院：" style={{ width: "100%" }}
                                        name="courtName"
                                        rules={[
                                            {
                                                required: true,
                                                message: '管辖法院不能为空'
                                            }
                                        ]}
                                    >

                                        <Input placeholder="请输入管辖法院" type="text" style={{ width: "80%" }} />
                                    </FormItem>
                                </Col>
                                <Col span="12">
                                    <FormItem label="代理站位：" style={{ width: "100%" }}
                                        name="agentSide"
                                        initialValue={'Y'}
                                        rules={[
                                            {
                                                required: true,
                                                message: '案件诊断类型不能为空'
                                            }
                                        ]}
                                    >
                                        <RadioGroup style={{ width: "80%" }}>
                                            <RadioButton style={{ minWidth: "92px", textAlign: "center" }}
                                                value='Y'>原告</RadioButton>
                                            <RadioButton style={{ minWidth: "92px", textAlign: "center" }}
                                                value='B'>被告</RadioButton>
                                        </RadioGroup>
                                    </FormItem>
                                </Col>
                            </Row> <Row>
                                <Col span="12">
                                    <FormItem label='案件简介' style={{ width: "100%" }}
                                        name="caseMemo"
                                        rules={[
                                            {
                                                required: false,
                                                message: '请输入案情简介'
                                            }
                                        ]}
                                    >
                                        <Input placeholder="请输入案件简介" type="textarea" rows={3}
                                            style={{ width: "80%" }} />
                                    </FormItem>
                                </Col>
                                <Col span="12">

                                </Col>
                            </Row>
                            <div style={titleStyle}>案件信息</div>
                            <Row>
                                <Col span="12">
                                    <FormItem label="业务员" style={{ width: "100%" }}
                                        name="businessUserId"
                                        rules={[
                                            {
                                                required: true,
                                                message: '业务员不能为空'
                                            }
                                        ]}
                                    >
                                        <Select
                                            style={{ width: "80%" }}
                                            placeholder="请选择"
                                            allowClear
                                        >
                                            <Option
                                                key={this.props.userInfo.userId}
                                                value={
                                                    this.props.userInfo.userId
                                                }
                                            >
                                                {this.props.userInfo.userName || this.props.userInfo.brokerName || ''}
                                                （当前自己）
                                            </Option>
                                            {StatusList.map((v) => {
                                                if (this.props.userInfo.userId != v.userId) return (
                                                    <Option
                                                        key={v.userId}
                                                        value={v.userId}
                                                    >
                                                        {v.userName}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "100px" }}>
                                <Col span={4} style={{ textAlign: "right" }}>
                                    <Button>
                                        <Link to="/CaseManagement">
                                            返回
                                        </Link>
                                    </Button>
                                </Col>
                                <Col span={4} style={{ textAlign: "center" }}>
                                    <Button loading={this.state.loading} type="primary"
                                        onClick={this.handleSubmit}>确定</Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
                <style>{`
.ant-row-flex ant-row-flex-middle{
    width: 100%;
    height: 50px!important;
}
                `}</style>

            </div >
        )
    }
}

let
    MainN = MainM;
let
    Main = connect((state) => {
        return state;
    },
        (dispatch) => ({
            actions: bindActionCreators(dispatch),
        })
    )(MainN);
export default Main;
