
// 初始化状态
let initStatus = {
    showModal: false,
    msgNum:0,
    isRefresh:false
}

export function work(state = initStatus, action) {
    switch (action.type) {
        case 'CreateTastStatus':
            return {
                ...state,
                showModal: action.showModal
            }
        case 'MessageNum':
            return {
                ...state,
                msgNum: action.msgNum
            }
        case 'isRefreshCalendar':
            return {
                ...state,
                isRefresh: action.isRefresh
            }
        default:
            return {...state};
    }
}