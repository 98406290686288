/**
 * Created by hao.cheng on 2017/4/16.
 */


import axios from 'axios';
import {
  message
} from 'antd';

// 拦截器
// 防止重复点击设置
let cancel, promiseArr = {};
const CancelToken = axios.CancelToken;

// "请求" 拦截器,过滤重复的请求
axios.interceptors.request.use(config => {
  //发起请求时，取消掉当前正在进行的相同请求;
  if (promiseArr[config.url] && config.method == "post") {
    promiseArr[config.url]('操作取消')
    promiseArr[config.url] = cancel
  } else {
    promiseArr[config.url] = cancel
  }
  return config
}, error => {
  return Promise.reject(error)

})

let serverError = false
const errEcec = (fn) => {//系统错误提示，防止同时出现多次弹窗
  if (serverError) {
    return
  }
  fn()
  serverError = true;
  setTimeout(() => {
    serverError = false;
  }, 1500)
}
export const Axios = (method, resourcesName, data, type) => {
  console.log('%c(' + method + '):%c ' + resourcesName + ' %c(data):%c ' + JSON.stringify(data), "color:blue", "", "color:blue", "");
  let headerData = {
    "content-type": "application/json;charset=UTF-8",
    appid: 'BROKER_CRM',
    appVersion: '11.2.4',
    appPackage: 'com.winhc.mis.pc',
    sessionId: localStorage.getItem('sessionId') || '',
    brokerId: localStorage.getItem('brokerId') || '',
    // userId: (JSON.parse(localStorage.getItem('legal_operInfo') || '') || {}).userId,
  };

  let requestUrl = window.api_legal;
  if (type === 'down') {
    requestUrl = window.downloadCentersUrl;
  } else if (type === 'login') {
    requestUrl = window.api_login
  } else if (type === 'winhc-lawyer-service') {
    requestUrl = window.api_lawService
  } else if (type === 'lawyer-workbench') {
    requestUrl = window.api_lawWorkbench
  } else if (type === 'case-chance') {
    requestUrl = window.api_chance
  } else if (type === 'bigData') {
    requestUrl = window.system_bigData_requestUrl;
  } else if (type === 'fireflyErp') {
    requestUrl = window.firefly_erp;
  } else if (type === 'cloud-legal') {
    requestUrl = window.winhc_legal
  }

  // console.log(headerData)
  if (/\/undefined/.test(resourcesName)) {
    // errFn('undefined');
    return
  }
  let requestConfig = (method === 'get' || method === 'GET') ? {
    method: method,
    url: requestUrl + resourcesName,
    params: data,
    headers: headerData,
    cancelToken: new CancelToken(c => {
      cancel = c
    })
  } : {
    method: method,
    url: requestUrl + resourcesName,
    data: data,
    headers: headerData,
    cancelToken: new CancelToken(c => {
      cancel = c
    })
  };
  return new Promise((resolve, reject) => {
    axios(requestConfig).then(function (res) {
      console.log('%c(' + method + (type ? ' ' + type : '') + '):%c ' + resourcesName + ' %c(succ):', "color:green", '', "color:green", res);
      if (res.data) {
        resolve(res.data)
      } else {
        errEcec(() => {
          message.error('系统错误！');
        })
      }
    }).catch(function (error) {
      console.log('%c(' + method + '):%c ' + resourcesName + ' %c(err):', "color:red;font-weight:700;font-size:16px;", '', "color:red;font-weight:700;font-size:16px;", error);
      console.log(error.message)
      let _message = ""
      if (/status code 403/.test(error)) {
        _message = "权限不足";
        // message.error('权限不足');
        window.HashHistory.push('/403');
      } else if (/status code 401/.test(error)) {
        _message = "";
        if (!localStorage.getItem('sessionId')) {
          return
        }
        message.error('身份认证过期');
        localStorage.removeItem('sessionId') //防止出现很多个弹窗提示
        window.HashHistory.push('/login');
      } else if (/status code 500/.test(error)) {
        _message = "";
        message.error('系统繁忙');
        // window.HashHistory.push('/500');
      } else if (/status code 504/.test(error)) {
        _message = "网关超时";
        // message.error('网关超时');
      } else if (/status code 502/.test(error)) {
        _message = "系统升级中，请稍后重试";
        setTimeout(() => {
          message.error('系统升级中，请稍后重试');
        }, 700)
      } else if (/Network Error/.test(error)) {
        _message = "网络错误";
        message.error('网络错误');
      } else if (/status code 404/.test(error)) {
        _message = "访问错误";
        setTimeout(() => {
          message.error('访问错误');
        }, 700)
      } else {
        _message = "系统错误";
      }
      reject({ message: _message });
    });
  });
}
