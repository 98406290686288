export const change_brokerId = (status) => ({
  type: 'SetBrokerId',
  brokerId: status
})
export const change_brokerType = (status) => ({
  type: 'SetBrokerType',
  brokerType: status
})
export const change_job = (status) => ({
  type: 'SetBrokerJob',
  job: status
})

