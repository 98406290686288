import React, { Component } from "react";
import {
    Row,
    Col,
    message,
    Form,
    Input,
    Select,
    Divider,
    Tooltip,
    Spin,
    Table,
    Modal,
    Radio,
} from "antd";
import { Axios } from "../../axios";
import Filter from "../../common/filter";
import G2 from "@antv/g2";
import { rules } from "../../common";

const { Option } = Select;
const FormItem = Form.Item;
let TextName = {
    0: {
        title: "新增意向客户统计图（单位：个）",
        tr: "新增意向客户数",
        tr1: "意向客户总数",
        tr2: "意向客户个人情况",
    },
    1: {
        title: "新增付费客户统计图（单位：个）",
        tr: "新增付费客户数",
        tr1: "付费客户总数",
        tr2: "付费客户个人情况",
    },
    2: {
        title: "新增待续费客户统计图（单位：个）",
        tr: "新增待续费客户数",
        tr1: "待续费客户总数",
        tr2: "待续费客户个人情况",
    },
    3: {
        title: "新增续费客户统计图（单位：个）",
        tr: "新增续费客户数",
        tr1: "续费客户总数",
        tr2: "续费客户个人情况",
    },
    4: {
        title: "新增成交额统计图（单位：万元）",
        tr: "新增成交额",
        tr1: "成交额总数",
        tr2: "成交额个人情况",
    },
};
class Personal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allData: {},
            spinning: false,
        };
    }
    getData = async () => {
        try {
            this.setState({ spinning: true });
            let url = "/brokeruser/personal/customer/risk";

            if (this.props.saleCode == 4) {
                url = "/brokeruser/personal/amt/risk";
            }

            let res = await Axios("get", url, this.props.perData);
            if (res.code === "200") {
                if (
                    res.data.businessAnalysisAmtListPersonalVOS &&
                    res.data.businessAnalysisAmtListPersonalVOS.length
                ) {
                    res.data.businessAnalysisVOS =
                        res.data.businessAnalysisAmtListPersonalVOS.sort(
                            (a, b) => b.newCustomer - a.newCustomer
                        );
                }
                res.data.businessAnalysisVOS
                    .sort((a, b) => b.newCustomer - a.newCustomer)
                    .forEach((item, i) => {
                        item.index = i + 1;
                    });
                if (this.props.saleCode == 4) {
                    res.data.businessAnalysisVOS.push({
                        index: false,
                        name: "合计",
                        newAmt: res.data.newAmtSum,
                        totalAmt: res.data.totalAmtSum,
                        rate: res.data.totalRate,
                    });
                } else {
                    res.data.businessAnalysisVOS.push({
                        index: false,
                        name: "合计",
                        newCustomer: res.data.newCustomerSum,
                        totalCustomer: res.data.totalCustomerSum,
                        rate: res.data.totalRate,
                    });
                }

                this.setState(
                    {
                        allData: res.data,
                        chartStyle:
                            res.data.businessAnalysisVOS.length > 20
                                ? {
                                    width:
                                        150 +
                                        res.data.businessAnalysisVOS.length *
                                        60 +
                                        "px",
                                    margin: "0 auto",
                                }
                                : { overflow: "hidden" },
                    },
                    this.getChart
                );
                console.log(res);
            } else {
                message.error(res.message);
            }
            this.setState({ spinning: false });
        } catch (err) {
            if (err.message) {
                message.error(err.message);
            }
            this.setState({ spinning: false });
            console.log(err);
        }
    };
    getChart = () => {
        let chartData = [];
        if (this.props.saleCode == 4) {
            this.state.allData.businessAnalysisVOS
                .slice(0, 10)
                .forEach((val) => {
                    if (val.index) {
                        chartData.push({
                            name: TextName[this.props.saleCode].tr,
                            日期: val.name,
                            金额:
                                window._SYT_util.formatMoney(
                                    window._SYT_util.math.accDiv(
                                        val.newAmt * 1,
                                        10000
                                    ),
                                    2
                                ) * 1, // 变成万元
                            rate: val.rate,
                            totalCustomer:
                                window._SYT_util.formatMoney(
                                    window._SYT_util.math.accDiv(
                                        val.totalAmt * 1,
                                        10000
                                    ),
                                    2
                                ) * 1, // 变成万元
                        });
                        // chartData.push({
                        //     name: TextName[this.props.saleCode].tr1,
                        //     日期: val.name,
                        //     金额:
                        //         window._SYT_util.formatMoney(
                        //             window._SYT_util.math.accDiv(
                        //                 val.totalAmt * 1,
                        //                 10000
                        //             ),
                        //             2
                        //         ) * 1, // 变成万元
                        //     rate: val.rate,
                        // });
                    }
                });
        } else {
            this.state.allData.businessAnalysisVOS
                .slice(0, 10)
                .forEach((val) => {
                    if (val.index) {
                        chartData.push({
                            name: TextName[this.props.saleCode].tr,
                            日期: val.name,
                            金额: val.newCustomer,
                            rate: val.rate,
                            totalCustomer: val.totalCustomer,
                        });
                    }
                });
        }
        let name = this.props.saleCode == 4 ? "万" : "人";
        document.getElementById("chartId").innerHTML = "";
        let chart = new G2.Chart({
            container: "chartId",
            forceFit: true,
            height: 300,
            padding: [15, 50, 70, 100],
        });
        chart.source(chartData);
        chart.interval().position("日期*金额").color("name").adjust().size(40);
        chart.line().position("日期*rate").color("orange");

        chart.tooltip(true, {
            custom: true, // 表示使用自定义的模板显示 tooltip
            // html: '', // tooltip 的 html 外层模板
            // itemTpl:'<tr><td></td><td style="color:{color}">{name}</td><td>{value}</td></tr>', // 使用 html 时每一个显示项的模板，默认支持 index, color, name, value 这四个字段。
            offset: 50, // 偏移量，设置tooltip 显示位置距离 x 轴方向上的偏移
            customFollow: false, // 设置 tooltip 是否跟随鼠标移动，默认为 true，跟随。
            htmlContent: (title, items) => {
                console.log(title, items);
                let _html =
                    '<div class="ac-tooltip" style="position:absolute;visibility: hidden;background-color:rgba(255, 255, 255, 0.9);padding:10px;border-radius:5px;color:#666;box-shadow: 0px 0 5px #ccc;"><div style="line-height:30px">' +
                    title +
                    "</div>";
                items.forEach((item, key) => {
                    if (key == 0) {
                        _html +=
                            '<div style="line-hegiht:30px"><span style="color:#38a0ff">' +
                            item.name +
                            '</span><span style="margin-left:15px">' +
                            item.value +
                            name +
                            "</span></div>";
                        _html +=
                            '<div style="line-hegiht:30px"><span style="color:#30bd73">' +
                            TextName[this.props.saleCode].tr1 +
                            '</span><span style="margin-left:15px">' +
                            item.point._origin.totalCustomer +
                            name +
                            "</span></div>";
                    } else if (key == 1) {
                        _html +=
                            '<div style="line-hegiht:30px"><span style="color:#f9a100">' +
                            item.name +
                            '</span><span style="margin-left:15px">' +
                            item.value +
                            "%</span></div>";
                    }
                });
                _html += "</div>";
                return _html;
            },
        });

        chart.scale("rate", {
            alias: "新增占比",
        });

        chart.axis("金额", {
            text: "22",
            label: {
                formatter: function formatter(text, item, index) {
                    if (text >= 1 || text == 0) {
                        return text;
                    }
                },
            },
        });

        chart.axis("rate", {
            label: {
                formatter: function formatter(val) {
                    return val + "%";
                },
            },
        });

        chart.render();
    };
    componentDidMount() {
        this.getData();
    }

    componentWillUnmount() { }

    render() {
        const { type } = this.props;
        const { allData } = this.state;
        console.log(allData.businessAnalysisVOS, 'this.props.Times', this.props.Times);
        return (
            <div style={{ paddingBottom: "20px" }}>
                <div style={{ marginTop: "15px", marginBottom: "10px" }}>
                    {TextName[this.props.saleCode].title}
                </div>
                <div
                    style={{
                        width: "100%",
                        overflow: "auto",
                        overflowY: "hidden",
                    }}
                >
                    <div
                        style={this.state.chartStyle}
                        id="chartId"
                        className=""
                    ></div>
                </div>
                <Spin
                    size="large"
                    spinning={this.state.spinning}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                />
                <Row
                    type="flex"
                    justify="space-between"
                    style={{ marginBottom: "15px" }}
                >
                    <div style={{ fontWeight: "bold", paddingTop: "5px" }}>
                        统计列表（单位：
                        {this.props.saleCode === 4 ? "万元" : "个"}）
                    </div>
                </Row>

                <Table
                    loading={this.state.spinning}
                    dataSource={allData.businessAnalysisVOS || []}
                    columns={[
                        {
                            title: "序号",
                            dataIndex: "index",
                        },
                        {
                            title: "日期",
                            dataIndex: "date",
                            render: () => this.props.Times,
                        },
                        {
                            title: "姓名",
                            dataIndex: "name",
                        },
                        {
                            title: TextName[this.props.saleCode].tr,
                            dataIndex:
                                this.props.saleCode == 4
                                    ? "newAmt"
                                    : "newCustomer",
                            render: (t, r) =>
                                this.props.saleCode == 4
                                    ? window._SYT_util.formatMoney(
                                        window._SYT_util.math.accDiv(
                                            t * 1,
                                            10000
                                        ),
                                        2
                                    )
                                    : t,
                        },
                        {
                            title: TextName[this.props.saleCode].tr1,
                            dataIndex:
                                this.props.saleCode == 4
                                    ? "totalAmt"
                                    : "totalCustomer",
                            render: (t, r) =>
                                this.props.saleCode == 4
                                    ? window._SYT_util.formatMoney(
                                        window._SYT_util.math.accDiv(
                                            t * 1,
                                            10000
                                        ),
                                        2
                                    )
                                    : t,
                        },
                        {
                            title: "新增占比",
                            dataIndex: "rate",
                            render: (t) => t + "%",
                        },
                    ]}
                    size="middle"
                    pagination={{
                        size: "large",
                        showQuickJumper: true,
                        pageSize: 10,
                    }}
                />
            </div>
        );
    }
}

export { Personal };
