import axios from 'axios';
import { Axios } from "../axios";
import React, { Component, useState, useEffect } from 'react';
import { Table, Checkbox, Popover, Select, Popconfirm, Row, Form, Input, DatePicker, message, InputNumber, Drawer, Tabs, Button, Tooltip } from 'antd';
import filter from "./filter";
import { cityListold } from "../common/cityList";
import { cityList } from "../common/cityListBigData";
import "./common.scss";
import { connect } from "react-redux";

import moment from 'moment';
import { Image } from "echarts/src/util/graphic";
import { CloseOutlined, SaveOutlined, EditOutlined, PictureOutlined, PaperClipOutlined, PlusOutlined } from '@ant-design/icons';
import HelgImg from "../assets/images/help.png"
import WW from '../assets/images/w.png'
import XX from '../assets/images/x.png'
import LL from '../assets/images/l.png'
import { Ossupload } from "./upLoad";

const { TextArea } = Input;

const { Option } = Select;
const FormItem = Form.Item;
const rules = {
    zhengNum: {
        message: '格式错误',
        pattern: /^\d*$/
    },
    amount: {
        message: '格式错误',
        pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/
    },
    email: {
        message: '邮箱错误',
        pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
    },
    mobileNo: {
        message: '手机号不合法',
        pattern: /^1\d{10}$/
    },
    idNo: {
        validator: (rule, value, callback) => {
            if (value && !window.someCheckReg.IdentityCodeValid(value + '')) {
                callback('身份证号不合法')
            }
            callback()
        }
    },
    rangeNum: (min, max) => {
        return {
            validator: (rule, value, callback) => {
                if (value) {
                    if (!isNaN(value)) {
                        if (Number(value) > max || Number(value) < min) {
                            callback('超出范围')
                        }
                    } else {
                        callback('格式错误')
                    }
                }
                callback()
            }
        };
    },
    maxLength: (length, tip) => {
        return {
            validator: (rule, value, callback) => {
                if (value) {
                    if (String(value).length > length) {
                        if (tip) {
                            callback(tip)
                        } else {
                            callback('超出长度')
                        }
                    }
                }
                callback()
            }
        };
    },
};
const upFileToOss = function (file, type, succ, err) {
    var fileObj = file;
    if (type === 'base64') {
        var arr = file.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        fileObj = new Blob([u8arr], { type: mime });
        fileObj.lastModifiedDate = new Date();
        fileObj.name = new Date().getTime() + '.png';
    }
    console.log(fileObj)
    let expire = 0, Sign;
    let now = Date.parse(new Date()) / 1000;
    if (expire < now + 3) {
        Sign = window.winhcFn.getOssSign()
        Sign = JSON.parse(Sign)
        // console.log(Sign)
    }
    let ossData = new FormData();
    let FileName = "tiangong/" + window.winhcFn.randomString(10) + "/" + fileObj.name
    ossData.append('OSSAccessKeyId', Sign.accessid);
    ossData.append('policy', Sign.policy);
    ossData.append('signature', Sign.signature);
    ossData.append('callback', Sign.callback);
    ossData.append('key', FileName);
    ossData.append('name', fileObj.name);
    ossData.append('success_action_status', 200); // 指定返回的状态码
    ossData.append('file', fileObj, FileName);
    // console.log(ossData)
    axios({
        method: 'post',
        url: Sign.host,
        data: ossData,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    }).then(function (res) {
        console.log(res)
        if (res.status == 200) {
            succ(window.winhc_oss_url + FileName)
        } else {
            err(res)
        }
    }).catch(function (error) {
        err(error)
    });
}

const ImgViewer = function (title, url) {
    // return <span onClick={()=>{
    //     let obj = document.createElement('div');
    //     let _Viewer=window.Viewer;
    //     let _image = new Image();
    //     //查看url是字符串还是数组
    //     if(typeof url === "string"){
    //         _image.src = url;
    //         obj.appendChild(_image);
    //     }else{
    //         //循环url放进obj中
    //         url.forEach(function(e){
    //             let img=document.createElement("img");
    //             img.src=e;
    //             obj.appendChild(img);
    //         });
    //     }
    //     // console.log("obj===",obj)
    //     let ImgViewer = new _Viewer(obj, {
    //         hidden: function () {
    //             ImgViewer.destroy();
    //         },
    //     });
    //     ImgViewer.show();
    // }}>{title}</span>;
};

class FlieList extends React.Component {
    close (name, index, id) {
        this.props.close(name, index, id)
    }

    render () {
        let list = [];
        for (let i = 0; i < this.props.fileList.length; i++) {
            if (this.props.fileList[i].docUrl) {
                let DocUrl = this.props.fileList[i].docUrl;
                let id = this.props.fileList[i].id;
                let showText = filter.checkOSSUrl(DocUrl);
                showText = DocUrl.replace(window.winhc_oss_url, '').replace(window.old_winhc_oss_url, '').replace("crm/", '').replace("YHC/", '').replace(/[A-Z|A-z|0-9]{10}\//, '')
                if (this.props.name) {
                    showText = this.props.name
                }
                list.push(
                    <div key={i} className={this.props.hoverBg ? 'hoverfff fileList' : 'fileList'}>
                        {window.someCheckReg.isImagesUrl(DocUrl) ?
                            <PictureOutlined />
                            :
                            <PaperClipOutlined />
                        }
                        {/* {window.someCheckReg.isImagesUrl(DocUrl)?<a>{ImgViewer(showText,DocUrl)}</a>
                            :<a target="_blank" href={DocUrl}>{showText}</a>} */}
                        {/* 先这样做显示用，后面增加图片显示 */}
                        <a target="_blank" href={DocUrl} style={{ wordBreak: 'break-all' }}>{this.props.fileName ? (this.props.fileList[i] || {})[this.props.fileName] : showText}</a>
                        {this.props.QuanXian ?
                            <span>
                                <Popconfirm title="是否删除该附件?" onConfirm={this.close.bind(this, DocUrl, i, id)}
                                    okText="删除"
                                    cancelText="取消">
                                    <div className="close">
                                        <CloseOutlined />
                                    </div>
                                </Popconfirm>
                                <div className="closeslt"></div>
                            </span> : ''}
                    </div>)
            }
        }
        return (
            <span>
                {this.props.showTitle ?
                    <div className="Privilege_title"><span className="title">{this.props.title}</span></div> : ''}
                {list}
            </span>
        )
    }
}

class DocumentShow extends React.Component {
    close (name) {
        this.props.close(name)
    }

    render () {
        let list = [];
        let imgs = [];
        let name = ''
        for (let i = 0; i < this.props.fileList.length; i++) {
            if (window.someCheckReg.isImagesUrl(this.props.fileList[i].docUrl)) {
                imgs.push(this.props.fileList[i].docUrl)
            }
        }
        for (let i = 0; i < this.props.fileList.length; i++) {
            if (this.props.fileList[i].docUrl) {
                let DocUrl = this.props.fileList[i].docUrl;
                // let showText = filter.checkOSSUrl(DocUrl);
                let showText = DocUrl.split("/")[DocUrl.split("/").length - 1];
                name = DocUrl
                    .split(".")
                    .pop()
                    .toLocaleLowerCase();
                // let showText=DocUrl.replace(window.winhc_oss_url,'').replace(window.old_winhc_oss_url,'').replace(/[A-Z|A-z|0-9]{10}/,'')
                list.push(
                    <div style={{ marginLeft: '15px', }}  >
                        <p style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}         >
                            {window.someCheckReg.isImagesUrl(DocUrl) ?
                                <div
                                    style={{
                                        width: "100px",
                                        height: "100px",
                                        display:
                                            "inline-block",
                                        marginLeft:
                                            "20px",
                                    }}
                                    key={i}

                                >
                                    <img
                                        src={DocUrl}
                                        style={{
                                            width: "100px",
                                            height: "100px",
                                        }}
                                    />
                                </div> :
                                (name == "doc" || name == "docx") ?
                                    <img
                                        src={WW} style={{
                                            width: "100px",
                                            height: "100px",
                                        }}
                                    /> : (name == "xlsx" || name == "xls") ? <img
                                        src={XX} style={{
                                            width: "100px",
                                            height: "100px",
                                        }}
                                    /> : <img src={LL} style={{
                                        width: "100px",
                                        height: "100px",
                                    }}
                                    />
                            }
                            &nbsp;&nbsp;  &nbsp;
                            {this.props.QuanXian ? <span> <a target="_blank" href={DocUrl}>打开</a>   &nbsp;&nbsp;  &nbsp;   <a target="_blank" href={DocUrl}>下载</a> </span>
                                : <a onClick={() => this.props.callback(i)}>删除</a>
                            }
                        </p>
                        <p> {showText}</p>
                    </div>
                )
            }
        }
        return (
            <span style={{ display: 'flex', flexWrap: 'wrap' }}  >
                {this.props.showTitle ?
                    <div className="Privilege_title"><span className="title">{this.props.title}</span></div> : ''}
                {list}
            </span>
        )
    }
}

class CaseReason extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            caseReasonlist: []
        };
    }

    caseReasonOnsearch = async (value) => {
        if (value) {
            if (this.CheckChinese(value)) {
                let res = await Axios('get', "/project/caseReason", {
                    condition: value,
                    pageNum: '1',
                    pageSize: '100'
                });
                this.setState({
                    caseReasonlist: res.data.dataList
                })
            }
        } else {
            let res = await Axios('get', "/project/caseReason", {
                pageNum: '1',
                pageSize: '100'
            });
            this.setState({
                caseReasonlist: res.data.dataList
            })
        }
    }

    CheckChinese (val) {
        // 正则
        var reg = new RegExp("[\\u4E00-\\u9FFF]+$", "g");
        // 验证
        if (!reg.test(val)) {
            return false   // 不纯中文
        } else {
            return true  // 纯中文
        }
    }

    caseReasonOnFocus = async () => {
        let res = await Axios('get', "/project/caseReason", {
            pageNum: '1',
            pageSize: '100'
        });
        this.setState({
            caseReasonlist: res.data.dataList
        })
    }

    render () {
        return (
            <Select
                defaultValue={this.props.initialValue}
                mode="combobox"
                placeholder="请选择"
                style={this.props.Style || {}}
                onSearch={this.caseReasonOnsearch}
                onFocus={this.caseReasonOnFocus}>
                {this.state.caseReasonlist.map((item, index) => {
                    return <Option key={index} value={item.caseReasonName}>{item.caseReasonName}</Option>
                })}
            </Select>
        )
    }
}
class FeedbackM extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            DrawerOpen: false,
            picUrl: '',
            trueS: false,
            confirmLoading: false,
            tData: [],
            loading: false,
            currentPage: 1,

            totalNum: 0,
        };

    }

    componentDidMount () {

    }
    refreshTable = async (key) => {
        try {
            this.setState({
                loading: true,
            });
            let res = await Axios("get", "/problem/list", { pageNum: key, pageSize: "10" });
            this.setState(
                {
                    loading: false,
                    tData: res.body,
                    totalNum: res.totalNum,
                }
            );
        } catch (err) {
            // message.error(err);
            this.setState({ loading: false });
        }
    };
    handleTableChange = async ({ current }) => {
        this.refreshTable(current)
    }
    handleSubmit = () => {
        console.log('values');
        this.formRef.current.
            validateFields()
            .then(async (values) => {
                console.log(values, this.state.picUrl, 'picUrl');
                if (this.state.picUrl) {
                    values.pic = this.state.picUrl
                }
                try {
                    this.setState({ confirmLoading: true })
                    let res = await Axios('POST', '/problem/save', values)
                    if (res.code == '200') {
                        this.formRef.current.resetFields()
                        message.success('提交成功');
                        this.setState({ confirmLoading: false, picUrl: '' });
                    } else {
                        message.error('提交失败');
                        this.setState({ confirmLoading: false });
                    }
                } catch (err) {
                    message.error(err.message);
                    this.setState({ confirmLoading: false })

                }
            })
            .catch((info) => {
                console.log('Validate Failed:', info)
            })
    }
    onChange = (e) => {
        console.log(e);
        if (e == 2) {
            this.refreshTable(1)
        }
    }
    onClose = () => {
        this.setState({ picUrl: '', DrawerOpen: false }, () => {
            this.setState({
                trueS: false
            })
        })
    }
    render () {
        const operInfo = this.props.operInfo;
        const billInfoCs = {
            background: "#FAFAFA",
            borderRadius: "8px",
            color: "#242A32",
            border: "1px solid #EEEEEE",
            padding: "8px 12px",
            position: "relative",
            margin: "0 16px 12px 0",
            cursor: "pointer",
            display: "flex",
            justifyContent:
                "space-between",
        };
        const feedbackTypeList = [
            {
                label: '业务反馈',
                value: 10,
            },
            {
                label: '功能异常',
                value: 1,
            },
            {
                label: '产品体验',
                value: 0,
            },
            {
                label: '法律服务相关',
                value: 5,
            },
            {
                label: '数据信息有误',
                value: 6,
            },
            {
                label: '账户登录/注销',
                value: 7,
            },
            {
                label: '其他',
                value: 9,
            },
        ]
        return (
            <span>
                <Tooltip title="问题反馈">
                    <img src={HelgImg} style={{ 'fontSize': '22px', width: '20px', cursor: 'pointer', marginRight: '20px' }} onClick={() => {
                        this.setState({ DrawerOpen: true }, () => {
                            this.setState({
                                trueS: true
                            })
                        })
                    }} />
                </Tooltip>
                <Drawer keyboard={false} onClose={this.onClose} open={this.state.DrawerOpen} closable={false} bodyStyle={{ paddingTop: 0 }} width='50rem' destroyOnClose>
                    <Tabs onChange={this.onChange} items={[{
                        label: '问题反馈',
                        key: '1',
                        children: (
                            <Form ref={this.formRef} layout='horizontal' labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
                                <Form.Item label="问题类型："
                                    name="problemType"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择问题类型'
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder="请选择问题类型"
                                        allowClear
                                    >
                                        {feedbackTypeList.map(v => <Option key={v.value} value={v.value}>{v.label}</Option>)}

                                    </Select>
                                </Form.Item>
                                <Form.Item label="问题描述："
                                    name="feedbackContent"
                                    rules={[
                                        {
                                            required: true,
                                            message: '问题描述不能为空', whitespace: true
                                        }
                                    ]}
                                >
                                    <TextArea rows={4} placeholder="请输入您的问题，问题描述越详细全面，越有可能得到解决哦" maxLength={300} />
                                </Form.Item>
                                <Form.Item label="联系方式："
                                    name="linkMobile"
                                    initialValue={operInfo.mobileNo || undefined}
                                    rules={[{
                                        required: true,
                                        message: '手机号不能为空'
                                    }, {
                                        message: '手机号不合法',
                                        pattern: /^((1[0-9]{2})+\d{8})$/
                                    }]
                                    }>

                                    <Input placeholder="请输入联系方式" type="text" maxLength={11} />
                                </Form.Item>
                                <Form.Item label="问题截图："
                                >
                                    {this.state.trueS ?
                                        <Ossupload

                                            callbackData={{ test: "123" }}
                                            callback={async (
                                                filename,
                                                file,
                                                info,
                                                callbackData
                                            ) => {
                                                if (info.status == 200) {
                                                    try {
                                                        let picUrl = "";
                                                        if (this.state.picUrl) {
                                                            picUrl =
                                                                this.state.picUrl +
                                                                "," +
                                                                window.winhc_oss_url +
                                                                filename;
                                                        } else {
                                                            picUrl =
                                                                window.winhc_oss_url +
                                                                filename;
                                                        }

                                                        let send = {
                                                            picUrl: picUrl,
                                                        };
                                                        this.setState({ picUrl: picUrl });
                                                    } catch (err) {
                                                        console.log("err", err);
                                                        message.error(err.msg);
                                                    }
                                                }
                                            }}
                                            beforeUpload={(file, up) => {
                                                console.log(file, up);
                                                const isJpgOrPng =
                                                    file.type === "image/jpeg" ||
                                                    file.type === "image/jpg" ||
                                                    file.type === "image/png";
                                                if (!isJpgOrPng) {
                                                    message.error(
                                                        "支持jpg、png格式，文件大小不超过5M，每次最多可上传10张"
                                                    );
                                                    return false;
                                                }

                                                if (file.size > 1024 * 1024 * 5) {
                                                    message.error(
                                                        "凭证信息超过限制范围，请重新上传"
                                                    );
                                                    return false;
                                                }
                                            }}
                                        >
                                            <Button
                                                disabled={
                                                    this.state.picUrl.split(",").length >=
                                                    10
                                                }
                                                icon={<PlusOutlined />}
                                                style={{
                                                    marginTop: "-20px",
                                                    marginRight: "20px",
                                                }}
                                            >
                                                添加截图
                                            </Button>
                                        </Ossupload> : ''}
                                    <p
                                        style={{
                                            marginTop: 5,
                                            fontSize: "12px",
                                            color: "#bdbbbb",
                                        }}
                                    >
                                        支持jpg、png格式，文件大小不超过5M，每次最多可上传10张
                                    </p>
                                    {this.state.picUrl
                                        ? this.state.picUrl
                                            .split(",")
                                            .map((v, index) => {
                                                return (

                                                    <span
                                                        style={billInfoCs}
                                                    >

                                                        <img
                                                            style={{
                                                                width: "45px",
                                                                height: "48px",
                                                                marginRight:
                                                                    "4px",
                                                            }}
                                                            src={v}
                                                        />
                                                        <span style={{
                                                            textOverflow: 'ellipsis',
                                                            overflow: 'hidden',
                                                            width: '72%',
                                                            whiteSpace: 'nowrap'
                                                        }}>


                                                            {filter.checkOSSUrl(
                                                                v
                                                            )}


                                                        </span>

                                                        <span>
                                                            <a
                                                                onClick={() => {
                                                                    fullScrImg({
                                                                        url: [v],
                                                                    });
                                                                }}
                                                            >
                                                                预览
                                                            </a>
                                                            &nbsp;&nbsp;

                                                            <a
                                                                onClick={() => {
                                                                    let Src =
                                                                        "";
                                                                    this.state.picUrl
                                                                        .split(
                                                                            ","
                                                                        )
                                                                        .forEach(
                                                                            (
                                                                                v,
                                                                                i
                                                                            ) => {
                                                                                if (
                                                                                    i !=
                                                                                    index
                                                                                ) {
                                                                                    if (
                                                                                        Src
                                                                                    ) {
                                                                                        Src =
                                                                                            Src +
                                                                                            "," +
                                                                                            v;
                                                                                    } else {
                                                                                        Src =
                                                                                            v;
                                                                                    }
                                                                                }
                                                                            }
                                                                        );
                                                                    console.log(
                                                                        Src
                                                                    );
                                                                    this.setState(
                                                                        {
                                                                            picUrl: Src,
                                                                        }
                                                                    );
                                                                }}
                                                            >
                                                                删除
                                                            </a>

                                                        </span>
                                                    </span>
                                                );
                                            })
                                        : ""} </Form.Item>
                                <Row
                                    type="flex"
                                    justify="end"
                                    align="middle"
                                    style={{ width: "85%", height: '30px' }}
                                >
                                    <Form.Item style={{ marginRight: '16px' }}
                                    >    <Button onClick={this.onClose}>
                                            返回
                                        </Button>

                                    </Form.Item>
                                    <Form.Item >
                                        <Button
                                            type="primary"
                                            onClick={this.handleSubmit}
                                            loading={this.state.confirmLoading}
                                        >
                                            提交
                                        </Button>
                                    </Form.Item>
                                </Row>

                            </Form>
                        ),
                    },
                    {
                        label: '反馈记录',
                        key: '2',
                        children: (
                            <Table
                                dataSource={this.state.tData}
                                columns={[
                                    {
                                        title: "反馈内容",
                                        dataIndex: "feedbackContent",
                                        width: 180,
                                        render: (text, record) => <span>

                                            {text && text.length > 30 ?
                                                <Popover content={<div style={{
                                                    padding: '10px',
                                                    maxWidth: '400px',
                                                    fontSize: '14px',
                                                    wordBreak: 'break-word'
                                                }}>{text}</div>}>
                                                    <span>{filter.strLength(text, 30)}</span>
                                                </Popover>
                                                : text || '-'}
                                            <p>
                                                {record.pic ? record.pic.split(",").map(v =>
                                                    <img src={v} style={{
                                                        width: '30px',
                                                        height: '30px',
                                                        marginRight: '6px'
                                                    }} onClick={() => {
                                                        fullScrImg({
                                                            url: [v],
                                                        });
                                                    }} />) : ''}
                                            </p>
                                        </span>,
                                    },

                                    {
                                        title: '反馈人/反馈时间',
                                        dataIndex: "brokerUserName",
                                        width: 180,
                                        render: (text, record) => <span>
                                            <span>{text || '-'}</span><br />
                                            <span>{record.linkMobile || '-'}</span><br />
                                            <span>{record.feedbackTime || '-'}</span><br />
                                        </span>,
                                    },
                                    {
                                        title: "回复",
                                        width: 180,
                                        dataIndex: "problemFeedbackReplies",
                                        render: (text, record) => text && text.map(v => <span>

                                            <span >赢火虫产品部：</span><br /><span>
                                                {v.replyContent && v.replyContent.length > 16 ?
                                                    <Popover content={<div style={{
                                                        padding: '10px',
                                                        maxWidth: '400px',
                                                        fontSize: '14px',
                                                        wordBreak: 'break-word'
                                                    }}>{v.replyContent}</div>}>
                                                        <span>{filter.strLength(v.replyContent, 16)}</span>
                                                    </Popover>
                                                    : v.replyContent || '-'}</span><br />
                                            <span style={{ color: '#a2a3a5', fontSize: '13px' }}> {v.replyTime || '-'}</span>
                                        </span>)
                                    },
                                ]}
                                size="middle"
                                pagination={{
                                    size: "large",
                                    showQuickJumper: true,
                                    pageSize: 10,
                                    total: parseInt(this.state.totalNum),
                                }}
                                loading={this.state.loading}
                                bordered={true}
                                onChange={this.handleTableChange}
                            />
                        ),
                    }]} size='large' destroyInactiveTabPane tabBarExtraContent={<CloseOutlined style={{ cursor: 'pointer' }} onClick={this.onClose} />}></Tabs>
                </Drawer>
            </span>
        )
    }
}
let Feedback = connect(
    (state) => {
        return state;
    },
    (dispatch) => ({})
)(FeedbackM);
class AreaSelect extends React.Component {
    constructor(props) {
        super(props);
        let provinceList = [], _cityList = [], countyList = [];
        let cityListAll = this.props.Hadoop ? cityList : cityListold
        for (let key in cityListAll) {
            provinceList.push(<Option key={key}>{key}</Option>)
        }
        if (this.props.province) {
            for (let key in cityListAll) {
                if (key === this.props.province) {
                    for (let key2 in cityListAll[key]) {
                        _cityList.push(<Option key={key2}>{key2}</Option>)
                    }
                }
            }
            if (this.props.kunchan && this.props.userInfo && this.props.province == '江苏') {
                this.props.userInfo.city == '昆山市' ? _cityList.push(<Option key='昆山市'>昆山市</Option>) : this.props.userInfo.city == '常熟市' && _cityList.push(<Option key='常熟市'>常熟市</Option>)
            }

            if (this.props.city && !this.props.hiddenCountry) {
                for (let key in cityListAll[this.props.province]) {
                    if (key === this.props.city) {
                        for (let i = 0; i < cityListAll[this.props.province][key].length; i++) {
                            countyList.push(<Option
                                key={cityListAll[this.props.province][key][i]}>{cityListAll[this.props.province][key][i]}</Option>)
                        }
                    }
                }
            }
        }
        this.state = {
            provinceList: provinceList,
            cityList: _cityList,
            countyList: countyList,
            province: this.props.province ? this.props.province : undefined,
            city: this.props.city ? this.props.city : undefined,
            county: this.props.county ? this.props.county : undefined,
        };
        this.ch_province = this.ch_province.bind(this);
        this.ch_city = this.ch_city.bind(this);
        this.ch_county = this.ch_county.bind(this);
    }

    componentDidMount () {
        if (this.props.didMount) {
            this.props.didMount(this.props.province, this.props.city, this.props.county)
        }
    }

    componentWillReceiveProps (next) {
        // 清空选择框
        if (next.qingkong) {
            this.setState({
                province: undefined,
                city: undefined,
                county: undefined
            })
        }

    }

    ch_province (value) {
        let cityListAll = this.props.Hadoop ? cityList : cityListold

        if (value) {
            let _cityList = [];
            let _first = '';
            for (let key in cityListAll) {
                if (key === value) {
                    for (let key2 in cityListAll[key]) {
                        if (!_first) {
                            _first = key2;
                        }
                        _cityList.push(<Option key={key2}>{key2}</Option>)
                    }
                }
            }
            if (this.props.kunchan && this.props.userInfo && value == '江苏') {
                this.props.userInfo.city == '昆山市' ? _cityList.push(<Option key='昆山市'>昆山市</Option>) : this.props.userInfo.city == '常熟市' && _cityList.push(<Option key='常熟市'>常熟市</Option>)
            }
            this.setState({ province: value, city: undefined, county: undefined, cityList: _cityList }, () => {
                if (_first) {
                    if (this.props.autoselect) {
                        this.ch_city(_first)    //默认选中第一个
                    } else {
                        this.ch_city(undefined)    //默认选中第一个
                    }
                } else {
                    this.props.select(value, undefined, undefined)
                }
            });
        } else {
            this.setState({ province: undefined, city: undefined, county: undefined, cityList: [], countyList: [] });
            this.props.select(undefined, undefined, undefined)
        }
    }

    ch_city (value) {
        let cityListAll = this.props.Hadoop ? cityList : cityListold

        if (!this.props.hiddenCountry) {
            if (value) {
                let countyList = [];
                let _first = '';
                for (let key in cityListAll[this.state.province]) {
                    if (key === value) {
                        if (!_first) {
                            _first = cityListAll[this.state.province][key][0];
                        }
                        for (let i = 0; i < cityListAll[this.state.province][key].length; i++) {
                            countyList.push(<Option
                                key={cityListAll[this.state.province][key][i]}>{cityListAll[this.state.province][key][i]}</Option>)
                        }
                    }
                }
                this.setState({ city: value, countyList: countyList }, () => {
                    if (_first) {
                        // this.ch_county(_first)    //默认选中第一个
                        this.ch_county(undefined)    //取消 默认选中第一个
                    } else {
                        this.props.select(this.state.province, value, undefined)
                    }
                })
            } else {
                this.setState({ city: undefined, county: undefined, countyList: [] });
                this.props.select(this.state.province, undefined, undefined)
            }
        } else {
            this.setState({ city: value })
            this.props.select(this.state.province, value, undefined)
        }
    }

    ch_county (value) {
        this.setState({ county: value });
        this.props.select(this.state.province, this.state.city, value)
    }

    render () {
        const style = { display: 'inline-block', width: '5%', height: '2px' };
        return (
            <div style={this.props.bodyStyle || {}}>{/*controlled value是否受控*/}
                <Select placeholder={this.props.placeholder ? "省" : "请选择"}
                    value={this.props.controlled ? (this.props.province || undefined) : this.state.province}
                    allowClear={this.props.allowClear} disabled={this.props.disabled}
                    style={this.props.hiddenCountry ? { width: '47.5%' } : { width: '30%' }}
                    onChange={this.ch_province}>
                    {this.state.provinceList}
                </Select>
                <span style={style}> </span>
                <Select placeholder={this.props.placeholder ? "市" : "请选择"}
                    value={this.props.controlled ? (this.props.city || undefined) : this.state.city}
                    allowClear={this.props.allowClear} disabled={this.props.disabled}
                    style={this.props.hiddenCountry ? { width: '47.5%' } : { width: '30%' }} onChange={this.ch_city}>
                    {this.state.cityList}
                </Select>
                <span style={this.props.hiddenCountry ? { display: 'none' } : style}> </span>
                <Select placeholder={this.props.placeholder ? "区" : "请选择"}
                    value={this.props.controlled ? this.props.county : this.state.county}
                    allowClear={this.props.allowClear} disabled={this.props.disabled}
                    style={this.props.hiddenCountry ? { display: 'none' } : { width: '30%' }} onChange={this.ch_county}>
                    {this.state.countyList}
                </Select>
            </div>
        )
    }
}
const appChannels = [
    {
        key: 'guanwang',
        val: '官网',
    }, {
        key: 'appStore',
        val: 'IOS',
    }, {
        key: 'appstore',
        val: 'ios',
    }, {
        key: 'vivo',
        val: 'vivo',
    }, {
        key: 'xiaomi',
        val: '小米',
    }, {
        key: 'meizu',
        val: '魅族',
    }, {
        key: 'qutoutiao',
        val: '趣头条',
    }, {
        key: 'huawei',
        val: '华为',
    }, {
        key: 'huawei01',
        val: '华为01',
    }, {
        key: 'zhihu',
        val: '知乎',
    }, {
        key: 'yingyongbao',
        val: '应用宝',
    }, {
        key: '360',
        val: '360',
    }, {
        key: 'weibo',
        val: '微博',
    }, {
        key: 'baidu',
        val: '百度',
    }, {
        key: 'jinritoutiao',
        val: '今日头条',
    }, {
        key: 'oppo',
        val: 'OPPO',
    }, {
        key: 'sanxing',
        val: '三星',
    }, {
        key: 'baidusem',
        val: '百度SEM',
    }, {
        key: 'xycx',
        val: '异业合作_信用查询',
    }, {
        key: 'baidu_mz',
        val: '百度_M站',
    }, {
        key: 'partner_sdxy',
        val: '异业合作_水滴信用',
    }, {
        key: 'ttf_mz',
        val: '头条_M站',
    }, {
        key: 'wxs_mz',
        val: '微信搜一搜_M站',
    },
]
const industryList = [
    "交通运输、仓储和邮政业",
    "住宿和餐饮业",
    "信息传输、软件和信息技术服务业",
    "公共管理、社会保障和社会组织",
    "农、林、牧、渔业",
    "制造业",
    "卫生和社会工作",
    "国际组织",
    "居民服务、修理和其他服务业",
    "建筑业",
    "房地产业",
    "批发和零售业",
    "教育",
    "文化、体育和娱乐业",
    "水利、环境和公共设施管理业",
    "电力、热力、燃气及水生产和供应业",
    "科学研究和技术服务业",
    "租赁和商务服务业",
    "采矿业",
    "金融业",
]
const sourceTypeList = [
    { id: 1, name: '业务员分享链接' },
    { id: 2, name: '后台开通' },
    { id: 3, name: 'APP首页申请' },
    { id: 4, name: '扫码申请' },
]
const TypeofpartnerList = [
    { id: 2, name: '居间商' },
    { id: 4, name: '城市代理商' },
    { id: 5, name: '运营商' },
    { id: 6, name: '区县运营商' },
]

const caseProcessType = [{
    id: 0,
    name: '一审',
    projectTypeId: '0',
}, {
    id: 1,
    name: '二审',
    projectTypeId: '0',
}, {
    id: 3,
    name: '再审',
    projectTypeId: '0',
}, {
    id: 4,
    name: '特别程序',
    projectTypeId: '0',
}, {
    id: 5,
    name: '执行',
    projectTypeId: '0',
}, {
    id: 10,
    name: '执行终本',
    projectTypeId: '1',
}, {
    id: 11,
    name: '执行异议',
    projectTypeId: '1',
}, {
    id: 12,
    name: '执行异议之诉',
    projectTypeId: '1',
}, {
    id: 20,
    name: '商事仲裁',
    projectTypeId: '2',
},/*{
            id:21,
            name:'执行',
            projectTypeId:'2',
        },*/{
    id: 22,
    name: '仲裁不予执行',
    projectTypeId: '2',
}, {
    id: 31,
    name: '实现担保物权案件',
    projectTypeId: '3',
}]
const specialProcessDesc = [{
    id: 0,
    name: '一般规定'
}, {
    id: 1,
    name: '选民资格案件'
}, {
    id: 2,
    name: '宣告失踪、宣告死亡案件'
}, {
    id: 3,
    name: '认定公民无民事行为能力、限制民事行为能力案件'
}, {
    id: 4,
    name: '认定财产无主案件'
}, {
    id: 5,
    name: '确认调解协议案件'
}, {
    id: 6,
    name: '实现担保物权案件'
}]
const judgeResult = [{
    id: 0,
    name: '我方胜诉',
    projectTypeId: '0'
}, {
    id: 1,
    name: '我方败诉',
    projectTypeId: '0'
}, {
    id: 2,
    name: '法院调解',
    projectTypeId: '0'
}, {
    id: 3,
    name: '和解',
    projectTypeId: '0'
}, {
    id: 4,
    name: '撤诉',
    projectTypeId: '0'
}, {
    id: 5,
    name: '驳回起诉',
    projectTypeId: '0'
}, {
    id: 6,
    name: '终止委托',
    projectTypeId: '0'
}, {
    id: 7,
    name: '我方部分胜诉',
    projectTypeId: '0'
}, {
    id: 8,
    name: '其他',
    projectTypeId: '0'
}, {
    id: 10,
    name: '暂缓执行',
    projectTypeId: '1'
}, {
    id: 11,
    name: '执行中止',
    projectTypeId: '1'
}, {
    id: 12,
    name: '执行终结',
    projectTypeId: '1'
}, {
    id: 13,
    name: '执行和解',
    projectTypeId: '1'
}, {
    id: 14,
    name: '不予执行',
    projectTypeId: '1'
}, {
    id: 15,
    name: '其他',
    projectTypeId: '1'
}, {
    id: 30,
    name: '待银行确认',
    projectTypeId: '2'
}, {
    id: 20,
    name: '我方胜诉',
    projectTypeId: '3'
}, {
    id: 21,
    name: '我方败诉',
    projectTypeId: '3'
}, {
    id: 22,
    name: '法院调解',
    projectTypeId: '3'
}, {
    id: 23,
    name: '和解',
    projectTypeId: '3'
}, {
    id: 24,
    name: '撤诉',
    projectTypeId: '3'
}, {
    id: 25,
    name: '驳回起诉',
    projectTypeId: '3'
}, {
    id: 26,
    name: '终止委托',
    projectTypeId: '3'
}, {
    id: 27,
    name: '我方部分胜诉',
    projectTypeId: '3'
}, {
    id: 28,
    name: '其他',
    projectTypeId: '3'
}]
const judgeStatus = [{
    id: 0,
    name: '达成诉求'
}, {
    id: 1,
    name: '部分达成诉求'
}, {
    id: 2,
    name: '未达成诉求'
}, {
    id: 3,
    name: '未委托'
}, {
    id: 4,
    name: '终止委托'
}, {
    id: 5,
    name: '其它'
},];

const EditIconFn = (props) => {
    /* 用法示例：
    *
<EditIconFn isEdit={this.state.edit_tel} edit={()=>{
    this.setState({
        edit_tel:true
    })
}} ok={()=>{
    this.setState({
        edit_tel:false
    })

    //  your code
}} cancel={()=>{
    this.setState({
        edit_tel:false
    })
}}/>
    *
    * */
    return (
        <span style={{ padding: '0 10px' }}>{props.isEdit ? <Popconfirm
            title="是否完成修改？"
            onConfirm={props.ok}
            onCancel={props.cancel}
            okText="确定"
            cancelText="取消">
            <SaveOutlined />
        </Popconfirm> : <EditOutlined onClick={props.edit} />}</span>
    );
}

//获取线上列表
const getOnlineList = function (value) {
    let onLineOption = [];
    if (value == 'Y') {
        onLineOption.push(<Option key='app'>APP</Option>);
        onLineOption.push(<Option key='WEB_SITE'>网站</Option>);
        onLineOption.push(<Option key='WX_GZH'>微信</Option>);
        onLineOption.push(<Option key='YHC_MP'>微信小程序</Option>);
        onLineOption.push(<Option key='ONLINE'>线上其它</Option>)
        onLineOption.push(<Option key='LS19423001'>法江湖</Option>)
        onLineOption.push(<Option key='XC'>携船网</Option>)


    } else if (value == 'N') {
        onLineOption.push(<Option key='ANDUN'>安盾网</Option>);
        onLineOption.push(<Option key='FASAC'>法智特</Option>);
        onLineOption.push(<Option key='MIS'>后台录入</Option>);
        onLineOption.push(<Option key='BROCK_CRM'>代理商案件</Option>);
    }
    return onLineOption;
};
const EditIconHook = (props) => {
    /* 用法示例：
    *
    *   文本输入框
<EditIconHook {...{
    quanxian,
    value:value,
    inputAtr:{
        style:{width:'150px'}
    },
    valueAtr:{
        className:'inner'
    },
    callback:(val)=>{
        console.log(val);

        //  your code
    }
}}/>
    *   其他
<EditIconHook {...{
    quanxian,
    value:detail.tel,
    input:<AreaSelect bodyStyle={{width: "300px",display:'inline-block'}} province={this.state.province} city={this.state.city}
                      county={this.state.county} allowClear
                      select={(province, city, county) => {
                          console.log(province, city, county)
                          this.setState({
                              province: province || '',
                              city: city || '',
                              county: county || ""
                          })
                      }}/>,
    valueAtr:{
        className:'inner'
    },
    callback:()=>{
        //  your code
    }
}}/>
    *
    * */
    const [edit, setEdit] = useState(false);
    const _Inner = props.value ||
        (props.inputAtr ? (props.inputAtr.value ? props.inputAtr.value : props.inputAtr.defaultValue ? props.inputAtr.defaultValue : '') : '')

    const inputId = 'input' + new Date().getTime();
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {edit && props.quanxian ? props.input ||
                <Input placeholder='请输入' id={inputId} type="text" {...props.inputAtr} /> :
                <div {...props.valueAtr}>{_Inner}</div>}
            {props.quanxian ? <EditIconFn isEdit={edit} edit={() => {
                setEdit(true)
            }} ok={() => {
                if (props.callback) {
                    if (props.input) {
                        props.callback();
                    } else {
                        props.callback(document.querySelector('#' + inputId).value);
                    }
                }
                setEdit(false)
            }} cancel={() => {
                setEdit(false)
            }} /> : ''}
        </div>
    )
}
const fullScrImg = function (props) {
    let obj = document.createElement('div');
    let _Viewer = window.Viewer;
    let _image = new Image();
    let is = true;
    //查看url是字符串还是数组
    if (typeof props.url === "string") {
        _image.src = props.url;
        obj.appendChild(_image);
    } else {
        //循环url放进obj中
        props.url.forEach(function (e) {
            let img = document.createElement("img");
            img.src = e;
            obj.appendChild(img);
        });
    }
    let ImgViewer = new _Viewer(obj, {
        hidden: function () {
            ImgViewer.destroy();
        },
        viewed () {
            if (is && props.index) {
                is = false;
                ImgViewer.view(props.index);
            }
        }
    });
    ImgViewer.show();
};
const LongText = function (props) {
    return (
        props.children ? <span>
            {props.children.length > (props.Height || 18) ?
                <Popover content={<div
                    style={{ padding: '10px', maxWidth: '400px', fontSize: '14px' }}>{props.children}</div>}>
                    <span
                        style={{ textDecoration: 'underline' }}>{filter.strLength(props.children, props.Height || 18)}</span>
                </Popover>
                : props.children}
        </span> : ''
    )
}

const dynamicType = {
    company_court_open_announcement: '开庭公告',//   d2b139b63e7927036f5b6f5e6edb2877 1
    company_court_register: '立案信息',//b34be5205b891bfe380a7de93d9cc55e 1
    company_send_announcement: '送达公告',// 218fc9ed5092c3797c1cb9e39969c199 1
    company_court_announcement: '法院公告', //3be0009420d7f2cf36b2ed0baae602f8 1
    wenshu_detail_v2: '裁判文书', //d2fe8de42a0718ece6051d6d8c867083 1
    company_zxr_final_case: '终本案件',// eccd9759b68baadacd10c8c51946825a 1
    bankruptcy_open_case: '破产公告', //11dfffe7f1ae87ed82e8debbf829f947 1
    company_bid: '招投标',// 269f2bab92a2419239aa67124d695678 1
    company_copyright_reg: '软件著作权',// d7106ae86669de1d05c4ce5494fa4e50 1
    company_copyright_works: '作品著作权', //1
    company_dishonest_info: '企业失信被执',// e713f1ee62d55d310d1f45e430b8763d 1
    company_equity_info: '股权出质',// 05da02086252db8195e6435aab8ad152 1
    company_finance: '融资历史', //1
    company_holder: '股东',// fe798db46abdaed63f4b04389642d24c_e3b3cff39e369c815fa4b747e672c461 1
    company_land_announcement: '购地信息',// fdfb81d2990c5ae605b29d7835edb72c 1
    company_land_mortgage: '土地抵押',//1
    company_mortgage_info: '动产抵押', //1
    company_patent: '专利',//1
    company_tm: '商标',// ae69b24b94da7e0e4bbf8337785c71b4 1
    company_zxr_restrict: '限制消费令',//1
    auction_tracking: '司法拍卖', //526140ed72468b9748ff4cdb92714d3a //1
    zxr_evaluate: '询价评估（机构）', //da0bd9f2fe4609cd23f742c7223a5ee0 //1
    zxr_evaluate_results: '询价评估',// 6e4f677f4d2cea7c2cb6fd1f33c035b6v//1
    company_license: '行政许可',// 485921a3b2a3c873282260964c3bdc47 //1
    company_judicial_assistance: '股权冻结',//1
    increase_registered_capital_info: '工商信息'     // 企业增资 1
}

let AreaSelect_province = undefined;
let AreaSelect_city = undefined;
let AreaSelect_county = undefined;

class EditTableM extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            linineState: {},
        };
    }

    render () {
        const { getFieldDecorator } = this.props.form;
        let Tr = [];
        this.props.list.map((item, index) => {
            let form = item.value;
            if (item.type === 'input') {
                form = <FormItem style={{ width: '100%' }} wrapperCol={{ span: '24' }}>
                    {getFieldDecorator(item.api, { initialValue: item.formValue || item.value })(
                        <Input size="default" placeholder={'请输入' + item.label} type="text" />
                    )}
                </FormItem>
            } else if (item.type === 'InputNumber') {
                form = <FormItem style={{ width: '100%' }} wrapperCol={{ span: '24' }} className='inputNumberStyle'>
                    {getFieldDecorator(item.api, { initialValue: item.formValue || item.value })(
                        <InputNumber
                            size="large"
                            min={0}
                            max={100}
                            placeholder={'请输入' + item.label}
                            formatter={value => {
                                if (value) {
                                    return `${value}%`
                                } else {
                                    return ``
                                }
                            }}
                            parser={value => value.replace('%', '')}
                        />
                    )}
                </FormItem>
            } else if (item.type === 'select') {
                let option = [];
                item.option.map((i) => {
                    option.push(<Option key={i.key + ''} value={i.key + ''}>{i.label}</Option>)
                })
                form = <FormItem style={{ width: '100%' }} wrapperCol={{ span: '24' }}>
                    {getFieldDecorator(item.api, { initialValue: ((item.formValue || item.value || '') + '' || undefined) })(
                        <Select placeholder={'请输入' + item.label} allowClear>
                            {option}
                        </Select>
                    )}
                </FormItem>
            } else if (item.type === "area") {

                AreaSelect_province = item.value[0];
                AreaSelect_city = item.value[1];
                AreaSelect_county = item.value[2];
                form =
                    <FormItem style={{ width: '100%' }} wrapperCol={{ span: '24' }}>
                        <AreaSelect province={item.value[0]} city={item.value[1]} county={item.value[2]} allowClear
                            select={(province, city, county) => {
                                console.log(province, city, county)
                                AreaSelect_province = province;
                                AreaSelect_city = city;
                                AreaSelect_county = county;

                            }} />
                    </FormItem>

            } else if (item.type === 'date') {
                let ThisDate = (item.formValue || item.value || '') + '' || undefined;
                form = <FormItem style={{ width: '100%' }} wrapperCol={{ span: '24' }}>
                    {getFieldDecorator(item.api, {
                        initialValue: ThisDate ? moment(ThisDate, 'YYYY-MM-DD') : undefined
                    })(
                        <DatePicker style={{ width: '100%' }} placeholder={"请选择" + item.label} allowClear />
                    )}
                </FormItem>
            } else if (item.type === 'range') {
                form = <div>
                    <FormItem style={{ width: '45%', marginRight: '0' }} wrapperCol={{ span: '24' }}>
                        {getFieldDecorator('min_' + item.api, { initialValue: item.value.min })(
                            <Input size="default" placeholder={'请输入'} type="text" />
                        )}
                    </FormItem>
                    <div style={{ display: "inline-block", width: '10%', textAlign: 'center', lineHeight: '30px' }}>-
                    </div>
                    <FormItem style={{ width: '45%', marginRight: '0' }} wrapperCol={{ span: '24' }}>
                        {getFieldDecorator('max_' + item.api, { initialValue: item.value.max })(
                            <Input size="default" placeholder={'请输入'} type="text" />
                        )}
                    </FormItem>
                </div>
            }
            if (item.otherType === 'inlineHover') {
                Tr.push(
                    <tr key={index}>
                        <td key={item.api + 'label'} className="tilteName">{item.label}</td>
                        <td key={item.api + 'value'} className="hoveShowFu"
                            style={this.state.edit ? { padding: '5px 8px' } : {}}>
                            {this.state.edit ? form :
                                <span>
                                    {item.type === 'range' ? item.value.min + '-' + item.value.max : item.value}
                                    {item.quanxian ? <EditOutlined className="hoveShow"
                                        onClick={() => {
                                            item.callback()
                                        }} /> : ''}
                                </span>}
                        </td>
                    </tr>
                )
            } else {
                Tr.push(
                    <tr key={index}>
                        <td key={item.api + 'label'} className="tilteName">{item.label}</td>
                        <td key={item.api + 'value'} style={this.state.edit ? { padding: '5px 8px' } : {}}>
                            {this.state.edit ? form :
                                item.type === 'range' ? item.value.min + '-' + item.value.max : item.value}
                        </td>
                    </tr>
                )
            }
        });
        return (
            <Form layout="inline" style={this.props.list.length === 0 ? { display: 'none' } : {}}>
                <table className="EditTable" cellPadding='0' cellSpacing='0'>
                    {this.props.title ?
                        <thead>
                            <tr>
                                <th colSpan="2">
                                    {this.props.title}
                                    {this.props.showEdit ?
                                        this.state.edit ?
                                            <Popconfirm title="是否完成修改?" onConfirm={() => {
                                                let _list = this.props.list;
                                                for (let i = 0; i < _list.length; i++) {
                                                    if (_list[i].regFn) {
                                                        if (_list[i].type === 'range') {
                                                            if (_list[i].regFn(this.props.form.getFieldValue('min_' + _list[i].api))) {
                                                                message.warning(_list[i].label + '输入有误');
                                                                return;
                                                            }
                                                        } else {
                                                            if (_list[i].regFn(this.props.form.getFieldValue('max_' + _list[i].api))) {
                                                                message.warning(_list[i].label + '输入有误');
                                                                return;
                                                            }
                                                        }
                                                    }
                                                }

                                                if (!AreaSelect_province) {
                                                    AreaSelect_province = ""
                                                }
                                                if (!AreaSelect_city) {
                                                    AreaSelect_city = ""
                                                }
                                                if (!AreaSelect_county) {
                                                    AreaSelect_county = ""
                                                }
                                                let Send = {
                                                    ...this.props.form.getFieldsValue(),
                                                    province: AreaSelect_province,
                                                    city: AreaSelect_city,
                                                    county: AreaSelect_county
                                                }

                                                this.props.confirm(Send);
                                                this.setState({ edit: false })
                                            }}
                                                onCancel={() => {
                                                    this.setState({ edit: false })
                                                }} okText="提交" cancelText="取消">
                                                <div className="edit"><SaveOutlined /></div>
                                            </Popconfirm> :
                                            <div className="edit" onClick={() => {
                                                this.setState({ edit: true })
                                            }}><EditOutlined /></div>
                                        : ''}
                                </th>
                            </tr>
                        </thead> : ''}
                    <tbody>
                        {Tr}
                    </tbody>
                </table>
                <style>
                    {`
                        .inputNumberStyle .ant-input-number{
                            width: 100%;
                            height: 33px;
                        }
                    `}
                </style>
            </Form>
        )
    }
}

let EditTable = EditTableM;

export {
    rules,
    upFileToOss,
    FlieList,
    Feedback,
    ImgViewer,
    CaseReason,
    DocumentShow,
    caseProcessType,
    specialProcessDesc,
    judgeResult,
    judgeStatus,
    AreaSelect,
    EditIconFn,
    EditIconHook,
    fullScrImg,
    LongText,
    EditTable,
    dynamicType, getOnlineList,
    industryList, sourceTypeList, TypeofpartnerList, appChannels
};

export const popoverTable = (length, props, type) => {
    let width = type === 'num' ? length * 8.5 + 35 : length * 15 + 25
    return {
        ...props,
        width,
        render: (text, record) => {
            if (props.fn) {
                text = props.fn(text, record)
            }
            return (length > 7 && /^YHC\d{8}$/.test(text) ? text :
                <div style={{ cursor: "pointer" }}>
                    {text && text.length > length ?
                        <Popover content={<div style={{
                            padding: '10px',
                            maxWidth: width > 400 ? width : 400 + 'px',
                            fontSize: '14px',
                            wordBreak: 'break-word'
                        }}>{text}</div>}>
                            <span>{filter.strLength(text, length)}</span>
                        </Popover>
                        : text || '-'}
                </div>
            )
        }
    }
}
export const TooltipTable = (props) => {
    const showToolTip = (e) => {
        let data = document.getElementById(e)
        if (data) {
            if (data.clientWidth >= data.scrollWidth) {
                return true
            } else {
                return false
            }
        }
    }
    return {
        ...props,
        render: (text, record, index) => {
            return <Tooltip
                title={text}
                color='#fff'
                placement="top"
                overlayInnerStyle={{
                    color: 'rgba(0,0,0,0.6)',
                    minWidth: '500px',
                    padding: '12px',
                    fontSize: '14px',
                    borderRadius: '4px',
                }}
                autoAdjustOverflow={true}
            >
                <div
                    key={text}
                    id={props.dataIndex + index}
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        pointerEvents: showToolTip(props.dataIndex + index) ? 'none' : 'auto'
                    }}
                >
                    {text || '-'}
                </div>
            </Tooltip>
        }
    }
}