import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
// 下面时引用的reducer
import { routerReducer } from 'react-router-redux'
import { work } from './work';
import { brokerId, brokerType, job } from './brokerId';
import { team } from './team';
import { baseData } from './baseData';
import { operInfo } from './operInfo';
import { userInfo } from './userInfo';


//注册reducer，每个自定义的reducer都要来这里注册！！！不注册会报错。
//使用redux的combineReducers方法将所有reducer打包起来
const rootReducer = combineReducers({
    routing: routerReducer,
    /* your reducers */
    work,
    team,
    baseData,
    operInfo,
    brokerId,
    job,
    brokerType, userInfo
});

const middlewares = [thunk];
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));
export default store;
