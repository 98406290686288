import { Form, Select } from 'antd';
import React, { useState, useEffect } from "react";
import RegionalData from "../common/RegionalData";
const { Option } = Select;
const { ProvinceData = [], CityData = {}, DistrictData = {} } = RegionalData
/**form-Form的方法 / region-是否显示区 / label-标题 / rules-是否必填 / isProvince-传过来表示不必传 / isCity-传过来表示不必传 / isDistrict-传过来表示不必传*/
const CareaSelect = ({ form, region, label, rules, isProvince, isCity, isDistrict, province = 'province', city = 'city', district = 'district' }) => {
    const style = { display: 'inline-block', width: region ? 'calc(33.3% - 6px)' : 'calc(50% - 6px)' }
    return (<Form.Item label={<span className={rules && 'ant-form-item-required-span'}>{label}</span>} style={{ margin: 0 }}>
        <Form.Item name={province} style={style} rules={[{ required: isProvince ? false : rules, message: "请选择省" }]}>
            <Select placeholder="请选择" onChange={() => { form.setFieldsValue({ [city]: undefined, [district]: undefined }) }} allowClear>
                {(ProvinceData || []).map((value) => <Option key={value.name}>{value.name}</Option>)}
            </Select>
        </Form.Item>
        <span style={{ display: 'inline-block', width: region ? 9 : 12 }} />
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues[province] !== currentValues[province]}>
            {({ getFieldValue }) =>
                <Form.Item name={city} style={style} rules={[{ required: isCity ? false : rules, message: "请选择市" }]}>
                    <Select placeholder="请选择" onChange={() => { form.setFieldsValue({ [district]: undefined }) }} allowClear>
                        {((CityData || {})[getFieldValue(province)] || []).map((value) => <Option key={value.name}>{value.name}</Option>)}
                    </Select>
                </Form.Item>
            }
        </Form.Item>
        <span style={{ display: region ? 'inline-block' : '', width: 9 }} />
        {region ? <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues[city] !== currentValues[city]}>
            {({ getFieldValue }) =>
                <Form.Item name={district} style={style} rules={[{ required: isDistrict ? false : rules, message: "请选择区" }]}>
                    <Select placeholder="请选择" allowClear>
                        {(((DistrictData || {})[getFieldValue(province)] || {})[getFieldValue(city)] || []).map((value) => <Option key={value.name}>{value.name}</Option>)}
                    </Select>
                </Form.Item>
            }
        </Form.Item> : ''}
    </Form.Item>)
}
export default CareaSelect;
/**
 * import { Form, Select } from 'antd';
import React, { useState, useEffect } from "react";
import { cityByCodeList } from "../common/cityByCodeList";
const { Option } = Select;
const CareaSelect = ({ form, region }) => {
    const style = { display: 'inline-block', width: region ? 'calc(32% - 12px)' : 'calc(50% - 12px)', margin: 0 }
    return (<Form.Item label="地区">
        <Form.Item name="province" style={style}>
            <Select placeholder="请选择" onChange={() => { form.setFieldsValue({ city: undefined }) }}>
                {(cityByCodeList || []).map((value) => <Option key={value.name}>{value.name}</Option>)}
            </Select>
        </Form.Item>
        <span style={{ display: 'inline-block', width: 12 }} />
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.province !== currentValues.province}>
            {({ getFieldValue }) =>
                <Form.Item name="city" style={style}>
                    <Select placeholder="请选择" onChange={() => { form.setFieldsValue({ district: undefined }) }}>
                        {(cityByCodeList || []).map((value) => {
                            if (value.name == getFieldValue('province')) {
                                return (value.children || []).map((values) => <Option key={values.name}>{values.name}</Option>)
                            } else {
                                return undefined
                            }
                        })}
                    </Select>
                </Form.Item>
            }
        </Form.Item>
        <span style={{ display: 'inline-block', width: 12 }} />
        {region ? <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.city !== currentValues.city}>
            {({ getFieldValue }) =>
                <Form.Item name="district" style={style}>
                    <Select placeholder="请选择">
                        {(cityByCodeList || []).map((value) => {
                            if (value.name == getFieldValue('province')) {
                                return (value.children || []).map((values) => {
                                    if (values.name == getFieldValue('city')) {
                                        return (values.children || []).map((values1) => <Option key={values1.name}>{values1.name}</Option>)
                                    } else {
                                        return undefined
                                    }
                                })
                            } else {
                                return undefined
                            }
                        })}
                    </Select>
                </Form.Item>
            }
        </Form.Item> : ''}
    </Form.Item>)
}

export default CareaSelect;
 */