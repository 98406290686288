
// 初始化状态
export function brokerId(state = '', action) {
  switch (action.type) {
    case 'SetBrokerId':
      localStorage.setItem('brokerId', JSON.stringify(action.brokerId))
      window.globalData.headDeptId = action.brokerId
      return action.brokerId
    default:
      return { ...state };
  }
}
export function job(state = '', action) {
  switch (action.type) {
    case 'SetBrokerJob':
      localStorage.setItem('job', JSON.stringify(action.job))
      window.globalData.job = action.job
      return action.job
    default:
      return { ...state };
  }
}
export function brokerType(state = '', action) {
  switch (action.type) {
    case 'SetBrokerType':
      localStorage.setItem('brokerType', JSON.stringify(action.brokerType))
      window.globalData.brokerType = action.brokerType
      return action.brokerType

    default:
      return { ...state };
  }
}


