import React from 'react';
import { message, notification } from 'antd';

/*
<Ossupload>按钮元素<Ossupload/>

参数
callbackData  需要回调函数带出的参数

回调函数
beforeUpload 上传文件前的回调  return false则停止上传 Function(file , up)
callback  上传成功后回调 Function(filename, file , info , callbackData)

<Ossupload
    callbackData={{test:'123'}}
    callback={(filename, file , info , callbackData)=>{
        console.log(filename, file , info , callbackData)
    }}
    beforeUpload={(file , up)=>{
        console.log(file , up)
        if(file.size>1024*1024*10){
            message.error('请选择小于10MB文件！');
            return false
        }
    }}>
        <span>上传</span>
</Ossupload>
* */
class Ossupload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            container: "container" + window.winhcFn.randomString(10),
            selectfiles: "selectfiles" + window.winhcFn.randomString(10),
            source: "test",
            id: "123455",
            accessid: "",
            accesskey: "",
            host: "",
            policyBase64: "",
            signature: "",
            callbackbody: "",
            multi_selection: true,
            filename: "",
            key: "",
            expire: "",
            g_object_name: "",
            // g_object_name_type:"random_name",
            g_object_name_type: "random_name_local_name",
            errorCallback: function (res, code) {
                if (code == -600) {
                    notification.error({
                        message: res,
                        description: '请选择小于300MB的文件'
                    })
                } else if (code == -601) {
                    notification.error({
                        message: res,
                        description: '文件后缀有误'
                    })
                } else {
                    message.error(res)
                }
            },
        }
    }

    init() {
        let This = this;
        let uploader = new window.plupload.Uploader({
            runtimes: 'html5,flash,silverlight,html4',
            browse_button: This.state.selectfiles,
            multi_selection: This.state.multi_selection,
            container: document.getElementById(This.state.container),
            flash_swf_url: 'lib/plupload-2.1.2/js/Moxie.swf',
            silverlight_xap_url: 'lib/plupload-2.1.2/js/Moxie.xap',
            url: 'https://oss.aliyuncs.com',

            filters: {
                /*mime_types : [ //只允许上传图片和zip文件
                    { title : "Image files", extensions : "doc,docx,pdf" }
                ],*/
                max_file_size: '300mb', //最大只能上传10mb的文件
                prevent_duplicates: false //允许选取重复文件
            },

            init: {
                PostInit: function () {
                    //document.getElementById('ossfile').innerHTML = '';
                },

                FilesAdded: function (up, files) {
                    window.plupload.each(files, function (file) {
                        console.log('file', file, 'file.id:', file.id, ' file.name:', file.name, 'plupload.formatSize(file.size):',)

                    });
                    This.set_upload_param(uploader, '', false);
                },

                BeforeUpload: function (up, file) {
                    if (This.props.beforeUpload && This.props.beforeUpload(file, up) === false) {
                        return
                    }
                    This.set_upload_param(up, file.name.replaceAll(",", '，'), true);
                },

                UploadProgress: function (up, file) {
                    /*var d = document.getElementById(file.id);
                    d.getElementsByTagName('b')[0].innerHTML = '<span>' + file.percent + "%</span>";
                    var prog = d.getElementsByTagName('div')[0];
                    var progBar = prog.getElementsByTagName('div')[0]
                    progBar.style.width= 2*file.percent+'px';
                    progBar.setAttribute('aria-valuenow', file.percent);*/
                },

                FileUploaded: function (up, file, info) {
                    if (info.status == 200) {
                        if (This.props.callback) {
                            var _fileName = window.winhcFn.encodeOssUrl(This.get_uploaded_object_name(file.name.replaceAll(",", '，')));
                            file.name = window.winhcFn.encodeOssUrl(file.name.replaceAll(",", ','));
                            if (This.props.callbackData) {
                                This.props.callback(_fileName, file, info, This.props.callbackData)
                            } else {
                                This.props.callback(_fileName, file, info)
                            }
                        }
                    } else if (info.status == 203) {
                        This.state.errorCallback(info.response, 203)
                    } else {
                        This.state.errorCallback(info.response, '')
                    }
                },

                Error: function (up, err) {
                    console.log('oss直传返回err', up, err)
                    if (err.code == -600) {
                        console.log("\n选择的文件太大了,可以根据应用情况，在upload.js 设置一下上传的最大大小");
                        This.state.errorCallback('选择的文件过大', -600)
                    } else if (err.code == -601) {
                        console.log("\n选择的文件后缀不对,可以根据应用情况，在upload.js进行设置可允许的上传文件类型");
                        This.state.errorCallback('选择的文件后缀有误', -601)
                    } else if (err.code == -602) {
                        console.log("\n这个文件已经上传过一遍了");
                        This.state.errorCallback('重复上传', -601)
                    } else if (err.code == -200) {
                        //beforeUpload 拦截之后的回调 无需处理
                    } else {
                        console.log("\nError xml:" + err.response);
                        This.state.errorCallback(err.response, "")
                    }
                }
            }
        });

        uploader.init();
    }

    // send_request() {
    //     let This = this;
    //     var xmlhttp = null;
    //     if (window.XMLHttpRequest) {
    //         xmlhttp = new XMLHttpRequest();
    //     } else if (window.ActiveXObject) {
    //         xmlhttp = new window.ActiveXObject("Microsoft.XMLHTTP");
    //     }
    //
    //     if (xmlhttp != null) {
    //         var serverUrl = window.system_ossRequest + '?source=' + This.state.source + '&id=' + This.state.id;
    //         // xmlhttp.setRequestHeader('Content-Disposition','attachment=')
    //         xmlhttp.open("GET", serverUrl, false);
    //         xmlhttp.send(null);
    //         return xmlhttp.responseText
    //     } else {
    //         alert("Your browser does not support XMLHTTP.");
    //     }
    // };

    check_object_radio() {
        let This = this;
        var tt = document.getElementsByName('myradio');
        for (var i = 0; i < tt.length; i++) {
            if (tt[i].checked) {
                This.state.g_object_name_type = tt[i].value;
                break;
            }
        }
    }

    get_signature() {
        let This = this;
        //可以判断当前expire是否超过了当前时间,如果超过了当前时间,就重新取一下 3s 做为缓冲
        This.state.now = This.state.timestamp = Date.parse(new Date()) / 1000;
        if (This.state.expire < This.state.now + 3) {
            This.state.body = window.winhcFn.getOssSign()
            var obj = eval("(" + This.state.body + ")");
            This.state.host = obj['host']
            This.state.policyBase64 = obj['policy']
            This.state.accessid = obj['accessid']
            This.state.signature = obj['signature']
            This.state.expire = parseInt(obj['expire'])
            This.state.callbackbody = obj['callback']
            This.state.key = obj['dir']
            return true;
        }
        return false;
    };

    // randomString(len) {
    //     len = len || 32;
    //     var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    //     var maxPos = chars.length;
    //     var pwd = '';
    //     for (let i = 0; i < len; i++) {
    //         pwd += chars.charAt(Math.floor(Math.random() * maxPos));
    //     }
    //     return pwd;
    // }

    get_suffix(filename) {
        var pos = filename.lastIndexOf('.')
        var suffix = ''
        if (pos != -1) {
            suffix = filename.substring(pos)
        }
        return suffix;
    }

    calculate_object_name(filename) {
        let This = this;
        if (This.state.g_object_name_type == 'local_name') {
            This.state.g_object_name += "${filename}"
        } else if (This.state.g_object_name_type == 'random_name') {
            var suffix = This.get_suffix(filename)
            This.state.g_object_name = This.state.key + window.winhcFn.randomString(10) + suffix
        } else if (This.state.g_object_name_type == 'random_name_local_name') {
            var suffix = This.get_suffix(filename)
            This.state.g_object_name = "crm/" + This.state.key + window.winhcFn.randomString(10) + "/" + filename
        }
        return ''
    }

    get_uploaded_object_name(filename) {
        let This = this;
        if (This.state.g_object_name_type == 'local_name') {
            var tmp_name = This.state.g_object_name
            tmp_name = tmp_name.replace("${filename}", filename);
            return tmp_name
        } else if (This.state.g_object_name_type == 'random_name') {
            return This.state.g_object_name
        } else if (This.state.g_object_name_type == 'random_name_local_name') {
            return This.state.g_object_name
        }
    }

    set_upload_param(up, filename, ret) {
        let This = this;
        if (ret == false) {
            ret = This.get_signature()
        }
        This.state.g_object_name = This.state.key;
        if (filename != '') {
            var suffix = This.get_suffix(filename)
            This.calculate_object_name(filename)
        }
        var new_multipart_params = {
            'key': This.state.g_object_name,
            'policy': This.state.policyBase64,
            'OSSAccessKeyId': This.state.accessid,
            'success_action_status': '200', //让服务端返回200,不然，默认会返回204
            'callback': This.state.callbackbody,
            'signature': This.state.signature,
        };

        up.setOption({
            'url': This.state.host,
            'multipart_params': new_multipart_params
        });

        up.start();
    }

    componentDidMount() {
        this.init()
    }

    render() {
        return (
            <div id={this.state.container} style={{ display: 'inline-block' }}>
                <div id={this.state.selectfiles} style={{ display: 'inline-block' }}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export { Ossupload }
