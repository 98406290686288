import React, { Component } from "react";
import {
    Row,
    Breadcrumb,
    Button,
    Form,
    message,
    DatePicker,
    Modal,
    Input,
    Select,
    Col,
    Radio,
    Popover,
    Table,
    TreeSelect,
    InputNumber,
    Cascader,
} from "antd";
import { AreaSelect, popoverTable } from "../../common";
import Filter from "../../common/filter";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Axios } from "../../axios";
import moment from "moment";
import { industryLevel3 } from "../../common/industryLevel3";
import filter from "../../common/filter";
import { Shengpi2, ContactList } from "./Common";

import { bindActionCreators } from "redux";
import { PlusOutlined } from "@ant-design/icons";
import { change_operInfo } from "../../redux/actions/operInfo";
const { TextArea } = Input;

const { Option } = Select;
const RangePicker = DatePicker.RangePicker;
let sendBody = {},
    Timer;
let StatuspageNum = 1;
const sourceOption = [
    { source: '', name: '全部' },
    { source: 'com.winhc.legal.saas_0', name: '云法务SaaS-委托催收' },
    { source: 'com.winhc.legal.saas_1', name: '云法务SaaS-账款中心' },
    { source: 'com.winhc.legal.saas_2', name: '云法务SaaS-非公债务' },
    { source: 'com.winhc.legal.saas_3', name: '云法务SaaS-履债评估' },
    { source: 'com.winhc.legal.app_0', name: '云法务App-委托催收' },
    { source: 'com.winhc.legal.app_1', name: '云法务App-账款中心' },
    { source: 'com.winhc.legal.app_2', name: '云法务App-非公债务' },
    { source: 'com.winhc.legal.app_3', name: '云法务App-履债评估' },
    { source: 'com.winhc.mis.web_4', name: 'MIS' },
]
class FsCaseManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tData: [],
            loading: false,
            totalNum: 0,
            currentPage:
                window.routerCache.FsCaseManage.currentPage || 1,
        };
        sendBody = window.routerCache.FsCaseManage.sendBody || {};
    }

    // 获取表格数据
    refreshTableByData = (data, totalNum, currentPage) => {
        this.setState({ tData: data, totalNum: totalNum, currentPage: currentPage });
    };
    // 获取表格数据
    refreshTable = async () => {
        try {
            this.setState({
                loading: true,
            });
            let send = sendBody;
            send.workType = window.routerCache.CustomerQuery.workTypeStr || undefined;
            send.pageNum = this.state.currentPage + "";
            send.pageSize = "10";
            let res = await Axios("get", "/legalEntrusted/list", send);
            console.log(res, 'resresresresres');
            this.setState({
                loading: false,
                tData: res.body || [],
                totalNum: res.totalNum,
            });
        } catch (err) {
            // message.error(err);
            this.setState({ loading: false });
        }
    };

    handleTableChange = async (pagination) => {
        this.setState({ loading: true });
        try {
            let send = sendBody;
            send.pageNum = pagination.current + "";
            send.pageSize = "10";
            let res = await Axios("get", "/legalEntrusted/list", send);
            this.setState(
                {
                    loading: false,
                    tData: res.body,
                    totalNum: res.totalNum,
                    currentPage: pagination.current,
                },
            );
        } catch (err) {
            // message.error(err.message);
            this.setState({ loading: false });
        }

    };

    // 组件渲染后获取外界数据(GET)
    componentDidMount() {
        this.refreshTable();
        window.winhcFn.changeDept = () => {
            this.setState(
                {
                    currentPage: 1,
                },
                () => {
                    this.refreshTable();
                }
            );
        };
    }

    componentWillUnmount() {
        window.routerCache.FsCaseManage.currentPage =
            this.state.currentPage;
        window.routerCache.FsCaseManage.sendBody = sendBody;
    }

    render() {
        const { refreshTable } = this;
        let columns = [

            {
                title: '客户(债权人、委托人)',
                dataIndex: 'creditorName',
                render(t, r) {
                    return <div style={{ textAlign: 'left' }}>
                        <div>{r.creditorCompanyName}</div>
                        <div style={{ color: '#D9001B' }}>{filter.legalspecification(r.purchaseSpec)}&nbsp;&nbsp;
                            {r.purchaseSpec ? ['legal_legal', 'legal_wisdom', 'legal_basic'].includes(r.purchaseSpec) && '含催收' : ''}</div>
                        <div>{t} {r.creditorMobile}</div>
                    </div>
                }
            },
            {
                title: '债务人',
                dataIndex: 'masterDebtName',
                render: (t, r) => {
                    return <div style={{ textAlign: 'left' }}>
                        <div>{r.payerName}</div>
                        <div>{t}  {r.masterDebtMobile}</div>
                        <div>身份证:{r.masterDebtIdCard || '-'}</div>
                        {r.otherDebtInfos && r.otherDebtInfos.length &&
                            <ContactList record={r} getData={this.getData} Infos={r.otherDebtInfos || []}> <a>其他联系人</a></ContactList>
                        }
                    </div>
                }
            }, {
                title: '应收（元）',
                dataIndex: 'fileName',
                render: (t, r) => <p style={{ textAlign: 'left' }}>应收：<span style={{ fontWeight: 'bold' }}>{filter.NumberToFixed(r.receivable || 0, 2)}</span></p>
            },
            {
                title: '状态',
                dataIndex: 'status',
                render: (t, r) => <a>{filter.FsCaseStatus(t)}</a>
            },
            popoverTable(10, {
                title: '备注',
                dataIndex: 'remark',
            }),
            {
                title: '跟进进度',
                dataIndex: 'legalEntrustedCollectionRecordInfos',
                render: (t, r) => <span>
                    {(t && t.length) ?
                        <span style={{ display: 'flex', flexWrap: 'wrap' }}>{(t || []).slice(0, 3).map(v => <p style={{
                            width: '100%',
                            display: 'flex'
                        }}> {(v.time || '').slice(0, 16)}&nbsp;{(v.matter || '-')}&nbsp; {(v.result || '-')}</p>)}</span>
                        : '-'}
                    {(t && t.length)
                        ? <FollowUpList record={r} Infos={t || []}>        <a>                       查看更多
                        </a></FollowUpList> : ''}&nbsp;&nbsp;
                </span>
            },
            {
                title: '基本信息',
                dataIndex: 'accountBillId',
                render: (t, r) =>
                    <div style={{ textAlign: 'left' }}>
                        <div>案件id：{r.id}</div>
                        <div>客户类型：{r.legalCloudId ? '云法务' : '-'}</div>
                        <div>来源：{filter.FsPackageName((r.packageName || '') + '_' + r.source)}</div>
                        <div>代理商：{r.brokerName || '-'}</div>
                        <div>提交时间：{r.timeCreated || '-'}</div>
                        <div>接单时间：{r.confirmTime || '-'}</div>
                        <div>结束时间：{r.finishTime || '-'}</div>
                    </div>
            },
            {
                title: '操作',
                dataIndex: 'id',
                fixed: 'right',
                render: (text, record) => (
                    <Link to={"/FsCaseManageDetail/" + text}>查看</Link>
                ),
            }]
        return (
            <div className="pageContainer">
                <Row className="headNav">
                    <Col span={24}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>非诉清收</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col className="title" span={24}>
                        <span className="name">非诉清收管理</span>
                    </Col>
                </Row>
                <div className="scrollContainer">
                    <div className="scrollMain">
                        <Search refreshTableByData={this.refreshTableByData} />
                        <div style={{ backgroundColor: "#fff" }}>
                            <Row
                                type="flex"
                                justify="space-between"
                                align="middle"
                            ></Row>

                            <Table
                                scroll={{ x: 'max-content' }}
                                dataSource={this.state.tData}
                                columns={columns}
                                size="middle"
                                pagination={{
                                    size: "large",
                                    showQuickJumper: true,
                                    pageSize: 10,
                                    total: parseInt(this.state.totalNum),
                                    current: Number(this.state.currentPage),
                                    showTotal: (total) => {
                                        return "共 " + total + " 条记录 ";
                                    },
                                }}
                                loading={this.state.loading}
                                onChange={this.handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return state;
    },
    (dispatch) => ({})
)(FsCaseManage);

class SearchM extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            province: "",
            city: "",
            qingkong: false,
        };
    }

    // 查询
    query = async () => {
        let _this = this;
        sendBody = this.formRef.current.getFieldsValue();
        _this.props.refreshTableByData([], 1, 1);
        sendBody.pageNum = "1";
        sendBody.pageSize = "10";
        sendBody.workType = window.routerCache.CustomerQuery.workTypeStr || undefined;

        if (this.state.province) {
            sendBody.province = this.state.province;
        }
        if (this.state.city) {
            sendBody.city = this.state.city;
        }
        if (sendBody.sourceHb) {
            sendBody.source = sendBody.sourceHb.split('_')[1];
            sendBody.packageName = sendBody.sourceHb.split('_')[0];
            if (sendBody.source == 4) {
                delete sendBody.packageName
            }
            delete sendBody.sourceHb
        }
        if (sendBody.date) {
            sendBody.startTimeCreated = moment(sendBody.date[0]).format("YYYY-MM-DD") + ' 00:00:00';
            sendBody.endTimeCreated = moment(sendBody.date[1]).format("YYYY-MM-DD") + ' 23:59:59';
            delete sendBody.date;
        }
        try {
            let res = await Axios("get", "/legalEntrusted/list", sendBody);

            _this.props.refreshTableByData(res.body, res.totalNum, 1);
        } catch (err) {
            // message.error(err.message);
        }
    };
    clearOptions = async () => {
        sendBody = {};
        // this.formRef.current.resetFields();
        // 清空的时候清空地区
        this.setState(
            {
                qingkong: true,
            },
            () => {
                this.setState({
                    qingkong: false,
                });
                this.formRef.current.resetFields();
            }
        );
        sendBody.pageNum = "1";
        sendBody.pageSize = "10";
        window.routerCache.CustomerQuery.workTypeStr = "";
        // sendBody.workType = window.routerCache.FsCaseManage.send_workType || '';
        this.props.refreshTableByData([], 1, 1);
        try {
            let res = await Axios("get", "/legalEntrusted/list", sendBody);

            this.props.refreshTableByData(res.body, res.totalNum, 1);
        } catch (err) {
            // message.error(err.message);
        }
    };
    // 选择日期范围
    dateChange = (value, b) => {
        console.log(value, b);
        if (value.length !== 0) {
            this.setState({ sDate: b[0] });
            this.setState({ eDate: b[1] });
        } else {
            this.setState({ sDate: "" });
            this.setState({ eDate: "" });
        }
    };

    // 组件渲染后获取外界数据(GET)
    componentDidMount() { }

    render() {
        /*控制查询按钮状态*/
        console.log(sendBody.companyName, "sendBody.companyName");
        return (
            <div className="search_like_antD" style={{ marginTop: "-8px" }}>
                <Form
                    ref={this.formRef}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                >
                    <Row
                        type="flex"
                        align="middle"
                        style={{ padding: "8px 0" }}
                    >
                        <Col span="8">
                            <Form.Item
                                label="客户名称"
                                style={{ width: "100%" }}
                                name="creditorCompanyName"
                                initialValue={sendBody.creditorCompanyName}
                            >
                                <Input
                                    placeholder="请输入客户名称"
                                    type="text"
                                    style={{ width: "80%" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span="8">
                            <Form.Item
                                label="债务人"
                                style={{ width: "100%" }}
                                name="payerName"
                                initialValue={sendBody.payerName}
                            >
                                <Input
                                    placeholder="请输入债务人"
                                    type="text"
                                    style={{ width: "80%" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span="8">
                            <Form.Item
                                label="来源"
                                style={{ width: "100%" }}
                                name="sourceHb"
                                initialValue={sendBody.sourceHb}
                            >
                                <Select placeholder="请选择" style={{ width: "80%" }} allowClear>
                                    {
                                        sourceOption.map((e) => {
                                            return <Option key={e.source} value={e.source}>{e.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row
                        type="flex"
                        align="middle"
                    >
                        <Col span="8">
                            <Form.Item
                                label="状态"
                                style={{ width: "100%" }}
                                name="status"
                                initialValue={sendBody.status}
                            >
                                <Select placeholder="请选择" allowClear style={{ width: "80%" }}>
                                    <Option key='1' value='1'>待确认</Option>
                                    <Option key='2' value='2'>进行中</Option>
                                    <Option key='3' value='3'>已完成</Option>
                                    <Option key='4' value='4'>已取消</Option>
                                    <Option key='5' value='5'>已拒绝</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span="8">
                            <Form.Item
                                label="提交时间"
                                style={{ width: "100%" }}
                                name="date"
                                initialValue={sendBody.date}
                            >
                                <RangePicker allowClear style={{ width: '80%' }} />
                            </Form.Item>
                        </Col>
                        <Col span="8">
                            <Form.Item
                                label="到期时间"
                                style={{ width: "100%" }}
                                name="overdueDate"
                                initialValue={sendBody.overdueDate}
                            >
                                <Select placeholder="请选择" style={{ width: "80%" }} allowClear>
                                    <Option key='' value=''>全部</Option>
                                    <Option key='1' value='1'>一周内到期</Option>
                                    <Option key='2' value='2'>30日内到期</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row
                        type="flex"
                        justify="space-between"
                        align="middle"
                        style={{ padding: "4px 0" }}
                    >
                        <Col span={8}></Col>
                        <Col span={8}></Col>
                        <Col span={8}>
                            <Row
                                type="flex"
                                justify="end"
                                align="middle"
                                style={{ width: "85%", height: "30px" }}
                            >
                                <Form.Item style={{ marginRight: "16px" }}>
                                    <Button type="primary" onClick={this.query}>
                                        查询
                                    </Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button onClick={this.clearOptions}>
                                        重置
                                    </Button>
                                </Form.Item>
                            </Row>
                        </Col>
                    </Row>
                    <div>
                        <Row
                            type="flex"
                            justify="space-between"
                            align="middle"
                            style={
                                window.routerCache.FsCaseManage
                                    .workTypeStr
                                    ? {
                                        paddingBottom: "10px",
                                    }
                                    : { paddingBottom: "40px" }
                            }
                        ></Row>
                    </div>
                </Form>
                <style>{`
               .search_like_antD .ant-form ant-form-inline .ant-row-flex .ant-row-flex-space-between .ant-row-flex-middle{
                    padding: 0px !important;
                }
                `}</style>
            </div>
        );
    }
}

let Search = connect(
    (state) => {
        return state;
    },
    (dispatch) => ({})
)(SearchM);

class FollowUpList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            confirmLoading: false,
            childrenList: []
        };
    }
    render() {
        return (
            <span>
                <span
                    onClick={() => {
                        this.setState({
                            visible: true,
                        });
                    }}
                >
                    {this.props.children}
                </span>
                <Modal
                    confirmLoading={this.state.confirmLoading}
                    title="查看催收记录"
                    visible={this.state.visible}
                    width='70%'
                    onCancel={() => {
                        this.setState({
                            visible: false,
                        });
                    }}
                    footer={null}
                >
                    <Table
                        dataSource={this.props.Infos}
                        rowKey={record => record.id}
                        columns={[{
                            title: '时间',
                            dataIndex: 'time',
                            width: 206,
                            render: (t) => (t || '').slice(0, 16)
                        },
                        {
                            title: '催收员',
                            width: 100,
                            dataIndex: 'operName',
                            render: (t) => t || '-'
                        },
                        {
                            title: '事项',
                            width: 100,
                            dataIndex: 'matter',
                            render: (t) => t || '-'

                        },
                        {
                            title: '结果',
                            width: 160,
                            dataIndex: 'result',
                            render: (t) => t || '-'

                        },
                        {
                            title: '备注',
                            dataIndex: 'memo',
                            render: (t) => t || '-'
                        }]}
                        size="middle"
                        pagination={{
                            size: 'large',
                            showQuickJumper: true,
                            pageSize: 10,
                        }}
                        bordered={true}
                    />
                </Modal>
            </span>
        );
    }
}

