import React, { useState, useEffect } from 'react'
import { Button, Carousel, Col, Drawer, Dropdown, Menu, Empty, Popconfirm, Row, Tag } from 'antd'
import { cityData } from "../../common/common";

const obj2 = {
  成立年限: 'timeRanges&timeCode',
  注册资本: 'regCapitalRanges&MAX',
  实缴资本: 'actualCapitalRanges&MAX',
  登记状态: 'regStatusStds&ARR',
  企业类型: 'companyOrgTypeStds&ARR',
  组织机构: 'companyTypes&ARR',
  资本类型: 'regCapitalCurrencies&ARR',
  参保人数: 'socialSecurityStaffNums&MAX',
  融资轮次: 'financeRounds&ARR',
  科技型企业: 'technologyCompanies&ARR',
}
const obj1 = {
  联系电话: 'hasPhones',
  小微企业: 'hasMicroCompany',
  联系邮箱: 'hasEmail',
  备案网站: 'hasIcp',
  一般纳税人: 'taxpayer',
  失信被执行人: 'hasDishonest',
  破产重整: 'hasBankruptcy',
  裁判文书: 'hasWenshu',
  行政处罚: 'hasPunishment',
  清算信息: 'hasLiquidating',
  动产抵押: 'hasMortgage',
  经营异常: 'hasAbnormal',
  股权冻结: 'hasJudicial',
  被执行人: 'hasZxr',
  限制高消费: 'hasRestrict',
  终本案件: 'hasFinalCase',
  司法案件: 'hasJudicialCase',
}
const SeatchDataObj = {
  1: '成立年限',
  2: '注册资本',
  3: '实缴资本',
  4: '登记状态',
  5: '企业类型',
  6: '组织机构',
  7: '资本类型',
  8: '参保人数',
  9: '联系电话',
  10: '备案网站',
  11: '一般纳税人',
  12: '融资轮次',
  13: '科技型企业',
  14: '联系邮箱',
  15: '失信被执行人',
  16: '破产重整',
  17: '裁判文书',
  18: '行政处罚',
  19: '清算信息',
  20: '动产抵押',
  21: '经营异常',
  22: '股权冻结',
  23: '被执行人',
  24: '限制高消费',
  25: '终本案件',
  26: '司法案件',
  27: '小微企业',
}

const searchTypesObj = {
  1: '企业名',
  2: '法定代表人',
  3: '股东',
  4: '高管',
  5: '品牌_产品',
  6: '地址',
}

const SearchHistoryDrawer = ({ open, onClose }) => {
  const [searchHisList, setSearchHisList] = useState([])

  useEffect(() => {
    if (open) {
      try {
        checkData()
      } catch (error) {
        console.log(error)
      }
    }
  }, [open])

  const navigateLegalWebsite = (uri) => {
    const sessionId = localStorage.getItem("sessionId") || "";
    const base64SessionId = window.btoa(sessionId);
    window.open(
      `${window.legalWebUrl
      }/TransitPage?K=${base64SessionId}&redirect=${encodeURIComponent(uri)}`,
      "_blank"
    );
  };

  const replaceFun = (array) => {
    const arr = []
    let str = ''
    let str1 = ''
    array.forEach((v) => {
      v = v.replace(/@/g, '&')
      str1 = v.split('&')[1]
      str = v.split('&')[0] + '&' + SeatchDataObj[str1] + '&' + v.split('&')[2]
      arr.push(str)
    })
    return arr
  }
  const checkData = () => {
    const his_sto = localStorage.getItem('advanceHisStorage') ? JSON.parse(localStorage.getItem('advanceHisStorage')) : []
    const tempShowList = []
    his_sto.forEach((e) => {
      const item = e.data
      const tempShowDataObj = {}
      if (e.date) {
        tempShowDataObj.date = e.date
      }
      if (item.creditCodes) {
        tempShowDataObj.creditCodes = JSON.parse(item.creditCodes)
        tempShowDataObj.CreditNum = JSON.parse(item.creditCodes).length
        tempShowDataObj.creditDataArr = cityData(3, tempShowDataObj.creditCodes || []) || []
      }
      if (item.checkedCities) {
        if (JSON.parse(item.checkedCities).length) {
          const arrList = replaceFun(JSON.parse(item.checkedCities))
          console.log(arrList)
          let name = [],
            name1 = [],
            obj = {}
          const arr = {},
            key = []
          arrList.forEach((e) => {
            name = e.split('&')
            name = name.filter((e) => e != 'undefined')
            try {
              if (name[2] !== '自定义') {
                if (obj1[name[1]]) {
                  if (arr[name[1]]) {
                    arr[name[1]] = arr[name[1]] + ',' + name[2]
                  } else {
                    arr[name[1]] = name[2]
                  }
                } else {
                  name1 = obj2[name[1]].split('&')
                  obj = {}
                  if (name1[1] == 'MAX') {
                    obj.max = name[0].split('-')[1]
                    obj.min = name[0].split('-')[0]
                  } else if (name1[1] == 'ARR') {
                    obj = name[0]
                  } else {
                    obj[name1[1]] = name[0]
                  }
                  if (arr[name[1]]) {
                    arr[name[1]] = arr[name[1]] + ',' + name[2]
                  } else {
                    arr[name[1]] = name[2]
                  }
                }
              }
            } catch (error) {
              //
            }
          })

          if (arrList.includes('ALL&企业类型&自定义')) {
            if (arr['企业类型']) {
              arr['企业类型'] = arr['企业类型'] + ',' + tempShowDataObj.creditDataArr
            } else {
              arr['企业类型'] = tempShowDataObj.creditDataArr
            }
          }
          for (const val in arr) {
            key.push({
              name: val,
              data: arr[val],
            })
          }
          tempShowDataObj.TagData = key || []
          tempShowDataObj.checkedCities = arrList
        } else {
          tempShowDataObj.TagData = []
          tempShowDataObj.checkedCities = []
        }
      }
      if (JSON.parse(item.arr12).length) {
        const arr = replaceFun(JSON.parse(item.arr12))
        tempShowDataObj.arr12 = arr
      }
      if (item.areaCodes) {
        tempShowDataObj.areaCodes = JSON.parse(item.areaCodes)
        tempShowDataObj.areaCodesNum = JSON.parse(item.areaCodes).length
        tempShowDataObj.cityDataArr = cityData(1, tempShowDataObj.areaCodes || []) || []
      }
      if (item.cateGoryCodes) {
        tempShowDataObj.cateGoryCodes = JSON.parse(item.cateGoryCodes)
        tempShowDataObj.IndustryNum = JSON.parse(item.cateGoryCodes).length
        tempShowDataObj.industryDataArr = cityData(2, tempShowDataObj.cateGoryCodes || []) || []
      }
      if (item.companyName) {
        tempShowDataObj.companyName = item.companyName
      }
      if (item.searchTypes) {
        const tempArr = JSON.parse(item.searchTypes)
        let tempStr = ''
        if (tempArr.length > 0) {
          tempArr.forEach((item) => (tempStr += searchTypesObj[item] + ','))
          tempStr = tempStr.substring(0, tempStr.length - 1)
          tempShowDataObj.searchTypesString = tempStr
        } else {
          tempShowDataObj.searchTypesString = ''
        }
      }
      tempShowList.push(tempShowDataObj)
    })
    setSearchHisList(tempShowList)
  }
  //删除某一条
  const delItem = (index) => {
    const his_sto = localStorage.getItem('advanceHisStorage') ? JSON.parse(localStorage.getItem('advanceHisStorage')) : []
    his_sto.splice(index, 1)
    localStorage.setItem('advanceHisStorage', JSON.stringify(his_sto))
    checkData()
  }
  //查询某一条
  const queryItem = (index) => {
    const his_sto = localStorage.getItem('advanceHisStorage') ? JSON.parse(localStorage.getItem('advanceHisStorage')) : []
    const query = his_sto[index].data
    let url = '?'
    Object.keys(query).forEach((key) => {
      if (query[key]) {
        url = url + `${key}=${query[key]}&`
      }
    })
    url = url.substring(0, url.length - 1)
    const reUrl = '/advancedSearch/advancedList' + url
    navigateLegalWebsite(reUrl)
  }

  return (
    <Drawer width='1000px' open={open} onClose={onClose} title='历史记录' destroyOnClose>
      <div>
        {searchHisList.length ? (
          <div>
            {searchHisList.map((im, key) => {
              return (
                <div className='ims' key={key}>
                  <div className='tags'>
                    {im.companyName ? (
                      <Tag className='tag'>
                        <span className='ellipsis'>关键词 &nbsp;:&nbsp; {(im.companyName || []).toString()}</span>
                      </Tag>
                    ) : (
                      ''
                    )}

                    {im.searchTypesString ? (
                      <>
                        {('查找范围' + im.searchTypesString).length > 12 ? (
                          <Dropdown
                            overlay={
                              <Menu
                                items={[
                                  {
                                    key: '1',
                                    label: (
                                      <>
                                        {'查找范围'} &nbsp;:&nbsp; {im.searchTypesString}
                                      </>
                                    ),
                                  },
                                ]}
                              />
                            }
                            placement='bottom'
                          >
                            <Tag className='tag'>{String('查找范围' + ' :  ' + im.searchTypesString).slice(0, 16) + '  ...'}</Tag>
                          </Dropdown>
                        ) : (
                          <Tag className='tag'>
                            {'查找范围'} &nbsp;:&nbsp; {im.searchTypesString}
                          </Tag>
                        )}
                      </>
                    ) : (
                      ''
                    )}
                    {im.TagData && im.TagData.map((value, index) =>
                      (value.name + value.data).length > 12 ? (
                        <Dropdown
                          key={index}
                          overlay={
                            <Menu
                              items={[
                                {
                                  key: '1',
                                  label: (
                                    <>
                                      {value.name} &nbsp;:&nbsp; {value.data}
                                    </>
                                  ),
                                },
                              ]}
                            />
                          }
                          placement='bottom'
                        >
                          <Tag key={index} className='tag'>
                            {String(value.name + ' :  ' + value.data).slice(0, 16) + '  ...'}
                          </Tag>
                        </Dropdown>
                      ) : (
                        <Tag key={index} className='tag'>
                          {value.name} &nbsp;:&nbsp; {value.data}
                        </Tag>
                      ),
                    )}
                    {im.cityDataArr && im.cityDataArr.length ? (
                      <Dropdown
                        overlayStyle={{ maxWidth: 300, maxHeight: 300, overflow: 'auto' }}
                        key={999}
                        overlay={
                          <Menu
                            items={[
                              {
                                key: '1',
                                label: (im.cityDataArr || []).toString(),
                              },
                            ]}
                          />
                        }
                        placement='bottom'
                      >
                        <Tag className='tag'>
                          <span className='ellipsis'>{(im.cityDataArr || []).toString()}</span>
                        </Tag>
                      </Dropdown>
                    ) : (
                      ''
                    )}
                    {im.industryDataArr && im.industryDataArr.length ? (
                      <Dropdown
                        overlayStyle={{ maxWidth: 300, maxHeight: 300, overflow: 'auto' }}
                        key={998}
                        overlay={
                          <Menu
                            items={[
                              {
                                key: '1',
                                label: (im.industryDataArr || []).toString(),
                              },
                            ]}
                          />
                        }
                        placement='bottom'
                      >
                        <Tag className='tag'>
                          <span className='ellipsis'>{(im.industryDataArr || []).toString()}</span>
                        </Tag>
                      </Dropdown>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='boms'>
                    <span style={{ color: '#919498', fontSize: 12 }}>{im.date}</span>
                    <div className='btn'>
                      <Popconfirm title='是否删除该条历史记录？' okText='确认删除' cancelText='取消' onConfirm={() => delItem(key)}>
                        <a style={{ color: '#242A32', marginRight: '16px' }}>删除</a>
                      </Popconfirm>

                      <a onClick={() => queryItem(key)}>查询</a>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <Empty description='暂无历史记录' />
        )}
        <style>
          {`
                .ims{
                    border-bottom: 1px solid #F0F0F0;
                    padding: 15px 0;
                }
                .ims .tags{
                    max-height: 120px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    -webkit-line-clamp: 3;
                    text-overflow: ellipsis;
                }
                .boms{
                    display: flex;
                    justify-content: space-between;
                    padding: 0 6px;
                    margin-top: 12px;
                }
                .tag{
                    font-size: 13px;
                    color: #3373FC;
                    margin-top: 10px;
                    padding: 6px 8px;
                    border:none;
                    background:rgba(23, 117, 255, 0.10);
                }
                .ellipsis{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: inline-block;
                    max-width: 192px;
                    margin-bottom: -5px;
                }
            `}
        </style>
      </div>
    </Drawer>
  )
}

export default SearchHistoryDrawer
