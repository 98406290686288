import { Industry } from "./industryNew";

export const demissionList = [
  {
    children: [
      {
        value: '35',
        label: '公司名称变更',
        level: '2',
      },
      {
        value: '36',
        label: '注册资本变更',
        level: '2',
      },
      {
        value: '37',
        label: '注册地址变更',
        level: '2',
      },
      {
        value: '38',
        label: '经营范围变更',
        level: '2',
      },
      {
        value: '39',
        label: '公司状态变更',
        level: '2',
      },
      {
        value: '40',
        label: '法定代表人变更',
        level: '2',
      },
      {
        value: '41',
        label: '成员变更',
        level: '2',
      },
      {
        value: '42',
        label: '股东变更',
        level: '2',
      },
      {
        value: '43',
        label: '对外投资',
        level: '2',
      },
      {
        value: '44',
        label: '主要成员变更',
        level: '2',
      },
    ],
    value: '100',
    label: '工商信息',
    level: '1',
  },
  {
    children: [
      {
        value: '27',
        label: '抽查检查',
        level: '2',
      },
      {
        value: '28',
        label: '双随机抽查',
        level: '2',
      },
      {
        value: '29',
        label: '融资信息',
        level: '2',
      },
      {
        value: '30',
        label: '行政许可',
        level: '2',
      },
      {
        value: '31',
        label: '购地信息',
        level: '2',
      },
      {
        value: '32',
        label: '地块公示',
        level: '2',
      },
      {
        value: '33',
        label: '土地转让',
        level: '2',
      },
      {
        value: '34',
        label: '知识产权',
        level: '2',
      },
    ],
    value: '200',
    label: '经营信息',
    level: '1',
  },
  {
    children: [
      {
        value: '2',
        label: '法院公告',
        level: '2',
      },
      {
        value: '3',
        label: '开庭公告',
        level: '2',
      },
      {
        value: '4',
        label: '立案信息',
        level: '2',
      },
      {
        value: '8',
        label: '送达公告',
        level: '2',
      },
      {
        value: '13',
        label: '破产信息',
        level: '2',
      },
      {
        value: '15',
        label: '失信被执行人',
        level: '2',
      },
      {
        value: '17',
        label: '被执行人',
        level: '2',
      },
      {
        value: '18',
        label: '终本案件',
        level: '2',
      },
      {
        value: '19',
        label: '限制高消费',
        level: '2',
      },
      {
        value: '20',
        label: '限制出境',
        level: '2',
      },
      {
        value: '26',
        label: '裁判文书',
        level: '2',
      },
    ],
    value: '300',
    label: '司法风险',
    level: '1',
  },
  {
    children: [
      {
        value: '1',
        label: '经营异常',
        level: '2',
      },
      {
        value: '5',
        label: '环保处罚',
        level: '2',
      },
      {
        value: '6',
        label: '严重违法',
        level: '2',
      },
      {
        value: '7',
        label: '行政处罚',
        level: '2',
      },
      {
        value: '9',
        label: '税收违法',
        level: '2',
      },
      {
        value: '10',
        label: '司法拍卖',
        level: '2',
      },
      {
        value: '11',
        label: '欠税公告',
        level: '2',
      },
      {
        value: '12',
        label: '询价评估结果',
        level: '2',
      },
      {
        value: '14',
        label: '简易注销',
        level: '2',
      },
      {
        value: '16',
        label: '公示催告',
        level: '2',
      },
      {
        value: '21',
        label: '股权出质',
        level: '2',
      },
      {
        value: '22',
        label: '股权质押',
        level: '2',
      },
      {
        value: '23',
        label: '股权冻结',
        level: '2',
      },
      {
        value: '24',
        label: '土地抵押',
        level: '2',
      },
      {
        value: '25',
        label: '动产抵押',
        level: '2',
      },
    ],
    value: '400',
    label: '经营风险',
    level: '1',
  },
]
export const cityByCodeData = [
  {
    label: '北京',
    value: '110000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '北京市',
        value: '110100',
        level: 2,
        firstChar: 'B',
        children: [
          {
            label: '东城区',
            value: '110101',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西城区',
            value: '110102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '朝阳区',
            value: '110105',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '丰台区',
            value: '110106',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '石景山区',
            value: '110107',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '海淀区',
            value: '110108',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '门头沟区',
            value: '110109',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '房山区',
            value: '110111',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '通州区',
            value: '110112',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '顺义区',
            value: '110113',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '昌平区',
            value: '110114',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大兴区',
            value: '110115',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '怀柔区',
            value: '110116',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平谷区',
            value: '110117',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '密云区',
            value: '110118',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '延庆区',
            value: '110119',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '天津',
    value: '120000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '天津市',
        value: '120100',
        level: 2,
        firstChar: 'T',
        children: [
          {
            label: '和平区',
            value: '120101',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '河东区',
            value: '120102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '河西区',
            value: '120103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南开区',
            value: '120104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '河北区',
            value: '120105',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '红桥区',
            value: '120106',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东丽区',
            value: '120110',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西青区',
            value: '120111',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '津南区',
            value: '120112',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '北辰区',
            value: '120113',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '武清区',
            value: '120114',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宝坻区',
            value: '120115',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '滨海新区',
            value: '120116',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宁河区',
            value: '120117',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '静海区',
            value: '120118',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '蓟州区',
            value: '120119',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '河北',
    value: '130000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '石家庄市',
        value: '130100',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '长安区',
            value: '130102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '桥西区',
            value: '130104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新华区',
            value: '130105',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '井陉矿区',
            value: '130107',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '裕华区',
            value: '130108',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '藁城区',
            value: '130109',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鹿泉区',
            value: '130110',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '栾城区',
            value: '130111',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '井陉县',
            value: '130121',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '正定县',
            value: '130123',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '行唐县',
            value: '130125',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '灵寿县',
            value: '130126',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '高邑县',
            value: '130127',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '深泽县',
            value: '130128',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '赞皇县',
            value: '130129',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '无极县',
            value: '130130',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平山县',
            value: '130131',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '元氏县',
            value: '130132',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '赵县',
            value: '130133',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '石家庄高新技术产业开发区',
            value: '130171',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '石家庄循环化工园区',
            value: '130172',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '辛集市',
            value: '130181',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '晋州市',
            value: '130183',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新乐市',
            value: '130184',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '唐山市',
        value: '130200',
        level: 2,
        firstChar: 'T',
        children: [
          {
            label: '路南区',
            value: '130202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '路北区',
            value: '130203',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '古冶区',
            value: '130204',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '开平区',
            value: '130205',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '丰南区',
            value: '130207',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '丰润区',
            value: '130208',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '曹妃甸区',
            value: '130209',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '滦南县',
            value: '130224',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乐亭县',
            value: '130225',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '迁西县',
            value: '130227',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '玉田县',
            value: '130229',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '河北唐山芦台经济开发区',
            value: '130271',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '唐山市汉沽管理区',
            value: '130272',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '唐山高新技术产业开发区',
            value: '130273',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '河北唐山海港经济开发区',
            value: '130274',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '遵化市',
            value: '130281',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '迁安市',
            value: '130283',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '滦州市',
            value: '130284',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '秦皇岛市',
        value: '130300',
        level: 2,
        firstChar: 'Q',
        children: [
          {
            label: '海港区',
            value: '130302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '山海关区',
            value: '130303',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '北戴河区',
            value: '130304',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '抚宁区',
            value: '130306',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '青龙满族自治县',
            value: '130321',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '昌黎县',
            value: '130322',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '卢龙县',
            value: '130324',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '秦皇岛市经济技术开发区',
            value: '130371',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '北戴河新区',
            value: '130372',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '邯郸市',
        value: '130400',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '邯山区',
            value: '130402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '丛台区',
            value: '130403',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '复兴区',
            value: '130404',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '峰峰矿区',
            value: '130406',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '肥乡区',
            value: '130407',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永年区',
            value: '130408',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '临漳县',
            value: '130423',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '成安县',
            value: '130424',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大名县',
            value: '130425',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '涉县',
            value: '130426',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '磁县',
            value: '130427',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '邱县',
            value: '130430',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鸡泽县',
            value: '130431',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '广平县',
            value: '130432',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '馆陶县',
            value: '130433',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '魏县',
            value: '130434',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '曲周县',
            value: '130435',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '邯郸经济技术开发区',
            value: '130471',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '邯郸冀南新区',
            value: '130473',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '武安市',
            value: '130481',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '邢台市',
        value: '130500',
        level: 2,
        firstChar: 'X',
        children: [
          {
            label: '襄都区',
            value: '130502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '信都区',
            value: '130503',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '任泽区',
            value: '130505',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南和区',
            value: '130506',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '临城县',
            value: '130522',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '内丘县',
            value: '130523',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '柏乡县',
            value: '130524',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '隆尧县',
            value: '130525',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宁晋县',
            value: '130528',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '巨鹿县',
            value: '130529',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新河县',
            value: '130530',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '广宗县',
            value: '130531',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平乡县',
            value: '130532',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '威县',
            value: '130533',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '清河县',
            value: '130534',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '临西县',
            value: '130535',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '河北邢台经济开发区',
            value: '130571',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南宫市',
            value: '130581',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沙河市',
            value: '130582',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '保定市',
        value: '130600',
        level: 2,
        firstChar: 'B',
        children: [
          {
            label: '竞秀区',
            value: '130602',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '莲池区',
            value: '130606',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '满城区',
            value: '130607',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '清苑区',
            value: '130608',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '徐水区',
            value: '130609',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '涞水县',
            value: '130623',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阜平县',
            value: '130624',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '定兴县',
            value: '130626',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '唐县',
            value: '130627',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '高阳县',
            value: '130628',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '容城县',
            value: '130629',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '涞源县',
            value: '130630',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '望都县',
            value: '130631',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安新县',
            value: '130632',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '易县',
            value: '130633',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '曲阳县',
            value: '130634',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '蠡县',
            value: '130635',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '顺平县',
            value: '130636',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '博野县',
            value: '130637',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '雄县',
            value: '130638',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '保定高新技术产业开发区',
            value: '130671',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '保定白沟新城',
            value: '130672',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '涿州市',
            value: '130681',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '定州市',
            value: '130682',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安国市',
            value: '130683',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '高碑店市',
            value: '130684',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '张家口市',
        value: '130700',
        level: 2,
        firstChar: 'Z',
        children: [
          {
            label: '桥东区',
            value: '130702',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '桥西区',
            value: '130703',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宣化区',
            value: '130705',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '下花园区',
            value: '130706',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '万全区',
            value: '130708',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '崇礼区',
            value: '130709',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '张北县',
            value: '130722',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '康保县',
            value: '130723',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沽源县',
            value: '130724',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '尚义县',
            value: '130725',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '蔚县',
            value: '130726',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阳原县',
            value: '130727',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '怀安县',
            value: '130728',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '怀来县',
            value: '130730',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '涿鹿县',
            value: '130731',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '赤城县',
            value: '130732',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '张家口经济开发区',
            value: '130771',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '张家口市察北管理区',
            value: '130772',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '张家口市塞北管理区',
            value: '130773',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '承德市',
        value: '130800',
        level: 2,
        firstChar: 'C',
        children: [
          {
            label: '双桥区',
            value: '130802',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '双滦区',
            value: '130803',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鹰手营子矿区',
            value: '130804',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '承德县',
            value: '130821',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '兴隆县',
            value: '130822',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '滦平县',
            value: '130824',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '隆化县',
            value: '130825',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '丰宁满族自治县',
            value: '130826',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宽城满族自治县',
            value: '130827',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '围场满族蒙古族自治县',
            value: '130828',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '承德高新技术产业开发区',
            value: '130871',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平泉市',
            value: '130881',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '沧州市',
        value: '130900',
        level: 2,
        firstChar: 'C',
        children: [
          {
            label: '新华区',
            value: '130902',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '运河区',
            value: '130903',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沧县',
            value: '130921',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '青县',
            value: '130922',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东光县',
            value: '130923',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '海兴县',
            value: '130924',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '盐山县',
            value: '130925',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '肃宁县',
            value: '130926',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南皮县',
            value: '130927',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '吴桥县',
            value: '130928',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '献县',
            value: '130929',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '孟村回族自治县',
            value: '130930',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '河北沧州经济开发区',
            value: '130971',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沧州高新技术产业开发区',
            value: '130972',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沧州渤海新区',
            value: '130973',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '泊头市',
            value: '130981',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '任丘市',
            value: '130982',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '黄骅市',
            value: '130983',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '河间市',
            value: '130984',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '廊坊市',
        value: '131000',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '安次区',
            value: '131002',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '广阳区',
            value: '131003',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '固安县',
            value: '131022',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永清县',
            value: '131023',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '香河县',
            value: '131024',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大城县',
            value: '131025',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '文安县',
            value: '131026',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大厂回族自治县',
            value: '131028',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '廊坊经济技术开发区',
            value: '131071',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '霸州市',
            value: '131081',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '三河市',
            value: '131082',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '衡水市',
        value: '131100',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '桃城区',
            value: '131102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '冀州区',
            value: '131103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '枣强县',
            value: '131121',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '武邑县',
            value: '131122',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '武强县',
            value: '131123',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '饶阳县',
            value: '131124',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安平县',
            value: '131125',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '故城县',
            value: '131126',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '景县',
            value: '131127',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阜城县',
            value: '131128',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '河北衡水高新技术产业开发区',
            value: '131171',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '衡水滨湖新区',
            value: '131172',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '深州市',
            value: '131182',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '山西',
    value: '140000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '太原市',
        value: '140100',
        level: 2,
        firstChar: 'T',
        children: [
          {
            label: '小店区',
            value: '140105',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '迎泽区',
            value: '140106',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '杏花岭区',
            value: '140107',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '尖草坪区',
            value: '140108',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '万柏林区',
            value: '140109',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '晋源区',
            value: '140110',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '清徐县',
            value: '140121',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阳曲县',
            value: '140122',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '娄烦县',
            value: '140123',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '山西转型综合改革示范区',
            value: '140171',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '古交市',
            value: '140181',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '大同市',
        value: '140200',
        level: 2,
        firstChar: 'D',
        children: [
          {
            label: '新荣区',
            value: '140212',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平城区',
            value: '140213',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '云冈区',
            value: '140214',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '云州区',
            value: '140215',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阳高县',
            value: '140221',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '天镇县',
            value: '140222',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '广灵县',
            value: '140223',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '灵丘县',
            value: '140224',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '浑源县',
            value: '140225',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '左云县',
            value: '140226',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '山西大同经济开发区',
            value: '140271',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '阳泉市',
        value: '140300',
        level: 2,
        firstChar: 'Y',
        children: [
          {
            label: '城区',
            value: '140302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '矿区',
            value: '140303',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '郊区',
            value: '140311',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平定县',
            value: '140321',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '盂县',
            value: '140322',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '长治市',
        value: '140400',
        level: 2,
        firstChar: 'Z',
        children: [
          {
            label: '潞州区',
            value: '140403',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '上党区',
            value: '140404',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '屯留区',
            value: '140405',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '潞城区',
            value: '140406',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '襄垣县',
            value: '140423',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平顺县',
            value: '140425',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '黎城县',
            value: '140426',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '壶关县',
            value: '140427',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '长子县',
            value: '140428',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '武乡县',
            value: '140429',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沁县',
            value: '140430',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沁源县',
            value: '140431',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '山西长治高新技术产业园区',
            value: '140471',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '晋城市',
        value: '140500',
        level: 2,
        firstChar: 'J',
        children: [
          {
            label: '城区',
            value: '140502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沁水县',
            value: '140521',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阳城县',
            value: '140522',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '陵川县',
            value: '140524',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '泽州县',
            value: '140525',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '高平市',
            value: '140581',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '朔州市',
        value: '140600',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '朔城区',
            value: '140602',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平鲁区',
            value: '140603',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '山阴县',
            value: '140621',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '应县',
            value: '140622',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '右玉县',
            value: '140623',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '山西朔州经济开发区',
            value: '140671',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '怀仁市',
            value: '140681',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '晋中市',
        value: '140700',
        level: 2,
        firstChar: 'J',
        children: [
          {
            label: '榆次区',
            value: '140702',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '太谷区',
            value: '140703',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '榆社县',
            value: '140721',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '左权县',
            value: '140722',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '和顺县',
            value: '140723',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '昔阳县',
            value: '140724',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '寿阳县',
            value: '140725',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '祁县',
            value: '140727',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平遥县',
            value: '140728',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '灵石县',
            value: '140729',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '介休市',
            value: '140781',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '运城市',
        value: '140800',
        level: 2,
        firstChar: 'Y',
        children: [
          {
            label: '盐湖区',
            value: '140802',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '临猗县',
            value: '140821',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '万荣县',
            value: '140822',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '闻喜县',
            value: '140823',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '稷山县',
            value: '140824',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新绛县',
            value: '140825',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '绛县',
            value: '140826',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '垣曲县',
            value: '140827',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '夏县',
            value: '140828',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平陆县',
            value: '140829',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '芮城县',
            value: '140830',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永济市',
            value: '140881',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '河津市',
            value: '140882',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '忻州市',
        value: '140900',
        level: 2,
        firstChar: 'X',
        children: [
          {
            label: '忻府区',
            value: '140902',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '定襄县',
            value: '140921',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '五台县',
            value: '140922',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '代县',
            value: '140923',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '繁峙县',
            value: '140924',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宁武县',
            value: '140925',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '静乐县',
            value: '140926',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '神池县',
            value: '140927',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '五寨县',
            value: '140928',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '岢岚县',
            value: '140929',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '河曲县',
            value: '140930',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '保德县',
            value: '140931',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '偏关县',
            value: '140932',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '五台山风景名胜区',
            value: '140971',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '原平市',
            value: '140981',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '临汾市',
        value: '141000',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '尧都区',
            value: '141002',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '曲沃县',
            value: '141021',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '翼城县',
            value: '141022',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '襄汾县',
            value: '141023',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '洪洞县',
            value: '141024',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '古县',
            value: '141025',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安泽县',
            value: '141026',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '浮山县',
            value: '141027',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '吉县',
            value: '141028',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乡宁县',
            value: '141029',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大宁县',
            value: '141030',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '隰县',
            value: '141031',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永和县',
            value: '141032',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '蒲县',
            value: '141033',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '汾西县',
            value: '141034',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '侯马市',
            value: '141081',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '霍州市',
            value: '141082',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '吕梁市',
        value: '141100',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '离石区',
            value: '141102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '文水县',
            value: '141121',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '交城县',
            value: '141122',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '兴县',
            value: '141123',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '临县',
            value: '141124',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '柳林县',
            value: '141125',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '石楼县',
            value: '141126',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '岚县',
            value: '141127',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '方山县',
            value: '141128',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '中阳县',
            value: '141129',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '交口县',
            value: '141130',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '孝义市',
            value: '141181',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '汾阳市',
            value: '141182',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '内蒙古',
    value: '150000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '呼和浩特市',
        value: '150100',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '新城区',
            value: '150102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '回民区',
            value: '150103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '玉泉区',
            value: '150104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '赛罕区',
            value: '150105',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '土默特左旗',
            value: '150121',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '托克托县',
            value: '150122',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '和林格尔县',
            value: '150123',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '清水河县',
            value: '150124',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '武川县',
            value: '150125',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '呼和浩特经济技术开发区',
            value: '150172',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '包头市',
        value: '150200',
        level: 2,
        firstChar: 'B',
        children: [
          {
            label: '东河区',
            value: '150202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '昆都仑区',
            value: '150203',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '青山区',
            value: '150204',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '石拐区',
            value: '150205',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '白云鄂博矿区',
            value: '150206',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '九原区',
            value: '150207',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '土默特右旗',
            value: '150221',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '固阳县',
            value: '150222',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '达尔罕茂明安联合旗',
            value: '150223',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '包头稀土高新技术产业开发区',
            value: '150271',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '乌海市',
        value: '150300',
        level: 2,
        firstChar: 'W',
        children: [
          {
            label: '海勃湾区',
            value: '150302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '海南区',
            value: '150303',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乌达区',
            value: '150304',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '赤峰市',
        value: '150400',
        level: 2,
        firstChar: 'C',
        children: [
          {
            label: '红山区',
            value: '150402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '元宝山区',
            value: '150403',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '松山区',
            value: '150404',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阿鲁科尔沁旗',
            value: '150421',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '巴林左旗',
            value: '150422',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '巴林右旗',
            value: '150423',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '林西县',
            value: '150424',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '克什克腾旗',
            value: '150425',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '翁牛特旗',
            value: '150426',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '喀喇沁旗',
            value: '150428',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宁城县',
            value: '150429',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '敖汉旗',
            value: '150430',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '通辽市',
        value: '150500',
        level: 2,
        firstChar: 'T',
        children: [
          {
            label: '科尔沁区',
            value: '150502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '科尔沁左翼中旗',
            value: '150521',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '科尔沁左翼后旗',
            value: '150522',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '开鲁县',
            value: '150523',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '库伦旗',
            value: '150524',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '奈曼旗',
            value: '150525',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '扎鲁特旗',
            value: '150526',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '通辽经济技术开发区',
            value: '150571',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '霍林郭勒市',
            value: '150581',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '鄂尔多斯市',
        value: '150600',
        level: 2,
        firstChar: 'E',
        children: [
          {
            label: '东胜区',
            value: '150602',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '康巴什区',
            value: '150603',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '达拉特旗',
            value: '150621',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '准格尔旗',
            value: '150622',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鄂托克前旗',
            value: '150623',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鄂托克旗',
            value: '150624',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '杭锦旗',
            value: '150625',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乌审旗',
            value: '150626',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '伊金霍洛旗',
            value: '150627',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '呼伦贝尔市',
        value: '150700',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '海拉尔区',
            value: '150702',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '扎赉诺尔区',
            value: '150703',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阿荣旗',
            value: '150721',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '莫力达瓦达斡尔族自治旗',
            value: '150722',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鄂伦春自治旗',
            value: '150723',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鄂温克族自治旗',
            value: '150724',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '陈巴尔虎旗',
            value: '150725',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新巴尔虎左旗',
            value: '150726',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新巴尔虎右旗',
            value: '150727',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '满洲里市',
            value: '150781',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '牙克石市',
            value: '150782',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '扎兰屯市',
            value: '150783',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '额尔古纳市',
            value: '150784',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '根河市',
            value: '150785',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '巴彦淖尔市',
        value: '150800',
        level: 2,
        firstChar: 'B',
        children: [
          {
            label: '临河区',
            value: '150802',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '五原县',
            value: '150821',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '磴口县',
            value: '150822',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乌拉特前旗',
            value: '150823',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乌拉特中旗',
            value: '150824',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乌拉特后旗',
            value: '150825',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '杭锦后旗',
            value: '150826',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '乌兰察布市',
        value: '150900',
        level: 2,
        firstChar: 'W',
        children: [
          {
            label: '集宁区',
            value: '150902',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '卓资县',
            value: '150921',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '化德县',
            value: '150922',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '商都县',
            value: '150923',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '兴和县',
            value: '150924',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '凉城县',
            value: '150925',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '察哈尔右翼前旗',
            value: '150926',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '察哈尔右翼中旗',
            value: '150927',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '察哈尔右翼后旗',
            value: '150928',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '四子王旗',
            value: '150929',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '丰镇市',
            value: '150981',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '兴安盟',
        value: '152200',
        level: 2,
        firstChar: 'X',
        children: [
          {
            label: '乌兰浩特市',
            value: '152201',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阿尔山市',
            value: '152202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '科尔沁右翼前旗',
            value: '152221',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '科尔沁右翼中旗',
            value: '152222',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '扎赉特旗',
            value: '152223',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '突泉县',
            value: '152224',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '锡林郭勒盟',
        value: '152500',
        level: 2,
        firstChar: 'X',
        children: [
          {
            label: '二连浩特市',
            value: '152501',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '锡林浩特市',
            value: '152502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阿巴嘎旗',
            value: '152522',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '苏尼特左旗',
            value: '152523',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '苏尼特右旗',
            value: '152524',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东乌珠穆沁旗',
            value: '152525',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西乌珠穆沁旗',
            value: '152526',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '太仆寺旗',
            value: '152527',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '镶黄旗',
            value: '152528',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '正镶白旗',
            value: '152529',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '正蓝旗',
            value: '152530',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '多伦县',
            value: '152531',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乌拉盖管委会',
            value: '152571',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '阿拉善盟',
        value: '152900',
        level: 2,
        firstChar: 'A',
        children: [
          {
            label: '阿拉善左旗',
            value: '152921',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阿拉善右旗',
            value: '152922',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '额济纳旗',
            value: '152923',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '内蒙古阿拉善经济开发区',
            value: '152971',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '辽宁',
    value: '210000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '沈阳市',
        value: '210100',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '和平区',
            value: '210102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沈河区',
            value: '210103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大东区',
            value: '210104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '皇姑区',
            value: '210105',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '铁西区',
            value: '210106',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '苏家屯区',
            value: '210111',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '浑南区',
            value: '210112',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沈北新区',
            value: '210113',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '于洪区',
            value: '210114',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '辽中区',
            value: '210115',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '康平县',
            value: '210123',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '法库县',
            value: '210124',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新民市',
            value: '210181',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '大连市',
        value: '210200',
        level: 2,
        firstChar: 'D',
        children: [
          {
            label: '中山区',
            value: '210202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西岗区',
            value: '210203',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沙河口区',
            value: '210204',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '甘井子区',
            value: '210211',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '旅顺口区',
            value: '210212',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '金州区',
            value: '210213',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '普兰店区',
            value: '210214',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '长海县',
            value: '210224',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '瓦房店市',
            value: '210281',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '庄河市',
            value: '210283',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '鞍山市',
        value: '210300',
        level: 2,
        firstChar: 'A',
        children: [
          {
            label: '铁东区',
            value: '210302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '铁西区',
            value: '210303',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '立山区',
            value: '210304',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '千山区',
            value: '210311',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '台安县',
            value: '210321',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '岫岩满族自治县',
            value: '210323',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '海城市',
            value: '210381',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '抚顺市',
        value: '210400',
        level: 2,
        firstChar: 'F',
        children: [
          {
            label: '新抚区',
            value: '210402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东洲区',
            value: '210403',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '望花区',
            value: '210404',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '顺城区',
            value: '210411',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '抚顺县',
            value: '210421',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新宾满族自治县',
            value: '210422',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '清原满族自治县',
            value: '210423',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '本溪市',
        value: '210500',
        level: 2,
        firstChar: 'B',
        children: [
          {
            label: '平山区',
            value: '210502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '溪湖区',
            value: '210503',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '明山区',
            value: '210504',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南芬区',
            value: '210505',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '本溪满族自治县',
            value: '210521',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '桓仁满族自治县',
            value: '210522',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '丹东市',
        value: '210600',
        level: 2,
        firstChar: 'D',
        children: [
          {
            label: '元宝区',
            value: '210602',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '振兴区',
            value: '210603',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '振安区',
            value: '210604',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宽甸满族自治县',
            value: '210624',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东港市',
            value: '210681',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '凤城市',
            value: '210682',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '锦州市',
        value: '210700',
        level: 2,
        firstChar: 'J',
        children: [
          {
            label: '古塔区',
            value: '210702',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '凌河区',
            value: '210703',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '太和区',
            value: '210711',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '黑山县',
            value: '210726',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '义县',
            value: '210727',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '凌海市',
            value: '210781',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '北镇市',
            value: '210782',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '营口市',
        value: '210800',
        level: 2,
        firstChar: 'Y',
        children: [
          {
            label: '站前区',
            value: '210802',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西市区',
            value: '210803',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鲅鱼圈区',
            value: '210804',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '老边区',
            value: '210811',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '盖州市',
            value: '210881',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大石桥市',
            value: '210882',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '阜新市',
        value: '210900',
        level: 2,
        firstChar: 'F',
        children: [
          {
            label: '海州区',
            value: '210902',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新邱区',
            value: '210903',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '太平区',
            value: '210904',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '清河门区',
            value: '210905',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '细河区',
            value: '210911',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阜新蒙古族自治县',
            value: '210921',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '彰武县',
            value: '210922',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '辽阳市',
        value: '211000',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '白塔区',
            value: '211002',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '文圣区',
            value: '211003',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宏伟区',
            value: '211004',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '弓长岭区',
            value: '211005',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '太子河区',
            value: '211011',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '辽阳县',
            value: '211021',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '灯塔市',
            value: '211081',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '盘锦市',
        value: '211100',
        level: 2,
        firstChar: 'P',
        children: [
          {
            label: '双台子区',
            value: '211102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '兴隆台区',
            value: '211103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大洼区',
            value: '211104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '盘山县',
            value: '211122',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '铁岭市',
        value: '211200',
        level: 2,
        firstChar: 'T',
        children: [
          {
            label: '银州区',
            value: '211202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '清河区',
            value: '211204',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '铁岭县',
            value: '211221',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西丰县',
            value: '211223',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '昌图县',
            value: '211224',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '调兵山市',
            value: '211281',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '开原市',
            value: '211282',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '朝阳市',
        value: '211300',
        level: 2,
        firstChar: 'C',
        children: [
          {
            label: '双塔区',
            value: '211302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙城区',
            value: '211303',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '朝阳县',
            value: '211321',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '建平县',
            value: '211322',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '喀喇沁左翼蒙古族自治县',
            value: '211324',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '北票市',
            value: '211381',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '凌源市',
            value: '211382',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '葫芦岛市',
        value: '211400',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '连山区',
            value: '211402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙港区',
            value: '211403',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南票区',
            value: '211404',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '绥中县',
            value: '211421',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '建昌县',
            value: '211422',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '兴城市',
            value: '211481',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '吉林',
    value: '220000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '长春市',
        value: '220100',
        level: 2,
        firstChar: 'Z',
        children: [
          {
            label: '南关区',
            value: '220102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宽城区',
            value: '220103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '朝阳区',
            value: '220104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '二道区',
            value: '220105',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '绿园区',
            value: '220106',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '双阳区',
            value: '220112',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '九台区',
            value: '220113',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '农安县',
            value: '220122',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '长春经济技术开发区',
            value: '220171',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '长春净月高新技术产业开发区',
            value: '220172',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '长春高新技术产业开发区',
            value: '220173',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '长春汽车经济技术开发区',
            value: '220174',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '榆树市',
            value: '220182',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '德惠市',
            value: '220183',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '公主岭市',
            value: '220184',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '吉林市',
        value: '220200',
        level: 2,
        firstChar: 'J',
        children: [
          {
            label: '昌邑区',
            value: '220202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙潭区',
            value: '220203',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '船营区',
            value: '220204',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '丰满区',
            value: '220211',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永吉县',
            value: '220221',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '吉林经济开发区',
            value: '220271',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '吉林高新技术产业开发区',
            value: '220272',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '吉林中国新加坡食品区',
            value: '220273',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '蛟河市',
            value: '220281',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '桦甸市',
            value: '220282',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '舒兰市',
            value: '220283',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '磐石市',
            value: '220284',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '四平市',
        value: '220300',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '铁西区',
            value: '220302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '铁东区',
            value: '220303',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '梨树县',
            value: '220322',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '伊通满族自治县',
            value: '220323',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '双辽市',
            value: '220382',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '辽源市',
        value: '220400',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '龙山区',
            value: '220402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西安区',
            value: '220403',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东丰县',
            value: '220421',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东辽县',
            value: '220422',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '通化市',
        value: '220500',
        level: 2,
        firstChar: 'T',
        children: [
          {
            label: '东昌区',
            value: '220502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '二道江区',
            value: '220503',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '通化县',
            value: '220521',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '辉南县',
            value: '220523',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '柳河县',
            value: '220524',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '梅河口市',
            value: '220581',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '集安市',
            value: '220582',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '白山市',
        value: '220600',
        level: 2,
        firstChar: 'B',
        children: [
          {
            label: '浑江区',
            value: '220602',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '江源区',
            value: '220605',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '抚松县',
            value: '220621',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '靖宇县',
            value: '220622',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '长白朝鲜族自治县',
            value: '220623',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '临江市',
            value: '220681',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '松原市',
        value: '220700',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '宁江区',
            value: '220702',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '前郭尔罗斯蒙古族自治县',
            value: '220721',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '长岭县',
            value: '220722',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乾安县',
            value: '220723',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '吉林松原经济开发区',
            value: '220771',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '扶余市',
            value: '220781',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '白城市',
        value: '220800',
        level: 2,
        firstChar: 'B',
        children: [
          {
            label: '洮北区',
            value: '220802',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '镇赉县',
            value: '220821',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '通榆县',
            value: '220822',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '吉林白城经济开发区',
            value: '220871',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '洮南市',
            value: '220881',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大安市',
            value: '220882',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '延边朝鲜族自治州',
        value: '222400',
        level: 2,
        firstChar: 'Y',
        children: [
          {
            label: '延吉市',
            value: '222401',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '图们市',
            value: '222402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '敦化市',
            value: '222403',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '珲春市',
            value: '222404',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙井市',
            value: '222405',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '和龙市',
            value: '222406',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '汪清县',
            value: '222424',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安图县',
            value: '222426',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '黑龙江',
    value: '230000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '哈尔滨市',
        value: '230100',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '道里区',
            value: '230102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南岗区',
            value: '230103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '道外区',
            value: '230104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平房区',
            value: '230108',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '松北区',
            value: '230109',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '香坊区',
            value: '230110',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '呼兰区',
            value: '230111',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阿城区',
            value: '230112',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '双城区',
            value: '230113',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '依兰县',
            value: '230123',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '方正县',
            value: '230124',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宾县',
            value: '230125',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '巴彦县',
            value: '230126',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '木兰县',
            value: '230127',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '通河县',
            value: '230128',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '延寿县',
            value: '230129',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '尚志市',
            value: '230183',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '五常市',
            value: '230184',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '齐齐哈尔市',
        value: '230200',
        level: 2,
        firstChar: 'Q',
        children: [
          {
            label: '龙沙区',
            value: '230202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '建华区',
            value: '230203',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '铁锋区',
            value: '230204',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '昂昂溪区',
            value: '230205',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '富拉尔基区',
            value: '230206',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '碾子山区',
            value: '230207',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '梅里斯达斡尔族区',
            value: '230208',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙江县',
            value: '230221',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '依安县',
            value: '230223',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '泰来县',
            value: '230224',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '甘南县',
            value: '230225',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '富裕县',
            value: '230227',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '克山县',
            value: '230229',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '克东县',
            value: '230230',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '拜泉县',
            value: '230231',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '讷河市',
            value: '230281',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '鸡西市',
        value: '230300',
        level: 2,
        firstChar: 'J',
        children: [
          {
            label: '鸡冠区',
            value: '230302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '恒山区',
            value: '230303',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '滴道区',
            value: '230304',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '梨树区',
            value: '230305',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '城子河区',
            value: '230306',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '麻山区',
            value: '230307',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鸡东县',
            value: '230321',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '虎林市',
            value: '230381',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '密山市',
            value: '230382',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '鹤岗市',
        value: '230400',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '向阳区',
            value: '230402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '工农区',
            value: '230403',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南山区',
            value: '230404',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '兴安区',
            value: '230405',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东山区',
            value: '230406',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '兴山区',
            value: '230407',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '萝北县',
            value: '230421',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '绥滨县',
            value: '230422',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '双鸭山市',
        value: '230500',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '尖山区',
            value: '230502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '岭东区',
            value: '230503',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '四方台区',
            value: '230505',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宝山区',
            value: '230506',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '集贤县',
            value: '230521',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '友谊县',
            value: '230522',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宝清县',
            value: '230523',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '饶河县',
            value: '230524',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '大庆市',
        value: '230600',
        level: 2,
        firstChar: 'D',
        children: [
          {
            label: '萨尔图区',
            value: '230602',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙凤区',
            value: '230603',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '让胡路区',
            value: '230604',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '红岗区',
            value: '230605',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大同区',
            value: '230606',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '肇州县',
            value: '230621',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '肇源县',
            value: '230622',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '林甸县',
            value: '230623',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '杜尔伯特蒙古族自治县',
            value: '230624',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大庆高新技术产业开发区',
            value: '230671',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '伊春市',
        value: '230700',
        level: 2,
        firstChar: 'Y',
        children: [
          {
            label: '伊美区',
            value: '230717',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乌翠区',
            value: '230718',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '友好区',
            value: '230719',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '嘉荫县',
            value: '230722',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '汤旺县',
            value: '230723',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '丰林县',
            value: '230724',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大箐山县',
            value: '230725',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南岔县',
            value: '230726',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '金林区',
            value: '230751',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '铁力市',
            value: '230781',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '佳木斯市',
        value: '230800',
        level: 2,
        firstChar: 'J',
        children: [
          {
            label: '向阳区',
            value: '230803',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '前进区',
            value: '230804',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东风区',
            value: '230805',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '郊区',
            value: '230811',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '桦南县',
            value: '230822',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '桦川县',
            value: '230826',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '汤原县',
            value: '230828',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '同江市',
            value: '230881',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '富锦市',
            value: '230882',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '抚远市',
            value: '230883',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '七台河市',
        value: '230900',
        level: 2,
        firstChar: 'Q',
        children: [
          {
            label: '新兴区',
            value: '230902',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '桃山区',
            value: '230903',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '茄子河区',
            value: '230904',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '勃利县',
            value: '230921',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '牡丹江市',
        value: '231000',
        level: 2,
        firstChar: 'M',
        children: [
          {
            label: '东安区',
            value: '231002',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阳明区',
            value: '231003',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '爱民区',
            value: '231004',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西安区',
            value: '231005',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '林口县',
            value: '231025',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '牡丹江经济技术开发区',
            value: '231071',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '绥芬河市',
            value: '231081',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '海林市',
            value: '231083',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宁安市',
            value: '231084',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '穆棱市',
            value: '231085',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东宁市',
            value: '231086',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '黑河市',
        value: '231100',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '爱辉区',
            value: '231102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '逊克县',
            value: '231123',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '孙吴县',
            value: '231124',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '北安市',
            value: '231181',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '五大连池市',
            value: '231182',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '嫩江市',
            value: '231183',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '绥化市',
        value: '231200',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '北林区',
            value: '231202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '望奎县',
            value: '231221',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '兰西县',
            value: '231222',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '青冈县',
            value: '231223',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '庆安县',
            value: '231224',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '明水县',
            value: '231225',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '绥棱县',
            value: '231226',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安达市',
            value: '231281',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '肇东市',
            value: '231282',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '海伦市',
            value: '231283',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '大兴安岭地区',
        value: '232700',
        level: 2,
        firstChar: 'D',
        children: [
          {
            label: '漠河市',
            value: '232701',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '呼玛县',
            value: '232721',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '塔河县',
            value: '232722',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '加格达奇区',
            value: '232761',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '松岭区',
            value: '232762',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新林区',
            value: '232763',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '呼中区',
            value: '232764',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '上海',
    value: '310000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '上海市',
        value: '310100',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '黄浦区',
            value: '310101',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '徐汇区',
            value: '310104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '长宁区',
            value: '310105',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '静安区',
            value: '310106',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '普陀区',
            value: '310107',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '虹口区',
            value: '310109',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '杨浦区',
            value: '310110',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '闵行区',
            value: '310112',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宝山区',
            value: '310113',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '嘉定区',
            value: '310114',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '浦东新区',
            value: '310115',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '金山区',
            value: '310116',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '松江区',
            value: '310117',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '青浦区',
            value: '310118',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '奉贤区',
            value: '310120',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '崇明区',
            value: '310151',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '江苏',
    value: '320000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '南京市',
        value: '320100',
        level: 2,
        firstChar: 'N',
        children: [
          {
            label: '玄武区',
            value: '320102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '秦淮区',
            value: '320104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '建邺区',
            value: '320105',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鼓楼区',
            value: '320106',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '浦口区',
            value: '320111',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '栖霞区',
            value: '320113',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '雨花台区',
            value: '320114',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '江宁区',
            value: '320115',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '六合区',
            value: '320116',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '溧水区',
            value: '320117',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '高淳区',
            value: '320118',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '无锡市',
        value: '320200',
        level: 2,
        firstChar: 'W',
        children: [
          {
            label: '锡山区',
            value: '320205',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '惠山区',
            value: '320206',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '滨湖区',
            value: '320211',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '梁溪区',
            value: '320213',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新吴区',
            value: '320214',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '江阴市',
            value: '320281',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宜兴市',
            value: '320282',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '徐州市',
        value: '320300',
        level: 2,
        firstChar: 'X',
        children: [
          {
            label: '鼓楼区',
            value: '320302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '云龙区',
            value: '320303',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '贾汪区',
            value: '320305',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '泉山区',
            value: '320311',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '铜山区',
            value: '320312',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '丰县',
            value: '320321',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沛县',
            value: '320322',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '睢宁县',
            value: '320324',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '徐州经济技术开发区',
            value: '320371',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新沂市',
            value: '320381',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '邳州市',
            value: '320382',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '常州市',
        value: '320400',
        level: 2,
        firstChar: 'C',
        children: [
          {
            label: '天宁区',
            value: '320402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '钟楼区',
            value: '320404',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新北区',
            value: '320411',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '武进区',
            value: '320412',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '金坛区',
            value: '320413',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '溧阳市',
            value: '320481',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '苏州市',
        value: '320500',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '虎丘区',
            value: '320505',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '吴中区',
            value: '320506',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '相城区',
            value: '320507',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '姑苏区',
            value: '320508',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '吴江区',
            value: '320509',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '苏州工业园区',
            value: '320571',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '常熟市',
            value: '320581',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '张家港市',
            value: '320582',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '昆山市',
            value: '320583',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '太仓市',
            value: '320585',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '南通市',
        value: '320600',
        level: 2,
        firstChar: 'N',
        children: [
          {
            label: '崇川区',
            value: '320602',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '港闸区',
            value: '320611',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '通州区',
            value: '320612',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '如东县',
            value: '320623',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南通经济技术开发区',
            value: '320671',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '启东市',
            value: '320681',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '如皋市',
            value: '320682',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '海门市',
            value: '320684',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '海安市',
            value: '320685',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '连云港市',
        value: '320700',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '连云区',
            value: '320703',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '海州区',
            value: '320706',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '赣榆区',
            value: '320707',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东海县',
            value: '320722',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '灌云县',
            value: '320723',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '灌南县',
            value: '320724',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '连云港经济技术开发区',
            value: '320771',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '连云港高新技术产业开发区',
            value: '320772',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '淮安市',
        value: '320800',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '淮安区',
            value: '320803',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '淮阴区',
            value: '320804',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '清江浦区',
            value: '320812',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '洪泽区',
            value: '320813',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '涟水县',
            value: '320826',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '盱眙县',
            value: '320830',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '金湖县',
            value: '320831',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '淮安经济技术开发区',
            value: '320871',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '盐城市',
        value: '320900',
        level: 2,
        firstChar: 'Y',
        children: [
          {
            label: '亭湖区',
            value: '320902',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '盐都区',
            value: '320903',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大丰区',
            value: '320904',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '响水县',
            value: '320921',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '滨海县',
            value: '320922',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阜宁县',
            value: '320923',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '射阳县',
            value: '320924',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '建湖县',
            value: '320925',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '盐城经济技术开发区',
            value: '320971',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东台市',
            value: '320981',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '扬州市',
        value: '321000',
        level: 2,
        firstChar: 'Y',
        children: [
          {
            label: '广陵区',
            value: '321002',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '邗江区',
            value: '321003',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '江都区',
            value: '321012',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宝应县',
            value: '321023',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '扬州经济技术开发区',
            value: '321071',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '仪征市',
            value: '321081',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '高邮市',
            value: '321084',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '镇江市',
        value: '321100',
        level: 2,
        firstChar: 'Z',
        children: [
          {
            label: '京口区',
            value: '321102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '润州区',
            value: '321111',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '丹徒区',
            value: '321112',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '镇江新区',
            value: '321171',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '丹阳市',
            value: '321181',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '扬中市',
            value: '321182',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '句容市',
            value: '321183',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '泰州市',
        value: '321200',
        level: 2,
        firstChar: 'T',
        children: [
          {
            label: '海陵区',
            value: '321202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '高港区',
            value: '321203',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '姜堰区',
            value: '321204',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '泰州医药高新技术产业开发区',
            value: '321271',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '兴化市',
            value: '321281',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '靖江市',
            value: '321282',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '泰兴市',
            value: '321283',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '宿迁市',
        value: '321300',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '宿城区',
            value: '321302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宿豫区',
            value: '321311',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沭阳县',
            value: '321322',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '泗阳县',
            value: '321323',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '泗洪县',
            value: '321324',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宿迁经济技术开发区',
            value: '321371',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '浙江',
    value: '330000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '杭州市',
        value: '330100',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '上城区',
            value: '330102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '下城区',
            value: '330103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '江干区',
            value: '330104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '拱墅区',
            value: '330105',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西湖区',
            value: '330106',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '滨江区',
            value: '330108',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '萧山区',
            value: '330109',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '余杭区',
            value: '330110',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '富阳区',
            value: '330111',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '临安区',
            value: '330112',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '桐庐县',
            value: '330122',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '淳安县',
            value: '330127',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '建德市',
            value: '330182',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '宁波市',
        value: '330200',
        level: 2,
        firstChar: 'N',
        children: [
          {
            label: '海曙区',
            value: '330203',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '江北区',
            value: '330205',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '北仑区',
            value: '330206',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '镇海区',
            value: '330211',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鄞州区',
            value: '330212',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '奉化区',
            value: '330213',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '象山县',
            value: '330225',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宁海县',
            value: '330226',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '余姚市',
            value: '330281',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '慈溪市',
            value: '330282',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '温州市',
        value: '330300',
        level: 2,
        firstChar: 'W',
        children: [
          {
            label: '鹿城区',
            value: '330302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙湾区',
            value: '330303',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '瓯海区',
            value: '330304',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '洞头区',
            value: '330305',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永嘉县',
            value: '330324',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平阳县',
            value: '330326',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '苍南县',
            value: '330327',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '文成县',
            value: '330328',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '泰顺县',
            value: '330329',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '温州经济技术开发区',
            value: '330371',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '瑞安市',
            value: '330381',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乐清市',
            value: '330382',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙港市',
            value: '330383',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '嘉兴市',
        value: '330400',
        level: 2,
        firstChar: 'J',
        children: [
          {
            label: '南湖区',
            value: '330402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '秀洲区',
            value: '330411',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '嘉善县',
            value: '330421',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '海盐县',
            value: '330424',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '海宁市',
            value: '330481',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平湖市',
            value: '330482',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '桐乡市',
            value: '330483',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '湖州市',
        value: '330500',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '吴兴区',
            value: '330502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南浔区',
            value: '330503',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '德清县',
            value: '330521',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '长兴县',
            value: '330522',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安吉县',
            value: '330523',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '绍兴市',
        value: '330600',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '越城区',
            value: '330602',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '柯桥区',
            value: '330603',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '上虞区',
            value: '330604',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新昌县',
            value: '330624',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '诸暨市',
            value: '330681',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '嵊州市',
            value: '330683',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '金华市',
        value: '330700',
        level: 2,
        firstChar: 'J',
        children: [
          {
            label: '婺城区',
            value: '330702',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '金东区',
            value: '330703',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '武义县',
            value: '330723',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '浦江县',
            value: '330726',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '磐安县',
            value: '330727',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '兰溪市',
            value: '330781',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '义乌市',
            value: '330782',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东阳市',
            value: '330783',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永康市',
            value: '330784',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '衢州市',
        value: '330800',
        level: 2,
        firstChar: 'Q',
        children: [
          {
            label: '柯城区',
            value: '330802',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '衢江区',
            value: '330803',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '常山县',
            value: '330822',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '开化县',
            value: '330824',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙游县',
            value: '330825',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '江山市',
            value: '330881',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '舟山市',
        value: '330900',
        level: 2,
        firstChar: 'Z',
        children: [
          {
            label: '定海区',
            value: '330902',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '普陀区',
            value: '330903',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '岱山县',
            value: '330921',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '嵊泗县',
            value: '330922',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '台州市',
        value: '331000',
        level: 2,
        firstChar: 'T',
        children: [
          {
            label: '椒江区',
            value: '331002',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '黄岩区',
            value: '331003',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '路桥区',
            value: '331004',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '三门县',
            value: '331022',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '天台县',
            value: '331023',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '仙居县',
            value: '331024',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '温岭市',
            value: '331081',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '临海市',
            value: '331082',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '玉环市',
            value: '331083',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '丽水市',
        value: '331100',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '莲都区',
            value: '331102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '青田县',
            value: '331121',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '缙云县',
            value: '331122',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '遂昌县',
            value: '331123',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '松阳县',
            value: '331124',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '云和县',
            value: '331125',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '庆元县',
            value: '331126',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '景宁畲族自治县',
            value: '331127',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙泉市',
            value: '331181',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '安徽',
    value: '340000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '合肥市',
        value: '340100',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '瑶海区',
            value: '340102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '庐阳区',
            value: '340103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '蜀山区',
            value: '340104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '包河区',
            value: '340111',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '长丰县',
            value: '340121',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '肥东县',
            value: '340122',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '肥西县',
            value: '340123',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '庐江县',
            value: '340124',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '合肥高新技术产业开发区',
            value: '340171',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '合肥经济技术开发区',
            value: '340172',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '合肥新站高新技术产业开发区',
            value: '340173',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '巢湖市',
            value: '340181',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '芜湖市',
        value: '340200',
        level: 2,
        firstChar: 'W',
        children: [
          {
            label: '镜湖区',
            value: '340202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '弋江区',
            value: '340203',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鸠江区',
            value: '340207',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '三山区',
            value: '340208',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '芜湖县',
            value: '340221',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '繁昌县',
            value: '340222',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南陵县',
            value: '340223',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '芜湖经济技术开发区',
            value: '340271',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安徽芜湖长江大桥经济开发区',
            value: '340272',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '无为市',
            value: '340281',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '蚌埠市',
        value: '340300',
        level: 2,
        firstChar: 'B',
        children: [
          {
            label: '龙子湖区',
            value: '340302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '蚌山区',
            value: '340303',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '禹会区',
            value: '340304',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '淮上区',
            value: '340311',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '怀远县',
            value: '340321',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '五河县',
            value: '340322',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '固镇县',
            value: '340323',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '蚌埠市高新技术开发区',
            value: '340371',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '蚌埠市经济开发区',
            value: '340372',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '淮南市',
        value: '340400',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '大通区',
            value: '340402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '田家庵区',
            value: '340403',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '谢家集区',
            value: '340404',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '八公山区',
            value: '340405',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '潘集区',
            value: '340406',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '凤台县',
            value: '340421',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '寿县',
            value: '340422',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '马鞍山市',
        value: '340500',
        level: 2,
        firstChar: 'M',
        children: [
          {
            label: '花山区',
            value: '340503',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '雨山区',
            value: '340504',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '博望区',
            value: '340506',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '当涂县',
            value: '340521',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '含山县',
            value: '340522',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '和县',
            value: '340523',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '淮北市',
        value: '340600',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '杜集区',
            value: '340602',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '相山区',
            value: '340603',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '烈山区',
            value: '340604',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '濉溪县',
            value: '340621',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '铜陵市',
        value: '340700',
        level: 2,
        firstChar: 'T',
        children: [
          {
            label: '铜官区',
            value: '340705',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '义安区',
            value: '340706',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '郊区',
            value: '340711',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '枞阳县',
            value: '340722',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '安庆市',
        value: '340800',
        level: 2,
        firstChar: 'A',
        children: [
          {
            label: '迎江区',
            value: '340802',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大观区',
            value: '340803',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宜秀区',
            value: '340811',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '怀宁县',
            value: '340822',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '太湖县',
            value: '340825',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宿松县',
            value: '340826',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '望江县',
            value: '340827',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '岳西县',
            value: '340828',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安徽安庆经济开发区',
            value: '340871',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '桐城市',
            value: '340881',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '潜山市',
            value: '340882',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '黄山市',
        value: '341000',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '屯溪区',
            value: '341002',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '黄山区',
            value: '341003',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '徽州区',
            value: '341004',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '歙县',
            value: '341021',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '休宁县',
            value: '341022',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '黟县',
            value: '341023',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '祁门县',
            value: '341024',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '滁州市',
        value: '341100',
        level: 2,
        firstChar: 'C',
        children: [
          {
            label: '琅琊区',
            value: '341102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南谯区',
            value: '341103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '来安县',
            value: '341122',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '全椒县',
            value: '341124',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '定远县',
            value: '341125',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '凤阳县',
            value: '341126',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '苏滁现代产业园',
            value: '341171',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '滁州经济技术开发区',
            value: '341172',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '天长市',
            value: '341181',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '明光市',
            value: '341182',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '阜阳市',
        value: '341200',
        level: 2,
        firstChar: 'F',
        children: [
          {
            label: '颍州区',
            value: '341202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '颍东区',
            value: '341203',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '颍泉区',
            value: '341204',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '临泉县',
            value: '341221',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '太和县',
            value: '341222',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阜南县',
            value: '341225',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '颍上县',
            value: '341226',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阜阳合肥现代产业园区',
            value: '341271',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阜阳经济技术开发区',
            value: '341272',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '界首市',
            value: '341282',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '宿州市',
        value: '341300',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '埇桥区',
            value: '341302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '砀山县',
            value: '341321',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '萧县',
            value: '341322',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '灵璧县',
            value: '341323',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '泗县',
            value: '341324',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宿州马鞍山现代产业园区',
            value: '341371',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宿州经济技术开发区',
            value: '341372',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '六安市',
        value: '341500',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '金安区',
            value: '341502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '裕安区',
            value: '341503',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '叶集区',
            value: '341504',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '霍邱县',
            value: '341522',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '舒城县',
            value: '341523',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '金寨县',
            value: '341524',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '霍山县',
            value: '341525',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '亳州市',
        value: '341600',
        level: 2,
        firstChar: 'B',
        children: [
          {
            label: '谯城区',
            value: '341602',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '涡阳县',
            value: '341621',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '蒙城县',
            value: '341622',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '利辛县',
            value: '341623',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '池州市',
        value: '341700',
        level: 2,
        firstChar: 'C',
        children: [
          {
            label: '贵池区',
            value: '341702',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东至县',
            value: '341721',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '石台县',
            value: '341722',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '青阳县',
            value: '341723',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '宣城市',
        value: '341800',
        level: 2,
        firstChar: 'X',
        children: [
          {
            label: '宣州区',
            value: '341802',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '郎溪县',
            value: '341821',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '泾县',
            value: '341823',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '绩溪县',
            value: '341824',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '旌德县',
            value: '341825',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宣城市经济开发区',
            value: '341871',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宁国市',
            value: '341881',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '广德市',
            value: '341882',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '福建',
    value: '350000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '福州市',
        value: '350100',
        level: 2,
        firstChar: 'F',
        children: [
          {
            label: '鼓楼区',
            value: '350102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '台江区',
            value: '350103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '仓山区',
            value: '350104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '马尾区',
            value: '350105',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '晋安区',
            value: '350111',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '长乐区',
            value: '350112',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '闽侯县',
            value: '350121',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '连江县',
            value: '350122',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '罗源县',
            value: '350123',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '闽清县',
            value: '350124',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永泰县',
            value: '350125',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平潭县',
            value: '350128',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '福清市',
            value: '350181',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '厦门市',
        value: '350200',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '思明区',
            value: '350203',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '海沧区',
            value: '350205',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '湖里区',
            value: '350206',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '集美区',
            value: '350211',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '同安区',
            value: '350212',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '翔安区',
            value: '350213',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '莆田市',
        value: '350300',
        level: 2,
        firstChar: 'P',
        children: [
          {
            label: '城厢区',
            value: '350302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '涵江区',
            value: '350303',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '荔城区',
            value: '350304',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '秀屿区',
            value: '350305',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '仙游县',
            value: '350322',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '三明市',
        value: '350400',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '梅列区',
            value: '350402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '三元区',
            value: '350403',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '明溪县',
            value: '350421',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '清流县',
            value: '350423',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宁化县',
            value: '350424',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大田县',
            value: '350425',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '尤溪县',
            value: '350426',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沙县',
            value: '350427',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '将乐县',
            value: '350428',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '泰宁县',
            value: '350429',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '建宁县',
            value: '350430',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永安市',
            value: '350481',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '泉州市',
        value: '350500',
        level: 2,
        firstChar: 'Q',
        children: [
          {
            label: '鲤城区',
            value: '350502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '丰泽区',
            value: '350503',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '洛江区',
            value: '350504',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '泉港区',
            value: '350505',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '惠安县',
            value: '350521',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安溪县',
            value: '350524',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永春县',
            value: '350525',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '德化县',
            value: '350526',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '金门县',
            value: '350527',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '石狮市',
            value: '350581',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '晋江市',
            value: '350582',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南安市',
            value: '350583',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '漳州市',
        value: '350600',
        level: 2,
        firstChar: 'Z',
        children: [
          {
            label: '芗城区',
            value: '350602',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙文区',
            value: '350603',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '云霄县',
            value: '350622',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '漳浦县',
            value: '350623',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '诏安县',
            value: '350624',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '长泰县',
            value: '350625',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东山县',
            value: '350626',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南靖县',
            value: '350627',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平和县',
            value: '350628',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '华安县',
            value: '350629',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙海市',
            value: '350681',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '南平市',
        value: '350700',
        level: 2,
        firstChar: 'N',
        children: [
          {
            label: '延平区',
            value: '350702',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '建阳区',
            value: '350703',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '顺昌县',
            value: '350721',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '浦城县',
            value: '350722',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '光泽县',
            value: '350723',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '松溪县',
            value: '350724',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '政和县',
            value: '350725',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '邵武市',
            value: '350781',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '武夷山市',
            value: '350782',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '建瓯市',
            value: '350783',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '龙岩市',
        value: '350800',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '新罗区',
            value: '350802',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永定区',
            value: '350803',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '长汀县',
            value: '350821',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '上杭县',
            value: '350823',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '武平县',
            value: '350824',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '连城县',
            value: '350825',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '漳平市',
            value: '350881',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '宁德市',
        value: '350900',
        level: 2,
        firstChar: 'N',
        children: [
          {
            label: '蕉城区',
            value: '350902',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '霞浦县',
            value: '350921',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '古田县',
            value: '350922',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '屏南县',
            value: '350923',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '寿宁县',
            value: '350924',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '周宁县',
            value: '350925',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '柘荣县',
            value: '350926',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '福安市',
            value: '350981',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '福鼎市',
            value: '350982',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '江西',
    value: '360000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '南昌市',
        value: '360100',
        level: 2,
        firstChar: 'N',
        children: [
          {
            label: '东湖区',
            value: '360102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西湖区',
            value: '360103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '青云谱区',
            value: '360104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '青山湖区',
            value: '360111',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新建区',
            value: '360112',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '红谷滩区',
            value: '360113',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南昌县',
            value: '360121',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安义县',
            value: '360123',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '进贤县',
            value: '360124',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '景德镇市',
        value: '360200',
        level: 2,
        firstChar: 'J',
        children: [
          {
            label: '昌江区',
            value: '360202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '珠山区',
            value: '360203',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '浮梁县',
            value: '360222',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乐平市',
            value: '360281',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '萍乡市',
        value: '360300',
        level: 2,
        firstChar: 'P',
        children: [
          {
            label: '安源区',
            value: '360302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '湘东区',
            value: '360313',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '莲花县',
            value: '360321',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '上栗县',
            value: '360322',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '芦溪县',
            value: '360323',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '九江市',
        value: '360400',
        level: 2,
        firstChar: 'J',
        children: [
          {
            label: '濂溪区',
            value: '360402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '浔阳区',
            value: '360403',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '柴桑区',
            value: '360404',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '武宁县',
            value: '360423',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '修水县',
            value: '360424',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永修县',
            value: '360425',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '德安县',
            value: '360426',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '都昌县',
            value: '360428',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '湖口县',
            value: '360429',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '彭泽县',
            value: '360430',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '瑞昌市',
            value: '360481',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '共青城市',
            value: '360482',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '庐山市',
            value: '360483',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '新余市',
        value: '360500',
        level: 2,
        firstChar: 'X',
        children: [
          {
            label: '渝水区',
            value: '360502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '分宜县',
            value: '360521',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '鹰潭市',
        value: '360600',
        level: 2,
        firstChar: 'Y',
        children: [
          {
            label: '月湖区',
            value: '360602',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '余江区',
            value: '360603',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '贵溪市',
            value: '360681',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '赣州市',
        value: '360700',
        level: 2,
        firstChar: 'G',
        children: [
          {
            label: '章贡区',
            value: '360702',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南康区',
            value: '360703',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '赣县区',
            value: '360704',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '信丰县',
            value: '360722',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大余县',
            value: '360723',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '上犹县',
            value: '360724',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '崇义县',
            value: '360725',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安远县',
            value: '360726',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '定南县',
            value: '360728',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '全南县',
            value: '360729',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宁都县',
            value: '360730',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '于都县',
            value: '360731',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '兴国县',
            value: '360732',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '会昌县',
            value: '360733',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '寻乌县',
            value: '360734',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '石城县',
            value: '360735',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '瑞金市',
            value: '360781',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙南市',
            value: '360783',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '吉安市',
        value: '360800',
        level: 2,
        firstChar: 'J',
        children: [
          {
            label: '吉州区',
            value: '360802',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '青原区',
            value: '360803',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '吉安县',
            value: '360821',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '吉水县',
            value: '360822',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '峡江县',
            value: '360823',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新干县',
            value: '360824',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永丰县',
            value: '360825',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '泰和县',
            value: '360826',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '遂川县',
            value: '360827',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '万安县',
            value: '360828',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安福县',
            value: '360829',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永新县',
            value: '360830',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '井冈山市',
            value: '360881',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '宜春市',
        value: '360900',
        level: 2,
        firstChar: 'Y',
        children: [
          {
            label: '袁州区',
            value: '360902',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '奉新县',
            value: '360921',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '万载县',
            value: '360922',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '上高县',
            value: '360923',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宜丰县',
            value: '360924',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '靖安县',
            value: '360925',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '铜鼓县',
            value: '360926',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '丰城市',
            value: '360981',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '樟树市',
            value: '360982',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '高安市',
            value: '360983',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '抚州市',
        value: '361000',
        level: 2,
        firstChar: 'F',
        children: [
          {
            label: '临川区',
            value: '361002',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东乡区',
            value: '361003',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南城县',
            value: '361021',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '黎川县',
            value: '361022',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南丰县',
            value: '361023',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '崇仁县',
            value: '361024',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乐安县',
            value: '361025',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宜黄县',
            value: '361026',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '金溪县',
            value: '361027',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '资溪县',
            value: '361028',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '广昌县',
            value: '361030',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '上饶市',
        value: '361100',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '信州区',
            value: '361102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '广丰区',
            value: '361103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '广信区',
            value: '361104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '玉山县',
            value: '361123',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '铅山县',
            value: '361124',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '横峰县',
            value: '361125',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '弋阳县',
            value: '361126',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '余干县',
            value: '361127',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鄱阳县',
            value: '361128',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '万年县',
            value: '361129',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '婺源县',
            value: '361130',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '德兴市',
            value: '361181',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '山东',
    value: '370000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '济南市',
        value: '370100',
        level: 2,
        firstChar: 'J',
        children: [
          {
            label: '历下区',
            value: '370102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '市中区',
            value: '370103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '槐荫区',
            value: '370104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '天桥区',
            value: '370105',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '历城区',
            value: '370112',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '长清区',
            value: '370113',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '章丘区',
            value: '370114',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '济阳区',
            value: '370115',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '莱芜区',
            value: '370116',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '钢城区',
            value: '370117',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平阴县',
            value: '370124',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '商河县',
            value: '370126',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '济南高新技术产业开发区',
            value: '370171',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '青岛市',
        value: '370200',
        level: 2,
        firstChar: 'Q',
        children: [
          {
            label: '市南区',
            value: '370202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '市北区',
            value: '370203',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '黄岛区',
            value: '370211',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '崂山区',
            value: '370212',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '李沧区',
            value: '370213',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '城阳区',
            value: '370214',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '即墨区',
            value: '370215',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '青岛高新技术产业开发区',
            value: '370271',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '胶州市',
            value: '370281',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平度市',
            value: '370283',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '莱西市',
            value: '370285',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '淄博市',
        value: '370300',
        level: 2,
        firstChar: 'Z',
        children: [
          {
            label: '淄川区',
            value: '370302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '张店区',
            value: '370303',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '博山区',
            value: '370304',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '临淄区',
            value: '370305',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '周村区',
            value: '370306',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '桓台县',
            value: '370321',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '高青县',
            value: '370322',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沂源县',
            value: '370323',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '枣庄市',
        value: '370400',
        level: 2,
        firstChar: 'Z',
        children: [
          {
            label: '市中区',
            value: '370402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '薛城区',
            value: '370403',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '峄城区',
            value: '370404',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '台儿庄区',
            value: '370405',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '山亭区',
            value: '370406',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '滕州市',
            value: '370481',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '东营市',
        value: '370500',
        level: 2,
        firstChar: 'D',
        children: [
          {
            label: '东营区',
            value: '370502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '河口区',
            value: '370503',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '垦利区',
            value: '370505',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '利津县',
            value: '370522',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '广饶县',
            value: '370523',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东营经济技术开发区',
            value: '370571',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东营港经济开发区',
            value: '370572',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '烟台市',
        value: '370600',
        level: 2,
        firstChar: 'Y',
        children: [
          {
            label: '芝罘区',
            value: '370602',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '福山区',
            value: '370611',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '牟平区',
            value: '370612',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '莱山区',
            value: '370613',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '蓬莱区',
            value: '370614',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '烟台高新技术产业开发区',
            value: '370671',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '烟台经济技术开发区',
            value: '370672',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙口市',
            value: '370681',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '莱阳市',
            value: '370682',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '莱州市',
            value: '370683',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '招远市',
            value: '370685',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '栖霞市',
            value: '370686',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '海阳市',
            value: '370687',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '潍坊市',
        value: '370700',
        level: 2,
        firstChar: 'W',
        children: [
          {
            label: '潍城区',
            value: '370702',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '寒亭区',
            value: '370703',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '坊子区',
            value: '370704',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '奎文区',
            value: '370705',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '临朐县',
            value: '370724',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '昌乐县',
            value: '370725',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '潍坊滨海经济技术开发区',
            value: '370772',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '青州市',
            value: '370781',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '诸城市',
            value: '370782',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '寿光市',
            value: '370783',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安丘市',
            value: '370784',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '高密市',
            value: '370785',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '昌邑市',
            value: '370786',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '济宁市',
        value: '370800',
        level: 2,
        firstChar: 'J',
        children: [
          {
            label: '任城区',
            value: '370811',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '兖州区',
            value: '370812',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '微山县',
            value: '370826',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鱼台县',
            value: '370827',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '金乡县',
            value: '370828',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '嘉祥县',
            value: '370829',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '汶上县',
            value: '370830',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '泗水县',
            value: '370831',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '梁山县',
            value: '370832',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '济宁高新技术产业开发区',
            value: '370871',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '曲阜市',
            value: '370881',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '邹城市',
            value: '370883',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '泰安市',
        value: '370900',
        level: 2,
        firstChar: 'T',
        children: [
          {
            label: '泰山区',
            value: '370902',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '岱岳区',
            value: '370911',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宁阳县',
            value: '370921',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东平县',
            value: '370923',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新泰市',
            value: '370982',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '肥城市',
            value: '370983',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '威海市',
        value: '371000',
        level: 2,
        firstChar: 'W',
        children: [
          {
            label: '环翠区',
            value: '371002',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '文登区',
            value: '371003',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '威海火炬高技术产业开发区',
            value: '371071',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '威海经济技术开发区',
            value: '371072',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '威海临港经济技术开发区',
            value: '371073',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '荣成市',
            value: '371082',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乳山市',
            value: '371083',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '日照市',
        value: '371100',
        level: 2,
        firstChar: 'R',
        children: [
          {
            label: '东港区',
            value: '371102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '岚山区',
            value: '371103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '五莲县',
            value: '371121',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '莒县',
            value: '371122',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '日照经济技术开发区',
            value: '371171',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '临沂市',
        value: '371300',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '兰山区',
            value: '371302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '罗庄区',
            value: '371311',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '河东区',
            value: '371312',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沂南县',
            value: '371321',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '郯城县',
            value: '371322',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沂水县',
            value: '371323',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '兰陵县',
            value: '371324',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '费县',
            value: '371325',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平邑县',
            value: '371326',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '莒南县',
            value: '371327',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '蒙阴县',
            value: '371328',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '临沭县',
            value: '371329',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '临沂高新技术产业开发区',
            value: '371371',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '德州市',
        value: '371400',
        level: 2,
        firstChar: 'D',
        children: [
          {
            label: '德城区',
            value: '371402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '陵城区',
            value: '371403',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宁津县',
            value: '371422',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '庆云县',
            value: '371423',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '临邑县',
            value: '371424',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '齐河县',
            value: '371425',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平原县',
            value: '371426',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '夏津县',
            value: '371427',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '武城县',
            value: '371428',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '德州经济技术开发区',
            value: '371471',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '德州运河经济开发区',
            value: '371472',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乐陵市',
            value: '371481',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '禹城市',
            value: '371482',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '聊城市',
        value: '371500',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '东昌府区',
            value: '371502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '茌平区',
            value: '371503',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阳谷县',
            value: '371521',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '莘县',
            value: '371522',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东阿县',
            value: '371524',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '冠县',
            value: '371525',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '高唐县',
            value: '371526',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '临清市',
            value: '371581',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '滨州市',
        value: '371600',
        level: 2,
        firstChar: 'B',
        children: [
          {
            label: '滨城区',
            value: '371602',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沾化区',
            value: '371603',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '惠民县',
            value: '371621',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阳信县',
            value: '371622',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '无棣县',
            value: '371623',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '博兴县',
            value: '371625',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '邹平市',
            value: '371681',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '菏泽市',
        value: '371700',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '牡丹区',
            value: '371702',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '定陶区',
            value: '371703',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '曹县',
            value: '371721',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '单县',
            value: '371722',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '成武县',
            value: '371723',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '巨野县',
            value: '371724',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '郓城县',
            value: '371725',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鄄城县',
            value: '371726',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东明县',
            value: '371728',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '菏泽经济技术开发区',
            value: '371771',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '菏泽高新技术开发区',
            value: '371772',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '河南',
    value: '410000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '郑州市',
        value: '410100',
        level: 2,
        firstChar: 'Z',
        children: [
          {
            label: '中原区',
            value: '410102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '二七区',
            value: '410103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '管城回族区',
            value: '410104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '金水区',
            value: '410105',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '上街区',
            value: '410106',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '惠济区',
            value: '410108',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '中牟县',
            value: '410122',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '郑州经济技术开发区',
            value: '410171',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '郑州高新技术产业开发区',
            value: '410172',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '郑州航空港经济综合实验区',
            value: '410173',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '巩义市',
            value: '410181',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '荥阳市',
            value: '410182',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新密市',
            value: '410183',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新郑市',
            value: '410184',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '登封市',
            value: '410185',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '开封市',
        value: '410200',
        level: 2,
        firstChar: 'K',
        children: [
          {
            label: '龙亭区',
            value: '410202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '顺河回族区',
            value: '410203',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鼓楼区',
            value: '410204',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '禹王台区',
            value: '410205',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '祥符区',
            value: '410212',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '杞县',
            value: '410221',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '通许县',
            value: '410222',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '尉氏县',
            value: '410223',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '兰考县',
            value: '410225',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '洛阳市',
        value: '410300',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '老城区',
            value: '410302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西工区',
            value: '410303',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '瀍河回族区',
            value: '410304',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '涧西区',
            value: '410305',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '吉利区',
            value: '410306',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '洛龙区',
            value: '410311',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '孟津县',
            value: '410322',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新安县',
            value: '410323',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '栾川县',
            value: '410324',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '嵩县',
            value: '410325',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '汝阳县',
            value: '410326',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宜阳县',
            value: '410327',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '洛宁县',
            value: '410328',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '伊川县',
            value: '410329',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '洛阳高新技术产业开发区',
            value: '410371',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '偃师市',
            value: '410381',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '平顶山市',
        value: '410400',
        level: 2,
        firstChar: 'P',
        children: [
          {
            label: '新华区',
            value: '410402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '卫东区',
            value: '410403',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '石龙区',
            value: '410404',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '湛河区',
            value: '410411',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宝丰县',
            value: '410421',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '叶县',
            value: '410422',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鲁山县',
            value: '410423',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '郏县',
            value: '410425',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平顶山高新技术产业开发区',
            value: '410471',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平顶山市城乡一体化示范区',
            value: '410472',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '舞钢市',
            value: '410481',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '汝州市',
            value: '410482',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '安阳市',
        value: '410500',
        level: 2,
        firstChar: 'A',
        children: [
          {
            label: '文峰区',
            value: '410502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '北关区',
            value: '410503',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '殷都区',
            value: '410505',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙安区',
            value: '410506',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安阳县',
            value: '410522',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '汤阴县',
            value: '410523',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '滑县',
            value: '410526',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '内黄县',
            value: '410527',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安阳高新技术产业开发区',
            value: '410571',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '林州市',
            value: '410581',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '鹤壁市',
        value: '410600',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '鹤山区',
            value: '410602',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '山城区',
            value: '410603',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '淇滨区',
            value: '410611',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '浚县',
            value: '410621',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '淇县',
            value: '410622',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鹤壁经济技术开发区',
            value: '410671',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '新乡市',
        value: '410700',
        level: 2,
        firstChar: 'X',
        children: [
          {
            label: '红旗区',
            value: '410702',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '卫滨区',
            value: '410703',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '凤泉区',
            value: '410704',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '牧野区',
            value: '410711',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新乡县',
            value: '410721',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '获嘉县',
            value: '410724',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '原阳县',
            value: '410725',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '延津县',
            value: '410726',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '封丘县',
            value: '410727',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新乡高新技术产业开发区',
            value: '410771',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新乡经济技术开发区',
            value: '410772',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新乡市平原城乡一体化示范区',
            value: '410773',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '卫辉市',
            value: '410781',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '辉县市',
            value: '410782',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '长垣市',
            value: '410783',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '焦作市',
        value: '410800',
        level: 2,
        firstChar: 'J',
        children: [
          {
            label: '解放区',
            value: '410802',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '中站区',
            value: '410803',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '马村区',
            value: '410804',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '山阳区',
            value: '410811',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '修武县',
            value: '410821',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '博爱县',
            value: '410822',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '武陟县',
            value: '410823',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '温县',
            value: '410825',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '焦作城乡一体化示范区',
            value: '410871',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沁阳市',
            value: '410882',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '孟州市',
            value: '410883',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '濮阳市',
        value: '410900',
        level: 2,
        firstChar: 'P',
        children: [
          {
            label: '华龙区',
            value: '410902',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '清丰县',
            value: '410922',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南乐县',
            value: '410923',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '范县',
            value: '410926',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '台前县',
            value: '410927',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '濮阳县',
            value: '410928',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '河南濮阳工业园区',
            value: '410971',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '濮阳经济技术开发区',
            value: '410972',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '许昌市',
        value: '411000',
        level: 2,
        firstChar: 'X',
        children: [
          {
            label: '魏都区',
            value: '411002',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '建安区',
            value: '411003',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鄢陵县',
            value: '411024',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '襄城县',
            value: '411025',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '许昌经济技术开发区',
            value: '411071',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '禹州市',
            value: '411081',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '长葛市',
            value: '411082',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '漯河市',
        value: '411100',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '源汇区',
            value: '411102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '郾城区',
            value: '411103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '召陵区',
            value: '411104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '舞阳县',
            value: '411121',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '临颍县',
            value: '411122',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '漯河经济技术开发区',
            value: '411171',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '三门峡市',
        value: '411200',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '湖滨区',
            value: '411202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '陕州区',
            value: '411203',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '渑池县',
            value: '411221',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '卢氏县',
            value: '411224',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '河南三门峡经济开发区',
            value: '411271',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '义马市',
            value: '411281',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '灵宝市',
            value: '411282',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '南阳市',
        value: '411300',
        level: 2,
        firstChar: 'N',
        children: [
          {
            label: '宛城区',
            value: '411302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '卧龙区',
            value: '411303',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南召县',
            value: '411321',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '方城县',
            value: '411322',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西峡县',
            value: '411323',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '镇平县',
            value: '411324',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '内乡县',
            value: '411325',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '淅川县',
            value: '411326',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '社旗县',
            value: '411327',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '唐河县',
            value: '411328',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新野县',
            value: '411329',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '桐柏县',
            value: '411330',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南阳高新技术产业开发区',
            value: '411371',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南阳市城乡一体化示范区',
            value: '411372',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '邓州市',
            value: '411381',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '商丘市',
        value: '411400',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '梁园区',
            value: '411402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '睢阳区',
            value: '411403',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '民权县',
            value: '411421',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '睢县',
            value: '411422',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宁陵县',
            value: '411423',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '柘城县',
            value: '411424',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '虞城县',
            value: '411425',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '夏邑县',
            value: '411426',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '豫东综合物流产业聚集区',
            value: '411471',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '河南商丘经济开发区',
            value: '411472',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永城市',
            value: '411481',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '信阳市',
        value: '411500',
        level: 2,
        firstChar: 'X',
        children: [
          {
            label: '浉河区',
            value: '411502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平桥区',
            value: '411503',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '罗山县',
            value: '411521',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '光山县',
            value: '411522',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新县',
            value: '411523',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '商城县',
            value: '411524',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '固始县',
            value: '411525',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '潢川县',
            value: '411526',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '淮滨县',
            value: '411527',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '息县',
            value: '411528',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '信阳高新技术产业开发区',
            value: '411571',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '周口市',
        value: '411600',
        level: 2,
        firstChar: 'Z',
        children: [
          {
            label: '川汇区',
            value: '411602',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '淮阳区',
            value: '411603',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '扶沟县',
            value: '411621',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西华县',
            value: '411622',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '商水县',
            value: '411623',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沈丘县',
            value: '411624',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '郸城县',
            value: '411625',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '太康县',
            value: '411627',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鹿邑县',
            value: '411628',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '河南周口经济开发区',
            value: '411671',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '项城市',
            value: '411681',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '驻马店市',
        value: '411700',
        level: 2,
        firstChar: 'Z',
        children: [
          {
            label: '驿城区',
            value: '411702',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西平县',
            value: '411721',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '上蔡县',
            value: '411722',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平舆县',
            value: '411723',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '正阳县',
            value: '411724',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '确山县',
            value: '411725',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '泌阳县',
            value: '411726',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '汝南县',
            value: '411727',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '遂平县',
            value: '411728',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新蔡县',
            value: '411729',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '河南驻马店经济开发区',
            value: '411771',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '济源市',
        value: '419001',
        level: 2,
        firstChar: 'J',
        children: [],
      },
    ],
  },
  {
    label: '湖北',
    value: '420000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '武汉市',
        value: '420100',
        level: 2,
        firstChar: 'W',
        children: [
          {
            label: '江岸区',
            value: '420102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '江汉区',
            value: '420103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '硚口区',
            value: '420104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '汉阳区',
            value: '420105',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '武昌区',
            value: '420106',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '青山区',
            value: '420107',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '洪山区',
            value: '420111',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东西湖区',
            value: '420112',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '汉南区',
            value: '420113',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '蔡甸区',
            value: '420114',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '江夏区',
            value: '420115',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '黄陂区',
            value: '420116',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新洲区',
            value: '420117',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '黄石市',
        value: '420200',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '黄石港区',
            value: '420202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西塞山区',
            value: '420203',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '下陆区',
            value: '420204',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '铁山区',
            value: '420205',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阳新县',
            value: '420222',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大冶市',
            value: '420281',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '十堰市',
        value: '420300',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '茅箭区',
            value: '420302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '张湾区',
            value: '420303',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '郧阳区',
            value: '420304',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '郧西县',
            value: '420322',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '竹山县',
            value: '420323',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '竹溪县',
            value: '420324',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '房县',
            value: '420325',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '丹江口市',
            value: '420381',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '宜昌市',
        value: '420500',
        level: 2,
        firstChar: 'Y',
        children: [
          {
            label: '西陵区',
            value: '420502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '伍家岗区',
            value: '420503',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '点军区',
            value: '420504',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '猇亭区',
            value: '420505',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '夷陵区',
            value: '420506',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '远安县',
            value: '420525',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '兴山县',
            value: '420526',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '秭归县',
            value: '420527',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '长阳土家族自治县',
            value: '420528',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '五峰土家族自治县',
            value: '420529',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宜都市',
            value: '420581',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '当阳市',
            value: '420582',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '枝江市',
            value: '420583',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '襄阳市',
        value: '420600',
        level: 2,
        firstChar: 'X',
        children: [
          {
            label: '襄城区',
            value: '420602',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '樊城区',
            value: '420606',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '襄州区',
            value: '420607',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南漳县',
            value: '420624',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '谷城县',
            value: '420625',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '保康县',
            value: '420626',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '老河口市',
            value: '420682',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '枣阳市',
            value: '420683',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宜城市',
            value: '420684',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '鄂州市',
        value: '420700',
        level: 2,
        firstChar: 'E',
        children: [
          {
            label: '梁子湖区',
            value: '420702',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '华容区',
            value: '420703',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鄂城区',
            value: '420704',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '荆门市',
        value: '420800',
        level: 2,
        firstChar: 'J',
        children: [
          {
            label: '东宝区',
            value: '420802',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '掇刀区',
            value: '420804',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沙洋县',
            value: '420822',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '钟祥市',
            value: '420881',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '京山市',
            value: '420882',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '孝感市',
        value: '420900',
        level: 2,
        firstChar: 'X',
        children: [
          {
            label: '孝南区',
            value: '420902',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '孝昌县',
            value: '420921',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大悟县',
            value: '420922',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '云梦县',
            value: '420923',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '应城市',
            value: '420981',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安陆市',
            value: '420982',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '汉川市',
            value: '420984',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '荆州市',
        value: '421000',
        level: 2,
        firstChar: 'J',
        children: [
          {
            label: '沙市区',
            value: '421002',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '荆州区',
            value: '421003',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '公安县',
            value: '421022',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '监利县',
            value: '421023',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '江陵县',
            value: '421024',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '荆州经济技术开发区',
            value: '421071',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '石首市',
            value: '421081',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '洪湖市',
            value: '421083',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '松滋市',
            value: '421087',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '黄冈市',
        value: '421100',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '黄州区',
            value: '421102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '团风县',
            value: '421121',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '红安县',
            value: '421122',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '罗田县',
            value: '421123',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '英山县',
            value: '421124',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '浠水县',
            value: '421125',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '蕲春县',
            value: '421126',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '黄梅县',
            value: '421127',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙感湖管理区',
            value: '421171',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '麻城市',
            value: '421181',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '武穴市',
            value: '421182',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '咸宁市',
        value: '421200',
        level: 2,
        firstChar: 'X',
        children: [
          {
            label: '咸安区',
            value: '421202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '嘉鱼县',
            value: '421221',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '通城县',
            value: '421222',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '崇阳县',
            value: '421223',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '通山县',
            value: '421224',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '赤壁市',
            value: '421281',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '随州市',
        value: '421300',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '曾都区',
            value: '421303',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '随县',
            value: '421321',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '广水市',
            value: '421381',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '恩施土家族苗族自治州',
        value: '422800',
        level: 2,
        firstChar: 'E',
        children: [
          {
            label: '恩施市',
            value: '422801',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '利川市',
            value: '422802',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '建始县',
            value: '422822',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '巴东县',
            value: '422823',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宣恩县',
            value: '422825',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '咸丰县',
            value: '422826',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '来凤县',
            value: '422827',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鹤峰县',
            value: '422828',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '仙桃市',
        value: '429004',
        level: 2,
        firstChar: 'X',
        children: [],
      },
      {
        label: '潜江市',
        value: '429005',
        level: 2,
        firstChar: 'Q',
        children: [],
      },
      {
        label: '天门市',
        value: '429006',
        level: 2,
        firstChar: 'T',
        children: [],
      },
      {
        label: '神农架林区',
        value: '429021',
        level: 2,
        firstChar: 'S',
        children: [],
      },
    ],
  },
  {
    label: '湖南',
    value: '430000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '长沙市',
        value: '430100',
        level: 2,
        firstChar: 'Z',
        children: [
          {
            label: '芙蓉区',
            value: '430102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '天心区',
            value: '430103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '岳麓区',
            value: '430104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '开福区',
            value: '430105',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '雨花区',
            value: '430111',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '望城区',
            value: '430112',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '长沙县',
            value: '430121',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '浏阳市',
            value: '430181',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宁乡市',
            value: '430182',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '株洲市',
        value: '430200',
        level: 2,
        firstChar: 'Z',
        children: [
          {
            label: '荷塘区',
            value: '430202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '芦淞区',
            value: '430203',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '石峰区',
            value: '430204',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '天元区',
            value: '430211',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '渌口区',
            value: '430212',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '攸县',
            value: '430223',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '茶陵县',
            value: '430224',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '炎陵县',
            value: '430225',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '云龙示范区',
            value: '430271',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '醴陵市',
            value: '430281',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '湘潭市',
        value: '430300',
        level: 2,
        firstChar: 'X',
        children: [
          {
            label: '雨湖区',
            value: '430302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '岳塘区',
            value: '430304',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '湘潭县',
            value: '430321',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '湖南湘潭高新技术产业园区',
            value: '430371',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '湘潭昭山示范区',
            value: '430372',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '湘潭九华示范区',
            value: '430373',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '湘乡市',
            value: '430381',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '韶山市',
            value: '430382',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '衡阳市',
        value: '430400',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '珠晖区',
            value: '430405',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '雁峰区',
            value: '430406',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '石鼓区',
            value: '430407',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '蒸湘区',
            value: '430408',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南岳区',
            value: '430412',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '衡阳县',
            value: '430421',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '衡南县',
            value: '430422',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '衡山县',
            value: '430423',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '衡东县',
            value: '430424',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '祁东县',
            value: '430426',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '衡阳综合保税区',
            value: '430471',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '湖南衡阳高新技术产业园区',
            value: '430472',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '湖南衡阳松木经济开发区',
            value: '430473',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '耒阳市',
            value: '430481',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '常宁市',
            value: '430482',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '邵阳市',
        value: '430500',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '双清区',
            value: '430502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大祥区',
            value: '430503',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '北塔区',
            value: '430511',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新邵县',
            value: '430522',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '邵阳县',
            value: '430523',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '隆回县',
            value: '430524',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '洞口县',
            value: '430525',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '绥宁县',
            value: '430527',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新宁县',
            value: '430528',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '城步苗族自治县',
            value: '430529',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '武冈市',
            value: '430581',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '邵东市',
            value: '430582',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '岳阳市',
        value: '430600',
        level: 2,
        firstChar: 'Y',
        children: [
          {
            label: '岳阳楼区',
            value: '430602',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '云溪区',
            value: '430603',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '君山区',
            value: '430611',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '岳阳县',
            value: '430621',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '华容县',
            value: '430623',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '湘阴县',
            value: '430624',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平江县',
            value: '430626',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '岳阳市屈原管理区',
            value: '430671',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '汨罗市',
            value: '430681',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '临湘市',
            value: '430682',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '常德市',
        value: '430700',
        level: 2,
        firstChar: 'C',
        children: [
          {
            label: '武陵区',
            value: '430702',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鼎城区',
            value: '430703',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安乡县',
            value: '430721',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '汉寿县',
            value: '430722',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '澧县',
            value: '430723',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '临澧县',
            value: '430724',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '桃源县',
            value: '430725',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '石门县',
            value: '430726',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '常德市西洞庭管理区',
            value: '430771',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '津市市',
            value: '430781',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '张家界市',
        value: '430800',
        level: 2,
        firstChar: 'Z',
        children: [
          {
            label: '永定区',
            value: '430802',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '武陵源区',
            value: '430811',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '慈利县',
            value: '430821',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '桑植县',
            value: '430822',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '益阳市',
        value: '430900',
        level: 2,
        firstChar: 'Y',
        children: [
          {
            label: '资阳区',
            value: '430902',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '赫山区',
            value: '430903',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南县',
            value: '430921',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '桃江县',
            value: '430922',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安化县',
            value: '430923',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '益阳市大通湖管理区',
            value: '430971',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '湖南益阳高新技术产业园区',
            value: '430972',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沅江市',
            value: '430981',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '郴州市',
        value: '431000',
        level: 2,
        firstChar: 'C',
        children: [
          {
            label: '北湖区',
            value: '431002',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '苏仙区',
            value: '431003',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '桂阳县',
            value: '431021',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宜章县',
            value: '431022',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永兴县',
            value: '431023',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '嘉禾县',
            value: '431024',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '临武县',
            value: '431025',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '汝城县',
            value: '431026',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '桂东县',
            value: '431027',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安仁县',
            value: '431028',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '资兴市',
            value: '431081',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '永州市',
        value: '431100',
        level: 2,
        firstChar: 'Y',
        children: [
          {
            label: '零陵区',
            value: '431102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '冷水滩区',
            value: '431103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '祁阳县',
            value: '431121',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东安县',
            value: '431122',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '双牌县',
            value: '431123',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '道县',
            value: '431124',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '江永县',
            value: '431125',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宁远县',
            value: '431126',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '蓝山县',
            value: '431127',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新田县',
            value: '431128',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '江华瑶族自治县',
            value: '431129',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永州经济技术开发区',
            value: '431171',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永州市金洞管理区',
            value: '431172',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永州市回龙圩管理区',
            value: '431173',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '怀化市',
        value: '431200',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '鹤城区',
            value: '431202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '中方县',
            value: '431221',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沅陵县',
            value: '431222',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '辰溪县',
            value: '431223',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '溆浦县',
            value: '431224',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '会同县',
            value: '431225',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '麻阳苗族自治县',
            value: '431226',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新晃侗族自治县',
            value: '431227',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '芷江侗族自治县',
            value: '431228',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '靖州苗族侗族自治县',
            value: '431229',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '通道侗族自治县',
            value: '431230',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '怀化市洪江管理区',
            value: '431271',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '洪江市',
            value: '431281',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '娄底市',
        value: '431300',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '娄星区',
            value: '431302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '双峰县',
            value: '431321',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新化县',
            value: '431322',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '冷水江市',
            value: '431381',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '涟源市',
            value: '431382',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '湘西土家族苗族自治州',
        value: '433100',
        level: 2,
        firstChar: 'X',
        children: [
          {
            label: '吉首市',
            value: '433101',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '泸溪县',
            value: '433122',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '凤凰县',
            value: '433123',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '花垣县',
            value: '433124',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '保靖县',
            value: '433125',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '古丈县',
            value: '433126',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永顺县',
            value: '433127',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙山县',
            value: '433130',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '广东',
    value: '440000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '广州市',
        value: '440100',
        level: 2,
        firstChar: 'G',
        children: [
          {
            label: '荔湾区',
            value: '440103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '越秀区',
            value: '440104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '海珠区',
            value: '440105',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '天河区',
            value: '440106',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '白云区',
            value: '440111',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '黄埔区',
            value: '440112',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '番禺区',
            value: '440113',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '花都区',
            value: '440114',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南沙区',
            value: '440115',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '从化区',
            value: '440117',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '增城区',
            value: '440118',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '韶关市',
        value: '440200',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '武江区',
            value: '440203',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '浈江区',
            value: '440204',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '曲江区',
            value: '440205',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '始兴县',
            value: '440222',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '仁化县',
            value: '440224',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '翁源县',
            value: '440229',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乳源瑶族自治县',
            value: '440232',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新丰县',
            value: '440233',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乐昌市',
            value: '440281',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南雄市',
            value: '440282',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '深圳市',
        value: '440300',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '罗湖区',
            value: '440303',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '福田区',
            value: '440304',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南山区',
            value: '440305',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宝安区',
            value: '440306',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙岗区',
            value: '440307',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '盐田区',
            value: '440308',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙华区',
            value: '440309',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '坪山区',
            value: '440310',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '光明区',
            value: '440311',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '珠海市',
        value: '440400',
        level: 2,
        firstChar: 'Z',
        children: [
          {
            label: '香洲区',
            value: '440402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '斗门区',
            value: '440403',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '金湾区',
            value: '440404',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '汕头市',
        value: '440500',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '龙湖区',
            value: '440507',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '金平区',
            value: '440511',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '濠江区',
            value: '440512',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '潮阳区',
            value: '440513',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '潮南区',
            value: '440514',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '澄海区',
            value: '440515',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南澳县',
            value: '440523',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '佛山市',
        value: '440600',
        level: 2,
        firstChar: 'F',
        children: [
          {
            label: '禅城区',
            value: '440604',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南海区',
            value: '440605',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '顺德区',
            value: '440606',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '三水区',
            value: '440607',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '高明区',
            value: '440608',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '江门市',
        value: '440700',
        level: 2,
        firstChar: 'J',
        children: [
          {
            label: '蓬江区',
            value: '440703',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '江海区',
            value: '440704',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新会区',
            value: '440705',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '台山市',
            value: '440781',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '开平市',
            value: '440783',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鹤山市',
            value: '440784',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '恩平市',
            value: '440785',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '湛江市',
        value: '440800',
        level: 2,
        firstChar: 'Z',
        children: [
          {
            label: '赤坎区',
            value: '440802',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '霞山区',
            value: '440803',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '坡头区',
            value: '440804',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '麻章区',
            value: '440811',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '遂溪县',
            value: '440823',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '徐闻县',
            value: '440825',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '廉江市',
            value: '440881',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '雷州市',
            value: '440882',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '吴川市',
            value: '440883',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '茂名市',
        value: '440900',
        level: 2,
        firstChar: 'M',
        children: [
          {
            label: '茂南区',
            value: '440902',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '电白区',
            value: '440904',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '高州市',
            value: '440981',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '化州市',
            value: '440982',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '信宜市',
            value: '440983',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '肇庆市',
        value: '441200',
        level: 2,
        firstChar: 'Z',
        children: [
          {
            label: '端州区',
            value: '441202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鼎湖区',
            value: '441203',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '高要区',
            value: '441204',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '广宁县',
            value: '441223',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '怀集县',
            value: '441224',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '封开县',
            value: '441225',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '德庆县',
            value: '441226',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '四会市',
            value: '441284',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '惠州市',
        value: '441300',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '惠城区',
            value: '441302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '惠阳区',
            value: '441303',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '博罗县',
            value: '441322',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '惠东县',
            value: '441323',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙门县',
            value: '441324',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '梅州市',
        value: '441400',
        level: 2,
        firstChar: 'M',
        children: [
          {
            label: '梅江区',
            value: '441402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '梅县区',
            value: '441403',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大埔县',
            value: '441422',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '丰顺县',
            value: '441423',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '五华县',
            value: '441424',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平远县',
            value: '441426',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '蕉岭县',
            value: '441427',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '兴宁市',
            value: '441481',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '汕尾市',
        value: '441500',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '城区',
            value: '441502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '海丰县',
            value: '441521',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '陆河县',
            value: '441523',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '陆丰市',
            value: '441581',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '河源市',
        value: '441600',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '源城区',
            value: '441602',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '紫金县',
            value: '441621',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙川县',
            value: '441622',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '连平县',
            value: '441623',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '和平县',
            value: '441624',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东源县',
            value: '441625',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '阳江市',
        value: '441700',
        level: 2,
        firstChar: 'Y',
        children: [
          {
            label: '江城区',
            value: '441702',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阳东区',
            value: '441704',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阳西县',
            value: '441721',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阳春市',
            value: '441781',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '清远市',
        value: '441800',
        level: 2,
        firstChar: 'Q',
        children: [
          {
            label: '清城区',
            value: '441802',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '清新区',
            value: '441803',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '佛冈县',
            value: '441821',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阳山县',
            value: '441823',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '连山壮族瑶族自治县',
            value: '441825',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '连南瑶族自治县',
            value: '441826',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '英德市',
            value: '441881',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '连州市',
            value: '441882',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '东莞市',
        value: '441900',
        level: 2,
        firstChar: 'D',
        children: [
          {
            label: '全部',
            value: '441900',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '中山市',
        value: '442000',
        level: 2,
        firstChar: 'Z',
        children: [
          {
            label: '全部',
            value: '442000',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '潮州市',
        value: '445100',
        level: 2,
        firstChar: 'C',
        children: [
          {
            label: '湘桥区',
            value: '445102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '潮安区',
            value: '445103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '饶平县',
            value: '445122',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '揭阳市',
        value: '445200',
        level: 2,
        firstChar: 'J',
        children: [
          {
            label: '榕城区',
            value: '445202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '揭东区',
            value: '445203',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '揭西县',
            value: '445222',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '惠来县',
            value: '445224',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '普宁市',
            value: '445281',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '云浮市',
        value: '445300',
        level: 2,
        firstChar: 'Y',
        children: [
          {
            label: '云城区',
            value: '445302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '云安区',
            value: '445303',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新兴县',
            value: '445321',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '郁南县',
            value: '445322',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '罗定市',
            value: '445381',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '广西',
    value: '450000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '南宁市',
        value: '450100',
        level: 2,
        firstChar: 'N',
        children: [
          {
            label: '兴宁区',
            value: '450102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '青秀区',
            value: '450103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '江南区',
            value: '450105',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西乡塘区',
            value: '450107',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '良庆区',
            value: '450108',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '邕宁区',
            value: '450109',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '武鸣区',
            value: '450110',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '隆安县',
            value: '450123',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '马山县',
            value: '450124',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '上林县',
            value: '450125',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宾阳县',
            value: '450126',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '横县',
            value: '450127',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '柳州市',
        value: '450200',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '城中区',
            value: '450202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鱼峰区',
            value: '450203',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '柳南区',
            value: '450204',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '柳北区',
            value: '450205',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '柳江区',
            value: '450206',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '柳城县',
            value: '450222',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鹿寨县',
            value: '450223',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '融安县',
            value: '450224',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '融水苗族自治县',
            value: '450225',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '三江侗族自治县',
            value: '450226',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '桂林市',
        value: '450300',
        level: 2,
        firstChar: 'G',
        children: [
          {
            label: '秀峰区',
            value: '450302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '叠彩区',
            value: '450303',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '象山区',
            value: '450304',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '七星区',
            value: '450305',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '雁山区',
            value: '450311',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '临桂区',
            value: '450312',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阳朔县',
            value: '450321',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '灵川县',
            value: '450323',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '全州县',
            value: '450324',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '兴安县',
            value: '450325',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永福县',
            value: '450326',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '灌阳县',
            value: '450327',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙胜各族自治县',
            value: '450328',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '资源县',
            value: '450329',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平乐县',
            value: '450330',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '恭城瑶族自治县',
            value: '450332',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '荔浦市',
            value: '450381',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '梧州市',
        value: '450400',
        level: 2,
        firstChar: 'W',
        children: [
          {
            label: '万秀区',
            value: '450403',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '长洲区',
            value: '450405',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙圩区',
            value: '450406',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '苍梧县',
            value: '450421',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '藤县',
            value: '450422',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '蒙山县',
            value: '450423',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '岑溪市',
            value: '450481',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '北海市',
        value: '450500',
        level: 2,
        firstChar: 'B',
        children: [
          {
            label: '海城区',
            value: '450502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '银海区',
            value: '450503',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '铁山港区',
            value: '450512',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '合浦县',
            value: '450521',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '防城港市',
        value: '450600',
        level: 2,
        firstChar: 'F',
        children: [
          {
            label: '港口区',
            value: '450602',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '防城区',
            value: '450603',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '上思县',
            value: '450621',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东兴市',
            value: '450681',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '钦州市',
        value: '450700',
        level: 2,
        firstChar: 'Q',
        children: [
          {
            label: '钦南区',
            value: '450702',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '钦北区',
            value: '450703',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '灵山县',
            value: '450721',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '浦北县',
            value: '450722',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '贵港市',
        value: '450800',
        level: 2,
        firstChar: 'G',
        children: [
          {
            label: '港北区',
            value: '450802',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '港南区',
            value: '450803',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '覃塘区',
            value: '450804',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平南县',
            value: '450821',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '桂平市',
            value: '450881',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '玉林市',
        value: '450900',
        level: 2,
        firstChar: 'Y',
        children: [
          {
            label: '玉州区',
            value: '450902',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '福绵区',
            value: '450903',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '容县',
            value: '450921',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '陆川县',
            value: '450922',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '博白县',
            value: '450923',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '兴业县',
            value: '450924',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '北流市',
            value: '450981',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '百色市',
        value: '451000',
        level: 2,
        firstChar: 'B',
        children: [
          {
            label: '右江区',
            value: '451002',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '田阳区',
            value: '451003',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '田东县',
            value: '451022',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '德保县',
            value: '451024',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '那坡县',
            value: '451026',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '凌云县',
            value: '451027',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乐业县',
            value: '451028',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '田林县',
            value: '451029',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西林县',
            value: '451030',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '隆林各族自治县',
            value: '451031',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '靖西市',
            value: '451081',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平果市',
            value: '451082',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '贺州市',
        value: '451100',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '八步区',
            value: '451102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平桂区',
            value: '451103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '昭平县',
            value: '451121',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '钟山县',
            value: '451122',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '富川瑶族自治县',
            value: '451123',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '河池市',
        value: '451200',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '金城江区',
            value: '451202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宜州区',
            value: '451203',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南丹县',
            value: '451221',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '天峨县',
            value: '451222',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '凤山县',
            value: '451223',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东兰县',
            value: '451224',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '罗城仫佬族自治县',
            value: '451225',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '环江毛南族自治县',
            value: '451226',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '巴马瑶族自治县',
            value: '451227',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '都安瑶族自治县',
            value: '451228',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大化瑶族自治县',
            value: '451229',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '来宾市',
        value: '451300',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '兴宾区',
            value: '451302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '忻城县',
            value: '451321',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '象州县',
            value: '451322',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '武宣县',
            value: '451323',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '金秀瑶族自治县',
            value: '451324',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '合山市',
            value: '451381',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '崇左市',
        value: '451400',
        level: 2,
        firstChar: 'C',
        children: [
          {
            label: '江州区',
            value: '451402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '扶绥县',
            value: '451421',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宁明县',
            value: '451422',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙州县',
            value: '451423',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大新县',
            value: '451424',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '天等县',
            value: '451425',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '凭祥市',
            value: '451481',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '海南',
    value: '460000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '海口市',
        value: '460100',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '秀英区',
            value: '460105',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙华区',
            value: '460106',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '琼山区',
            value: '460107',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '美兰区',
            value: '460108',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '三亚市',
        value: '460200',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '海棠区',
            value: '460202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '吉阳区',
            value: '460203',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '天涯区',
            value: '460204',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '崖州区',
            value: '460205',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '儋州市',
        value: '460400',
        level: 2,
        firstChar: 'D',
        children: [
          {
            label: '全部',
            value: '460400',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '五指山市',
        value: '469001',
        level: 2,
        firstChar: 'W',
        children: [
          {
            label: '全部',
            value: '469001',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '琼海市',
        value: '469002',
        level: 2,
        firstChar: 'Q',
        children: [
          {
            label: '全部',
            value: '469002',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '文昌市',
        value: '469005',
        level: 2,
        firstChar: 'W',
        children: [
          {
            label: '全部',
            value: '469005',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '万宁市',
        value: '469006',
        level: 2,
        firstChar: 'W',
        children: [
          {
            label: '全部',
            value: '469006',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '东方市',
        value: '469007',
        level: 2,
        firstChar: 'D',
        children: [
          {
            label: '全部',
            value: '469007',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '定安县',
        value: '469021',
        level: 2,
        firstChar: 'D',
        children: [
          {
            label: '全部',
            value: '469021',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '屯昌县',
        value: '469022',
        level: 2,
        firstChar: 'T',
        children: [
          {
            label: '全部',
            value: '469022',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '澄迈县',
        value: '469023',
        level: 2,
        firstChar: 'C',
        children: [
          {
            label: '全部',
            value: '469023',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '临高县',
        value: '469024',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '全部',
            value: '469024',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '白沙黎族自治县',
        value: '469025',
        level: 2,
        firstChar: 'B',
        children: [
          {
            label: '全部',
            value: '469025',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '昌江黎族自治县',
        value: '469026',
        level: 2,
        firstChar: 'C',
        children: [
          {
            label: '全部',
            value: '469026',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '乐东黎族自治县',
        value: '469027',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '全部',
            value: '469027',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '陵水黎族自治县',
        value: '469028',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '全部',
            value: '469028',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '保亭黎族苗族自治县',
        value: '469029',
        level: 2,
        firstChar: 'B',
        children: [
          {
            label: '全部',
            value: '469029',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '琼中黎族苗族自治县',
        value: '469030',
        level: 2,
        firstChar: 'Q',
        children: [
          {
            label: '全部',
            value: '469030',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '重庆',
    value: '500000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '重庆市',
        value: '500100',
        level: 2,
        firstChar: 'Z',
        children: [
          {
            label: '万州区',
            value: '500101',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '涪陵区',
            value: '500102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '渝中区',
            value: '500103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大渡口区',
            value: '500104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '江北区',
            value: '500105',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沙坪坝区',
            value: '500106',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '九龙坡区',
            value: '500107',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南岸区',
            value: '500108',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '北碚区',
            value: '500109',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '綦江区',
            value: '500110',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大足区',
            value: '500111',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '渝北区',
            value: '500112',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '巴南区',
            value: '500113',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '黔江区',
            value: '500114',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '长寿区',
            value: '500115',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '江津区',
            value: '500116',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '合川区',
            value: '500117',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永川区',
            value: '500118',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南川区',
            value: '500119',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '璧山区',
            value: '500120',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '铜梁区',
            value: '500151',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '潼南区',
            value: '500152',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '荣昌区',
            value: '500153',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '开州区',
            value: '500154',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '梁平区',
            value: '500155',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '武隆区',
            value: '500156',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '城口县',
            value: '500229',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '丰都县',
            value: '500230',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '垫江县',
            value: '500231',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '忠县',
            value: '500233',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '云阳县',
            value: '500235',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '奉节县',
            value: '500236',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '巫山县',
            value: '500237',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '巫溪县',
            value: '500238',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '石柱土家族自治县',
            value: '500240',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '秀山土家族苗族自治县',
            value: '500241',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '酉阳土家族苗族自治县',
            value: '500242',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '彭水苗族土家族自治县',
            value: '500243',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '四川',
    value: '510000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '成都市',
        value: '510100',
        level: 2,
        firstChar: 'C',
        children: [
          {
            label: '锦江区',
            value: '510104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '青羊区',
            value: '510105',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '金牛区',
            value: '510106',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '武侯区',
            value: '510107',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '成华区',
            value: '510108',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙泉驿区',
            value: '510112',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '青白江区',
            value: '510113',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新都区',
            value: '510114',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '温江区',
            value: '510115',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '双流区',
            value: '510116',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '郫都区',
            value: '510117',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新津区',
            value: '510118',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '金堂县',
            value: '510121',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大邑县',
            value: '510129',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '蒲江县',
            value: '510131',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '都江堰市',
            value: '510181',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '彭州市',
            value: '510182',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '邛崃市',
            value: '510183',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '崇州市',
            value: '510184',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '简阳市',
            value: '510185',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '自贡市',
        value: '510300',
        level: 2,
        firstChar: 'Z',
        children: [
          {
            label: '自流井区',
            value: '510302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '贡井区',
            value: '510303',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大安区',
            value: '510304',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沿滩区',
            value: '510311',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '荣县',
            value: '510321',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '富顺县',
            value: '510322',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '攀枝花市',
        value: '510400',
        level: 2,
        firstChar: 'P',
        children: [
          {
            label: '东区',
            value: '510402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西区',
            value: '510403',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '仁和区',
            value: '510411',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '米易县',
            value: '510421',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '盐边县',
            value: '510422',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '泸州市',
        value: '510500',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '江阳区',
            value: '510502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '纳溪区',
            value: '510503',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙马潭区',
            value: '510504',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '泸县',
            value: '510521',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '合江县',
            value: '510522',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '叙永县',
            value: '510524',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '古蔺县',
            value: '510525',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '德阳市',
        value: '510600',
        level: 2,
        firstChar: 'D',
        children: [
          {
            label: '旌阳区',
            value: '510603',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '罗江区',
            value: '510604',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '中江县',
            value: '510623',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '广汉市',
            value: '510681',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '什邡市',
            value: '510682',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '绵竹市',
            value: '510683',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '绵阳市',
        value: '510700',
        level: 2,
        firstChar: 'M',
        children: [
          {
            label: '涪城区',
            value: '510703',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '游仙区',
            value: '510704',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安州区',
            value: '510705',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '三台县',
            value: '510722',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '盐亭县',
            value: '510723',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '梓潼县',
            value: '510725',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '北川羌族自治县',
            value: '510726',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平武县',
            value: '510727',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '江油市',
            value: '510781',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '广元市',
        value: '510800',
        level: 2,
        firstChar: 'G',
        children: [
          {
            label: '利州区',
            value: '510802',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '昭化区',
            value: '510811',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '朝天区',
            value: '510812',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '旺苍县',
            value: '510821',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '青川县',
            value: '510822',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '剑阁县',
            value: '510823',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '苍溪县',
            value: '510824',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '遂宁市',
        value: '510900',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '船山区',
            value: '510903',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安居区',
            value: '510904',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '蓬溪县',
            value: '510921',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大英县',
            value: '510923',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '射洪市',
            value: '510981',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '内江市',
        value: '511000',
        level: 2,
        firstChar: 'N',
        children: [
          {
            label: '市中区',
            value: '511002',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东兴区',
            value: '511011',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '威远县',
            value: '511024',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '资中县',
            value: '511025',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '内江经济开发区',
            value: '511071',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '隆昌市',
            value: '511083',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '乐山市',
        value: '511100',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '市中区',
            value: '511102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沙湾区',
            value: '511111',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '五通桥区',
            value: '511112',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '金口河区',
            value: '511113',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '犍为县',
            value: '511123',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '井研县',
            value: '511124',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '夹江县',
            value: '511126',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沐川县',
            value: '511129',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '峨边彝族自治县',
            value: '511132',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '马边彝族自治县',
            value: '511133',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '峨眉山市',
            value: '511181',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '南充市',
        value: '511300',
        level: 2,
        firstChar: 'N',
        children: [
          {
            label: '顺庆区',
            value: '511302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '高坪区',
            value: '511303',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '嘉陵区',
            value: '511304',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南部县',
            value: '511321',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '营山县',
            value: '511322',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '蓬安县',
            value: '511323',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '仪陇县',
            value: '511324',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西充县',
            value: '511325',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阆中市',
            value: '511381',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '眉山市',
        value: '511400',
        level: 2,
        firstChar: 'M',
        children: [
          {
            label: '东坡区',
            value: '511402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '彭山区',
            value: '511403',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '仁寿县',
            value: '511421',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '洪雅县',
            value: '511423',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '丹棱县',
            value: '511424',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '青神县',
            value: '511425',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '宜宾市',
        value: '511500',
        level: 2,
        firstChar: 'Y',
        children: [
          {
            label: '翠屏区',
            value: '511502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南溪区',
            value: '511503',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '叙州区',
            value: '511504',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '江安县',
            value: '511523',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '长宁县',
            value: '511524',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '高县',
            value: '511525',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '珙县',
            value: '511526',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '筠连县',
            value: '511527',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '兴文县',
            value: '511528',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '屏山县',
            value: '511529',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '广安市',
        value: '511600',
        level: 2,
        firstChar: 'G',
        children: [
          {
            label: '广安区',
            value: '511602',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '前锋区',
            value: '511603',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '岳池县',
            value: '511621',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '武胜县',
            value: '511622',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '邻水县',
            value: '511623',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '华蓥市',
            value: '511681',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '达州市',
        value: '511700',
        level: 2,
        firstChar: 'D',
        children: [
          {
            label: '通川区',
            value: '511702',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '达川区',
            value: '511703',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宣汉县',
            value: '511722',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '开江县',
            value: '511723',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大竹县',
            value: '511724',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '渠县',
            value: '511725',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '达州经济开发区',
            value: '511771',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '万源市',
            value: '511781',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '雅安市',
        value: '511800',
        level: 2,
        firstChar: 'Y',
        children: [
          {
            label: '雨城区',
            value: '511802',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '名山区',
            value: '511803',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '荥经县',
            value: '511822',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '汉源县',
            value: '511823',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '石棉县',
            value: '511824',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '天全县',
            value: '511825',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '芦山县',
            value: '511826',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宝兴县',
            value: '511827',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '巴中市',
        value: '511900',
        level: 2,
        firstChar: 'B',
        children: [
          {
            label: '巴州区',
            value: '511902',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '恩阳区',
            value: '511903',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '通江县',
            value: '511921',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南江县',
            value: '511922',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平昌县',
            value: '511923',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '巴中经济开发区',
            value: '511971',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '资阳市',
        value: '512000',
        level: 2,
        firstChar: 'Z',
        children: [
          {
            label: '雁江区',
            value: '512002',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安岳县',
            value: '512021',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乐至县',
            value: '512022',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '阿坝藏族羌族自治州',
        value: '513200',
        level: 2,
        firstChar: 'A',
        children: [
          {
            label: '马尔康市',
            value: '513201',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '汶川县',
            value: '513221',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '理县',
            value: '513222',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '茂县',
            value: '513223',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '松潘县',
            value: '513224',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '九寨沟县',
            value: '513225',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '金川县',
            value: '513226',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '小金县',
            value: '513227',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '黑水县',
            value: '513228',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '壤塘县',
            value: '513230',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阿坝县',
            value: '513231',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '若尔盖县',
            value: '513232',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '红原县',
            value: '513233',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '甘孜藏族自治州',
        value: '513300',
        level: 2,
        firstChar: 'G',
        children: [
          {
            label: '康定市',
            value: '513301',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '泸定县',
            value: '513322',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '丹巴县',
            value: '513323',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '九龙县',
            value: '513324',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '雅江县',
            value: '513325',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '道孚县',
            value: '513326',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '炉霍县',
            value: '513327',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '甘孜县',
            value: '513328',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新龙县',
            value: '513329',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '德格县',
            value: '513330',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '白玉县',
            value: '513331',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '石渠县',
            value: '513332',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '色达县',
            value: '513333',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '理塘县',
            value: '513334',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '巴塘县',
            value: '513335',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乡城县',
            value: '513336',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '稻城县',
            value: '513337',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '得荣县',
            value: '513338',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '凉山彝族自治州',
        value: '513400',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '西昌市',
            value: '513401',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '木里藏族自治县',
            value: '513422',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '盐源县',
            value: '513423',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '德昌县',
            value: '513424',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '会理县',
            value: '513425',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '会东县',
            value: '513426',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宁南县',
            value: '513427',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '普格县',
            value: '513428',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '布拖县',
            value: '513429',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '金阳县',
            value: '513430',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '昭觉县',
            value: '513431',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '喜德县',
            value: '513432',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '冕宁县',
            value: '513433',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '越西县',
            value: '513434',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '甘洛县',
            value: '513435',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '美姑县',
            value: '513436',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '雷波县',
            value: '513437',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '贵州',
    value: '520000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '贵阳市',
        value: '520100',
        level: 2,
        firstChar: 'G',
        children: [
          {
            label: '南明区',
            value: '520102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '云岩区',
            value: '520103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '花溪区',
            value: '520111',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乌当区',
            value: '520112',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '白云区',
            value: '520113',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '观山湖区',
            value: '520115',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '开阳县',
            value: '520121',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '息烽县',
            value: '520122',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '修文县',
            value: '520123',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '清镇市',
            value: '520181',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '六盘水市',
        value: '520200',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '钟山区',
            value: '520201',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '六枝特区',
            value: '520203',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '水城县',
            value: '520221',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '盘州市',
            value: '520281',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '遵义市',
        value: '520300',
        level: 2,
        firstChar: 'Z',
        children: [
          {
            label: '红花岗区',
            value: '520302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '汇川区',
            value: '520303',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '播州区',
            value: '520304',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '桐梓县',
            value: '520322',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '绥阳县',
            value: '520323',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '正安县',
            value: '520324',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '道真仡佬族苗族自治县',
            value: '520325',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '务川仡佬族苗族自治县',
            value: '520326',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '凤冈县',
            value: '520327',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '湄潭县',
            value: '520328',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '余庆县',
            value: '520329',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '习水县',
            value: '520330',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '赤水市',
            value: '520381',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '仁怀市',
            value: '520382',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '安顺市',
        value: '520400',
        level: 2,
        firstChar: 'A',
        children: [
          {
            label: '西秀区',
            value: '520402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平坝区',
            value: '520403',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '普定县',
            value: '520422',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '镇宁布依族苗族自治县',
            value: '520423',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '关岭布依族苗族自治县',
            value: '520424',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '紫云苗族布依族自治县',
            value: '520425',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '毕节市',
        value: '520500',
        level: 2,
        firstChar: 'B',
        children: [
          {
            label: '七星关区',
            value: '520502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大方县',
            value: '520521',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '黔西县',
            value: '520522',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '金沙县',
            value: '520523',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '织金县',
            value: '520524',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '纳雍县',
            value: '520525',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '威宁彝族回族苗族自治县',
            value: '520526',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '赫章县',
            value: '520527',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '铜仁市',
        value: '520600',
        level: 2,
        firstChar: 'T',
        children: [
          {
            label: '碧江区',
            value: '520602',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '万山区',
            value: '520603',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '江口县',
            value: '520621',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '玉屏侗族自治县',
            value: '520622',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '石阡县',
            value: '520623',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '思南县',
            value: '520624',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '印江土家族苗族自治县',
            value: '520625',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '德江县',
            value: '520626',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沿河土家族自治县',
            value: '520627',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '松桃苗族自治县',
            value: '520628',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '黔西南布依族苗族自治州',
        value: '522300',
        level: 2,
        firstChar: 'Q',
        children: [
          {
            label: '兴义市',
            value: '522301',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '兴仁市',
            value: '522302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '普安县',
            value: '522323',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '晴隆县',
            value: '522324',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '贞丰县',
            value: '522325',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '望谟县',
            value: '522326',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '册亨县',
            value: '522327',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安龙县',
            value: '522328',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '黔东南苗族侗族自治州',
        value: '522600',
        level: 2,
        firstChar: 'Q',
        children: [
          {
            label: '凯里市',
            value: '522601',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '黄平县',
            value: '522622',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '施秉县',
            value: '522623',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '三穗县',
            value: '522624',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '镇远县',
            value: '522625',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '岑巩县',
            value: '522626',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '天柱县',
            value: '522627',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '锦屏县',
            value: '522628',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '剑河县',
            value: '522629',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '台江县',
            value: '522630',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '黎平县',
            value: '522631',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '榕江县',
            value: '522632',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '从江县',
            value: '522633',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '雷山县',
            value: '522634',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '麻江县',
            value: '522635',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '丹寨县',
            value: '522636',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '黔南布依族苗族自治州',
        value: '522700',
        level: 2,
        firstChar: 'Q',
        children: [
          {
            label: '都匀市',
            value: '522701',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '福泉市',
            value: '522702',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '荔波县',
            value: '522722',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '贵定县',
            value: '522723',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '瓮安县',
            value: '522725',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '独山县',
            value: '522726',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平塘县',
            value: '522727',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '罗甸县',
            value: '522728',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '长顺县',
            value: '522729',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙里县',
            value: '522730',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '惠水县',
            value: '522731',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '三都水族自治县',
            value: '522732',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '云南',
    value: '530000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '昆明市',
        value: '530100',
        level: 2,
        firstChar: 'K',
        children: [
          {
            label: '五华区',
            value: '530102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '盘龙区',
            value: '530103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '官渡区',
            value: '530111',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西山区',
            value: '530112',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东川区',
            value: '530113',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '呈贡区',
            value: '530114',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '晋宁区',
            value: '530115',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '富民县',
            value: '530124',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宜良县',
            value: '530125',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '石林彝族自治县',
            value: '530126',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '嵩明县',
            value: '530127',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '禄劝彝族苗族自治县',
            value: '530128',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '寻甸回族彝族自治县',
            value: '530129',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安宁市',
            value: '530181',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '曲靖市',
        value: '530300',
        level: 2,
        firstChar: 'Q',
        children: [
          {
            label: '麒麟区',
            value: '530302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沾益区',
            value: '530303',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '马龙区',
            value: '530304',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '陆良县',
            value: '530322',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '师宗县',
            value: '530323',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '罗平县',
            value: '530324',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '富源县',
            value: '530325',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '会泽县',
            value: '530326',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宣威市',
            value: '530381',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '玉溪市',
        value: '530400',
        level: 2,
        firstChar: 'Y',
        children: [
          {
            label: '红塔区',
            value: '530402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '江川区',
            value: '530403',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '通海县',
            value: '530423',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '华宁县',
            value: '530424',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '易门县',
            value: '530425',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '峨山彝族自治县',
            value: '530426',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新平彝族傣族自治县',
            value: '530427',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '元江哈尼族彝族傣族自治县',
            value: '530428',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '澄江市',
            value: '530481',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '保山市',
        value: '530500',
        level: 2,
        firstChar: 'B',
        children: [
          {
            label: '隆阳区',
            value: '530502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '施甸县',
            value: '530521',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '龙陵县',
            value: '530523',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '昌宁县',
            value: '530524',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '腾冲市',
            value: '530581',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '昭通市',
        value: '530600',
        level: 2,
        firstChar: 'Z',
        children: [
          {
            label: '昭阳区',
            value: '530602',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鲁甸县',
            value: '530621',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '巧家县',
            value: '530622',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '盐津县',
            value: '530623',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大关县',
            value: '530624',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永善县',
            value: '530625',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '绥江县',
            value: '530626',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '镇雄县',
            value: '530627',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '彝良县',
            value: '530628',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '威信县',
            value: '530629',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '水富市',
            value: '530681',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '丽江市',
        value: '530700',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '古城区',
            value: '530702',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '玉龙纳西族自治县',
            value: '530721',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永胜县',
            value: '530722',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '华坪县',
            value: '530723',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宁蒗彝族自治县',
            value: '530724',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '普洱市',
        value: '530800',
        level: 2,
        firstChar: 'P',
        children: [
          {
            label: '思茅区',
            value: '530802',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宁洱哈尼族彝族自治县',
            value: '530821',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '墨江哈尼族自治县',
            value: '530822',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '景东彝族自治县',
            value: '530823',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '景谷傣族彝族自治县',
            value: '530824',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '镇沅彝族哈尼族拉祜族自治县',
            value: '530825',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '江城哈尼族彝族自治县',
            value: '530826',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '孟连傣族拉祜族佤族自治县',
            value: '530827',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '澜沧拉祜族自治县',
            value: '530828',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西盟佤族自治县',
            value: '530829',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '临沧市',
        value: '530900',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '临翔区',
            value: '530902',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '凤庆县',
            value: '530921',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '云县',
            value: '530922',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永德县',
            value: '530923',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '镇康县',
            value: '530924',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '双江拉祜族佤族布朗族傣族自治县',
            value: '530925',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '耿马傣族佤族自治县',
            value: '530926',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沧源佤族自治县',
            value: '530927',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '楚雄彝族自治州',
        value: '532300',
        level: 2,
        firstChar: 'C',
        children: [
          {
            label: '楚雄市',
            value: '532301',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '双柏县',
            value: '532322',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '牟定县',
            value: '532323',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南华县',
            value: '532324',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '姚安县',
            value: '532325',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大姚县',
            value: '532326',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永仁县',
            value: '532327',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '元谋县',
            value: '532328',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '武定县',
            value: '532329',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '禄丰县',
            value: '532331',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '红河哈尼族彝族自治州',
        value: '532500',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '个旧市',
            value: '532501',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '开远市',
            value: '532502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '蒙自市',
            value: '532503',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '弥勒市',
            value: '532504',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '屏边苗族自治县',
            value: '532523',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '建水县',
            value: '532524',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '石屏县',
            value: '532525',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '泸西县',
            value: '532527',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '元阳县',
            value: '532528',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '红河县',
            value: '532529',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '金平苗族瑶族傣族自治县',
            value: '532530',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '绿春县',
            value: '532531',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '河口瑶族自治县',
            value: '532532',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '文山壮族苗族自治州',
        value: '532600',
        level: 2,
        firstChar: 'W',
        children: [
          {
            label: '文山市',
            value: '532601',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '砚山县',
            value: '532622',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西畴县',
            value: '532623',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '麻栗坡县',
            value: '532624',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '马关县',
            value: '532625',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '丘北县',
            value: '532626',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '广南县',
            value: '532627',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '富宁县',
            value: '532628',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '西双版纳傣族自治州',
        value: '532800',
        level: 2,
        firstChar: 'X',
        children: [
          {
            label: '景洪市',
            value: '532801',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '勐海县',
            value: '532822',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '勐腊县',
            value: '532823',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '大理白族自治州',
        value: '532900',
        level: 2,
        firstChar: 'D',
        children: [
          {
            label: '大理市',
            value: '532901',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '漾濞彝族自治县',
            value: '532922',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '祥云县',
            value: '532923',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宾川县',
            value: '532924',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '弥渡县',
            value: '532925',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南涧彝族自治县',
            value: '532926',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '巍山彝族回族自治县',
            value: '532927',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永平县',
            value: '532928',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '云龙县',
            value: '532929',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '洱源县',
            value: '532930',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '剑川县',
            value: '532931',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鹤庆县',
            value: '532932',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '德宏傣族景颇族自治州',
        value: '533100',
        level: 2,
        firstChar: 'D',
        children: [
          {
            label: '瑞丽市',
            value: '533102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '芒市',
            value: '533103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '梁河县',
            value: '533122',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '盈江县',
            value: '533123',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '陇川县',
            value: '533124',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '怒江傈僳族自治州',
        value: '533300',
        level: 2,
        firstChar: 'N',
        children: [
          {
            label: '泸水市',
            value: '533301',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '福贡县',
            value: '533323',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '贡山独龙族怒族自治县',
            value: '533324',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '兰坪白族普米族自治县',
            value: '533325',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '迪庆藏族自治州',
        value: '533400',
        level: 2,
        firstChar: 'D',
        children: [
          {
            label: '香格里拉市',
            value: '533401',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '德钦县',
            value: '533422',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '维西傈僳族自治县',
            value: '533423',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '西藏',
    value: '540000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '拉萨市',
        value: '540100',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '城关区',
            value: '540102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '堆龙德庆区',
            value: '540103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '达孜区',
            value: '540104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '林周县',
            value: '540121',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '当雄县',
            value: '540122',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '尼木县',
            value: '540123',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '曲水县',
            value: '540124',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '墨竹工卡县',
            value: '540127',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '格尔木藏青工业园区',
            value: '540171',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '拉萨经济技术开发区',
            value: '540172',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西藏文化旅游创意园区',
            value: '540173',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '达孜工业园区',
            value: '540174',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '日喀则市',
        value: '540200',
        level: 2,
        firstChar: 'R',
        children: [
          {
            label: '桑珠孜区',
            value: '540202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南木林县',
            value: '540221',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '江孜县',
            value: '540222',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '定日县',
            value: '540223',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '萨迦县',
            value: '540224',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '拉孜县',
            value: '540225',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '昂仁县',
            value: '540226',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '谢通门县',
            value: '540227',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '白朗县',
            value: '540228',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '仁布县',
            value: '540229',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '康马县',
            value: '540230',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '定结县',
            value: '540231',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '仲巴县',
            value: '540232',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '亚东县',
            value: '540233',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '吉隆县',
            value: '540234',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '聂拉木县',
            value: '540235',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '萨嘎县',
            value: '540236',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '岗巴县',
            value: '540237',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '昌都市',
        value: '540300',
        level: 2,
        firstChar: 'C',
        children: [
          {
            label: '卡若区',
            value: '540302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '江达县',
            value: '540321',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '贡觉县',
            value: '540322',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '类乌齐县',
            value: '540323',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '丁青县',
            value: '540324',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '察雅县',
            value: '540325',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '八宿县',
            value: '540326',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '左贡县',
            value: '540327',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '芒康县',
            value: '540328',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '洛隆县',
            value: '540329',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '边坝县',
            value: '540330',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '林芝市',
        value: '540400',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '巴宜区',
            value: '540402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '工布江达县',
            value: '540421',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '米林县',
            value: '540422',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '墨脱县',
            value: '540423',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '波密县',
            value: '540424',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '察隅县',
            value: '540425',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '朗县',
            value: '540426',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '山南市',
        value: '540500',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '乃东区',
            value: '540502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '扎囊县',
            value: '540521',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '贡嘎县',
            value: '540522',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '桑日县',
            value: '540523',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '琼结县',
            value: '540524',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '曲松县',
            value: '540525',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '措美县',
            value: '540526',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '洛扎县',
            value: '540527',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '加查县',
            value: '540528',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '隆子县',
            value: '540529',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '错那县',
            value: '540530',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '浪卡子县',
            value: '540531',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '那曲市',
        value: '540600',
        level: 2,
        firstChar: 'N',
        children: [
          {
            label: '色尼区',
            value: '540602',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '嘉黎县',
            value: '540621',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '比如县',
            value: '540622',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '聂荣县',
            value: '540623',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安多县',
            value: '540624',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '申扎县',
            value: '540625',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '索县',
            value: '540626',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '班戈县',
            value: '540627',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '巴青县',
            value: '540628',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '尼玛县',
            value: '540629',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '双湖县',
            value: '540630',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '阿里地区',
        value: '542500',
        level: 2,
        firstChar: 'A',
        children: [
          {
            label: '普兰县',
            value: '542521',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '札达县',
            value: '542522',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '噶尔县',
            value: '542523',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '日土县',
            value: '542524',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '革吉县',
            value: '542525',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '改则县',
            value: '542526',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '措勤县',
            value: '542527',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '陕西',
    value: '610000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '西安市',
        value: '610100',
        level: 2,
        firstChar: 'X',
        children: [
          {
            label: '新城区',
            value: '610102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '碑林区',
            value: '610103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '莲湖区',
            value: '610104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '灞桥区',
            value: '610111',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '未央区',
            value: '610112',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '雁塔区',
            value: '610113',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阎良区',
            value: '610114',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '临潼区',
            value: '610115',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '长安区',
            value: '610116',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '高陵区',
            value: '610117',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鄠邑区',
            value: '610118',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '蓝田县',
            value: '610122',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '周至县',
            value: '610124',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '铜川市',
        value: '610200',
        level: 2,
        firstChar: 'T',
        children: [
          {
            label: '王益区',
            value: '610202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '印台区',
            value: '610203',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '耀州区',
            value: '610204',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宜君县',
            value: '610222',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '宝鸡市',
        value: '610300',
        level: 2,
        firstChar: 'B',
        children: [
          {
            label: '渭滨区',
            value: '610302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '金台区',
            value: '610303',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '陈仓区',
            value: '610304',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '凤翔县',
            value: '610322',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '岐山县',
            value: '610323',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '扶风县',
            value: '610324',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '眉县',
            value: '610326',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '陇县',
            value: '610327',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '千阳县',
            value: '610328',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '麟游县',
            value: '610329',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '凤县',
            value: '610330',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '太白县',
            value: '610331',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '咸阳市',
        value: '610400',
        level: 2,
        firstChar: 'X',
        children: [
          {
            label: '秦都区',
            value: '610402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '杨陵区',
            value: '610403',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '渭城区',
            value: '610404',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '三原县',
            value: '610422',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '泾阳县',
            value: '610423',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乾县',
            value: '610424',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '礼泉县',
            value: '610425',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永寿县',
            value: '610426',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '长武县',
            value: '610428',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '旬邑县',
            value: '610429',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '淳化县',
            value: '610430',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '武功县',
            value: '610431',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '兴平市',
            value: '610481',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '彬州市',
            value: '610482',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '渭南市',
        value: '610500',
        level: 2,
        firstChar: 'W',
        children: [
          {
            label: '临渭区',
            value: '610502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '华州区',
            value: '610503',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '潼关县',
            value: '610522',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大荔县',
            value: '610523',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '合阳县',
            value: '610524',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '澄城县',
            value: '610525',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '蒲城县',
            value: '610526',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '白水县',
            value: '610527',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '富平县',
            value: '610528',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '韩城市',
            value: '610581',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '华阴市',
            value: '610582',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '延安市',
        value: '610600',
        level: 2,
        firstChar: 'Y',
        children: [
          {
            label: '宝塔区',
            value: '610602',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安塞区',
            value: '610603',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '延长县',
            value: '610621',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '延川县',
            value: '610622',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '志丹县',
            value: '610625',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '吴起县',
            value: '610626',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '甘泉县',
            value: '610627',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '富县',
            value: '610628',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '洛川县',
            value: '610629',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宜川县',
            value: '610630',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '黄龙县',
            value: '610631',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '黄陵县',
            value: '610632',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '子长市',
            value: '610681',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '汉中市',
        value: '610700',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '汉台区',
            value: '610702',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '南郑区',
            value: '610703',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '城固县',
            value: '610722',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '洋县',
            value: '610723',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西乡县',
            value: '610724',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '勉县',
            value: '610725',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宁强县',
            value: '610726',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '略阳县',
            value: '610727',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '镇巴县',
            value: '610728',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '留坝县',
            value: '610729',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '佛坪县',
            value: '610730',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '榆林市',
        value: '610800',
        level: 2,
        firstChar: 'Y',
        children: [
          {
            label: '榆阳区',
            value: '610802',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '横山区',
            value: '610803',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '府谷县',
            value: '610822',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '靖边县',
            value: '610824',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '定边县',
            value: '610825',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '绥德县',
            value: '610826',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '米脂县',
            value: '610827',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '佳县',
            value: '610828',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '吴堡县',
            value: '610829',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '清涧县',
            value: '610830',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '子洲县',
            value: '610831',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '神木市',
            value: '610881',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '安康市',
        value: '610900',
        level: 2,
        firstChar: 'A',
        children: [
          {
            label: '汉滨区',
            value: '610902',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '汉阴县',
            value: '610921',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '石泉县',
            value: '610922',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宁陕县',
            value: '610923',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '紫阳县',
            value: '610924',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '岚皋县',
            value: '610925',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平利县',
            value: '610926',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '镇坪县',
            value: '610927',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '旬阳县',
            value: '610928',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '白河县',
            value: '610929',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '商洛市',
        value: '611000',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '商州区',
            value: '611002',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '洛南县',
            value: '611021',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '丹凤县',
            value: '611022',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '商南县',
            value: '611023',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '山阳县',
            value: '611024',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '镇安县',
            value: '611025',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '柞水县',
            value: '611026',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '甘肃',
    value: '620000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '兰州市',
        value: '620100',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '城关区',
            value: '620102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '七里河区',
            value: '620103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西固区',
            value: '620104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '安宁区',
            value: '620105',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '红古区',
            value: '620111',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永登县',
            value: '620121',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '皋兰县',
            value: '620122',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '榆中县',
            value: '620123',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '兰州新区',
            value: '620171',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '嘉峪关市',
        value: '620200',
        level: 2,
        firstChar: 'J',
        children: [],
      },
      {
        label: '金昌市',
        value: '620300',
        level: 2,
        firstChar: 'J',
        children: [
          {
            label: '金川区',
            value: '620302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永昌县',
            value: '620321',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '白银市',
        value: '620400',
        level: 2,
        firstChar: 'B',
        children: [
          {
            label: '白银区',
            value: '620402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平川区',
            value: '620403',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '靖远县',
            value: '620421',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '会宁县',
            value: '620422',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '景泰县',
            value: '620423',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '天水市',
        value: '620500',
        level: 2,
        firstChar: 'T',
        children: [
          {
            label: '秦州区',
            value: '620502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '麦积区',
            value: '620503',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '清水县',
            value: '620521',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '秦安县',
            value: '620522',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '甘谷县',
            value: '620523',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '武山县',
            value: '620524',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '张家川回族自治县',
            value: '620525',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '武威市',
        value: '620600',
        level: 2,
        firstChar: 'W',
        children: [
          {
            label: '凉州区',
            value: '620602',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '民勤县',
            value: '620621',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '古浪县',
            value: '620622',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '天祝藏族自治县',
            value: '620623',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '张掖市',
        value: '620700',
        level: 2,
        firstChar: 'Z',
        children: [
          {
            label: '甘州区',
            value: '620702',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '肃南裕固族自治县',
            value: '620721',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '民乐县',
            value: '620722',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '临泽县',
            value: '620723',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '高台县',
            value: '620724',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '山丹县',
            value: '620725',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '平凉市',
        value: '620800',
        level: 2,
        firstChar: 'P',
        children: [
          {
            label: '崆峒区',
            value: '620802',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '泾川县',
            value: '620821',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '灵台县',
            value: '620822',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '崇信县',
            value: '620823',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '庄浪县',
            value: '620825',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '静宁县',
            value: '620826',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '华亭市',
            value: '620881',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '酒泉市',
        value: '620900',
        level: 2,
        firstChar: 'J',
        children: [
          {
            label: '肃州区',
            value: '620902',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '金塔县',
            value: '620921',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '瓜州县',
            value: '620922',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '肃北蒙古族自治县',
            value: '620923',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阿克塞哈萨克族自治县',
            value: '620924',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '玉门市',
            value: '620981',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '敦煌市',
            value: '620982',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '庆阳市',
        value: '621000',
        level: 2,
        firstChar: 'Q',
        children: [
          {
            label: '西峰区',
            value: '621002',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '庆城县',
            value: '621021',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '环县',
            value: '621022',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '华池县',
            value: '621023',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '合水县',
            value: '621024',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '正宁县',
            value: '621025',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宁县',
            value: '621026',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '镇原县',
            value: '621027',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '定西市',
        value: '621100',
        level: 2,
        firstChar: 'D',
        children: [
          {
            label: '安定区',
            value: '621102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '通渭县',
            value: '621121',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '陇西县',
            value: '621122',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '渭源县',
            value: '621123',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '临洮县',
            value: '621124',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '漳县',
            value: '621125',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '岷县',
            value: '621126',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '陇南市',
        value: '621200',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '武都区',
            value: '621202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '成县',
            value: '621221',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '文县',
            value: '621222',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '宕昌县',
            value: '621223',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '康县',
            value: '621224',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西和县',
            value: '621225',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '礼县',
            value: '621226',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '徽县',
            value: '621227',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '两当县',
            value: '621228',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '临夏回族自治州',
        value: '622900',
        level: 2,
        firstChar: 'L',
        children: [
          {
            label: '临夏市',
            value: '622901',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '临夏县',
            value: '622921',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '康乐县',
            value: '622922',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永靖县',
            value: '622923',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '广河县',
            value: '622924',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '和政县',
            value: '622925',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '东乡族自治县',
            value: '622926',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '积石山保安族东乡族撒拉族自治县',
            value: '622927',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '甘南藏族自治州',
        value: '623000',
        level: 2,
        firstChar: 'G',
        children: [
          {
            label: '合作市',
            value: '623001',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '临潭县',
            value: '623021',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '卓尼县',
            value: '623022',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '舟曲县',
            value: '623023',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '迭部县',
            value: '623024',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '玛曲县',
            value: '623025',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '碌曲县',
            value: '623026',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '夏河县',
            value: '623027',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '青海',
    value: '630000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '西宁市',
        value: '630100',
        level: 2,
        firstChar: 'X',
        children: [
          {
            label: '城东区',
            value: '630102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '城中区',
            value: '630103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '城西区',
            value: '630104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '城北区',
            value: '630105',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '湟中区',
            value: '630106',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大通回族土族自治县',
            value: '630121',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '湟源县',
            value: '630123',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '海东市',
        value: '630200',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '乐都区',
            value: '630202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平安区',
            value: '630203',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '民和回族土族自治县',
            value: '630222',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '互助土族自治县',
            value: '630223',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '化隆回族自治县',
            value: '630224',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '循化撒拉族自治县',
            value: '630225',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '海北藏族自治州',
        value: '632200',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '门源回族自治县',
            value: '632221',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '祁连县',
            value: '632222',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '海晏县',
            value: '632223',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '刚察县',
            value: '632224',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '黄南藏族自治州',
        value: '632300',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '同仁县',
            value: '632321',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '尖扎县',
            value: '632322',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '泽库县',
            value: '632323',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '河南蒙古族自治县',
            value: '632324',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '海南藏族自治州',
        value: '632500',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '共和县',
            value: '632521',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '同德县',
            value: '632522',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '贵德县',
            value: '632523',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '兴海县',
            value: '632524',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '贵南县',
            value: '632525',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '果洛藏族自治州',
        value: '632600',
        level: 2,
        firstChar: 'G',
        children: [
          {
            label: '玛沁县',
            value: '632621',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '班玛县',
            value: '632622',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '甘德县',
            value: '632623',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '达日县',
            value: '632624',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '久治县',
            value: '632625',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '玛多县',
            value: '632626',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '玉树藏族自治州',
        value: '632700',
        level: 2,
        firstChar: 'Y',
        children: [
          {
            label: '玉树市',
            value: '632701',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '杂多县',
            value: '632722',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '称多县',
            value: '632723',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '治多县',
            value: '632724',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '囊谦县',
            value: '632725',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '曲麻莱县',
            value: '632726',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '海西蒙古族藏族自治州',
        value: '632800',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '格尔木市',
            value: '632801',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '德令哈市',
            value: '632802',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '茫崖市',
            value: '632803',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乌兰县',
            value: '632821',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '都兰县',
            value: '632822',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '天峻县',
            value: '632823',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '大柴旦行政委员会',
            value: '632857',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '宁夏',
    value: '640000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '银川市',
        value: '640100',
        level: 2,
        firstChar: 'Y',
        children: [
          {
            label: '兴庆区',
            value: '640104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西夏区',
            value: '640105',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '金凤区',
            value: '640106',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '永宁县',
            value: '640121',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '贺兰县',
            value: '640122',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '灵武市',
            value: '640181',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '石嘴山市',
        value: '640200',
        level: 2,
        firstChar: 'S',
        children: [
          {
            label: '大武口区',
            value: '640202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '惠农区',
            value: '640205',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '平罗县',
            value: '640221',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '吴忠市',
        value: '640300',
        level: 2,
        firstChar: 'W',
        children: [
          {
            label: '利通区',
            value: '640302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '红寺堡区',
            value: '640303',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '盐池县',
            value: '640323',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '同心县',
            value: '640324',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '青铜峡市',
            value: '640381',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '固原市',
        value: '640400',
        level: 2,
        firstChar: 'G',
        children: [
          {
            label: '原州区',
            value: '640402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '西吉县',
            value: '640422',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '隆德县',
            value: '640423',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '泾源县',
            value: '640424',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '彭阳县',
            value: '640425',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '中卫市',
        value: '640500',
        level: 2,
        firstChar: 'Z',
        children: [
          {
            label: '沙坡头区',
            value: '640502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '中宁县',
            value: '640521',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '海原县',
            value: '640522',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    label: '新疆',
    value: '650000',
    level: 1,
    firstChar: null,
    children: [
      {
        label: '乌鲁木齐市',
        value: '650100',
        level: 2,
        firstChar: 'W',
        children: [
          {
            label: '天山区',
            value: '650102',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沙依巴克区',
            value: '650103',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新市区',
            value: '650104',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '水磨沟区',
            value: '650105',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '头屯河区',
            value: '650106',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '达坂城区',
            value: '650107',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '米东区',
            value: '650109',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乌鲁木齐县',
            value: '650121',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '克拉玛依市',
        value: '650200',
        level: 2,
        firstChar: 'K',
        children: [
          {
            label: '独山子区',
            value: '650202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '克拉玛依区',
            value: '650203',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '白碱滩区',
            value: '650204',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乌尔禾区',
            value: '650205',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '吐鲁番市',
        value: '650400',
        level: 2,
        firstChar: 'T',
        children: [
          {
            label: '高昌区',
            value: '650402',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '鄯善县',
            value: '650421',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '托克逊县',
            value: '650422',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '哈密市',
        value: '650500',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '伊州区',
            value: '650502',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '巴里坤哈萨克自治县',
            value: '650521',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '伊吾县',
            value: '650522',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '昌吉回族自治州',
        value: '652300',
        level: 2,
        firstChar: 'C',
        children: [
          {
            label: '昌吉市',
            value: '652301',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阜康市',
            value: '652302',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '呼图壁县',
            value: '652323',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '玛纳斯县',
            value: '652324',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '奇台县',
            value: '652325',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '吉木萨尔县',
            value: '652327',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '木垒哈萨克自治县',
            value: '652328',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '博尔塔拉蒙古自治州',
        value: '652700',
        level: 2,
        firstChar: 'B',
        children: [
          {
            label: '博乐市',
            value: '652701',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阿拉山口市',
            value: '652702',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '精河县',
            value: '652722',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '温泉县',
            value: '652723',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '巴音郭楞蒙古自治州',
        value: '652800',
        level: 2,
        firstChar: 'B',
        children: [
          {
            label: '库尔勒市',
            value: '652801',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '轮台县',
            value: '652822',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '尉犁县',
            value: '652823',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '若羌县',
            value: '652824',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '且末县',
            value: '652825',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '焉耆回族自治县',
            value: '652826',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '和静县',
            value: '652827',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '和硕县',
            value: '652828',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '博湖县',
            value: '652829',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '库尔勒经济技术开发区',
            value: '652871',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '阿克苏地区',
        value: '652900',
        level: 2,
        firstChar: 'A',
        children: [
          {
            label: '阿克苏市',
            value: '652901',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '库车市',
            value: '652902',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '温宿县',
            value: '652922',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沙雅县',
            value: '652924',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新和县',
            value: '652925',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '拜城县',
            value: '652926',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乌什县',
            value: '652927',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阿瓦提县',
            value: '652928',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '柯坪县',
            value: '652929',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '克孜勒苏柯尔克孜自治州',
        value: '653000',
        level: 2,
        firstChar: 'K',
        children: [
          {
            label: '阿图什市',
            value: '653001',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阿克陶县',
            value: '653022',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '阿合奇县',
            value: '653023',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乌恰县',
            value: '653024',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '喀什地区',
        value: '653100',
        level: 2,
        firstChar: 'K',
        children: [
          {
            label: '喀什市',
            value: '653101',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '疏附县',
            value: '653121',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '疏勒县',
            value: '653122',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '英吉沙县',
            value: '653123',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '泽普县',
            value: '653124',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '莎车县',
            value: '653125',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '叶城县',
            value: '653126',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '麦盖提县',
            value: '653127',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '岳普湖县',
            value: '653128',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '伽师县',
            value: '653129',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '巴楚县',
            value: '653130',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '塔什库尔干塔吉克自治县',
            value: '653131',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '和田地区',
        value: '653200',
        level: 2,
        firstChar: 'H',
        children: [
          {
            label: '和田市',
            value: '653201',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '和田县',
            value: '653221',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '墨玉县',
            value: '653222',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '皮山县',
            value: '653223',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '洛浦县',
            value: '653224',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '策勒县',
            value: '653225',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '于田县',
            value: '653226',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '民丰县',
            value: '653227',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '伊犁哈萨克自治州',
        value: '654000',
        level: 2,
        firstChar: 'Y',
        children: [
          {
            label: '伊宁市',
            value: '654002',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '奎屯市',
            value: '654003',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '霍尔果斯市',
            value: '654004',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '伊宁县',
            value: '654021',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '察布查尔锡伯自治县',
            value: '654022',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '霍城县',
            value: '654023',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '巩留县',
            value: '654024',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '新源县',
            value: '654025',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '昭苏县',
            value: '654026',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '特克斯县',
            value: '654027',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '尼勒克县',
            value: '654028',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '塔城地区',
        value: '654200',
        level: 2,
        firstChar: 'T',
        children: [
          {
            label: '塔城市',
            value: '654201',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '乌苏市',
            value: '654202',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '额敏县',
            value: '654221',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '沙湾县',
            value: '654223',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '托里县',
            value: '654224',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '裕民县',
            value: '654225',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '和布克赛尔蒙古自治县',
            value: '654226',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '阿勒泰地区',
        value: '654300',
        level: 2,
        firstChar: 'A',
        children: [
          {
            label: '阿勒泰市',
            value: '654301',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '布尔津县',
            value: '654321',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '富蕴县',
            value: '654322',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '福海县',
            value: '654323',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '哈巴河县',
            value: '654324',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '青河县',
            value: '654325',
            level: 3,
            firstChar: null,
            children: null,
          },
          {
            label: '吉木乃县',
            value: '654326',
            level: 3,
            firstChar: null,
            children: null,
          },
        ],
      },
      {
        label: '石河子市',
        value: '659100',
        level: 2,
        firstChar: 'S',
        children: null,
      },
      {
        label: '阿拉尔市',
        value: '659200',
        level: 2,
        firstChar: 'A',
        children: null,

      },
      {
        label: '图木舒克市',
        value: '659300',
        level: 2,
        firstChar: 'T',
        children: null,

      },
      {
        label: '五家渠市',
        value: '659400',
        level: 2,
        firstChar: 'W',
        children: null,

      },
      {
        label: '北屯市',
        value: '659500',
        level: 2,
        firstChar: 'B',
        children: null,

      },
      {
        label: '铁门关市',
        value: '659600',
        level: 2,
        firstChar: 'T',
        children: null,

      },
      {
        label: '双河市',
        value: '659700',
        level: 2,
        firstChar: 'S',
        children: null,

      },
      {
        label: '可克达拉市',
        value: '659800',
        level: 2,
        firstChar: 'K',
        children: null,

      },
      {
        label: '昆玉市',
        value: '659900',
        level: 2,
        firstChar: 'K',
        children: null,

      },
      {
        label: '胡杨河市',
        value: '651000',
        level: 2,
        firstChar: 'H',
        children: null,

      },
    ],
  },
  {
    label: '台湾',
    value: '710000',
    level: 1,
    firstChar: null,
    children: null,
  },
  {
    label: '香港',
    value: '810000',
    level: 1,
    firstChar: null,
    children: null,
  },
  {
    label: '澳门',
    value: '820000',
    level: 1,
    firstChar: null,
    children: null,
  },
]
export const Credit = [{
  "label": "工商(9开头)",
  "value": "9",
  "level": 1,
  "firstChar": null,
  'children': [{
    "label": "企业(91开头)",
    "value": "91",
    "level": 2,
    "firstChar": null,
  }, {
    "label": "个体工商户(92开头)",
    "value": "92",
    "level": 2,
    "firstChar": null,
  }, {
    "label": "农民专业合作社(93开头)",
    "value": "93",
    "level": 2,
    "firstChar": null,
  },
  ]
},
{
  "label": "机构(1开头)",
  "value": "1",
  "level": 1,
  "firstChar": null,
  'children': [{
    "label": "机关(11开头)",
    "value": "11",
    "level": 2,
    "firstChar": null,
  }, {
    "label": "事业单位(12开头)",
    "value": "12",
    "level": 2,
    "firstChar": null,
  }, {
    "label": "中央编办直接管理机构编制的群众团体(13开头)",
    "value": "13",
    "level": 2,
    "firstChar": null,
  }, {
    "label": "其他(19开头)",
    "value": "19",
    "level": 2,
    "firstChar": null,
  },
  ]
},
{
  "label": "民政(5开头)",
  "value": "5",
  "level": 1,
  "firstChar": null,
  'children': [{
    "label": "社会团体(51开头)",
    "value": "51",
    "level": 2,
    "firstChar": null,
  }, {
    "label": "民办非企业单位(52开头)",
    "value": "52",
    "level": 2,
    "firstChar": null,
  }, {
    "label": "基金会(53开头)",
    "value": "53",
    "level": 2,
    "firstChar": null,
  }, {
    "label": "其他(59开头)",
    "value": "59",
    "level": 2,
    "firstChar": null,
  },
  ]
},
{
  "label": "其他(Y开头)",
  "value": "Y",
  "level": 1,
  "firstChar": null,
  'children': [{
    "label": "其他(Y1开头)",
    "value": "Y1",
    "level": 2,
    "firstChar": null,
  }
  ]
},

]
const treeData1 = (list, value, key) => {
  if (list.children && list.children.length) {
    list.children.map(v => {
      v.value = value + "" + v.code;
      v.label = v.name;
      v.rank = key;
      treeData1(v, v.value, key + 1);
    });
  }
  return list;
}
Industry.map(v => {
  v.value = v.code;
  v.label = v.name;
  v.rank = 1;
  return treeData1(v, v.code, 2);
});

let obj = {};
const treeData = (list, parentId, type) => {
  list.forEach((item) => {
    if (parentId == 1) {
      if (type == 2) { obj[item.value] = item.label } else { obj[item.value.slice(0, 2)] = item.label }
    } else if (parentId == 2) {
      if (type == 2) { obj[item.value] = item.label } else { obj[item.value.slice(0, 4)] = item.label }
    } else if (parentId == 3) {
      if (type == 2) { obj[item.value] = item.label } else { obj[Number.parseInt(item.value)] = item.label }
    } else if (type == 2 && parentId == 4) {
      obj[item.value] = item.label
    }
    if (item.children && item.children.length) {
      treeData(item.children, (item.level || item.rank) + 1, type);
    }
  });
  return obj
}
export const cityData = (type, data) => {
  let arr = type == 1 ? cityByCodeData : type == 2 ? Industry : Credit;
  obj = {};
  let obj1 = treeData(arr, 1, type), arr2 = []
  data.forEach(v => {
    if (v.s) {
      v.s.forEach(p => {
        if (p.t || p.c) {
          if (p.c) {
            p.c.forEach(k => {
              if (k.c) {
                k.c.forEach(kk => {
                  arr2.unshift(obj1[v.f + '' + p.s + '' + k.s + '' + kk.s])
                })
              } else {
                arr2.unshift(obj1[v.f + '' + p.s + '' + k.s])
              }
            })
          } else {
            p.t.forEach(k => {
              arr2.unshift(obj1[v.f + '' + p.s + '' + k])
            })
          }
        } else {
          arr2.unshift(obj1[(type != 3 ? v.f : '') + '' + p.s])
        }
      })
    } else {
      arr2.push(obj1[v.f])
    }
  })
  return arr2
} 
