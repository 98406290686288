
// action是一个纯对象，不要觉得它是一个函数，要看return回来的其实就是一个object

export const change_operInfo = (operInfo) => ({
    type: 'change_operInfo',
    operInfo: operInfo
})
export const change_operAuth = (operAuth) => ({
    type: 'change_operAuth',
    operAuth: operAuth
})
