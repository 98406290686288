import { Axios } from '../../axios';

export const GetDepartList = (value) => ({
    type: 'GetDepartList',
    value: value
})

export const DepartList = () => {
	return async (dispatch) => {
        let res = await Axios('get', "/departs", {parentId:1});
        dispatch(GetDepartList(res.data))
	}
}

