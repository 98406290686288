import { useState, useEffect, useRef } from "react";
const useGetState = (initVal) => {
    const [state, setState] = useState(initVal);
    const ref = useRef(initVal);
    const setStateCopy = (newVal) => {
        ref.current = newVal;
        setState(newVal);
    };
    const getState = () => ref.current;
    return [state, setStateCopy, getState];
};

export default useGetState;
