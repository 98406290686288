import React, { Component } from "react";
import {
    Row,
    Breadcrumb,
    Button,
    Form,
    message,
    DatePicker,
    Modal,
    Input,
    Select,
    Col,
    Radio,
    Popover,
    Table,
    TreeSelect,
    notification,
} from "antd";
import { AreaSelect } from "../../common";
import Filter from "../../common/filter";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Axios } from "../../axios";
import moment from "moment";
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;
const RangePicker = DatePicker.RangePicker;
let setPageData = {},
    sendBody = {},
    Timer;
let loading_exportOrders = false,
    message_loading = "";
class WindControlDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tData: [],
            loading: false,
            totalNum: 0,
            currentPage: 1,
        };
        sendBody = window.routerCache.WindControlDetails.sendBody || {};
        setPageData = {};
    }
    // 获取表格数据
    refreshTableByData = (data, totalNum, currentPage) => {
        this.setState(
            { tData: data, totalNum: totalNum, currentPage: currentPage },
            this.polling
        );
    };
    // 获取表格数据
    refreshTable = async () => {
        try {
            this.setState({
                loading: true,
            });
            let send = sendBody;
            // send.workType = window.routerCache.WindControlDetails.send_workType || '';
            send.pageNum = this.state.currentPage + "";
            send.pageSize = "10";
            send.batchNo = this.props.match.params.id;

            let res = await Axios("get", "/crmbatch/diagnosis/page", send);
            this.setState(
                {
                    loading: false,
                    tData: res.data.dataList,
                    totalNum: res.data.totalNum,
                },
                this.polling
            );
            setPageData[this.state.currentPage + ""] = res.data.dataList;
        } catch (err) {
            // message.error(err);
            this.setState({ loading: false });
        }
    };

    handleTableChange = async (pagination) => {
        this.setState({ loading: true });
        if (Object.keys(setPageData).includes(pagination.current + "")) {
            this.setState(
                {
                    loading: false,
                    tData: setPageData[pagination.current + ""],
                    currentPage: parseInt(pagination.current),
                },
                this.polling
            );
        } else {
            try {
                let send = sendBody;
                send.pageNum = pagination.current + "";
                send.pageSize = "10";
                send.batchNo = this.props.match.params.id;

                let res = await Axios("get", "/crmbatch/diagnosis/page", send);
                this.setState(
                    {
                        loading: false,
                        tData: res.data.dataList,
                        totalNum: res.data.totalNum,
                        currentPage: pagination.current,
                    },
                    this.polling
                );
                setPageData[pagination.current + ""] = res.data.dataList;
            } catch (err) {
                // message.error(err.message);
                this.setState({ loading: false });
            }
        }
    };
    // 组件渲染后获取外界数据(GET)
    componentDidMount() {
        this.refreshTable();
        window.winhcFn.changeDept = () => {
            this.setState(
                {
                    currentPage: 1,
                },
                () => {
                    this.refreshTable();
                }
            );
        };
    }
    componentWillUnmount() {
        window.routerCache.WindControlDetails.currentPage =
            this.state.currentPage;
        window.routerCache.WindControlDetails.sendBody = sendBody;
        if (Timer) {
            clearTimeout(Timer);
        }
    }

    polling = () => {
        let This = this;
        let gonext = false;
        this.state.tData.forEach((item) => {
            if (item.status == "0") {
                gonext = true;
                return;
            }
        });
        if (!gonext) {
            return;
        }
        if (Timer) {
            clearTimeout(Timer);
        }
        Timer = setTimeout(async () => {
            try {
                let send = sendBody;
                send.pageNum = this.state.currentPage + "";
                send.pageSize = "10";
                send.batchNo = This.props.match.params.id;

                let res = await Axios("get", "/crmbatch/diagnosis/page", send);
                if (this.state.currentPage == sendBody.pageNum) {
                    this.setState(
                        {
                            tData: res.data.dataList,
                            totalNum: res.data.totalNum,
                        },
                        this.polling
                    );
                    setPageData[this.state.currentPage + ""] =
                        res.data.dataList;
                }
            } catch (err) {
                // message.error(err.message);
                console.log(err);
            }
        }, 2000);
    };

    exportOrders = async () => {
        if (!loading_exportOrders) {
            loading_exportOrders = true;
            message_loading = message.loading("生成中", 0);
            try {
                let res = await Axios("get", "/crmbatch/diagnosis/poi", {
                    batchNo: this.props.match.params.id,
                });
                if (res.code == "00") {
                    notification.success({
                        message: res.message,
                    });
                } else {
                    notification.warning({
                        message: res.message,
                    });
                }
                loading_exportOrders = false;
                message_loading();
                message_loading = "";
            } catch (error) {
                console.log(error);
                loading_exportOrders = false;
                message_loading();
                message_loading = "";
            }
        }
    };
    render() {
        let columns = [
            {
                title: "ID",
                dataIndex: "diagnosisId",
                width: 50,
                key: "1",
                render: (t, r) => {
                    return (
                        <Link to={"/CompanyCheckDetail/" + r.diagnosisId}>
                            {t}
                        </Link>
                    );
                },
            },
            {
                title: "原告",
                dataIndex: "creditorName",
                width: 180,
                key: "2",
                render: (text) => (
                    <div style={{ cursor: "pointer" }}>
                        {text && text.length > 16 ? (
                            <Popover
                                content={
                                    <div
                                        style={{
                                            padding: "10px",
                                            maxWidth: "500px",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {text}
                                    </div>
                                }
                            >
                                <span>{Filter.strLength(text, 16)}</span>
                            </Popover>
                        ) : (
                            text
                        )}
                    </div>
                ),
            },
            {
                title: "被告",
                dataIndex: "debtorName",
                key: "3",
                width: 180,
                render: (text) => (
                    <div style={{ cursor: "pointer" }}>
                        {text && text.length > 16 ? (
                            <Popover
                                content={
                                    <div
                                        style={{
                                            padding: "10px",
                                            maxWidth: "500px",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {text}
                                    </div>
                                }
                            >
                                <span>{Filter.strLength(text, 16)}</span>
                            </Popover>
                        ) : (
                            text
                        )}
                    </div>
                ),
            },
            {
                title: "标的额（万元）",
                dataIndex: "caseAmt",
                key: "4",
                width: 100,
                render: (t) => (
                    <span>
                        {" "}
                        {window._SYT_util.formatMoney(
                            window._SYT_util.math.accDiv(t * 1, 10000),
                            2
                        )}
                    </span>
                ),
            },
            {
                title: "提交时间",
                dataIndex: "createTime",
                width: 130,
                key: "5",
            },
            {
                title: "评级",
                dataIndex: "grade",
                width: 80,
                key: "6",
            },
            {
                title: "评分",
                dataIndex: "score",
                width: 80,
                key: "9",
            },
            {
                title: "状态",
                dataIndex: "status",
                width: 90,
                key: "7",
                render: (text, record, index) => (
                    <span>
                        {text == "0"
                            ? "诊断中"
                            : text == "1"
                                ? "诊断完成"
                                : text == "9"
                                    ? "诊断失败"
                                    : ""}{" "}
                        {text == "0" ? <LoadingOutlined /> : null}
                    </span>
                ),
            },
            {
                title: "操作",
                width: 60,
                key: "8",
                dataIndex: "operation",
                render: (text, r) => (
                    <Link to={"/CompanyCheckDetail/" + r.diagnosisId}>
                        查看
                    </Link>
                ),
            },
        ];
        return (
            <div className="pageContainer">
                <Row className="headNav" type="flex" justify="space-between">
                    <Col span={24}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>营销获客</Breadcrumb.Item>
                            <Breadcrumb.Item>
                                {" "}
                                <Link to="/staffNameListManage">
                                    名单资料管理
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>风控预检详情</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={24} className="title">
                        <span className="name">导入风控预检数量列表</span>
                        <Button onClick={this.exportOrders} style={{ float: "right" }}>
                            智能预检导出
                        </Button>
                    </Col>
                </Row>
                <div className="scrollContainer">
                    <div className="scrollMain">
                        <div style={{ backgroundColor: "#fff" }}>
                            <Table
                                dataSource={this.state.tData}
                                columns={columns}
                                size="middle"
                                pagination={{
                                    size: "large",
                                    showQuickJumper: true,
                                    pageSize: 10,
                                    total: parseInt(this.state.totalNum),
                                    current: Number(this.state.currentPage),
                                }}
                                loading={this.state.loading}
                                onChange={this.handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return state;
    },
    (dispatch) => ({})
)(WindControlDetails);
