import React, { Component } from "react";
import {
  Row,
  message,
  Button,
  Table,
  Modal,
  DatePicker,
  Tabs,
  Radio,
  Select
} from "antd";
import { Axios } from "../../axios";
import Filter from "../../common/filter";
import { connect } from "react-redux";
import G2 from "@antv/g2";
import moment from "moment";
import "./per.scss";

const DataSet = require("@antv/data-set");
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Option } = Select;

let funnelThis = {};
class FunnelM extends Component {
  constructor(props) {
    super(props);
    let quan = JSON.parse(localStorage.getItem("legal_userInfo") || '{}')

    this.state = {
      spinning: false,
      activeKey:
        window.routerCache.perforIndicators.Funnel.activeKey || "1",
      seltype: window.routerCache.perforIndicators.Funnel.seltype || "1",
      dateStr:
        window.routerCache.perforIndicators.Funnel.dateStr ||
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1) +
        "月",
      dateFrom:
        window.routerCache.perforIndicators.Funnel.dateFrom ||
        Filter.timetrans(
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            1
          ),
          "day"
        ),
      dateEnd:
        window.routerCache.perforIndicators.Funnel.dateEnd ||
        Filter.timetrans(
          new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            0
          ),
          "day"
        ),
      dateFrom_f:
        window.routerCache.perforIndicators.Funnel.dateFrom_f ||
        undefined,
      dateEnd_f:
        window.routerCache.perforIndicators.Funnel.dateEnd_f ||
        undefined,
      queryRange:
        ["4", "5", "6", "senior"].includes(String((this.props.userInfo || {}).brokerType || (this.props.userInfo || {}).job || (quan || {}).brokerType || (quan || {}).job)) ? 'all' : 'self'
    };
  }

  jidudate = () => {
    let jiduStart = "",
      jiduEnd = "",
      dateStr = "",
      sl = new Date().getMonth() + 1;
    if (sl >= 1 && sl <= 3) {
      jiduStart = new Date().getFullYear() + "-01-01";
      jiduEnd = new Date().getFullYear() + "-03-31";
      dateStr = new Date().getFullYear() + "第一季度";
    } else if (sl >= 4 && sl <= 6) {
      jiduStart = new Date().getFullYear() + "-04-01";
      jiduEnd = new Date().getFullYear() + "-06-30";
      dateStr = new Date().getFullYear() + "第二季度";
    } else if (sl >= 7 && sl <= 9) {
      jiduStart = new Date().getFullYear() + "-07-01";
      jiduEnd = new Date().getFullYear() + "-09-30";
      dateStr = new Date().getFullYear() + "第三季度";
    } else if (sl >= 10 && sl <= 12) {
      jiduStart = new Date().getFullYear() + "-10-01";
      jiduEnd = new Date().getFullYear() + "-12-31";
      dateStr = new Date().getFullYear() + "第四季度";
    }
    console.log(jiduStart, jiduEnd, dateStr);
    return [jiduStart, jiduEnd, dateStr];
  };
  refresh = () => {
    this.getData();
  };

  componentDidMount () {
    window.routerCache.perforIndicators.other.thisObj.Funnel = this.refresh;
    this.getData();
  }

  componentWillUnmount () {
    window.routerCache.perforIndicators.Funnel.activeKey =
      this.state.activeKey;
    window.routerCache.perforIndicators.Funnel.seltype = this.state.seltype;
    window.routerCache.perforIndicators.Funnel.dateStr = this.state.dateStr;
    window.routerCache.perforIndicators.Funnel.dateFrom =
      this.state.dateFrom;
    window.routerCache.perforIndicators.Funnel.dateEnd = this.state.dateEnd;
    window.routerCache.perforIndicators.Funnel.dateFrom_f =
      this.state.dateFrom_f;
    window.routerCache.perforIndicators.Funnel.dateEnd_f =
      this.state.dateEnd_f;
    // window.routerCache.perforIndicators.Funnel.queryRange =
    //   this.state.queryRange;
  }
  getData = async () => {
    try {
      if (!this.state.dateFrom) {
        return;
      }
      this.setState({ spinning: true });
      let res = await Axios("get", "/brokeruser/funnel/risk", {
        dateFrom: this.state.dateFrom,
        dateEnd: this.state.dateEnd,
        deptId: window.globalData.headDeptId,
        cache: false,
        queryRange: this.state.queryRange || undefined
      });
      console.log(res);
      res.data.funnelVOS = [
        { stage: "意向", count: res.data.intentionNum },
        { stage: "付费", count: res.data.payNum },
        { stage: "续费", count: res.data.renewNum },
      ];
      res.data.funnelVOS.forEach((item, index) => {
        item.pv2 = item.count;
        if (item.stage == "意向") {
          item.pv = 99;
        } else if (item.stage == "付费") {
          item.pv = 66;
        } else if (item.stage == "续费") {
          item.pv = 33;
        }
        item.action = item.stage;
      });
      this.setState({ spinning: false });

      this.getChart(res);
    } catch (err) {
      if (err.message) {
        message.error(err.message);
      }
      console.log(err);
      this.setState({ spinning: false });
    }
  };
  getChart = (res) => {
    let data = [],
      total = 0;

    data = res.data.funnelVOS;
    total = 99;

    const DataView = DataSet.DataView;
    const dv = new DataView().source(data);
    dv.transform({
      type: "map",
      callback: function callback (row) {
        if (total) {
          row.percent = row.pv / total;
        } else {
          row.percent = 0;
        }
        return row;
      },
    });
    data = dv.rows;
    document.querySelector("#Chartid5").innerHTML = null;
    const chart = new G2.Chart({
      container: "Chartid5",
      forceFit: true,
      height: 400,
      padding: [20, 120, 40],
    });
    chart.source(data, {
      percent: {
        nice: false,
      },
    });
    chart.axis(false);

    chart.tooltip(false);

    chart.coord("rect").transpose().scale(1, -1);
    chart
      .intervalSymmetric()
      .position("action*percent")
      .shape("pyramid")
      .color("action", [
        "#ffa500",
        "#30bd73",
        "#00A0A0",
        "#1890ff",
        "#f57375",
      ])
      .label(
        "action*pv2",
        function (action, pv2) {
          return action + " " + pv2;
        },
        {
          offset: 35,
          labelLine: {
            lineWidth: 1,
            stroke: "rgba(0, 0, 0, 0.15)",
          },
        }
      )
      .tooltip("action*pv*percent", function (action, pv, percent) {
        return {
          name: action,
          percent: parseInt(percent * 100) + "%",
          pv: pv,
        };
      });
    data.forEach(function (obj) {
      // 中间标签文本
      chart.guide().text({
        top: true,
        position: {
          action: obj.action,
          percent: "median",
        },
        // content: parseInt(obj.percent * 100) + '%', // 显示的文本内容
        style: {
          fill: "#fff",
          fontSize: "12",
          textAlign: "center",
          shadowBlur: 2,
          shadowColor: "rgba(0, 0, 0, .45)",
        },
      });
    });
    chart.render();
  };
  render () {
    const { operInfo = {}, userInfo = {} } = this.props;
    const quanxian = String(userInfo.brokerType || userInfo.job || "");
    console.log(quanxian, userInfo, this.props, 'quanxianquanxianquanxianquanxian');
    return (
      <div style={{ padding: "20px 0" }}>
        <Row type="flex" style={{
          position: "relative", display: 'flex',
          justifyContent: 'space-between'
        }}>
          <span>
            <Radio.Group
              value={this.state.seltype}
              onChange={(e) => {
                let dateFrom = "",
                  dateEnd = "",
                  dateStr = "";
                if (e.target.value == "1") {
                  dateFrom = Filter.timetrans(
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth(),
                      1
                    ),
                    "day"
                  );
                  dateEnd = Filter.timetrans(
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth() + 1,
                      0
                    ),
                    "day"
                  );
                  dateStr =
                    new Date().getFullYear() +
                    "-" +
                    (new Date().getMonth() + 1) +
                    "月";
                } else if (e.target.value == "2") {
                  dateFrom = this.jidudate()[0];
                  dateEnd = this.jidudate()[1];
                  dateStr = this.jidudate()[2];
                } else if (e.target.value == "3") {
                  dateFrom = Filter.timetrans(
                    new Date(new Date().getFullYear(), 0, 1),
                    "day"
                  );
                  dateEnd = Filter.timetrans(
                    new Date(
                      new Date().getFullYear() + 1,
                      0,
                      0
                    ),
                    "day"
                  );
                  dateStr = new Date().getFullYear() + "年";
                } else if (e.target.value == "4") {
                  dateFrom = this.state.dateFrom_f;
                  dateEnd = this.state.dateEnd_f;
                  dateStr =
                    this.state.dateFrom_f ==
                      this.state.dateEnd_f
                      ? this.state.dateFrom_f
                      : this.state.dateFrom_f +
                      " ~ " +
                      this.state.dateEnd_f;
                }
                this.setState(
                  {
                    seltype: e.target.value,
                    dateFrom,
                    dateEnd,
                    dateStr,
                  },
                  this.refresh
                );
              }}
            >
              <Radio.Button value="1">本月</Radio.Button>
              <Radio.Button value="2">本季度</Radio.Button>
              <Radio.Button value="3">本年</Radio.Button>
              <Radio.Button value="4">自定义</Radio.Button>
            </Radio.Group>
            &emsp;
            <RangePicker
              style={
                this.state.seltype == "4"
                  ? { width: 250 }
                  : { display: "none" }
              }
              disabledDate={(item) =>
                item && item < moment("20150101", "YYYYMMDD")
              }
              onChange={(date, dateString) => {
                console.log(date, dateString);
                this.setState(
                  {
                    dateFrom_f: dateString[0],
                    dateEnd_f: dateString[1],
                    dateFrom: dateString[0],
                    dateEnd: dateString[1],
                    dateStr:
                      dateString[0] == dateString[1]
                        ? dateString[0]
                        : dateString[0] +
                        " ~ " +
                        dateString[1],
                  },
                  this.refresh
                );
              }}
              value={
                this.state.dateFrom
                  ? [
                    moment(this.state.dateFrom, "YYYY-MM-DD"),
                    moment(this.state.dateEnd, "YYYY-MM-DD"),
                  ]
                  : undefined
              }
            />
          </span>
          <span>
            数据范围：
            <Select
              disabled={quanxian == 'groupLeader'}
              defaultValue="lucy"
              value={this.state.queryRange}
              style={{ width: 120 }}
              onChange={e => {
                this.setState(
                  {
                    queryRange: e,
                  },
                  this.getData
                );
              }}
            >
              {["4", "5", "6", "senior"].includes(quanxian) && <Option value="all">全部</Option>}
              {["director", "groupLeader"].includes(quanxian) && <Option value="depart">部门</Option>}
              <Option value="self">本人</Option>
            </Select>
          </span>
        </Row>
        <div style={{ paddingTop: "15px" }}>
          <div style={{ margin: "0 auto", maxWidth: "700px" }}>
            <div id="Chartid5"></div>
          </div>
        </div>
      </div>
    );
  }
}
let Funnel = connect(state => {
  return state;
})(FunnelM);
export { Funnel };
