import React, { Component } from "react";
import {
  Row,
  Breadcrumb,
  Button,
  Form,
  message,
  Modal,
  Input,
  Select,
  Tabs,
  Col,
  Radio,
  Popover,
  Table,
  Popconfirm,
  Upload,
} from "antd";
import { popoverTable } from "../../common";
import Filter from "../../common/filter";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Ossupload } from "../../common/upLoad";
import "./acustomerManage.scss";
import { LoadingOutlined, UploadOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Axios } from "../../axios";
import reqwest from "reqwest";
import { FlieList } from "../../common";
const { TabPane } = Tabs;

const { Option } = Select;
const { TextArea } = Input;
let setPageData = {},
  sendBody = {},
  Timer;
let TabPaneData = {
  3: {
    url: "https://winhc.oss-cn-shanghai.aliyuncs.com/crm/0fileTemplate/%E6%9F%A5%E8%AF%A2%E8%AF%89%E8%AE%BC%E6%95%B0%E9%87%8F%E6%A8%A1%E6%9D%BF.xlsx",
    name: '发案企业名单精筛',
    name1: ['导入：批量企业名单', '导出：批量企业名单+诉讼案件数+执行案件数+某一被执行人名称+案由'],
    name2: ['批量企业名单通常只能定性是否有过案件，但无法定量精确了解每家企业的诉讼和执行案件的数量。所以，对导出结果的案件数量进行筛选或排序处理，可更好完成精准营销。', '与客户电话沟通时，在话术中使用被执行人名称和案由，会更容易取得客户信任和兴趣，保障电话起始即能顺畅沟通，极大提升有效通话率。'],
  },
  1: {
    url: "https://winhc.oss-cn-shanghai.aliyuncs.com/crm/0fileTemplate/%E9%A2%84%E7%BA%A6%E6%88%90%E5%8A%9F%E5%90%8D%E5%8D%95%E6%A8%A1%E6%9D%BF.xlsx",
    name: "预约成功名单导入",
    name1: "将预约成功的客户名单，按模板要求导入系统，在客户查询中可查看到导入的客户名单。",
  },
  5: {
    url: "https://winhc.oss-cn-shanghai.aliyuncs.com/crm/0fileTemplate/%E6%89%B9%E9%87%8F%E6%A1%88%E4%BB%B6%E9%A3%8E%E6%8E%A7%E9%A2%84%E6%A3%80%E6%A8%A1%E6%9D%BF.xlsx",
    name: '批量案源质量筛查',
    name1: ['导入：批量案件名单（包括：原告名称、被告名称、金额）', '导出：批量案件名单+案件质量等级+案件质量评分'],
    name2: ['当客户提供批量案源时，需要对案源质量进行快速评判，高效筛选出优质案件优先对接与处置，同时快速高效的反馈对客户体验助力极大，增强客户信任。', '通过大数据或其他渠道获得的批量案源线索，高效进行案件质量筛查，可以避免消耗人力物力才发现是劣质案件浪费资源。第一时间对接优质案件，快人一步！'],
  },
  6: {
    url: "https://winhc.oss-cn-shanghai.aliyuncs.com/crm/0fileTemplate/%E4%BC%81%E4%B8%9A%E6%99%BA%E8%83%BD%E9%A3%8E%E6%8E%A7%E9%A2%84%E6%A3%80%E6%A8%A1%E6%9D%BF.xlsx",
    name: '企业批量债权精筛',
    name1: ['导入：批量企业名称', '导出：原告名称+被告名称+标的额+案件质量等级+案件质量评分'],
    name2: ['当早期接触到某些企业客户，对方尚未提供批量案源时，你可以快速整理出它的公开债权信息以及案源质量分析，高效筛选出优质案件优先对接与处置，同时快速高效的反馈对客户体验助力极大，增强客户信任。', '对于已签约在处置的批量案件，你可以快速整理被告/被执行人的对外第三人债权，并同时筛查债权质量，高效掌握财产线索助力案件回款！'],
  },
};
class ListDataManagement extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      tData: [],
      loading: false,
      totalNum: 0,
      currentPage: window.routerCache.ListDataManagement.currentPage || 1,
      fileSign: window.routerCache.ListDataManagement.fileSign || "3",
      modalType: "3", // 默认3
      fileList: [],
      uploading: false,
      FlieList: [],
      uploadVisible1: false
    };
    sendBody = window.routerCache.ListDataManagement.sendBody || {};
    setPageData = {};
  }

  // 获取表格数据
  refreshTableByData = (data, totalNum, currentPage) => {
    this.setState(
      { tData: data, totalNum: totalNum, currentPage: currentPage },
      this.polling
    );
  };

  // 获取表格数据
  refreshTable = async () => {
    try {
      this.setState({
        loading: true,
      });

      let send = sendBody;
      // send.workType = window.routerCache.ListDataManagement.send_workType || '';
      send.pageNum = this.state.currentPage + "";
      send.pageSize = "10";
      send.fileSign = this.state.fileSign;
      let res = await Axios("get", "/crmbatch/page", send);
      this.setState(
        {
          loading: false,
          tData: res.data.dataList,
          totalNum: res.data.totalNum,
        },
        this.polling
      );
      setPageData[this.state.currentPage + ""] = res.data.dataList;
    } catch (e) {
      console.log(e);
      this.setState({ loading: false });
    }
  };

  handleTableChange = async (pagination) => {
    this.setState({ loading: true });
    if (Object.keys(setPageData).includes(pagination.current + "")) {
      this.setState({
        loading: false,
        tData: setPageData[pagination.current + ""],
        currentPage: parseInt(pagination.current),
      });
    } else {
      try {
        let send = sendBody;
        send.pageNum = pagination.current + "";
        send.pageSize = "10";
        send.fileSign = this.state.fileSign;
        // send.workType = window.routerCache.ListDataManagement.send_workType || '';
        let res = await Axios("get", "/crmbatch/page", send);
        this.setState(
          {
            loading: false,
            tData: res.data.dataList,
            totalNum: res.data.totalNum,
            currentPage: pagination.current,
          },
          this.polling
        );
        setPageData[pagination.current + ""] = res.data.dataList;
      } catch (err) {
        message.error(err.message);
        this.setState({ loading: false });
      }
    }
  };

  // 组件渲染后获取外界数据(GET)
  async componentDidMount () {
    console.log("个人用户信息数据====", this.props.operInfo.operInfo);
    // 储存全局数据，
    if (window.routerCache.CompanyCheck.bulkImport) {
      await this.setState({
        fileSign: "5",
        uploadVisible: true,
        modalType: "5",
      });
    }
    this.refreshTable();
    if (window.winhcFn.redirectRoute) {
      window.winhcFn.redirectRoute(); //调用左侧切换列表。
    }
  }

  componentWillUnmount () {
    window.routerCache.ListDataManagement.currentPage =
      this.state.currentPage;
    window.routerCache.ListDataManagement.fileSign = this.state.fileSign;
    window.routerCache.ListDataManagement.sendBody = sendBody;
    window.routerCache.CompanyCheck.bulkImport = false;
  }

  tabChange = async (e) => {
    await this.setState({
      fileSign: e,
      currentPage: 1,
    });
    this.refreshTable();
  };

  moreUpload = async () => {
    let memo = this.formRef.current.getFieldsValue().memo;
    let fileSign = this.state.modalType; // 上传的类型即为弹框类型。
    if (!this.state.fileList.length) {
      message.warning("请选择需要导入文件");
      return;
    }

    let send = {
      fileSign: fileSign,
      memo: memo,
      uploadUrl: this.state.fileList[0].docUrl,
    };

    try {
      let res = await Axios("post", "/crmbatch", send);
      console.log("上传文件==", res);
      if (res.code === "200") {
        message.success(res.message);
        await this.setState({
          uploadVisible: false,
          fileSign: this.state.modalType,
          currentPage: 1,
          fileList: [], // 成功后清楚上传文件。
        });
        this.formRef.current.resetFields();
        this.refreshTable();
      } else {
        message.error(res.message);
      }
    } catch (error) {
      console.log(error);
    }

  };

  polling = () => {
    let gonext = false;
    this.state.tData.forEach((item) => {
      if (item.status == "1") {
        gonext = true;
        return;
      }
    });
    if (!gonext) {
      return;
    }
    if (Timer) {
      clearTimeout(Timer);
    }
    setTimeout(async () => {
      try {
        let send = sendBody;
        send.pageNum = this.state.currentPage + "";
        send.pageSize = "10";
        send.fileSign = this.state.fileSign;
        let res = await Axios("get", "/crmbatch/page", send);
        if (this.state.currentPage == sendBody.pageNum) {
          this.setState(
            {
              tData: res.data.dataList,
              totalNum: res.data.totalNum,
            },
            this.polling
          );
          setPageData[this.state.currentPage + ""] =
            res.data.dataList;
        }
      } catch (err) {
        console.log(err);
      }
    }, 1000);
  };
  DeleteCrmbatch = async (record) => {
    try {
      let res = await Axios("delete", "/crmbatch/" + record.batchNo, { batchNo: record.batchNo });
      if (res.errorCode == '200' || res.isSuccess == 'T') {
        this.refreshTable()
        message.success(res.errorMsg)
      } else { message.error(res.errorMsg) }
    } catch (err) {
      // message.error(err.message)
    }
  };
  render () {
    const { uploading, fileList, fileSign } = this.state;
    const props = {
      accept: ".xls,.xlsx",
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          //   fileList: [...state.fileList, file],
          fileList: [file],
        }));
        return false;
      },
      fileList,
    };
    let columns = [
      {
        title: "导入批次号",
        dataIndex: "batchNo",
        key: "1",
        fixed: 'left',
        // width: 120,
      },
      {
        title: "导入记录条数",
        dataIndex: "uploadNum",
        // width: 130,
        key: "3",
      },
      {
        title: "成功条数",
        dataIndex: "successNum",
        // width: 100,
        key: "4",
      },
      {
        title: "失败条数",
        dataIndex: "failNum",
        // width: 100,
        key: "5",
      },
      {
        title: "导入文件名称",
        dataIndex: "uploadUrl",
        key: "6",
        // width: 260,
        render: (text, record, index) => {
          return text ? (
            <span>
              <PaperClipOutlined />
              <a
                target="_blank"
                href={window.winhc_oss_url + text}
              >
                {Filter.checkOSSUrl(text)}
              </a>
            </span>
          ) : (
            ""
          );
        },
      },
      {
        title: "成功名单",
        dataIndex: "successUrl",
        key: "7",
        // width: 100,
        render: (text, record, index) => {
          return !text ? (
            ""
          ) : (
            <span>
              <a
                target="_blank"
                href={window.winhc_oss_url + text}
              >
                {"下载"}
              </a>
            </span>
          );
        },
      },
      {
        title: "失败名单",
        dataIndex: "failUrl",
        key: "8",
        // width: 100,
        render: (text, record, index) => {
          return !text ? (
            ""
          ) : (
            <span>
              <a
                target="_blank"
                href={window.winhc_oss_url + text}
              >
                {"下载"}
              </a>
            </span>
          );
        },
      },
      {
        title: "备注",
        dataIndex: "memo",
        key: "9",
        render: (text) => text ? text == '名单导入失败，请使用正确的模板导入数据' ? <span style={{ color: 'red' }}>{text}</span> : text : '-'
      },
      {
        title: "状态",
        dataIndex: "status",
        key: "12",
        fixed: 'right',
        // width: 100,
        render: (text, record, index) => (
          <span>
            {text == 1 ? (
              <span>
                <LoadingOutlined style={{ color: "#108ee9" }} />
                <span>
                  {" "}
                  <a>处理中</a>
                </span>
              </span>
            ) : text == 2 ? (
              "已完成"
            ) : (
              ""
            )}
          </span>
        ),
      },
      {
        title: "操作",
        dataIndex: "operate",
        fixed: "right",
        render: (text, record) => (record.status == 1 ?
          <Popconfirm title="确定要删除吗？" onConfirm={() => this.DeleteCrmbatch(record)} okText="确定" cancelText="取消" >
            <a>删除</a>
          </Popconfirm> : '删除'
        ),
      },
    ];

    let columns2 = [
      {
        title: "导入批次号",
        dataIndex: "batchNo",
        key: "1",
        fixed: 'left',
        // width: 120,
      },
      {
        title: "导入记录条数",
        dataIndex: "uploadNum",
        // width: 120,
        key: "3",
      },
      {
        title: "成功条数",
        dataIndex: "successNum",
        // width: 100,
        key: "4",
      },
      {
        title: "失败条数",
        dataIndex: "failNum",
        // width: 100,
        key: "5",
      },
      {
        title: "导入文件名称",
        dataIndex: "uploadUrl",
        key: "6",
        render: (text, record, index) => {
          return text ? (
            <span>
              <PaperClipOutlined />
              <a
                target="_blank"
                href={window.winhc_oss_url + text}
              >
                {Filter.checkOSSUrl(text)}
              </a>
            </span>
          ) : (
            ""
          );
        },
      },
      {
        title: "成功名单",
        dataIndex: "successUrl",
        key: "7",
        // width: 100,
        render: (text, record, index) => {
          return !text ? (
            ""
          ) : (
            <span>
              <a
                target="_blank"
                href={window.winhc_oss_url + text}
              >
                {"下载"}
              </a>
            </span>
          );
        },
      },
      {
        title: "失败名单",
        dataIndex: "failUrl",
        key: "8",
        // width: 100,
        render: (text, record, index) => {
          return !text ? (
            ""
          ) : (
            <span>
              <a
                target="_blank"
                href={window.winhc_oss_url + text}
              >
                {"下载"}
              </a>
            </span>
          );
        },
      },
      popoverTable(12, {
        title: "备注",
        dataIndex: "memo",
        key: "9",
        render: (text) => {
          if (text && text != "undefined") {
            return <span>{text}</span>;
          }
        },
      }),
      {
        title: "状态",
        dataIndex: "status",
        key: "12",
        // fixed: 'right',
        // width: 100,
        render: (text, record, index) => (
          <span>
            {text == 1 ? (
              <span>

                <LoadingOutlined style={{ color: "#108ee9" }} />
                <span>
                  {" "}
                  <a>处理中</a>
                </span>
              </span>
            ) : text == 2 ? (
              "已完成"
            ) : (
              ""
            )}
          </span>
        ),
      },
      {
        title: "操作",
        dataIndex: "status",
        key: "13",
        fixed: 'right',
        // width: 70,
        render: (text, record, index) => {
          let url = ''
          if (this.state.fileSign == "5" || this.state.fileSign == "6") {
            url = "/windControlDetails/"
          } else if (this.state.fileSign == "4") {
            url = "/windControlDetailsOld/"
          }
          return <span>{url ? <Link to={url + record.batchNo} >查看</Link> : '--'}&nbsp;&nbsp;|&nbsp;&nbsp; {record.status == 1 ?
            <Popconfirm title="确定要删除吗？" onConfirm={() => this.DeleteCrmbatch(record)} okText="确定" cancelText="取消" >
              <a>删除</a>
            </Popconfirm> : '删除'
          }   </span>
        },
      },
    ];

    return (
      <div className="pageContainer">
        <Row className="headNav" style={{ paddingBottom: "0" }}>
          <Col span={24}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>营销获客</Breadcrumb.Item>
              <Breadcrumb.Item>名单资料管理</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={24} className="title">
            <span className="name">名单资料管理</span>
          </Col>
          <Col span={24} >
            <Tabs activeKey={fileSign} onChange={this.tabChange}>
              <TabPane tab="发案企业名单精筛" key="3" />
              {/* <TabPane tab="预约成功名单记录" key="1" /> */}
              <TabPane tab="批量案源质量筛查" key="5" />
              <TabPane tab="企业批量债权精筛" key="6" />
            </Tabs>
          </Col>
        </Row>
        <div className="scrollContainer">
          <div style={{ padding: '16px 24px', background: '#FFFFFF', marginBottom: '16px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }} >
              <div style={{ lineHeight: '22px', color: '#9D9D9D' }}>
                场景使用说明<br />
                {TabPaneData[fileSign].name1[0]}<br />
                {TabPaneData[fileSign].name1[1]}
              </div>
              <div>
                <Button
                  style={{
                    marginRight: "16px",
                  }}
                >
                  <a href={TabPaneData[fileSign].url}>
                    下载模板
                  </a>
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    this.setState({
                      uploadVisible: true,
                      modalType: fileSign,
                    }, () => {
                      this.setState({ uploadVisible1: true })
                    });
                  }}
                >
                  导入数据
                </Button>
                <p style={{ color: '#ED4033' }}>*每次上传客户数据不超过500条*</p>
              </div></div>
            {TabPaneData[fileSign].name2 && <div style={{ background: '#FAFAFA', borderRadius: '2px', padding: '8px', marginTop: '8px', color: '#FE7431', lineHeight: '30px' }}>
              <span style={{ background: '#FAF0EB', padding: '4px 6px', }}>价值1</span>&emsp;{TabPaneData[fileSign].name2[0]}<br />
              <span style={{ background: '#FAF0EB', padding: '4px 6px', }}>价值2</span>&emsp;{TabPaneData[fileSign].name2[1]}
            </div>}
          </div>
          <div className="scrollMain ListDataManagement">
            <div
              style={{
                fontSize: "16px",
                fontFamily: "PingFangSC-Medium, PingFang SC",
                fontWeight: "600",
                color: "rgba(0,0,0,0.9)",
                padding: "24px 0 16px",
              }}
            >
              名单导入资料列表
            </div>

            <Table
              scroll={{ x: 'max-content' }}
              dataSource={this.state.tData}
              columns={
                this.state.fileSign == "4" ||
                  this.state.fileSign == "5" ||
                  this.state.fileSign == "6"
                  ? columns2
                  : columns
              }
              size="middle"
              rowKey={(record) => record.batchNo}
              pagination={{
                size: "large",
                showQuickJumper: true,
                pageSize: 10,
                total: parseInt(this.state.totalNum),
                current: Number(this.state.currentPage),
                showTotal: (total) => {
                  return "共 " + total + " 条记录 ";
                },
              }}
              loading={this.state.loading}
              onChange={this.handleTableChange}
            />
            <Modal
              title={
                this.state.modalType == "3"
                  ? "查询诉讼数量"
                  : this.state.modalType == "1"
                    ? "预约成功名单导入"
                    : this.state.modalType == "5"
                      ? "批量案件风控预检"
                      : this.state.modalType == "6"
                        ? "企业智能风控预检"
                        : ""
              }
              open={this.state.uploadVisible}
              onOk={this.moreUpload}
              confirmLoading={this.state.confirmLoading}
              onCancel={() => {
                this.setState({ uploadVisible: false });
              }}
              okText="立即导入"
            >
              <Form
                ref={this.formRef}
                layout="horizontal"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
              >
                <Form.Item label="上传文件">
                  {this.state.uploadVisible1 ? <Ossupload
                    callbackData={{ test: "123" }}
                    callback={async (
                      filename,
                      file,
                      info,
                      callbackData
                    ) => {
                      if (info.status == 200) {
                        try {
                          this.setState({
                            fileList: [
                              {
                                docUrl:
                                  window.winhc_oss_url +
                                  filename,
                              },
                            ],
                          });
                        } catch (err) {
                          console.log("err", err);
                          message.error(err.message);
                        }
                      }
                    }}
                    beforeUpload={(file, up) => {
                      console.log(file, up);
                      if (
                        file.size >
                        1024 * 1024 * 10 * 30
                      ) {
                        message.error(
                          "请选择小于300MB文件！"
                        );
                        return false;
                      }
                    }}
                  >
                    <Button>
                      <UploadOutlined />
                      导入Excel
                    </Button>
                  </Ossupload> : ''}
                  <div>
                    <FlieList
                      fileList={this.state.fileList}
                      QuanXian={false}
                    />
                  </div>
                </Form.Item>
                <Form.Item label="备注："
                  name="memo"
                >
                  <TextArea
                    rows={2}
                    placeholder="请填写备注信息"
                  />
                </Form.Item>
              </Form>
            </Modal>
          </div>
        </div>
        <style>{`
                    .ant-tabs-bar.ant-tabs-top-bar{
                        margin:0;
                        margin-top:'18px';
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 500;
                        color: rgba(0,0,0,0.9);
                    }
                `}</style>
      </div>
    );
  }
}

export default connect((state) => {
  return state;
})(ListDataManagement);
