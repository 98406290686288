/**
 * Created by Administrator on 2016/7/2.
 */
// 初始化状态
let setbaseData = {
    docKinds:[],
    caseTypes:[],
    clueTypes:[],
    caseStages:[],
    fundUsers:[],
    departs:{
        defaultDepartList:[],
        treeList:[],
        treeListName:[],
        levelListDisabled:[],
    },
    projectType:[],
    companyInfo:[],
    industryInfo:[],
    channelInfos:[],
    operTree: {
        gettreeData:()=>{
          return []
        },
        treeData:[],
        getOperInfo:()=>{
            return false
        }
    },
    methods:{
        getDocKindsByType:()=>{
            return "";
        }
    }
}
let getChildren=(deptId,list)=>{
    let _list=[];
    list.forEach((item)=>{
        if(item.parentId==deptId){
            _list.push(item)
        }
    });
    return _list;
}

//判断某个部门ID在不在操作员列表中
let exitDeptId=(deptId,list)=>{
    let flag = false;
    for(let i=0;i<list.length;i++){
        if(deptId == list[i].deptId){
            flag = true;
        }
    }
    return flag;
}

//判断array中有没有level
let exitLevel=(num,list)=>{
     let flag = true;
    for(let i=0;i<=num;i++){
        for(let j=0;j<list.length;j++){
            if(i == list[j].level){
                flag = true;
                break;
            }else{
                flag = false
            }
        }
        if(!flag){
            break;
        }
    }  
    return flag;
}

//获取部门列表
let getDepartList = (action)=>{
    // console.log("action==",action)
    let levelList={},levelListName={},levelListDisabled={},data=action.departs,level=-1,treeList=[],treeListName=[],treeListDisabled=[];
            let defaultDepartList = data;
            data.forEach((item)=>{
                level=item.level*1>level?item.level*1:level
            });
            for(let i=0;i<=level;i++){
                levelList['level'+i]=[];
                levelListName['level'+i]=[];
                levelListDisabled['level'+i]=[];
                data.forEach((item)=>{
                    if(item.level!==null&&item.level*1===i){
                        levelList['level'+i].push({
                            label:item.deptName,
                            key:item.deptId+'',
                            value:item.deptId+'',
                            parentId:item.parentId,
                            children:[],
                        })
                        levelListName['level'+i].push({
                            label:item.deptName,
                            key:item.deptId+'-'+item.deptName,
                            value:item.deptId+'-'+item.deptName,
                            parentId:item.parentId,
                            children:[],
                        })
                        levelListDisabled['level'+i].push({
                            label:item.deptName,
                            key:item.deptId+'',
                            value:item.deptId+'',
                            parentId:item.parentId,
                            children:[],
                            disabled:true,
                        })
                    }
                });
            }
            for(let i=level-1;i>=0;i--){
                for(let j=0;j<levelList['level'+i].length;j++){
                    levelList['level'+i][j].children=getChildren(levelList['level'+i][j].key,levelList['level'+(i+1)])
                }
                for(let j=0;j<levelListName['level'+i].length;j++){
                    levelListName['level'+i][j].children=getChildren(levelListName['level'+i][j].key.split('-')[0],levelListName['level'+(i+1)])
                }
                for(let j=0;j<levelListDisabled['level'+i].length;j++){
                    levelListDisabled['level'+i][j].children=getChildren(levelListDisabled['level'+i][j].key,levelListDisabled['level'+(i+1)])
                }
            }
            if(levelList.level0){
                treeList=levelList.level0;
            }
            if(levelListDisabled.level0){
                treeListDisabled=levelListDisabled.level0;
            }
            if(levelListName.level0){
                treeListName=levelListName.level0;
            }
            console.log(treeList);
            function getdepartName(id) {
                let str='';
                for(let i=0;i<data.length;i++){
                    if(data[i].deptId==id){
                        str=data[i].deptName;
                        break
                    }
                }
                return str
            }
            let returnObj = {defaultDepartList,treeList,treeListName,treeListDisabled,getdepartName}
        return returnObj
}

//生成操作员树
let getTreeData = (action) =>{
    // 操作员信息发生变化 需要重新 执行
    window.globalTreeData={}; //清空缓存
    let levels={},departs=action.operTree.departs,levelNow=-1,treeData;
    departs.forEach((item)=>{
        levelNow=item.level*1>levelNow?item.level*1:levelNow
    });
    for(let i=0;i<=levelNow;i++){
        levels['level'+i]=[];
        departs.forEach((item)=>{
            if(item.level!==null&&item.level*1===i){
                levels['level'+i].push({
                    label:item.deptName,
                    key:item.deptId+'deptNode',
                    value:item.deptId+'deptNode',//为了避免与子id重复
                    parentId:item.parentId,
                    children:[],
                    selectable:false
                })
            }
        });
    }
    for(let i=levelNow-1;i>=0;i--){
        for(let j=0;j<levels['level'+i].length;j++){
            levels['level'+i][j].children=getChildren(levels['level'+i][j].key.replace('deptNode',''),levels['level'+(i+1)])
        }
    }
    if(levels.level0){
        treeData=levels.level0;
    }

    let setTreeData=(_item,list)=>{
        let _list=JSON.parse(JSON.stringify(list));
        for(let i=0;i<_list.length;i++){
            if(_item.deptId==_list[i].key.replace('deptNode','')&&!_list[i].isOper){
                _list[i].children.push({
                    label: _item.realName,
                    value: _item.operId+'',
                    key: _item.operId+''+_item.realName,
                    isOper:true,
                });
                break;
            }else if(_list[i].children){
                if(_list[i].children.length>0){
                    _list[i].children=setTreeData(_item,_list[i].children)
                }
            }
        }
        return _list
    };
    action.operTree.opers.forEach((item)=>{
        if(item.deptId){
            treeData=setTreeData(item,treeData)
        }
    });
    return treeData;
}

// 根据操作员标签条件生成人员树
let createTreeDataByOper= (type,value,action)=> {
    //获取缓存数据
    let globalCacheData = window.globalTreeData;
    let str=''
    value.forEach((e)=>{
        str = str+'_'+e;
    });
    
    if(type==='tags'){
        if(globalCacheData[str+""]){
            //返回数据
            return globalCacheData[str+""];
        }
    }

    let type_levels={},type_departs=action.operTree.departs,type_levelNow=-1,type_treeData=[];
    type_departs.forEach((item)=>{
        type_levelNow=item.level*1>type_levelNow?item.level*1:type_levelNow
    });

    let type_opers=[];
    if(type==='tags'){
        action.operTree.opers.forEach((item)=>{
            let slt=false;
            if(item.tagCodes&&item.tagCodes.length>0){
                item.tagCodes.forEach((item2)=>{
                    value.forEach((item3)=>{
                        if(item3==item2){
                            type_opers.push(item);
                            slt=true;
                            return;
                        }
                    });
                    if(slt){return}
                });
                if(slt){return}
            }
        });
    }
   
     //根据层级过滤标签中不存在的部门
    let tempArray = new Array();
    for(let j=0;j<type_departs.length;j++){
        if(exitDeptId(type_departs[j].deptId,type_opers)){
            tempArray.push(type_departs[j])
        }
    }
    let filterTagsDept = (list)=>{
        for(let i=0;i<list.length;i++){
            for(let j=0;j<type_departs.length;j++){
                if(list[i].parentId == type_departs[j].deptId){
                    //判断列表中是否存在
                    if(!exitDeptId(type_departs[j].deptId,list)){
                        tempArray.push(type_departs[j])
                    }
                }
            }
        }
        
        //判断parentId是否都存在
        let tempLevel = -1;
        tempArray.forEach((item)=>{
            tempLevel=item.level*1>tempLevel?item.level*1:tempLevel
        });
        if(!exitLevel(tempLevel,tempArray)){
            filterTagsDept(tempArray);
        }
        return tempArray       
    }
   
    type_departs = filterTagsDept(tempArray);
  
    for(let i=0;i<=type_levelNow;i++){
        type_levels['level'+i]=[];
        type_departs.forEach((item)=>{
            if(item.level!==null&&item.level*1===i){
                type_levels['level'+i].push({
                    label:item.deptName,
                    key:item.deptId+'deptNode',
                    value:item.deptId+'deptNode',//为了避免与子id重复
                    parentId:item.parentId,
                    children:[],
                    selectable:false
                })
            }
        });
    }

    for(let i=type_levelNow-1;i>=0;i--){
        for(let j=0;j<type_levels['level'+i].length;j++){
            type_levels['level'+i][j].children=getChildren(type_levels['level'+i][j].key.replace('deptNode',''),type_levels['level'+(i+1)])
        }
    }
    if(type_levels.level0){
        type_treeData=type_levels.level0;
    }
    
    let type_setTreeData=(_item,list)=>{
        let _list=JSON.parse(JSON.stringify(list));
        for(let i=0;i<_list.length;i++){
            if(_item.deptId==_list[i].key.replace('deptNode','')&&!_list[i].isOper){
                _list[i].children.push({
                    label: _item.realName,
                    value: _item.operId+'',
                    key: _item.operId+''+_item.realName,
                    isOper:true,
                });
                break;
            }else if(_list[i].children){
                if(_list[i].children.length>0){
                    _list[i].children=type_setTreeData(_item,_list[i].children)
                }
            }
        }
        return _list
    };
    type_opers.forEach((item)=>{
        if(item.deptId){
            type_treeData=type_setTreeData(item,type_treeData)
        }
    });
    // console.log("部门树=",type_treeData)
    globalCacheData[str+""] = type_treeData;//存储缓存数据
    
    return type_treeData
}

export function baseData(state = setbaseData, action) {
    switch (action.type) {
        case 'change_baseData_all':
            let _departs = getDepartList(action);
            //生成部门树
            let _treeData = getTreeData(action);
            //根据操作员类型和标签生成树
            let _gettreeDataFun = (type,value)=>{
                return createTreeDataByOper(type,value,action);
            };
            return {
                ...state,   //三个点是展开符
                docKinds: action.docKinds,
                caseTypes: action.caseTypes,
                clueTypes: action.clueTypes,
                caseStages: action.caseStages,
                fundUsers: action.fundUsers,
                projectType: action.projectType,
                companyInfo: action.companyInfo,
                industryInfo: action.industryInfo,
                channelInfos: action.channelInfos,
                departs: _departs,
                operTree: {
                    gettreeData:_gettreeDataFun,
                    treeData:_treeData,
                    getOperInfo:(oper,type)=>{
                        let operInfo={};
                        action.operTree.opers.forEach((item)=>{
                            if(type=="mobile"){
                              if(item.mobile==oper){
                                    operInfo=item;
                                    return
                                }
                            }else{
                                if(item.operId==oper){
                                    operInfo=item;
                                    return
                                }

                            }
                        });
                        return operInfo
                    }
                },
                methods:{
                    getDocKindsByName:(docKindName)=>{
                       let id = "";
                       for (let index = 0; index < action.docKinds.length; index++) {
                           const element = action.docKinds[index];
                           if(action.docKinds[index].docKindName==docKindName){
                               id = action.docKinds[index].docKindId;
                               break;
                           }
                       }
                        return  id;
                    }
                }



            }
        default:
            return {...state};
    }
}
