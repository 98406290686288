import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Row,
  Col,
  Breadcrumb,
  Form,
  Input,
  TreeSelect,
  Radio,
  DatePicker,
  Select,
  message,
  Modal,
  Alert,
  notification,
} from "antd";
import { Axios } from "../../axios";
import { Link } from "react-router-dom";
import moment from "moment";
import filter from "../../common/filter";
import { advFiledNewList, ImgViewer, popoverTable, Role } from "../../common";
import { connect } from "react-redux";
import CareaSelect from "../../common/CareaSelect";
import { cityByCodeList } from "../../common/cityByCodeList";
const { RangePicker } = DatePicker;
const { Option } = Select;
let timeout, currentValue, loading_exportOrders = false, message_loading = '',
  sendBody = window.routerCache.RecoveriesManage.sendBody || {}, StatuspageNum = 1, MarketpageNum = 1;

const RecoveriesManage = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const refreshTable = async (pageNum, pageSize) => {
    try {
      setLoading(true);
      sendBody.pageNum = pageNum || data.pageNum || sendBody.pageNum || 1;
      sendBody.pageSize = pageSize || data.pageSize || sendBody.pageSize || 10;
      sendBody.workType = window.routerCache.RecoveriesManage.workTypeStr || undefined;
      let res = await Axios("post", "/property/batch/return", sendBody);
      if (res.isSuccess == "T" || res.errorCode == "200") {
        setData(res || {});
      } else {
        message.error(res.message);
      }
      setLoading(false);
    } catch (err) {
      // message.error(err.message)
      setLoading(false);
    }
  };
  useEffect(() => {
    refreshTable();
    return () => {
      window.routerCache.RecoveriesManage.sendBody = sendBody;
    };
  }, []);
  const exportOrders = async () => {
    if (!loading_exportOrders) {
      loading_exportOrders = true;
      message_loading = message.loading('生成中', 0);
      delete sendBody.pageNum
      delete sendBody.pageSize
      try {
        let res = await Axios('post', '/property/batch/return/poi', sendBody);
        console.log(res)
        if (res.code == '200') {
          notification.success({ message: res.message })
        } else {
          notification.warning({ message: res.message })
        }
        loading_exportOrders = false;
        message_loading();
        message_loading = '';
      } catch (error) {
        console.log(error);
        loading_exportOrders = false;
        message_loading();
        message_loading = '';
      }
    }
  }
  let columns = [
    {
      title: "批次号",
      dataIndex: "batchNo",
      fixed: "left",
    },
    {
      title: "房号",
      dataIndex: "roomNo",
    },
    {
      title: "姓名",
      dataIndex: "name",
    },
    popoverTable(12, {
      title: "联系电话",
      dataIndex: "mobileNo",
    }),
    {
      title: "欠费年限",
      dataIndex: "oweAmtYear",
    },
    {
      title: "欠费金额（元）",
      dataIndex: "oweAmt",
      render: (text, record) => text || "0",
    },
    {
      title: "回款金额（元）",
      dataIndex: "returnAmt",
      render: (text, record) => text || "0",
    },
    {
      title: "回款时间",
      dataIndex: "returnDateStr",
      render: (text, record) => (text ? text.slice(0, 10) : "-"),
    },
    {
      title: "催收人",
      dataIndex: "collectionOperName",
    },
    {
      title: "物业市场运维",
      dataIndex: "propertyMarketOperName",
    },
    {
      title: "审核状态",
      dataIndex: "processStatus",
      render: (text, record) =>
        text == "1" ? "未审核" : text == "2" ? "审核通过" : "-",
    },
    {
      title: "审核人",
      dataIndex: "reviewerName",
    },
    {
      title: "审核时间",
      dataIndex: "reviewerTime",
    }, {
      title: "案件状态",
      dataIndex: "caseStatus",
      render: (text, record) =>
        text == "1" ? "已撤案" : text == "0" ? "未撤案" : "-",
    },
    {
      title: "操作",
      dataIndex: "caseId",
      fixed: "right",
      render: (text, record) => <Link to={"/RecoveriesManageDetail/" + text}  > 查看 </Link>,
    },
  ];
  columns.map(v => {
    if (!v.render) v.render = text => text || "-";
  });
  return (
    <div className="pageContainer">
      <Row className="headNav">
        <Col span={24}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>物业管理</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={24} className="title">
          <span className="name">回款管理</span>
        </Col>
      </Row>
      <div className="scrollContainer">
        <div className="scrollMain">
          <Search refreshTable={refreshTable} />
          <Row type="flex" justify="space-between" align="middle">
            <div className="tips"></div>
            <Button style={{ marginBottom: 12 }} onClick={exportOrders} >生成报表</Button>
          </Row>
          <div style={{ backgroundColor: "#fff" }}>
            <Table
              scroll={{ x: "max-content" }}
              columns={columns}
              dataSource={data.body || []}
              loading={loading}
              pagination={{
                size: "large",
                showQuickJumper: true,
                pageSize: data.pageSize,
                total: parseInt(data.totalNum),
                current: Number(data.pageNum),
                showTotal: total => {
                  return "共 " + total + " 条记录 ";
                },
              }}
              onChange={({ current, pageSize }) => refreshTable(current, pageSize)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default RecoveriesManage;

const Search = connect(
  state => {
    return state;
  },
  dispatch => ({})
)(({ refreshTable }) => {
  const [form] = Form.useForm();
  const [statusList, setStatusList] = useState([]);
  const [marketList, setMarketList] = useState([]);
  const onFinish = () => {
    form
      .validateFields()
      .then(values => {
        if (values.date && values.date.length) {
          values.startEntrustDate = moment(values.date[0]).format("YYYY-MM-DD");
          values.endEntrustDate = moment(values.date[1]).format("YYYY-MM-DD");
          delete values.date;
        }
        if (values.date1 && values.date1.length) {
          values.startReturnDate = moment(values.date1[0]).format("YYYY-MM-DD");
          values.endReturnDate = moment(values.date1[1]).format("YYYY-MM-DD");
          delete values.date1;
        }
        sendBody = values;
        refreshTable(1);
        console.log(values, "values");
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };
  const onReset = () => {
    sendBody = {};
    form.resetFields();
    refreshTable(1);
    StatuspageNum = 1
    MarketpageNum = 1
  };
  const clearAway = () => {
    window.routerCache.RecoveriesManage.workTypeStr = "";
    window.routerCache.RecoveriesManage.workType_status = "";
    delete sendBody.workType;
    refreshTable();
    StatuspageNum = 1
    MarketpageNum = 1
  };
  const StatusListFun = (pageNum) => {
    Axios("get", "/brokeruser/page", {
      pageNum: pageNum,
      pageSize: 10,
    }).then((val) => {
      setStatusList(statusList.concat(val.data.dataList || []))
    });
  };
  const MarketListFun = (pageNum) => {
    Axios("get", "/property/batch/marketOper", {
      pageNum: pageNum,
      pageSize: 10,
    }).then((val) => {
      setMarketList(marketList.concat(val.body || []))
    });
  };
  useEffect(() => {
    StatusListFun(1);
    MarketListFun(1);
    form.setFieldsValue({
      ...sendBody,
      date: filter.RangePicker(sendBody, 'startEntrustDate', 'endEntrustDate', 'YYYY-MM-DD'),
      date1: filter.RangePicker(sendBody, 'startReturnDate', 'endReturnDate', 'YYYY-MM-DD'),
    });
  }, []);
  const layout = { labelCol: { span: 6 }, wrapperCol: { span: 18 } };
  return (
    <Form form={form} {...layout} className="ant-form-ant-form-horizontal-flex">
      <Form.Item label="批次ID" name="batchId">
        <Input placeholder="请输入批次ID" />
      </Form.Item>
      <Form.Item label="案件ID" name="caseId">
        <Input placeholder="请输入案件ID" />
      </Form.Item>
      <CareaSelect form={form} label="催收区域" province='collectionProvince' city='collectionCity' />
      <Form.Item label="批次号" name="batchNo">
        <Input placeholder="请输入批次号" />
      </Form.Item>
      <Form.Item label="客户名称" name="customName">
        <Input placeholder="请输入客户名称" />
      </Form.Item>
      <Form.Item label="案件等级" name="caseLevel">
        <Select placeholder="请选择案件等级" allowClear>
          <Option key="A" value="A">A</Option>
          <Option key="B" value="B">B</Option>
          <Option key="C" value="C">C</Option>
          <Option key="D" value="D">D</Option>
        </Select>
      </Form.Item>
      <Form.Item label="销售专员" name="saleBrokerUserId">
        <Select
          placeholder="请选择销售专员"
          allowClear
          onPopupScroll={(e, value) => {
            e.persist();
            const { target } = e;
            if (
              target.scrollTop +
              target.offsetHeight >=
              target.scrollHeight
            ) {
              StatuspageNum = StatuspageNum + 1;
              StatusListFun(StatuspageNum);
            }
          }}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (Array.isArray(option.props.children)
              ? option.props.children.join("")
              : option.props.children
            )
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {statusList.map((v) => <Option key={v.id} value={v.id}>
            {v.userName}
          </Option>)}
        </Select>
      </Form.Item>
      <Form.Item label="物业市场运维" name="propertyMarketOperId">
        <Select
          placeholder="请选择物业市场运维"
          allowClear
          onPopupScroll={(e, value) => {
            e.persist();
            const { target } = e;
            if (
              target.scrollTop +
              target.offsetHeight >=
              target.scrollHeight
            ) {
              MarketpageNum = MarketpageNum + 1;
              MarketListFun(MarketpageNum);
            }
          }}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (Array.isArray(option.props.children)
              ? option.props.children.join("")
              : option.props.children
            )
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {marketList.map((v) => <Option key={v.propertyMarketOperId} value={v.propertyMarketOperId}>
            {v.operName}
          </Option>)}
        </Select>
      </Form.Item>
      <Form.Item label="姓名" name="name">
        <Input placeholder="请输入姓名" />
      </Form.Item>
      <Form.Item label="委案日期" name="date">
        <RangePicker allowClear style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item label="房号" name="roomNo">
        <Input placeholder="请输入房号" />
      </Form.Item>
      <Form.Item label="审核状态" name="processStatus">
        <Select placeholder="请选择审核状态" allowClear>
          <Option key="1">未审核</Option>
          <Option key="2">审核通过</Option>
        </Select>
      </Form.Item>
      <Form.Item label="联系电话" name="mobileNo">
        <Input placeholder="请输入联系电话" />
      </Form.Item>
      <Form.Item label="催收人" name="collectionOperName">
        <Input placeholder="请输入催收人" />
      </Form.Item>
      <Form.Item label="回款日期" name="date1">
        <RangePicker allowClear style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item label="案件状态" name="caseStatus">
        <Select placeholder="请选择案件状态" allowClear>
          <Option key="" value="">全部</Option>
          <Option key="0" value="0">未撤案</Option>
          <Option key="1" value="1">已撤案</Option>
        </Select>
      </Form.Item>
      <Form.Item label={null} />
      <Form.Item wrapperCol={{ offset: 12 }}>
        <Button type="primary" htmlType="submit" onClick={onFinish}>
          查询
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button htmlType="button" onClick={onReset}>
          清空
        </Button>
      </Form.Item>
      <div>
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          style={{
            paddingBottom: window.routerCache.RecoveriesManage.workTypeStr ? "10px" : "40px",
          }}
        >
          {window.routerCache.RecoveriesManage.workTypeStr && (
            <Alert
              message={
                <div>
                  {" "}
                  <span> {window.routerCache.RecoveriesManage.workTypeStr} </span>
                  &nbsp;&nbsp;<a onClick={clearAway}>清空</a>&nbsp;&nbsp;
                </div>
              }
              type="info"
              showIcon
              style={{ marginTop: "12px" }}
            />
          )}
        </Row>
      </div>
    </Form>
  );
});
