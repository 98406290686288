import { Err404, Err500, Err403 } from '../components/errPage/errPage';
import React from 'react';
import {
  HomeOutlined, BarChartOutlined, ProjectOutlined, LineChartOutlined, FileDoneOutlined,
  UserAddOutlined, MoneyCollectOutlined, AuditOutlined, FolderOutlined, TeamOutlined, WarningOutlined, IssuesCloseOutlined, ContainerOutlined
} from '@ant-design/icons';

import App from '../App';
import Login from '../components/login/login.jsx';

import WorkTable from '../components/workTable/workTable';
import CustomerQuery from "../components/customerManage/CustomerQuery"
import CustomerDetails from "../components/customerManage/CustomerDetails"
import OrderQuery from "../components/customerManage/OrderQuery"
import PersonQueryNew from "../components/customerManage/PersonQueryNew"
import PerforIndicators from "../components/performance/perforIndicators"
import OrderDetails from "../components/customerManage/OrderDetails"
import RelevanceOrderQuery from "../components/customerManage/RelevanceOrderQuery"
import RelevanceOrderQueryDetails from "../components/customerManage/RelevanceOrderQueryDetails"
import CompanyCheckDetail from "../components/customerManage/CompanyCheckDetail"
import EnterpriseQuery from "../components/customerManage/enterpriseQuery"
import BusinessAppealManage from "../components/customerManage/BusinessAppealManage"
import FsCaseManage from "../components/customerManage/FsCaseManage"
import FsCaseManageDetail from "../components/customerManage/FsCaseManageDetail"
import LegalCaseManage from "../components/customerManage/LegalCaseManage"
import RecoveriesManage from "../components/customerManage/RecoveriesManage"
import LegalCaseManageDetail from "../components/customerManage/LegalCaseManageDetail"
import RecoveriesManageDetail from "../components/customerManage/RecoveriesManageDetail"

import EmployCompanyManage from "../components/customerManage/EmployCompanyManage"
import SubmitCustomerManage from "../components/customerManage/SubmitCustomerManage"
import EmployCompanyManageDetail from "../components/customerManage/EmployCompanyManageDetail"

import MarketManage from "../components/customerManage/MarketManage"
import MarketManageDetail from "../components/customerManage/MarketManageDetail"
import ThreadManage from "../components/customerManage/ThreadManage"
import ThreadManageDetail from "../components/customerManage/ThreadManageDetail"

import QuarterlyReport from "../components/customerManage/QuarterlyReport"

import DocumentManage from "../components/customerManage/DocumentManage"
import ElectronicContract from "../components/customerManage/ElectronicContract"
import ElectronicContractDetail from "../components/customerManage/ElectronicContractDetail"
import RenewCompanyManage from "../components/customerManage/RenewCompanyManage"
import PayCompanyManage from "../components/customerManage/PayCompanyManage"
import ListDataManagement from "../components/customerManage/ListDataManagement"
import WindControlDetails from "../components/customerManage/WindControlDetails"
import WindControlDetailsOld from "../components/customerManage/WindControlDetailsOld"
import OperatorManage from "../components/customerManage/OperatorManage"
import OperatorManageDetails from "../components/customerManage/OperatorManageDetails"
import PayCompanyManageDetail from "../components/customerManage/PayCompanyManageDetail"
import RenewCompanyManageDetail from "../components/customerManage/RenewCompanyManageDetail"
import CaseManagement from "../components/customerManage/CaseManagement"
import NewOpportunities from "../components/customerManage/NewOpportunities"
import CaseManagementAdd from "../components/customerManage/CaseManagementAdd"
import CaseManagementDetail from "../components/customerManage/CaseManagementDetail"
import System from "../components/customerManage/System"
// { id: 2, name: '居间商' },
// { id: 4, name: '城市代理商' },
// { id: 5, name: '运营商' },
// { id: 6, name: '区县运营商' },
// { id: senior, name: '高管' },
// { id: director, name: '部门主管' },
// { id: groupLeader, name: '部门组长' },
// { id: worker, name: '员工' },

const routes = [
  {
    path: "/login",
    roles: 'all',
    exact: true,
    component: Login,
  },
  {
    path: "/500",
    roles: 'all',
    exact: true,
    component: Err500,
  },
  {
    path: "/403",
    exact: true,
    roles: 'all',
    component: Err403,
  },
  {
    path: "/404",
    roles: 'all',
    exact: true,
    component: Err404,
  },

  {
    path: "/",
    component: App,
    exact: false,
    routes: [{
      path: "/workTable",
      name: '首页',
      roles: 'all',
      icon: <HomeOutlined />,
      component: WorkTable,
      exact: false,
    },
    {
      name: '客户管理',
      roles: 'all',
      icon: <BarChartOutlined />,
      routes: [{
        path: "/PayCompanyManage",
        name: '付费企业客户管理',
        roles: 'all',
        component: PayCompanyManage,
      }, {
        path: "/PayCompanyManageDetail/:id",
        roles: 'all',
        component: PayCompanyManageDetail,
        exact: false,
      }, {
        path: "/RenewCompanyManage",
        name: '续费客户管理',
        roles: 'all',
        component: RenewCompanyManage,
      }, {
        path: "/RenewCompanyManageDetail/:id",
        roles: 'all',
        component: RenewCompanyManageDetail,
        exact: false,
      }, {
        path: "/EmployCompanyManage",
        name: '意向客户管理',
        roles: 'all',
        component: EmployCompanyManage,
      }, {
        path: "/EmployCompanyManageDetail/:id",
        // name: '意向客户详情',
        roles: 'all',
        component: EmployCompanyManageDetail,
        exact: false,
      }, {
        path: "/ThreadManage",
        name: '线索客户管理',
        roles: 'all',
        component: ThreadManage,
      },
      {
        path: "/ThreadManageDetail/:id",
        roles: 'all',
        component: ThreadManageDetail,
        exact: false,
      },]
    },
    {
      name: '订单管理',
      roles: 'all',
      icon: <ProjectOutlined />,
      routes: [
        {
          name: '直客订单管理',
          path: "/OrderQuery",
          roles: 'all',
          component: OrderQuery,
        }, {
          path: "/OrderQueryDetail/:id", // 合作商详情
          component: OrderDetails,
          roles: 'all',
          exact: false,
        },
        {
          path: "/ElectronicContract",
          name: '电子合同',
          roles: 'all',
          component: ElectronicContract,
        }, {
          path: "/ElectronicContractDetail/:id",
          name: '详情',
          roles: 'all',
          component: ElectronicContractDetail,
          exact: false,
          onlyRouter: true,
        }, {
          path: "/QuarterlyReport/:id",
          name: '季度服务报告',
          roles: 'all',
          component: QuarterlyReport,
          exact: false,
          onlyRouter: true,
        },
      ]


    },
    {
      path: "/MarketManage",
      name: '销售对账管理',
      icon: <LineChartOutlined />,
      roles: ['4', '5', '6', 'senior'],
      component: MarketManage,
    },
    {
      path: "/MarketManageDetail/:id",
      roles: ['4', '5', '6', 'senior'],
      component: MarketManageDetail,
      exact: false,
    }, {
      name: '营销获客',
      roles: 'all',
      icon: <UserAddOutlined />,
      routes: [
        {
          path: "/EnterpriseQuery",
          name: '企业精准查询',
          roles: ['4', '5', '6', 'senior', 'director', 'groupLeader'],
          component: EnterpriseQuery,
        }, {
          path: "/staffNameListManage",
          name: '名单资料管理',
          roles: ['4', '5', '6', 'senior', 'director', 'groupLeader'],
          component: ListDataManagement,
        }, {
          path: "/NewOpportunities",
          name: '新增机会',
          roles: 'all',
          component: NewOpportunities,
        }, {
          path: "/windControlDetails/:id",
          name: '详情',
          roles: 'all',
          component: WindControlDetails,
          exact: false,
          onlyRouter: true,
        }, {
          path: "/WindControlDetailsOld/:id",
          name: '详情',
          roles: 'all',
          component: WindControlDetailsOld,
          exact: false,
          onlyRouter: true,
        }, {
          path: "/companyCheckDetail/:id",
          name: '预检详情',
          roles: 'all',
          component: CompanyCheckDetail,
          exact: true,
          onlyRouter: true,
        }

      ]
    },
    {
      path: "/CaseManagement",
      name: '案件管理',
      icon: <MoneyCollectOutlined />,
      roles: 'all',
      component: CaseManagement,
    },
    {
      name: '运营商关联订单',
      path: "/RelevanceOrderQuery",
      icon: <AuditOutlined />,
      roles: ['4', '6', 'senior'],
      component: RelevanceOrderQuery,
    }, {
      path: "/RelevanceOrderQueryDetail/:id", // 合作商详情
      component: RelevanceOrderQueryDetails,
      roles: ['4', '6', 'senior'],
      exact: false,
    }, {
      path: "/FsCaseManage",
      name: '非诉清收',
      roles: 'all',
      icon: <ContainerOutlined />,
      component: FsCaseManage,
      exact: false,
    },
    {
      name: '物业管理',
      roles: 'all',
      icon: <FileDoneOutlined />,
      routes: [{
        path: "/LegalCaseManage",
        name: '案件管理',
        roles: 'all',
        keys: 1,
        component: LegalCaseManage,
      }, {
        path: "/RecoveriesManage",
        name: '回款管理',
        roles: 'all',
        keys: 2,
        component: RecoveriesManage,
      }, {
        path: "/LegalCaseManageDetail/:id",
        roles: 'all',
        component: LegalCaseManageDetail,
        exact: false,
      }, {
        path: "/RecoveriesManageDetail/:id",
        roles: 'all',
        component: RecoveriesManageDetail,
        exact: false,
      }]
    },
    {
      path: "/FsCaseManageDetail/:id",
      roles: 'all',
      component: FsCaseManageDetail,
      exact: false,
    },
    {
      path: "/DocumentManage",
      name: '赢火虫商学院',
      icon: <FolderOutlined />,
      roles: 'all',
      component: DocumentManage,
    },
    {
      name: '成员与部门管理',
      icon: <TeamOutlined />,
      path: "/PersonQuery",
      roles: ['6', '4', '5', 'senior', 'director', 'groupLeader'],
      component: PersonQueryNew,
    },
    {
      path: "/PerforIndicators",
      name: '业务分析',
      roles: 'all',
      icon: <WarningOutlined />,
      component: PerforIndicators,
      exact: false,
    }, {
      path: "/BusinessAppealManage",
      name: '业务申诉管理',
      roles: 'all',
      icon: <IssuesCloseOutlined />,
      component: BusinessAppealManage,
      exact: false,
    },
    {
      path: "/OperatorManageDetail/:id",
      roles: ['4', 'senior'],
      component: OperatorManageDetails,
      exact: false,
    },
    {
      path: "/CaseManagementAdd",
      roles: 'all',
      component: CaseManagementAdd,
      exact: false,
    }, {
      path: "/CaseManagementDetail/:id",
      roles: 'all',
      component: CaseManagementDetail,
      exact: false,
    },
    {
      path: "/system",
      roles: 'all',
      component: System,
      exact: false,
    },
    ]
  },

];

export default routes;
