const SeatchData = [
  {
    name: '企业相关',
    data: [
      {
        name: '成立年限',
        field: 'timeRanges',
        Code: 'timeCode',
        data: [
          {
            model: '2',
            type: '3月内',
          },
          {
            model: '4',
            type: '半年内',
          },
          {
            model: '3',
            type: '1年内',
          },
          {
            model: '5',
            type: '1-3年',
          },
          {
            model: '6',
            type: '3-5年',
          },
          {
            model: '7',
            type: '5-10年',
          },
          {
            model: '8',
            type: '10年以上',
          },
          {
            model: 'ALL',
            type: '自定义',
          },
        ],
      },
      {
        name: '注册资本',
        field: 'regCapitalRanges',
        data: [
          {
            model: '0-100',
            type: '100万以内',
          },
          {
            model: '100-200',
            type: '100-200万',
          },
          {
            model: '200-500',
            type: '200-500万',
          },
          {
            model: '500-1000',
            type: '500-1000万',
          },
          {
            model: '1000-5000',
            type: '1000-5000万',
          },
          {
            model: '5000',
            type: '5000万以上',
          },
          {
            model: 'ALL',
            type: '自定义',
          },
        ],
      },
      {
        name: '实缴资本',
        field: 'actualCapitalRanges',
        data: [
          {
            model: '0-100',
            type: '100万以内',
          },
          {
            model: '100-200',
            type: '100-200万',
          },
          {
            model: '200-500',
            type: '200-500万',
          },
          {
            model: '500-1000',
            type: '500-1000万',
          },
          {
            model: '1000-5000',
            type: '1000-5000万',
          },
          {
            model: '5000',
            type: '5000万以上',
          },
          {
            model: 'ALL',
            type: '自定义',
          },
        ],
      },
      {
        name: '登记状态',
        field: 'regStatusStds',
        data: [
          {
            model: '在业/存续',
            type: '在业/存续',
          },
          {
            model: '清算',
            type: '清算',
          },
          {
            model: '迁入',
            type: '迁入',
          },
          {
            model: '迁出',
            type: '迁出',
          },
          {
            model: '停业',
            type: '停业',
          },
          {
            model: '撤销',
            type: '撤销',
          },
          {
            model: '吊销',
            type: '吊销',
          },
          {
            model: '注销',
            type: '注销',
          },
          {
            model: '歇业',
            type: '歇业',
          },
        ],
      },
      {
        name: '企业类型',
        field: 'companyOrgTypeStds',
        data: [
          {
            model: 'ALL',
            type: "自定义"
          },
          {
            model: '有限责任公司',
            type: '有限责任公司',
          },
          {
            model: '股份有限公司',
            type: '股份有限公司',
          },
          {
            model: '股份合作企业',
            type: '股份合作企业',
          },
          {
            model: '国有企业',
            type: '国有企业',
          },
          {
            model: '集体所有制',
            type: '集体所有制',
          },
          {
            model: '个体工商户',
            type: '个体工商户',
          },
          {
            model: '独资企业',
            type: '独资企业',
          },
          {
            model: '有限合伙',
            type: '有限合伙',
          },
          {
            model: '普通合伙',
            type: '普通合伙',
          },
          {
            model: '外商投资企业',
            type: '外商投资企业',
          },
          {
            model: '港、澳、台商投资企业',
            type: '港、澳、台商投资企业',
          },
          {
            model: '联营企业',
            type: '联营企业',
          },
          {
            model: '农民专业合作社',
            type: '农民专业合作社',
          },
        ],
      },
      {
        name: '组织机构',
        field: 'companyTypes',
        data: [
          {
            model: '1',
            type: '大陆企业',
          },
          {
            model: '2',
            type: '香港企业',
          },
          {
            model: '4',
            type: '律师事务所',
          },
          {
            model: '51',
            type: '社会团体',
          },
          {
            model: '52',
            type: '民办非企业单位',
          },
          {
            model: '53',
            type: '基金会',
          },
          {
            model: '54',
            type: '其他社会组织',
          },
          {
            model: '11',
            type: '机关单位',
          },
          {
            model: '12',
            type: '事业单位',
          }
        ],
      },
      {
        name: '资本类型',
        field: 'regCapitalCurrencies',
        data: [
          {
            model: '人民币',
            type: '人民币',
          },
          {
            model: '美元',
            type: '美元',
          },
          {
            model: '其它',
            type: '其它',
          },
        ],
      },
    ],
  },
  {
    name: '经营状态',
    data: [
      {
        name: '参保人数',
        field: 'socialSecurityStaffNums',
        data: [
          {
            model: '0',
            type: '0人',
          },
          {
            model: '1-4',
            type: '1-4人',
          },
          {
            model: '5-9',
            type: '5-9人',
          },
          {
            model: '10-19',
            type: '10-19人',
          },
          {
            model: '20-99',
            type: '20-99人',
          },
          {
            model: '100-499',
            type: '100-499人',
          },
          {
            model: '500-999',
            type: '500-999人',
          },
          {
            model: '1000-4999',
            type: '1000-4999人',
          },
          {
            model: '5000-9999',
            type: '5000-9999人',
          },
          {
            model: '10000',
            type: '10000人以上',
          },
          {
            model: 'ALL',
            type: '自定义',
          },
        ],
      },
      {
        name: '小微企业',
        Code: 'drop',
        data: [
          {
            model: 'hasMicroCompany',
            type: '小微企业',
          }
        ],
      },
      {
        name: '联系方式',
        Code: 'drop',
        data: [
          {
            model: 'hasPhones',
            type: '联系电话',
          },
          {
            model: 'hasEmail',
            type: '联系邮箱',
          },
        ],
      },
      {
        name: '备案网站',
        Code: 'drop',

        data: [
          {
            model: 'hasIcp',
            type: '备案网站',
          },
        ],
      },
      {
        name: '一般纳税人',
        Code: 'drop',

        data: [
          {
            model: 'taxpayer',
            type: '一般纳税人',
          },
        ],
      },
      {
        name: '融资轮次',
        field: 'financeRounds',
        data: [
          {
            model: '种子轮/天使轮',
            type: '种子轮/天使轮',
          },
          {
            model: 'A轮',
            type: 'A轮',
          },
          {
            model: 'B轮',
            type: 'B轮',
          },
          {
            model: 'C轮',
            type: 'C轮',
          },
          {
            model: 'D轮',
            type: 'D轮',
          },
          {
            model: 'E轮及以上',
            type: 'E轮及以上',
          },
          {
            model: 'IPO',
            type: 'IPO',
          },
          {
            model: '并购',
            type: '并购',
          },
          {
            model: '其他',
            type: '其他',
          },
        ],
      },
      {
        name: '科技型企业',
        field: 'technologyCompanies',
        data: [
          {
            model: '科技型初创企业',
            type: '科技型初创企业',
          },
          {
            model: '独角兽企业',
            type: '独角兽企业',
          },
          {
            model: '瞪羚企业',
            type: '瞪羚企业',
          },
        ],
      },
    ],
  },

  {
    name: '风险信息',

    data: [
      {
        Code: 'drop',
        name: '常见风险',
        data: [
          {
            model: 'hasDishonest',
            type: '失信被执行人',
          },
          {
            model: 'hasBankruptcy',
            type: '破产重整',
          },
          {
            model: 'hasWenshu',
            type: '裁判文书',
          },
          {
            model: 'hasPunishment',
            type: '行政处罚',
          },
          {
            model: 'hasLiquidating',
            type: '清算信息',
          },
          {
            model: 'hasMortgage',
            type: '动产抵押',
          },
          {
            model: 'hasAbnormal',
            type: '经营异常',
          },
          {
            model: 'hasJudicial',
            type: '股权冻结',
          },
          {
            model: 'hasZxr',
            type: '被执行人',
          },
          {
            model: 'hasRestrict',
            type: '限制高消费',
          },
          {
            model: 'hasFinalCase',
            type: '终本案件',
          },
          {
            model: 'hasJudicialCase',
            type: '司法案件',
          },
        ],
      },
    ],
  },
]

const SeatchType = {
  timeRanges: '成立年限',
  regCapitalRanges: '注册资本',
  actualCapitalRanges: '实缴资本',
  regStatusStds: '登记状态',
  companyOrgTypeStds: '企业类型',
  companyTypes: '组织机构',
  regCapitalCurrencies: '资本类型',
  socialSecurityStaffNums: '参保人数',
  hasPhones: '联系电话',
  hasIcp: '联系邮箱',
  taxpayer: '一般纳税人',
  financeRounds: '融资轮次',
  technologyCompanies: '科技型企业',
  hasDishonest: '失信被执行人',
  hasBankruptcy: '破产重整',
  hasWenshu: '裁判文书',
  hasPunishment: '行政处罚',
  hasLiquidating: '清算信息',
  hasMortgage: '动产抵押',
  hasAbnormal: '经营异常',
  hasJudicial: '股权冻结',
  hasRestrict: '限制高消费',
  hasFinalCase: '终本案件',
  hasJudicialCase: '司法案件',
}

export { SeatchData, SeatchType }
