import React from "react";
import { Form, Button, message, Breadcrumb, Row, Col } from "antd";
import { Axios } from "../../axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { LoadingOutlined } from '@ant-design/icons';

let Timer2;
class MainM extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "",
            detailData: {},
        };
    }
    componentDidMount() {
        this.getDetail(this.props.match.params.id);
    }
    componentWillUnmount() {
        if (Timer2) {
            clearTimeout(Timer2);
        }
    }

    getDetail = async (id) => {
        try {
            let res = await Axios(
                "get",
                "/crmbatch/diagnosis/detail/" + id,
                {}
            );
            console.log(res);
            if (Timer2) {
                clearTimeout(Timer2);
            }
            if (res.data.status == "0") {
                this.setState({
                    status: "loading",
                });
            } else if (res.data.status == "1") {
                this.setState({
                    status: "success",
                });
            } else if (res.data.status == "9") {
                this.setState({
                    status: "err",
                });
            }
            this.setState({
                detailData: res.data,
            });
            if (res.data.reportStatus == "2") {
                this.setState({
                    reportLoading: false,
                });
            }
            if (
                res.data.status == "0" ||
                (res.data.status == "1" && res.data.reportStatus == "1")
            ) {
                Timer2 = setTimeout(() => {
                    this.getDetail(id);
                }, 1500);
            }
        } catch (err) {
            console.log(err);
        }
    };
    reCheck = async () => {
        try {
            this.setState({
                confirmLoading: true,
            });
            let res = await Axios("post", "/crmbatch/diagnosis/re", {
                relatedId: this.state.detailData.diagnosisId,
            });
            console.log(res);
            message.success(res.message);
            // this.props.history.goBack()
            this.getDetail(this.state.detailData.diagnosisId);
            this.setState({
                confirmLoading: false,
            });
        } catch (err) {
            console.log(err);
            if (err && err.message) {
                message.error(err.message);
            }
            this.setState({
                confirmLoading: false,
            });
        }
    };
    getReport = async () => {
        if (this.state.reportLoading) {
            return;
        }
        try {
            this.setState({
                reportLoading: true,
            });
            let res = await Axios(
                "get",
                "/diagnosisRecords/ai/risk/" +
                this.state.detailData.diagnosisId,
                {}
            );
            console.log(res);
            this.getDetail(this.state.detailData.diagnosisId);
        } catch (err) {
            console.log(err);
            this.setState({
                reportLoading: false,
            });
        }
    };
    render() {
        const { detailData } = this.state;
        return (
            <div className="pageContainer">
                <Row className="headNav">
                    <Col span={24}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>营销获客</Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to="/staffNameListManage">
                                    名单资料管理
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link onClick={() => window.history.back(-1)}>
                                    风控预检
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>风控预检详情</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={24} className="title">
                        <span className="name">风控预检详情</span>
                    </Col>
                </Row>
                <div className="scrollContainer">
                    <div
                        className="scrollMain"
                        style={{
                            paddingTop: "20px",
                            display: "flex",
                            minHeight: "100%",
                        }}
                    >
                        <div style={{ width: "50%", paddingRight: "10%" }}>
                            <Form
                                layout="horizontal"
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 18 }}
                            >
                                <Form.Item
                                    style={{ marginBottom: "10px" }}
                                    label="ID："
                                >
                                    {detailData.diagnosisId}
                                </Form.Item>
                                <Form.Item
                                    style={{ marginBottom: "10px" }}
                                    label="原告："
                                >
                                    {detailData.creditorName}
                                </Form.Item>
                                {detailData.debtorDTOList &&
                                    detailData.debtorDTOList.map((item) => {
                                        if (item.litigantType == "1") {
                                            return (
                                                <Form.Item
                                                    style={{
                                                        marginBottom: "10px",
                                                    }}
                                                    key={item.litigantName}
                                                    label="被告："
                                                    required={true}
                                                >
                                                    {item.litigantName}
                                                </Form.Item>
                                            );
                                        } else {
                                            return (
                                                <div key={item.litigantName}>
                                                    <Form.Item
                                                        style={{
                                                            marginBottom:
                                                                "10px",
                                                        }}
                                                        label="被告："
                                                        required={true}
                                                    >
                                                        {item.litigantName}(
                                                        {item.idNo})
                                                    </Form.Item>
                                                    <Form.Item
                                                        style={{
                                                            marginBottom:
                                                                "10px",
                                                        }}
                                                        label=" "
                                                        colon={false}
                                                    >
                                                        关联企业：
                                                        {item.relatedCompany ||
                                                            "无"}
                                                    </Form.Item>
                                                </div>
                                            );
                                        }
                                    })}
                                <Form.Item
                                    style={{ marginBottom: "10px" }}
                                    label="标的："
                                    required={true}
                                >
                                    {window._SYT_util.formatMoney(
                                        window._SYT_util.math.accDiv(
                                            detailData.caseAmt * 1,
                                            10000
                                        ),
                                        2
                                    ) * 1}
                                    万元
                                </Form.Item>
                            </Form>
                            <div
                                style={
                                    detailData.status != "0"
                                        ? {
                                            paddingLeft: "33%",
                                            paddingTop: "10px",
                                        }
                                        : { display: "none" }
                                }
                            >
                                <Button
                                    type="primary"
                                    onClick={this.reCheck}
                                    loading={this.state.confirmLoading}
                                >
                                    重新风控
                                </Button>
                            </div>
                        </div>
                        <div style={{ width: "50%" }}>
                            <div
                                style={{
                                    borderLeft: "1px dashed #666",
                                    paddingLeft: "30px",
                                }}
                            >
                                <div
                                    style={
                                        this.state.status == "loading"
                                            ? {
                                                textAlign: "center",
                                                padding: "30px 0 40px",
                                            }
                                            : { display: "none" }
                                    }
                                >
                                    <LoadingOutlined spin style={{ fontSize: 50 }} />
                                    <div
                                        style={{
                                            fontSize: 22,
                                            color: "#444",
                                            paddingTop: "24px",
                                        }}
                                    >
                                        正在为您进行智能诊断，请耐心等待结果！
                                    </div>
                                </div>
                                <div
                                    style={
                                        detailData.status != "0"
                                            ? { textAlign: "right" }
                                            : { display: "none" }
                                    }
                                >
                                    状态
                                    <div
                                        style={{
                                            fontSize: 20,
                                            color: "#444",
                                            paddingBottom: "10px",
                                        }}
                                    >
                                        {detailData.status == "1"
                                            ? "诊断完成"
                                            : null}
                                        {detailData.status == "9"
                                            ? "诊断失败"
                                            : null}
                                    </div>
                                    {detailData.status == "9"
                                        ? detailData.result ||
                                        "请确定被告信息是否有误，修改后重新风控"
                                        : null}
                                </div>
                                <div
                                    style={
                                        this.state.status == "success"
                                            ? {}
                                            : { display: "none" }
                                    }
                                >
                                    <div
                                        style={{
                                            display: "inline-block",
                                            width: "200px",
                                        }}
                                    >
                                        本次风控等级：
                                        <span
                                            style={{
                                                fontSize: "20px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {detailData.grade}
                                        </span>
                                    </div>
                                    <div style={{ display: "inline-block" }}>
                                        风控评分：
                                        <span
                                            style={{
                                                fontSize: "20px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {detailData.score}分
                                        </span>
                                    </div>
                                    <br />
                                    <br />
                                    {detailData.debtorDTOList &&
                                        detailData.debtorDTOList.map((item) => (
                                            <div key={item.litigantName}>
                                                <div
                                                    style={{
                                                        display: "inline-block",
                                                        background: "#F2F2F2",
                                                        marginBottom: "8px",
                                                        borderRadius: "4px",
                                                        padding: "5px",
                                                        position: "relative",
                                                        left: "-5px",
                                                    }}
                                                >
                                                    <div>
                                                        被告：
                                                        {item.litigantName}
                                                    </div>
                                                    <div
                                                        style={{
                                                            display:
                                                                "inline-block",
                                                            width: "200px",
                                                        }}
                                                    >
                                                        本次风控等级：
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    "20px",
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            {item.grade}
                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display:
                                                                "inline-block",
                                                        }}
                                                    >
                                                        风控评分：
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    "20px",
                                                                fontWeight:
                                                                    "bold",
                                                                display:
                                                                    "inline-block",
                                                                minWidth:
                                                                    "23px",
                                                            }}
                                                        >
                                                            {item.score}
                                                        </span>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    "20px",
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            分
                                                        </span>
                                                    </div>
                                                </div>
                                                <br />
                                            </div>
                                        ))}
                                    <br />
                                    <br />
                                    智能风控结论：
                                    <br />
                                    <span>{detailData.result}</span>
                                    <br />
                                    <br />
                                    {detailData.reportStatus == "2" ? (
                                        <div>
                                            <a
                                                target="_blank"
                                                href={detailData.reportUrl}
                                            >
                                                智能风控报告.pdf
                                            </a>
                                            <Button
                                                onClick={() => {
                                                    window.open(
                                                        "https://m.winhc.cn/wx-mobile/pdfjs/web/viewer.html?file=" +
                                                        detailData.reportUrl
                                                    );
                                                }}
                                                type="primary"
                                                style={{ marginLeft: "30px" }}
                                            >
                                                查看报告
                                            </Button>
                                        </div>
                                    ) : null}
                                    {/* {!detailData.reportUrl ? (
                                        <Button
                                            type="primary"
                                            onClick={this.getReport}
                                            loading={this.state.reportLoading}
                                        >
                                            {this.state.reportLoading
                                                ? "生成中..."
                                                : "生成报告"}
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                                    {detailData.reportStatus == "3" ? (
                                        <div style={{ color: "red" }}>
                                            生成报告失败
                                        </div>
                                    ) : null} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default connect((state) => {
    return state;
})(MainM);
