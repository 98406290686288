import React from "react";
import {
  Button,
  Table,
  Row,
  Col,
  message,
  Form,
  Breadcrumb,
  Modal,
  Input, Popover,
} from "antd";
import { Axios } from "../../axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, HashRouter } from "react-router-dom";
import { FormOutlined } from "@ant-design/icons";
import G2 from "@antv/g2";
import { AreaSelectForm, ImgViewer, FlieList, rules } from "../../common";

import filter from "../../common/filter";
import time from "../../common/filter";

let detailCustId;
class MainM extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadingList: false,
      DataInfo: {},
      historyList: [],
      ReturnedNum: 0,
      ReturnedPage: 0,
      ReturnedLoading: false,
    };
  }

  componentDidMount () {
    detailCustId = this.props.match.params.id;
    this.getData();
    this.gethistory();
    this.getReturnedList(1);
  }

  getData = async () => {
    try {
      this.setState({
        loading: true,
      });
      let res = await Axios("get", "/legalEntrusted/list/" + detailCustId, {});
      if (res.errorCode === "200" || res.isSuccess == 'T') {
        this.setState({
          DataInfo: res.body || {},
        });
      } else {
        message.error(res.message);
      }
    } catch (err) {
      // message.error(err);
      this.setState({ loading: false });
    }
  };
  gethistory = async () => {
    try {
      this.setState({
        loading: true,
      });
      let res = await Axios("get", "/legalEntrusted/query/history/" + detailCustId, {});
      if (res.errorCode === "200" || res.isSuccess == 'T') {
        this.setState({
          historyList: res.body || [],
        });
      } else {
        message.error(res.message);
      }
    } catch (err) {
      // message.error(err);
      this.setState({ loading: false });
    }
  };
  //获取回款
  getReturnedList = async pageNum => {
    try {
      this.setState({
        ReturnedLoading: true,
      });
      let res = await Axios("get", "/legalEntrusted/return/list/" + detailCustId, { id: detailCustId, pageNum: pageNum || 1, pageSize: 10 });
      if (res.isSuccess === "T" || res.code == "00") {
        this.setState({
          ReturnedList: res.body || [],
          ReturnedPage: pageNum,
          ReturnedNum: res.totalNum,
        });
      } else {
        message.error(res.errorMsg);
      }
      this.setState({
        ReturnedLoading: false,
      });
    } catch (err) {
      // message.error(err);
      this.setState({ ReturnedLoading: false });
    }
  };
  render () {
    const cStyle = {
      padding: "12px 24px 12px 24px",
      marginBottom: "15px",
      borderRadius: "4px",
      background: "#fff",
      fontSize: "14px",
    };
    const tStyle = {
      color: "#222",
      lineHeight: "30px",
      fontWeight: "650",
      fontSize: "16px",
      margin: "0px -24px 15px -24px",
      borderBottom: "1px solid #efe5e5",
      padding: "0 24px 8px 24px",
    };
    const dStyle = {
      color: "#222",
      lineHeight: "30px",
      margin: "10px 0px 0px 0px",
    };
    const { DataInfo, historyList, ReturnedList, ReturnedNum, ReturnedPage, ReturnedLoading } = this.state;
    return (
      <div className="pageContainer">
        <Row className="headNav">
          <Col span={24}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>非诉清收</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/FsCaseManage">非诉清收</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>非诉清收详情</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={24} className="title">
            <span className="name">非诉清收详情</span>
            <div style={{
              background: "#1890ff",
              color: "#fff",
              lineHeight: "26px",
              padding: "3px 10px",
              float: "right",
              borderRadius: "4px",
              marginRight: "12px",
            }}  >
              {filter.FsCaseStatus(DataInfo.status)}
            </div>
          </Col>
        </Row>
        <div className="scrollContainer">
          <div style={cStyle}>
            <div style={tStyle}>客户基本信息</div>
            <Row style={dStyle}>
              <Col span={8}>
                客户名称：{DataInfo.creditorCompanyName || "-"}
              </Col>
              <Col span={8}>
                联系人：{DataInfo.linkman || "-"}
              </Col>
              <Col span={8}>
                联系电话：{DataInfo.mobileNo || "-"}
              </Col>
            </Row>
            <Row style={dStyle}>
              <Col span={8}>
                套餐类型： {filter.legalspecification(DataInfo.purchaseSpec) || '-'}&nbsp;&nbsp;
                {DataInfo.purchaseSpec ? ['legal_legal', 'legal_wisdom', 'legal_basic'].includes(DataInfo.purchaseSpec) && '含催收' : ''}
              </Col>
              <Col span={8}> </Col>
              <Col span={8}> </Col>
            </Row>
          </div>
          <div style={cStyle}>
            <div style={tStyle}>案件信息</div>
            <Row style={dStyle}>
              <Col span={8}>
                案件ID：{detailCustId || "-"}
              </Col>
              <Col span={8}>
                客户类型：{DataInfo.legalCloudId ? '云法务' : '-'}
              </Col>
              <Col span={8}>
                来源：{filter.FsPackageName((DataInfo.packageName || '') + '_' + DataInfo.source)}
              </Col>
            </Row>
            <Row style={dStyle}>
              <Col span={8}>
                代理商：{DataInfo.brokerInfoName || "-"}
              </Col>
              <Col span={8}> 提交时间：{DataInfo.timeCreated || "-"}</Col>
              <Col span={8}> 接单时间：{DataInfo.confirmTime || "-"}</Col>
            </Row>
            <Row style={dStyle}>
              <Col span={8}>
                结束时间：{DataInfo.finishTime || "-"}
              </Col>
              <Col span={8}> 应收金额：{filter.NumberToFixed(DataInfo.receivable || 0, 2) + ' 元'}</Col>
              <Col span={8}> 备注：{DataInfo.remark || "-"}</Col>
            </Row>
          </div>
          <div style={cStyle}>
            <div style={tStyle}>债务人信息</div>
            <Row style={dStyle}>
              <Col span={8}>
                欠款对象：{DataInfo.payerName || "-"}
              </Col>
              <Col span={8}>
                债务人：{DataInfo.masterDebtName || "-"}
              </Col>
              <Col span={8}>
                联系电话：{DataInfo.masterDebtMobile || "-"}
              </Col>
            </Row>
            <Row style={dStyle}>
              <Col span={8}>
                身份证号：{DataInfo.masterDebtIdCard || "-"}
              </Col>
            </Row>
          </div>
          <div style={cStyle}>
            <div style={tStyle}>跟进记录</div>
            <Table
              dataSource={DataInfo.legalEntrustedCollectionRecordInfos || []}
              columns={[{
                title: '时间',
                dataIndex: 'time',
                width: 206,
                render: (t) => (t || '').slice(0, 16)
              },
              {
                title: '催收员',
                width: 100,
                dataIndex: 'operName',
                render: (t) => t || '-'
              },
              {
                title: '事项',
                width: 100,
                dataIndex: 'matter',
                render: (t) => t || '-'

              }, {
                title: '结果',
                width: 160,
                dataIndex: 'result',
                render: (t) => t || '-'

              }, {
                title: '备注',
                dataIndex: 'memo',
              }]}
              size="middle"
              pagination={
                (DataInfo.legalEntrustedCollectionRecordInfos || []).length > 10
                  ? {
                    size: "large",
                    showQuickJumper: true,
                    pageSize: 10,
                  }
                  : false
              }
              bordered={true}
            />
          </div>
          <div style={cStyle}>
            <div style={tStyle}>客户回款记录</div>
            <Table
              dataSource={ReturnedList || []}
              rowKey={record => record.id + "" + record.timeCreated}
              columns={[
                {
                  title: "回款ID",
                  dataIndex: "returnRecordId",
                  width: 100,
                  render: (text, record) => text || "-",
                },
                {
                  title: "回款金额（元）",
                  dataIndex: "returnAmt",
                  width: 100,
                },
                {
                  title: "回款凭证",
                  dataIndex: "returnUrl",
                  width: 100,
                  render: (text, record) =>
                    (text && text.slice(0, 1) == "[") ? (
                      <Popover
                        content={
                          <p
                            style={{
                              maxWidth: "400px",
                            }}
                          >
                            <FlieList
                              fileList={(JSON.parse(text) || []).map(v => {
                                return { docUrl: v.fileUrl };
                              })}
                            />
                          </p>
                        }
                        title={null}
                      >
                        <a>查看</a>
                      </Popover>
                    ) : (
                      "-"
                    ),
                },
                {
                  title: "添加人",
                  dataIndex: "insertMan",
                  width: 120,
                  render: (text, record) => text || "-",
                },
                {
                  title: "添加渠道",
                  dataIndex: "source",
                  width: 90,
                  render: (text, record) => (record.packageName && text ? filter.FsPackageName(record.packageName + "_" + text) : text ? filter.FsCaseSource(text) : filter.UserFeedBackAppPackage(record.packageName)),
                },
                {
                  title: "回款时间",
                  dataIndex: "returnDate",
                  width: 120,
                  render: t => (t || "").slice(0, 10),
                },
                {
                  title: "添加时间",
                  dataIndex: "timeCreated",
                  width: 120,
                },
              ]}
              size="middle"
              pagination={{
                size: "large",
                showQuickJumper: true,
                pageSize: 10,
                total: parseInt(ReturnedNum),
                current: Number(ReturnedPage),
              }}
              loading={ReturnedLoading}
              bordered={true}
              onChange={({ current }) => this.getReturnedList(current)}
            />
          </div>
          <div style={cStyle}>
            <div style={tStyle}>操作日志</div>
            <Table
              dataSource={historyList || []}
              columns={[
                {
                  title: "操作类型",
                  dataIndex: "operAction",
                },
                {
                  title: "操作人",
                  dataIndex: "operName",
                },
                {
                  title: "操作时间",
                  dataIndex: "operDate",
                  render (t, r) {
                    return <span>{filter.dateTime(t + "" + r.operTime)}</span>;
                  },
                },
                {
                  title: "内容",
                  dataIndex: "operContent",
                },
              ]}
              size="middle"
              pagination={
                (DataInfo.legalEntrustedCollectionRecordInfos || []).length > 10
                  ? {
                    size: "large",
                    showQuickJumper: true,
                    pageSize: 10,
                  }
                  : false
              }
              bordered={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

let Main = connect(
  (state) => {
    return state;
  },
  (dispatch) => ({
    actions: bindActionCreators({}, dispatch),
  })
)(MainM);
export default Main;