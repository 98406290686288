import React from "react";
import { Button, Table, Row, Col, message, Form, Breadcrumb, Modal, Tooltip } from "antd";
import { Axios } from "../../axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link, HashRouter } from "react-router-dom";
import G2 from "@antv/g2";
import { AreaSelectForm, ImgViewer, rules, FlieList, popoverTable } from "../../common";
import filter from "../../common/filter";
import time from "../../common/filter";
import Filter from "../../common/filter";
import { Ossupload } from "../../common/upLoad";
import log from "echarts/src/scale/Log";

let detailCustId;
const confirm = Modal.confirm;

class MainM extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadingList: false,
      companyInfo: {},
      packageVO: {},
      hasServe: false,
      itemVOList: [],
      details: {},
      reconciliationRules: {},
      bizOperHis: [],
      legalServiceItemName: "",
      connectList: [], //沟通记录，只会有一条
      feedBackList: [],
      feedBackTotalNum: 0,
      OtherSettles: [],
      operation: [],
      CaseList: [],
      totalNum: 0,
      ManageallserveModalLoading: false,
      settleStatus: undefined,
      sumAmt: 0,
      ServiceList: [],
      SingleList: [],
    };
  }

  componentDidMount () {
    detailCustId = this.props.match.params.id;
    this.getData();
    this.getFeedBack(1);
    this.GetSingle();
    this.CaseTable();
  }

  handleTableChange = pagination => {
    this.getFeedBack(pagination.current);
  };
  getData = async () => {
    try {
      this.setState({
        loading: true,
      });
      let res = await Axios("get", "/checkBill/reconciliation/" + detailCustId, {});
      if (res.code === "200") {
        this.setState(
          {
            details: res.data.reconciliationBasicInfo || {},
            reconciliationRules: res.data.reconciliationRules || [],
            settleStatus: res.data.settleStatus || undefined,
            OtherSettles: res.data.otherSettles || [],
          },
          () => {
            this.GetServiceReturn(this.state.details.settleNo);
          }
        );
      } else {
        message.error(res.message);
      }
    } catch (err) {
      // message.error(err);
      this.setState({ loading: false });
    }
  };
  CaseTable = async () => {
    try {
      this.setState({
        loading: true,
      });
      let res = await Axios("get", "/checkBill/reconciliation/" + detailCustId + "/case", { pageNum: "1", pageSize: "1000" });
      if (res.code === "200") {
        this.setState({
          CaseList: res.data || [],
        });
      } else {
        message.error(res.message);
      }
    } catch (err) {
      // message.error(err);
      this.setState({ loading: false });
    }
  };
  GetServiceReturn = async value => {
    try {
      let res = await Axios("get", "/checkBill/query/serviceReturn", { pageNum: "1", pageSize: "100", settleNo: value });
      if (res.isSuccess == "T" || res.errorCode == "SUCCESS") {
        this.setState({
          ServiceList: res.body || [],
        });
      } else {
        message.error(res.message);
      }
    } catch (err) {
      // message.error(err);
    }
  };
  GetSingle = async () => {
    try {
      let res = await Axios("get", "/checkBill/" + detailCustId + "/single", { pageNum: "1", pageSize: "100", legalCloudSettleId: detailCustId });
      if (res.isSuccess == "T" || res.errorCode == "SUCCESS") {
        this.setState({
          SingleList: res.body || [],
        });
      } else {
        message.error(res.message);
      }
    } catch (err) {
      // message.error(err);
    }
  };
  getFeedBack = async currentPage => {
    try {
      this.setState({
        loading: true,
      });
      let res = await Axios("get", "/checkBill/detail/" + detailCustId + "/page", {
        pageNum: currentPage + "",
        pageSize: "10",
      });
      if (res.code === "200") {
        this.setState(
          {
            operation: res.data.dataList,
            totalNum: res.data.totalNum,
          },
          () => {
            let sumAmt = 0;
            (this.state.operation || []).map(v => (sumAmt = sumAmt + v.orderSettleAmtStr * 1));
            this.setState({ sumAmt });
          }
        );
      } else {
        message.error(res.message);
      }
    } catch (err) {
      // message.error(err);
      this.setState({ loading: false });
    }
  };

  render () {
    const cStyle = {
      padding: "12px 24px 12px 24px",
      marginBottom: "15px",
      borderRadius: "4px",
      background: "#fff",
      fontSize: "14px",
    };
    const tStyle = {
      color: "#222",
      lineHeight: "30px",
      fontWeight: "650",
      fontSize: "16px",
      margin: "0px -24px 15px -24px",
      borderBottom: "1px solid #efe5e5",
      padding: "0 24px 8px 24px",
    };
    const dStyle = {
      color: "#222",
      lineHeight: "30px",
      margin: "10px 0px 0px 0px",
    };
    const SStyle = {
      color: "red",
    };
    const { sumAmt, details, operation, reconciliationRules, settleStatus, CaseList, OtherSettles, SingleList, ServiceList } = this.state;
    console.log(operation, this.state.operation);
    return (
      <div className="pageContainer">
        <Row className="headNav">
          <Col span={24}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to="/MarketManage">销售对账管理</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>销售对账管理详情</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={24} className="title">
            <span className="name">销售对账管理详情</span>{" "}
            <div
              style={{
                background: "#1890ff",
                color: "#fff",
                padding: "4px 12px",
                float: "right",
                borderRadius: "4px",
              }}
            >
              {settleStatus == "1" ? "已结算" : "未结算"}
            </div>
          </Col>
        </Row>

        <div className="scrollContainer">
          <div style={cStyle}>
            <div style={tStyle}>对账基本信息</div>
            <Row style={dStyle}>
              <Col span={8}>对账单号：{details.settleNo}</Col>
              <Col span={8}>对账主体：{details.brokerName}</Col>
            </Row>
            <Row style={dStyle}>
              <Col span={8}>结算金额：{(details.finalSettleAmt || 0).toFixed(2) + "元"}</Col>
              <Col span={8}>
                对账周期：
                {(details.settleStartTime ? details.settleStartTime.replace("T", " ") : "暂无") + "  ~  " + (details.settleEndTime ? details.settleEndTime.replace("T", " ") : "暂无")}
              </Col>
            </Row>
            <span></span>
          </div>
          <div style={cStyle}>
            <div style={tStyle}>合作商收款信息</div>
            <Table
              rowKey={record => record.id}
              dataSource={[details]}
              columns={[
                {
                  title: "开户名",
                  dataIndex: "accountName",
                },
                {
                  title: "开户行",
                  dataIndex: "bankName",
                },
                {
                  title: "收款账号",
                  dataIndex: "accountNum",
                },
              ]}
              scroll={{ x: "max-content" }}
              size="middle"
              pagination={false}
              bordered={true}
            />
          </div>
          <div style={cStyle}>
            <div style={tStyle}>结算概述</div>
            <Row style={dStyle}>
              <Col>
                本期结算订单数
                <span style={SStyle}>&nbsp;{reconciliationRules.settleOrderCount || 0}&nbsp;</span>
                {reconciliationRules.directGuestCount ? (
                  <span>
                    ，其中直客订单
                    <span style={SStyle}>&nbsp;{reconciliationRules.directGuestCount || 0}</span>
                  </span>
                ) : (
                  ""
                )}
                {reconciliationRules.operatorCount ? (
                  <span>
                    ，下级运营商订单
                    <span style={SStyle}>&nbsp;{reconciliationRules.operatorCount || 0}</span>
                  </span>
                ) : (
                  ""
                )}
                {reconciliationRules.hipCityCount ? (
                  <span>
                    ，被跨城市订单
                    <span style={SStyle}>&nbsp;{reconciliationRules.hipCityCount || 0}</span>
                  </span>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            {sumAmt ? (
              <Row style={dStyle}>
                <Col>
                  您本期云法务套餐结算总金额为
                  <span style={SStyle}>&nbsp;{sumAmt || 0}&nbsp;</span>元
                </Col>
              </Row>
            ) : (
              ""
            )}
            {details.settleCaseAmt ? (
              <Row style={dStyle}>
                <Col>
                  您本期案件结算总金额为
                  <span style={SStyle}>&nbsp;{details.settleCaseAmt || 0}&nbsp;</span>元
                </Col>
              </Row>
            ) : (
              ""
            )}
            {reconciliationRules.singleCount ? (
              <Row style={dStyle}>
                <Col>
                  您本期单项服务订单数
                  <span style={SStyle}>&nbsp;{reconciliationRules.singleCount || 0}&nbsp;</span>
                  结算金额 <span style={SStyle}>&nbsp;{reconciliationRules.singleSettleAmt || 0}&nbsp;</span>元
                </Col>
              </Row>
            ) : (
              ""
            )}
            {reconciliationRules.settleEntrustedReturnAmt ? (
              <Row style={dStyle}>
                <Col>
                  您本次非诉清收结算总金额为
                  <span style={SStyle}>&nbsp;{reconciliationRules.settleEntrustedReturnAmt || 0}&nbsp;</span>元
                </Col>
              </Row>
            ) : (
              ""
            )}
            {details.otherSettleAmt ? (
              <Row style={dStyle}>
                <Col>
                  您其他结算总金额为
                  <span style={SStyle}>&nbsp;{details.otherSettleAmt || 0}&nbsp;</span>元
                </Col>
              </Row>
            ) : (
              ""
            )}
            <Row style={dStyle}>
              <Col>
                本期结算金额为
                <span style={SStyle}>&nbsp;{(details.finalSettleAmt || 0).toFixed(2)}&nbsp;</span>元
              </Col>
            </Row>
          </div>
          <div style={cStyle}>
            <div style={tStyle}>云法务套餐结算</div>
            <Table
              rowKey={record => record.id}
              dataSource={operation}
              columns={[
                {
                  title: "订单号",
                  dataIndex: "orderId",
                },
                {
                  title: "客户名称",
                  dataIndex: "companyName",
                },
                {
                  title: "销售主体",
                  dataIndex: "brokerInfoName",
                },
                {
                  title: "套餐",
                  dataIndex: "specification",
                  render: t => filter.legalspecification(t),
                },
                {
                  title: "套餐成本价",
                  dataIndex: "costAmt",
                  render: text => (text ? text + "元" : "-"),
                },
                {
                  title: "实际支付金额",
                  dataIndex: "payAmt",
                  render: text => (text ? text + "元" : "-"),
                },
                {
                  title: "结算佣金比例",
                  dataIndex: "settleRate",
                  render: text => (text ? (text * 100).toFixed(2) + "%" : "-"),
                },
                // {
                //     title: "超出成本价部分佣金金额",
                //     dataIndex: "overCostAmt",
                //     render: (text) => text ? (text + "元") : '-',
                // },
                {
                  title: "佣金金额",
                  dataIndex: "orderSettleAmtStr",
                  render: (text, record) => (text || 0) + "元",
                },
                {
                  title: "订单状态",
                  dataIndex: "transSign",
                  render: text => (text === 0 ? "正常" : text === 1 ? "红冲" : ""),
                },
                {
                  title: "订单类型",
                  dataIndex: "orderType",
                },
                {
                  title: "是否上传合同附件",
                  dataIndex: "isUpload",
                },
                {
                  title: "购买时间",
                  dataIndex: "payTime",
                },
                {
                  title: "订单关系",
                  dataIndex: "settleDesc",
                },
                {
                  title: "结算说明",
                  dataIndex: "memo",
                  render: (text, record) =>
                    text ? (
                      <a
                        onClick={() =>
                          confirm({
                            title: null,
                            content: <div style={{ whiteSpace: "pre-wrap" }}>{text}</div>,
                            icon: null,
                            maskClosable: true,
                          })
                        }
                      >
                        查看
                      </a>
                    ) : (
                      "-"
                    ),
                },
              ]}
              scroll={{ x: "max-content" }}
              size="middle"
              pagination={{
                size: "large",
                pageSize: 10,
                total: parseInt(this.state.totalNum),
                showTotal: totalNum => {
                  return "共 " + totalNum + " 条记录 ";
                },
              }}
              bordered={true}
              onChange={this.handleTableChange}
            />
          </div>
          <div style={cStyle}>
            <div style={tStyle}>案件结算</div>
            <Table
              rowKey={record => record.id}
              dataSource={CaseList}
              columns={[
                {
                  title: "案件ID",
                  dataIndex: "caseId",
                  render: text => <Link to={"/CaseManagementDetail/" + text}>{text}</Link>,
                },
                {
                  title: "原告",
                  dataIndex: "creditorName",
                },
                {
                  title: "被告",
                  dataIndex: "debtorName",
                },
                {
                  title: "关联收入",
                  dataIndex: "riskIncomes",
                  render: (t, r) => (
                    <div>
                      {" "}
                      {(t || []).length ? (
                        <div>
                          {(t || []).map(v => (
                            <div>
                              ID：{v.id} 金额：{v.incomeAmt || "-"}
                            </div>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ),
                },
                {
                  title: "结算类型",
                  dataIndex: "settleType",
                  render: text => (text == "1" ? "律所结算" : text == "2" ? "平台结算" : text == "3" ? "居间结算" : "-"),
                },
                {
                  title: "结算金额",
                  dataIndex: "settleAmt",
                },
                {
                  title: "操作时间",
                  dataIndex: "operDate",
                },
                {
                  title: "操作人",
                  dataIndex: "operName",
                },
              ]}
              size="middle"
              pagination={{
                size: "large",
                showQuickJumper: true,
                pageSize: 10,
              }}
              bordered={true}
            />
          </div>
          <div style={cStyle}>
            <div style={tStyle}>单项服务订单结算</div>
            <Table
              rowKey={(record, index) => record.companyName + record.brokerInfoName + index}
              dataSource={SingleList}
              columns={[
                {
                  title: "订单ID",
                  dataIndex: "orderId",
                  width: 200,
                  render: (text, record) => text || "-",
                },
                {
                  title: "客户名称",
                  dataIndex: "companyName",
                  render: (text, record) => text || "-",
                },
                {
                  title: "销售主体",
                  dataIndex: "brokerInfoName",
                  render: (text, record) => text || "-",
                },
                {
                  title: "购买时间",
                  dataIndex: "payTime",
                  render: (text, record) => (text || "-").slice(0, 10),
                },
                {
                  title: "单项服务内容",
                  dataIndex: "singleServiceDesc",
                  render: (text, record) => text || "-",
                },
                {
                  title: "成本价",
                  dataIndex: "costAmt",
                },
                {
                  title: "实际支付金额",
                  dataIndex: "payAmt",
                },
                {
                  title: "佣金金额",
                  dataIndex: "kickBackAmt",
                },
                {
                  title: "订单状态",
                  dataIndex: "status",
                  render: (text, record) => Filter.SingleStatus(text),
                },
              ]}
              size="middle"
              pagination={{
                size: "large",
                showQuickJumper: true,
                pageSize: 10,
              }}
              bordered={true}
            />
          </div>
          <div style={cStyle}>
            <div style={tStyle}>非诉清收服务费结算</div>
            <Table
              rowKey={(record, index) => record.id}
              dataSource={ServiceList}
              columns={[
                {
                  title: "案件ID",
                  dataIndex: "entrustedCollectionId",
                  width: 200,
                },
                {
                  title: "客户",
                  dataIndex: "creditorCompanyName",
                  render: (text, record) => text || "-",
                },
                {
                  title: "债务人",
                  dataIndex: "payerName",
                  render: (text, record) => text || "-",
                },
                {
                  title: "回款类型",
                  dataIndex: "returnType",
                  render: (text, record) => filter.ServiceChargeMode(text),
                },
                {
                  title: "服务费金额",
                  dataIndex: "serviceAmt",
                  render: (text, record) => text || "-",
                },
                {
                  title: "关联客户回款记录",
                  dataIndex: "returnRelationInfoList",
                  render: (text, record) =>
                    (text || []).length ? (
                      <div>
                        {(text || []).map(v => (
                          <div>
                            ID：{v.id} 金额：{v.entrustedReturnAmt || "-"}
                          </div>
                        ))}
                      </div>
                    ) : (
                      "-"
                    ),
                },
                {
                  title: "实付金额",
                  dataIndex: "payAmt",
                  width: 90,
                  render: (text, record) => text || "-",
                },
                {
                  title: "审核日期",
                  dataIndex: "approveDate",
                  render: (text, record) => (text || "-").slice(0, 10),
                },
                {
                  title: (
                    <span>
                      结算比例&nbsp;{" "}
                      <Tooltip
                        title={
                          <p>
                            结算比例说明：
                            <br />
                            非诉清收处置团队成本占比为60%，平台收益为40%，合作商可获得平台收益的60%，即最终为40%*60%=24%
                          </p>
                        }
                      >
                        <ExclamationCircleOutlined />
                      </Tooltip>{" "}
                    </span>
                  ),
                  dataIndex: "settleFeeRate",
                  render: (text, record) => (text ? text * 100 + "%" : "-"),
                },
                {
                  title: "结算金额",
                  dataIndex: "settleAmt",
                },
              ]}
              size="middle"
              pagination={{
                size: "large",
                showQuickJumper: true,
                pageSize: 10,
              }}
              bordered={true}
            />
          </div>
          <div style={cStyle}>
            <div style={tStyle}>其他结算</div>
            <Table
              rowKey={record => record.id}
              dataSource={OtherSettles}
              columns={[
                {
                  title: "结算类型",
                  dataIndex: "settleType",
                  render: text => filter.SettleType(text),
                },
                {
                  title: "订单笔数",
                  dataIndex: "orderCount",
                  render: (text, record) => (record.settleType == 1 || record.settleType == 3 ? text || 0 : text ? text : "-"),
                },
                {
                  title: "月度云法务套餐总金额",
                  dataIndex: "packageSumAmt",
                  render: (text, record) => (record.settleType == 1 ? (text || 0) + "元" : text ? text + "元" : "-"),
                },
                {
                  title: "比例",
                  dataIndex: "rate",
                  render: text => (text ? text * 100 + "%" : "-"),
                },
                {
                  title: "结算金额",
                  dataIndex: "settleAmt",
                  render: text => (text ? text + "元" : "-"),
                },
                {
                  title: "添加日期",
                  dataIndex: "timeCreated",
                  render: text => text || "-",
                },
                popoverTable(10, {
                  title: "备注",
                  dataIndex: "memo",
                }),
              ]}
              size="middle"
              pagination={{
                size: "large",
                showQuickJumper: true,
                pageSize: 10,
                // total: parseInt(this.state.totalNum),
                // current: Number(this.state.currentPage),
              }}
              bordered={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

let Main = connect(
  state => {
    return state;
  },
  dispatch => ({
    actions: bindActionCreators({}, dispatch),
  })
)(MainM);
export default Main;
