import React, { useEffect, useState } from "react";
// import FileViewer from 'react-file-viewer'
import Filter from "./filter";
const FilePreview = ({ path, height = "100vh" }) => {
  const [ftype, setFtype] = useState("");
  //   const [page, setPage] = useState<number>(1)
  //   const [totalPage, setTotalPage] = useState<number>(0)
  useEffect(() => {
    path && setFtype(Filter.urlGetFileType(path));
  }, [path]);

  if (!path) {
    return <></>;
  }

  return (
    <div className="relative">
      {["png", "jpeg", "jpg", "gif", "webp", "bmp"].includes(
        ftype.toLowerCase()
      ) ? (
        <img src={path} alt="" style={{ width: '100%' }} />

      ) : ['mp4', 'mp3', 'm4a'].includes(
        ftype.toLowerCase()
      ) ? <video
        id="video1"
        src={path}
        controls="controls"
        width="100%"
        controlslist="nodownload"
      /> : (
        <iframe
          className="web-file-iframe"
          src={
            ftype === "pdf"
              ? `https://mz.winhc.cn/pdfjs/web/pcViewer.html?type=closeShare&file=${encodeURI(
                path
              )}`
              : ftype === "txt" ? encodeURI(path) : `https://view.officeapps.live.com/op/view.aspx?src=${encodeURI(
                path
              )}`
          }
          // src={"http://view.xdocin.com/xdoc?_xdoc=" + url}
          frameBorder="0"
          allowFullScreen={true}
          allow="clipboard-read; clipboard-write"
          style={{ width: "100%", height }}
        ></iframe>
      )}
    </div>
  );
};

// 添加鼠标事件处理程序

export default FilePreview;
