import React, { Component } from "react";
import {
    Button,
    Table,
    Row,
    Col,
    message,
    Form,
    DatePicker, Card, Dropdown,
    Breadcrumb,
    Modal, Menu,
    Popover, Input, Popconfirm, Collapse, Descriptions, Select,
} from "antd";
import { Axios } from "../../axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SaveOutlined, EditOutlined } from '@ant-design/icons';
import { Link, HashRouter } from "react-router-dom";
import G2 from "@antv/g2";
import { Shengpi2, Tables } from "./Common";
import { AreaSelectForm, ImgViewer, rules, FlieList } from "../../common";
import filter from "../../common/filter";
import time from "../../common/filter";
import Filter from "../../common/filter";
import { DownOutlined, UploadOutlined } from '@ant-design/icons';
import { Ossupload } from "../../common/upLoad";
import moment from "moment";

const Panel = Collapse.Panel;
const Option = Select.Option;
const { TextArea } = Input;
let detailCustId;
let timeout;
let currentValue;
let docKind_applyDoc = '';

function fetch (value, callback) {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    currentValue = value;

    async function fake () {
        let res = await Axios("get", "/caseBase/caseReson", {
            caseReasonName: value,
            pageNum: "1",
            pageSize: "10",
        });
        console.log(res.body)
        if (res.code === "00") {
            callback(res.body);
        }
    }

    timeout = setTimeout(fake, 300);
}

class MainM extends React.Component {
    formRef = React.createRef();
    formRef1 = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            companyInfo: {},
            packageVO: {},
            bizOperHis: [],
            brokeFileList: [],
            isInfo: false,
            isInfo1: false,
            uploadDisabled: true,
            value: undefined,
            data: [],
            Tableloading: false,
            legalCloudOperHis: [],
            caseBaseInfoVo: {},
            docKindOption: [],
            caseDocList: [],
            Modal_anjianfengxiang: false,
            shareCaseInfoList: [],
            caseLbsInfos: [],
            BillTableList: [],
            caseBaseInfo: {},
            isTrue1: false,
            isTrue2: false,
        };
    }

    componentDidMount () {
        detailCustId = this.props.match.params.id;
        this.getData();
        this.Infokind()
        docKind_applyDoc = '';
    }

    getData = async () => {
        try {
            this.setState({
                loading: true,
            });
            let res = await Axios(
                "get",

                "/caseBase/open/" + detailCustId,
                {}
            );
            if (res.code === "00" || res.isSucces == 'T') {
                let caseInfoDetailVo = JSON.parse(res.body).caseInfoDetailVo

                this.setState({
                    companyInfo: caseInfoDetailVo,
                    legalCloudOperHis: JSON.parse(res.body).legalCloudOperHis,
                    caseBaseInfoVo: JSON.parse(res.body).caseManageInfoVo || {},
                    caseBaseInfo: JSON.parse(res.body).caseBaseInfo || {},
                    // uploadDisabled: true,
                    caseLbsInfos: JSON.parse(res.body).caseLbsInfos || [],
                    caseDocList: JSON.parse(res.body).caseDocList || []
                })
                console.log(JSON.parse(res.body))
                if (JSON.parse(res.body).riskApplyInfo.applyId) {
                    this.getRiskInvoice(JSON.parse(res.body).riskApplyInfo.applyId)
                }
            } else {
                message.error(res.message);
            }
        } catch
        (err) {
            // message.error(err);
            this.setState({ loading: false });
        }
    }
        ;

    fun = (docKindOption, caseDocList) => {
        console.log(docKindOption, caseDocList, "$$$$$$")
        let name
        let obj = {}
        let arr = []
        let arr1 = []
        for (let i = 0; i < docKindOption.length; i++) {
            obj = {}
            arr = []
            for (let j = 0; j < caseDocList.length; j++) {
                if (docKindOption[i].docKindId == caseDocList[j].docKindId) {
                    arr.push(caseDocList[j])
                }
            }
            if (arr.length) {
                if (docKindOption[i].docKindName) {
                    obj.Name = docKindOption[i].docKindName
                    obj.Url = arr
                    arr1.push(obj)
                }
            }

        }
        return arr1
    }

    getRiskInvoice = async (id) => {
        try {
            let res = await Axios("get", "/caseBase/riskInvoice/" + id, {});
            if (res.isSuccess === "T" || res.errorCode === "200") {
                this.setState({
                    BillTableList: res.body || []
                })
            } else {
                message.error(res.errorMsg);
            }
        } catch (err) {
            // message.error(err);
        }
    };
    Infokind = async () => {
        try {
            let res = await Axios(
                "get",

                "/caseBase/doc/kind",
                {}
            );
            console.log(res, "$$$$$$$")
            if (res.code === "200") {
                this.setState({
                    docKindOption: (res.data || []).filter(v => v.docGroup == 'apply' || v.docGroup == 'contract')
                })

            } else {
                message.error(res.message);
            }
        } catch (err) {
            // message.error(err);
        }
    };
    handleChange = (value) => {
        this.setState({ value });
    };
    handleSearch = (value) => {
        if (value) {
            fetch(value, (data) => this.setState({ data }));
        } else {
            this.setState({ data: [] });
        }
    };
    handleSubmit = (e) => {
        // e.preventDefault();
        let arr = e == 1 ? ['caseBizMode'] :
            ['creditorName', 'debtorName', 'capitalAmt', 'interestAmt', 'caseAmt', 'caseReasonName',
                'caseStageId', 'caseNo', 'referCaseNo', 'courtName', 'agentSide'];
        this.formRef.current.
            validateFields(arr)
            .then(async (values) => {
                try {
                    this.setState({ confirmLoading: true })
                    let res = await Axios('put', '/caseBase/' + detailCustId, values)
                    if (res.code == '200') {
                        this.getData();
                        this.formRef.current.resetFields()
                        message.success(res.message);
                        this.setState({
                            visible: false, confirmLoading: false, isInfo: false,
                            isInfo1: false,
                        });
                    } else {
                        message.error(res.message);
                        this.setState({ confirmLoading: false });
                    }

                } catch (err) {
                    message.error(err.message);
                    this.setState({ confirmLoading: false })

                }
            })
            .catch((info) => {
                console.log('Validate Failed:', info)
            })


    }

    SelectChange = (vel) => {
        if (vel == undefined) {
            docKind_applyDoc = '';
            this.setState({ uploadDisabled: true })
        } else {
            docKind_applyDoc = vel;
            this.setState({ uploadDisabled: false })
        }
    }

    UpLoadCallBack = (fileName) => {
        console.log(fileName)

        if (!fileName) {
            message.error('上传失败')
            return
        }
        console.log("fileName=", fileName)
        this.setState({
            upLoadLoading: true
        })
        Axios('post', '/caseBase/doc', {
            docUrl: window.winhc_oss_url + fileName,
            docKindId: docKind_applyDoc,
            caseId: detailCustId,
        }, function (res) {
            if (res.code == '00') {
                message.success(res.msg);
                this.refreshTable();
            } else {
                message.error(res.msg)
            }
            this.setState({
                upLoadLoading: false
            })
        }, function () {
            this.setState({
                upLoadLoading: false
            })
            message.error('添加失败')
        })
    }
    getShareCaseInfo = async () => {
        this.setState({
            Tableloading: true,
        })
        try {
            let res = await Axios(
                "get",

                "/caseBase/query/share",
                { caseId: detailCustId }
            );
            console.log(res)
            if (res.code === "00") {
                this.setState({
                    Tableloading: false,
                    shareCaseInfoList: JSON.parse(res.body)
                })

            } else {
                message.error(res.message);
            }
        } catch (err) {
            // message.error(err);
        }
    }
    caseStageIdFun = (value) => {

        if (value == 6 || value == 7) {
            this.setState({
                isTrue1: true,
                isTrue2: true
            })
        } else if (value == 5 || value == 4 || value == 2 || value == 3) {
            this.setState({
                isTrue1: false,
                isTrue2: true
            })
        } else {
            this.setState({
                isTrue1: false,
                isTrue2: false
            })
        }
    }
    render () {
        let QuanXian = true
        const cStyle = {
            padding: "12px 24px 12px 24px",
            marginBottom: "15px",
            borderRadius: "4px",
            background: "#fff",
            fontSize: "14px",
        };
        const tStyle = {
            color: "#222",
            lineHeight: "30px",
            fontWeight: "650",
            fontSize: "16px",
            margin: "0px -24px 15px -24px",
            borderBottom: "1px solid #efe5e5",
            padding: "0 24px 8px 24px",
        };
        const dStyle = {
            color: "#222",
            lineHeight: "30px",
            margin: "10px 0px 0px 0px",
        };
        const formItemLayout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };
        const moreStyle = {
            padding: '6px 0',
            textAlign: 'center'
        }
        const {
            companyInfo,
            packageVO,
            isInfo,
            isInfo1,
            shareCaseInfoList,
            legalCloudOperHis,
            caseBaseInfoVo,
            caseDocList,
            caseLbsInfos,
            caseBaseInfo,
            BillTableList
        } = this.state;
        const operInfo = this.props.operInfo;
        const caseLbsInfosList = {
            '1': '管辖法院',
            '2': '原告所在地',
            '3': '债务人/财产',
            '4': '财产',
        };
        console.log(companyInfo)

        return (
            <div className="pageContainer">
                <Row className="headNav">
                    <Col span={24}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>
                                <Link to="/CaseManagement">
                                    案件管理
                                </Link>
                            </Breadcrumb.Item>
                            {/* <Breadcrumb.Item>添加案件</Breadcrumb.Item> */}
                            <Breadcrumb.Item>案件详情</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={24} className="title">
                        <span className="name">案件详情</span>
                    </Col>
                </Row>
                <div className="scrollContainer">
                    <div style={cStyle}>
                        {/* <div style={tStyle}>
                            <Dropdown overlay={<Menu className='specialMenuStyle'>
                                <Menu.Item>
                                    <div style={moreStyle}>
                                        <Button style={{ width: '100%' }} size="large" onClick={() => {
                                            this.getShareCaseInfo();
                                            this.setState({ Modal_anjianfengxiang: true })
                                        }}>
                                            案件分享
                                        </Button>
                                    </div>
                                </Menu.Item>
                            </Menu>}>
                                <Button style={{ marginLeft: 8, height: '32px' }}>
                                    管理操作 <DownOutlined />
                                </Button>
                            </Dropdown>
                        </div> */}
                        <Form
                            ref={this.formRef}
                            layout="inline"
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 18 }}
                        >
                            <Collapse defaultActiveKey={['1', '2', '3', '5', '6', '7']}>
                                <Panel header={<span>申请详情<span style={{ color: 'red', marginLeft: 16 }}> {caseBaseInfoVo.isRelevance ? '关联案件' : '非关联案件'}</span></span>} key="1" id="caseScrollCon_1">
                                    <Row style={{
                                        backgroundColor: "#f9f9f9",
                                        height: "54px",
                                    }}>
                                        <Col span={12} style={{
                                            display: "flex",
                                            alignItems: 'center',
                                            height: "54px",
                                            justifyContent: 'center'
                                        }}>申请信息
                                            {/* {operInfo.userRole === '2' ? companyInfo.caseStatusDese == '审核拒绝' ? (isInfo1 ?
                                                <Popconfirm
                                                    title="是否完成修改?"
                                                    onConfirm={() => this.handleSubmit(1)}
                                                    onCancel={() => this.setState({ isInfo1: false })}
                                                    okText="提交"
                                                    cancelText="取消"
                                                >
                                                    <SaveOutlined style={{
                                                        marginLeft: "80px"
                                                    }} />
                                                </Popconfirm> :
                                                <EditOutlined style={{
                                                    marginLeft: "80px"
                                                }} onClick={() => this.setState({
                                                    isInfo1: true,
                                                    isInfo: false
                                                })} />
                                            ) : '' : (isInfo1 ? <Popconfirm
                                                title="是否完成修改?"
                                                onConfirm={() => this.handleSubmit(1)}
                                                onCancel={() => this.setState({ isInfo1: false })}
                                                okText="提交"
                                                cancelText="取消"
                                            >      <SaveOutlined style={{
                                                marginLeft: "80px"
                                            }} />
                                            </Popconfirm> :
                                                <EditOutlined style={{
                                                    marginLeft: "80px"
                                                }} onClick={() => this.setState({
                                                    isInfo1: true,
                                                    isInfo: false
                                                })} />)} */}
                                        </Col>
                                        <Col span={12} style={{
                                            display: "flex",
                                            alignItems: 'center',
                                            height: "54px",
                                            justifyContent: 'center'
                                        }}>案件信息
                                            {/* {operInfo.userRole === '2' ? companyInfo.caseStatusDese == '审核拒绝' ? (isInfo ?
                                                <Popconfirm
                                                    title="是否完成修改?"
                                                    onConfirm={() => this.handleSubmit(2)}
                                                    onCancel={() => this.setState({ isInfo: false })}
                                                    okText="提交"
                                                    cancelText="取消"
                                                >    <SaveOutlined style={{
                                                    marginLeft: "80px"
                                                }} />
                                                </Popconfirm> :
                                                <EditOutlined style={{
                                                    marginLeft: "80px"
                                                }} onClick={() => this.setState({
                                                    isInfo: true,
                                                    isInfo1: false
                                                })} />
                                            ) : '' : (isInfo ? <Popconfirm
                                                title="是否完成修改?"
                                                onConfirm={() => this.handleSubmit(2)}
                                                onCancel={() => this.setState({ isInfo: false })}
                                                okText="提交"
                                                cancelText="取消"
                                            >    <SaveOutlined style={{
                                                marginLeft: "80px"
                                            }} />
                                            </Popconfirm> :
                                                <EditOutlined style={{
                                                    marginLeft: "80px"
                                                }} onClick={() => this.setState({
                                                    isInfo: true,
                                                    isInfo1: false
                                                })} />
                                            )} */}
                                        </Col>
                                    </Row>
                                    <Descriptions bordered>
                                        <Descriptions.Item span={2}
                                            label="案件ID">{caseBaseInfo.caseId || ''}</Descriptions.Item>
                                        <Descriptions.Item span={2} label="原告"> {isInfo ?
                                            <Form.Item
                                                style={{ width: "100%", marginTop: "-10px", marginBottom: "-10px" }}
                                                name="creditorName"
                                                initialValue={companyInfo.creditorName ? companyInfo.creditorName : undefined}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请输入内容'
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    style={{ width: "100%" }}
                                                />
                                            </Form.Item> : companyInfo.creditorName || ''
                                        }</Descriptions.Item>
                                        <Descriptions.Item span={2}
                                            label="申请人类型">{caseBaseInfo.applyUserType == '1' ? '律师' : companyInfo.applyUserType == '0' ? '当事人' : companyInfo.applyUserType == '2' ? '合作商' : ''}</Descriptions.Item>
                                        <Descriptions.Item span={2} label="被告">{isInfo ?
                                            <Form.Item
                                                style={{ width: "100%", marginTop: "-10px", marginBottom: "-10px" }}
                                                name="debtorName"
                                                initialValue={companyInfo.debtorName ? companyInfo.debtorName : undefined}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请输入内容'
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    style={{ width: "100%" }}
                                                />
                                            </Form.Item> : companyInfo.debtorName || ''
                                        }</Descriptions.Item>

                                        <Descriptions.Item span={2}
                                            label="申请人姓名">{caseBaseInfo.applyUserName || ''}</Descriptions.Item>
                                        <Descriptions.Item span={2} label="本金">{isInfo ?
                                            <Form.Item
                                                style={{ width: "100%", marginTop: "-10px", marginBottom: "-10px" }}
                                                name="capitalAmt"
                                                initialValue={companyInfo.interestAmt ? companyInfo.capitalAmt : companyInfo.caseAmt}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请输入内容'
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    style={{ width: "100%" }}
                                                />

                                            </Form.Item> : companyInfo.interestAmt ? companyInfo.capitalAmt : companyInfo.caseAmt
                                        }</Descriptions.Item>

                                        <Descriptions.Item span={2}
                                            label="手机号">{caseBaseInfo.mobileNo || ''}</Descriptions.Item>
                                        <Descriptions.Item span={2} label="利息">{isInfo ?
                                            <Form.Item
                                                style={{ width: "100%", marginTop: "-10px", marginBottom: "-10px" }}
                                                name="interestAmt"
                                                initialValue={companyInfo.interestAmt ? companyInfo.interestAmt : undefined}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请输入内容'
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    style={{ width: "100%" }}
                                                />
                                            </Form.Item> : companyInfo.interestAmt || ''
                                        }</Descriptions.Item>

                                        <Descriptions.Item span={2}
                                            label={caseBaseInfo.idType == '1' ? '身份证号' : companyInfo.idType == '2' ? '营业执照' : '证件号'}>{caseBaseInfo.idNo}</Descriptions.Item>
                                        <Descriptions.Item span={2} label="案件标的">{isInfo ?
                                            <Form.Item
                                                style={{ width: "100%", marginTop: "-10px", marginBottom: "-10px" }}
                                                name="caseAmt"
                                                initialValue={companyInfo.caseAmt || undefined}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请输入内容'
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    style={{ width: "100%" }}
                                                />
                                            </Form.Item> : companyInfo.caseAmt || ''
                                        }</Descriptions.Item>

                                        <Descriptions.Item span={2}
                                            label="是否有外部律师">{caseBaseInfo.isOuterLawyer == 'Y' ? '是' : caseBaseInfo.isOuterLawyer == 'N' ? '否' : '否'}</Descriptions.Item>
                                        <Descriptions.Item span={2}
                                            label="案由">

                                            {isInfo ?
                                                <Form.Item
                                                    style={{
                                                        width: "100%",
                                                        marginTop: "-10px",
                                                        marginBottom: "-10px"
                                                    }}
                                                    name="caseReasonName"
                                                    initialValue={this.state.value || companyInfo.caseReasonName}
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: '请输入内容'
                                                        }
                                                    ]}
                                                >
                                                    <Select
                                                        style={{ width: "100%" }}
                                                        showSearch
                                                        allowClear
                                                        placeholder="请输入"
                                                        // defaultActiveFirstOption={false}
                                                        showArrow={true}
                                                        filterOption={false}
                                                        onSearch={this.handleSearch}
                                                        onChange={this.handleChange}
                                                        notFoundContent={null}
                                                    >
                                                        {this.state.data.map((v) => (
                                                            <Option key={v.caseReasonName}
                                                                value={v.caseReasonName}>
                                                                {v.caseReasonName}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item> :
                                                companyInfo.caseReasonName

                                            }
                                        </Descriptions.Item>

                                        <Descriptions.Item span={2} label="合作模式">
                                            {isInfo1 ?
                                                <Form.Item
                                                    style={{
                                                        width: "100%",
                                                        marginTop: "-10px",
                                                        marginBottom: "-10px"
                                                    }}
                                                    name="caseBizMode"
                                                    initialValue={caseBaseInfo.caseBizMode || undefined}
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: '请选择合作模式'
                                                        }
                                                    ]}
                                                >
                                                    <Select
                                                        placeholder="请选择"
                                                        allowClear
                                                        style={{ width: "100%" }}
                                                    >
                                                        <Option key='1' value={1}>诉讼</Option>
                                                        <Option key='3' value={3}>执行</Option>
                                                        <Option key='5' value={5}>债权收购</Option>
                                                        <Option key='6' value={6}>非诉清收</Option>
                                                    </Select>
                                                </Form.Item> : {
                                                    '1': '诉讼',
                                                    '3': '执行',
                                                    '6': '非诉清收',
                                                    '5': '债权收购',
                                                }[caseBaseInfo.caseBizMode]
                                            }

                                        </Descriptions.Item>
                                        <Descriptions.Item span={2} label="案件阶段">
                                            {isInfo ?
                                                <Form.Item
                                                    style={{
                                                        width: "100%",
                                                        marginTop: "-10px",
                                                        marginBottom: "-10px"
                                                    }}
                                                    name="caseStageId"
                                                    initialValue={companyInfo.caseStageId || undefined}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '请选择案件来源'
                                                        }
                                                    ]}
                                                >
                                                    <Select
                                                        placeholder="请选择"
                                                        allowClear
                                                        style={{ width: "100%" }}
                                                        onChange={this.caseStageIdFun}
                                                    >
                                                        <Option key='1' value={1}>未起诉</Option>
                                                        <Option key='2' value={2}>已起诉尚未判决</Option>
                                                        <Option key='3' value={3}>一审</Option>
                                                        <Option key='4' value={4}>二审</Option>
                                                        <Option key='5' value={5}>已判决尚未执行</Option>
                                                        <Option key='6' value={6}>正在强制执行</Option>
                                                        <Option key='7' value={7}>终结本次执行</Option>
                                                        <Option key='8' value={8}>商事仲裁中</Option>
                                                        <Option key='9' value={9}>其他</Option>
                                                    </Select>
                                                </Form.Item> : {
                                                    '1': '未起诉',
                                                    '2': '已起诉尚未判决',
                                                    '3': '一审',
                                                    '4': '二审',
                                                    '5': '已判决尚未执行',
                                                    '6': '正在强制执行',
                                                    '7': '终结本次执行',
                                                    '8': '商事仲裁中',
                                                    '9': '其他',
                                                }[companyInfo.caseStageId]
                                            }
                                        </Descriptions.Item>

                                        <Descriptions.Item span={2} label="案件归属">{{
                                            '1': '赢火虫',
                                            '2': '法智特',
                                        }[caseBaseInfo.caseBelong]}</Descriptions.Item>
                                        <Descriptions.Item span={2} label="执行案号">{isInfo ?
                                            <Form.Item
                                                style={{ width: "100%", marginTop: "-10px", marginBottom: "-10px" }}
                                                name="caseNo"
                                                initialValue={companyInfo.caseNo || undefined}
                                                rules={[
                                                    {
                                                        required: this.state.isTrue1,
                                                        message: '请输入执行案号'
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    style={{ width: "100%" }}
                                                />
                                            </Form.Item> : companyInfo.caseNo || ''
                                        }</Descriptions.Item>

                                        <Descriptions.Item span={2} label="案件类型">{{
                                            '1': '一般案件',
                                            '2': '诊断案件',
                                        }[caseBaseInfo.diagnosisSign]}</Descriptions.Item>
                                        <Descriptions.Item span={2} label="诉讼案号">{isInfo ?
                                            <Form.Item
                                                style={{ width: "100%", marginTop: "-10px", marginBottom: "-10px" }}
                                                name="referCaseNo"
                                                initialValue={companyInfo.referCaseNo || undefined}
                                                rules={[
                                                    {
                                                        required: this.state.isTrue2,
                                                        message: '请输入执行案号'
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    style={{ width: "100%" }}
                                                />
                                            </Form.Item> : companyInfo.referCaseNo || ''
                                        }</Descriptions.Item>

                                        <Descriptions.Item span={2}
                                            label="案件来源">{filter.appidOnlineType(caseBaseInfo.appid)}</Descriptions.Item>
                                        <Descriptions.Item span={2} label="管辖法院">{isInfo ?
                                            <Form.Item
                                                style={{ width: "100%", marginTop: "-10px", marginBottom: "-10px" }}
                                                name="courtName"
                                                initialValue={companyInfo.courtName || undefined}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请输入内容'
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    style={{ width: "100%" }}
                                                />
                                            </Form.Item> : companyInfo.courtName || ''
                                        }</Descriptions.Item>

                                        <Descriptions.Item span={2}
                                            label="渠道代码">{caseBaseInfo.channelCode}</Descriptions.Item>
                                        <Descriptions.Item span={2} label="代理站位">              {isInfo ?
                                            <Form.Item
                                                style={{ width: "100%", marginTop: "-10px", marginBottom: "-10px" }}
                                                name="agentSide"
                                                initialValue={companyInfo.agentSide || undefined}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请选代理站位'
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    placeholder="请选代理站位"
                                                    allowClear
                                                    style={{ width: "100%" }}
                                                >
                                                    <Option key='Y'>原告</Option>
                                                    <Option key='B'>被告</Option>
                                                </Select>
                                            </Form.Item> : companyInfo.agentSide == "Y" ? "原告" : "被告"
                                        }</Descriptions.Item>

                                        <Descriptions.Item span={2}
                                            label="申请时间">{filter.date(caseBaseInfo.applyDate) + ' ' + filter.time(companyInfo.applyTime)}</Descriptions.Item>
                                        <Descriptions.Item span={2}
                                            label="案件等级">{companyInfo.caseLevel || ''}</Descriptions.Item>

                                        <Descriptions.Item span={2}
                                            label="案件状态">{companyInfo.caseStatusDese || ''}</Descriptions.Item>
                                        <Descriptions.Item span={2}
                                            label="案件类别标签">{filter.caseKindTag(companyInfo.caseKindTag) || ''}</Descriptions.Item>

                                    </Descriptions>
                                </Panel>
                                {(caseBaseInfoVo.isRelevance || '') && <Panel header='案情简介/地址信息/管理信息' key="2">
                                    <Row type="flex" justify="space-around">
                                        <Col span={7}>
                                            <Card bordered={false}>
                                                <Card type="inner"
                                                    title={<span style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}>案情简介（用户可见）</span>}
                                                >
                                                    <div style={{ padding: '8px 4px' }}>
                                                        <Descriptions>
                                                            <Descriptions.Item
                                                                span={3}>   {companyInfo.caseMemo || '暂无'}  </Descriptions.Item>
                                                        </Descriptions>
                                                    </div>
                                                </Card>
                                            </Card>
                                            {/* <Card bordered={false}>
                                                <Card type="inner"
                                                    title={<span style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}>备注（内部可见）</span>}
                                                >
                                                    <Descriptions>
                                                        <Descriptions.Item
                                                            span={3}> {companyInfo.riskMemo || '暂无'} </Descriptions.Item>
                                                    </Descriptions>
                                                </Card>
                                            </Card> */}
                                        </Col>
                                        <Col span={7}>
                                            <Card bordered={false}>
                                                <Card type="inner"
                                                    title={<span style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}>地址信息</span>}
                                                >
                                                    <Descriptions bordered={caseLbsInfos.length}>
                                                        {
                                                            caseLbsInfos.length ? caseLbsInfos.map((v, i) => {
                                                                return <Descriptions.Item label={caseLbsInfosList[v.lbsSign]} span={3}>
                                                                    {v.province + ' / ' +
                                                                        v.city + ' / ' +
                                                                        v.county}

                                                                </Descriptions.Item>


                                                            }) : <Descriptions.Item span={3}>
                                                                暂无

                                                            </Descriptions.Item>

                                                        }
                                                    </Descriptions>
                                                </Card>
                                            </Card>
                                        </Col> <Col span={7}>
                                            <Card bordered={false}>
                                                <Card type="inner"
                                                    title={<span style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}>管理信息</span>}
                                                >
                                                    <Descriptions bordered>
                                                        <Descriptions.Item label="投资经理/电销/案源律师"
                                                            span={3}>  {caseBaseInfoVo.saleOperName ||
                                                                caseBaseInfoVo.oldSaleOperName ||
                                                                caseBaseInfoVo.rmDealName ||
                                                                caseBaseInfoVo.rmOperName
                                                                ? (caseBaseInfoVo.saleOperName || ` `) +
                                                                (caseBaseInfoVo.oldSaleOperName
                                                                    ? `(${caseBaseInfoVo.oldSaleOperName})`
                                                                    : " ") +
                                                                "/" +
                                                                (caseBaseInfoVo.callOperName || ` `) +
                                                                "/" +
                                                                (caseBaseInfoVo.serviceOperName || ` `)
                                                                : ""}</Descriptions.Item><Descriptions.Item
                                                                    label="风控负责人/承办人/操作员"
                                                                    span={3}> {caseBaseInfoVo.rmChargerName || caseBaseInfoVo.rmDealName || caseBaseInfoVo.rmOperName
                                                                        ? (caseBaseInfoVo.rmChargerName || ` `) +
                                                                        "/" +
                                                                        (caseBaseInfoVo.rmDealName || ` `) +
                                                                        "/" +
                                                                        (caseBaseInfoVo.rmOperName || ` `)
                                                                        : ""}</Descriptions.Item><Descriptions.Item label="项目总监"
                                                                            span={3}>
                                                            {caseBaseInfoVo.lcOperName || ""}</Descriptions.Item>
                                                        <Descriptions.Item label="跟踪负责人"
                                                            span={3}>{caseBaseInfoVo.signChargerName || ` `}
                                                        </Descriptions.Item><Descriptions.Item label="运营商" span={3}>
                                                            {caseBaseInfoVo.brokerName || ""}
                                                        </Descriptions.Item>
                                                    </Descriptions> </Card>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Panel>}
                                {(caseBaseInfoVo.isRelevance || '') && <Panel header='申请资料附件' key="3">
                                    {/* <Row justify="start" align="middle">
                                        <Col span="8">
                                            <Form layout='horizontal'>
                                                <Form.Item label="资料类别：" {...formItemLayout}
                                                    style={{ width: "100%" }}
                                                    name="docKind"
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: '资料类别名称不能为空'
                                                        }
                                                    ]}
                                                >
                                                    <Select style={{ width: "80%" }} placeholder="请选择资料类别"
                                                        allowClear
                                                        onChange={this.SelectChange}>
                                                        {this.state.docKindOption.map((v, i) =>
                                                            <Option value={v.docKindId + ''}
                                                                disabled={v.status == '0' ? false : true}
                                                                key={i}>{v.docKindName}</Option>
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </Form>
                                        </Col>
                                        <Col span="8" push="1">
                                            <Ossupload
                                                callbackData={{ test: "123" }}
                                                callback={async (
                                                    filename,
                                                    file,
                                                    info,
                                                    callbackData
                                                ) => {
                                                    if (info.status == 200) {
                                                        try {
                                                            let send = {
                                                                docUrl: window.winhc_oss_url + filename,
                                                                docKindId: docKind_applyDoc,
                                                                caseId: detailCustId,
                                                            };

                                                            try {
                                                                let res = await Axios(
                                                                    "post",
                                                                    "/caseBase/doc",
                                                                    send
                                                                );
                                                                console.log("上传文件==", res);
                                                                if (res.code === "200") {
                                                                    message.success(
                                                                        res.message
                                                                    );
                                                                    this.getData(); // 刷新列表数据;
                                                                } else {
                                                                    message.error(res.message);
                                                                }
                                                            } catch (error) {
                                                                console.log(error);
                                                            }
                                                        } catch (err) {
                                                            console.log("err", err);
                                                            message.error(err.message);
                                                        }
                                                    }
                                                }}
                                                beforeUpload={(file, up) => {
                                                    console.log(file, up);
                                                    if (file.size > 1024 * 1024 * 10 * 30) {
                                                        message.error("请选择小于300MB文件！");
                                                        return false;
                                                    }
                                                }}
                                            >

                                                <Button
                                                    disabled={this.state.uploadDisabled}
                                                    loading={this.state.upLoadLoading}
                                                    style={{}}
                                                >
                                                    <UploadOutlined /> 上传文件
                                                </Button>

                                            </Ossupload>
                                        </Col>
                                        {console.log(this.fun(this.state.docKindOption, this.state.caseDocList), 'caseDocListcaseDocList')}
                                    </Row> */}
                                    <Row justify="start" align="middle">
                                        {this.fun(this.state.docKindOption, this.state.caseDocList).map((v) => {
                                            return <div>
                                                <p style={{
                                                    margin: '15px',
                                                    paddingLeft: '7px',
                                                    borderLeft: '3px solid #6b6b6b',

                                                }}>
                                                    {v.Name}
                                                </p>
                                                <FlieList
                                                    fileList={v.Url}
                                                    QuanXian={!(v.Name == '风险投合同')}
                                                    close={async (url, index, id) => {
                                                        console.log(url, index, id)
                                                        try {
                                                            let res = await Axios(
                                                                "delete",
                                                                "/caseBase/doc",

                                                                {
                                                                    docUrl: url,
                                                                    id: id,
                                                                    caseId: detailCustId,
                                                                }
                                                            );
                                                            if (res.code === "200") {
                                                                message.success(res.message);
                                                                this.getData(); // 刷新列表数据;

                                                            } else {
                                                                message.error(res.message);
                                                            }
                                                        } catch (err) {
                                                            message.error(err.message);
                                                        }
                                                    }}
                                                />
                                            </div>

                                        })}
                                    </Row>
                                </Panel>}
                                {1 && <Panel header='风险投开票信息' key="7">
                                    <Table
                                        dataSource={BillTableList || []}
                                        rowKey={'id'}
                                        columns={[
                                            {
                                                title: "发票ID",
                                                dataIndex: "id",
                                                width: "60",
                                            },
                                            {
                                                title: "发票号",
                                                dataIndex: "invoiceNo",
                                                width: "100",
                                            },
                                            {
                                                title: "发票日期",
                                                dataIndex: "invoiceDate",
                                                width: "100",
                                                render: (text, record, index) => <span>{Filter.date(text)}</span>,
                                            },
                                            {
                                                title: "发票状态",
                                                dataIndex: "status",
                                                width: "100",
                                                render: (t, r) => Filter.InvoiceStatus(t),
                                            },
                                            {
                                                title: "发票地址",
                                                dataIndex: "downloadUrl",
                                                width: "80",
                                                render: (t, r) => (
                                                    <a href={t} target="_blank">
                                                        {t ? "查看" : "-"}
                                                    </a>
                                                ),
                                            },

                                            {
                                                title: "开票公司",
                                                dataIndex: "invoiceCompanyName",
                                            },
                                            {
                                                title: "发票类型",
                                                dataIndex: "invoiceType",
                                                width: "120",
                                                render: (t, r) => Filter.InvoiceType(t),
                                            },
                                            {
                                                title: "金额（元）",
                                                dataIndex: "invoiceAmt",
                                                width: "130",
                                            },
                                            {
                                                title: "公司名称",
                                                dataIndex: "companyName",
                                                render: (t, r) => (
                                                    <Popover
                                                        content={
                                                            <div
                                                                style={{
                                                                    color: "#222",
                                                                    padding: "3px",
                                                                    textAlign: "left",
                                                                    lineHeight: "24px",
                                                                }}
                                                            >
                                                                <div>公司账号：{r.companyAccountNo}</div>
                                                                <div>开户银行：{r.openBank}</div>
                                                                <div>公司税号：{r.taxNo}</div>
                                                                <div>开票地址：{r.invoiceAddress}</div>
                                                                <div>联系方式：{r.tel}</div>
                                                            </div>
                                                        }
                                                    >
                                                        <span style={{ color: "#108ee9", cursor: "pointer" }}>{t}</span>
                                                    </Popover>
                                                ),
                                            },
                                            {
                                                title: "开票对象",
                                                dataIndex: "invoiceTarget",
                                                render: (t, r) => Filter.invoiceTarget(t),
                                            },
                                            {
                                                title: "关联收入",
                                                dataIndex: "riskIncomes",
                                                render: (t, r) => (t || []).length ? (
                                                    <div>
                                                        {(t || []).map(v => (
                                                            <div>
                                                                ID：{v.id} 金额：{v.incomeAmt}
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : ("")
                                            },
                                        ]}
                                        size="middle"
                                        pagination={
                                            false
                                        }
                                    />
                                </Panel>}
                                {/* <Panel header='开发记录' key="4">
                                </Panel>
                                <Panel header={<span
                                    style={{ width: "100%", display: "flex", justifyContent: 'space-between' }}>
                                    <span>工作记录</span><span> <span
                                        style={{ marginRight: "20px", color: "#628ffe" }}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                        }}
                                    >     <AddBroker title='添加工作记录' getData={this.getData} />    </span>
                                    </span>
                                </span>} key="5">
                                    <Table
                                        dataSource={legalCloudOperHis}
                                        rowKey={'id'}
                                        columns={[
                                            // bizId: "2204141648120009"
                                            // bizType: "workRecord"
                                            // brokerUserId: 137
                                            // id: 50
                                            // legalCloudId: "2204141648120009"
                                            // memo: "添加工作记录"
                                            // operAction: "添加工作记录"
                                            // operContent: "0000000"
                                            // operDate: "20220418"
                                            // operName: "测试帅1"
                                            // operTime: "142625"
                                            // pageNum: null
                                            // pageSize: null
                                            {
                                                title: "时间",
                                                dataIndex: "operDate",
                                                width: "80px",
                                                render: (text, record, index) => filter.date(record.operDate) + '  ' + filter.time(record.operTime)
                                            },
                                            {
                                                title: "内容",
                                                dataIndex: "operContent",
                                                width: "80px",
                                            },
                                            {
                                                title: "记录人",
                                                dataIndex: "operName",
                                                width: "80px",
                                            },
                                            {
                                                title: "操作",
                                                dataIndex: "id",
                                                width: "80px",
                                                render: (text, record, index) => <>
                                                    <Popconfirm title="你确定要删除吗?"
                                                        onConfirm={() => Axios('delete', '/caseBase/workRecord/' + record.id, {}).then((res) => {
                                                            if (res.code === '200') {
                                                                message.success(res.message);
                                                                this.getData();

                                                            } else {

                                                            }
                                                        })}
                                                        okText="是"
                                                        cancelText="否"><a>删除</a></Popconfirm>
                                                    &nbsp;&nbsp;|&nbsp;&nbsp;

                                                    <AddBroker record={record} getData={this.getData} title='编辑' />
                                                </>
                                            },

                                        ]}
                                        size="middle"
                                        pagination={
                                            false
                                        }
                                    />
                                </Panel>
                                <Panel header='操作记录' key="6">
                                    <Tables code={7} detailCustId={this.props.match.params.id} />
                                </Panel> */}
                            </Collapse>
                        </Form>

                    </div>
                    <Modal title="案件分享"
                        width={800}
                        footer={null}
                        open={this.state.Modal_anjianfengxiang} onCancel={() => {
                            this.setState({ Modal_anjianfengxiang: false })
                        }} confirmLoading={this.state.confirmLoading}>
                        <div style={{ overflow: 'hidden' }}>
                            <Form layout='inline'
                                style={{ marginBottom: '24px', position: 'relative', textAlign: 'right' }} ref={this.formRef1}>
                                <Button style={{ marginRight: '20px' }} onClick={() => {
                                    this.formRef1.current.
                                        validateFields(['shareMobileNo', 'shareUserName'])
                                        .then(async (values) => {
                                            let This = this;
                                            let send = {
                                                caseId: detailCustId,
                                                mobileNo: values.shareMobileNo,
                                                userName: values.shareUserName,
                                                // userId: This.state.shareApplyUserId
                                            }
                                            try {
                                                let res = await Axios(
                                                    "post",
                                                    "/caseBase/add/share",
                                                    send
                                                );
                                                console.log(res)
                                                if (res.code === "00") {
                                                    message.success(res.msg);
                                                    this.getShareCaseInfo()
                                                } else {
                                                    message.error(res.message);
                                                }
                                            } catch (err) {
                                                // message.error(err);
                                            }
                                        })
                                        .catch((info) => {
                                            console.log('Validate Failed:', info)
                                        })

                                }} type="primary">添加</Button>
                                <Form.Item label="手机号：" style={{ position: 'absolute', left: '0', top: '0', }}
                                    name="shareMobileNo"
                                    rules={[{
                                        required: true,
                                        message: '手机号不能为空'
                                    }, {
                                        message: '律师手机号不合法',
                                        pattern: /^((1[0-9]{2})+\d{8})$/
                                    }]}
                                >
                                    <Input placeholder="请输入手机号" style={{ width: 220 }}
                                        onChange={this.share_mobileNoCheck}
                                        type="text" maxLength='11' />
                                </Form.Item>
                                <Form.Item label="姓名：" style={{ position: 'absolute', left: '300px', top: '0', }}
                                    name="shareUserName"
                                    rules={[{
                                        required: true,
                                        message: '姓名不能为空'
                                    }]}
                                >
                                    <Input type="text" placeholder="请输入姓名" style={{ width: 220 }}
                                    />
                                </Form.Item>
                            </Form>
                        </div>
                        <Table
                            dataSource={this.state.shareCaseInfoList}
                            columns={[
                                {
                                    key: '1',
                                    title: '手机号',
                                    dataIndex: 'mobileNo',
                                    render: (text, record, index) => (
                                        <span>
                                            {text}
                                        </span>
                                    )
                                }, {
                                    key: '2',
                                    title: '用户名',
                                    dataIndex: 'userName',
                                    render: (text, record, index) => (
                                        <span>
                                            {text}
                                        </span>
                                    )
                                }, {
                                    key: '3',
                                    title: '是否是管理员',
                                    dataIndex: 'isManager',
                                    render: (text, record, index) => (
                                        <span>
                                            {text == 'Y' ? "是" : text == 'N' ? "否" : ""}
                                        </span>
                                    )
                                }, {
                                    key: '4',
                                    title: '是否可以接受消息',
                                    dataIndex: 'isReceiver',
                                    render: (text, record, index) => (
                                        <span>
                                            {text == 'Y' ? "是" : text == 'N' ? "否" : ""}
                                        </span>
                                    )
                                }, {
                                    key: '5',
                                    title: '分享者标志',
                                    dataIndex: 'shareSign',
                                    render: (text, record, index) => (
                                        <span>
                                            {text == '1' ? "用户分享" : text == '2' ? "后台添加" : ""}
                                        </span>
                                    )
                                }, {
                                    title: '操作',
                                    dataIndex: 'id',
                                    render: (t, r) => (
                                        <span>
                                            <Popconfirm title="是否确认删除该记录?" onConfirm={async () => {
                                                let This = this;

                                                try {
                                                    let res = await Axios(
                                                        "delete",
                                                        "/caseBase/share/" + t,
                                                        {}
                                                    );
                                                    console.log(res)
                                                    if (res.code === "00") {
                                                        message.success(res.msg);
                                                        This.getShareCaseInfo();
                                                    } else {
                                                        message.error(res.message);
                                                    }
                                                } catch (err) {
                                                    // message.error(err);
                                                }
                                            }} onCancel={() => {
                                            }} okText="确认" cancelText="取消">
                                                <span style={{ color: '#108ee9', cursor: 'pointer' }}>删除</span>
                                            </Popconfirm>
                                        </span>)
                                }]}
                            size="middle"
                            pagination={false}
                            loading={this.state.Tableloading}
                            bordered={true}
                        />
                    </Modal>

                </div >
                <style>{`
                .ant-collapse.ant-collapse-icon-position-start{
                    width: 100%;
                }
                .ant-collapse-item.ant-collapse-item-active{
                    width: 100%;
                }
                  .specialMenuStyle .ant-dropdown-menu-item{
                        padding:0px 8px;
                    }
               .ant-card-body{
                    padding: 0px !important;
                }.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
      width: 30%;
} 
.ant-row-flex-space-around .ant-descriptions-bordered .ant-descriptions-item-label {
    width: 30%;
}
.ant-row-flex-space-around .ant-descriptions-bordered .ant-descriptions-item-label, .ant-descriptions-bordered .ant-descriptions-item-content {
    padding: 16px 16px!important;
    border-right: 1px solid #e8e8e8;
}
.ant-row-flex-space-around .ant-descriptions-bordered .ant-descriptions-item-label, .ant-descriptions-bordered .ant-descriptions-item-content {
   width: 30%;
}
                `}</style>
            </div >
        )
            ;
    }
}

let
    MainN = MainM;
let
    Main = connect((state) => {
        return state;
    }
        ,
        (dispatch) => (
            {
                actions: bindActionCreators(dispatch),
            }
        )
    )(MainN);
export default Main;

class AddBrokerM
    extends Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            selectOption: [],
            loading: false,
            confirmLoading: false,
        };
    }


    componentWillMount () {

    }

    // 单击确定按钮提交表单
    handleSubmit = (e) => {
        e.preventDefault();
        let arr = ['applyTime', 'operContent'];
        this.formRef.current.
            validateFields(arr)
            .then(async (values) => {
                if (values.applyTime) {
                    values.workTime = values.applyTime.format("YYYYMMDDHHmmss")
                    delete values.applyTime
                }
                values.caseId = detailCustId
                try {
                    this.setState({ confirmLoading: true })
                    let method = this.props.title == '编辑' ? 'put' : 'post'
                    let url = this.props.title == '编辑' ? '/caseBase/workRecord/' + this.props.record.id : '/caseBase/save/workRecord'
                    let res = await Axios(method, url, values)
                    if (res.code == '200') {
                        this.props.getData();
                        this.formRef.current.resetFields()
                        message.success(res.message);
                        this.setState({ visible: false, confirmLoading: false, province: '', city: '' });
                    } else {
                        message.error(res.message);
                        this.setState({ confirmLoading: false });
                    }

                } catch (err) {
                    message.error(err.message);
                    this.setState({ confirmLoading: false })

                }
            })
            .catch((info) => {
                console.log('Validate Failed:', info)
            })
    }
    // 弹出框设置
    showModal = () => {
        this.setState({ visible: true });
    }
    handleCancel = (e) => {
        this.setState({ visible: false });
    }
    handleSelectChange = (value) => {
        console.log("select=" + value);
    }

    render () {
        const formItemLayout = {
            labelCol: { span: 5 },
            wrapperCol: { span: 17 },
        };
        return (
            <span>
                <a type="primary" icon="plus" onClick={() => {
                    this.setState({ visible: true })
                }}>   {this.props.title}</a>
                <Modal
                    title="添加工作记录"
                    open={this.state.visible}
                    onOk={this.handleSubmit}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={() => {
                        this.setState({ visible: false })
                    }}
                >
                    <Form layout='horizontal' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} ref={this.formRef}>
                        <Form.Item {...formItemLayout} label="时间："
                            name="applyTime"
                            initialValue={this.props.record ? moment(filter.date(this.props.record.operDate) + '  ' + filter.time(this.props.record.operTime), 'YYYY-MM-DD HH-mm-ss') : undefined}
                            rules={[
                                {
                                    required: true,
                                    message: '选择时间'
                                }
                            ]}
                        >

                            <DatePicker
                                allowClear
                                style={{ width: "80%" }}
                                showTime={{ format: 'HH:mm:ss' }}
                                format="YYYY-MM-DD HH:mm:ss"
                                placeholder='选择时间'
                            />
                        </Form.Item>
                        <Form.Item {...formItemLayout} label="内容："
                            name="operContent"
                            initialValue={this.props.record ? this.props.record.operContent : undefined}
                            rules={[
                                {
                                    required: true,
                                    message: '请输入内容'
                                }
                            ]}
                        >
                            <TextArea rows={4} placeholder="请输入内容" style={{ width: "80%" }}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </span>
        )
    }
}

let AddBroker = connect((state) => {
    return state;
}
    ,
    (dispatch) => (
        {}
    )
)(AddBrokerM);
