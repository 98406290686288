import React, { Component, useState } from "react";
import {
    Button,
    Modal,
    Row,
    Breadcrumb,
    Select,
    Card,
    Tabs,
    Avatar,

    Dropdown,
    UploadFile,
    message,
    Menu,
    Form, Col,
    Input,
} from "antd";
import { AreaSelect, popoverTable } from "../../common";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Axios } from "../../axios";
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import headPhoto from "../../assets/images/headPhoto.png";
import md5 from "js-md5";
const { Option } = Select;
const { TabPane } = Tabs;

let sendBody = {};

const tabList = [
    {
        name: "个人信息",
        icon: <UserOutlined />,
        components: "1",
    },
    {
        name: "安全设置",
        icon: <LockOutlined />,
        components: "2",
    },
];
class System extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tData: [],
            loading: false,
            totalNum: 0,
            states: "0",
        };
    }
    render() {
        return (
            <div className="pageContainer">
                <Row className="headNav">
                    <Col span={24}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>系统设置</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col className="title" span={24}>
                        <span className="name">系统设置</span>
                    </Col>
                </Row>

                <div className="scrollContainer">
                    <Card className="w-full h-full">
                        <Tabs
                            tabPosition="left"
                            activeKey={this.state.states}
                            destroyInactiveTabPane={true}
                            onChange={(index) => {
                                console.log(index);
                                this.setState({
                                    states: index,
                                });
                            }}
                        >
                            {tabList.map((item, index) => (
                                <TabPane
                                    tab={
                                        <span>
                                            {item.icon}
                                            {item.name}
                                        </span>
                                    }
                                    key={index}
                                >
                                    {item.components == "1" ? (
                                        <PersonInfo />
                                    ) : (
                                        <SecuritySetting />
                                    )}
                                </TabPane>
                            ))}
                        </Tabs>
                    </Card>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return state;
    },
    (dispatch) => ({})
)(System);

class SecuritySettingM extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            ChangeType: "",
            type: 1,
            canGetCode: true,
            seconds: 60, //称数初始化
        };
    }
    getCode = async () => {
        const operInfo = this.props.operInfo;
        try {
            this.setState({ canGetCode: false });
            let res = await Axios(
                "get",
                "/login/open/sendCheckNo",
                {
                    mobileNo: operInfo.mobileNo,
                    smsKind: "checkNo",
                },
                "login"
            );
            if (res.isSuccess === "T") {
                message.success(
                    `验证码已发送到手机 ${operInfo.mobileNo.substr(
                        0,
                        3
                    )}****${operInfo.mobileNo.substr(7, 4)}`
                );
                let siv = setInterval(() => {
                    this.setState(
                        {
                            seconds: this.state.seconds - 1,
                        },
                        () => {
                            if (this.state.seconds == 0) {
                                clearInterval(siv);
                                this.setState({
                                    seconds: 60,
                                    canGetCode: true,
                                });
                            }
                        }
                    );
                }, 1000);
            } else {
                this.setState({
                    canGetCode: true,
                });
                message.error(res.errorMsg);
            }
        } catch (err) {
            console.log(err);
            this.setState({
                canGetCode: true,
            });
        }
    };

    setPassword = async () => {
        this.formRef.current.
            validateFields()
            .then(async (values) => {
                const operInfo = this.props.operInfo;
                console.log(this.state.type, values);
                const params = {
                    setupType: "loginPwd",
                    mobileNo: operInfo.mobileNo,
                };
                if (this.state.type) {
                    params.oldPwd = md5(
                        values.oldPwd.toUpperCase() + window.md5Key
                    );
                    params.newPwd = md5(
                        values.newPwd.toUpperCase() + window.md5Key
                    );
                } else {
                    params.newPwd = md5(
                        values.newPwd.toUpperCase() + window.md5Key
                    );
                    params.mobileCheckNo = values.mobileCheckNo;
                }
                console.log(params);
                try {
                    this.setState({ confirmLoading: true });

                    let res = await Axios(
                        "post",
                        "/login/setupUserInfo",
                        params,
                        "login"
                    );
                    if (res.isSuccess === "T") {
                        message.success("修改成功");
                        this.setState({
                            visible: false,
                            confirmLoading: false,
                        });
                        this.formRef.current.resetFields();
                    } else {
                        message.error(res.errorMsg);
                        this.setState({
                            confirmLoading: false,
                        });
                    }
                } catch (err) {
                    message.error(err.errorMsg);
                    this.setState({ confirmLoading: false });
                }
            })
            .catch((info) => {
                console.log('Validate Failed:', info)
            })
    };
    render() {
        const data = this.props.record;
        const operInfo = this.props.operInfo;
        console.log(this.props.operInfo, "this.props.operInfo");
        return (
            <div className="System">
                <div>
                    {" "}
                    <h1>安全设置</h1>
                </div>{" "}
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingBottom: 0,
                    }}
                >
                    <p>
                        {" "}
                        <h1 className="text-base font-bold">登录密码</h1>
                        <p>********</p>
                    </p>
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item
                                    onClick={() =>
                                        this.setState({
                                            visible: true,
                                            type: 1,
                                        })
                                    }
                                >
                                    {" "}
                                    通过旧密码
                                </Menu.Item>
                                <Menu.Item
                                    onClick={() =>
                                        this.setState({
                                            visible: true,
                                            type: 0,
                                        })
                                    }
                                >
                                    通过手机号
                                </Menu.Item>
                            </Menu>
                        }
                    >
                        <Button>修改</Button>
                    </Dropdown>
                </div>{" "}
                <Modal
                    title="修改密码"
                    open={this.state.visible}
                    onCancel={() => {
                        this.setState({
                            visible: false,
                        });
                        this.formRef.current.resetFields();
                    }}
                    footer={false}
                >
                    <Form
                        ref={this.formRef}
                        name="basic"
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 20 }}
                        initialValues={{ remember: true }}
                        autoComplete="off"
                    >
                        {this.state.type ? (
                            <div>
                                <Form.Item
                                    label="旧密码："
                                    style={{ width: "100%" }}
                                    name="oldPwd"
                                    rules={[
                                        {
                                            required: true,
                                            message: '旧密码不能为空'
                                        }
                                    ]}
                                >
                                    <Input.Password
                                        placeholder="请输入旧密码"
                                        type="text"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="新密码："
                                    style={{ width: "100%" }}
                                    name="newPwd"
                                    rules={[
                                        {
                                            required: true,
                                            message: "新密码不能为空",
                                        },
                                        {
                                            message:
                                                "密码必须英文字母大写、小写，数字及特殊符号构成，必须满足3种类型，8-20位!",
                                            pattern:
                                                /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_]+$)(?![a-z0-9]+$)(?![a-z\\W_]+$)(?![0-9\\W_]+$)[a-zA-Z0-9\\W_]{8,20}$/,
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        placeholder="请输入新密码（8-20位）"
                                        type="text"
                                    />
                                </Form.Item>
                            </div>
                        ) : (
                            <div>
                                <Form.Item
                                    label="新密码："
                                    style={{ width: "100%" }}
                                    name="newPwd"
                                    rules={[
                                        {
                                            required: true,
                                            message: "新密码不能为空",
                                        },
                                        {
                                            message:
                                                "密码必须英文字母大写、小写，数字及特殊符号构成，必须满足3种类型，8-20位!",
                                            pattern:
                                                /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_]+$)(?![a-z0-9]+$)(?![a-z\\W_]+$)(?![0-9\\W_]+$)[a-zA-Z0-9\\W_]{8,20}$/,
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        placeholder="请输入新密码（8-20位）"
                                        type="text"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="验证码："
                                    style={{ width: "100%" }}

                                    name="mobileCheckNo"
                                    rules={[
                                        {
                                            required: true,
                                            message: "验证码不能为空",
                                        },
                                    ]}
                                >
                                    <div style={{ display: "flex" }}>
                                        {" "}
                                        <Input placeholder="请输入验证码" />
                                        <Button
                                            type="primary"
                                            onClick={this.getCode}
                                            disabled={
                                                !this.state.canGetCode
                                            }
                                        >
                                            {this.state.canGetCode ? (
                                                <>获取验证码</>
                                            ) : (
                                                <strong className="sendCodegray">
                                                    {this.state.seconds +
                                                        " s 后重发"}
                                                </strong>
                                            )}
                                        </Button>
                                    </div>
                                </Form.Item>
                            </div>
                        )}
                        <Button
                            loading={this.state.confirmLoading}
                            style={{ float: "right" }}
                            type="primary"
                            onClick={() => this.setPassword()}
                        >
                            提交
                        </Button>
                        {/* </Form.Item> */}
                    </Form>
                </Modal>
            </div>
        );
    }
}

let SecuritySetting = connect(
    (state) => {
        return state;
    },
    (dispatch) => ({})
)(SecuritySettingM);

class PersonInfoM extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
    }
    render() {
        const data = this.props.record;
        const { operInfo, userInfo } = this.props;
        console.log(this.props.operInfo, "this.props.operInfo");
        return (
            <div className="System">
                <div>
                    {" "}
                    <h1>个人信息</h1>
                </div>{" "}
                <div>
                    {" "}
                    <h1 className="text-base font-bold">姓名</h1>
                    <p>{userInfo.brokerName || userInfo.userName || "-"}</p>
                </div>{" "}
                <div>
                    {" "}
                    <h1 className="text-base font-bold">企业</h1>
                    <p>{operInfo.companyName || "-"}</p>
                </div>
                <div>
                    {" "}
                    <h1 className="text-base font-bold">头像</h1>
                    <p>
                        {" "}
                        <Avatar
                            className="mt-3"
                            size={48}
                            style={{ fontSize: "16px" }}
                            src={operInfo.avatar || headPhoto}
                        >
                            {userInfo.userName
                                ? userInfo.userName.substr(0, 1)
                                : ""}
                        </Avatar>
                    </p>
                </div>
            </div>
        );
    }
}

let PersonInfo = connect(
    (state) => {
        return state;
    },
    (dispatch) => ({})
)(PersonInfoM);
