import React, { Component } from "react";
import {
    Row,
    Breadcrumb,
    Button,
    Form,
    message,
    DatePicker,
    Modal,
    Input,
    Select,
    Col,
    Radio,
    Popover,
    Table,
    TreeSelect,
    InputNumber,
    Cascader,
} from "antd";
import { AreaSelect, popoverTable } from "../../common";
import Filter from "../../common/filter";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Axios } from "../../axios";
import moment from "moment";
import { industryLevel3 } from "../../common/industryLevel3";
import filter from "../../common/filter";
import { Shengpi2 } from "./Common";

import { bindActionCreators } from "redux";
import { PlusOutlined } from "@ant-design/icons";
import { change_operInfo } from "../../redux/actions/operInfo";
const { TextArea } = Input;

const { Option } = Select;
const RangePicker = DatePicker.RangePicker;
let setPageData = {},
    sendBody = {},
    Timer;
let StatuspageNum = 1;

class BusinessAppealManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tData: [],
            loading: false,
            totalNum: 0,
            currentPage:
                window.routerCache.BusinessAppealManage.currentPage || 1,
        };
        sendBody = window.routerCache.BusinessAppealManage.sendBody || {};
        setPageData = {};
    }

    // 获取表格数据
    refreshTableByData = (data, totalNum, currentPage) => {
        this.setState(
            { tData: data, totalNum: totalNum, currentPage: currentPage },
            // this.polling
        );
    };
    // 获取表格数据
    refreshTable = async () => {
        try {
            this.setState({
                loading: true,
            });
            let send = sendBody;
            send.workType = window.routerCache.CustomerQuery.workTypeStr || "";
            send.pageNum = this.state.currentPage + "";
            send.pageSize = "10";
            let res = await Axios("get", "/legal/appeal/list", send);
            this.setState(
                {
                    loading: false,
                    tData: res.body,
                    totalNum: res.totalNum,
                },
                // this.polling
            );
            setPageData[this.state.currentPage + ""] = res.body;
        } catch (err) {
            // message.error(err);
            this.setState({ loading: false });
        }
    };

    handleTableChange = async (pagination) => {
        this.setState({ loading: true });
        if (Object.keys(setPageData).includes(pagination.current + "")) {
            this.setState({
                loading: false,
                tData: setPageData[pagination.current + ""],
                currentPage: parseInt(pagination.current),
            });
        } else {
            try {
                let send = sendBody;
                send.pageNum = pagination.current + "";
                send.pageSize = "10";
                let res = await Axios("get", "/legal/appeal/list", send);
                this.setState(
                    {
                        loading: false,
                        tData: res.body,
                        totalNum: res.totalNum,
                        currentPage: pagination.current,
                    },
                    // this.polling
                );
                setPageData[pagination.current + ""] = res.body;
            } catch (err) {
                // message.error(err.message);
                this.setState({ loading: false });
            }
        }
    };

    // 组件渲染后获取外界数据(GET)
    componentDidMount() {
        this.refreshTable();
        window.winhcFn.changeDept = () => {
            this.setState(
                {
                    currentPage: 1,
                },
                () => {
                    this.refreshTable();
                }
            );
        };
    }

    componentWillUnmount() {
        window.routerCache.BusinessAppealManage.currentPage =
            this.state.currentPage;
        window.routerCache.BusinessAppealManage.sendBody = sendBody;
        if (Timer) {
            clearTimeout(Timer);
        }
    }

    polling = () => {
        let gonext = false;
        this.state.tData.forEach((item) => {
            if (item.status == "0") {
                gonext = true;
                return;
            }
        });
        if (!gonext) {
            return;
        }
        if (Timer) {
            clearTimeout(Timer);
        }
        // setTimeout(async () => {
        //     try {
        //         let send = sendBody;
        //         send.pageNum = this.state.currentPage + "";
        //         send.pageSize = '10';
        //         let res = await Axios('get', '/BrokerInfos/trial/legalcloud', send)
        //         if (this.state.currentPage == sendBody.pageNum) {
        //             this.setState({
        //                 tData: res.body,
        //                 totalNum: res.totalNum,
        //             }, this.polling);
        //             setPageData[this.state.currentPage + ''] = res.body;
        //         }
        //     } catch (err) {
        //         // message.error(err.message);
        //         console.log(err)
        //     }
        // }, 1000)
    };

    render() {
        const { refreshTable } = this;
        const { userRole } = this.props.operInfo;
        let { userInfo = {} } = this.props;
        if ("{}" == JSON.stringify(userInfo))
            userInfo = JSON.parse(localStorage.getItem("legal_userInfo") || {});
        let styleS = {
            display: "flex",
            background: "red",
            color: "white",
            borderRadius: "50%",
            width: "18px",
            height: "18px",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "12px",
            marginTop: "-5px",
        };
        let columns = [
            {
                title: "ID",
                dataIndex: "id",
                fixed: "left",
                // width: 70,
            },
            {
                title: "客户名称",
                dataIndex: "companyName",
                // width: 210,
                fixed: "left",
                render: (text, r) => (
                    <div style={{ display: "flex", cursor: "pointer" }}>
                        {text && text.length > 11 ? (
                            <Popover
                                content={
                                    <div
                                        style={{
                                            padding: "10px",
                                            maxWidth: "500px",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {text}
                                    </div>
                                }
                            >
                                <span>{Filter.strLength(text, 11)}</span>
                            </Popover>
                        ) : (
                            text
                        )}{" "}
                        {r.transSign == "1" ? (
                            <span style={styleS}>转</span>
                        ) : (
                            ""
                        )}
                    </div>
                ),
            },
            popoverTable(12, {
                title: "联系人",
                dataIndex: "linkMan",
                // width: 110,
            }),
            {
                title: "联系电话",
                dataIndex: "mobileNo",
                // width: 120,
            },
            popoverTable(12, {
                title: "备注",
                dataIndex: "memo",
                // width: 200,
            }),
            {
                title: "申诉人",
                dataIndex: "brokerUserName",
                // width: 160,
            },
            {
                title: "申诉时间",
                dataIndex: "timeCreated",
                // width: 160,
                render: (t) => (t ? t.replaceAll("T", " ") : "-"),
            },
        ];
        if (userRole === "1" || userInfo.job == "senior" || userInfo.job == "director") {
            columns.push({
                title: "操作",
                dataIndex: "timeCreated1",
                fixed: "right",
                // width: 90,
                render: (text, record) => (
                    <Shengpi2
                        refreshTable={this.refreshTable}
                        index={"8"}
                        brokerUserId={record.brokerUserId}
                        detailCustId={record.id}
                    >
                        <a>分配业务员</a>
                    </Shengpi2>
                ),
            });
        }
        return (
            <div className="pageContainer">
                <Row className="headNav">
                    <Col span={24}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>业务申诉</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col className="title" span={24}>
                        <span className="name">业务申诉管理</span>
                        <AddBroker refreshTable={this.refreshTable} />
                    </Col>
                </Row>
                <div className="scrollContainer">
                    <div className="scrollMain">
                        <Search refreshTableByData={this.refreshTableByData} />

                        <div style={{ backgroundColor: "#fff" }}>
                            <Row
                                type="flex"
                                justify="space-between"
                                align="middle"
                            ></Row>

                            <Table
                                scroll={{ x: 'max-content' }}
                                dataSource={this.state.tData}
                                columns={columns}
                                size="middle"
                                pagination={{
                                    size: "large",
                                    showQuickJumper: true,
                                    pageSize: 10,
                                    total: parseInt(this.state.totalNum),
                                    current: Number(this.state.currentPage),
                                }}
                                loading={this.state.loading}
                                onChange={this.handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return state;
    },
    (dispatch) => ({})
)(BusinessAppealManage);

class SearchM extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            province: "",
            city: "",
            qingkong: false,
        };
    }

    // 查询
    query = async () => {
        setPageData = {};
        let _this = this;
        sendBody = this.formRef.current.getFieldsValue();
        _this.props.refreshTableByData([], 1, 1);
        delete sendBody.date;
        sendBody.pageNum = "1";
        sendBody.pageSize = "10";
        sendBody.workType = window.routerCache.CustomerQuery.workTypeStr || "";

        if (this.state.province) {
            sendBody.province = this.state.province;
        }
        if (this.state.city) {
            sendBody.city = this.state.city;
        }
        try {
            let res = await Axios("get", "/legal/appeal/list", sendBody);

            _this.props.refreshTableByData(res.body, res.totalNum, 1);
            setPageData["1"] = res.body;
        } catch (err) {
            // message.error(err.message);
        }
    };
    clearOptions = async () => {
        sendBody = {};
        setPageData = {};
        // this.formRef.current.resetFields();

        // 清空的时候清空地区
        this.setState(
            {
                province: "",
                city: "",
                qingkong: true,
            },
            () => {
                this.setState({
                    qingkong: false,
                });
                this.formRef.current.resetFields();
            }
        );
        sendBody.pageNum = "1";
        sendBody.pageSize = "10";
        window.routerCache.CustomerQuery.workTypeStr = "";
        // sendBody.workType = window.routerCache.BusinessAppealManage.send_workType || '';
        this.props.refreshTableByData([], 1, 1);
        try {
            let res = await Axios("get", "/legal/appeal/list", sendBody);

            this.props.refreshTableByData(res.body, res.totalNum, 1);
            setPageData["1"] = res.body;
        } catch (err) {
            // message.error(err.message);
        }
    };
    // 选择日期范围
    dateChange = (value, b) => {
        console.log(value, b);
        if (value.length !== 0) {
            // this.setState({sDate: b[0].replace(/-/g, '')});
            // this.setState({eDate: b[1].replace(/-/g, '')});
            this.setState({ sDate: b[0] });
            this.setState({ eDate: b[1] });
        } else {
            this.setState({ sDate: "" });
            this.setState({ eDate: "" });
        }
    };

    // 组件渲染后获取外界数据(GET)
    componentDidMount() { }

    render() {
        /*控制查询按钮状态*/
        console.log(sendBody.companyName, "sendBody.companyName");
        return (
            <div className="search_like_antD" style={{ marginTop: "-8px" }}>
                <Form
                    ref={this.formRef}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                >
                    <Row
                        type="flex"
                        align="middle"
                        style={{ padding: "8px 0" }}
                    >
                        <Col span="8">
                            <Form.Item
                                label="客户名称"
                                style={{ width: "100%" }}
                                name="companyName"
                                initialValue={sendBody.companyName}
                            >
                                <Input
                                    placeholder="请输入"
                                    type="text"
                                    style={{ width: "80%" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span="8">
                            <Form.Item
                                label="联系人"
                                style={{ width: "100%" }}
                                name="linkMan"
                                initialValue={sendBody.linkMan}
                            >
                                <Input
                                    placeholder="请输入"
                                    type="text"
                                    style={{ width: "80%" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span="8">
                            <Form.Item
                                label="联系电话"
                                style={{ width: "100%" }}
                                name="mobileNo"
                                initialValue={sendBody.mobileNo}
                            >
                                <Input
                                    placeholder="请输入"
                                    type="text"
                                    style={{ width: "80%" }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row
                        type="flex"
                        justify="space-between"
                        align="middle"
                        style={{ padding: "4px 0" }}
                    >
                        <Col span={8}></Col>
                        <Col span={8}></Col>
                        <Col span={8}>
                            <Row
                                type="flex"
                                justify="end"
                                align="middle"
                                style={{ width: "85%", height: "30px" }}
                            >
                                <Form.Item style={{ marginRight: "16px" }}>
                                    <Button type="primary" onClick={this.query}>
                                        查询
                                    </Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button onClick={this.clearOptions}>
                                        重置
                                    </Button>
                                </Form.Item>
                            </Row>
                        </Col>
                    </Row>
                    <div>
                        <Row
                            type="flex"
                            justify="space-between"
                            align="middle"
                            style={
                                window.routerCache.BusinessAppealManage
                                    .workTypeStr
                                    ? {
                                        paddingBottom: "10px",
                                    }
                                    : { paddingBottom: "40px" }
                            }
                        ></Row>
                    </div>
                </Form>
                <style>{`
               .search_like_antD .ant-form ant-form-inline .ant-row-flex .ant-row-flex-space-between .ant-row-flex-middle{
                    padding: 0px !important;
                }
                `}</style>
            </div>
        );
    }
}

let Search = connect(
    (state) => {
        return state;
    },
    (dispatch) => ({})
)(SearchM);

class AddBrokerM extends Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            selectOption: [],
            productList: [],
            loading: false,
            confirmLoading: false,
            province: "",
            city: "",
            qingkong: false,
        };
    }

    componentWillMount() { }

    getCustList = async (v) => {
        if (Timer) {
            clearTimeout(Timer);
        }
        Timer = setTimeout(async () => {
            try {
                let res = await Axios(
                    "get",
                    "/custInfos/open/Eci/simple/v2",
                    { companyName: v },
                    "fireflyErp"
                );
                console.log("企业列表=", res);
                if (res.isSuccess == "T" && res.body) {
                    let custList = [];
                    JSON.parse(res.body).result.forEach((item) => {
                        if (item.name) {
                            let names = [];
                            for (let i = 0; i < item.name.length; i++) {
                                let yes = false;
                                for (let j = 0; j < v.length; j++) {
                                    if (v[j] == item.name[i]) {
                                        yes = true;
                                        break;
                                    }
                                }
                                if (yes) {
                                    names.push(
                                        <span style={{ color: "#FD485E" }}>
                                            {item.name[i]}
                                        </span>
                                    );
                                } else {
                                    names.push(item.name[i]);
                                }
                            }
                            custList.push(
                                <Option key={item.keyNo} value={item.name}>
                                    {names}
                                </Option>
                            );
                        }
                    });
                    this.setState({
                        custList,
                    });
                } else {
                    this.setState({
                        custList: [],
                    });
                }
            } catch (err) {
                if (err && err.message) {
                    message.error(err.message);
                }
            }
        }, 700);
    };
    // 单击确定按钮提交表单
    handleSubmit = (e) => {
        const { userRole } = this.props.operInfo;
        e.preventDefault();
        this.formRef.current
            .validateFields()
            .then(async (values) => {
                let sendData = values;
                console.log(sendData);
                if (
                    !sendData.linkMan.replaceAll(" ", "") ||
                    !sendData.linkMan.replaceAll("\n", "")
                ) {
                    message.info("联系人不可全为空");
                    return;
                }
                if (
                    !sendData.memo.replaceAll(" ", "") ||
                    !sendData.memo.replaceAll("\n", "")
                ) {
                    message.info("备注不可全为空");
                    return;
                }

                try {
                    this.setState({ confirmLoading: true });
                    let res = await Axios(
                        "post",
                        "/legal/appeal/save",
                        sendData
                    );
                    if (res.errorCode == "200" || res.isSuccess == "T") {
                        this.props.refreshTable();
                        this.formRef.current.resetFields();
                        message.success(res.errorMsg);
                        this.setState({
                            visible: false,
                            qingkong: true,
                            confirmLoading: false,
                        });
                    } else {
                        message.error(res.message || res.errorMsg);
                        this.setState({ confirmLoading: false });
                    }
                } catch (err) {
                    message.error(err.message);
                    this.setState({ confirmLoading: false });
                }
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    };
    // 弹出框设置
    showModal = () => {
        this.setState({ visible: true });
    };
    handleCancel = (e) => {
        this.setState({ visible: false });
    };
    handleSelectChange = (value) => {
        console.log("select=" + value);
    };

    componentDidMount() {
        const { userRole } = this.props.operInfo;
    }
    render() {
        const { userRole } = this.props.operInfo;
        const { productList } = this.state;
        return (
            <span>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    style={{ float: "right" }}
                    onClick={() => {
                        this.setState({ visible: true });
                    }}
                >
                    新增申诉
                </Button>
                <Modal
                    title="新增申诉"
                    open={this.state.visible}
                    onOk={this.handleSubmit}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={() => {
                        this.setState({ visible: false });
                    }}
                >
                    <Form
                        ref={this.formRef}
                        layout="horizontal"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 16 }}
                    >
                        <Form.Item
                            label="企业名称："
                            name="companyName"
                            initialValue={sendBody.companyName}
                            rules={[
                                {
                                    required: true,
                                    message: "企业名称不能为空",
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                optionLabelProp="value"
                                placeholder="请输入企业名称"
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                onSearch={(v) => {
                                    setTimeout(() => {
                                        // if (v) {
                                        v = v.replace(/\s/g, "");
                                        this.formRef.current.setFieldsValue({
                                            companyName: v || undefined,
                                        });
                                        this.getCustList(v);
                                        // }else{

                                        // }
                                    });
                                }}
                                notFoundContent={null}
                                onSelect={(v, options) => {
                                    if (v) {
                                        v = v.replace(/\s/g, "");
                                    }
                                }}
                            >
                                {this.state.custList}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="联系人："
                            name="linkMan"
                            initialValue={sendBody.linkMan}
                            rules={[
                                {
                                    required: true,
                                    message: "请输入联系人",
                                },
                            ]}
                        >
                            <Input
                                placeholder="请填写客户的联系人"
                                type="text"
                                maxLength={100}
                            />
                        </Form.Item>
                        <Form.Item
                            label="联系电话："
                            name="mobileNo"
                            initialValue={sendBody.mobileNo}
                            rules={[
                                {
                                    required: true,
                                    message: "请输入联系电话",
                                },
                                {
                                    message: "手机号不合法",
                                    pattern: /^((1[0-9]{2})+\d{8})$/,
                                },
                            ]}
                        >
                            <InputNumber
                                style={{ width: "100%" }}
                                placeholder="请填写客户联系人的电话"
                                maxLength={11}
                            />
                        </Form.Item>
                        <Form.Item
                            label="备注："
                            name="memo"
                            initialValue={sendBody.memo}
                            rules={[
                                {
                                    required: true,
                                    message: "请输入备注",
                                },
                            ]}
                        >
                            <TextArea
                                rows={2}
                                placeholder="请输入备注"
                                maxLength={300}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
                <style>{`
                    .ant-input-number-handler-wrap{
                       display: none;   
                    }
                  `}</style>
            </span>
        );
    }
}

let AddBroker = connect(
    (state) => {
        return state;
    },
    (dispatch) => ({
        change_operInfo: bindActionCreators(change_operInfo, dispatch),
    })
)(AddBrokerM);
