import React, { Component } from "react";
import {
    Row,
    Col,
    Breadcrumb,
    message,
    Button,
    Form,
    Popconfirm,
    Input,
    Select,
    Tooltip,
    Table,
    Popover,
    TreeSelect,
} from "antd";
import { Ossupload } from "../../common/upLoad";
import { FlieList, popoverTable } from "../../common";
import { Axios } from "../../axios";
import { AreaSelect, Imgbox } from "../../common";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Filter from "../../common/filter";
import filter from "../../common/filter";

const { Option } = Select;

export default class CustomerDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            brokerDetails: {},
            tData: [],
            loading: false,
            totalNum: 0,
            currentPage: 1,
            comtotalNum: 0,
            InformationList: {},
            OrderinformationList: {},
        };
    }

    getDet = async () => {
        try {
            let res = await Axios(
                "get",
                "/order/relation/list/" + window.brokerId,
                {}
            );
            if (res.code == "200") {
                this.setState({
                    InformationList: res.data.customInfo,
                    OrderinformationList: res.data.orderInfo,
                });
            } else {
                message.error(res.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    componentDidMount() {
        window.brokerId = this.props.match.params.id;
        this.getDet();
    }

    render() {
        const brokerDetails = {};
        const companyInfo = {};
        const { InformationList, OrderinformationList } = this.state;
        const cStyle = {
            padding: "12px 24px 12px 24px",
            marginBottom: "15px",
            borderRadius: "4px",
            background: "#fff",
            fontSize: "14px",
        };
        const tStyle = {
            color: "#222",
            lineHeight: "30px",
            fontWeight: "650",
            fontSize: "16px",
            margin: "0px -24px 15px -24px",
            borderBottom: "1px solid #efe5e5",
            padding: "0 24px 8px 24px",
        };
        const dStyle = {
            color: "#222",
            lineHeight: "30px",
            margin: "10px 0px 0px 0px",
        };
        return (
            <div className="pageContainer">
                <Row className="headNav" type="flex" justify="space-between">
                    <Col span={24}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>
                                <Link to="/RelevanceOrderQuery">
                                    运营商关联订单
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                运营商关联订单详情
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={24} className="title">
                        <span className="name">运营商关联订单详情</span>
                        <div
                            style={{
                                background: "#1890ff",
                                color: "#fff",
                                lineHeight: "26px",
                                padding: "3px 10px",
                                float: "right",
                                borderRadius: "4px",
                                marginRight: "12px",
                            }}
                        >
                            状态：
                            {InformationList.status == "0"
                                ? "待审核"
                                : InformationList.status == "1"
                                    ? "审核成功"
                                    : InformationList.status == "2"
                                        ? "审核失败"
                                        : "-"}
                        </div>
                    </Col>
                </Row>
                <div style={{ padding: "16px 24px" }}>
                    <div style={cStyle}>
                        <div style={tStyle}>客户基本信息</div>
                        <Row style={dStyle}>
                            <Col span={8}>
                                客户名称：
                                {InformationList.companyName || "暂无"}
                            </Col>
                            <Col span={8}>
                                所在地：{InformationList.province || "暂无"}{" "}
                                {InformationList.city || ""}
                            </Col>
                            <Col span={8}>
                                所属运营商：
                                {InformationList.brokerName || "暂无"}
                            </Col>
                        </Row>
                    </div>
                </div>
                <div style={{ padding: "16px 24px" }}>
                    <div style={cStyle}>
                        <div style={tStyle}>订单信息</div>
                        <Row style={dStyle}>
                            <Col span={8}>
                                订单号：{OrderinformationList.orderId || "暂无"}
                            </Col>
                            <Col span={8}>
                                套餐名称：
                                {OrderinformationList.transAmt
                                    ? "￥" +
                                    OrderinformationList.transAmt +
                                    "/" +
                                    OrderinformationList.specificationDesc
                                    : "暂无"}
                            </Col>
                            <Col span={8}>
                                购买时间：
                                {OrderinformationList.payTime
                                    ? OrderinformationList.payTime.replace(
                                        "T",
                                        "    "
                                    )
                                    : "暂无"}
                            </Col>
                        </Row>
                        <Row style={dStyle}>
                            <Col span={8}>
                                支付金额：
                                {OrderinformationList.payAmt
                                    ? OrderinformationList.payAmt + "元"
                                    : "暂无"}
                            </Col>
                            <Col span={8}>
                                订单状态：
                                {Filter.RelevanceOrderStatus(
                                    OrderinformationList.status
                                ) || "-"}
                            </Col>
                            <Col span={8}>
                                退款操作时间：
                                {OrderinformationList.refundDate ? filter.date(
                                    OrderinformationList.refundDate) + '  ' + filter.time(OrderinformationList.refundTime
                                    ) : "暂无"}
                            </Col>
                        </Row>
                        <Row style={dStyle}>
                            <Col span={8}>
                                操作人： {OrderinformationList.status == 'G' ? '总部云法务' : "暂无"}
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}
