import React, { Component } from "react";
import {
  Row,
  Breadcrumb,
  Button,
  Form,
  message,
  DatePicker,
  Modal,
  Input,
  Select,
  Col,
  Radio,
  Popover,
  Table,
  TreeSelect,
  Alert,
} from "antd";
import { AreaSelect, popoverTable } from "../../common";
import Filter from "../../common/filter";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Axios } from "../../axios";
import moment from "moment";

const { Option } = Select;
const RangePicker = DatePicker.RangePicker;
let sendBody = {};

let StatuspageNum = 1;
class RenewCompanyManage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tData: [],
      loading: false,
      totalNum: 0,
      TopTip: 0,
      currentPage: window.routerCache.RenewCompanyManage.currentPage || 1,
    };
    sendBody = window.routerCache.RenewCompanyManage.sendBody || {};
  }

  // 获取表格数据
  refreshTableByData = (data, totalNum, currentPage) => {
    this.setState({
      tData: data,
      totalNum: totalNum,
      currentPage: currentPage,
    });
  };
  // 获取表格数据
  refreshTable = async () => {
    try {
      this.setState({
        loading: true,
      });
      if (window.routerCache.RenewCompanyManage.workTypeStr && !sendBody.renewStatus && window.routerCache.RenewCompanyManage.type) {
        sendBody.renewStatus = window.routerCache.RenewCompanyManage.workTypeStr;
      } else if (window.routerCache.RenewCompanyManage.workTypeStr && !window.routerCache.RenewCompanyManage.type) {
        sendBody.workType =
          window.routerCache.RenewCompanyManage.workTypeStr || "";
        delete sendBody.renewStatus;

      }
      if (window.routerCache.RenewCompanyManage.type) {
        delete sendBody.workType;
      }
      let send = sendBody;

      send.pageNum = this.state.currentPage + "";
      send.pageSize = "10";
      let res = await Axios("get", "/renew/list", send);
      this.setState({
        loading: false,
        tData: res.body,
        totalNum: res.totalNum,
      });
    } catch (err) {
      // message.error(err);
      this.setState({ loading: false });
    }
  };
  getTopTip = async () => {
    try {
      let res = await Axios("get", "/renew/topTip", {});
      if (res.errorCode == '200' || res.isSuccess == 'T') {
        this.setState({
          TopTip: res.body || 0,
        });
      } else {
        message.error(res.errorMsg);
      }
    } catch (err) {
    }
  };
  handleTableChange = async (pagination) => {
    this.setState({ loading: true });
    try {
      let send = sendBody;
      send.pageNum = pagination.current + "";
      send.pageSize = "10";
      let res = await Axios("get", "/renew/list", send);
      this.setState(
        {
          loading: false,
          tData: res.body,
          totalNum: res.totalNum,
          currentPage: pagination.current,
        },
        this.polling
      );
    } catch (err) {
      // message.error(err.message);
      this.setState({ loading: false });
    }
  };
  Adopt = async (id) => {
    try {
      let res = await Axios("put", "/renew/adopt/" + id, { id });
      if (res.errorCode == '200' || res.isSuccess == 'T') {
        message.success(res.errorMsg);
        this.refreshTable();
      } else {
        message.error(res.errorMsg);
      }

    } catch (err) {
      // message.error(err.message);
      this.setState({ loading: false });
    }
  };
  // 组件渲染后获取外界数据(GET)
  componentDidMount () {
    this.refreshTable();
    this.getTopTip();
    window.winhcFn.changeDept = () => {
      this.setState(
        {
          currentPage: 1,
        },
        () => {
          this.refreshTable();
        }
      );
    };
  }

  componentWillUnmount () {
    window.routerCache.RenewCompanyManage.currentPage =
      this.state.currentPage;
    window.routerCache.RenewCompanyManage.sendBody = sendBody;
  }
  render () {
    const { refreshTable } = this;
    const { TopTip } = this.state;
    let styleS = {
      display: "flex",
      background: "red",
      color: "white",
      borderRadius: "50%",
      width: "18px",
      height: "18px",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "12px",
      marginTop: "-5px",
    };
    let columns = [
      {
        title: "ID",
        dataIndex: "id",
        fixed: "left",
      },
      {
        title: "客户名称",
        dataIndex: "companyName",
        fixed: "left",
        render: (text, r) => (
          <div style={{ display: "flex", cursor: "pointer" }}>
            {text && text.length > 11 ? (
              <Popover
                content={
                  <div
                    style={{
                      padding: "10px",
                      maxWidth: "500px",
                      fontSize: "14px",
                    }}
                  >
                    {text}
                  </div>
                }
              >
                <span>{Filter.strLength(text, 11)}</span>
              </Popover>
            ) : (
              text
            )}{" "}
            {r.transSign == "1" ? (
              <span style={styleS}>转</span>
            ) : (
              ""
            )}
          </div>
        ),
      },
      {
        title: "套餐类型",
        dataIndex: "purchaseSpec",
        render: (text, record) => Filter.legalspecification(text),
      },
      {
        title: "到期时间",
        dataIndex: "expiresTime",
        render: (text, record) =>
          text * 1 ? text + "天" : "超期" + Math.abs(text) + "天",
      },
      {
        title: "续费跟进人",
        dataIndex: "followUserName",
      },

      {
        title: "续费意愿",
        dataIndex: "renewIdea",
        render: (text, record) => Filter.RenewIdea(text),
      },
      popoverTable(8, {
        title: "最新跟进记录",
        dataIndex: "followDesc",
      }),
      {
        title: "最新跟进时间",
        dataIndex: "followDate",
        render: (text, record) => text ? text.slice(0, 10) : '-'
      },
      {
        title: "续费状态",
        dataIndex: "renewStatus",
        render: (text, record) =>
          text == "1" ? "未续费" : text == "2" ? "已续费" : "-",
      },
      {
        title: "认领状态",
        dataIndex: "adoptStatus",
        render: (text, record) =>
          text == "0" ? "未认领" : text == "1" ? "已认领" : "-",
      },
      {
        title: "操作",
        dataIndex: "operate",
        fixed: "right",
        render: (text, record) => (
          <span>
            <Link to={"/RenewCompanyManageDetail/" + record.id}>
              查看
            </Link>
            &emsp;
            {((Number(record.expiresTime) > 0 && Number(record.expiresTime) <= 30) && (record.adoptStatus != "1" ?
              <a onClick={() => this.Adopt(record.id)}  >
                认领客户
              </a> : '认领客户')) || '-'}
          </span>

        ),
      },
    ];
    return (
      <div className="pageContainer">
        <Row className="headNav">
          <Col span={24}>
            {Number(TopTip) ? <Alert message={<span>
              客户续费认领提醒：有{Number(TopTip)}家客户在30天内服务即将到期，<span style={{ fontWeight: 'bold', color: 'red' }}>为保障客户续费工作，请及时认领并跟进 </span>。如果<span style={{ fontWeight: 'bold', color: 'red' }}>在客户距到期剩余25天时仍未认领客户</span>，云法务总部视为您未及时跟进这些即将到期的客户，此时云法务总部将跟进这些客户，客户通过云法务总部下单续费成功后，归属总部云法务。请及时在列表“操作”一栏中，点击“认领客户”按钮，认领客户
            </span>} type="info" /> : <Breadcrumb separator=">">
              <Breadcrumb.Item>续费客户管理</Breadcrumb.Item>
            </Breadcrumb>}
          </Col>
          <Col span={24} className="title">
            <span className="name">续费客户管理</span>
          </Col>
        </Row>
        <div className="scrollContainer">
          <div className="scrollMain">
            <Search refreshTableByData={this.refreshTableByData} />

            <div style={{ backgroundColor: "#fff" }}>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
              ></Row>

              <Table
                scroll={{ x: 'max-content' }}
                dataSource={this.state.tData}
                columns={columns}
                size="middle"
                pagination={{
                  size: "large",
                  showQuickJumper: true,
                  pageSize: 10,
                  total: parseInt(this.state.totalNum),
                  current: Number(this.state.currentPage),
                }}
                loading={this.state.loading}
                onChange={this.handleTableChange}
              />
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default connect(
  (state) => {
    return state;
  },
  (dispatch) => ({})
)(RenewCompanyManage);

class SearchM extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      workTypeStr: window.routerCache.RenewCompanyManage.type && window.routerCache.RenewCompanyManage.workTypeStr || undefined
    };
  }

  // 查询
  query = async () => {
    let _this = this;
    sendBody = this.formRef.current.getFieldsValue();
    _this.props.refreshTableByData([], 1, 1);
    sendBody.pageNum = "1";
    sendBody.pageSize = "10";
    if (window.routerCache.RenewCompanyManage.workTypeStr && !window.routerCache.RenewCompanyManage.type) {
      sendBody.workType =
        window.routerCache.RenewCompanyManage.workTypeStr || "";

    }
    try {
      let res = await Axios("get", "/renew/list", sendBody);

      _this.props.refreshTableByData(res.body, res.totalNum, 1);
    } catch (err) {
      // message.error(err.message);
    }
  };
  clearOptions = async () => {
    this.setState({
      workTypeStr: undefined
    }, () => {

      this.formRef.current.resetFields();
    })
    window.routerCache.RenewCompanyManage.workTypeStr = ""
    window.routerCache.RenewCompanyManage.type = undefined

    sendBody = {};
    sendBody.pageNum = "1";
    sendBody.pageSize = "10";
    this.props.refreshTableByData([], 1, 1);



    try {
      let res = await Axios("get", "/renew/list", sendBody);

      this.props.refreshTableByData(res.body, res.totalNum, 1);
    } catch (err) {
      // message.error(err.message);
    }
  };
  alertHandle = async () => {
    this.setState({
      workTypeStr: undefined
    }, () => {

      this.formRef.current.resetFields();
    })
    window.routerCache.RenewCompanyManage.workTypeStr = ""
    sendBody = {};
    sendBody.pageNum = "1";
    sendBody.pageSize = "10";
    this.props.refreshTableByData([], 1, 1);
    try {
      let res = await Axios("get", "/renew/list", sendBody);

      this.props.refreshTableByData(res.body, res.totalNum, 1);
    } catch (err) {
    }
  };
  // 组件渲染后获取外界数据(GET)
  componentDidMount () { }
  render () {
    /*控制查询按钮状态*/
    return (
      <div className="search_like_antD" style={{ marginTop: "-8px" }}>
        {window.routerCache.RenewCompanyManage.workTypeStr && !window.routerCache.RenewCompanyManage.type && (
          <Alert
            message={window.routerCache.RenewCompanyManage.workTypeStr}
            type="info"
            showIcon
            closeText="清空"
            onClose={this.alertHandle}
            style={{ marginBottom: 5 }}
          />
        )}
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} ref={this.formRef}>
          <Row
            type="flex"
            align="middle"
          >
            <Col span="8">
              <Form.Item
                label="客户名称"
                style={{ width: "100%" }}
                name="companyName"
                initialValue={sendBody.companyName}>

                <Input
                  placeholder="请输入"
                  type="text"
                  style={{ width: "80%" }}
                />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item
                label="续费跟进人"
                style={{ width: "100%" }}
                name="followUserName"
                initialValue={sendBody.followUserName}>
                <Input
                  placeholder="请输入"
                  type="text"
                  style={{ width: "80%" }}
                />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item
                label="续费意愿"
                style={{ width: "100%" }}
                name="renewIdea"
                initialValue={sendBody.renewIdea}>
                <Select
                  placeholder="请选择"
                  allowClear
                  style={{ width: "80%" }}
                >
                  <Option key="4">有意愿</Option>
                  <Option key="1">沟通中</Option>
                  <Option key="2">续费中</Option>
                  <Option key="3">不续费</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row
            type="flex"
            align="middle"
          >
            <Col span="8">
              <Form.Item
                label="续费状态"
                style={{ width: "100%" }}
                name="renewStatus"
                initialValue={sendBody.renewStatus || window.routerCache.RenewCompanyManage.type ? this.state.workTypeStr : undefined}>
                <Select
                  placeholder="请选择"
                  allowClear
                  style={{ width: "80%" }}
                >
                  <Option key="1">未续费</Option>
                  <Option key="2">已续费</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item
                label="到期时间"
                style={{ width: "100%" }}
                name="endDate"
                initialValue={sendBody.endDate}>

                <Select
                  placeholder="请选择"
                  style={{ width: "80%" }}
                >
                  <Option key="1">45天内到期</Option>
                  <Option key="2">30天内到期</Option>
                  <Option key="3">15天内到期</Option>
                  <Option key="4">7天内到期</Option>
                  <Option key="5">今天到期</Option>
                  <Option key="6">超期1天以上</Option>
                  <Option key="7">超期15天以上</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="认领状态"
                style={{ width: "100%" }}
                name="adoptStatus"
                initialValue={sendBody.adoptStatus}>
                <Select
                  placeholder="请选择"
                  allowClear
                  style={{ width: "80%" }}
                >
                  <Option key="0">未认领</Option>
                  <Option key="1">已认领</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row
            type="flex"
            align="middle"
          >
            <Col span="8">

            </Col>
            <Col span="8">

            </Col>
            <Col span={8}>
              <Row
                type="flex"
                justify="end"
                align="middle"
                style={{ width: "85%", height: '30px' }}
              >
                <Form.Item style={{ marginRight: '16px' }}
                >
                  <Button
                    type="primary"
                    onClick={this.query}
                  >
                    查询
                  </Button>
                </Form.Item>
                <Form.Item >
                  <Button onClick={this.clearOptions}>
                    重置
                  </Button>
                </Form.Item>
              </Row>
            </Col>
          </Row>
          <div>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={
                window.routerCache.RenewCompanyManage
                  .workTypeStr
                  ? {
                    paddingBottom: "10px",
                  }
                  : { paddingBottom: "40px" }
              }
            ></Row>
          </div>
        </Form>
        <style>{`
               .search_like_antD .ant-form ant-form-inline .ant-row-flex .ant-row-flex-space-between .ant-row-flex-middle{
                    padding: 0px !important;
                }
                `}</style>
      </div>
    );
  }
}

let Search = connect(
  (state) => {
    return state;
  },
  (dispatch) => ({})
)(SearchM);
