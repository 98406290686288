import React, { Component } from "react";
import {
  Row,
  Breadcrumb,
  Button,
  Form,
  message,
  Modal,
  Input,
  Select,
  Col,
  Radio,
  Popover,
  Table,
  TreeSelect,
  Tree,
  Tooltip,
  Dropdown,
  Alert,
  Avatar,
  Checkbox,
  Space,
  Popconfirm,
} from "antd";
import { AreaSelect } from "../../common";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  PlusOutlined,
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
  ApartmentOutlined,
} from "@ant-design/icons";

import { Axios } from "../../axios";
import filter from "../../common/filter";
import { change_userInfo } from "../../redux/actions/userInfo";
import { bindActionCreators } from "redux";

import moment from "moment";
const { confirm } = Modal;
let userInfos = localStorage.getItem("legal_userInfo") || {};
const { Option } = Select;
let sendBody = {};
class PersonQuery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tData: [],
      loading: false,
      totalNum: 0,
      docKindsMap: [],
      currentPage: window.routerCache.PersonQuery.currentPage || 1,
      selectedKeys: [""],
    };
    sendBody = window.routerCache.PersonQuery.sendBody || {};
  }

  // 获取表格数据
  refreshTableByData = (data, totalNum, currentPage) => {
    this.setState({
      tData: data,
      totalNum: totalNum,
      currentPage: currentPage,
    });
  };
  // 获取表格数据
  refreshTable = async () => {
    try {
      this.setState({
        loading: true,
      });
      let { userInfo = {} } = this.props;
      if ("{}" == JSON.stringify(userInfo)) userInfo = JSON.parse(userInfos);
      if (userInfo.job == "director" || userInfo.job == 'groupLeader') sendBody.departId = userInfo.departId;
      let send = sendBody;
      send.pageNum = this.state.currentPage + "";
      send.pageSize = "10";
      let res = await Axios("get", "/brokeruser/page", send);
      console.log(res);
      if (res.code == 200) {
        this.setState({
          tData: res.data.dataList,
          totalNum: res.data.totalNum,
        });
      } else {
        message.error(res.message);
      }
      this.setState({ loading: false });
    } catch (err) {
      // message.error(err);
      this.setState({ loading: false });
    }
  };
  docKindList = async is => {
    try {
      let res = await Axios("get", "/brokeruser/query/department", {});
      if (res.code == "200") {
        this.setState(
          {
            docKindsMap: res.data || [],
          },
          () => {
            if (is) {
              let { userInfo = {} } = this.props;
              if ("{}" == JSON.stringify(userInfo)) userInfo = JSON.parse(userInfos);
              if ((userInfo.brokerId && userInfo.mobileNo) || userInfo.job) {
                this.refreshTable();
              }
            }
          }
        );
      } else {
      }
    } catch (err) {
      // message.error(err);
      this.setState({ loading: false });
    }
  };
  handleTableChange = async pagination => {
    this.setState({ loading: true });
    try {
      let send = sendBody;
      send.pageNum = pagination.current + "";
      send.pageSize = "10";
      // send.workType = window.routerCache.PersonQuery.send_workType || '';
      let res = await Axios("get", "/brokeruser/page", send);
      console.log(res);
      this.setState({
        loading: false,
        tData: res.data.dataList,
        totalNum: res.data.totalNum,
        currentPage: pagination.current,
      });
    } catch (err) {
      // message.error(err.message);
      this.setState({ loading: false });
    }
  };

  // 组件渲染后获取外界数据(GET)
  componentDidMount () {
    console.log(this.props.userInfo, "userInfouserInfouserInfouserInfouserInfouserInfo");
    sendBody.departId = undefined;
    this.docKindList(true);
  }
  DeleteFun = async data => {
    let res = await Axios("get", "/brokeruser/page", {
      pageNum: 1,
      pageSize: 10,
      departId: data.id,
    });
    console.log(res, "resres");
    if (res.code == "200") {
      if (res.data.dataList && res.data.dataList.length) {
        Modal.error({
          title: "确认删除部门",
          content: "请先移除部门内所有人员再进行删除部门操作",
          closable: true,
          maskClosable: true,
          icon: null,
          okText: "好的",
          okType: null,
        });
      } else {
        confirm({
          title: "确认删除部门",
          closable: true,
          maskClosable: true,
          icon: null,
          content: "部门删除后不可恢复，是否继续？",
          cancelText: "取消",
          okButtonProps: { type: "primary", danger: true },
          okText: "删除",
          onOk: () => {
            Axios("delete", "/brokeruser/del/department/" + data.id, {
              id: data.id,
            }).then(res => {
              if (res.code === "200") {
                message.success(res.message);
                sendBody.departId = undefined;
                this.setState({ selectedKeys: [""] }, () => {
                  this.docKindList();
                  this.refreshTable();
                });
              } else {
              }
            });
          },
          onCancel () {
            console.log("Cancel");
          },
        });
      }
    }
  };
  componentWillUnmount () {
    window.routerCache.PersonQuery.currentPage = this.state.currentPage;
    window.routerCache.PersonQuery.sendBody = sendBody;
  }

  CheckStatus = (id, status) => {
    Axios("put", "/brokeruser/" + id, { status: status }).then(res => {
      if (res.code === "200") {
        this.refreshTable();
      } else {
      }
    });
  };
  onSelect = selectedKeys => {
    if (selectedKeys.length) {
      sendBody.departId = selectedKeys[0];
      this.setState(
        {
          selectedKeys,
          currentPage: 1,
        },
        () => {
          this.refreshTable();
        }
      );
    }
  };
  render () {
    let { docKindsMap, selectedKeys } = this.state;
    const brokerIds = localStorage.getItem("brokerIds");
    let { userInfo = {} } = this.props;
    if ("{}" == JSON.stringify(userInfo)) userInfo = JSON.parse(userInfos);
    // let Quanxian=  this.props.operInfo.operInfo.roles.includes('ROLE_BrokerManage');
    let idShow = userInfo.job == "director" || userInfo.job == "groupLeader";

    let Quanxian = true;
    let columns = [
      {
        title: "ID",
        dataIndex: "id",
        width: 50,
      },
      {
        title: "姓名",
        dataIndex: "userName",
        width: 100,
      },
      {
        title: "手机号",
        dataIndex: "mobileNo",
        width: 160,
      },
      {
        title: "部门",
        dataIndex: "departName",
        width: 100,
        render: (text, record) =>
          text ? text : record.job == "worker" || !record.job ? "-" : userInfo.brokerName,
      },
      {
        title: "开通日期",
        width: 90,
        dataIndex: "openDate",
        render: text => filter.date(text),
      },
      {
        title: (
          <span>
            所属角色
            <Question />
          </span>
        ),
        dataIndex: "job",
        width: 100,
        render: (text, record) => filter.JobType(text) || "员工",
      },
      {
        title: "状态",
        dataIndex: "status",
        width: 110,
        render: (text, record) => (
          <span style={{ color: text === 0 ? "#76B603" : "#D9001B" }}>
            {text === 0 ? "启用" : text === 1 ? "禁用" : "禁用"}
          </span>
        ),
      },
      {
        title: "操作",
        dataIndex: "operate",
        width: 140,
        render: (text, record, index) => (
          <span>
            {record.status === 0 ? (
              <AddBroker
                refreshTable={this.refreshTable}
                record={record}
                selectedKeys={selectedKeys}
                docKindsMap={docKindsMap}
              >
                <a>修改</a>
              </AddBroker>
            ) : (
              "修改"
            )}
            &nbsp;|&nbsp;
            {record.status === 1 ? (
              <a
                onClick={() => {
                  confirm({
                    title: "确定启用该账号吗",
                    icon: <ExclamationCircleOutlined />,
                    maskClosable: true,
                    content: null,
                    onOk: () => {
                      Axios("put", "/brokeruser/" + record.id, {
                        status: 0,
                        departId: record.departId,
                      }).then(res => {
                        if (res.code === "200") {
                          message.success(res.message);
                          this.refreshTable();
                        } else {
                          message.error(res.message);
                        }
                      });
                    },
                    onCancel () {
                      console.log("Cancel");
                    },
                  });
                }}
              >
                启用
              </a>
            ) : record.id != brokerIds ? (
              <Forbidden record={record} key={index} refreshTable={this.refreshTable}>
                <a>禁用</a>{" "}
              </Forbidden>
            ) : (
              <span onClick={() => message.info("如需禁用自己账号，请联系老板操作")}> 禁用 </span>
            )}
            {record.status != 0 && record.departName ? (
              <span>
                &nbsp;|&nbsp;{" "}
                <a
                  onClick={() => {
                    confirm({
                      title: "确定要把该员工从部门移出吗",
                      closable: true,
                      maskClosable: true,
                      icon: null,
                      content: "确定移出的话，被移出的员工在公司层面展示",
                      cancelText: "取消",
                      okButtonProps: {
                        type: "primary",
                        danger: true,
                      },
                      okText: "移出",
                      onOk: () => {
                        Axios("post", "/brokeruser/remove/department/" + record.id, {
                          id: record.id,
                        }).then(res => {
                          if (res.code === "200") {
                            message.success(res.message);
                            this.refreshTable();
                          } else {
                          }
                        });
                      },
                      onCancel () {
                        console.log("Cancel");
                      },
                    });
                  }}
                >
                  移出部门
                </a>
              </span>
            ) : (
              ""
            )}
          </span>
        ),
      },
    ];
    let Style = {
      width: "250px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "7px",
      fontWeight: "600",
      fontSize: "14px",
    };
    let Style1 = {
      width: "100%",
      height: "100%",
      display: "inline-block",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    };
    return (
      <div className="pageContainer">
        <Row className="headNav">
          <Col span={24}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>成员与部门管理</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="title" span={24}>
            <span className="name">成员与部门管理</span>
            {Quanxian ? (
              <AddBroker
                refreshTable={this.refreshTable}
                record={{}}
                selectedKeys={selectedKeys}
                docKindsMap={docKindsMap}
              >
                <Button type="primary" icon={<PlusOutlined />} style={{ float: "right" }}>
                  新增成员
                </Button>
              </AddBroker>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <div className="scrollContainer">
          <div className="scrollMain">
            {/* <Search refreshTableByData={this.refreshTableByData} /> */}
            <div style={{ backgroundColor: "#fff" }}>
              <div style={{ display: "flex", marginTop: "24px" }}>
                <div>
                  <div
                    style={{
                      width: "320px",
                      maxHeight: "500px",
                      overflowY: "auto",
                    }}
                  >
                    <Tree onSelect={this.onSelect} selectedKeys={selectedKeys}>
                      <Tree.TreeNode
                        style={{
                          marginLeft: "-24px",
                        }}
                        title={
                          <span style={Style}>
                            <span style={Style1}>
                              <Avatar
                                shape="square"
                                size={27}
                                style={{
                                  background: "#1775FF",
                                  marginRight: "6px",
                                }}
                              >
                                {userInfo.brokerName ? userInfo.brokerName.slice(0, 1) : "-"}
                              </Avatar>
                              <Tooltip placement="left" title={userInfo.brokerName}>
                                {userInfo.brokerName}
                              </Tooltip>
                            </span>
                          </span>
                        }
                        key={""}
                      />
                      {(docKindsMap || []).map(data => (
                        <Tree.TreeNode
                          title={
                            <span style={Style}>
                              <span style={Style1}>
                                <ApartmentOutlined
                                  style={{
                                    fontSize: "14px",
                                    marginRight: "6px",
                                  }}
                                />
                                <Tooltip placement="left" title={data.departName}>
                                  {data.departName}
                                </Tooltip>
                              </span>
                              {!idShow ? (
                                <Dropdown
                                  menu={{
                                    items: [
                                      {
                                        key: "1",
                                        label: (
                                          <AddSection
                                            refreshTable={this.refreshTable}
                                            docKindList={this.docKindList}
                                            record={data}
                                          >
                                            编辑部门
                                          </AddSection>
                                        ),
                                      },

                                      {
                                        key: "2",
                                        label: <a onClick={() => this.DeleteFun(data)}>删除</a>,
                                      },
                                    ],
                                  }}
                                  placement="bottomRight"
                                >
                                  <MoreOutlined
                                    style={{
                                      fontSize: "22px",
                                    }}
                                  />
                                </Dropdown>
                              ) : (
                                <MoreOutlined
                                  style={{
                                    fontSize: "22px",
                                  }}
                                />
                              )}
                            </span>
                          }
                          key={data.id}
                        />
                      ))}
                    </Tree>
                  </div>
                  {!idShow ? (
                    <AddSection
                      refreshTable={this.refreshTable}
                      docKindList={this.docKindList}
                      record={{}}
                    >
                      <Button icon={<PlusOutlined />} style={{ width: "70%" }}>
                        新增部门
                      </Button>
                    </AddSection>
                  ) : (
                    ""
                  )}
                </div>
                <div style={{ flex: 1 }}>
                  <Table
                    dataSource={this.state.tData}
                    scroll={{ x: "max-content" }}
                    rowKey="id"
                    columns={columns}
                    size="middle"
                    pagination={{
                      size: "large",
                      showQuickJumper: true,
                      pageSize: 10,
                      total: parseInt(this.state.totalNum),
                      current: Number(this.state.currentPage),
                      showTotal: total => {
                        return "共 " + total + " 条记录 ";
                      },
                    }}
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <style>
          {`
                    .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal.ant-tree-node-selected{
                        
color: #1775FF;
background: #E7F1FF;
                    }
                    
                    `}
        </style>
      </div>
    );
  }
}

export default connect(
  state => {
    return state;
  },
  dispatch => ({
    change_userInfo: bindActionCreators(change_userInfo, dispatch),
  })
)(PersonQuery);

class SearchM extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {};
  }

  // 查询
  query = async () => {
    let _this = this;
    sendBody = this.formRef.current.getFieldsValue();
    console.log(sendBody);
    sendBody.pageNum = "1";
    sendBody.pageSize = "10";
    // sendBody.workType = window.routerCache.PersonQuery.send_workType || '';
    try {
      let res = await Axios("get", "/brokeruser/page", sendBody);
      console.log(sendBody);
      _this.props.refreshTableByData(res.data.dataList, res.data.totalNum, 1);
    } catch (err) {
      // message.error(err.message);
    }
  };
  clearOptions = async () => {
    sendBody = {};
    // 清空的时候清空地区
    this.setState(
      {
        province: "",
        city: "",
        qingkong: true,
      },
      () => {
        this.setState({
          qingkong: false,
        });
        this.formRef.current.resetFields();
      }
    );

    sendBody.pageNum = "1";
    sendBody.pageSize = "10";

    this.props.refreshTableByData([], 1, 1);

    try {
      let res = await Axios("get", "/brokeruser/page", sendBody);

      this.props.refreshTableByData(res.data.dataList, res.data.totalNum, 1);
    } catch (err) { }
  };
  clearLawfirm = async () => {
    window.routerCache.PersonQuery.workTypeStr = "";
    window.routerCache.PersonQuery.workType_status = "";
    sendBody.status = "";
    this.props.refreshTableByData([], 1, 1);
    this.setState({
      workType_status: "",
    });
    try {
      let res = await Axios("get", "/BrokerInfos/pageLists", sendBody);
      this.props.refreshTableByData(res.data.dataList, res.data.totalNum, 1);
    } catch (err) { }
  };
  // 选择日期范围
  dateChange = (value, b) => {
    console.log(value, b);
    if (value.length !== 0) {
      this.setState({ sDate: b[0].replace(/-/g, "") });
      this.setState({ eDate: b[1].replace(/-/g, "") });
    } else {
      this.setState({ sDate: "" });
      this.setState({ eDate: "" });
    }
  };

  // 组件渲染后获取外界数据(GET)
  componentDidMount () { }

  render () {
    /*控制查询按钮状态*/
    return (
      <div className="search_like_antD" style={{ marginTop: "-8px" }}>
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} ref={this.formRef}>
          <Row type="flex" align="middle">
            <Col span={8}>
              <Form.Item
                label="姓名："
                style={{ width: "100%" }}
                name="userName"
                initialValue={sendBody.userName}
              >
                <Input placeholder="请输入姓名" type="text" style={{ width: "80%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="手机号"
                style={{ width: "100%" }}
                name="mobileNo"
                initialValue={sendBody.mobileNo}
              >
                <Input placeholder="请输入手机号" type="text" style={{ width: "80%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="代理商"
                style={{ width: "100%" }}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="brokerName"
                initialValue={sendBody.brokerName}
              >
                <Input placeholder="请输入代理商" type="text" style={{ width: "80%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" align="middle">
            <Col span={8}>
              <Form.Item
                label="状态："
                style={{ width: "100%" }}
                name="status"
                initialValue={sendBody.status}
              >
                <Select style={{ width: "80%" }} placeholder="请选择" allowClear>
                  <Option value="0">启用</Option>
                  <Option value="1">禁用</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}></Col>
            <Col span={8}>
              <Row
                type="flex"
                justify="end"
                align="middle"
                style={{ width: "85%", height: "30px" }}
              >
                <Form.Item style={{ marginRight: "16px" }}>
                  <Button type="primary" onClick={this.query}>
                    查询
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button onClick={this.clearOptions}>重置</Button>
                </Form.Item>
              </Row>
            </Col>
          </Row>
          <div>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={
                window.routerCache.PersonQuery.workTypeStr
                  ? {
                    paddingBottom: "10px",
                  }
                  : { paddingBottom: "40px" }
              }
            >
              {window.routerCache.PersonQuery.workTypeStr ? (
                <Alert
                  message={
                    <div>
                      <span>{window.routerCache.PersonQuery.workTypeStr}</span>
                      &nbsp;&nbsp;
                      <a onClick={this.clearLawfirm}>清空</a>
                      &nbsp;&nbsp;
                    </div>
                  }
                  type="info"
                  showIcon
                  style={{ marginTop: "12px" }}
                />
              ) : (
                ""
              )}
            </Row>
          </div>
        </Form>
        <style>{`
               .search_like_antD .ant-form ant-form-inline .ant-row-flex .ant-row-flex-space-between .ant-row-flex-middle{
                    padding: 0px !important;
                }
                `}</style>
      </div>
    );
  }
}

let Search = connect(
  state => {
    return state;
  },
  dispatch => ({})
)(SearchM);

class ForbiddenM extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      BrokerOption: [],
      loading: false,
      confirmLoading: false,
      Forbid: false,
      open: false,
      tData: [],
      isTrue1: false,
      batchList: [],
      isAbled: false,
    };
  }
  componentWillMount () { }
  // 单击确定按钮提交表单
  handleSubmit = e => {
    let { record, index } = this.props;
    e.preventDefault();
    this.formRef.current
      .validateFields()
      .then(async values => {
        console.log(values, record.id, "valuesvalues");
        values.brokerUserId = record.id;
        let arr = this.state.batchList;
        arr.push(values);
        let arr1 = [];
        this.state.BrokerOption.map(v => {
          if (!values.codes.includes(v.code)) arr1.push(v);
        });
        this.formRef.current.setFieldsValue({
          codes: undefined,
        });
        this.setState(
          {
            BrokerOption: arr1,
            batchList: arr,
          },
          () => {
            if (!this.state.BrokerOption.length) {
              this.setState({ isAbled: true }, () => {
                this.formRef.current.setFieldsValue({
                  toBrokerUserId: undefined,
                });
              });
            }
          }
        );
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };
  // 弹出框设置
  showModal = async istrues => {
    console.log(this.state.open);
    try {
      let { record } = this.props;
      let res = await Axios("GET", "/brokeruser/module/data", {
        brokerUserId: record.id,
      });
      let res1 = await Axios("get", "/brokeruser/all/page", {
        pageNum: "1",
        pageSize: "1000",
        status: 0,
      });
      if (res.code == "200" && res1.code == "200") {
        let is = res.data.every(v => v.count == 0);
        if (is) {
          this.setState({ BrokerOption: [], isAbled: true });
          if (!istrues) {
            confirm({
              title: "禁用后此账号将无法登录",
              icon: <ExclamationCircleOutlined />,
              maskClosable: true,
              content: null,
              onOk: () => {
                this.CheckStatus(record, 1, false);
              },
              onCancel () {
                console.log("Cancel");
              },
            });
          }
        } else {
          let obj = res.data.filter(v => v.count != 0);
          if (obj.length) {
            this.setState(
              {
                visible: true,
                BrokerOption: obj,
                tData: res1.data.dataList,
                batchList: [],
                isTrue1: false,
                isAbled: false,
              },
              () => {
                this.formRef.current.resetFields();
              }
            );
          } else {
            this.setState(
              {
                visible: true,
                BrokerOption: obj,
                tData: res1.data.dataList,
                isTrue1: false,
                batchList: [],
                isAbled: false,
              },
              () => {
                this.formRef.current.resetFields();
              }
            );
          }
        }
      } else {
        message.error(res.message);
      }
    } catch (err) {
      message.error(err.message);
    }
  };
  handleCancel = e => {
    this.setState({ visible: false });
  };
  handleOpenChange = value => {
    console.log("select=" + value);
  };
  Brokerusersi = async (record, status) => {
    await Axios("put", "/brokeruser/" + record.id, {
      status: status,
      departId: record.departId,
    }).then(res => {
      if (res.code === "200") {
        message.success(res.message);
        this.setState({
          visible: false,
          confirmLoading: false,
        });
        this.props.refreshTable();
      } else {
        this.setState({ confirmLoading: false });
      }
    });
  };
  getBatchList = (value, keys, batchList, record, status) => {
    keys = keys + 1;
    Axios("post", "/brokeruser/module/transfer/batch", value).then(res => {
      if (res.code === "200") {
        if (keys < batchList.length) {
          this.getBatchList(batchList[keys], keys, batchList, record, status);
        } else {
          this.Brokerusersi(record, status);
        }
      } else {
        this.setState({ confirmLoading: false });
      }
    });
  };
  CheckStatus = async (record, status, isTrues12) => {
    if (this.state.BrokerOption.length) {
      this.setState({ isTrue1: true });
      return;
    }
    if (isTrues12) {
      this.setState({ confirmLoading: true });
      // this.state.batchList.map(async (v) => {
      //     await Axios("post", "/brokeruser/module/transfer/batch", v);
      // });
      this.getBatchList(this.state.batchList[0], 0, this.state.batchList, record, status);
    } else {
      this.Brokerusersi(record, status);
      // Axios("put", "/brokeruser/" + record.id, {
      //     status: status,
      //     departId: record.departId,
      // }).then((res) => {
      //     if (res.code === "200") {
      //         message.success(res.message);
      //         this.setState({
      //             visible: false,
      //             confirmLoading: false,
      //         });
      //         this.props.refreshTable();
      //     } else {
      //         this.setState({ confirmLoading: false });
      //     }
      // });
    }
  };
  confirm = () => {
    this.setState({ open: true });
  };
  render () {
    let { record, index } = this.props;
    let { BrokerOption, tData, isAbled } = this.state;
    return (
      <span>
        <span onClick={() => this.showModal(false)}>{this.props.children}</span>
        <Modal
          title={
            <span>
              禁用账号
              <span
                style={{
                  marginLeft: "6px",
                  fontSize: "14px",
                  color: "#A7AAAD",
                }}
              >
                (已完成/已退款/已关闭状态的订单或已关闭/已结案/异常结案的案件不能分配)
              </span>
            </span>
          }
          open={this.state.visible}
          onOk={() => this.CheckStatus(record, 1, true)}
          width={650}
          // maskClosable={false}
          // closable={false}
          confirmLoading={this.state.confirmLoading}
          onCancel={() => {
            this.setState({ visible: false });
          }}
        >
          <p className="p">
            在禁用该账号前，请先把该账号负责的工作转移给其他人；目前该账号需要转移的工作有以下几项：
          </p>
          <Form
            ref={this.formRef}
            layout="horizontal"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Form.Item
              name="codes"
              label={null}
              rules={[
                {
                  required: true,
                  message: "请选择要分配的项目",
                },
              ]}
            >
              <Checkbox.Group className="checkbox-group">
                {BrokerOption.map(v => (
                  <Checkbox value={v.code} key={v.code}>
                    {v.name} ({v.count})
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
            <span className="DivImg">
              <span>转移给</span>
              <img src={require("../../assets/images/jt.png")} alt="" />
            </span>
            <Form.Item
              name="toBrokerUserId"
              label={null}
              rules={[
                {
                  required: true,
                  message: "请选择分配人",
                },
              ]}
            >
              <Radio.Group className="checkbox-group" disabled={isAbled}>
                <Space direction="vertical">
                  {tData.map(v => {
                    if (v.id != record.id)
                      return (
                        <Radio value={v.id} key={v.id}>
                          {v.userName}（
                          {v.userRole == "1" ? "代理商" : v.job === "senior" ? "高管" : "员工"}）
                        </Radio>
                      );
                  })}
                </Space>
              </Radio.Group>
            </Form.Item>
            <Button type="primary" ghost className="ButtonS" onClick={this.handleSubmit}>
              确定
            </Button>
          </Form>
          {this.state.isTrue1 ? (
            <p style={{ color: "#ff4d4f" }}>请先把该账号负责的项目转移给其他人</p>
          ) : (
            ""
          )}
        </Modal>
        <style>{`
                .checkbox-group{
                    width: 250px;
                    padding-left: 20px;
                    border: 1px solid #E7E7E7;
                    height: 320px;
                    overflow-x: auto;
                }
                .ButtonS{
                    position: absolute;
                    right: 10%;
                    bottom: 11%;
                }s
                .p{
                    font-size: 16px;
                    margin-bottom: 14px;
                }
                .ant-checkbox-group{
                    width: 250px;
                }
                .ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item{
                    margin-top:14px  !important;
                }
                .ant-space-item{
                    margin: 6px 0 !important;
                }
                .ant-space-item{
                    width: 218px;
                }
                .DivImg{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                }
                .DivImg img{
                  width: 60px;
                }
                .DivImg span{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }
                `}</style>
      </span>
    );
  }
}

let Forbidden = connect(
  state => {
    return state;
  },
  dispatch => ({})
)(ForbiddenM);

class AddBrokerM extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      selectOption: [],
      loading: false,
      confirmLoading: false,
    };
  }
  componentWillMount () { }
  // 单击确定按钮提交表单
  handleSubmit = e => {
    e.preventDefault();
    this.formRef.current
      .validateFields()
      .then(async values => {
        try {
          let { record } = this.props;
          let url = "";
          let type = "";
          if (record.userName) {
            url = "/brokeruser/" + record.id;
            type = "put";
            delete values.mobileNo;
          } else {
            type = "post";
            url = "/brokeruser";
            values.id = values.departId || undefined;
            delete values.departId;
          }
          if (values.job == "senior") {
            delete values.id;
            delete values.departId;
          }
          console.log(values, "valuesvaluesvalues");
          this.setState({ confirmLoading: true });
          let res = await Axios(type, url, values);
          if (res.code == "200") {
            this.props.refreshTable();
            this.formRef.current.resetFields();
            message.success(res.message);
            this.setState({
              visible: false,
              confirmLoading: false,
            });
          } else {
            message.error(res.message);
            this.setState({ confirmLoading: false });
          }
        } catch (err) {
          message.error(err.message);
          this.setState({ confirmLoading: false });
        }
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };
  // 弹出框设置
  showModal = () => {
    this.setState({ visible: true });
  };
  handleCancel = e => {
    this.setState({ visible: false });
  };
  handleSelectChange = value => {
    console.log("select=" + value);
  };

  render () {
    const brokerIds = localStorage.getItem("brokerIds");
    let { record, docKindsMap, selectedKeys } = this.props;
    let { userInfo = {} } = this.props;
    if ("{}" == JSON.stringify(userInfo)) userInfo = JSON.parse(userInfos);
    // console.log(, "sdfsdfsdfsdf");
    let idDisabled = userInfo.job == "director" || userInfo.job == "groupLeader";
    return (
      <span>
        <span
          onClick={() => {
            this.setState({ visible: true }, () => {
              this.formRef.current.setFieldsValue({
                departId: undefined,
              });

              if (selectedKeys.length && selectedKeys[0]) {
                record.departId = selectedKeys[0] * 1;
              }
              this.formRef.current.setFieldsValue(record);
            });
          }}
        >
          {this.props.children}
        </span>
        <Modal
          title={record.id ? "修改成员" : "新增成员"}
          open={this.state.visible}
          onOk={this.handleSubmit}
          confirmLoading={this.state.confirmLoading}
          onCancel={() => {
            this.setState({ visible: false });
          }}
        >
          <Form
            ref={this.formRef}
            layout="horizontal"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
          >
            <Form.Item
              label="部门"
              name="departId"
              initialValue={record.departId || undefined}
              rules={[
                {
                  required: false,
                  message: "请选择部门",
                },
              ]}
            >
              <Select
                placeholder="请选择"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  (Array.isArray(option.props.children)
                    ? option.props.children.join("")
                    : option.props.children
                  )
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {(docKindsMap || []).map(v => (
                  <Option value={v.id} key={v.id}>
                    {v.departName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="姓名"
              name="userName"
              initialValue={record.userName || undefined}
              rules={[
                {
                  required: true,
                  message: "姓名不能为空",
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="请输入姓名" type="text" />
            </Form.Item>
            <Form.Item
              label="手机号"
              name="mobileNo"
              initialValue={record.mobileNo || undefined}
              rules={[
                {
                  required: true,
                  message: "手机号不能为空",
                },
                {
                  message: "手机号不合法",
                  pattern: /^((1[0-9]{2})+\d{8})$/,
                },
              ]}
            >
              <Input
                placeholder="请输入联系人手机号"
                type="text"
                maxLength={11}
                disabled={record.mobileNo}
              />
            </Form.Item>
            <Form.Item
              label="账号角色"
              name="job"
              initialValue={record.mobileNo ? (record.job ? record.job : "worker") : undefined}
              rules={[
                {
                  required: true,
                  message: "请选择账号角色",
                },
              ]}
            >
              <Radio.Group>
                <Radio value="senior" disabled={idDisabled}>
                  高管
                </Radio>
                <Radio value="director" disabled={idDisabled}>
                  部门主管
                </Radio>
                <Radio value="groupLeader" disabled={idDisabled}>
                  部门组长
                </Radio>
                <Radio value="worker" disabled={idDisabled && record.id == brokerIds}>
                  员工
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Modal>
      </span>
    );
  }
}

let AddBroker = connect(
  state => {
    return state;
  },
  dispatch => ({
    change_userInfo: bindActionCreators(change_userInfo, dispatch),
  })
)(AddBrokerM);

class AddSectionM extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      selectOption: [],
      loading: false,
      confirmLoading: false,
    };
  }
  componentWillMount () { }
  // 单击确定按钮提交表单
  handleSubmit = e => {
    e.preventDefault();
    this.formRef.current
      .validateFields()
      .then(async values => {
        try {
          let { record } = this.props;
          if (record.id) {
            values.id = record.id;
          }
          this.setState({ confirmLoading: true });
          let res = await Axios("post", "/brokeruser/add/department", values);
          if (res.code == "200") {
            this.props.docKindList();
            if (record.id) {
              this.props.refreshTable();
            }
            this.formRef.current.resetFields();
            message.success(res.message);
            this.setState({
              visible: false,
              confirmLoading: false,
            });
          } else {
            message.error(res.message);
            this.setState({ confirmLoading: false });
          }
        } catch (err) {
          message.error(err.message);
          this.setState({ confirmLoading: false });
        }
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };
  // 弹出框设置
  showModal = () => {
    this.setState({ visible: true });
  };
  handleCancel = e => {
    this.setState({ visible: false });
  };
  handleSelectChange = value => {
    console.log("select=" + value);
  };

  render () {
    let { record } = this.props;

    return (
      <span>
        <span
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
          onClick={() => {
            this.setState({ visible: true }, () => {
              this.formRef.current.setFieldsValue(record);
            });
          }}
        >
          {" "}
          {this.props.children}
        </span>
        <Modal
          title={record.id ? "编辑部门" : "添加部门"}
          open={this.state.visible}
          onOk={this.handleSubmit}
          confirmLoading={this.state.confirmLoading}
          onCancel={() => {
            this.setState({ visible: false });
          }}
        >
          <Form
            ref={this.formRef}
            layout="horizontal"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
          >
            <Form.Item
              label="部门名称"
              name="departName"
              initialValue={record.departName}
              rules={[
                {
                  required: true,
                  message: "请输入部门名称",
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="请输入部门名称" type="text" maxLength={50} />
            </Form.Item>
          </Form>
        </Modal>
      </span>
    );
  }
}

let AddSection = connect(
  state => {
    return state;
  },
  dispatch => ({})
)(AddSectionM);

class QuestionM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      selectOption: [],
      loading: false,
      confirmLoading: false,
    };
  }
  componentWillMount () { }

  // 弹出框设置
  showModal = () => {
    this.setState({ visible: true });
  };
  handleCancel = e => {
    this.setState({ visible: false });
  };
  handleSelectChange = value => {
    console.log("select=" + value);
  };

  render () {
    let Styles1 = {
      width: "155px",
      display: "flex",
      alignItems: "center",
      padding: " 6px 6px",
      borderRight: "1px solid rgb(236,236,236)",
      borderBottom: "1px solid rgb(236,236,236)",
    };
    let Styles2 = {
      width: "230px",
      display: "flex",
      alignItems: "center",
      padding: " 6px 6px",
      borderRight: "1px solid rgb(236,236,236)",
      borderBottom: "1px solid rgb(236,236,236)",
      color: "rgb(70,170,56)",
    };
    let Styles3 = {
      width: "70px",
      display: "flex",
      alignItems: "center",
      padding: " 6px 6px",
      borderRight: "1px solid rgb(236,236,236)",
      borderBottom: "1px solid rgb(236,236,236)",
    };
    let data = [
      {
        title: "首页",
        subhead: "首页",
        type: "页面",
        boss: "可操作（可查看所有业务数据）",
        executive: "可操作（可查看所有业务数据）",
        executive1: "可操作（可查看本人的业务数据）",
        executive2: "可操作（可查看本人的业务数据）",
        staff: "可操作（可查看本人的业务数据）",
      },
      {
        title: "客户管理",
        subhead: "付费企业客户管理",
        type: "页面",
        boss: "可操作（可查看所有客户）",
        executive: "可操作（可查看所有客户）",
        executive1: "可操作（可查看本部门的所有客户）",
        executive2: "可操作（可查看本人的客户）",
        staff: "可操作（可查看本人的客户）",
        rowSpan: 4,
      },
      {
        title: "客户管理",
        subhead: "续费客户管理",
        type: "页面",
        boss: "可操作（可查看所有客户）",
        executive: "可操作（可查看所有客户）",
        executive1: "可操作（可查看本部门的所有客户）",
        executive2: "可操作（可查看本部门的所有客户）",
        staff: "可操作（可查看本人的客户）",
        colSpan: 0,
      },
      {
        title: "客户管理",
        subhead: "意向客户管理",
        type: "页面",
        boss: "可操作（可查看所有客户）",
        executive: "可操作（可查看所有客户）",
        executive1: "可操作（可查看本部门的所有客户）",
        executive2: "可操作（可查看本部门的所有客户）",
        staff: "可操作（可查看本人的客户）",
        colSpan: 0,
      },
      {
        title: "客户管理",
        subhead: "线索客户管理",
        type: "页面",
        boss: "可操作（可查看所有客户）",
        executive: "可操作（可查看所有客户）",
        executive1: "可操作（可查看本部门的所有客户）",
        executive2: "可操作（可查看本部门的所有客户）",
        staff: "可操作（可查看本人的客户）",
        colSpan: 0,
      },
      {
        title: "订单管理",
        subhead: "直客订单管理",
        type: "页面",
        boss: "可操作（可查看所有订单）",
        executive: "可操作（可查看所有订单）",
        executive1: "可操作（可查看本部门的所有订单）",
        executive2: "可操作（可查看本人的订单）",
        staff: "可操作（可查看本人的订单）",
        rowSpan: 2,
      },
      {
        title: "订单管理",
        subhead: "电子合同",
        type: "页面",
        boss: "可操作（可查看所有合同）",
        executive: "可操作（可查看所有合同）",
        executive1: "可操作（可查看本部门的所有合同）",
        executive2: "可操作（可查看本人的合同）",
        staff: "可操作（可查看本人的合同）",
        colSpan: 0,
      },
      {
        title: "销售对账管理",
        subhead: "销售对账管理",
        type: "页面",
        boss: "可操作",
        executive: "可操作",
        executive1: "可操作",
        executive2: "不可操作",
        staff: "不可操作",
      },
      {
        title: "营销获客",
        subhead: "企业精准查询",
        type: "页面",
        boss: "可操作",
        executive: "可操作",
        executive1: "可操作",
        executive2: "可操作",
        staff: "不可操作",
        rowSpan: 3,
      },
      {
        title: "营销获客",
        subhead: "名单资料管理",
        type: "页面",
        boss: "可操作",
        executive: "可操作",
        executive1: "可操作",
        executive2: "可操作",
        staff: "不可操作",
        colSpan: 0,
      },
      {
        title: "营销获客",
        subhead: "新增机会",
        type: "页面",
        boss: "可操作",
        executive: "可操作",
        executive1: "可操作",
        executive2: "可操作",
        staff: "可操作（不可生成报表）",
        colSpan: 0,
      },
      {
        title: "案件管理",
        subhead: "案件管理",
        type: "页面",
        boss: "可操作（可查看所有案件）",
        executive: "可操作（可查看所有案件）",
        executive1: "可操作（可查看本部门的所有案件）",
        executive2: "可操作（可查看本人的案件）",
        staff: "可操作（可查看本人的案件）",
      },
      {
        title: "运营商关联订单",
        subhead: "运营商关联订单",
        type: "页面",
        boss: "可操作（可查看所有订单）",
        executive: "可操作（可查看所有订单）",
        executive1: "不可操作",
        executive2: "不可操作",
        staff: "不可操作",
      },
      {
        title: "非诉清收",
        subhead: "非诉清收",
        type: "页面",
        boss: "可操作（可查看所有案件）",
        executive: "可操作（可查看所有案件）",
        executive1: "可操作（可查看本部门的所有案件）",
        executive2: "可操作（可查看本部门的案件）",
        staff: "可操作（可查看本人的案件）",
      },
      {
        title: "物业管理",
        subhead: "案件管理",
        type: "页面",
        boss: "可操作（可查看所有案件）",
        executive: "可操作（可查看所有案件）",
        executive1: "可操作（可查看本部门的所有案件）",
        executive2: "可操作（可查看本部门的案件）",
        staff: "可操作（可查看本人的案件）",
        rowSpan: 2,
      },
      {
        title: "物业管理",
        subhead: "回款管理",
        type: "页面",
        boss: "可操作（可查看所有回款）",
        executive: "可操作（可查看所有回款）",
        executive1: "可操作（可查看本部门的所有回款）",
        executive2: "可操作（可查看本部门的回款）",
        staff: "可操作（可查看本人的回款）",
        colSpan: 0,
      },
      {
        title: "赢火虫商学院",
        subhead: "赢火虫商学院",
        type: "页面",
        boss: "可操作",
        executive: "可操作",
        executive1: "可操作",
        executive2: "可操作",
        staff: "可操作",
      },
      {
        title: "成员与部门管理",
        subhead: "成员与部门管理",
        type: "页面",
        boss: "可操作",
        executive: "可操作",
        executive1: "可操作",
        executive2: "可操作",
        staff: "不可操作",
      },
      {
        title: "业务分析",
        subhead: "业务分析",
        type: "页面",
        boss: "可操作（可查看所有业务情况）",
        executive: "可操作（可查看所有业务情况）",
        executive1: "可操作（可查看本部门的业务情况）",
        executive2: "付费客户数、销售漏斗：可操作（查看本人的客户）",
        staff: "可操作（可查看本人的业务情况）",
        rowSpan: 2,
      },
      {
        title: "",
        subhead: "",
        type: "",
        boss: "/",
        executive: "/",
        executive1: "/",
        executive2: "意向客户数、待续费客户数、续费客户数、成交额：可操作（可查看本部门的业务情况）",
        staff: "/",
        colSpan: 0,
      },
      {
        title: "业务申诉管理",
        subhead: "业务申诉管理",
        type: "页面",
        boss: "可操作（可查看所有申诉）",
        executive: "可操作（可查看所有申诉）",
        executive1: "可操作（可查看本部门的所有申诉）",
        executive2: "可操作（可查看本部门的所有申诉）",
        staff: "可操作（可查看本人的申诉）",
      },
      {
        title: "设置",
        subhead: "个人信息",
        type: "页面",
        boss: "可操作（可查看本人的信息）",
        executive: "可操作（可查看本人的信息）",
        executive1: "可操作（可查看本人的信息）",
        executive2: "可操作（可查看本人的信息）",
        staff: "可操作（可查看本人的信息）",
        rowSpan: 2,
      },
      {
        title: "设置",
        subhead: "安全设置",
        type: "页面",
        boss: "可操作（可查看本人的信息）",
        executive: "可操作（可查看本人的信息）",
        executive1: "可操作（可查看本人的信息）",
        executive2: "可操作（可查看本人的信息）",
        staff: "可操作（可查看本人的信息）",
        colSpan: 0,
      },
    ];
    const columns = [
      {
        title: "一级",
        dataIndex: "title",
        onCell: (record) => record
      },
      {
        title: "二级",
        dataIndex: "subhead",
      },
      {
        title: "类型说明",
        dataIndex: "type",
      },
      {
        title: "老板",
        dataIndex: "boss",
        render: (text) => <span style={{ color: text == '不可操作' ? 'red' : 'rgb(70,170,56)' }}>{text}</span>,
      },
      {
        title: "高管",
        dataIndex: "executive",
        render: (text) => <span style={{ color: text == '不可操作' ? 'red' : 'rgb(70,170,56)' }}>{text}</span>,
      },
      {
        title: "部门主管",
        dataIndex: "executive1",
        render: (text) => <span style={{ color: text == '不可操作' ? 'red' : 'rgb(70,170,56)' }}>{text}</span>,
      },
      {
        title: "部门组长",
        dataIndex: "executive2",
        width: 244,
        render: (text) => <span style={{ color: text == '不可操作' ? 'red' : 'rgb(70,170,56)' }}>{text}</span>,
      },
      {
        title: "员工",
        dataIndex: "staff",
        render: (text) => <span style={{ color: text == '不可操作' ? 'red' : 'rgb(70,170,56)' }}>{text}</span>,
      },
    ];
    return (
      <span>
        <QuestionCircleOutlined
          onClick={() => {
            this.setState({ visible: true });
          }}
        >
          新增业务员
        </QuestionCircleOutlined>
        <Modal
          title="权限目录表"
          open={this.state.visible}
          onOk={this.handleSubmit}
          width='80%'
          confirmLoading={this.state.confirmLoading}
          onCancel={() => {
            this.setState({ visible: false });
          }}
          footer={null}
        >
          <Table columns={columns} dataSource={data} bordered size="small" pagination={false} />
        </Modal>
      </span>
    );
  }
}

let Question = connect(
  state => {
    return state;
  },
  dispatch => ({})
)(QuestionM);
