export default {
  ProvinceData: [
    { name: "北京", code: "110000", level: 1, firstChar: null },
    { name: "天津", code: "12000", level: 1, firstChar: null },
    { name: "河北", code: "130000", level: 1, firstChar: null },
    { name: "山西", code: "140000", level: 1, firstChar: null },
    { name: "内蒙古", code: "150000", level: 1, firstChar: null },
    { name: "辽宁", code: "210000", level: 1, firstChar: null },
    { name: "吉林", code: "220000", level: 1, firstChar: null },
    { name: "黑龙江", code: "230000", level: 1, firstChar: null },
    { name: "上海", code: "310000", level: 1, firstChar: null },
    { name: "江苏", code: "320000", level: 1, firstChar: null },
    { name: "浙江", code: "330000", level: 1, firstChar: null },
    { name: "安徽", code: "340000", level: 1, firstChar: null },
    { name: "福建", code: "350000", level: 1, firstChar: null },
    { name: "江西", code: "360000", level: 1, firstChar: null },
    { name: "山东", code: "370000", level: 1, firstChar: null },
    { name: "河南", code: "410000", level: 1, firstChar: null },
    { name: "湖北", code: "420000", level: 1, firstChar: null },
    { name: "湖南", code: "430000", level: 1, firstChar: null },
    { name: "广东", code: "440000", level: 1, firstChar: null },
    { name: "广西", code: "450000", level: 1, firstChar: null },
    { name: "海南", code: "460000", level: 1, firstChar: null },
    { name: "重庆", code: "500000", level: 1, firstChar: null },
    { name: "四川", code: "510000", level: 1, firstChar: null },
    { name: "贵州", code: "520000", level: 1, firstChar: null },
    { name: "云南", code: "530000", level: 1, firstChar: null },
    { name: "西藏", code: "540000", level: 1, firstChar: null },
    { name: "陕西", code: "610000", level: 1, firstChar: null },
    { name: "甘肃", code: "620000", level: 1, firstChar: null },
    { name: "青海", code: "630000", level: 1, firstChar: null },
    { name: "宁夏", code: "640000", level: 1, firstChar: null },
    { name: "新疆", code: "650000", level: 1, firstChar: null },
    { name: "台湾", code: "710000", level: 1, firstChar: null },
    { name: "香港", code: "810000", level: 1, firstChar: null },
    { name: "澳门", code: "820000", level: 1, firstChar: null },
  ],
  CityData: {
    北京: [{ name: "北京市", code: "110100", level: 2, firstChar: "B" }],
    天津: [{ name: "天津市", code: "120100", level: 2, firstChar: "T" }],
    河北: [
      { name: "石家庄市", code: "130100", level: 2, firstChar: "S" },
      { name: "唐山市", code: "130200", level: 2, firstChar: "T" },
      { name: "秦皇岛市", code: "130300", level: 2, firstChar: "Q" },
      { name: "邯郸市", code: "130400", level: 2, firstChar: "H" },
      { name: "邢台市", code: "130500", level: 2, firstChar: "X" },
      { name: "保定市", code: "130600", level: 2, firstChar: "B" },
      { name: "张家口市", code: "130700", level: 2, firstChar: "Z" },
      { name: "承德市", code: "130800", level: 2, firstChar: "C" },
      { name: "沧州市", code: "130900", level: 2, firstChar: "C" },
      { name: "廊坊市", code: "131000", level: 2, firstChar: "L" },
      { name: "衡水市", code: "131100", level: 2, firstChar: "H" },
      { name: "雄安新区", code: "130638", level: 2, firstChar: "X" },
    ],
    山西: [
      { name: "太原市", code: "140100", level: 2, firstChar: "T" },
      { name: "大同市", code: "140200", level: 2, firstChar: "D" },
      { name: "阳泉市", code: "140300", level: 2, firstChar: "Y" },
      { name: "长治市", code: "140400", level: 2, firstChar: "Z" },
      { name: "晋城市", code: "140500", level: 2, firstChar: "J" },
      { name: "朔州市", code: "140600", level: 2, firstChar: "S" },
      { name: "晋中市", code: "140700", level: 2, firstChar: "J" },
      { name: "运城市", code: "140800", level: 2, firstChar: "Y" },
      { name: "忻州市", code: "140900", level: 2, firstChar: "X" },
      { name: "临汾市", code: "141000", level: 2, firstChar: "L" },
      { name: "吕梁市", code: "141100", level: 2, firstChar: "L" },
    ],
    内蒙古: [
      { name: "呼和浩特市", code: "150100", level: 2, firstChar: "H" },
      { name: "包头市", code: "150200", level: 2, firstChar: "B" },
      { name: "乌海市", code: "150300", level: 2, firstChar: "W" },
      { name: "赤峰市", code: "150400", level: 2, firstChar: "C" },
      { name: "通辽市", code: "150500", level: 2, firstChar: "T" },
      { name: "鄂尔多斯市", code: "150600", level: 2, firstChar: "E" },
      { name: "呼伦贝尔市", code: "150700", level: 2, firstChar: "H" },
      { name: "巴彦淖尔市", code: "150800", level: 2, firstChar: "B" },
      { name: "乌兰察布市", code: "150900", level: 2, firstChar: "W" },
      { name: "兴安盟", code: "152200", level: 2, firstChar: "X" },
      { name: "锡林郭勒盟", code: "152500", level: 2, firstChar: "X" },
      { name: "阿拉善盟", code: "152900", level: 2, firstChar: "A" },
    ],
    辽宁: [
      { name: "沈阳市", code: "210100", level: 2, firstChar: "S" },
      { name: "大连市", code: "210200", level: 2, firstChar: "D" },
      { name: "鞍山市", code: "210300", level: 2, firstChar: "A" },
      { name: "抚顺市", code: "210400", level: 2, firstChar: "F" },
      { name: "本溪市", code: "210500", level: 2, firstChar: "B" },
      { name: "丹东市", code: "210600", level: 2, firstChar: "D" },
      { name: "锦州市", code: "210700", level: 2, firstChar: "J" },
      { name: "营口市", code: "210800", level: 2, firstChar: "Y" },
      { name: "阜新市", code: "210900", level: 2, firstChar: "F" },
      { name: "辽阳市", code: "211000", level: 2, firstChar: "L" },
      { name: "盘锦市", code: "211100", level: 2, firstChar: "P" },
      { name: "铁岭市", code: "211200", level: 2, firstChar: "T" },
      { name: "朝阳市", code: "211300", level: 2, firstChar: "C" },
      { name: "葫芦岛市", code: "211400", level: 2, firstChar: "H" },
    ],
    吉林: [
      { name: "长春市", code: "220100", level: 2, firstChar: "Z" },
      { name: "吉林市", code: "220200", level: 2, firstChar: "J" },
      { name: "四平市", code: "220300", level: 2, firstChar: "S" },
      { name: "辽源市", code: "220400", level: 2, firstChar: "L" },
      { name: "通化市", code: "220500", level: 2, firstChar: "T" },
      { name: "白山市", code: "220600", level: 2, firstChar: "B" },
      { name: "松原市", code: "220700", level: 2, firstChar: "S" },
      { name: "白城市", code: "220800", level: 2, firstChar: "B" },
      { name: "延边朝鲜族自治州", code: "222400", level: 2, firstChar: "Y" },
    ],
    黑龙江: [
      { name: "哈尔滨市", code: "230100", level: 2, firstChar: "H" },
      { name: "齐齐哈尔市", code: "230200", level: 2, firstChar: "Q" },
      { name: "鸡西市", code: "230300", level: 2, firstChar: "J" },
      { name: "鹤岗市", code: "230400", level: 2, firstChar: "H" },
      { name: "双鸭山市", code: "230500", level: 2, firstChar: "S" },
      { name: "大庆市", code: "230600", level: 2, firstChar: "D" },
      { name: "伊春市", code: "230700", level: 2, firstChar: "Y" },
      { name: "佳木斯市", code: "230800", level: 2, firstChar: "J" },
      { name: "七台河市", code: "230900", level: 2, firstChar: "Q" },
      { name: "牡丹江市", code: "231000", level: 2, firstChar: "M" },
      { name: "黑河市", code: "231100", level: 2, firstChar: "H" },
      { name: "绥化市", code: "231200", level: 2, firstChar: "S" },
      { name: "大兴安岭地区", code: "232700", level: 2, firstChar: "D" },
    ],
    上海: [{ name: "上海市", code: "310100", level: 2, firstChar: "S" }],
    江苏: [
      { name: "南京市", code: "320100", level: 2, firstChar: "N" },
      { name: "无锡市", code: "320200", level: 2, firstChar: "W" },
      { name: "徐州市", code: "320300", level: 2, firstChar: "X" },
      { name: "常州市", code: "320400", level: 2, firstChar: "C" },
      { name: "苏州市", code: "320500", level: 2, firstChar: "S" },
      { name: "南通市", code: "320600", level: 2, firstChar: "N" },
      { name: "连云港市", code: "320700", level: 2, firstChar: "L" },
      { name: "淮安市", code: "320800", level: 2, firstChar: "H" },
      { name: "盐城市", code: "320900", level: 2, firstChar: "Y" },
      { name: "扬州市", code: "321000", level: 2, firstChar: "Y" },
      { name: "镇江市", code: "321100", level: 2, firstChar: "Z" },
      { name: "泰州市", code: "321200", level: 2, firstChar: "T" },
      { name: "宿迁市", code: "321300", level: 2, firstChar: "S" },
    ],
    浙江: [
      { name: "杭州市", code: "330100", level: 2, firstChar: "H" },
      { name: "宁波市", code: "330200", level: 2, firstChar: "N" },
      { name: "温州市", code: "330300", level: 2, firstChar: "W" },
      { name: "嘉兴市", code: "330400", level: 2, firstChar: "J" },
      { name: "湖州市", code: "330500", level: 2, firstChar: "H" },
      { name: "绍兴市", code: "330600", level: 2, firstChar: "S" },
      { name: "金华市", code: "330700", level: 2, firstChar: "J" },
      { name: "衢州市", code: "330800", level: 2, firstChar: "Q" },
      { name: "舟山市", code: "330900", level: 2, firstChar: "Z" },
      { name: "台州市", code: "331000", level: 2, firstChar: "T" },
      { name: "丽水市", code: "331100", level: 2, firstChar: "L" },
    ],
    安徽: [
      { name: "合肥市", code: "340100", level: 2, firstChar: "H" },
      { name: "芜湖市", code: "340200", level: 2, firstChar: "W" },
      { name: "蚌埠市", code: "340300", level: 2, firstChar: "B" },
      { name: "淮南市", code: "340400", level: 2, firstChar: "H" },
      { name: "马鞍山市", code: "340500", level: 2, firstChar: "M" },
      { name: "淮北市", code: "340600", level: 2, firstChar: "H" },
      { name: "铜陵市", code: "340700", level: 2, firstChar: "T" },
      { name: "安庆市", code: "340800", level: 2, firstChar: "A" },
      { name: "黄山市", code: "341000", level: 2, firstChar: "H" },
      { name: "滁州市", code: "341100", level: 2, firstChar: "C" },
      { name: "阜阳市", code: "341200", level: 2, firstChar: "F" },
      { name: "宿州市", code: "341300", level: 2, firstChar: "S" },
      { name: "六安市", code: "341500", level: 2, firstChar: "L" },
      { name: "亳州市", code: "341600", level: 2, firstChar: "B" },
      { name: "池州市", code: "341700", level: 2, firstChar: "C" },
      { name: "宣城市", code: "341800", level: 2, firstChar: "X" },
    ],
    福建: [
      { name: "福州市", code: "350100", level: 2, firstChar: "F" },
      { name: "厦门市", code: "350200", level: 2, firstChar: "S" },
      { name: "莆田市", code: "350300", level: 2, firstChar: "P" },
      { name: "三明市", code: "350400", level: 2, firstChar: "S" },
      { name: "泉州市", code: "350500", level: 2, firstChar: "Q" },
      { name: "漳州市", code: "350600", level: 2, firstChar: "Z" },
      { name: "南平市", code: "350700", level: 2, firstChar: "N" },
      { name: "龙岩市", code: "350800", level: 2, firstChar: "L" },
      { name: "宁德市", code: "350900", level: 2, firstChar: "N" },
    ],
    江西: [
      { name: "南昌市", code: "360100", level: 2, firstChar: "N" },
      { name: "景德镇市", code: "360200", level: 2, firstChar: "J" },
      { name: "萍乡市", code: "360300", level: 2, firstChar: "P" },
      { name: "九江市", code: "360400", level: 2, firstChar: "J" },
      { name: "新余市", code: "360500", level: 2, firstChar: "X" },
      { name: "鹰潭市", code: "360600", level: 2, firstChar: "Y" },
      { name: "赣州市", code: "360700", level: 2, firstChar: "G" },
      { name: "吉安市", code: "360800", level: 2, firstChar: "J" },
      { name: "宜春市", code: "360900", level: 2, firstChar: "Y" },
      { name: "抚州市", code: "361000", level: 2, firstChar: "F" },
      { name: "上饶市", code: "361100", level: 2, firstChar: "S" },
    ],
    山东: [
      { name: "济南市", code: "370100", level: 2, firstChar: "J" },
      { name: "青岛市", code: "370200", level: 2, firstChar: "Q" },
      { name: "淄博市", code: "370300", level: 2, firstChar: "Z" },
      { name: "枣庄市", code: "370400", level: 2, firstChar: "Z" },
      { name: "东营市", code: "370500", level: 2, firstChar: "D" },
      { name: "烟台市", code: "370600", level: 2, firstChar: "Y" },
      { name: "潍坊市", code: "370700", level: 2, firstChar: "W" },
      { name: "济宁市", code: "370800", level: 2, firstChar: "J" },
      { name: "泰安市", code: "370900", level: 2, firstChar: "T" },
      { name: "威海市", code: "371000", level: 2, firstChar: "W" },
      { name: "日照市", code: "371100", level: 2, firstChar: "R" },
      { name: "临沂市", code: "371300", level: 2, firstChar: "L" },
      { name: "德州市", code: "371400", level: 2, firstChar: "D" },
      { name: "聊城市", code: "371500", level: 2, firstChar: "L" },
      { name: "滨州市", code: "371600", level: 2, firstChar: "B" },
      { name: "菏泽市", code: "371700", level: 2, firstChar: "H" },
    ],
    河南: [
      { name: "郑州市", code: "410100", level: 2, firstChar: "Z" },
      { name: "开封市", code: "410200", level: 2, firstChar: "K" },
      { name: "洛阳市", code: "410300", level: 2, firstChar: "L" },
      { name: "平顶山市", code: "410400", level: 2, firstChar: "P" },
      { name: "安阳市", code: "410500", level: 2, firstChar: "A" },
      { name: "鹤壁市", code: "410600", level: 2, firstChar: "H" },
      { name: "新乡市", code: "410700", level: 2, firstChar: "X" },
      { name: "焦作市", code: "410800", level: 2, firstChar: "J" },
      { name: "濮阳市", code: "410900", level: 2, firstChar: "P" },
      { name: "许昌市", code: "411000", level: 2, firstChar: "X" },
      { name: "漯河市", code: "411100", level: 2, firstChar: "L" },
      { name: "三门峡市", code: "411200", level: 2, firstChar: "S" },
      { name: "南阳市", code: "411300", level: 2, firstChar: "N" },
      { name: "商丘市", code: "411400", level: 2, firstChar: "S" },
      { name: "信阳市", code: "411500", level: 2, firstChar: "X" },
      { name: "周口市", code: "411600", level: 2, firstChar: "Z" },
      { name: "驻马店市", code: "411700", level: 2, firstChar: "Z" },
      { name: "济源市", code: "419001", level: 2, firstChar: "J" },
    ],
    湖北: [
      { name: "武汉市", code: "420100", level: 2, firstChar: "W" },
      { name: "黄石市", code: "420200", level: 2, firstChar: "H" },
      { name: "十堰市", code: "420300", level: 2, firstChar: "S" },
      { name: "宜昌市", code: "420500", level: 2, firstChar: "Y" },
      { name: "襄阳市", code: "420600", level: 2, firstChar: "X" },
      { name: "鄂州市", code: "420700", level: 2, firstChar: "E" },
      { name: "荆门市", code: "420800", level: 2, firstChar: "J" },
      { name: "孝感市", code: "420900", level: 2, firstChar: "X" },
      { name: "荆州市", code: "421000", level: 2, firstChar: "J" },
      { name: "黄冈市", code: "421100", level: 2, firstChar: "H" },
      { name: "咸宁市", code: "421200", level: 2, firstChar: "X" },
      { name: "随州市", code: "421300", level: 2, firstChar: "S" },
      { name: "恩施土家族苗族自治州", code: "422800", level: 2, firstChar: "E" },
      { name: "仙桃市", code: "429004", level: 2, firstChar: "X" },
      { name: "潜江市", code: "429005", level: 2, firstChar: "Q" },
      { name: "天门市", code: "429006", level: 2, firstChar: "T" },
      { name: "神农架林区", code: "429021", level: 2, firstChar: "S" },
    ],
    湖南: [
      { name: "长沙市", code: "430100", level: 2, firstChar: "Z" },
      { name: "株洲市", code: "430200", level: 2, firstChar: "Z" },
      { name: "湘潭市", code: "430300", level: 2, firstChar: "X" },
      { name: "衡阳市", code: "430400", level: 2, firstChar: "H" },
      { name: "邵阳市", code: "430500", level: 2, firstChar: "S" },
      { name: "岳阳市", code: "430600", level: 2, firstChar: "Y" },
      { name: "常德市", code: "430700", level: 2, firstChar: "C" },
      { name: "张家界市", code: "430800", level: 2, firstChar: "Z" },
      { name: "益阳市", code: "430900", level: 2, firstChar: "Y" },
      { name: "郴州市", code: "431000", level: 2, firstChar: "C" },
      { name: "永州市", code: "431100", level: 2, firstChar: "Y" },
      { name: "怀化市", code: "431200", level: 2, firstChar: "H" },
      { name: "娄底市", code: "431300", level: 2, firstChar: "L" },
      { name: "湘西土家族苗族自治州", code: "433100", level: 2, firstChar: "X" },
    ],
    广东: [
      { name: "广州市", code: "440100", level: 2, firstChar: "G" },
      { name: "韶关市", code: "440200", level: 2, firstChar: "S" },
      { name: "深圳市", code: "440300", level: 2, firstChar: "S" },
      { name: "珠海市", code: "440400", level: 2, firstChar: "Z" },
      { name: "汕头市", code: "440500", level: 2, firstChar: "S" },
      { name: "佛山市", code: "440600", level: 2, firstChar: "F" },
      { name: "江门市", code: "440700", level: 2, firstChar: "J" },
      { name: "湛江市", code: "440800", level: 2, firstChar: "Z" },
      { name: "茂名市", code: "440900", level: 2, firstChar: "M" },
      { name: "肇庆市", code: "441200", level: 2, firstChar: "Z" },
      { name: "惠州市", code: "441300", level: 2, firstChar: "H" },
      { name: "梅州市", code: "441400", level: 2, firstChar: "M" },
      { name: "汕尾市", code: "441500", level: 2, firstChar: "S" },
      { name: "河源市", code: "441600", level: 2, firstChar: "H" },
      { name: "阳江市", code: "441700", level: 2, firstChar: "Y" },
      { name: "清远市", code: "441800", level: 2, firstChar: "Q" },
      { name: "东莞市", code: "441900", level: 2, firstChar: "D" },
      { name: "中山市", code: "442000", level: 2, firstChar: "Z" },
      { name: "潮州市", code: "445100", level: 2, firstChar: "C" },
      { name: "揭阳市", code: "445200", level: 2, firstChar: "J" },
      { name: "云浮市", code: "445300", level: 2, firstChar: "Y" },
    ],
    广西: [
      { name: "南宁市", code: "450100", level: 2, firstChar: "N" },
      { name: "柳州市", code: "450200", level: 2, firstChar: "L" },
      { name: "桂林市", code: "450300", level: 2, firstChar: "G" },
      { name: "梧州市", code: "450400", level: 2, firstChar: "W" },
      { name: "北海市", code: "450500", level: 2, firstChar: "B" },
      { name: "防城港市", code: "450600", level: 2, firstChar: "F" },
      { name: "钦州市", code: "450700", level: 2, firstChar: "Q" },
      { name: "贵港市", code: "450800", level: 2, firstChar: "G" },
      { name: "玉林市", code: "450900", level: 2, firstChar: "Y" },
      { name: "百色市", code: "451000", level: 2, firstChar: "B" },
      { name: "贺州市", code: "451100", level: 2, firstChar: "H" },
      { name: "河池市", code: "451200", level: 2, firstChar: "H" },
      { name: "来宾市", code: "451300", level: 2, firstChar: "L" },
      { name: "崇左市", code: "451400", level: 2, firstChar: "C" },
    ],
    海南: [
      { name: "海口市", code: "460100", level: 2, firstChar: "H" },
      { name: "三亚市", code: "460200", level: 2, firstChar: "S" },
      { name: "儋州市", code: "460400", level: 2, firstChar: "D" },
      { name: "五指山市", code: "469001", level: 2, firstChar: "W" },
      { name: "琼海市", code: "469002", level: 2, firstChar: "Q" },
      { name: "文昌市", code: "469005", level: 2, firstChar: "W" },
      { name: "万宁市", code: "469006", level: 2, firstChar: "W" },
      { name: "东方市", code: "469007", level: 2, firstChar: "D" },
      { name: "定安县", code: "469021", level: 2, firstChar: "D" },
      { name: "屯昌县", code: "469022", level: 2, firstChar: "T" },
      { name: "澄迈县", code: "469023", level: 2, firstChar: "C" },
      { name: "临高县", code: "469024", level: 2, firstChar: "L" },
      { name: "白沙黎族自治县", code: "469025", level: 2, firstChar: "B" },
      { name: "昌江黎族自治县", code: "469026", level: 2, firstChar: "C" },
      { name: "乐东黎族自治县", code: "469027", level: 2, firstChar: "L" },
      { name: "陵水黎族自治县", code: "469028", level: 2, firstChar: "L" },
      { name: "保亭黎族苗族自治县", code: "469029", level: 2, firstChar: "B" },
      { name: "琼中黎族苗族自治县", code: "469030", level: 2, firstChar: "Q" },
    ],
    重庆: [{ name: "重庆市", code: "500000", level: 2, firstChar: "Z" }],
    四川: [
      { name: "成都市", code: "510100", level: 2, firstChar: "C" },
      { name: "自贡市", code: "510300", level: 2, firstChar: "Z" },
      { name: "攀枝花市", code: "510400", level: 2, firstChar: "P" },
      { name: "泸州市", code: "510500", level: 2, firstChar: "L" },
      { name: "德阳市", code: "510600", level: 2, firstChar: "D" },
      { name: "绵阳市", code: "510700", level: 2, firstChar: "M" },
      { name: "广元市", code: "510800", level: 2, firstChar: "G" },
      { name: "遂宁市", code: "510900", level: 2, firstChar: "S" },
      { name: "内江市", code: "511000", level: 2, firstChar: "N" },
      { name: "乐山市", code: "511100", level: 2, firstChar: "L" },
      { name: "南充市", code: "511300", level: 2, firstChar: "N" },
      { name: "眉山市", code: "511400", level: 2, firstChar: "M" },
      { name: "宜宾市", code: "511500", level: 2, firstChar: "Y" },
      { name: "广安市", code: "511600", level: 2, firstChar: "G" },
      { name: "达州市", code: "511700", level: 2, firstChar: "D" },
      { name: "雅安市", code: "511800", level: 2, firstChar: "Y" },
      { name: "巴中市", code: "511900", level: 2, firstChar: "B" },
      { name: "资阳市", code: "512000", level: 2, firstChar: "Z" },
      { name: "阿坝藏族羌族自治州", code: "513200", level: 2, firstChar: "A" },
      { name: "甘孜藏族自治州", code: "513300", level: 2, firstChar: "G" },
      { name: "凉山彝族自治州", code: "513400", level: 2, firstChar: "L" },
    ],
    贵州: [
      { name: "贵阳市", code: "520100", level: 2, firstChar: "G" },
      { name: "六盘水市", code: "520200", level: 2, firstChar: "L" },
      { name: "遵义市", code: "520300", level: 2, firstChar: "Z" },
      { name: "安顺市", code: "520400", level: 2, firstChar: "A" },
      { name: "毕节市", code: "520500", level: 2, firstChar: "B" },
      { name: "铜仁市", code: "520600", level: 2, firstChar: "T" },
      { name: "黔西南布依族苗族自治州", code: "522300", level: 2, firstChar: "Q" },
      { name: "黔东南苗族侗族自治州", code: "522600", level: 2, firstChar: "Q" },
      { name: "黔南布依族苗族自治州", code: "522700", level: 2, firstChar: "Q" },
    ],
    云南: [
      { name: "昆明市", code: "530100", level: 2, firstChar: "K" },
      { name: "曲靖市", code: "530300", level: 2, firstChar: "Q" },
      { name: "玉溪市", code: "530400", level: 2, firstChar: "Y" },
      { name: "保山市", code: "530500", level: 2, firstChar: "B" },
      { name: "昭通市", code: "530600", level: 2, firstChar: "Z" },
      { name: "丽江市", code: "530700", level: 2, firstChar: "L" },
      { name: "普洱市", code: "530800", level: 2, firstChar: "P" },
      { name: "临沧市", code: "530900", level: 2, firstChar: "L" },
      { name: "楚雄彝族自治州", code: "532300", level: 2, firstChar: "C" },
      { name: "红河哈尼族彝族自治州", code: "532500", level: 2, firstChar: "H" },
      { name: "文山壮族苗族自治州", code: "532600", level: 2, firstChar: "W" },
      { name: "西双版纳傣族自治州", code: "532800", level: 2, firstChar: "X" },
      { name: "大理白族自治州", code: "532900", level: 2, firstChar: "D" },
      { name: "德宏傣族景颇族自治州", code: "533100", level: 2, firstChar: "D" },
      { name: "怒江傈僳族自治州", code: "533300", level: 2, firstChar: "N" },
      { name: "迪庆藏族自治州", code: "533400", level: 2, firstChar: "D" },
    ],
    西藏: [
      { name: "拉萨市", code: "540100", level: 2, firstChar: "L" },
      { name: "日喀则市", code: "540200", level: 2, firstChar: "R" },
      { name: "昌都市", code: "540300", level: 2, firstChar: "C" },
      { name: "林芝市", code: "540400", level: 2, firstChar: "L" },
      { name: "山南市", code: "540500", level: 2, firstChar: "S" },
      { name: "那曲市", code: "540600", level: 2, firstChar: "N" },
      { name: "阿里地区", code: "542500", level: 2, firstChar: "A" },
    ],
    陕西: [
      { name: "西安市", code: "610100", level: 2, firstChar: "X" },
      { name: "铜川市", code: "610200", level: 2, firstChar: "T" },
      { name: "宝鸡市", code: "610300", level: 2, firstChar: "B" },
      { name: "咸阳市", code: "610400", level: 2, firstChar: "X" },
      { name: "渭南市", code: "610500", level: 2, firstChar: "W" },
      { name: "延安市", code: "610600", level: 2, firstChar: "Y" },
      { name: "汉中市", code: "610700", level: 2, firstChar: "H" },
      { name: "榆林市", code: "610800", level: 2, firstChar: "Y" },
      { name: "安康市", code: "610900", level: 2, firstChar: "A" },
      { name: "商洛市", code: "611000", level: 2, firstChar: "S" },
    ],
    甘肃: [
      { name: "兰州市", code: "620100", level: 2, firstChar: "L" },
      { name: "嘉峪关市", code: "620200", level: 2, firstChar: "J" },
      { name: "金昌市", code: "620300", level: 2, firstChar: "J" },
      { name: "白银市", code: "620400", level: 2, firstChar: "B" },
      { name: "天水市", code: "620500", level: 2, firstChar: "T" },
      { name: "武威市", code: "620600", level: 2, firstChar: "W" },
      { name: "张掖市", code: "620700", level: 2, firstChar: "Z" },
      { name: "平凉市", code: "620800", level: 2, firstChar: "P" },
      { name: "酒泉市", code: "620900", level: 2, firstChar: "J" },
      { name: "庆阳市", code: "621000", level: 2, firstChar: "Q" },
      { name: "定西市", code: "621100", level: 2, firstChar: "D" },
      { name: "陇南市", code: "621200", level: 2, firstChar: "L" },
      { name: "临夏回族自治州", code: "622900", level: 2, firstChar: "L" },
      { name: "甘南藏族自治州", code: "623000", level: 2, firstChar: "G" },
    ],
    青海: [
      { name: "西宁市", code: "630100", level: 2, firstChar: "X" },
      { name: "海东市", code: "630200", level: 2, firstChar: "H" },
      { name: "海北藏族自治州", code: "632200", level: 2, firstChar: "H" },
      { name: "黄南藏族自治州", code: "632300", level: 2, firstChar: "H" },
      { name: "海南藏族自治州", code: "632500", level: 2, firstChar: "H" },
      { name: "果洛藏族自治州", code: "632600", level: 2, firstChar: "G" },
      { name: "玉树藏族自治州", code: "632700", level: 2, firstChar: "Y" },
      { name: "海西蒙古族藏族自治州", code: "632800", level: 2, firstChar: "H" },
    ],
    宁夏: [
      { name: "银川市", code: "640100", level: 2, firstChar: "Y" },
      { name: "石嘴山市", code: "640200", level: 2, firstChar: "S" },
      { name: "吴忠市", code: "640300", level: 2, firstChar: "W" },
      { name: "固原市", code: "640400", level: 2, firstChar: "G" },
      { name: "中卫市", code: "640500", level: 2, firstChar: "Z" },
    ],
    新疆: [
      { name: "乌鲁木齐市", code: "650100", level: 2, firstChar: "W" },
      { name: "克拉玛依市", code: "650200", level: 2, firstChar: "K" },
      { name: "吐鲁番市", code: "650400", level: 2, firstChar: "T" },
      { name: "哈密市", code: "650500", level: 2, firstChar: "H" },
      { name: "昌吉回族自治州", code: "652300", level: 2, firstChar: "C" },
      { name: "博尔塔拉蒙古自治州", code: "652700", level: 2, firstChar: "B" },
      { name: "巴音郭楞蒙古自治州", code: "652800", level: 2, firstChar: "B" },
      { name: "阿克苏地区", code: "652900", level: 2, firstChar: "A" },
      { name: "克孜勒苏柯尔克孜自治州", code: "653000", level: 2, firstChar: "K" },
      { name: "喀什地区", code: "653100", level: 2, firstChar: "K" },
      { name: "和田地区", code: "653200", level: 2, firstChar: "H" },
      { name: "伊犁哈萨克自治州", code: "654000", level: 2, firstChar: "Y" },
      { name: "塔城地区", code: "654200", level: 2, firstChar: "T" },
      { name: "阿勒泰地区", code: "654300", level: 2, firstChar: "A" },
      { name: "石河子市", code: "659001", level: 2, firstChar: "S" },
      { name: "阿拉尔市", code: "659002", level: 2, firstChar: "A" },
      { name: "图木舒克市", code: "659003", level: 2, firstChar: "T" },
      { name: "五家渠市", code: "659004", level: 2, firstChar: "W" },
      { name: "北屯市", code: "659005", level: 2, firstChar: "B" },
      { name: "铁门关市", code: "659006", level: 2, firstChar: "T" },
      { name: "双河市", code: "659007", level: 2, firstChar: "S" },
      { name: "可克达拉市", code: "659008", level: 2, firstChar: "K" },
      { name: "昆玉市", code: "659009", level: 2, firstChar: "K" },
      { name: "胡杨河市", code: "659010", level: 2, firstChar: "H" },
    ],
    台湾: [
      { name: "台中市", code: "710000", level: 2, firstChar: "T" },
      { name: "高雄市", code: "710000", level: 2, firstChar: "G" },
      { name: "新北市", code: "710000", level: 2, firstChar: "X" },
      { name: "桃园市", code: "710000", level: 2, firstChar: "T" },
      { name: "台北市", code: "710000", level: 2, firstChar: "T" },
      { name: "台南市", code: "710000", level: 2, firstChar: "T" },
    ],
    香港: [
      { name: "新界", code: "810000", level: 2, firstChar: "X" },
      { name: "香港岛", code: "810000", level: 2, firstChar: "X" },
      { name: "九龙半岛", code: "810000", level: 2, firstChar: "J" },
    ],
    澳门: [
      { name: "澳门半岛", code: "820000", level: 2, firstChar: "A" },
      { name: "路氹城", code: "820000", level: 2, firstChar: "L" },
      { name: "离岛", code: "820000", level: 2, firstChar: "L" },
    ],
  },
  DistrictData: {
    北京: {
      北京市: [
        { name: "东城区", code: "110101", level: 3, firstChar: null, children: null },
        { name: "西城区", code: "110102", level: 3, firstChar: null, children: null },
        { name: "朝阳区", code: "110105", level: 3, firstChar: null, children: null },
        { name: "丰台区", code: "110106", level: 3, firstChar: null, children: null },
        { name: "石景山区", code: "110107", level: 3, firstChar: null, children: null },
        { name: "海淀区", code: "110108", level: 3, firstChar: null, children: null },
        { name: "门头沟区", code: "110109", level: 3, firstChar: null, children: null },
        { name: "房山区", code: "110111", level: 3, firstChar: null, children: null },
        { name: "通州区", code: "110112", level: 3, firstChar: null, children: null },
        { name: "顺义区", code: "110113", level: 3, firstChar: null, children: null },
        { name: "昌平区", code: "110114", level: 3, firstChar: null, children: null },
        { name: "大兴区", code: "110115", level: 3, firstChar: null, children: null },
        { name: "怀柔区", code: "110116", level: 3, firstChar: null, children: null },
        { name: "平谷区", code: "110117", level: 3, firstChar: null, children: null },
        { name: "密云区", code: "110118", level: 3, firstChar: null, children: null },
        { name: "延庆区", code: "110119", level: 3, firstChar: null, children: null },
      ],
    },
    天津: {
      天津市: [
        { name: "和平区", code: "120101", level: 3, firstChar: null, children: null },
        { name: "河东区", code: "120102", level: 3, firstChar: null, children: null },
        { name: "河西区", code: "120103", level: 3, firstChar: null, children: null },
        { name: "南开区", code: "120104", level: 3, firstChar: null, children: null },
        { name: "河北区", code: "120105", level: 3, firstChar: null, children: null },
        { name: "红桥区", code: "120106", level: 3, firstChar: null, children: null },
        { name: "东丽区", code: "120110", level: 3, firstChar: null, children: null },
        { name: "西青区", code: "120111", level: 3, firstChar: null, children: null },
        { name: "津南区", code: "120112", level: 3, firstChar: null, children: null },
        { name: "北辰区", code: "120113", level: 3, firstChar: null, children: null },
        { name: "武清区", code: "120114", level: 3, firstChar: null, children: null },
        { name: "宝坻区", code: "120115", level: 3, firstChar: null, children: null },
        { name: "滨海新区", code: "120116", level: 3, firstChar: null, children: null },
        { name: "宁河区", code: "120117", level: 3, firstChar: null, children: null },
        { name: "静海区", code: "120118", level: 3, firstChar: null, children: null },
        { name: "蓟州区", code: "120119", level: 3, firstChar: null, children: null },
      ],
    },
    河北: {
      石家庄市: [
        { name: "长安区", code: "130102", level: 3, firstChar: null, children: null },
        { name: "桥西区", code: "130104", level: 3, firstChar: null, children: null },
        { name: "新华区", code: "130105", level: 3, firstChar: null, children: null },
        { name: "井陉矿区", code: "130107", level: 3, firstChar: null, children: null },
        { name: "裕华区", code: "130108", level: 3, firstChar: null, children: null },
        { name: "藁城区", code: "130109", level: 3, firstChar: null, children: null },
        { name: "鹿泉区", code: "130110", level: 3, firstChar: null, children: null },
        { name: "栾城区", code: "130111", level: 3, firstChar: null, children: null },
        { name: "井陉县", code: "130121", level: 3, firstChar: null, children: null },
        { name: "正定县", code: "130123", level: 3, firstChar: null, children: null },
        { name: "行唐县", code: "130125", level: 3, firstChar: null, children: null },
        { name: "灵寿县", code: "130126", level: 3, firstChar: null, children: null },
        { name: "高邑县", code: "130127", level: 3, firstChar: null, children: null },
        { name: "深泽县", code: "130128", level: 3, firstChar: null, children: null },
        { name: "赞皇县", code: "130129", level: 3, firstChar: null, children: null },
        { name: "无极县", code: "130130", level: 3, firstChar: null, children: null },
        { name: "平山县", code: "130131", level: 3, firstChar: null, children: null },
        { name: "元氏县", code: "130132", level: 3, firstChar: null, children: null },
        { name: "赵县", code: "130133", level: 3, firstChar: null, children: null },
        {
          name: "石家庄高新技术产业开发区",
          code: "130171",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "石家庄循环化工园区", code: "130172", level: 3, firstChar: null, children: null },
        { name: "辛集市", code: "130181", level: 3, firstChar: null, children: null },
        { name: "晋州市", code: "130183", level: 3, firstChar: null, children: null },
        { name: "新乐市", code: "130184", level: 3, firstChar: null, children: null },
      ],
      唐山市: [
        { name: "路南区", code: "130202", level: 3, firstChar: null, children: null },
        { name: "路北区", code: "130203", level: 3, firstChar: null, children: null },
        { name: "古冶区", code: "130204", level: 3, firstChar: null, children: null },
        { name: "开平区", code: "130205", level: 3, firstChar: null, children: null },
        { name: "丰南区", code: "130207", level: 3, firstChar: null, children: null },
        { name: "丰润区", code: "130208", level: 3, firstChar: null, children: null },
        { name: "曹妃甸区", code: "130209", level: 3, firstChar: null, children: null },
        { name: "滦南县", code: "130224", level: 3, firstChar: null, children: null },
        { name: "乐亭县", code: "130225", level: 3, firstChar: null, children: null },
        { name: "迁西县", code: "130227", level: 3, firstChar: null, children: null },
        { name: "玉田县", code: "130229", level: 3, firstChar: null, children: null },
        {
          name: "河北唐山芦台经济开发区",
          code: "130271",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "唐山市汉沽管理区", code: "130272", level: 3, firstChar: null, children: null },
        {
          name: "唐山高新技术产业开发区",
          code: "130273",
          level: 3,
          firstChar: null,
          children: null,
        },
        {
          name: "河北唐山海港经济开发区",
          code: "130274",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "遵化市", code: "130281", level: 3, firstChar: null, children: null },
        { name: "迁安市", code: "130283", level: 3, firstChar: null, children: null },
        { name: "滦州市", code: "130284", level: 3, firstChar: null, children: null },
      ],
      秦皇岛市: [
        { name: "海港区", code: "130302", level: 3, firstChar: null, children: null },
        { name: "山海关区", code: "130303", level: 3, firstChar: null, children: null },
        { name: "北戴河区", code: "130304", level: 3, firstChar: null, children: null },
        { name: "抚宁区", code: "130306", level: 3, firstChar: null, children: null },
        { name: "青龙满族自治县", code: "130321", level: 3, firstChar: null, children: null },
        { name: "昌黎县", code: "130322", level: 3, firstChar: null, children: null },
        { name: "卢龙县", code: "130324", level: 3, firstChar: null, children: null },
        {
          name: "秦皇岛市经济技术开发区",
          code: "130371",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "北戴河新区", code: "130372", level: 3, firstChar: null, children: null },
      ],
      邯郸市: [
        { name: "邯山区", code: "130402", level: 3, firstChar: null, children: null },
        { name: "丛台区", code: "130403", level: 3, firstChar: null, children: null },
        { name: "复兴区", code: "130404", level: 3, firstChar: null, children: null },
        { name: "峰峰矿区", code: "130406", level: 3, firstChar: null, children: null },
        { name: "肥乡区", code: "130407", level: 3, firstChar: null, children: null },
        { name: "永年区", code: "130408", level: 3, firstChar: null, children: null },
        { name: "临漳县", code: "130423", level: 3, firstChar: null, children: null },
        { name: "成安县", code: "130424", level: 3, firstChar: null, children: null },
        { name: "大名县", code: "130425", level: 3, firstChar: null, children: null },
        { name: "涉县", code: "130426", level: 3, firstChar: null, children: null },
        { name: "磁县", code: "130427", level: 3, firstChar: null, children: null },
        { name: "邱县", code: "130430", level: 3, firstChar: null, children: null },
        { name: "鸡泽县", code: "130431", level: 3, firstChar: null, children: null },
        { name: "广平县", code: "130432", level: 3, firstChar: null, children: null },
        { name: "馆陶县", code: "130433", level: 3, firstChar: null, children: null },
        { name: "魏县", code: "130434", level: 3, firstChar: null, children: null },
        { name: "曲周县", code: "130435", level: 3, firstChar: null, children: null },
        { name: "邯郸经济技术开发区", code: "130471", level: 3, firstChar: null, children: null },
        { name: "邯郸冀南新区", code: "130473", level: 3, firstChar: null, children: null },
        { name: "武安市", code: "130481", level: 3, firstChar: null, children: null },
      ],
      邢台市: [
        { name: "襄都区", code: "130502", level: 3, firstChar: null, children: null },
        { name: "信都区", code: "130503", level: 3, firstChar: null, children: null },
        { name: "任泽区", code: "130505", level: 3, firstChar: null, children: null },
        { name: "南和区", code: "130506", level: 3, firstChar: null, children: null },
        { name: "临城县", code: "130522", level: 3, firstChar: null, children: null },
        { name: "内丘县", code: "130523", level: 3, firstChar: null, children: null },
        { name: "柏乡县", code: "130524", level: 3, firstChar: null, children: null },
        { name: "隆尧县", code: "130525", level: 3, firstChar: null, children: null },
        { name: "宁晋县", code: "130528", level: 3, firstChar: null, children: null },
        { name: "巨鹿县", code: "130529", level: 3, firstChar: null, children: null },
        { name: "新河县", code: "130530", level: 3, firstChar: null, children: null },
        { name: "广宗县", code: "130531", level: 3, firstChar: null, children: null },
        { name: "平乡县", code: "130532", level: 3, firstChar: null, children: null },
        { name: "威县", code: "130533", level: 3, firstChar: null, children: null },
        { name: "清河县", code: "130534", level: 3, firstChar: null, children: null },
        { name: "临西县", code: "130535", level: 3, firstChar: null, children: null },
        { name: "河北邢台经济开发区", code: "130571", level: 3, firstChar: null, children: null },
        { name: "南宫市", code: "130581", level: 3, firstChar: null, children: null },
        { name: "沙河市", code: "130582", level: 3, firstChar: null, children: null },
      ],
      保定市: [
        { name: "竞秀区", code: "130602", level: 3, firstChar: null, children: null },
        { name: "莲池区", code: "130606", level: 3, firstChar: null, children: null },
        { name: "满城区", code: "130607", level: 3, firstChar: null, children: null },
        { name: "清苑区", code: "130608", level: 3, firstChar: null, children: null },
        { name: "徐水区", code: "130609", level: 3, firstChar: null, children: null },
        { name: "涞水县", code: "130623", level: 3, firstChar: null, children: null },
        { name: "阜平县", code: "130624", level: 3, firstChar: null, children: null },
        { name: "定兴县", code: "130626", level: 3, firstChar: null, children: null },
        { name: "唐县", code: "130627", level: 3, firstChar: null, children: null },
        { name: "高阳县", code: "130628", level: 3, firstChar: null, children: null },
        { name: "容城县", code: "130629", level: 3, firstChar: null, children: null },
        { name: "涞源县", code: "130630", level: 3, firstChar: null, children: null },
        { name: "望都县", code: "130631", level: 3, firstChar: null, children: null },
        { name: "安新县", code: "130632", level: 3, firstChar: null, children: null },
        { name: "易县", code: "130633", level: 3, firstChar: null, children: null },
        { name: "曲阳县", code: "130634", level: 3, firstChar: null, children: null },
        { name: "蠡县", code: "130635", level: 3, firstChar: null, children: null },
        { name: "顺平县", code: "130636", level: 3, firstChar: null, children: null },
        { name: "博野县", code: "130637", level: 3, firstChar: null, children: null },
        { name: "雄县", code: "130638", level: 3, firstChar: null, children: null },
        {
          name: "保定高新技术产业开发区",
          code: "130671",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "保定白沟新城", code: "130672", level: 3, firstChar: null, children: null },
        { name: "涿州市", code: "130681", level: 3, firstChar: null, children: null },
        { name: "定州市", code: "130682", level: 3, firstChar: null, children: null },
        { name: "安国市", code: "130683", level: 3, firstChar: null, children: null },
        { name: "高碑店市", code: "130684", level: 3, firstChar: null, children: null },
      ],
      张家口市: [
        { name: "桥东区", code: "130702", level: 3, firstChar: null, children: null },
        { name: "桥西区", code: "130703", level: 3, firstChar: null, children: null },
        { name: "宣化区", code: "130705", level: 3, firstChar: null, children: null },
        { name: "下花园区", code: "130706", level: 3, firstChar: null, children: null },
        { name: "万全区", code: "130708", level: 3, firstChar: null, children: null },
        { name: "崇礼区", code: "130709", level: 3, firstChar: null, children: null },
        { name: "张北县", code: "130722", level: 3, firstChar: null, children: null },
        { name: "康保县", code: "130723", level: 3, firstChar: null, children: null },
        { name: "沽源县", code: "130724", level: 3, firstChar: null, children: null },
        { name: "尚义县", code: "130725", level: 3, firstChar: null, children: null },
        { name: "蔚县", code: "130726", level: 3, firstChar: null, children: null },
        { name: "阳原县", code: "130727", level: 3, firstChar: null, children: null },
        { name: "怀安县", code: "130728", level: 3, firstChar: null, children: null },
        { name: "怀来县", code: "130730", level: 3, firstChar: null, children: null },
        { name: "涿鹿县", code: "130731", level: 3, firstChar: null, children: null },
        { name: "赤城县", code: "130732", level: 3, firstChar: null, children: null },
        { name: "张家口经济开发区", code: "130771", level: 3, firstChar: null, children: null },
        { name: "张家口市察北管理区", code: "130772", level: 3, firstChar: null, children: null },
        { name: "张家口市塞北管理区", code: "130773", level: 3, firstChar: null, children: null },
      ],
      承德市: [
        { name: "双桥区", code: "130802", level: 3, firstChar: null, children: null },
        { name: "双滦区", code: "130803", level: 3, firstChar: null, children: null },
        { name: "鹰手营子矿区", code: "130804", level: 3, firstChar: null, children: null },
        { name: "承德县", code: "130821", level: 3, firstChar: null, children: null },
        { name: "兴隆县", code: "130822", level: 3, firstChar: null, children: null },
        { name: "滦平县", code: "130824", level: 3, firstChar: null, children: null },
        { name: "隆化县", code: "130825", level: 3, firstChar: null, children: null },
        { name: "丰宁满族自治县", code: "130826", level: 3, firstChar: null, children: null },
        { name: "宽城满族自治县", code: "130827", level: 3, firstChar: null, children: null },
        { name: "围场满族蒙古族自治县", code: "130828", level: 3, firstChar: null, children: null },
        {
          name: "承德高新技术产业开发区",
          code: "130871",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "平泉市", code: "130881", level: 3, firstChar: null, children: null },
      ],
      沧州市: [
        { name: "新华区", code: "130902", level: 3, firstChar: null, children: null },
        { name: "运河区", code: "130903", level: 3, firstChar: null, children: null },
        { name: "沧县", code: "130921", level: 3, firstChar: null, children: null },
        { name: "青县", code: "130922", level: 3, firstChar: null, children: null },
        { name: "东光县", code: "130923", level: 3, firstChar: null, children: null },
        { name: "海兴县", code: "130924", level: 3, firstChar: null, children: null },
        { name: "盐山县", code: "130925", level: 3, firstChar: null, children: null },
        { name: "肃宁县", code: "130926", level: 3, firstChar: null, children: null },
        { name: "南皮县", code: "130927", level: 3, firstChar: null, children: null },
        { name: "吴桥县", code: "130928", level: 3, firstChar: null, children: null },
        { name: "献县", code: "130929", level: 3, firstChar: null, children: null },
        { name: "孟村回族自治县", code: "130930", level: 3, firstChar: null, children: null },
        { name: "河北沧州经济开发区", code: "130971", level: 3, firstChar: null, children: null },
        {
          name: "沧州高新技术产业开发区",
          code: "130972",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "沧州渤海新区", code: "130973", level: 3, firstChar: null, children: null },
        { name: "泊头市", code: "130981", level: 3, firstChar: null, children: null },
        { name: "任丘市", code: "130982", level: 3, firstChar: null, children: null },
        { name: "黄骅市", code: "130983", level: 3, firstChar: null, children: null },
        { name: "河间市", code: "130984", level: 3, firstChar: null, children: null },
      ],
      廊坊市: [
        { name: "安次区", code: "131002", level: 3, firstChar: null, children: null },
        { name: "广阳区", code: "131003", level: 3, firstChar: null, children: null },
        { name: "固安县", code: "131022", level: 3, firstChar: null, children: null },
        { name: "永清县", code: "131023", level: 3, firstChar: null, children: null },
        { name: "香河县", code: "131024", level: 3, firstChar: null, children: null },
        { name: "大城县", code: "131025", level: 3, firstChar: null, children: null },
        { name: "文安县", code: "131026", level: 3, firstChar: null, children: null },
        { name: "大厂回族自治县", code: "131028", level: 3, firstChar: null, children: null },
        { name: "廊坊经济技术开发区", code: "131071", level: 3, firstChar: null, children: null },
        { name: "霸州市", code: "131081", level: 3, firstChar: null, children: null },
        { name: "三河市", code: "131082", level: 3, firstChar: null, children: null },
      ],
      衡水市: [
        { name: "桃城区", code: "131102", level: 3, firstChar: null, children: null },
        { name: "冀州区", code: "131103", level: 3, firstChar: null, children: null },
        { name: "枣强县", code: "131121", level: 3, firstChar: null, children: null },
        { name: "武邑县", code: "131122", level: 3, firstChar: null, children: null },
        { name: "武强县", code: "131123", level: 3, firstChar: null, children: null },
        { name: "饶阳县", code: "131124", level: 3, firstChar: null, children: null },
        { name: "安平县", code: "131125", level: 3, firstChar: null, children: null },
        { name: "故城县", code: "131126", level: 3, firstChar: null, children: null },
        { name: "景县", code: "131127", level: 3, firstChar: null, children: null },
        { name: "阜城县", code: "131128", level: 3, firstChar: null, children: null },
        {
          name: "河北衡水高新技术产业开发区",
          code: "131171",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "衡水滨湖新区", code: "131172", level: 3, firstChar: null, children: null },
        { name: "深州市", code: "131182", level: 3, firstChar: null, children: null },
      ],
      雄安新区: [],
    },
    山西: {
      太原市: [
        { name: "小店区", code: "140105", level: 3, firstChar: null, children: null },
        { name: "迎泽区", code: "140106", level: 3, firstChar: null, children: null },
        { name: "杏花岭区", code: "140107", level: 3, firstChar: null, children: null },
        { name: "尖草坪区", code: "140108", level: 3, firstChar: null, children: null },
        { name: "万柏林区", code: "140109", level: 3, firstChar: null, children: null },
        { name: "晋源区", code: "140110", level: 3, firstChar: null, children: null },
        { name: "清徐县", code: "140121", level: 3, firstChar: null, children: null },
        { name: "阳曲县", code: "140122", level: 3, firstChar: null, children: null },
        { name: "娄烦县", code: "140123", level: 3, firstChar: null, children: null },
        {
          name: "山西转型综合改革示范区",
          code: "140171",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "古交市", code: "140181", level: 3, firstChar: null, children: null },
      ],
      大同市: [
        { name: "新荣区", code: "140212", level: 3, firstChar: null, children: null },
        { name: "平城区", code: "140213", level: 3, firstChar: null, children: null },
        { name: "云冈区", code: "140214", level: 3, firstChar: null, children: null },
        { name: "云州区", code: "140215", level: 3, firstChar: null, children: null },
        { name: "阳高县", code: "140221", level: 3, firstChar: null, children: null },
        { name: "天镇县", code: "140222", level: 3, firstChar: null, children: null },
        { name: "广灵县", code: "140223", level: 3, firstChar: null, children: null },
        { name: "灵丘县", code: "140224", level: 3, firstChar: null, children: null },
        { name: "浑源县", code: "140225", level: 3, firstChar: null, children: null },
        { name: "左云县", code: "140226", level: 3, firstChar: null, children: null },
        { name: "山西大同经济开发区", code: "140271", level: 3, firstChar: null, children: null },
      ],
      阳泉市: [
        { name: "城区", code: "140302", level: 3, firstChar: null, children: null },
        { name: "矿区", code: "140303", level: 3, firstChar: null, children: null },
        { name: "郊区", code: "140311", level: 3, firstChar: null, children: null },
        { name: "平定县", code: "140321", level: 3, firstChar: null, children: null },
        { name: "盂县", code: "140322", level: 3, firstChar: null, children: null },
      ],
      长治市: [
        { name: "潞州区", code: "140403", level: 3, firstChar: null, children: null },
        { name: "上党区", code: "140404", level: 3, firstChar: null, children: null },
        { name: "屯留区", code: "140405", level: 3, firstChar: null, children: null },
        { name: "潞城区", code: "140406", level: 3, firstChar: null, children: null },
        { name: "襄垣县", code: "140423", level: 3, firstChar: null, children: null },
        { name: "平顺县", code: "140425", level: 3, firstChar: null, children: null },
        { name: "黎城县", code: "140426", level: 3, firstChar: null, children: null },
        { name: "壶关县", code: "140427", level: 3, firstChar: null, children: null },
        { name: "长子县", code: "140428", level: 3, firstChar: null, children: null },
        { name: "武乡县", code: "140429", level: 3, firstChar: null, children: null },
        { name: "沁县", code: "140430", level: 3, firstChar: null, children: null },
        { name: "沁源县", code: "140431", level: 3, firstChar: null, children: null },
        {
          name: "山西长治高新技术产业园区",
          code: "140471",
          level: 3,
          firstChar: null,
          children: null,
        },
      ],
      晋城市: [
        { name: "城区", code: "140502", level: 3, firstChar: null, children: null },
        { name: "沁水县", code: "140521", level: 3, firstChar: null, children: null },
        { name: "阳城县", code: "140522", level: 3, firstChar: null, children: null },
        { name: "陵川县", code: "140524", level: 3, firstChar: null, children: null },
        { name: "泽州县", code: "140525", level: 3, firstChar: null, children: null },
        { name: "高平市", code: "140581", level: 3, firstChar: null, children: null },
      ],
      朔州市: [
        { name: "朔城区", code: "140602", level: 3, firstChar: null, children: null },
        { name: "平鲁区", code: "140603", level: 3, firstChar: null, children: null },
        { name: "山阴县", code: "140621", level: 3, firstChar: null, children: null },
        { name: "应县", code: "140622", level: 3, firstChar: null, children: null },
        { name: "右玉县", code: "140623", level: 3, firstChar: null, children: null },
        { name: "山西朔州经济开发区", code: "140671", level: 3, firstChar: null, children: null },
        { name: "怀仁市", code: "140681", level: 3, firstChar: null, children: null },
      ],
      晋中市: [
        { name: "榆次区", code: "140702", level: 3, firstChar: null, children: null },
        { name: "太谷区", code: "140703", level: 3, firstChar: null, children: null },
        { name: "榆社县", code: "140721", level: 3, firstChar: null, children: null },
        { name: "左权县", code: "140722", level: 3, firstChar: null, children: null },
        { name: "和顺县", code: "140723", level: 3, firstChar: null, children: null },
        { name: "昔阳县", code: "140724", level: 3, firstChar: null, children: null },
        { name: "寿阳县", code: "140725", level: 3, firstChar: null, children: null },
        { name: "祁县", code: "140727", level: 3, firstChar: null, children: null },
        { name: "平遥县", code: "140728", level: 3, firstChar: null, children: null },
        { name: "灵石县", code: "140729", level: 3, firstChar: null, children: null },
        { name: "介休市", code: "140781", level: 3, firstChar: null, children: null },
      ],
      运城市: [
        { name: "盐湖区", code: "140802", level: 3, firstChar: null, children: null },
        { name: "临猗县", code: "140821", level: 3, firstChar: null, children: null },
        { name: "万荣县", code: "140822", level: 3, firstChar: null, children: null },
        { name: "闻喜县", code: "140823", level: 3, firstChar: null, children: null },
        { name: "稷山县", code: "140824", level: 3, firstChar: null, children: null },
        { name: "新绛县", code: "140825", level: 3, firstChar: null, children: null },
        { name: "绛县", code: "140826", level: 3, firstChar: null, children: null },
        { name: "垣曲县", code: "140827", level: 3, firstChar: null, children: null },
        { name: "夏县", code: "140828", level: 3, firstChar: null, children: null },
        { name: "平陆县", code: "140829", level: 3, firstChar: null, children: null },
        { name: "芮城县", code: "140830", level: 3, firstChar: null, children: null },
        { name: "永济市", code: "140881", level: 3, firstChar: null, children: null },
        { name: "河津市", code: "140882", level: 3, firstChar: null, children: null },
      ],
      忻州市: [
        { name: "忻府区", code: "140902", level: 3, firstChar: null, children: null },
        { name: "定襄县", code: "140921", level: 3, firstChar: null, children: null },
        { name: "五台县", code: "140922", level: 3, firstChar: null, children: null },
        { name: "代县", code: "140923", level: 3, firstChar: null, children: null },
        { name: "繁峙县", code: "140924", level: 3, firstChar: null, children: null },
        { name: "宁武县", code: "140925", level: 3, firstChar: null, children: null },
        { name: "静乐县", code: "140926", level: 3, firstChar: null, children: null },
        { name: "神池县", code: "140927", level: 3, firstChar: null, children: null },
        { name: "五寨县", code: "140928", level: 3, firstChar: null, children: null },
        { name: "岢岚县", code: "140929", level: 3, firstChar: null, children: null },
        { name: "河曲县", code: "140930", level: 3, firstChar: null, children: null },
        { name: "保德县", code: "140931", level: 3, firstChar: null, children: null },
        { name: "偏关县", code: "140932", level: 3, firstChar: null, children: null },
        { name: "五台山风景名胜区", code: "140971", level: 3, firstChar: null, children: null },
        { name: "原平市", code: "140981", level: 3, firstChar: null, children: null },
      ],
      临汾市: [
        { name: "尧都区", code: "141002", level: 3, firstChar: null, children: null },
        { name: "曲沃县", code: "141021", level: 3, firstChar: null, children: null },
        { name: "翼城县", code: "141022", level: 3, firstChar: null, children: null },
        { name: "襄汾县", code: "141023", level: 3, firstChar: null, children: null },
        { name: "洪洞县", code: "141024", level: 3, firstChar: null, children: null },
        { name: "古县", code: "141025", level: 3, firstChar: null, children: null },
        { name: "安泽县", code: "141026", level: 3, firstChar: null, children: null },
        { name: "浮山县", code: "141027", level: 3, firstChar: null, children: null },
        { name: "吉县", code: "141028", level: 3, firstChar: null, children: null },
        { name: "乡宁县", code: "141029", level: 3, firstChar: null, children: null },
        { name: "大宁县", code: "141030", level: 3, firstChar: null, children: null },
        { name: "隰县", code: "141031", level: 3, firstChar: null, children: null },
        { name: "永和县", code: "141032", level: 3, firstChar: null, children: null },
        { name: "蒲县", code: "141033", level: 3, firstChar: null, children: null },
        { name: "汾西县", code: "141034", level: 3, firstChar: null, children: null },
        { name: "侯马市", code: "141081", level: 3, firstChar: null, children: null },
        { name: "霍州市", code: "141082", level: 3, firstChar: null, children: null },
      ],
      吕梁市: [
        { name: "离石区", code: "141102", level: 3, firstChar: null, children: null },
        { name: "文水县", code: "141121", level: 3, firstChar: null, children: null },
        { name: "交城县", code: "141122", level: 3, firstChar: null, children: null },
        { name: "兴县", code: "141123", level: 3, firstChar: null, children: null },
        { name: "临县", code: "141124", level: 3, firstChar: null, children: null },
        { name: "柳林县", code: "141125", level: 3, firstChar: null, children: null },
        { name: "石楼县", code: "141126", level: 3, firstChar: null, children: null },
        { name: "岚县", code: "141127", level: 3, firstChar: null, children: null },
        { name: "方山县", code: "141128", level: 3, firstChar: null, children: null },
        { name: "中阳县", code: "141129", level: 3, firstChar: null, children: null },
        { name: "交口县", code: "141130", level: 3, firstChar: null, children: null },
        { name: "孝义市", code: "141181", level: 3, firstChar: null, children: null },
        { name: "汾阳市", code: "141182", level: 3, firstChar: null, children: null },
      ],
    },
    内蒙古: {
      呼和浩特市: [
        { name: "新城区", code: "150102", level: 3, firstChar: null, children: null },
        { name: "回民区", code: "150103", level: 3, firstChar: null, children: null },
        { name: "玉泉区", code: "150104", level: 3, firstChar: null, children: null },
        { name: "赛罕区", code: "150105", level: 3, firstChar: null, children: null },
        { name: "土默特左旗", code: "150121", level: 3, firstChar: null, children: null },
        { name: "托克托县", code: "150122", level: 3, firstChar: null, children: null },
        { name: "和林格尔县", code: "150123", level: 3, firstChar: null, children: null },
        { name: "清水河县", code: "150124", level: 3, firstChar: null, children: null },
        { name: "武川县", code: "150125", level: 3, firstChar: null, children: null },
        {
          name: "呼和浩特经济技术开发区",
          code: "150172",
          level: 3,
          firstChar: null,
          children: null,
        },
      ],
      包头市: [
        { name: "东河区", code: "150202", level: 3, firstChar: null, children: null },
        { name: "昆都仑区", code: "150203", level: 3, firstChar: null, children: null },
        { name: "青山区", code: "150204", level: 3, firstChar: null, children: null },
        { name: "石拐区", code: "150205", level: 3, firstChar: null, children: null },
        { name: "白云鄂博矿区", code: "150206", level: 3, firstChar: null, children: null },
        { name: "九原区", code: "150207", level: 3, firstChar: null, children: null },
        { name: "土默特右旗", code: "150221", level: 3, firstChar: null, children: null },
        { name: "固阳县", code: "150222", level: 3, firstChar: null, children: null },
        { name: "达尔罕茂明安联合旗", code: "150223", level: 3, firstChar: null, children: null },
        {
          name: "包头稀土高新技术产业开发区",
          code: "150271",
          level: 3,
          firstChar: null,
          children: null,
        },
      ],
      乌海市: [
        { name: "海勃湾区", code: "150302", level: 3, firstChar: null, children: null },
        { name: "海南区", code: "150303", level: 3, firstChar: null, children: null },
        { name: "乌达区", code: "150304", level: 3, firstChar: null, children: null },
      ],
      赤峰市: [
        { name: "红山区", code: "150402", level: 3, firstChar: null, children: null },
        { name: "元宝山区", code: "150403", level: 3, firstChar: null, children: null },
        { name: "松山区", code: "150404", level: 3, firstChar: null, children: null },
        { name: "阿鲁科尔沁旗", code: "150421", level: 3, firstChar: null, children: null },
        { name: "巴林左旗", code: "150422", level: 3, firstChar: null, children: null },
        { name: "巴林右旗", code: "150423", level: 3, firstChar: null, children: null },
        { name: "林西县", code: "150424", level: 3, firstChar: null, children: null },
        { name: "克什克腾旗", code: "150425", level: 3, firstChar: null, children: null },
        { name: "翁牛特旗", code: "150426", level: 3, firstChar: null, children: null },
        { name: "喀喇沁旗", code: "150428", level: 3, firstChar: null, children: null },
        { name: "宁城县", code: "150429", level: 3, firstChar: null, children: null },
        { name: "敖汉旗", code: "150430", level: 3, firstChar: null, children: null },
      ],
      通辽市: [
        { name: "科尔沁区", code: "150502", level: 3, firstChar: null, children: null },
        { name: "科尔沁左翼中旗", code: "150521", level: 3, firstChar: null, children: null },
        { name: "科尔沁左翼后旗", code: "150522", level: 3, firstChar: null, children: null },
        { name: "开鲁县", code: "150523", level: 3, firstChar: null, children: null },
        { name: "库伦旗", code: "150524", level: 3, firstChar: null, children: null },
        { name: "奈曼旗", code: "150525", level: 3, firstChar: null, children: null },
        { name: "扎鲁特旗", code: "150526", level: 3, firstChar: null, children: null },
        { name: "通辽经济技术开发区", code: "150571", level: 3, firstChar: null, children: null },
        { name: "霍林郭勒市", code: "150581", level: 3, firstChar: null, children: null },
      ],
      鄂尔多斯市: [
        { name: "东胜区", code: "150602", level: 3, firstChar: null, children: null },
        { name: "康巴什区", code: "150603", level: 3, firstChar: null, children: null },
        { name: "达拉特旗", code: "150621", level: 3, firstChar: null, children: null },
        { name: "准格尔旗", code: "150622", level: 3, firstChar: null, children: null },
        { name: "鄂托克前旗", code: "150623", level: 3, firstChar: null, children: null },
        { name: "鄂托克旗", code: "150624", level: 3, firstChar: null, children: null },
        { name: "杭锦旗", code: "150625", level: 3, firstChar: null, children: null },
        { name: "乌审旗", code: "150626", level: 3, firstChar: null, children: null },
        { name: "伊金霍洛旗", code: "150627", level: 3, firstChar: null, children: null },
      ],
      呼伦贝尔市: [
        { name: "海拉尔区", code: "150702", level: 3, firstChar: null, children: null },
        { name: "扎赉诺尔区", code: "150703", level: 3, firstChar: null, children: null },
        { name: "阿荣旗", code: "150721", level: 3, firstChar: null, children: null },
        {
          name: "莫力达瓦达斡尔族自治旗",
          code: "150722",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "鄂伦春自治旗", code: "150723", level: 3, firstChar: null, children: null },
        { name: "鄂温克族自治旗", code: "150724", level: 3, firstChar: null, children: null },
        { name: "陈巴尔虎旗", code: "150725", level: 3, firstChar: null, children: null },
        { name: "新巴尔虎左旗", code: "150726", level: 3, firstChar: null, children: null },
        { name: "新巴尔虎右旗", code: "150727", level: 3, firstChar: null, children: null },
        { name: "满洲里市", code: "150781", level: 3, firstChar: null, children: null },
        { name: "牙克石市", code: "150782", level: 3, firstChar: null, children: null },
        { name: "扎兰屯市", code: "150783", level: 3, firstChar: null, children: null },
        { name: "额尔古纳市", code: "150784", level: 3, firstChar: null, children: null },
        { name: "根河市", code: "150785", level: 3, firstChar: null, children: null },
      ],
      巴彦淖尔市: [
        { name: "临河区", code: "150802", level: 3, firstChar: null, children: null },
        { name: "五原县", code: "150821", level: 3, firstChar: null, children: null },
        { name: "磴口县", code: "150822", level: 3, firstChar: null, children: null },
        { name: "乌拉特前旗", code: "150823", level: 3, firstChar: null, children: null },
        { name: "乌拉特中旗", code: "150824", level: 3, firstChar: null, children: null },
        { name: "乌拉特后旗", code: "150825", level: 3, firstChar: null, children: null },
        { name: "杭锦后旗", code: "150826", level: 3, firstChar: null, children: null },
      ],
      乌兰察布市: [
        { name: "集宁区", code: "150902", level: 3, firstChar: null, children: null },
        { name: "卓资县", code: "150921", level: 3, firstChar: null, children: null },
        { name: "化德县", code: "150922", level: 3, firstChar: null, children: null },
        { name: "商都县", code: "150923", level: 3, firstChar: null, children: null },
        { name: "兴和县", code: "150924", level: 3, firstChar: null, children: null },
        { name: "凉城县", code: "150925", level: 3, firstChar: null, children: null },
        { name: "察哈尔右翼前旗", code: "150926", level: 3, firstChar: null, children: null },
        { name: "察哈尔右翼中旗", code: "150927", level: 3, firstChar: null, children: null },
        { name: "察哈尔右翼后旗", code: "150928", level: 3, firstChar: null, children: null },
        { name: "四子王旗", code: "150929", level: 3, firstChar: null, children: null },
        { name: "丰镇市", code: "150981", level: 3, firstChar: null, children: null },
      ],
      兴安盟: [
        { name: "乌兰浩特市", code: "152201", level: 3, firstChar: null, children: null },
        { name: "阿尔山市", code: "152202", level: 3, firstChar: null, children: null },
        { name: "科尔沁右翼前旗", code: "152221", level: 3, firstChar: null, children: null },
        { name: "科尔沁右翼中旗", code: "152222", level: 3, firstChar: null, children: null },
        { name: "扎赉特旗", code: "152223", level: 3, firstChar: null, children: null },
        { name: "突泉县", code: "152224", level: 3, firstChar: null, children: null },
      ],
      锡林郭勒盟: [
        { name: "二连浩特市", code: "152501", level: 3, firstChar: null, children: null },
        { name: "锡林浩特市", code: "152502", level: 3, firstChar: null, children: null },
        { name: "阿巴嘎旗", code: "152522", level: 3, firstChar: null, children: null },
        { name: "苏尼特左旗", code: "152523", level: 3, firstChar: null, children: null },
        { name: "苏尼特右旗", code: "152524", level: 3, firstChar: null, children: null },
        { name: "东乌珠穆沁旗", code: "152525", level: 3, firstChar: null, children: null },
        { name: "西乌珠穆沁旗", code: "152526", level: 3, firstChar: null, children: null },
        { name: "太仆寺旗", code: "152527", level: 3, firstChar: null, children: null },
        { name: "镶黄旗", code: "152528", level: 3, firstChar: null, children: null },
        { name: "正镶白旗", code: "152529", level: 3, firstChar: null, children: null },
        { name: "正蓝旗", code: "152530", level: 3, firstChar: null, children: null },
        { name: "多伦县", code: "152531", level: 3, firstChar: null, children: null },
        { name: "乌拉盖管委会", code: "152571", level: 3, firstChar: null, children: null },
      ],
      阿拉善盟: [
        { name: "阿拉善左旗", code: "152921", level: 3, firstChar: null, children: null },
        { name: "阿拉善右旗", code: "152922", level: 3, firstChar: null, children: null },
        { name: "额济纳旗", code: "152923", level: 3, firstChar: null, children: null },
        {
          name: "内蒙古阿拉善经济开发区",
          code: "152971",
          level: 3,
          firstChar: null,
          children: null,
        },
      ],
    },
    辽宁: {
      沈阳市: [
        { name: "和平区", code: "210102", level: 3, firstChar: null, children: null },
        { name: "沈河区", code: "210103", level: 3, firstChar: null, children: null },
        { name: "大东区", code: "210104", level: 3, firstChar: null, children: null },
        { name: "皇姑区", code: "210105", level: 3, firstChar: null, children: null },
        { name: "铁西区", code: "210106", level: 3, firstChar: null, children: null },
        { name: "苏家屯区", code: "210111", level: 3, firstChar: null, children: null },
        { name: "浑南区", code: "210112", level: 3, firstChar: null, children: null },
        { name: "沈北新区", code: "210113", level: 3, firstChar: null, children: null },
        { name: "于洪区", code: "210114", level: 3, firstChar: null, children: null },
        { name: "辽中区", code: "210115", level: 3, firstChar: null, children: null },
        { name: "康平县", code: "210123", level: 3, firstChar: null, children: null },
        { name: "法库县", code: "210124", level: 3, firstChar: null, children: null },
        { name: "新民市", code: "210181", level: 3, firstChar: null, children: null },
      ],
      大连市: [
        { name: "中山区", code: "210202", level: 3, firstChar: null, children: null },
        { name: "西岗区", code: "210203", level: 3, firstChar: null, children: null },
        { name: "沙河口区", code: "210204", level: 3, firstChar: null, children: null },
        { name: "甘井子区", code: "210211", level: 3, firstChar: null, children: null },
        { name: "旅顺口区", code: "210212", level: 3, firstChar: null, children: null },
        { name: "金州区", code: "210213", level: 3, firstChar: null, children: null },
        { name: "普兰店区", code: "210214", level: 3, firstChar: null, children: null },
        { name: "长海县", code: "210224", level: 3, firstChar: null, children: null },
        { name: "瓦房店市", code: "210281", level: 3, firstChar: null, children: null },
        { name: "庄河市", code: "210283", level: 3, firstChar: null, children: null },
      ],
      鞍山市: [
        { name: "铁东区", code: "210302", level: 3, firstChar: null, children: null },
        { name: "铁西区", code: "210303", level: 3, firstChar: null, children: null },
        { name: "立山区", code: "210304", level: 3, firstChar: null, children: null },
        { name: "千山区", code: "210311", level: 3, firstChar: null, children: null },
        { name: "台安县", code: "210321", level: 3, firstChar: null, children: null },
        { name: "岫岩满族自治县", code: "210323", level: 3, firstChar: null, children: null },
        { name: "海城市", code: "210381", level: 3, firstChar: null, children: null },
      ],
      抚顺市: [
        { name: "新抚区", code: "210402", level: 3, firstChar: null, children: null },
        { name: "东洲区", code: "210403", level: 3, firstChar: null, children: null },
        { name: "望花区", code: "210404", level: 3, firstChar: null, children: null },
        { name: "顺城区", code: "210411", level: 3, firstChar: null, children: null },
        { name: "抚顺县", code: "210421", level: 3, firstChar: null, children: null },
        { name: "新宾满族自治县", code: "210422", level: 3, firstChar: null, children: null },
        { name: "清原满族自治县", code: "210423", level: 3, firstChar: null, children: null },
      ],
      本溪市: [
        { name: "平山区", code: "210502", level: 3, firstChar: null, children: null },
        { name: "溪湖区", code: "210503", level: 3, firstChar: null, children: null },
        { name: "明山区", code: "210504", level: 3, firstChar: null, children: null },
        { name: "南芬区", code: "210505", level: 3, firstChar: null, children: null },
        { name: "本溪满族自治县", code: "210521", level: 3, firstChar: null, children: null },
        { name: "桓仁满族自治县", code: "210522", level: 3, firstChar: null, children: null },
      ],
      丹东市: [
        { name: "元宝区", code: "210602", level: 3, firstChar: null, children: null },
        { name: "振兴区", code: "210603", level: 3, firstChar: null, children: null },
        { name: "振安区", code: "210604", level: 3, firstChar: null, children: null },
        { name: "宽甸满族自治县", code: "210624", level: 3, firstChar: null, children: null },
        { name: "东港市", code: "210681", level: 3, firstChar: null, children: null },
        { name: "凤城市", code: "210682", level: 3, firstChar: null, children: null },
      ],
      锦州市: [
        { name: "古塔区", code: "210702", level: 3, firstChar: null, children: null },
        { name: "凌河区", code: "210703", level: 3, firstChar: null, children: null },
        { name: "太和区", code: "210711", level: 3, firstChar: null, children: null },
        { name: "黑山县", code: "210726", level: 3, firstChar: null, children: null },
        { name: "义县", code: "210727", level: 3, firstChar: null, children: null },
        { name: "凌海市", code: "210781", level: 3, firstChar: null, children: null },
        { name: "北镇市", code: "210782", level: 3, firstChar: null, children: null },
      ],
      营口市: [
        { name: "站前区", code: "210802", level: 3, firstChar: null, children: null },
        { name: "西市区", code: "210803", level: 3, firstChar: null, children: null },
        { name: "鲅鱼圈区", code: "210804", level: 3, firstChar: null, children: null },
        { name: "老边区", code: "210811", level: 3, firstChar: null, children: null },
        { name: "盖州市", code: "210881", level: 3, firstChar: null, children: null },
        { name: "大石桥市", code: "210882", level: 3, firstChar: null, children: null },
      ],
      阜新市: [
        { name: "海州区", code: "210902", level: 3, firstChar: null, children: null },
        { name: "新邱区", code: "210903", level: 3, firstChar: null, children: null },
        { name: "太平区", code: "210904", level: 3, firstChar: null, children: null },
        { name: "清河门区", code: "210905", level: 3, firstChar: null, children: null },
        { name: "细河区", code: "210911", level: 3, firstChar: null, children: null },
        { name: "阜新蒙古族自治县", code: "210921", level: 3, firstChar: null, children: null },
        { name: "彰武县", code: "210922", level: 3, firstChar: null, children: null },
      ],
      辽阳市: [
        { name: "白塔区", code: "211002", level: 3, firstChar: null, children: null },
        { name: "文圣区", code: "211003", level: 3, firstChar: null, children: null },
        { name: "宏伟区", code: "211004", level: 3, firstChar: null, children: null },
        { name: "弓长岭区", code: "211005", level: 3, firstChar: null, children: null },
        { name: "太子河区", code: "211011", level: 3, firstChar: null, children: null },
        { name: "辽阳县", code: "211021", level: 3, firstChar: null, children: null },
        { name: "灯塔市", code: "211081", level: 3, firstChar: null, children: null },
      ],
      盘锦市: [
        { name: "双台子区", code: "211102", level: 3, firstChar: null, children: null },
        { name: "兴隆台区", code: "211103", level: 3, firstChar: null, children: null },
        { name: "大洼区", code: "211104", level: 3, firstChar: null, children: null },
        { name: "盘山县", code: "211122", level: 3, firstChar: null, children: null },
      ],
      铁岭市: [
        { name: "银州区", code: "211202", level: 3, firstChar: null, children: null },
        { name: "清河区", code: "211204", level: 3, firstChar: null, children: null },
        { name: "铁岭县", code: "211221", level: 3, firstChar: null, children: null },
        { name: "西丰县", code: "211223", level: 3, firstChar: null, children: null },
        { name: "昌图县", code: "211224", level: 3, firstChar: null, children: null },
        { name: "调兵山市", code: "211281", level: 3, firstChar: null, children: null },
        { name: "开原市", code: "211282", level: 3, firstChar: null, children: null },
      ],
      朝阳市: [
        { name: "双塔区", code: "211302", level: 3, firstChar: null, children: null },
        { name: "龙城区", code: "211303", level: 3, firstChar: null, children: null },
        { name: "朝阳县", code: "211321", level: 3, firstChar: null, children: null },
        { name: "建平县", code: "211322", level: 3, firstChar: null, children: null },
        {
          name: "喀喇沁左翼蒙古族自治县",
          code: "211324",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "北票市", code: "211381", level: 3, firstChar: null, children: null },
        { name: "凌源市", code: "211382", level: 3, firstChar: null, children: null },
      ],
      葫芦岛市: [
        { name: "连山区", code: "211402", level: 3, firstChar: null, children: null },
        { name: "龙港区", code: "211403", level: 3, firstChar: null, children: null },
        { name: "南票区", code: "211404", level: 3, firstChar: null, children: null },
        { name: "绥中县", code: "211421", level: 3, firstChar: null, children: null },
        { name: "建昌县", code: "211422", level: 3, firstChar: null, children: null },
        { name: "兴城市", code: "211481", level: 3, firstChar: null, children: null },
      ],
    },
    吉林: {
      长春市: [
        { name: "南关区", code: "220102", level: 3, firstChar: null, children: null },
        { name: "宽城区", code: "220103", level: 3, firstChar: null, children: null },
        { name: "朝阳区", code: "220104", level: 3, firstChar: null, children: null },
        { name: "二道区", code: "220105", level: 3, firstChar: null, children: null },
        { name: "绿园区", code: "220106", level: 3, firstChar: null, children: null },
        { name: "双阳区", code: "220112", level: 3, firstChar: null, children: null },
        { name: "九台区", code: "220113", level: 3, firstChar: null, children: null },
        { name: "农安县", code: "220122", level: 3, firstChar: null, children: null },
        { name: "长春经济技术开发区", code: "220171", level: 3, firstChar: null, children: null },
        {
          name: "长春净月高新技术产业开发区",
          code: "220172",
          level: 3,
          firstChar: null,
          children: null,
        },
        {
          name: "长春高新技术产业开发区",
          code: "220173",
          level: 3,
          firstChar: null,
          children: null,
        },
        {
          name: "长春汽车经济技术开发区",
          code: "220174",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "榆树市", code: "220182", level: 3, firstChar: null, children: null },
        { name: "德惠市", code: "220183", level: 3, firstChar: null, children: null },
        { name: "公主岭市", code: "220184", level: 3, firstChar: null, children: null },
      ],
      吉林市: [
        { name: "昌邑区", code: "220202", level: 3, firstChar: null, children: null },
        { name: "龙潭区", code: "220203", level: 3, firstChar: null, children: null },
        { name: "船营区", code: "220204", level: 3, firstChar: null, children: null },
        { name: "丰满区", code: "220211", level: 3, firstChar: null, children: null },
        { name: "永吉县", code: "220221", level: 3, firstChar: null, children: null },
        { name: "吉林经济开发区", code: "220271", level: 3, firstChar: null, children: null },
        {
          name: "吉林高新技术产业开发区",
          code: "220272",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "吉林中国新加坡食品区", code: "220273", level: 3, firstChar: null, children: null },
        { name: "蛟河市", code: "220281", level: 3, firstChar: null, children: null },
        { name: "桦甸市", code: "220282", level: 3, firstChar: null, children: null },
        { name: "舒兰市", code: "220283", level: 3, firstChar: null, children: null },
        { name: "磐石市", code: "220284", level: 3, firstChar: null, children: null },
      ],
      四平市: [
        { name: "铁西区", code: "220302", level: 3, firstChar: null, children: null },
        { name: "铁东区", code: "220303", level: 3, firstChar: null, children: null },
        { name: "梨树县", code: "220322", level: 3, firstChar: null, children: null },
        { name: "伊通满族自治县", code: "220323", level: 3, firstChar: null, children: null },
        { name: "双辽市", code: "220382", level: 3, firstChar: null, children: null },
      ],
      辽源市: [
        { name: "龙山区", code: "220402", level: 3, firstChar: null, children: null },
        { name: "西安区", code: "220403", level: 3, firstChar: null, children: null },
        { name: "东丰县", code: "220421", level: 3, firstChar: null, children: null },
        { name: "东辽县", code: "220422", level: 3, firstChar: null, children: null },
      ],
      通化市: [
        { name: "东昌区", code: "220502", level: 3, firstChar: null, children: null },
        { name: "二道江区", code: "220503", level: 3, firstChar: null, children: null },
        { name: "通化县", code: "220521", level: 3, firstChar: null, children: null },
        { name: "辉南县", code: "220523", level: 3, firstChar: null, children: null },
        { name: "柳河县", code: "220524", level: 3, firstChar: null, children: null },
        { name: "梅河口市", code: "220581", level: 3, firstChar: null, children: null },
        { name: "集安市", code: "220582", level: 3, firstChar: null, children: null },
      ],
      白山市: [
        { name: "浑江区", code: "220602", level: 3, firstChar: null, children: null },
        { name: "江源区", code: "220605", level: 3, firstChar: null, children: null },
        { name: "抚松县", code: "220621", level: 3, firstChar: null, children: null },
        { name: "靖宇县", code: "220622", level: 3, firstChar: null, children: null },
        { name: "长白朝鲜族自治县", code: "220623", level: 3, firstChar: null, children: null },
        { name: "临江市", code: "220681", level: 3, firstChar: null, children: null },
      ],
      松原市: [
        { name: "宁江区", code: "220702", level: 3, firstChar: null, children: null },
        {
          name: "前郭尔罗斯蒙古族自治县",
          code: "220721",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "长岭县", code: "220722", level: 3, firstChar: null, children: null },
        { name: "乾安县", code: "220723", level: 3, firstChar: null, children: null },
        { name: "吉林松原经济开发区", code: "220771", level: 3, firstChar: null, children: null },
        { name: "扶余市", code: "220781", level: 3, firstChar: null, children: null },
      ],
      白城市: [
        { name: "洮北区", code: "220802", level: 3, firstChar: null, children: null },
        { name: "镇赉县", code: "220821", level: 3, firstChar: null, children: null },
        { name: "通榆县", code: "220822", level: 3, firstChar: null, children: null },
        { name: "吉林白城经济开发区", code: "220871", level: 3, firstChar: null, children: null },
        { name: "洮南市", code: "220881", level: 3, firstChar: null, children: null },
        { name: "大安市", code: "220882", level: 3, firstChar: null, children: null },
      ],
      延边朝鲜族自治州: [
        { name: "延吉市", code: "222401", level: 3, firstChar: null, children: null },
        { name: "图们市", code: "222402", level: 3, firstChar: null, children: null },
        { name: "敦化市", code: "222403", level: 3, firstChar: null, children: null },
        { name: "珲春市", code: "222404", level: 3, firstChar: null, children: null },
        { name: "龙井市", code: "222405", level: 3, firstChar: null, children: null },
        { name: "和龙市", code: "222406", level: 3, firstChar: null, children: null },
        { name: "汪清县", code: "222424", level: 3, firstChar: null, children: null },
        { name: "安图县", code: "222426", level: 3, firstChar: null, children: null },
      ],
    },
    黑龙江: {
      哈尔滨市: [
        { name: "道里区", code: "230102", level: 3, firstChar: null, children: null },
        { name: "南岗区", code: "230103", level: 3, firstChar: null, children: null },
        { name: "道外区", code: "230104", level: 3, firstChar: null, children: null },
        { name: "平房区", code: "230108", level: 3, firstChar: null, children: null },
        { name: "松北区", code: "230109", level: 3, firstChar: null, children: null },
        { name: "香坊区", code: "230110", level: 3, firstChar: null, children: null },
        { name: "呼兰区", code: "230111", level: 3, firstChar: null, children: null },
        { name: "阿城区", code: "230112", level: 3, firstChar: null, children: null },
        { name: "双城区", code: "230113", level: 3, firstChar: null, children: null },
        { name: "依兰县", code: "230123", level: 3, firstChar: null, children: null },
        { name: "方正县", code: "230124", level: 3, firstChar: null, children: null },
        { name: "宾县", code: "230125", level: 3, firstChar: null, children: null },
        { name: "巴彦县", code: "230126", level: 3, firstChar: null, children: null },
        { name: "木兰县", code: "230127", level: 3, firstChar: null, children: null },
        { name: "通河县", code: "230128", level: 3, firstChar: null, children: null },
        { name: "延寿县", code: "230129", level: 3, firstChar: null, children: null },
        { name: "尚志市", code: "230183", level: 3, firstChar: null, children: null },
        { name: "五常市", code: "230184", level: 3, firstChar: null, children: null },
      ],
      齐齐哈尔市: [
        { name: "龙沙区", code: "230202", level: 3, firstChar: null, children: null },
        { name: "建华区", code: "230203", level: 3, firstChar: null, children: null },
        { name: "铁锋区", code: "230204", level: 3, firstChar: null, children: null },
        { name: "昂昂溪区", code: "230205", level: 3, firstChar: null, children: null },
        { name: "富拉尔基区", code: "230206", level: 3, firstChar: null, children: null },
        { name: "碾子山区", code: "230207", level: 3, firstChar: null, children: null },
        { name: "梅里斯达斡尔族区", code: "230208", level: 3, firstChar: null, children: null },
        { name: "龙江县", code: "230221", level: 3, firstChar: null, children: null },
        { name: "依安县", code: "230223", level: 3, firstChar: null, children: null },
        { name: "泰来县", code: "230224", level: 3, firstChar: null, children: null },
        { name: "甘南县", code: "230225", level: 3, firstChar: null, children: null },
        { name: "富裕县", code: "230227", level: 3, firstChar: null, children: null },
        { name: "克山县", code: "230229", level: 3, firstChar: null, children: null },
        { name: "克东县", code: "230230", level: 3, firstChar: null, children: null },
        { name: "拜泉县", code: "230231", level: 3, firstChar: null, children: null },
        { name: "讷河市", code: "230281", level: 3, firstChar: null, children: null },
      ],
      鸡西市: [
        { name: "鸡冠区", code: "230302", level: 3, firstChar: null, children: null },
        { name: "恒山区", code: "230303", level: 3, firstChar: null, children: null },
        { name: "滴道区", code: "230304", level: 3, firstChar: null, children: null },
        { name: "梨树区", code: "230305", level: 3, firstChar: null, children: null },
        { name: "城子河区", code: "230306", level: 3, firstChar: null, children: null },
        { name: "麻山区", code: "230307", level: 3, firstChar: null, children: null },
        { name: "鸡东县", code: "230321", level: 3, firstChar: null, children: null },
        { name: "虎林市", code: "230381", level: 3, firstChar: null, children: null },
        { name: "密山市", code: "230382", level: 3, firstChar: null, children: null },
      ],
      鹤岗市: [
        { name: "向阳区", code: "230402", level: 3, firstChar: null, children: null },
        { name: "工农区", code: "230403", level: 3, firstChar: null, children: null },
        { name: "南山区", code: "230404", level: 3, firstChar: null, children: null },
        { name: "兴安区", code: "230405", level: 3, firstChar: null, children: null },
        { name: "东山区", code: "230406", level: 3, firstChar: null, children: null },
        { name: "兴山区", code: "230407", level: 3, firstChar: null, children: null },
        { name: "萝北县", code: "230421", level: 3, firstChar: null, children: null },
        { name: "绥滨县", code: "230422", level: 3, firstChar: null, children: null },
      ],
      双鸭山市: [
        { name: "尖山区", code: "230502", level: 3, firstChar: null, children: null },
        { name: "岭东区", code: "230503", level: 3, firstChar: null, children: null },
        { name: "四方台区", code: "230505", level: 3, firstChar: null, children: null },
        { name: "宝山区", code: "230506", level: 3, firstChar: null, children: null },
        { name: "集贤县", code: "230521", level: 3, firstChar: null, children: null },
        { name: "友谊县", code: "230522", level: 3, firstChar: null, children: null },
        { name: "宝清县", code: "230523", level: 3, firstChar: null, children: null },
        { name: "饶河县", code: "230524", level: 3, firstChar: null, children: null },
      ],
      大庆市: [
        { name: "萨尔图区", code: "230602", level: 3, firstChar: null, children: null },
        { name: "龙凤区", code: "230603", level: 3, firstChar: null, children: null },
        { name: "让胡路区", code: "230604", level: 3, firstChar: null, children: null },
        { name: "红岗区", code: "230605", level: 3, firstChar: null, children: null },
        { name: "大同区", code: "230606", level: 3, firstChar: null, children: null },
        { name: "肇州县", code: "230621", level: 3, firstChar: null, children: null },
        { name: "肇源县", code: "230622", level: 3, firstChar: null, children: null },
        { name: "林甸县", code: "230623", level: 3, firstChar: null, children: null },
        { name: "杜尔伯特蒙古族自治县", code: "230624", level: 3, firstChar: null, children: null },
        {
          name: "大庆高新技术产业开发区",
          code: "230671",
          level: 3,
          firstChar: null,
          children: null,
        },
      ],
      伊春市: [
        { name: "伊美区", code: "230717", level: 3, firstChar: null, children: null },
        { name: "乌翠区", code: "230718", level: 3, firstChar: null, children: null },
        { name: "友好区", code: "230719", level: 3, firstChar: null, children: null },
        { name: "嘉荫县", code: "230722", level: 3, firstChar: null, children: null },
        { name: "汤旺县", code: "230723", level: 3, firstChar: null, children: null },
        { name: "丰林县", code: "230724", level: 3, firstChar: null, children: null },
        { name: "大箐山县", code: "230725", level: 3, firstChar: null, children: null },
        { name: "南岔县", code: "230726", level: 3, firstChar: null, children: null },
        { name: "金林区", code: "230751", level: 3, firstChar: null, children: null },
        { name: "铁力市", code: "230781", level: 3, firstChar: null, children: null },
      ],
      佳木斯市: [
        { name: "向阳区", code: "230803", level: 3, firstChar: null, children: null },
        { name: "前进区", code: "230804", level: 3, firstChar: null, children: null },
        { name: "东风区", code: "230805", level: 3, firstChar: null, children: null },
        { name: "郊区", code: "230811", level: 3, firstChar: null, children: null },
        { name: "桦南县", code: "230822", level: 3, firstChar: null, children: null },
        { name: "桦川县", code: "230826", level: 3, firstChar: null, children: null },
        { name: "汤原县", code: "230828", level: 3, firstChar: null, children: null },
        { name: "同江市", code: "230881", level: 3, firstChar: null, children: null },
        { name: "富锦市", code: "230882", level: 3, firstChar: null, children: null },
        { name: "抚远市", code: "230883", level: 3, firstChar: null, children: null },
      ],
      七台河市: [
        { name: "新兴区", code: "230902", level: 3, firstChar: null, children: null },
        { name: "桃山区", code: "230903", level: 3, firstChar: null, children: null },
        { name: "茄子河区", code: "230904", level: 3, firstChar: null, children: null },
        { name: "勃利县", code: "230921", level: 3, firstChar: null, children: null },
      ],
      牡丹江市: [
        { name: "东安区", code: "231002", level: 3, firstChar: null, children: null },
        { name: "阳明区", code: "231003", level: 3, firstChar: null, children: null },
        { name: "爱民区", code: "231004", level: 3, firstChar: null, children: null },
        { name: "西安区", code: "231005", level: 3, firstChar: null, children: null },
        { name: "林口县", code: "231025", level: 3, firstChar: null, children: null },
        { name: "牡丹江经济技术开发区", code: "231071", level: 3, firstChar: null, children: null },
        { name: "绥芬河市", code: "231081", level: 3, firstChar: null, children: null },
        { name: "海林市", code: "231083", level: 3, firstChar: null, children: null },
        { name: "宁安市", code: "231084", level: 3, firstChar: null, children: null },
        { name: "穆棱市", code: "231085", level: 3, firstChar: null, children: null },
        { name: "东宁市", code: "231086", level: 3, firstChar: null, children: null },
      ],
      黑河市: [
        { name: "爱辉区", code: "231102", level: 3, firstChar: null, children: null },
        { name: "逊克县", code: "231123", level: 3, firstChar: null, children: null },
        { name: "孙吴县", code: "231124", level: 3, firstChar: null, children: null },
        { name: "北安市", code: "231181", level: 3, firstChar: null, children: null },
        { name: "五大连池市", code: "231182", level: 3, firstChar: null, children: null },
        { name: "嫩江市", code: "231183", level: 3, firstChar: null, children: null },
      ],
      绥化市: [
        { name: "北林区", code: "231202", level: 3, firstChar: null, children: null },
        { name: "望奎县", code: "231221", level: 3, firstChar: null, children: null },
        { name: "兰西县", code: "231222", level: 3, firstChar: null, children: null },
        { name: "青冈县", code: "231223", level: 3, firstChar: null, children: null },
        { name: "庆安县", code: "231224", level: 3, firstChar: null, children: null },
        { name: "明水县", code: "231225", level: 3, firstChar: null, children: null },
        { name: "绥棱县", code: "231226", level: 3, firstChar: null, children: null },
        { name: "安达市", code: "231281", level: 3, firstChar: null, children: null },
        { name: "肇东市", code: "231282", level: 3, firstChar: null, children: null },
        { name: "海伦市", code: "231283", level: 3, firstChar: null, children: null },
      ],
      大兴安岭地区: [
        { name: "漠河市", code: "232701", level: 3, firstChar: null, children: null },
        { name: "呼玛县", code: "232721", level: 3, firstChar: null, children: null },
        { name: "塔河县", code: "232722", level: 3, firstChar: null, children: null },
        { name: "加格达奇区", code: "232761", level: 3, firstChar: null, children: null },
        { name: "松岭区", code: "232762", level: 3, firstChar: null, children: null },
        { name: "新林区", code: "232763", level: 3, firstChar: null, children: null },
        { name: "呼中区", code: "232764", level: 3, firstChar: null, children: null },
      ],
    },
    上海: {
      上海市: [
        { name: "黄浦区", code: "310101", level: 3, firstChar: null, children: null },
        { name: "徐汇区", code: "310104", level: 3, firstChar: null, children: null },
        { name: "长宁区", code: "310105", level: 3, firstChar: null, children: null },
        { name: "静安区", code: "310106", level: 3, firstChar: null, children: null },
        { name: "普陀区", code: "310107", level: 3, firstChar: null, children: null },
        { name: "虹口区", code: "310109", level: 3, firstChar: null, children: null },
        { name: "杨浦区", code: "310110", level: 3, firstChar: null, children: null },
        { name: "闵行区", code: "310112", level: 3, firstChar: null, children: null },
        { name: "宝山区", code: "310113", level: 3, firstChar: null, children: null },
        { name: "嘉定区", code: "310114", level: 3, firstChar: null, children: null },
        { name: "浦东新区", code: "310115", level: 3, firstChar: null, children: null },
        { name: "金山区", code: "310116", level: 3, firstChar: null, children: null },
        { name: "松江区", code: "310117", level: 3, firstChar: null, children: null },
        { name: "青浦区", code: "310118", level: 3, firstChar: null, children: null },
        { name: "奉贤区", code: "310120", level: 3, firstChar: null, children: null },
        { name: "崇明区", code: "310151", level: 3, firstChar: null, children: null },
      ],
    },
    江苏: {
      南京市: [
        { name: "玄武区", code: "320102", level: 3, firstChar: null, children: null },
        { name: "秦淮区", code: "320104", level: 3, firstChar: null, children: null },
        { name: "建邺区", code: "320105", level: 3, firstChar: null, children: null },
        { name: "鼓楼区", code: "320106", level: 3, firstChar: null, children: null },
        { name: "浦口区", code: "320111", level: 3, firstChar: null, children: null },
        { name: "栖霞区", code: "320113", level: 3, firstChar: null, children: null },
        { name: "雨花台区", code: "320114", level: 3, firstChar: null, children: null },
        { name: "江宁区", code: "320115", level: 3, firstChar: null, children: null },
        { name: "六合区", code: "320116", level: 3, firstChar: null, children: null },
        { name: "溧水区", code: "320117", level: 3, firstChar: null, children: null },
        { name: "高淳区", code: "320118", level: 3, firstChar: null, children: null },
      ],
      无锡市: [
        { name: "锡山区", code: "320205", level: 3, firstChar: null, children: null },
        { name: "惠山区", code: "320206", level: 3, firstChar: null, children: null },
        { name: "滨湖区", code: "320211", level: 3, firstChar: null, children: null },
        { name: "梁溪区", code: "320213", level: 3, firstChar: null, children: null },
        { name: "新吴区", code: "320214", level: 3, firstChar: null, children: null },
        { name: "江阴市", code: "320281", level: 3, firstChar: null, children: null },
        { name: "宜兴市", code: "320282", level: 3, firstChar: null, children: null },
      ],
      徐州市: [
        { name: "鼓楼区", code: "320302", level: 3, firstChar: null, children: null },
        { name: "云龙区", code: "320303", level: 3, firstChar: null, children: null },
        { name: "贾汪区", code: "320305", level: 3, firstChar: null, children: null },
        { name: "泉山区", code: "320311", level: 3, firstChar: null, children: null },
        { name: "铜山区", code: "320312", level: 3, firstChar: null, children: null },
        { name: "丰县", code: "320321", level: 3, firstChar: null, children: null },
        { name: "沛县", code: "320322", level: 3, firstChar: null, children: null },
        { name: "睢宁县", code: "320324", level: 3, firstChar: null, children: null },
        { name: "徐州经济技术开发区", code: "320371", level: 3, firstChar: null, children: null },
        { name: "新沂市", code: "320381", level: 3, firstChar: null, children: null },
        { name: "邳州市", code: "320382", level: 3, firstChar: null, children: null },
      ],
      常州市: [
        { name: "天宁区", code: "320402", level: 3, firstChar: null, children: null },
        { name: "钟楼区", code: "320404", level: 3, firstChar: null, children: null },
        { name: "新北区", code: "320411", level: 3, firstChar: null, children: null },
        { name: "武进区", code: "320412", level: 3, firstChar: null, children: null },
        { name: "金坛区", code: "320413", level: 3, firstChar: null, children: null },
        { name: "溧阳市", code: "320481", level: 3, firstChar: null, children: null },
      ],
      苏州市: [
        { name: "虎丘区", code: "320505", level: 3, firstChar: null, children: null },
        { name: "吴中区", code: "320506", level: 3, firstChar: null, children: null },
        { name: "相城区", code: "320507", level: 3, firstChar: null, children: null },
        { name: "姑苏区", code: "320508", level: 3, firstChar: null, children: null },
        { name: "吴江区", code: "320509", level: 3, firstChar: null, children: null },
        { name: "苏州工业园区", code: "320571", level: 3, firstChar: null, children: null },
        { name: "常熟市", code: "320581", level: 3, firstChar: null, children: null },
        { name: "张家港市", code: "320582", level: 3, firstChar: null, children: null },
        { name: "昆山市", code: "320583", level: 3, firstChar: null, children: null },
        { name: "太仓市", code: "320585", level: 3, firstChar: null, children: null },
      ],
      南通市: [
        { name: "崇川区", code: "320602", level: 3, firstChar: null, children: null },
        { name: "港闸区", code: "320611", level: 3, firstChar: null, children: null },
        { name: "通州区", code: "320612", level: 3, firstChar: null, children: null },
        { name: "如东县", code: "320623", level: 3, firstChar: null, children: null },
        { name: "南通经济技术开发区", code: "320671", level: 3, firstChar: null, children: null },
        { name: "启东市", code: "320681", level: 3, firstChar: null, children: null },
        { name: "如皋市", code: "320682", level: 3, firstChar: null, children: null },
        { name: "海门市", code: "320684", level: 3, firstChar: null, children: null },
        { name: "海安市", code: "320685", level: 3, firstChar: null, children: null },
      ],
      连云港市: [
        { name: "连云区", code: "320703", level: 3, firstChar: null, children: null },
        { name: "海州区", code: "320706", level: 3, firstChar: null, children: null },
        { name: "赣榆区", code: "320707", level: 3, firstChar: null, children: null },
        { name: "东海县", code: "320722", level: 3, firstChar: null, children: null },
        { name: "灌云县", code: "320723", level: 3, firstChar: null, children: null },
        { name: "灌南县", code: "320724", level: 3, firstChar: null, children: null },
        { name: "连云港经济技术开发区", code: "320771", level: 3, firstChar: null, children: null },
        {
          name: "连云港高新技术产业开发区",
          code: "320772",
          level: 3,
          firstChar: null,
          children: null,
        },
      ],
      淮安市: [
        { name: "淮安区", code: "320803", level: 3, firstChar: null, children: null },
        { name: "淮阴区", code: "320804", level: 3, firstChar: null, children: null },
        { name: "清江浦区", code: "320812", level: 3, firstChar: null, children: null },
        { name: "洪泽区", code: "320813", level: 3, firstChar: null, children: null },
        { name: "涟水县", code: "320826", level: 3, firstChar: null, children: null },
        { name: "盱眙县", code: "320830", level: 3, firstChar: null, children: null },
        { name: "金湖县", code: "320831", level: 3, firstChar: null, children: null },
        { name: "淮安经济技术开发区", code: "320871", level: 3, firstChar: null, children: null },
      ],
      盐城市: [
        { name: "亭湖区", code: "320902", level: 3, firstChar: null, children: null },
        { name: "盐都区", code: "320903", level: 3, firstChar: null, children: null },
        { name: "大丰区", code: "320904", level: 3, firstChar: null, children: null },
        { name: "响水县", code: "320921", level: 3, firstChar: null, children: null },
        { name: "滨海县", code: "320922", level: 3, firstChar: null, children: null },
        { name: "阜宁县", code: "320923", level: 3, firstChar: null, children: null },
        { name: "射阳县", code: "320924", level: 3, firstChar: null, children: null },
        { name: "建湖县", code: "320925", level: 3, firstChar: null, children: null },
        { name: "盐城经济技术开发区", code: "320971", level: 3, firstChar: null, children: null },
        { name: "东台市", code: "320981", level: 3, firstChar: null, children: null },
      ],
      扬州市: [
        { name: "广陵区", code: "321002", level: 3, firstChar: null, children: null },
        { name: "邗江区", code: "321003", level: 3, firstChar: null, children: null },
        { name: "江都区", code: "321012", level: 3, firstChar: null, children: null },
        { name: "宝应县", code: "321023", level: 3, firstChar: null, children: null },
        { name: "扬州经济技术开发区", code: "321071", level: 3, firstChar: null, children: null },
        { name: "仪征市", code: "321081", level: 3, firstChar: null, children: null },
        { name: "高邮市", code: "321084", level: 3, firstChar: null, children: null },
      ],
      镇江市: [
        { name: "京口区", code: "321102", level: 3, firstChar: null, children: null },
        { name: "润州区", code: "321111", level: 3, firstChar: null, children: null },
        { name: "丹徒区", code: "321112", level: 3, firstChar: null, children: null },
        { name: "镇江新区", code: "321171", level: 3, firstChar: null, children: null },
        { name: "丹阳市", code: "321181", level: 3, firstChar: null, children: null },
        { name: "扬中市", code: "321182", level: 3, firstChar: null, children: null },
        { name: "句容市", code: "321183", level: 3, firstChar: null, children: null },
      ],
      泰州市: [
        { name: "海陵区", code: "321202", level: 3, firstChar: null, children: null },
        { name: "高港区", code: "321203", level: 3, firstChar: null, children: null },
        { name: "姜堰区", code: "321204", level: 3, firstChar: null, children: null },
        {
          name: "泰州医药高新技术产业开发区",
          code: "321271",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "兴化市", code: "321281", level: 3, firstChar: null, children: null },
        { name: "靖江市", code: "321282", level: 3, firstChar: null, children: null },
        { name: "泰兴市", code: "321283", level: 3, firstChar: null, children: null },
      ],
      宿迁市: [
        { name: "宿城区", code: "321302", level: 3, firstChar: null, children: null },
        { name: "宿豫区", code: "321311", level: 3, firstChar: null, children: null },
        { name: "沭阳县", code: "321322", level: 3, firstChar: null, children: null },
        { name: "泗阳县", code: "321323", level: 3, firstChar: null, children: null },
        { name: "泗洪县", code: "321324", level: 3, firstChar: null, children: null },
        { name: "宿迁经济技术开发区", code: "321371", level: 3, firstChar: null, children: null },
      ],
    },
    浙江: {
      杭州市: [
        { name: "上城区", code: "330102", level: 3, firstChar: null, children: null },
        { name: "下城区", code: "330103", level: 3, firstChar: null, children: null },
        { name: "江干区", code: "330104", level: 3, firstChar: null, children: null },
        { name: "拱墅区", code: "330105", level: 3, firstChar: null, children: null },
        { name: "西湖区", code: "330106", level: 3, firstChar: null, children: null },
        { name: "滨江区", code: "330108", level: 3, firstChar: null, children: null },
        { name: "萧山区", code: "330109", level: 3, firstChar: null, children: null },
        { name: "余杭区", code: "330110", level: 3, firstChar: null, children: null },
        { name: "富阳区", code: "330111", level: 3, firstChar: null, children: null },
        { name: "临安区", code: "330112", level: 3, firstChar: null, children: null },
        { name: "桐庐县", code: "330122", level: 3, firstChar: null, children: null },
        { name: "淳安县", code: "330127", level: 3, firstChar: null, children: null },
        { name: "建德市", code: "330182", level: 3, firstChar: null, children: null },
      ],
      宁波市: [
        { name: "海曙区", code: "330203", level: 3, firstChar: null, children: null },
        { name: "江北区", code: "330205", level: 3, firstChar: null, children: null },
        { name: "北仑区", code: "330206", level: 3, firstChar: null, children: null },
        { name: "镇海区", code: "330211", level: 3, firstChar: null, children: null },
        { name: "鄞州区", code: "330212", level: 3, firstChar: null, children: null },
        { name: "奉化区", code: "330213", level: 3, firstChar: null, children: null },
        { name: "象山县", code: "330225", level: 3, firstChar: null, children: null },
        { name: "宁海县", code: "330226", level: 3, firstChar: null, children: null },
        { name: "余姚市", code: "330281", level: 3, firstChar: null, children: null },
        { name: "慈溪市", code: "330282", level: 3, firstChar: null, children: null },
      ],
      温州市: [
        { name: "鹿城区", code: "330302", level: 3, firstChar: null, children: null },
        { name: "龙湾区", code: "330303", level: 3, firstChar: null, children: null },
        { name: "瓯海区", code: "330304", level: 3, firstChar: null, children: null },
        { name: "洞头区", code: "330305", level: 3, firstChar: null, children: null },
        { name: "永嘉县", code: "330324", level: 3, firstChar: null, children: null },
        { name: "平阳县", code: "330326", level: 3, firstChar: null, children: null },
        { name: "苍南县", code: "330327", level: 3, firstChar: null, children: null },
        { name: "文成县", code: "330328", level: 3, firstChar: null, children: null },
        { name: "泰顺县", code: "330329", level: 3, firstChar: null, children: null },
        { name: "温州经济技术开发区", code: "330371", level: 3, firstChar: null, children: null },
        { name: "瑞安市", code: "330381", level: 3, firstChar: null, children: null },
        { name: "乐清市", code: "330382", level: 3, firstChar: null, children: null },
        { name: "龙港市", code: "330383", level: 3, firstChar: null, children: null },
      ],
      嘉兴市: [
        { name: "南湖区", code: "330402", level: 3, firstChar: null, children: null },
        { name: "秀洲区", code: "330411", level: 3, firstChar: null, children: null },
        { name: "嘉善县", code: "330421", level: 3, firstChar: null, children: null },
        { name: "海盐县", code: "330424", level: 3, firstChar: null, children: null },
        { name: "海宁市", code: "330481", level: 3, firstChar: null, children: null },
        { name: "平湖市", code: "330482", level: 3, firstChar: null, children: null },
        { name: "桐乡市", code: "330483", level: 3, firstChar: null, children: null },
      ],
      湖州市: [
        { name: "吴兴区", code: "330502", level: 3, firstChar: null, children: null },
        { name: "南浔区", code: "330503", level: 3, firstChar: null, children: null },
        { name: "德清县", code: "330521", level: 3, firstChar: null, children: null },
        { name: "长兴县", code: "330522", level: 3, firstChar: null, children: null },
        { name: "安吉县", code: "330523", level: 3, firstChar: null, children: null },
      ],
      绍兴市: [
        { name: "越城区", code: "330602", level: 3, firstChar: null, children: null },
        { name: "柯桥区", code: "330603", level: 3, firstChar: null, children: null },
        { name: "上虞区", code: "330604", level: 3, firstChar: null, children: null },
        { name: "新昌县", code: "330624", level: 3, firstChar: null, children: null },
        { name: "诸暨市", code: "330681", level: 3, firstChar: null, children: null },
        { name: "嵊州市", code: "330683", level: 3, firstChar: null, children: null },
      ],
      金华市: [
        { name: "婺城区", code: "330702", level: 3, firstChar: null, children: null },
        { name: "金东区", code: "330703", level: 3, firstChar: null, children: null },
        { name: "武义县", code: "330723", level: 3, firstChar: null, children: null },
        { name: "浦江县", code: "330726", level: 3, firstChar: null, children: null },
        { name: "磐安县", code: "330727", level: 3, firstChar: null, children: null },
        { name: "兰溪市", code: "330781", level: 3, firstChar: null, children: null },
        { name: "义乌市", code: "330782", level: 3, firstChar: null, children: null },
        { name: "东阳市", code: "330783", level: 3, firstChar: null, children: null },
        { name: "永康市", code: "330784", level: 3, firstChar: null, children: null },
      ],
      衢州市: [
        { name: "柯城区", code: "330802", level: 3, firstChar: null, children: null },
        { name: "衢江区", code: "330803", level: 3, firstChar: null, children: null },
        { name: "常山县", code: "330822", level: 3, firstChar: null, children: null },
        { name: "开化县", code: "330824", level: 3, firstChar: null, children: null },
        { name: "龙游县", code: "330825", level: 3, firstChar: null, children: null },
        { name: "江山市", code: "330881", level: 3, firstChar: null, children: null },
      ],
      舟山市: [
        { name: "定海区", code: "330902", level: 3, firstChar: null, children: null },
        { name: "普陀区", code: "330903", level: 3, firstChar: null, children: null },
        { name: "岱山县", code: "330921", level: 3, firstChar: null, children: null },
        { name: "嵊泗县", code: "330922", level: 3, firstChar: null, children: null },
      ],
      台州市: [
        { name: "椒江区", code: "331002", level: 3, firstChar: null, children: null },
        { name: "黄岩区", code: "331003", level: 3, firstChar: null, children: null },
        { name: "路桥区", code: "331004", level: 3, firstChar: null, children: null },
        { name: "三门县", code: "331022", level: 3, firstChar: null, children: null },
        { name: "天台县", code: "331023", level: 3, firstChar: null, children: null },
        { name: "仙居县", code: "331024", level: 3, firstChar: null, children: null },
        { name: "温岭市", code: "331081", level: 3, firstChar: null, children: null },
        { name: "临海市", code: "331082", level: 3, firstChar: null, children: null },
        { name: "玉环市", code: "331083", level: 3, firstChar: null, children: null },
      ],
      丽水市: [
        { name: "莲都区", code: "331102", level: 3, firstChar: null, children: null },
        { name: "青田县", code: "331121", level: 3, firstChar: null, children: null },
        { name: "缙云县", code: "331122", level: 3, firstChar: null, children: null },
        { name: "遂昌县", code: "331123", level: 3, firstChar: null, children: null },
        { name: "松阳县", code: "331124", level: 3, firstChar: null, children: null },
        { name: "云和县", code: "331125", level: 3, firstChar: null, children: null },
        { name: "庆元县", code: "331126", level: 3, firstChar: null, children: null },
        { name: "景宁畲族自治县", code: "331127", level: 3, firstChar: null, children: null },
        { name: "龙泉市", code: "331181", level: 3, firstChar: null, children: null },
      ],
    },
    安徽: {
      合肥市: [
        { name: "瑶海区", code: "340102", level: 3, firstChar: null, children: null },
        { name: "庐阳区", code: "340103", level: 3, firstChar: null, children: null },
        { name: "蜀山区", code: "340104", level: 3, firstChar: null, children: null },
        { name: "包河区", code: "340111", level: 3, firstChar: null, children: null },
        { name: "长丰县", code: "340121", level: 3, firstChar: null, children: null },
        { name: "肥东县", code: "340122", level: 3, firstChar: null, children: null },
        { name: "肥西县", code: "340123", level: 3, firstChar: null, children: null },
        { name: "庐江县", code: "340124", level: 3, firstChar: null, children: null },
        {
          name: "合肥高新技术产业开发区",
          code: "340171",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "合肥经济技术开发区", code: "340172", level: 3, firstChar: null, children: null },
        {
          name: "合肥新站高新技术产业开发区",
          code: "340173",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "巢湖市", code: "340181", level: 3, firstChar: null, children: null },
      ],
      芜湖市: [
        { name: "镜湖区", code: "340202", level: 3, firstChar: null, children: null },
        { name: "弋江区", code: "340203", level: 3, firstChar: null, children: null },
        { name: "鸠江区", code: "340207", level: 3, firstChar: null, children: null },
        { name: "三山区", code: "340208", level: 3, firstChar: null, children: null },
        { name: "芜湖县", code: "340221", level: 3, firstChar: null, children: null },
        { name: "繁昌县", code: "340222", level: 3, firstChar: null, children: null },
        { name: "南陵县", code: "340223", level: 3, firstChar: null, children: null },
        { name: "芜湖经济技术开发区", code: "340271", level: 3, firstChar: null, children: null },
        {
          name: "安徽芜湖长江大桥经济开发区",
          code: "340272",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "无为市", code: "340281", level: 3, firstChar: null, children: null },
      ],
      蚌埠市: [
        { name: "龙子湖区", code: "340302", level: 3, firstChar: null, children: null },
        { name: "蚌山区", code: "340303", level: 3, firstChar: null, children: null },
        { name: "禹会区", code: "340304", level: 3, firstChar: null, children: null },
        { name: "淮上区", code: "340311", level: 3, firstChar: null, children: null },
        { name: "怀远县", code: "340321", level: 3, firstChar: null, children: null },
        { name: "五河县", code: "340322", level: 3, firstChar: null, children: null },
        { name: "固镇县", code: "340323", level: 3, firstChar: null, children: null },
        { name: "蚌埠市高新技术开发区", code: "340371", level: 3, firstChar: null, children: null },
        { name: "蚌埠市经济开发区", code: "340372", level: 3, firstChar: null, children: null },
      ],
      淮南市: [
        { name: "大通区", code: "340402", level: 3, firstChar: null, children: null },
        { name: "田家庵区", code: "340403", level: 3, firstChar: null, children: null },
        { name: "谢家集区", code: "340404", level: 3, firstChar: null, children: null },
        { name: "八公山区", code: "340405", level: 3, firstChar: null, children: null },
        { name: "潘集区", code: "340406", level: 3, firstChar: null, children: null },
        { name: "凤台县", code: "340421", level: 3, firstChar: null, children: null },
        { name: "寿县", code: "340422", level: 3, firstChar: null, children: null },
      ],
      马鞍山市: [
        { name: "花山区", code: "340503", level: 3, firstChar: null, children: null },
        { name: "雨山区", code: "340504", level: 3, firstChar: null, children: null },
        { name: "博望区", code: "340506", level: 3, firstChar: null, children: null },
        { name: "当涂县", code: "340521", level: 3, firstChar: null, children: null },
        { name: "含山县", code: "340522", level: 3, firstChar: null, children: null },
        { name: "和县", code: "340523", level: 3, firstChar: null, children: null },
      ],
      淮北市: [
        { name: "杜集区", code: "340602", level: 3, firstChar: null, children: null },
        { name: "相山区", code: "340603", level: 3, firstChar: null, children: null },
        { name: "烈山区", code: "340604", level: 3, firstChar: null, children: null },
        { name: "濉溪县", code: "340621", level: 3, firstChar: null, children: null },
      ],
      铜陵市: [
        { name: "铜官区", code: "340705", level: 3, firstChar: null, children: null },
        { name: "义安区", code: "340706", level: 3, firstChar: null, children: null },
        { name: "郊区", code: "340711", level: 3, firstChar: null, children: null },
        { name: "枞阳县", code: "340722", level: 3, firstChar: null, children: null },
      ],
      安庆市: [
        { name: "迎江区", code: "340802", level: 3, firstChar: null, children: null },
        { name: "大观区", code: "340803", level: 3, firstChar: null, children: null },
        { name: "宜秀区", code: "340811", level: 3, firstChar: null, children: null },
        { name: "怀宁县", code: "340822", level: 3, firstChar: null, children: null },
        { name: "太湖县", code: "340825", level: 3, firstChar: null, children: null },
        { name: "宿松县", code: "340826", level: 3, firstChar: null, children: null },
        { name: "望江县", code: "340827", level: 3, firstChar: null, children: null },
        { name: "岳西县", code: "340828", level: 3, firstChar: null, children: null },
        { name: "安徽安庆经济开发区", code: "340871", level: 3, firstChar: null, children: null },
        { name: "桐城市", code: "340881", level: 3, firstChar: null, children: null },
        { name: "潜山市", code: "340882", level: 3, firstChar: null, children: null },
      ],
      黄山市: [
        { name: "屯溪区", code: "341002", level: 3, firstChar: null, children: null },
        { name: "黄山区", code: "341003", level: 3, firstChar: null, children: null },
        { name: "徽州区", code: "341004", level: 3, firstChar: null, children: null },
        { name: "歙县", code: "341021", level: 3, firstChar: null, children: null },
        { name: "休宁县", code: "341022", level: 3, firstChar: null, children: null },
        { name: "黟县", code: "341023", level: 3, firstChar: null, children: null },
        { name: "祁门县", code: "341024", level: 3, firstChar: null, children: null },
      ],
      滁州市: [
        { name: "琅琊区", code: "341102", level: 3, firstChar: null, children: null },
        { name: "南谯区", code: "341103", level: 3, firstChar: null, children: null },
        { name: "来安县", code: "341122", level: 3, firstChar: null, children: null },
        { name: "全椒县", code: "341124", level: 3, firstChar: null, children: null },
        { name: "定远县", code: "341125", level: 3, firstChar: null, children: null },
        { name: "凤阳县", code: "341126", level: 3, firstChar: null, children: null },
        { name: "苏滁现代产业园", code: "341171", level: 3, firstChar: null, children: null },
        { name: "滁州经济技术开发区", code: "341172", level: 3, firstChar: null, children: null },
        { name: "天长市", code: "341181", level: 3, firstChar: null, children: null },
        { name: "明光市", code: "341182", level: 3, firstChar: null, children: null },
      ],
      阜阳市: [
        { name: "颍州区", code: "341202", level: 3, firstChar: null, children: null },
        { name: "颍东区", code: "341203", level: 3, firstChar: null, children: null },
        { name: "颍泉区", code: "341204", level: 3, firstChar: null, children: null },
        { name: "临泉县", code: "341221", level: 3, firstChar: null, children: null },
        { name: "太和县", code: "341222", level: 3, firstChar: null, children: null },
        { name: "阜南县", code: "341225", level: 3, firstChar: null, children: null },
        { name: "颍上县", code: "341226", level: 3, firstChar: null, children: null },
        { name: "阜阳合肥现代产业园区", code: "341271", level: 3, firstChar: null, children: null },
        { name: "阜阳经济技术开发区", code: "341272", level: 3, firstChar: null, children: null },
        { name: "界首市", code: "341282", level: 3, firstChar: null, children: null },
      ],
      宿州市: [
        { name: "埇桥区", code: "341302", level: 3, firstChar: null, children: null },
        { name: "砀山县", code: "341321", level: 3, firstChar: null, children: null },
        { name: "萧县", code: "341322", level: 3, firstChar: null, children: null },
        { name: "灵璧县", code: "341323", level: 3, firstChar: null, children: null },
        { name: "泗县", code: "341324", level: 3, firstChar: null, children: null },
        {
          name: "宿州马鞍山现代产业园区",
          code: "341371",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "宿州经济技术开发区", code: "341372", level: 3, firstChar: null, children: null },
      ],
      六安市: [
        { name: "金安区", code: "341502", level: 3, firstChar: null, children: null },
        { name: "裕安区", code: "341503", level: 3, firstChar: null, children: null },
        { name: "叶集区", code: "341504", level: 3, firstChar: null, children: null },
        { name: "霍邱县", code: "341522", level: 3, firstChar: null, children: null },
        { name: "舒城县", code: "341523", level: 3, firstChar: null, children: null },
        { name: "金寨县", code: "341524", level: 3, firstChar: null, children: null },
        { name: "霍山县", code: "341525", level: 3, firstChar: null, children: null },
      ],
      亳州市: [
        { name: "谯城区", code: "341602", level: 3, firstChar: null, children: null },
        { name: "涡阳县", code: "341621", level: 3, firstChar: null, children: null },
        { name: "蒙城县", code: "341622", level: 3, firstChar: null, children: null },
        { name: "利辛县", code: "341623", level: 3, firstChar: null, children: null },
      ],
      池州市: [
        { name: "贵池区", code: "341702", level: 3, firstChar: null, children: null },
        { name: "东至县", code: "341721", level: 3, firstChar: null, children: null },
        { name: "石台县", code: "341722", level: 3, firstChar: null, children: null },
        { name: "青阳县", code: "341723", level: 3, firstChar: null, children: null },
      ],
      宣城市: [
        { name: "宣州区", code: "341802", level: 3, firstChar: null, children: null },
        { name: "郎溪县", code: "341821", level: 3, firstChar: null, children: null },
        { name: "泾县", code: "341823", level: 3, firstChar: null, children: null },
        { name: "绩溪县", code: "341824", level: 3, firstChar: null, children: null },
        { name: "旌德县", code: "341825", level: 3, firstChar: null, children: null },
        { name: "宣城市经济开发区", code: "341871", level: 3, firstChar: null, children: null },
        { name: "宁国市", code: "341881", level: 3, firstChar: null, children: null },
        { name: "广德市", code: "341882", level: 3, firstChar: null, children: null },
      ],
    },
    福建: {
      福州市: [
        { name: "鼓楼区", code: "350102", level: 3, firstChar: null, children: null },
        { name: "台江区", code: "350103", level: 3, firstChar: null, children: null },
        { name: "仓山区", code: "350104", level: 3, firstChar: null, children: null },
        { name: "马尾区", code: "350105", level: 3, firstChar: null, children: null },
        { name: "晋安区", code: "350111", level: 3, firstChar: null, children: null },
        { name: "长乐区", code: "350112", level: 3, firstChar: null, children: null },
        { name: "闽侯县", code: "350121", level: 3, firstChar: null, children: null },
        { name: "连江县", code: "350122", level: 3, firstChar: null, children: null },
        { name: "罗源县", code: "350123", level: 3, firstChar: null, children: null },
        { name: "闽清县", code: "350124", level: 3, firstChar: null, children: null },
        { name: "永泰县", code: "350125", level: 3, firstChar: null, children: null },
        { name: "平潭县", code: "350128", level: 3, firstChar: null, children: null },
        { name: "福清市", code: "350181", level: 3, firstChar: null, children: null },
      ],
      厦门市: [
        { name: "思明区", code: "350203", level: 3, firstChar: null, children: null },
        { name: "海沧区", code: "350205", level: 3, firstChar: null, children: null },
        { name: "湖里区", code: "350206", level: 3, firstChar: null, children: null },
        { name: "集美区", code: "350211", level: 3, firstChar: null, children: null },
        { name: "同安区", code: "350212", level: 3, firstChar: null, children: null },
        { name: "翔安区", code: "350213", level: 3, firstChar: null, children: null },
      ],
      莆田市: [
        { name: "城厢区", code: "350302", level: 3, firstChar: null, children: null },
        { name: "涵江区", code: "350303", level: 3, firstChar: null, children: null },
        { name: "荔城区", code: "350304", level: 3, firstChar: null, children: null },
        { name: "秀屿区", code: "350305", level: 3, firstChar: null, children: null },
        { name: "仙游县", code: "350322", level: 3, firstChar: null, children: null },
      ],
      三明市: [
        { name: "梅列区", code: "350402", level: 3, firstChar: null, children: null },
        { name: "三元区", code: "350403", level: 3, firstChar: null, children: null },
        { name: "明溪县", code: "350421", level: 3, firstChar: null, children: null },
        { name: "清流县", code: "350423", level: 3, firstChar: null, children: null },
        { name: "宁化县", code: "350424", level: 3, firstChar: null, children: null },
        { name: "大田县", code: "350425", level: 3, firstChar: null, children: null },
        { name: "尤溪县", code: "350426", level: 3, firstChar: null, children: null },
        { name: "沙县", code: "350427", level: 3, firstChar: null, children: null },
        { name: "将乐县", code: "350428", level: 3, firstChar: null, children: null },
        { name: "泰宁县", code: "350429", level: 3, firstChar: null, children: null },
        { name: "建宁县", code: "350430", level: 3, firstChar: null, children: null },
        { name: "永安市", code: "350481", level: 3, firstChar: null, children: null },
      ],
      泉州市: [
        { name: "鲤城区", code: "350502", level: 3, firstChar: null, children: null },
        { name: "丰泽区", code: "350503", level: 3, firstChar: null, children: null },
        { name: "洛江区", code: "350504", level: 3, firstChar: null, children: null },
        { name: "泉港区", code: "350505", level: 3, firstChar: null, children: null },
        { name: "惠安县", code: "350521", level: 3, firstChar: null, children: null },
        { name: "安溪县", code: "350524", level: 3, firstChar: null, children: null },
        { name: "永春县", code: "350525", level: 3, firstChar: null, children: null },
        { name: "德化县", code: "350526", level: 3, firstChar: null, children: null },
        { name: "金门县", code: "350527", level: 3, firstChar: null, children: null },
        { name: "石狮市", code: "350581", level: 3, firstChar: null, children: null },
        { name: "晋江市", code: "350582", level: 3, firstChar: null, children: null },
        { name: "南安市", code: "350583", level: 3, firstChar: null, children: null },
      ],
      漳州市: [
        { name: "芗城区", code: "350602", level: 3, firstChar: null, children: null },
        { name: "龙文区", code: "350603", level: 3, firstChar: null, children: null },
        { name: "云霄县", code: "350622", level: 3, firstChar: null, children: null },
        { name: "漳浦县", code: "350623", level: 3, firstChar: null, children: null },
        { name: "诏安县", code: "350624", level: 3, firstChar: null, children: null },
        { name: "长泰县", code: "350625", level: 3, firstChar: null, children: null },
        { name: "东山县", code: "350626", level: 3, firstChar: null, children: null },
        { name: "南靖县", code: "350627", level: 3, firstChar: null, children: null },
        { name: "平和县", code: "350628", level: 3, firstChar: null, children: null },
        { name: "华安县", code: "350629", level: 3, firstChar: null, children: null },
        { name: "龙海市", code: "350681", level: 3, firstChar: null, children: null },
      ],
      南平市: [
        { name: "延平区", code: "350702", level: 3, firstChar: null, children: null },
        { name: "建阳区", code: "350703", level: 3, firstChar: null, children: null },
        { name: "顺昌县", code: "350721", level: 3, firstChar: null, children: null },
        { name: "浦城县", code: "350722", level: 3, firstChar: null, children: null },
        { name: "光泽县", code: "350723", level: 3, firstChar: null, children: null },
        { name: "松溪县", code: "350724", level: 3, firstChar: null, children: null },
        { name: "政和县", code: "350725", level: 3, firstChar: null, children: null },
        { name: "邵武市", code: "350781", level: 3, firstChar: null, children: null },
        { name: "武夷山市", code: "350782", level: 3, firstChar: null, children: null },
        { name: "建瓯市", code: "350783", level: 3, firstChar: null, children: null },
      ],
      龙岩市: [
        { name: "新罗区", code: "350802", level: 3, firstChar: null, children: null },
        { name: "永定区", code: "350803", level: 3, firstChar: null, children: null },
        { name: "长汀县", code: "350821", level: 3, firstChar: null, children: null },
        { name: "上杭县", code: "350823", level: 3, firstChar: null, children: null },
        { name: "武平县", code: "350824", level: 3, firstChar: null, children: null },
        { name: "连城县", code: "350825", level: 3, firstChar: null, children: null },
        { name: "漳平市", code: "350881", level: 3, firstChar: null, children: null },
      ],
      宁德市: [
        { name: "蕉城区", code: "350902", level: 3, firstChar: null, children: null },
        { name: "霞浦县", code: "350921", level: 3, firstChar: null, children: null },
        { name: "古田县", code: "350922", level: 3, firstChar: null, children: null },
        { name: "屏南县", code: "350923", level: 3, firstChar: null, children: null },
        { name: "寿宁县", code: "350924", level: 3, firstChar: null, children: null },
        { name: "周宁县", code: "350925", level: 3, firstChar: null, children: null },
        { name: "柘荣县", code: "350926", level: 3, firstChar: null, children: null },
        { name: "福安市", code: "350981", level: 3, firstChar: null, children: null },
        { name: "福鼎市", code: "350982", level: 3, firstChar: null, children: null },
      ],
    },
    江西: {
      南昌市: [
        { name: "东湖区", code: "360102", level: 3, firstChar: null, children: null },
        { name: "西湖区", code: "360103", level: 3, firstChar: null, children: null },
        { name: "青云谱区", code: "360104", level: 3, firstChar: null, children: null },
        { name: "青山湖区", code: "360111", level: 3, firstChar: null, children: null },
        { name: "新建区", code: "360112", level: 3, firstChar: null, children: null },
        { name: "红谷滩区", code: "360113", level: 3, firstChar: null, children: null },
        { name: "南昌县", code: "360121", level: 3, firstChar: null, children: null },
        { name: "安义县", code: "360123", level: 3, firstChar: null, children: null },
        { name: "进贤县", code: "360124", level: 3, firstChar: null, children: null },
      ],
      景德镇市: [
        { name: "昌江区", code: "360202", level: 3, firstChar: null, children: null },
        { name: "珠山区", code: "360203", level: 3, firstChar: null, children: null },
        { name: "浮梁县", code: "360222", level: 3, firstChar: null, children: null },
        { name: "乐平市", code: "360281", level: 3, firstChar: null, children: null },
      ],
      萍乡市: [
        { name: "安源区", code: "360302", level: 3, firstChar: null, children: null },
        { name: "湘东区", code: "360313", level: 3, firstChar: null, children: null },
        { name: "莲花县", code: "360321", level: 3, firstChar: null, children: null },
        { name: "上栗县", code: "360322", level: 3, firstChar: null, children: null },
        { name: "芦溪县", code: "360323", level: 3, firstChar: null, children: null },
      ],
      九江市: [
        { name: "濂溪区", code: "360402", level: 3, firstChar: null, children: null },
        { name: "浔阳区", code: "360403", level: 3, firstChar: null, children: null },
        { name: "柴桑区", code: "360404", level: 3, firstChar: null, children: null },
        { name: "武宁县", code: "360423", level: 3, firstChar: null, children: null },
        { name: "修水县", code: "360424", level: 3, firstChar: null, children: null },
        { name: "永修县", code: "360425", level: 3, firstChar: null, children: null },
        { name: "德安县", code: "360426", level: 3, firstChar: null, children: null },
        { name: "都昌县", code: "360428", level: 3, firstChar: null, children: null },
        { name: "湖口县", code: "360429", level: 3, firstChar: null, children: null },
        { name: "彭泽县", code: "360430", level: 3, firstChar: null, children: null },
        { name: "瑞昌市", code: "360481", level: 3, firstChar: null, children: null },
        { name: "共青城市", code: "360482", level: 3, firstChar: null, children: null },
        { name: "庐山市", code: "360483", level: 3, firstChar: null, children: null },
      ],
      新余市: [
        { name: "渝水区", code: "360502", level: 3, firstChar: null, children: null },
        { name: "分宜县", code: "360521", level: 3, firstChar: null, children: null },
      ],
      鹰潭市: [
        { name: "月湖区", code: "360602", level: 3, firstChar: null, children: null },
        { name: "余江区", code: "360603", level: 3, firstChar: null, children: null },
        { name: "贵溪市", code: "360681", level: 3, firstChar: null, children: null },
      ],
      赣州市: [
        { name: "章贡区", code: "360702", level: 3, firstChar: null, children: null },
        { name: "南康区", code: "360703", level: 3, firstChar: null, children: null },
        { name: "赣县区", code: "360704", level: 3, firstChar: null, children: null },
        { name: "信丰县", code: "360722", level: 3, firstChar: null, children: null },
        { name: "大余县", code: "360723", level: 3, firstChar: null, children: null },
        { name: "上犹县", code: "360724", level: 3, firstChar: null, children: null },
        { name: "崇义县", code: "360725", level: 3, firstChar: null, children: null },
        { name: "安远县", code: "360726", level: 3, firstChar: null, children: null },
        { name: "定南县", code: "360728", level: 3, firstChar: null, children: null },
        { name: "全南县", code: "360729", level: 3, firstChar: null, children: null },
        { name: "宁都县", code: "360730", level: 3, firstChar: null, children: null },
        { name: "于都县", code: "360731", level: 3, firstChar: null, children: null },
        { name: "兴国县", code: "360732", level: 3, firstChar: null, children: null },
        { name: "会昌县", code: "360733", level: 3, firstChar: null, children: null },
        { name: "寻乌县", code: "360734", level: 3, firstChar: null, children: null },
        { name: "石城县", code: "360735", level: 3, firstChar: null, children: null },
        { name: "瑞金市", code: "360781", level: 3, firstChar: null, children: null },
        { name: "龙南市", code: "360783", level: 3, firstChar: null, children: null },
      ],
      吉安市: [
        { name: "吉州区", code: "360802", level: 3, firstChar: null, children: null },
        { name: "青原区", code: "360803", level: 3, firstChar: null, children: null },
        { name: "吉安县", code: "360821", level: 3, firstChar: null, children: null },
        { name: "吉水县", code: "360822", level: 3, firstChar: null, children: null },
        { name: "峡江县", code: "360823", level: 3, firstChar: null, children: null },
        { name: "新干县", code: "360824", level: 3, firstChar: null, children: null },
        { name: "永丰县", code: "360825", level: 3, firstChar: null, children: null },
        { name: "泰和县", code: "360826", level: 3, firstChar: null, children: null },
        { name: "遂川县", code: "360827", level: 3, firstChar: null, children: null },
        { name: "万安县", code: "360828", level: 3, firstChar: null, children: null },
        { name: "安福县", code: "360829", level: 3, firstChar: null, children: null },
        { name: "永新县", code: "360830", level: 3, firstChar: null, children: null },
        { name: "井冈山市", code: "360881", level: 3, firstChar: null, children: null },
      ],
      宜春市: [
        { name: "袁州区", code: "360902", level: 3, firstChar: null, children: null },
        { name: "奉新县", code: "360921", level: 3, firstChar: null, children: null },
        { name: "万载县", code: "360922", level: 3, firstChar: null, children: null },
        { name: "上高县", code: "360923", level: 3, firstChar: null, children: null },
        { name: "宜丰县", code: "360924", level: 3, firstChar: null, children: null },
        { name: "靖安县", code: "360925", level: 3, firstChar: null, children: null },
        { name: "铜鼓县", code: "360926", level: 3, firstChar: null, children: null },
        { name: "丰城市", code: "360981", level: 3, firstChar: null, children: null },
        { name: "樟树市", code: "360982", level: 3, firstChar: null, children: null },
        { name: "高安市", code: "360983", level: 3, firstChar: null, children: null },
      ],
      抚州市: [
        { name: "临川区", code: "361002", level: 3, firstChar: null, children: null },
        { name: "东乡区", code: "361003", level: 3, firstChar: null, children: null },
        { name: "南城县", code: "361021", level: 3, firstChar: null, children: null },
        { name: "黎川县", code: "361022", level: 3, firstChar: null, children: null },
        { name: "南丰县", code: "361023", level: 3, firstChar: null, children: null },
        { name: "崇仁县", code: "361024", level: 3, firstChar: null, children: null },
        { name: "乐安县", code: "361025", level: 3, firstChar: null, children: null },
        { name: "宜黄县", code: "361026", level: 3, firstChar: null, children: null },
        { name: "金溪县", code: "361027", level: 3, firstChar: null, children: null },
        { name: "资溪县", code: "361028", level: 3, firstChar: null, children: null },
        { name: "广昌县", code: "361030", level: 3, firstChar: null, children: null },
      ],
      上饶市: [
        { name: "信州区", code: "361102", level: 3, firstChar: null, children: null },
        { name: "广丰区", code: "361103", level: 3, firstChar: null, children: null },
        { name: "广信区", code: "361104", level: 3, firstChar: null, children: null },
        { name: "玉山县", code: "361123", level: 3, firstChar: null, children: null },
        { name: "铅山县", code: "361124", level: 3, firstChar: null, children: null },
        { name: "横峰县", code: "361125", level: 3, firstChar: null, children: null },
        { name: "弋阳县", code: "361126", level: 3, firstChar: null, children: null },
        { name: "余干县", code: "361127", level: 3, firstChar: null, children: null },
        { name: "鄱阳县", code: "361128", level: 3, firstChar: null, children: null },
        { name: "万年县", code: "361129", level: 3, firstChar: null, children: null },
        { name: "婺源县", code: "361130", level: 3, firstChar: null, children: null },
        { name: "德兴市", code: "361181", level: 3, firstChar: null, children: null },
      ],
    },
    山东: {
      济南市: [
        { name: "历下区", code: "370102", level: 3, firstChar: null, children: null },
        { name: "市中区", code: "370103", level: 3, firstChar: null, children: null },
        { name: "槐荫区", code: "370104", level: 3, firstChar: null, children: null },
        { name: "天桥区", code: "370105", level: 3, firstChar: null, children: null },
        { name: "历城区", code: "370112", level: 3, firstChar: null, children: null },
        { name: "长清区", code: "370113", level: 3, firstChar: null, children: null },
        { name: "章丘区", code: "370114", level: 3, firstChar: null, children: null },
        { name: "济阳区", code: "370115", level: 3, firstChar: null, children: null },
        { name: "莱芜区", code: "370116", level: 3, firstChar: null, children: null },
        { name: "钢城区", code: "370117", level: 3, firstChar: null, children: null },
        { name: "平阴县", code: "370124", level: 3, firstChar: null, children: null },
        { name: "商河县", code: "370126", level: 3, firstChar: null, children: null },
        {
          name: "济南高新技术产业开发区",
          code: "370171",
          level: 3,
          firstChar: null,
          children: null,
        },
      ],
      青岛市: [
        { name: "市南区", code: "370202", level: 3, firstChar: null, children: null },
        { name: "市北区", code: "370203", level: 3, firstChar: null, children: null },
        { name: "黄岛区", code: "370211", level: 3, firstChar: null, children: null },
        { name: "崂山区", code: "370212", level: 3, firstChar: null, children: null },
        { name: "李沧区", code: "370213", level: 3, firstChar: null, children: null },
        { name: "城阳区", code: "370214", level: 3, firstChar: null, children: null },
        { name: "即墨区", code: "370215", level: 3, firstChar: null, children: null },
        {
          name: "青岛高新技术产业开发区",
          code: "370271",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "胶州市", code: "370281", level: 3, firstChar: null, children: null },
        { name: "平度市", code: "370283", level: 3, firstChar: null, children: null },
        { name: "莱西市", code: "370285", level: 3, firstChar: null, children: null },
      ],
      淄博市: [
        { name: "淄川区", code: "370302", level: 3, firstChar: null, children: null },
        { name: "张店区", code: "370303", level: 3, firstChar: null, children: null },
        { name: "博山区", code: "370304", level: 3, firstChar: null, children: null },
        { name: "临淄区", code: "370305", level: 3, firstChar: null, children: null },
        { name: "周村区", code: "370306", level: 3, firstChar: null, children: null },
        { name: "桓台县", code: "370321", level: 3, firstChar: null, children: null },
        { name: "高青县", code: "370322", level: 3, firstChar: null, children: null },
        { name: "沂源县", code: "370323", level: 3, firstChar: null, children: null },
      ],
      枣庄市: [
        { name: "市中区", code: "370402", level: 3, firstChar: null, children: null },
        { name: "薛城区", code: "370403", level: 3, firstChar: null, children: null },
        { name: "峄城区", code: "370404", level: 3, firstChar: null, children: null },
        { name: "台儿庄区", code: "370405", level: 3, firstChar: null, children: null },
        { name: "山亭区", code: "370406", level: 3, firstChar: null, children: null },
        { name: "滕州市", code: "370481", level: 3, firstChar: null, children: null },
      ],
      东营市: [
        { name: "东营区", code: "370502", level: 3, firstChar: null, children: null },
        { name: "河口区", code: "370503", level: 3, firstChar: null, children: null },
        { name: "垦利区", code: "370505", level: 3, firstChar: null, children: null },
        { name: "利津县", code: "370522", level: 3, firstChar: null, children: null },
        { name: "广饶县", code: "370523", level: 3, firstChar: null, children: null },
        { name: "东营经济技术开发区", code: "370571", level: 3, firstChar: null, children: null },
        { name: "东营港经济开发区", code: "370572", level: 3, firstChar: null, children: null },
      ],
      烟台市: [
        { name: "芝罘区", code: "370602", level: 3, firstChar: null, children: null },
        { name: "福山区", code: "370611", level: 3, firstChar: null, children: null },
        { name: "牟平区", code: "370612", level: 3, firstChar: null, children: null },
        { name: "莱山区", code: "370613", level: 3, firstChar: null, children: null },
        { name: "蓬莱区", code: "370614", level: 3, firstChar: null, children: null },
        {
          name: "烟台高新技术产业开发区",
          code: "370671",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "烟台经济技术开发区", code: "370672", level: 3, firstChar: null, children: null },
        { name: "龙口市", code: "370681", level: 3, firstChar: null, children: null },
        { name: "莱阳市", code: "370682", level: 3, firstChar: null, children: null },
        { name: "莱州市", code: "370683", level: 3, firstChar: null, children: null },
        { name: "招远市", code: "370685", level: 3, firstChar: null, children: null },
        { name: "栖霞市", code: "370686", level: 3, firstChar: null, children: null },
        { name: "海阳市", code: "370687", level: 3, firstChar: null, children: null },
      ],
      潍坊市: [
        { name: "潍城区", code: "370702", level: 3, firstChar: null, children: null },
        { name: "寒亭区", code: "370703", level: 3, firstChar: null, children: null },
        { name: "坊子区", code: "370704", level: 3, firstChar: null, children: null },
        { name: "奎文区", code: "370705", level: 3, firstChar: null, children: null },
        { name: "临朐县", code: "370724", level: 3, firstChar: null, children: null },
        { name: "昌乐县", code: "370725", level: 3, firstChar: null, children: null },
        {
          name: "潍坊滨海经济技术开发区",
          code: "370772",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "青州市", code: "370781", level: 3, firstChar: null, children: null },
        { name: "诸城市", code: "370782", level: 3, firstChar: null, children: null },
        { name: "寿光市", code: "370783", level: 3, firstChar: null, children: null },
        { name: "安丘市", code: "370784", level: 3, firstChar: null, children: null },
        { name: "高密市", code: "370785", level: 3, firstChar: null, children: null },
        { name: "昌邑市", code: "370786", level: 3, firstChar: null, children: null },
      ],
      济宁市: [
        { name: "任城区", code: "370811", level: 3, firstChar: null, children: null },
        { name: "兖州区", code: "370812", level: 3, firstChar: null, children: null },
        { name: "微山县", code: "370826", level: 3, firstChar: null, children: null },
        { name: "鱼台县", code: "370827", level: 3, firstChar: null, children: null },
        { name: "金乡县", code: "370828", level: 3, firstChar: null, children: null },
        { name: "嘉祥县", code: "370829", level: 3, firstChar: null, children: null },
        { name: "汶上县", code: "370830", level: 3, firstChar: null, children: null },
        { name: "泗水县", code: "370831", level: 3, firstChar: null, children: null },
        { name: "梁山县", code: "370832", level: 3, firstChar: null, children: null },
        {
          name: "济宁高新技术产业开发区",
          code: "370871",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "曲阜市", code: "370881", level: 3, firstChar: null, children: null },
        { name: "邹城市", code: "370883", level: 3, firstChar: null, children: null },
      ],
      泰安市: [
        { name: "泰山区", code: "370902", level: 3, firstChar: null, children: null },
        { name: "岱岳区", code: "370911", level: 3, firstChar: null, children: null },
        { name: "宁阳县", code: "370921", level: 3, firstChar: null, children: null },
        { name: "东平县", code: "370923", level: 3, firstChar: null, children: null },
        { name: "新泰市", code: "370982", level: 3, firstChar: null, children: null },
        { name: "肥城市", code: "370983", level: 3, firstChar: null, children: null },
      ],
      威海市: [
        { name: "环翠区", code: "371002", level: 3, firstChar: null, children: null },
        { name: "文登区", code: "371003", level: 3, firstChar: null, children: null },
        {
          name: "威海火炬高技术产业开发区",
          code: "371071",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "威海经济技术开发区", code: "371072", level: 3, firstChar: null, children: null },
        {
          name: "威海临港经济技术开发区",
          code: "371073",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "荣成市", code: "371082", level: 3, firstChar: null, children: null },
        { name: "乳山市", code: "371083", level: 3, firstChar: null, children: null },
      ],
      日照市: [
        { name: "东港区", code: "371102", level: 3, firstChar: null, children: null },
        { name: "岚山区", code: "371103", level: 3, firstChar: null, children: null },
        { name: "五莲县", code: "371121", level: 3, firstChar: null, children: null },
        { name: "莒县", code: "371122", level: 3, firstChar: null, children: null },
        { name: "日照经济技术开发区", code: "371171", level: 3, firstChar: null, children: null },
      ],
      临沂市: [
        { name: "兰山区", code: "371302", level: 3, firstChar: null, children: null },
        { name: "罗庄区", code: "371311", level: 3, firstChar: null, children: null },
        { name: "河东区", code: "371312", level: 3, firstChar: null, children: null },
        { name: "沂南县", code: "371321", level: 3, firstChar: null, children: null },
        { name: "郯城县", code: "371322", level: 3, firstChar: null, children: null },
        { name: "沂水县", code: "371323", level: 3, firstChar: null, children: null },
        { name: "兰陵县", code: "371324", level: 3, firstChar: null, children: null },
        { name: "费县", code: "371325", level: 3, firstChar: null, children: null },
        { name: "平邑县", code: "371326", level: 3, firstChar: null, children: null },
        { name: "莒南县", code: "371327", level: 3, firstChar: null, children: null },
        { name: "蒙阴县", code: "371328", level: 3, firstChar: null, children: null },
        { name: "临沭县", code: "371329", level: 3, firstChar: null, children: null },
        {
          name: "临沂高新技术产业开发区",
          code: "371371",
          level: 3,
          firstChar: null,
          children: null,
        },
      ],
      德州市: [
        { name: "德城区", code: "371402", level: 3, firstChar: null, children: null },
        { name: "陵城区", code: "371403", level: 3, firstChar: null, children: null },
        { name: "宁津县", code: "371422", level: 3, firstChar: null, children: null },
        { name: "庆云县", code: "371423", level: 3, firstChar: null, children: null },
        { name: "临邑县", code: "371424", level: 3, firstChar: null, children: null },
        { name: "齐河县", code: "371425", level: 3, firstChar: null, children: null },
        { name: "平原县", code: "371426", level: 3, firstChar: null, children: null },
        { name: "夏津县", code: "371427", level: 3, firstChar: null, children: null },
        { name: "武城县", code: "371428", level: 3, firstChar: null, children: null },
        { name: "德州经济技术开发区", code: "371471", level: 3, firstChar: null, children: null },
        { name: "德州运河经济开发区", code: "371472", level: 3, firstChar: null, children: null },
        { name: "乐陵市", code: "371481", level: 3, firstChar: null, children: null },
        { name: "禹城市", code: "371482", level: 3, firstChar: null, children: null },
      ],
      聊城市: [
        { name: "东昌府区", code: "371502", level: 3, firstChar: null, children: null },
        { name: "茌平区", code: "371503", level: 3, firstChar: null, children: null },
        { name: "阳谷县", code: "371521", level: 3, firstChar: null, children: null },
        { name: "莘县", code: "371522", level: 3, firstChar: null, children: null },
        { name: "东阿县", code: "371524", level: 3, firstChar: null, children: null },
        { name: "冠县", code: "371525", level: 3, firstChar: null, children: null },
        { name: "高唐县", code: "371526", level: 3, firstChar: null, children: null },
        { name: "临清市", code: "371581", level: 3, firstChar: null, children: null },
      ],
      滨州市: [
        { name: "滨城区", code: "371602", level: 3, firstChar: null, children: null },
        { name: "沾化区", code: "371603", level: 3, firstChar: null, children: null },
        { name: "惠民县", code: "371621", level: 3, firstChar: null, children: null },
        { name: "阳信县", code: "371622", level: 3, firstChar: null, children: null },
        { name: "无棣县", code: "371623", level: 3, firstChar: null, children: null },
        { name: "博兴县", code: "371625", level: 3, firstChar: null, children: null },
        { name: "邹平市", code: "371681", level: 3, firstChar: null, children: null },
      ],
      菏泽市: [
        { name: "牡丹区", code: "371702", level: 3, firstChar: null, children: null },
        { name: "定陶区", code: "371703", level: 3, firstChar: null, children: null },
        { name: "曹县", code: "371721", level: 3, firstChar: null, children: null },
        { name: "单县", code: "371722", level: 3, firstChar: null, children: null },
        { name: "成武县", code: "371723", level: 3, firstChar: null, children: null },
        { name: "巨野县", code: "371724", level: 3, firstChar: null, children: null },
        { name: "郓城县", code: "371725", level: 3, firstChar: null, children: null },
        { name: "鄄城县", code: "371726", level: 3, firstChar: null, children: null },
        { name: "东明县", code: "371728", level: 3, firstChar: null, children: null },
        { name: "菏泽经济技术开发区", code: "371771", level: 3, firstChar: null, children: null },
        { name: "菏泽高新技术开发区", code: "371772", level: 3, firstChar: null, children: null },
      ],
    },
    河南: {
      郑州市: [
        { name: "中原区", code: "410102", level: 3, firstChar: null, children: null },
        { name: "二七区", code: "410103", level: 3, firstChar: null, children: null },
        { name: "管城回族区", code: "410104", level: 3, firstChar: null, children: null },
        { name: "金水区", code: "410105", level: 3, firstChar: null, children: null },
        { name: "上街区", code: "410106", level: 3, firstChar: null, children: null },
        { name: "惠济区", code: "410108", level: 3, firstChar: null, children: null },
        { name: "中牟县", code: "410122", level: 3, firstChar: null, children: null },
        { name: "郑州经济技术开发区", code: "410171", level: 3, firstChar: null, children: null },
        {
          name: "郑州高新技术产业开发区",
          code: "410172",
          level: 3,
          firstChar: null,
          children: null,
        },
        {
          name: "郑州航空港经济综合实验区",
          code: "410173",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "巩义市", code: "410181", level: 3, firstChar: null, children: null },
        { name: "荥阳市", code: "410182", level: 3, firstChar: null, children: null },
        { name: "新密市", code: "410183", level: 3, firstChar: null, children: null },
        { name: "新郑市", code: "410184", level: 3, firstChar: null, children: null },
        { name: "登封市", code: "410185", level: 3, firstChar: null, children: null },
      ],
      开封市: [
        { name: "龙亭区", code: "410202", level: 3, firstChar: null, children: null },
        { name: "顺河回族区", code: "410203", level: 3, firstChar: null, children: null },
        { name: "鼓楼区", code: "410204", level: 3, firstChar: null, children: null },
        { name: "禹王台区", code: "410205", level: 3, firstChar: null, children: null },
        { name: "祥符区", code: "410212", level: 3, firstChar: null, children: null },
        { name: "杞县", code: "410221", level: 3, firstChar: null, children: null },
        { name: "通许县", code: "410222", level: 3, firstChar: null, children: null },
        { name: "尉氏县", code: "410223", level: 3, firstChar: null, children: null },
        { name: "兰考县", code: "410225", level: 3, firstChar: null, children: null },
      ],
      洛阳市: [
        { name: "老城区", code: "410302", level: 3, firstChar: null, children: null },
        { name: "西工区", code: "410303", level: 3, firstChar: null, children: null },
        { name: "瀍河回族区", code: "410304", level: 3, firstChar: null, children: null },
        { name: "涧西区", code: "410305", level: 3, firstChar: null, children: null },
        { name: "吉利区", code: "410306", level: 3, firstChar: null, children: null },
        { name: "洛龙区", code: "410311", level: 3, firstChar: null, children: null },
        { name: "孟津县", code: "410322", level: 3, firstChar: null, children: null },
        { name: "新安县", code: "410323", level: 3, firstChar: null, children: null },
        { name: "栾川县", code: "410324", level: 3, firstChar: null, children: null },
        { name: "嵩县", code: "410325", level: 3, firstChar: null, children: null },
        { name: "汝阳县", code: "410326", level: 3, firstChar: null, children: null },
        { name: "宜阳县", code: "410327", level: 3, firstChar: null, children: null },
        { name: "洛宁县", code: "410328", level: 3, firstChar: null, children: null },
        { name: "伊川县", code: "410329", level: 3, firstChar: null, children: null },
        {
          name: "洛阳高新技术产业开发区",
          code: "410371",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "偃师市", code: "410381", level: 3, firstChar: null, children: null },
      ],
      平顶山市: [
        { name: "新华区", code: "410402", level: 3, firstChar: null, children: null },
        { name: "卫东区", code: "410403", level: 3, firstChar: null, children: null },
        { name: "石龙区", code: "410404", level: 3, firstChar: null, children: null },
        { name: "湛河区", code: "410411", level: 3, firstChar: null, children: null },
        { name: "宝丰县", code: "410421", level: 3, firstChar: null, children: null },
        { name: "叶县", code: "410422", level: 3, firstChar: null, children: null },
        { name: "鲁山县", code: "410423", level: 3, firstChar: null, children: null },
        { name: "郏县", code: "410425", level: 3, firstChar: null, children: null },
        {
          name: "平顶山高新技术产业开发区",
          code: "410471",
          level: 3,
          firstChar: null,
          children: null,
        },
        {
          name: "平顶山市城乡一体化示范区",
          code: "410472",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "舞钢市", code: "410481", level: 3, firstChar: null, children: null },
        { name: "汝州市", code: "410482", level: 3, firstChar: null, children: null },
      ],
      安阳市: [
        { name: "文峰区", code: "410502", level: 3, firstChar: null, children: null },
        { name: "北关区", code: "410503", level: 3, firstChar: null, children: null },
        { name: "殷都区", code: "410505", level: 3, firstChar: null, children: null },
        { name: "龙安区", code: "410506", level: 3, firstChar: null, children: null },
        { name: "安阳县", code: "410522", level: 3, firstChar: null, children: null },
        { name: "汤阴县", code: "410523", level: 3, firstChar: null, children: null },
        { name: "滑县", code: "410526", level: 3, firstChar: null, children: null },
        { name: "内黄县", code: "410527", level: 3, firstChar: null, children: null },
        {
          name: "安阳高新技术产业开发区",
          code: "410571",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "林州市", code: "410581", level: 3, firstChar: null, children: null },
      ],
      鹤壁市: [
        { name: "鹤山区", code: "410602", level: 3, firstChar: null, children: null },
        { name: "山城区", code: "410603", level: 3, firstChar: null, children: null },
        { name: "淇滨区", code: "410611", level: 3, firstChar: null, children: null },
        { name: "浚县", code: "410621", level: 3, firstChar: null, children: null },
        { name: "淇县", code: "410622", level: 3, firstChar: null, children: null },
        { name: "鹤壁经济技术开发区", code: "410671", level: 3, firstChar: null, children: null },
      ],
      新乡市: [
        { name: "红旗区", code: "410702", level: 3, firstChar: null, children: null },
        { name: "卫滨区", code: "410703", level: 3, firstChar: null, children: null },
        { name: "凤泉区", code: "410704", level: 3, firstChar: null, children: null },
        { name: "牧野区", code: "410711", level: 3, firstChar: null, children: null },
        { name: "新乡县", code: "410721", level: 3, firstChar: null, children: null },
        { name: "获嘉县", code: "410724", level: 3, firstChar: null, children: null },
        { name: "原阳县", code: "410725", level: 3, firstChar: null, children: null },
        { name: "延津县", code: "410726", level: 3, firstChar: null, children: null },
        { name: "封丘县", code: "410727", level: 3, firstChar: null, children: null },
        {
          name: "新乡高新技术产业开发区",
          code: "410771",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "新乡经济技术开发区", code: "410772", level: 3, firstChar: null, children: null },
        {
          name: "新乡市平原城乡一体化示范区",
          code: "410773",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "卫辉市", code: "410781", level: 3, firstChar: null, children: null },
        { name: "辉县市", code: "410782", level: 3, firstChar: null, children: null },
        { name: "长垣市", code: "410783", level: 3, firstChar: null, children: null },
      ],
      焦作市: [
        { name: "解放区", code: "410802", level: 3, firstChar: null, children: null },
        { name: "中站区", code: "410803", level: 3, firstChar: null, children: null },
        { name: "马村区", code: "410804", level: 3, firstChar: null, children: null },
        { name: "山阳区", code: "410811", level: 3, firstChar: null, children: null },
        { name: "修武县", code: "410821", level: 3, firstChar: null, children: null },
        { name: "博爱县", code: "410822", level: 3, firstChar: null, children: null },
        { name: "武陟县", code: "410823", level: 3, firstChar: null, children: null },
        { name: "温县", code: "410825", level: 3, firstChar: null, children: null },
        { name: "焦作城乡一体化示范区", code: "410871", level: 3, firstChar: null, children: null },
        { name: "沁阳市", code: "410882", level: 3, firstChar: null, children: null },
        { name: "孟州市", code: "410883", level: 3, firstChar: null, children: null },
      ],
      濮阳市: [
        { name: "华龙区", code: "410902", level: 3, firstChar: null, children: null },
        { name: "清丰县", code: "410922", level: 3, firstChar: null, children: null },
        { name: "南乐县", code: "410923", level: 3, firstChar: null, children: null },
        { name: "范县", code: "410926", level: 3, firstChar: null, children: null },
        { name: "台前县", code: "410927", level: 3, firstChar: null, children: null },
        { name: "濮阳县", code: "410928", level: 3, firstChar: null, children: null },
        { name: "河南濮阳工业园区", code: "410971", level: 3, firstChar: null, children: null },
        { name: "濮阳经济技术开发区", code: "410972", level: 3, firstChar: null, children: null },
      ],
      许昌市: [
        { name: "魏都区", code: "411002", level: 3, firstChar: null, children: null },
        { name: "建安区", code: "411003", level: 3, firstChar: null, children: null },
        { name: "鄢陵县", code: "411024", level: 3, firstChar: null, children: null },
        { name: "襄城县", code: "411025", level: 3, firstChar: null, children: null },
        { name: "许昌经济技术开发区", code: "411071", level: 3, firstChar: null, children: null },
        { name: "禹州市", code: "411081", level: 3, firstChar: null, children: null },
        { name: "长葛市", code: "411082", level: 3, firstChar: null, children: null },
      ],
      漯河市: [
        { name: "源汇区", code: "411102", level: 3, firstChar: null, children: null },
        { name: "郾城区", code: "411103", level: 3, firstChar: null, children: null },
        { name: "召陵区", code: "411104", level: 3, firstChar: null, children: null },
        { name: "舞阳县", code: "411121", level: 3, firstChar: null, children: null },
        { name: "临颍县", code: "411122", level: 3, firstChar: null, children: null },
        { name: "漯河经济技术开发区", code: "411171", level: 3, firstChar: null, children: null },
      ],
      三门峡市: [
        { name: "湖滨区", code: "411202", level: 3, firstChar: null, children: null },
        { name: "陕州区", code: "411203", level: 3, firstChar: null, children: null },
        { name: "渑池县", code: "411221", level: 3, firstChar: null, children: null },
        { name: "卢氏县", code: "411224", level: 3, firstChar: null, children: null },
        { name: "河南三门峡经济开发区", code: "411271", level: 3, firstChar: null, children: null },
        { name: "义马市", code: "411281", level: 3, firstChar: null, children: null },
        { name: "灵宝市", code: "411282", level: 3, firstChar: null, children: null },
      ],
      南阳市: [
        { name: "宛城区", code: "411302", level: 3, firstChar: null, children: null },
        { name: "卧龙区", code: "411303", level: 3, firstChar: null, children: null },
        { name: "南召县", code: "411321", level: 3, firstChar: null, children: null },
        { name: "方城县", code: "411322", level: 3, firstChar: null, children: null },
        { name: "西峡县", code: "411323", level: 3, firstChar: null, children: null },
        { name: "镇平县", code: "411324", level: 3, firstChar: null, children: null },
        { name: "内乡县", code: "411325", level: 3, firstChar: null, children: null },
        { name: "淅川县", code: "411326", level: 3, firstChar: null, children: null },
        { name: "社旗县", code: "411327", level: 3, firstChar: null, children: null },
        { name: "唐河县", code: "411328", level: 3, firstChar: null, children: null },
        { name: "新野县", code: "411329", level: 3, firstChar: null, children: null },
        { name: "桐柏县", code: "411330", level: 3, firstChar: null, children: null },
        {
          name: "南阳高新技术产业开发区",
          code: "411371",
          level: 3,
          firstChar: null,
          children: null,
        },
        {
          name: "南阳市城乡一体化示范区",
          code: "411372",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "邓州市", code: "411381", level: 3, firstChar: null, children: null },
      ],
      商丘市: [
        { name: "梁园区", code: "411402", level: 3, firstChar: null, children: null },
        { name: "睢阳区", code: "411403", level: 3, firstChar: null, children: null },
        { name: "民权县", code: "411421", level: 3, firstChar: null, children: null },
        { name: "睢县", code: "411422", level: 3, firstChar: null, children: null },
        { name: "宁陵县", code: "411423", level: 3, firstChar: null, children: null },
        { name: "柘城县", code: "411424", level: 3, firstChar: null, children: null },
        { name: "虞城县", code: "411425", level: 3, firstChar: null, children: null },
        { name: "夏邑县", code: "411426", level: 3, firstChar: null, children: null },
        {
          name: "豫东综合物流产业聚集区",
          code: "411471",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "河南商丘经济开发区", code: "411472", level: 3, firstChar: null, children: null },
        { name: "永城市", code: "411481", level: 3, firstChar: null, children: null },
      ],
      信阳市: [
        { name: "浉河区", code: "411502", level: 3, firstChar: null, children: null },
        { name: "平桥区", code: "411503", level: 3, firstChar: null, children: null },
        { name: "罗山县", code: "411521", level: 3, firstChar: null, children: null },
        { name: "光山县", code: "411522", level: 3, firstChar: null, children: null },
        { name: "新县", code: "411523", level: 3, firstChar: null, children: null },
        { name: "商城县", code: "411524", level: 3, firstChar: null, children: null },
        { name: "固始县", code: "411525", level: 3, firstChar: null, children: null },
        { name: "潢川县", code: "411526", level: 3, firstChar: null, children: null },
        { name: "淮滨县", code: "411527", level: 3, firstChar: null, children: null },
        { name: "息县", code: "411528", level: 3, firstChar: null, children: null },
        {
          name: "信阳高新技术产业开发区",
          code: "411571",
          level: 3,
          firstChar: null,
          children: null,
        },
      ],
      周口市: [
        { name: "川汇区", code: "411602", level: 3, firstChar: null, children: null },
        { name: "淮阳区", code: "411603", level: 3, firstChar: null, children: null },
        { name: "扶沟县", code: "411621", level: 3, firstChar: null, children: null },
        { name: "西华县", code: "411622", level: 3, firstChar: null, children: null },
        { name: "商水县", code: "411623", level: 3, firstChar: null, children: null },
        { name: "沈丘县", code: "411624", level: 3, firstChar: null, children: null },
        { name: "郸城县", code: "411625", level: 3, firstChar: null, children: null },
        { name: "太康县", code: "411627", level: 3, firstChar: null, children: null },
        { name: "鹿邑县", code: "411628", level: 3, firstChar: null, children: null },
        { name: "河南周口经济开发区", code: "411671", level: 3, firstChar: null, children: null },
        { name: "项城市", code: "411681", level: 3, firstChar: null, children: null },
      ],
      驻马店市: [
        { name: "驿城区", code: "411702", level: 3, firstChar: null, children: null },
        { name: "西平县", code: "411721", level: 3, firstChar: null, children: null },
        { name: "上蔡县", code: "411722", level: 3, firstChar: null, children: null },
        { name: "平舆县", code: "411723", level: 3, firstChar: null, children: null },
        { name: "正阳县", code: "411724", level: 3, firstChar: null, children: null },
        { name: "确山县", code: "411725", level: 3, firstChar: null, children: null },
        { name: "泌阳县", code: "411726", level: 3, firstChar: null, children: null },
        { name: "汝南县", code: "411727", level: 3, firstChar: null, children: null },
        { name: "遂平县", code: "411728", level: 3, firstChar: null, children: null },
        { name: "新蔡县", code: "411729", level: 3, firstChar: null, children: null },
        { name: "河南驻马店经济开发区", code: "411771", level: 3, firstChar: null, children: null },
      ],
      济源市: [],
    },
    湖北: {
      武汉市: [
        { name: "江岸区", code: "420102", level: 3, firstChar: null, children: null },
        { name: "江汉区", code: "420103", level: 3, firstChar: null, children: null },
        { name: "硚口区", code: "420104", level: 3, firstChar: null, children: null },
        { name: "汉阳区", code: "420105", level: 3, firstChar: null, children: null },
        { name: "武昌区", code: "420106", level: 3, firstChar: null, children: null },
        { name: "青山区", code: "420107", level: 3, firstChar: null, children: null },
        { name: "洪山区", code: "420111", level: 3, firstChar: null, children: null },
        { name: "东西湖区", code: "420112", level: 3, firstChar: null, children: null },
        { name: "汉南区", code: "420113", level: 3, firstChar: null, children: null },
        { name: "蔡甸区", code: "420114", level: 3, firstChar: null, children: null },
        { name: "江夏区", code: "420115", level: 3, firstChar: null, children: null },
        { name: "黄陂区", code: "420116", level: 3, firstChar: null, children: null },
        { name: "新洲区", code: "420117", level: 3, firstChar: null, children: null },
      ],
      黄石市: [
        { name: "黄石港区", code: "420202", level: 3, firstChar: null, children: null },
        { name: "西塞山区", code: "420203", level: 3, firstChar: null, children: null },
        { name: "下陆区", code: "420204", level: 3, firstChar: null, children: null },
        { name: "铁山区", code: "420205", level: 3, firstChar: null, children: null },
        { name: "阳新县", code: "420222", level: 3, firstChar: null, children: null },
        { name: "大冶市", code: "420281", level: 3, firstChar: null, children: null },
      ],
      十堰市: [
        { name: "茅箭区", code: "420302", level: 3, firstChar: null, children: null },
        { name: "张湾区", code: "420303", level: 3, firstChar: null, children: null },
        { name: "郧阳区", code: "420304", level: 3, firstChar: null, children: null },
        { name: "郧西县", code: "420322", level: 3, firstChar: null, children: null },
        { name: "竹山县", code: "420323", level: 3, firstChar: null, children: null },
        { name: "竹溪县", code: "420324", level: 3, firstChar: null, children: null },
        { name: "房县", code: "420325", level: 3, firstChar: null, children: null },
        { name: "丹江口市", code: "420381", level: 3, firstChar: null, children: null },
      ],
      宜昌市: [
        { name: "西陵区", code: "420502", level: 3, firstChar: null, children: null },
        { name: "伍家岗区", code: "420503", level: 3, firstChar: null, children: null },
        { name: "点军区", code: "420504", level: 3, firstChar: null, children: null },
        { name: "猇亭区", code: "420505", level: 3, firstChar: null, children: null },
        { name: "夷陵区", code: "420506", level: 3, firstChar: null, children: null },
        { name: "远安县", code: "420525", level: 3, firstChar: null, children: null },
        { name: "兴山县", code: "420526", level: 3, firstChar: null, children: null },
        { name: "秭归县", code: "420527", level: 3, firstChar: null, children: null },
        { name: "长阳土家族自治县", code: "420528", level: 3, firstChar: null, children: null },
        { name: "五峰土家族自治县", code: "420529", level: 3, firstChar: null, children: null },
        { name: "宜都市", code: "420581", level: 3, firstChar: null, children: null },
        { name: "当阳市", code: "420582", level: 3, firstChar: null, children: null },
        { name: "枝江市", code: "420583", level: 3, firstChar: null, children: null },
      ],
      襄阳市: [
        { name: "襄城区", code: "420602", level: 3, firstChar: null, children: null },
        { name: "樊城区", code: "420606", level: 3, firstChar: null, children: null },
        { name: "襄州区", code: "420607", level: 3, firstChar: null, children: null },
        { name: "南漳县", code: "420624", level: 3, firstChar: null, children: null },
        { name: "谷城县", code: "420625", level: 3, firstChar: null, children: null },
        { name: "保康县", code: "420626", level: 3, firstChar: null, children: null },
        { name: "老河口市", code: "420682", level: 3, firstChar: null, children: null },
        { name: "枣阳市", code: "420683", level: 3, firstChar: null, children: null },
        { name: "宜城市", code: "420684", level: 3, firstChar: null, children: null },
      ],
      鄂州市: [
        { name: "梁子湖区", code: "420702", level: 3, firstChar: null, children: null },
        { name: "华容区", code: "420703", level: 3, firstChar: null, children: null },
        { name: "鄂城区", code: "420704", level: 3, firstChar: null, children: null },
      ],
      荆门市: [
        { name: "东宝区", code: "420802", level: 3, firstChar: null, children: null },
        { name: "掇刀区", code: "420804", level: 3, firstChar: null, children: null },
        { name: "沙洋县", code: "420822", level: 3, firstChar: null, children: null },
        { name: "钟祥市", code: "420881", level: 3, firstChar: null, children: null },
        { name: "京山市", code: "420882", level: 3, firstChar: null, children: null },
      ],
      孝感市: [
        { name: "孝南区", code: "420902", level: 3, firstChar: null, children: null },
        { name: "孝昌县", code: "420921", level: 3, firstChar: null, children: null },
        { name: "大悟县", code: "420922", level: 3, firstChar: null, children: null },
        { name: "云梦县", code: "420923", level: 3, firstChar: null, children: null },
        { name: "应城市", code: "420981", level: 3, firstChar: null, children: null },
        { name: "安陆市", code: "420982", level: 3, firstChar: null, children: null },
        { name: "汉川市", code: "420984", level: 3, firstChar: null, children: null },
      ],
      荆州市: [
        { name: "沙市区", code: "421002", level: 3, firstChar: null, children: null },
        { name: "荆州区", code: "421003", level: 3, firstChar: null, children: null },
        { name: "公安县", code: "421022", level: 3, firstChar: null, children: null },
        { name: "监利县", code: "421023", level: 3, firstChar: null, children: null },
        { name: "江陵县", code: "421024", level: 3, firstChar: null, children: null },
        { name: "荆州经济技术开发区", code: "421071", level: 3, firstChar: null, children: null },
        { name: "石首市", code: "421081", level: 3, firstChar: null, children: null },
        { name: "洪湖市", code: "421083", level: 3, firstChar: null, children: null },
        { name: "松滋市", code: "421087", level: 3, firstChar: null, children: null },
      ],
      黄冈市: [
        { name: "黄州区", code: "421102", level: 3, firstChar: null, children: null },
        { name: "团风县", code: "421121", level: 3, firstChar: null, children: null },
        { name: "红安县", code: "421122", level: 3, firstChar: null, children: null },
        { name: "罗田县", code: "421123", level: 3, firstChar: null, children: null },
        { name: "英山县", code: "421124", level: 3, firstChar: null, children: null },
        { name: "浠水县", code: "421125", level: 3, firstChar: null, children: null },
        { name: "蕲春县", code: "421126", level: 3, firstChar: null, children: null },
        { name: "黄梅县", code: "421127", level: 3, firstChar: null, children: null },
        { name: "龙感湖管理区", code: "421171", level: 3, firstChar: null, children: null },
        { name: "麻城市", code: "421181", level: 3, firstChar: null, children: null },
        { name: "武穴市", code: "421182", level: 3, firstChar: null, children: null },
      ],
      咸宁市: [
        { name: "咸安区", code: "421202", level: 3, firstChar: null, children: null },
        { name: "嘉鱼县", code: "421221", level: 3, firstChar: null, children: null },
        { name: "通城县", code: "421222", level: 3, firstChar: null, children: null },
        { name: "崇阳县", code: "421223", level: 3, firstChar: null, children: null },
        { name: "通山县", code: "421224", level: 3, firstChar: null, children: null },
        { name: "赤壁市", code: "421281", level: 3, firstChar: null, children: null },
      ],
      随州市: [
        { name: "曾都区", code: "421303", level: 3, firstChar: null, children: null },
        { name: "随县", code: "421321", level: 3, firstChar: null, children: null },
        { name: "广水市", code: "421381", level: 3, firstChar: null, children: null },
      ],
      恩施土家族苗族自治州: [
        { name: "恩施市", code: "422801", level: 3, firstChar: null, children: null },
        { name: "利川市", code: "422802", level: 3, firstChar: null, children: null },
        { name: "建始县", code: "422822", level: 3, firstChar: null, children: null },
        { name: "巴东县", code: "422823", level: 3, firstChar: null, children: null },
        { name: "宣恩县", code: "422825", level: 3, firstChar: null, children: null },
        { name: "咸丰县", code: "422826", level: 3, firstChar: null, children: null },
        { name: "来凤县", code: "422827", level: 3, firstChar: null, children: null },
        { name: "鹤峰县", code: "422828", level: 3, firstChar: null, children: null },
      ],
      仙桃市: [],
      潜江市: [],
      天门市: [],
      神农架林区: [],
    },
    湖南: {
      长沙市: [
        { name: "芙蓉区", code: "430102", level: 3, firstChar: null, children: null },
        { name: "天心区", code: "430103", level: 3, firstChar: null, children: null },
        { name: "岳麓区", code: "430104", level: 3, firstChar: null, children: null },
        { name: "开福区", code: "430105", level: 3, firstChar: null, children: null },
        { name: "雨花区", code: "430111", level: 3, firstChar: null, children: null },
        { name: "望城区", code: "430112", level: 3, firstChar: null, children: null },
        { name: "长沙县", code: "430121", level: 3, firstChar: null, children: null },
        { name: "浏阳市", code: "430181", level: 3, firstChar: null, children: null },
        { name: "宁乡市", code: "430182", level: 3, firstChar: null, children: null },
      ],
      株洲市: [
        { name: "荷塘区", code: "430202", level: 3, firstChar: null, children: null },
        { name: "芦淞区", code: "430203", level: 3, firstChar: null, children: null },
        { name: "石峰区", code: "430204", level: 3, firstChar: null, children: null },
        { name: "天元区", code: "430211", level: 3, firstChar: null, children: null },
        { name: "渌口区", code: "430212", level: 3, firstChar: null, children: null },
        { name: "攸县", code: "430223", level: 3, firstChar: null, children: null },
        { name: "茶陵县", code: "430224", level: 3, firstChar: null, children: null },
        { name: "炎陵县", code: "430225", level: 3, firstChar: null, children: null },
        { name: "云龙示范区", code: "430271", level: 3, firstChar: null, children: null },
        { name: "醴陵市", code: "430281", level: 3, firstChar: null, children: null },
      ],
      湘潭市: [
        { name: "雨湖区", code: "430302", level: 3, firstChar: null, children: null },
        { name: "岳塘区", code: "430304", level: 3, firstChar: null, children: null },
        { name: "湘潭县", code: "430321", level: 3, firstChar: null, children: null },
        {
          name: "湖南湘潭高新技术产业园区",
          code: "430371",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "湘潭昭山示范区", code: "430372", level: 3, firstChar: null, children: null },
        { name: "湘潭九华示范区", code: "430373", level: 3, firstChar: null, children: null },
        { name: "湘乡市", code: "430381", level: 3, firstChar: null, children: null },
        { name: "韶山市", code: "430382", level: 3, firstChar: null, children: null },
      ],
      衡阳市: [
        { name: "珠晖区", code: "430405", level: 3, firstChar: null, children: null },
        { name: "雁峰区", code: "430406", level: 3, firstChar: null, children: null },
        { name: "石鼓区", code: "430407", level: 3, firstChar: null, children: null },
        { name: "蒸湘区", code: "430408", level: 3, firstChar: null, children: null },
        { name: "南岳区", code: "430412", level: 3, firstChar: null, children: null },
        { name: "衡阳县", code: "430421", level: 3, firstChar: null, children: null },
        { name: "衡南县", code: "430422", level: 3, firstChar: null, children: null },
        { name: "衡山县", code: "430423", level: 3, firstChar: null, children: null },
        { name: "衡东县", code: "430424", level: 3, firstChar: null, children: null },
        { name: "祁东县", code: "430426", level: 3, firstChar: null, children: null },
        { name: "衡阳综合保税区", code: "430471", level: 3, firstChar: null, children: null },
        {
          name: "湖南衡阳高新技术产业园区",
          code: "430472",
          level: 3,
          firstChar: null,
          children: null,
        },
        {
          name: "湖南衡阳松木经济开发区",
          code: "430473",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "耒阳市", code: "430481", level: 3, firstChar: null, children: null },
        { name: "常宁市", code: "430482", level: 3, firstChar: null, children: null },
      ],
      邵阳市: [
        { name: "双清区", code: "430502", level: 3, firstChar: null, children: null },
        { name: "大祥区", code: "430503", level: 3, firstChar: null, children: null },
        { name: "北塔区", code: "430511", level: 3, firstChar: null, children: null },
        { name: "新邵县", code: "430522", level: 3, firstChar: null, children: null },
        { name: "邵阳县", code: "430523", level: 3, firstChar: null, children: null },
        { name: "隆回县", code: "430524", level: 3, firstChar: null, children: null },
        { name: "洞口县", code: "430525", level: 3, firstChar: null, children: null },
        { name: "绥宁县", code: "430527", level: 3, firstChar: null, children: null },
        { name: "新宁县", code: "430528", level: 3, firstChar: null, children: null },
        { name: "城步苗族自治县", code: "430529", level: 3, firstChar: null, children: null },
        { name: "武冈市", code: "430581", level: 3, firstChar: null, children: null },
        { name: "邵东市", code: "430582", level: 3, firstChar: null, children: null },
      ],
      岳阳市: [
        { name: "岳阳楼区", code: "430602", level: 3, firstChar: null, children: null },
        { name: "云溪区", code: "430603", level: 3, firstChar: null, children: null },
        { name: "君山区", code: "430611", level: 3, firstChar: null, children: null },
        { name: "岳阳县", code: "430621", level: 3, firstChar: null, children: null },
        { name: "华容县", code: "430623", level: 3, firstChar: null, children: null },
        { name: "湘阴县", code: "430624", level: 3, firstChar: null, children: null },
        { name: "平江县", code: "430626", level: 3, firstChar: null, children: null },
        { name: "岳阳市屈原管理区", code: "430671", level: 3, firstChar: null, children: null },
        { name: "汨罗市", code: "430681", level: 3, firstChar: null, children: null },
        { name: "临湘市", code: "430682", level: 3, firstChar: null, children: null },
      ],
      常德市: [
        { name: "武陵区", code: "430702", level: 3, firstChar: null, children: null },
        { name: "鼎城区", code: "430703", level: 3, firstChar: null, children: null },
        { name: "安乡县", code: "430721", level: 3, firstChar: null, children: null },
        { name: "汉寿县", code: "430722", level: 3, firstChar: null, children: null },
        { name: "澧县", code: "430723", level: 3, firstChar: null, children: null },
        { name: "临澧县", code: "430724", level: 3, firstChar: null, children: null },
        { name: "桃源县", code: "430725", level: 3, firstChar: null, children: null },
        { name: "石门县", code: "430726", level: 3, firstChar: null, children: null },
        { name: "常德市西洞庭管理区", code: "430771", level: 3, firstChar: null, children: null },
        { name: "津市市", code: "430781", level: 3, firstChar: null, children: null },
      ],
      张家界市: [
        { name: "永定区", code: "430802", level: 3, firstChar: null, children: null },
        { name: "武陵源区", code: "430811", level: 3, firstChar: null, children: null },
        { name: "慈利县", code: "430821", level: 3, firstChar: null, children: null },
        { name: "桑植县", code: "430822", level: 3, firstChar: null, children: null },
      ],
      益阳市: [
        { name: "资阳区", code: "430902", level: 3, firstChar: null, children: null },
        { name: "赫山区", code: "430903", level: 3, firstChar: null, children: null },
        { name: "南县", code: "430921", level: 3, firstChar: null, children: null },
        { name: "桃江县", code: "430922", level: 3, firstChar: null, children: null },
        { name: "安化县", code: "430923", level: 3, firstChar: null, children: null },
        { name: "益阳市大通湖管理区", code: "430971", level: 3, firstChar: null, children: null },
        {
          name: "湖南益阳高新技术产业园区",
          code: "430972",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "沅江市", code: "430981", level: 3, firstChar: null, children: null },
      ],
      郴州市: [
        { name: "北湖区", code: "431002", level: 3, firstChar: null, children: null },
        { name: "苏仙区", code: "431003", level: 3, firstChar: null, children: null },
        { name: "桂阳县", code: "431021", level: 3, firstChar: null, children: null },
        { name: "宜章县", code: "431022", level: 3, firstChar: null, children: null },
        { name: "永兴县", code: "431023", level: 3, firstChar: null, children: null },
        { name: "嘉禾县", code: "431024", level: 3, firstChar: null, children: null },
        { name: "临武县", code: "431025", level: 3, firstChar: null, children: null },
        { name: "汝城县", code: "431026", level: 3, firstChar: null, children: null },
        { name: "桂东县", code: "431027", level: 3, firstChar: null, children: null },
        { name: "安仁县", code: "431028", level: 3, firstChar: null, children: null },
        { name: "资兴市", code: "431081", level: 3, firstChar: null, children: null },
      ],
      永州市: [
        { name: "零陵区", code: "431102", level: 3, firstChar: null, children: null },
        { name: "冷水滩区", code: "431103", level: 3, firstChar: null, children: null },
        { name: "祁阳县", code: "431121", level: 3, firstChar: null, children: null },
        { name: "东安县", code: "431122", level: 3, firstChar: null, children: null },
        { name: "双牌县", code: "431123", level: 3, firstChar: null, children: null },
        { name: "道县", code: "431124", level: 3, firstChar: null, children: null },
        { name: "江永县", code: "431125", level: 3, firstChar: null, children: null },
        { name: "宁远县", code: "431126", level: 3, firstChar: null, children: null },
        { name: "蓝山县", code: "431127", level: 3, firstChar: null, children: null },
        { name: "新田县", code: "431128", level: 3, firstChar: null, children: null },
        { name: "江华瑶族自治县", code: "431129", level: 3, firstChar: null, children: null },
        { name: "永州经济技术开发区", code: "431171", level: 3, firstChar: null, children: null },
        { name: "永州市金洞管理区", code: "431172", level: 3, firstChar: null, children: null },
        { name: "永州市回龙圩管理区", code: "431173", level: 3, firstChar: null, children: null },
      ],
      怀化市: [
        { name: "鹤城区", code: "431202", level: 3, firstChar: null, children: null },
        { name: "中方县", code: "431221", level: 3, firstChar: null, children: null },
        { name: "沅陵县", code: "431222", level: 3, firstChar: null, children: null },
        { name: "辰溪县", code: "431223", level: 3, firstChar: null, children: null },
        { name: "溆浦县", code: "431224", level: 3, firstChar: null, children: null },
        { name: "会同县", code: "431225", level: 3, firstChar: null, children: null },
        { name: "麻阳苗族自治县", code: "431226", level: 3, firstChar: null, children: null },
        { name: "新晃侗族自治县", code: "431227", level: 3, firstChar: null, children: null },
        { name: "芷江侗族自治县", code: "431228", level: 3, firstChar: null, children: null },
        { name: "靖州苗族侗族自治县", code: "431229", level: 3, firstChar: null, children: null },
        { name: "通道侗族自治县", code: "431230", level: 3, firstChar: null, children: null },
        { name: "怀化市洪江管理区", code: "431271", level: 3, firstChar: null, children: null },
        { name: "洪江市", code: "431281", level: 3, firstChar: null, children: null },
      ],
      娄底市: [
        { name: "娄星区", code: "431302", level: 3, firstChar: null, children: null },
        { name: "双峰县", code: "431321", level: 3, firstChar: null, children: null },
        { name: "新化县", code: "431322", level: 3, firstChar: null, children: null },
        { name: "冷水江市", code: "431381", level: 3, firstChar: null, children: null },
        { name: "涟源市", code: "431382", level: 3, firstChar: null, children: null },
      ],
      湘西土家族苗族自治州: [
        { name: "吉首市", code: "433101", level: 3, firstChar: null, children: null },
        { name: "泸溪县", code: "433122", level: 3, firstChar: null, children: null },
        { name: "凤凰县", code: "433123", level: 3, firstChar: null, children: null },
        { name: "花垣县", code: "433124", level: 3, firstChar: null, children: null },
        { name: "保靖县", code: "433125", level: 3, firstChar: null, children: null },
        { name: "古丈县", code: "433126", level: 3, firstChar: null, children: null },
        { name: "永顺县", code: "433127", level: 3, firstChar: null, children: null },
        { name: "龙山县", code: "433130", level: 3, firstChar: null, children: null },
      ],
    },
    广东: {
      广州市: [
        { name: "荔湾区", code: "440103", level: 3, firstChar: null, children: null },
        { name: "越秀区", code: "440104", level: 3, firstChar: null, children: null },
        { name: "海珠区", code: "440105", level: 3, firstChar: null, children: null },
        { name: "天河区", code: "440106", level: 3, firstChar: null, children: null },
        { name: "白云区", code: "440111", level: 3, firstChar: null, children: null },
        { name: "黄埔区", code: "440112", level: 3, firstChar: null, children: null },
        { name: "番禺区", code: "440113", level: 3, firstChar: null, children: null },
        { name: "花都区", code: "440114", level: 3, firstChar: null, children: null },
        { name: "南沙区", code: "440115", level: 3, firstChar: null, children: null },
        { name: "从化区", code: "440117", level: 3, firstChar: null, children: null },
        { name: "增城区", code: "440118", level: 3, firstChar: null, children: null },
      ],
      韶关市: [
        { name: "武江区", code: "440203", level: 3, firstChar: null, children: null },
        { name: "浈江区", code: "440204", level: 3, firstChar: null, children: null },
        { name: "曲江区", code: "440205", level: 3, firstChar: null, children: null },
        { name: "始兴县", code: "440222", level: 3, firstChar: null, children: null },
        { name: "仁化县", code: "440224", level: 3, firstChar: null, children: null },
        { name: "翁源县", code: "440229", level: 3, firstChar: null, children: null },
        { name: "乳源瑶族自治县", code: "440232", level: 3, firstChar: null, children: null },
        { name: "新丰县", code: "440233", level: 3, firstChar: null, children: null },
        { name: "乐昌市", code: "440281", level: 3, firstChar: null, children: null },
        { name: "南雄市", code: "440282", level: 3, firstChar: null, children: null },
      ],
      深圳市: [
        { name: "罗湖区", code: "440303", level: 3, firstChar: null, children: null },
        { name: "福田区", code: "440304", level: 3, firstChar: null, children: null },
        { name: "南山区", code: "440305", level: 3, firstChar: null, children: null },
        { name: "宝安区", code: "440306", level: 3, firstChar: null, children: null },
        { name: "龙岗区", code: "440307", level: 3, firstChar: null, children: null },
        { name: "盐田区", code: "440308", level: 3, firstChar: null, children: null },
        { name: "龙华区", code: "440309", level: 3, firstChar: null, children: null },
        { name: "坪山区", code: "440310", level: 3, firstChar: null, children: null },
        { name: "光明区", code: "440311", level: 3, firstChar: null, children: null },
      ],
      珠海市: [
        { name: "香洲区", code: "440402", level: 3, firstChar: null, children: null },
        { name: "斗门区", code: "440403", level: 3, firstChar: null, children: null },
        { name: "金湾区", code: "440404", level: 3, firstChar: null, children: null },
      ],
      汕头市: [
        { name: "龙湖区", code: "440507", level: 3, firstChar: null, children: null },
        { name: "金平区", code: "440511", level: 3, firstChar: null, children: null },
        { name: "濠江区", code: "440512", level: 3, firstChar: null, children: null },
        { name: "潮阳区", code: "440513", level: 3, firstChar: null, children: null },
        { name: "潮南区", code: "440514", level: 3, firstChar: null, children: null },
        { name: "澄海区", code: "440515", level: 3, firstChar: null, children: null },
        { name: "南澳县", code: "440523", level: 3, firstChar: null, children: null },
      ],
      佛山市: [
        { name: "禅城区", code: "440604", level: 3, firstChar: null, children: null },
        { name: "南海区", code: "440605", level: 3, firstChar: null, children: null },
        { name: "顺德区", code: "440606", level: 3, firstChar: null, children: null },
        { name: "三水区", code: "440607", level: 3, firstChar: null, children: null },
        { name: "高明区", code: "440608", level: 3, firstChar: null, children: null },
      ],
      江门市: [
        { name: "蓬江区", code: "440703", level: 3, firstChar: null, children: null },
        { name: "江海区", code: "440704", level: 3, firstChar: null, children: null },
        { name: "新会区", code: "440705", level: 3, firstChar: null, children: null },
        { name: "台山市", code: "440781", level: 3, firstChar: null, children: null },
        { name: "开平市", code: "440783", level: 3, firstChar: null, children: null },
        { name: "鹤山市", code: "440784", level: 3, firstChar: null, children: null },
        { name: "恩平市", code: "440785", level: 3, firstChar: null, children: null },
      ],
      湛江市: [
        { name: "赤坎区", code: "440802", level: 3, firstChar: null, children: null },
        { name: "霞山区", code: "440803", level: 3, firstChar: null, children: null },
        { name: "坡头区", code: "440804", level: 3, firstChar: null, children: null },
        { name: "麻章区", code: "440811", level: 3, firstChar: null, children: null },
        { name: "遂溪县", code: "440823", level: 3, firstChar: null, children: null },
        { name: "徐闻县", code: "440825", level: 3, firstChar: null, children: null },
        { name: "廉江市", code: "440881", level: 3, firstChar: null, children: null },
        { name: "雷州市", code: "440882", level: 3, firstChar: null, children: null },
        { name: "吴川市", code: "440883", level: 3, firstChar: null, children: null },
      ],
      茂名市: [
        { name: "茂南区", code: "440902", level: 3, firstChar: null, children: null },
        { name: "电白区", code: "440904", level: 3, firstChar: null, children: null },
        { name: "高州市", code: "440981", level: 3, firstChar: null, children: null },
        { name: "化州市", code: "440982", level: 3, firstChar: null, children: null },
        { name: "信宜市", code: "440983", level: 3, firstChar: null, children: null },
      ],
      肇庆市: [
        { name: "端州区", code: "441202", level: 3, firstChar: null, children: null },
        { name: "鼎湖区", code: "441203", level: 3, firstChar: null, children: null },
        { name: "高要区", code: "441204", level: 3, firstChar: null, children: null },
        { name: "广宁县", code: "441223", level: 3, firstChar: null, children: null },
        { name: "怀集县", code: "441224", level: 3, firstChar: null, children: null },
        { name: "封开县", code: "441225", level: 3, firstChar: null, children: null },
        { name: "德庆县", code: "441226", level: 3, firstChar: null, children: null },
        { name: "四会市", code: "441284", level: 3, firstChar: null, children: null },
      ],
      惠州市: [
        { name: "惠城区", code: "441302", level: 3, firstChar: null, children: null },
        { name: "惠阳区", code: "441303", level: 3, firstChar: null, children: null },
        { name: "博罗县", code: "441322", level: 3, firstChar: null, children: null },
        { name: "惠东县", code: "441323", level: 3, firstChar: null, children: null },
        { name: "龙门县", code: "441324", level: 3, firstChar: null, children: null },
      ],
      梅州市: [
        { name: "梅江区", code: "441402", level: 3, firstChar: null, children: null },
        { name: "梅县区", code: "441403", level: 3, firstChar: null, children: null },
        { name: "大埔县", code: "441422", level: 3, firstChar: null, children: null },
        { name: "丰顺县", code: "441423", level: 3, firstChar: null, children: null },
        { name: "五华县", code: "441424", level: 3, firstChar: null, children: null },
        { name: "平远县", code: "441426", level: 3, firstChar: null, children: null },
        { name: "蕉岭县", code: "441427", level: 3, firstChar: null, children: null },
        { name: "兴宁市", code: "441481", level: 3, firstChar: null, children: null },
      ],
      汕尾市: [
        { name: "城区", code: "441502", level: 3, firstChar: null, children: null },
        { name: "海丰县", code: "441521", level: 3, firstChar: null, children: null },
        { name: "陆河县", code: "441523", level: 3, firstChar: null, children: null },
        { name: "陆丰市", code: "441581", level: 3, firstChar: null, children: null },
      ],
      河源市: [
        { name: "源城区", code: "441602", level: 3, firstChar: null, children: null },
        { name: "紫金县", code: "441621", level: 3, firstChar: null, children: null },
        { name: "龙川县", code: "441622", level: 3, firstChar: null, children: null },
        { name: "连平县", code: "441623", level: 3, firstChar: null, children: null },
        { name: "和平县", code: "441624", level: 3, firstChar: null, children: null },
        { name: "东源县", code: "441625", level: 3, firstChar: null, children: null },
      ],
      阳江市: [
        { name: "江城区", code: "441702", level: 3, firstChar: null, children: null },
        { name: "阳东区", code: "441704", level: 3, firstChar: null, children: null },
        { name: "阳西县", code: "441721", level: 3, firstChar: null, children: null },
        { name: "阳春市", code: "441781", level: 3, firstChar: null, children: null },
      ],
      清远市: [
        { name: "清城区", code: "441802", level: 3, firstChar: null, children: null },
        { name: "清新区", code: "441803", level: 3, firstChar: null, children: null },
        { name: "佛冈县", code: "441821", level: 3, firstChar: null, children: null },
        { name: "阳山县", code: "441823", level: 3, firstChar: null, children: null },
        { name: "连山壮族瑶族自治县", code: "441825", level: 3, firstChar: null, children: null },
        { name: "连南瑶族自治县", code: "441826", level: 3, firstChar: null, children: null },
        { name: "英德市", code: "441881", level: 3, firstChar: null, children: null },
        { name: "连州市", code: "441882", level: 3, firstChar: null, children: null },
      ],
      东莞市: [],
      中山市: [],
      潮州市: [
        { name: "湘桥区", code: "445102", level: 3, firstChar: null, children: null },
        { name: "潮安区", code: "445103", level: 3, firstChar: null, children: null },
        { name: "饶平县", code: "445122", level: 3, firstChar: null, children: null },
      ],
      揭阳市: [
        { name: "榕城区", code: "445202", level: 3, firstChar: null, children: null },
        { name: "揭东区", code: "445203", level: 3, firstChar: null, children: null },
        { name: "揭西县", code: "445222", level: 3, firstChar: null, children: null },
        { name: "惠来县", code: "445224", level: 3, firstChar: null, children: null },
        { name: "普宁市", code: "445281", level: 3, firstChar: null, children: null },
      ],
      云浮市: [
        { name: "云城区", code: "445302", level: 3, firstChar: null, children: null },
        { name: "云安区", code: "445303", level: 3, firstChar: null, children: null },
        { name: "新兴县", code: "445321", level: 3, firstChar: null, children: null },
        { name: "郁南县", code: "445322", level: 3, firstChar: null, children: null },
        { name: "罗定市", code: "445381", level: 3, firstChar: null, children: null },
      ],
    },
    广西: {
      南宁市: [
        { name: "兴宁区", code: "450102", level: 3, firstChar: null, children: null },
        { name: "青秀区", code: "450103", level: 3, firstChar: null, children: null },
        { name: "江南区", code: "450105", level: 3, firstChar: null, children: null },
        { name: "西乡塘区", code: "450107", level: 3, firstChar: null, children: null },
        { name: "良庆区", code: "450108", level: 3, firstChar: null, children: null },
        { name: "邕宁区", code: "450109", level: 3, firstChar: null, children: null },
        { name: "武鸣区", code: "450110", level: 3, firstChar: null, children: null },
        { name: "隆安县", code: "450123", level: 3, firstChar: null, children: null },
        { name: "马山县", code: "450124", level: 3, firstChar: null, children: null },
        { name: "上林县", code: "450125", level: 3, firstChar: null, children: null },
        { name: "宾阳县", code: "450126", level: 3, firstChar: null, children: null },
        { name: "横县", code: "450127", level: 3, firstChar: null, children: null },
      ],
      柳州市: [
        { name: "城中区", code: "450202", level: 3, firstChar: null, children: null },
        { name: "鱼峰区", code: "450203", level: 3, firstChar: null, children: null },
        { name: "柳南区", code: "450204", level: 3, firstChar: null, children: null },
        { name: "柳北区", code: "450205", level: 3, firstChar: null, children: null },
        { name: "柳江区", code: "450206", level: 3, firstChar: null, children: null },
        { name: "柳城县", code: "450222", level: 3, firstChar: null, children: null },
        { name: "鹿寨县", code: "450223", level: 3, firstChar: null, children: null },
        { name: "融安县", code: "450224", level: 3, firstChar: null, children: null },
        { name: "融水苗族自治县", code: "450225", level: 3, firstChar: null, children: null },
        { name: "三江侗族自治县", code: "450226", level: 3, firstChar: null, children: null },
      ],
      桂林市: [
        { name: "秀峰区", code: "450302", level: 3, firstChar: null, children: null },
        { name: "叠彩区", code: "450303", level: 3, firstChar: null, children: null },
        { name: "象山区", code: "450304", level: 3, firstChar: null, children: null },
        { name: "七星区", code: "450305", level: 3, firstChar: null, children: null },
        { name: "雁山区", code: "450311", level: 3, firstChar: null, children: null },
        { name: "临桂区", code: "450312", level: 3, firstChar: null, children: null },
        { name: "阳朔县", code: "450321", level: 3, firstChar: null, children: null },
        { name: "灵川县", code: "450323", level: 3, firstChar: null, children: null },
        { name: "全州县", code: "450324", level: 3, firstChar: null, children: null },
        { name: "兴安县", code: "450325", level: 3, firstChar: null, children: null },
        { name: "永福县", code: "450326", level: 3, firstChar: null, children: null },
        { name: "灌阳县", code: "450327", level: 3, firstChar: null, children: null },
        { name: "龙胜各族自治县", code: "450328", level: 3, firstChar: null, children: null },
        { name: "资源县", code: "450329", level: 3, firstChar: null, children: null },
        { name: "平乐县", code: "450330", level: 3, firstChar: null, children: null },
        { name: "恭城瑶族自治县", code: "450332", level: 3, firstChar: null, children: null },
        { name: "荔浦市", code: "450381", level: 3, firstChar: null, children: null },
      ],
      梧州市: [
        { name: "万秀区", code: "450403", level: 3, firstChar: null, children: null },
        { name: "长洲区", code: "450405", level: 3, firstChar: null, children: null },
        { name: "龙圩区", code: "450406", level: 3, firstChar: null, children: null },
        { name: "苍梧县", code: "450421", level: 3, firstChar: null, children: null },
        { name: "藤县", code: "450422", level: 3, firstChar: null, children: null },
        { name: "蒙山县", code: "450423", level: 3, firstChar: null, children: null },
        { name: "岑溪市", code: "450481", level: 3, firstChar: null, children: null },
      ],
      北海市: [
        { name: "海城区", code: "450502", level: 3, firstChar: null, children: null },
        { name: "银海区", code: "450503", level: 3, firstChar: null, children: null },
        { name: "铁山港区", code: "450512", level: 3, firstChar: null, children: null },
        { name: "合浦县", code: "450521", level: 3, firstChar: null, children: null },
      ],
      防城港市: [
        { name: "港口区", code: "450602", level: 3, firstChar: null, children: null },
        { name: "防城区", code: "450603", level: 3, firstChar: null, children: null },
        { name: "上思县", code: "450621", level: 3, firstChar: null, children: null },
        { name: "东兴市", code: "450681", level: 3, firstChar: null, children: null },
      ],
      钦州市: [
        { name: "钦南区", code: "450702", level: 3, firstChar: null, children: null },
        { name: "钦北区", code: "450703", level: 3, firstChar: null, children: null },
        { name: "灵山县", code: "450721", level: 3, firstChar: null, children: null },
        { name: "浦北县", code: "450722", level: 3, firstChar: null, children: null },
      ],
      贵港市: [
        { name: "港北区", code: "450802", level: 3, firstChar: null, children: null },
        { name: "港南区", code: "450803", level: 3, firstChar: null, children: null },
        { name: "覃塘区", code: "450804", level: 3, firstChar: null, children: null },
        { name: "平南县", code: "450821", level: 3, firstChar: null, children: null },
        { name: "桂平市", code: "450881", level: 3, firstChar: null, children: null },
      ],
      玉林市: [
        { name: "玉州区", code: "450902", level: 3, firstChar: null, children: null },
        { name: "福绵区", code: "450903", level: 3, firstChar: null, children: null },
        { name: "容县", code: "450921", level: 3, firstChar: null, children: null },
        { name: "陆川县", code: "450922", level: 3, firstChar: null, children: null },
        { name: "博白县", code: "450923", level: 3, firstChar: null, children: null },
        { name: "兴业县", code: "450924", level: 3, firstChar: null, children: null },
        { name: "北流市", code: "450981", level: 3, firstChar: null, children: null },
      ],
      百色市: [
        { name: "右江区", code: "451002", level: 3, firstChar: null, children: null },
        { name: "田阳区", code: "451003", level: 3, firstChar: null, children: null },
        { name: "田东县", code: "451022", level: 3, firstChar: null, children: null },
        { name: "德保县", code: "451024", level: 3, firstChar: null, children: null },
        { name: "那坡县", code: "451026", level: 3, firstChar: null, children: null },
        { name: "凌云县", code: "451027", level: 3, firstChar: null, children: null },
        { name: "乐业县", code: "451028", level: 3, firstChar: null, children: null },
        { name: "田林县", code: "451029", level: 3, firstChar: null, children: null },
        { name: "西林县", code: "451030", level: 3, firstChar: null, children: null },
        { name: "隆林各族自治县", code: "451031", level: 3, firstChar: null, children: null },
        { name: "靖西市", code: "451081", level: 3, firstChar: null, children: null },
        { name: "平果市", code: "451082", level: 3, firstChar: null, children: null },
      ],
      贺州市: [
        { name: "八步区", code: "451102", level: 3, firstChar: null, children: null },
        { name: "平桂区", code: "451103", level: 3, firstChar: null, children: null },
        { name: "昭平县", code: "451121", level: 3, firstChar: null, children: null },
        { name: "钟山县", code: "451122", level: 3, firstChar: null, children: null },
        { name: "富川瑶族自治县", code: "451123", level: 3, firstChar: null, children: null },
      ],
      河池市: [
        { name: "金城江区", code: "451202", level: 3, firstChar: null, children: null },
        { name: "宜州区", code: "451203", level: 3, firstChar: null, children: null },
        { name: "南丹县", code: "451221", level: 3, firstChar: null, children: null },
        { name: "天峨县", code: "451222", level: 3, firstChar: null, children: null },
        { name: "凤山县", code: "451223", level: 3, firstChar: null, children: null },
        { name: "东兰县", code: "451224", level: 3, firstChar: null, children: null },
        { name: "罗城仫佬族自治县", code: "451225", level: 3, firstChar: null, children: null },
        { name: "环江毛南族自治县", code: "451226", level: 3, firstChar: null, children: null },
        { name: "巴马瑶族自治县", code: "451227", level: 3, firstChar: null, children: null },
        { name: "都安瑶族自治县", code: "451228", level: 3, firstChar: null, children: null },
        { name: "大化瑶族自治县", code: "451229", level: 3, firstChar: null, children: null },
      ],
      来宾市: [
        { name: "兴宾区", code: "451302", level: 3, firstChar: null, children: null },
        { name: "忻城县", code: "451321", level: 3, firstChar: null, children: null },
        { name: "象州县", code: "451322", level: 3, firstChar: null, children: null },
        { name: "武宣县", code: "451323", level: 3, firstChar: null, children: null },
        { name: "金秀瑶族自治县", code: "451324", level: 3, firstChar: null, children: null },
        { name: "合山市", code: "451381", level: 3, firstChar: null, children: null },
      ],
      崇左市: [
        { name: "江州区", code: "451402", level: 3, firstChar: null, children: null },
        { name: "扶绥县", code: "451421", level: 3, firstChar: null, children: null },
        { name: "宁明县", code: "451422", level: 3, firstChar: null, children: null },
        { name: "龙州县", code: "451423", level: 3, firstChar: null, children: null },
        { name: "大新县", code: "451424", level: 3, firstChar: null, children: null },
        { name: "天等县", code: "451425", level: 3, firstChar: null, children: null },
        { name: "凭祥市", code: "451481", level: 3, firstChar: null, children: null },
      ],
    },
    海南: {
      海口市: [
        { name: "秀英区", code: "460105", level: 3, firstChar: null, children: null },
        { name: "龙华区", code: "460106", level: 3, firstChar: null, children: null },
        { name: "琼山区", code: "460107", level: 3, firstChar: null, children: null },
        { name: "美兰区", code: "460108", level: 3, firstChar: null, children: null },
      ],
      三亚市: [
        { name: "海棠区", code: "460202", level: 3, firstChar: null, children: null },
        { name: "吉阳区", code: "460203", level: 3, firstChar: null, children: null },
        { name: "天涯区", code: "460204", level: 3, firstChar: null, children: null },
        { name: "崖州区", code: "460205", level: 3, firstChar: null, children: null },
      ],
      儋州市: [],
      五指山市: [],
      琼海市: [],
      文昌市: [],
      万宁市: [],
      东方市: [],
      定安县: [],
      屯昌县: [],
      澄迈县: [],
      临高县: [],
      白沙黎族自治县: [],
      昌江黎族自治县: [],
      乐东黎族自治县: [],
      陵水黎族自治县: [],
      保亭黎族苗族自治县: [],
      琼中黎族苗族自治县: [],
    },
    重庆: {
      重庆市: [
        { name: "万州区", code: "500101", level: 3, firstChar: null, children: null },
        { name: "涪陵区", code: "500102", level: 3, firstChar: null, children: null },
        { name: "渝中区", code: "500103", level: 3, firstChar: null, children: null },
        { name: "大渡口区", code: "500104", level: 3, firstChar: null, children: null },
        { name: "江北区", code: "500105", level: 3, firstChar: null, children: null },
        { name: "沙坪坝区", code: "500106", level: 3, firstChar: null, children: null },
        { name: "九龙坡区", code: "500107", level: 3, firstChar: null, children: null },
        { name: "南岸区", code: "500108", level: 3, firstChar: null, children: null },
        { name: "北碚区", code: "500109", level: 3, firstChar: null, children: null },
        { name: "綦江区", code: "500110", level: 3, firstChar: null, children: null },
        { name: "大足区", code: "500111", level: 3, firstChar: null, children: null },
        { name: "渝北区", code: "500112", level: 3, firstChar: null, children: null },
        { name: "巴南区", code: "500113", level: 3, firstChar: null, children: null },
        { name: "黔江区", code: "500114", level: 3, firstChar: null, children: null },
        { name: "长寿区", code: "500115", level: 3, firstChar: null, children: null },
        { name: "江津区", code: "500116", level: 3, firstChar: null, children: null },
        { name: "合川区", code: "500117", level: 3, firstChar: null, children: null },
        { name: "永川区", code: "500118", level: 3, firstChar: null, children: null },
        { name: "南川区", code: "500119", level: 3, firstChar: null, children: null },
        { name: "璧山区", code: "500120", level: 3, firstChar: null, children: null },
        { name: "铜梁区", code: "500151", level: 3, firstChar: null, children: null },
        { name: "潼南区", code: "500152", level: 3, firstChar: null, children: null },
        { name: "荣昌区", code: "500153", level: 3, firstChar: null, children: null },
        { name: "开州区", code: "500154", level: 3, firstChar: null, children: null },
        { name: "梁平区", code: "500155", level: 3, firstChar: null, children: null },
        { name: "武隆区", code: "500156", level: 3, firstChar: null, children: null },
        { name: "城口县", code: "500229", level: 3, firstChar: null, children: null },
        { name: "丰都县", code: "500230", level: 3, firstChar: null, children: null },
        { name: "垫江县", code: "500231", level: 3, firstChar: null, children: null },
        { name: "忠县", code: "500233", level: 3, firstChar: null, children: null },
        { name: "云阳县", code: "500235", level: 3, firstChar: null, children: null },
        { name: "奉节县", code: "500236", level: 3, firstChar: null, children: null },
        { name: "巫山县", code: "500237", level: 3, firstChar: null, children: null },
        { name: "巫溪县", code: "500238", level: 3, firstChar: null, children: null },
        { name: "石柱土家族自治县", code: "500240", level: 3, firstChar: null, children: null },
        { name: "秀山土家族苗族自治县", code: "500241", level: 3, firstChar: null, children: null },
        { name: "酉阳土家族苗族自治县", code: "500242", level: 3, firstChar: null, children: null },
        { name: "彭水苗族土家族自治县", code: "500243", level: 3, firstChar: null, children: null },
      ],
    },
    四川: {
      成都市: [
        { name: "锦江区", code: "510104", level: 3, firstChar: null, children: null },
        { name: "青羊区", code: "510105", level: 3, firstChar: null, children: null },
        { name: "金牛区", code: "510106", level: 3, firstChar: null, children: null },
        { name: "武侯区", code: "510107", level: 3, firstChar: null, children: null },
        { name: "成华区", code: "510108", level: 3, firstChar: null, children: null },
        { name: "龙泉驿区", code: "510112", level: 3, firstChar: null, children: null },
        { name: "青白江区", code: "510113", level: 3, firstChar: null, children: null },
        { name: "新都区", code: "510114", level: 3, firstChar: null, children: null },
        { name: "温江区", code: "510115", level: 3, firstChar: null, children: null },
        { name: "双流区", code: "510116", level: 3, firstChar: null, children: null },
        { name: "郫都区", code: "510117", level: 3, firstChar: null, children: null },
        { name: "新津区", code: "510118", level: 3, firstChar: null, children: null },
        { name: "金堂县", code: "510121", level: 3, firstChar: null, children: null },
        { name: "大邑县", code: "510129", level: 3, firstChar: null, children: null },
        { name: "蒲江县", code: "510131", level: 3, firstChar: null, children: null },
        { name: "都江堰市", code: "510181", level: 3, firstChar: null, children: null },
        { name: "彭州市", code: "510182", level: 3, firstChar: null, children: null },
        { name: "邛崃市", code: "510183", level: 3, firstChar: null, children: null },
        { name: "崇州市", code: "510184", level: 3, firstChar: null, children: null },
        { name: "简阳市", code: "510185", level: 3, firstChar: null, children: null },
      ],
      自贡市: [
        { name: "自流井区", code: "510302", level: 3, firstChar: null, children: null },
        { name: "贡井区", code: "510303", level: 3, firstChar: null, children: null },
        { name: "大安区", code: "510304", level: 3, firstChar: null, children: null },
        { name: "沿滩区", code: "510311", level: 3, firstChar: null, children: null },
        { name: "荣县", code: "510321", level: 3, firstChar: null, children: null },
        { name: "富顺县", code: "510322", level: 3, firstChar: null, children: null },
      ],
      攀枝花市: [
        { name: "东区", code: "510402", level: 3, firstChar: null, children: null },
        { name: "西区", code: "510403", level: 3, firstChar: null, children: null },
        { name: "仁和区", code: "510411", level: 3, firstChar: null, children: null },
        { name: "米易县", code: "510421", level: 3, firstChar: null, children: null },
        { name: "盐边县", code: "510422", level: 3, firstChar: null, children: null },
      ],
      泸州市: [
        { name: "江阳区", code: "510502", level: 3, firstChar: null, children: null },
        { name: "纳溪区", code: "510503", level: 3, firstChar: null, children: null },
        { name: "龙马潭区", code: "510504", level: 3, firstChar: null, children: null },
        { name: "泸县", code: "510521", level: 3, firstChar: null, children: null },
        { name: "合江县", code: "510522", level: 3, firstChar: null, children: null },
        { name: "叙永县", code: "510524", level: 3, firstChar: null, children: null },
        { name: "古蔺县", code: "510525", level: 3, firstChar: null, children: null },
      ],
      德阳市: [
        { name: "旌阳区", code: "510603", level: 3, firstChar: null, children: null },
        { name: "罗江区", code: "510604", level: 3, firstChar: null, children: null },
        { name: "中江县", code: "510623", level: 3, firstChar: null, children: null },
        { name: "广汉市", code: "510681", level: 3, firstChar: null, children: null },
        { name: "什邡市", code: "510682", level: 3, firstChar: null, children: null },
        { name: "绵竹市", code: "510683", level: 3, firstChar: null, children: null },
      ],
      绵阳市: [
        { name: "涪城区", code: "510703", level: 3, firstChar: null, children: null },
        { name: "游仙区", code: "510704", level: 3, firstChar: null, children: null },
        { name: "安州区", code: "510705", level: 3, firstChar: null, children: null },
        { name: "三台县", code: "510722", level: 3, firstChar: null, children: null },
        { name: "盐亭县", code: "510723", level: 3, firstChar: null, children: null },
        { name: "梓潼县", code: "510725", level: 3, firstChar: null, children: null },
        { name: "北川羌族自治县", code: "510726", level: 3, firstChar: null, children: null },
        { name: "平武县", code: "510727", level: 3, firstChar: null, children: null },
        { name: "江油市", code: "510781", level: 3, firstChar: null, children: null },
      ],
      广元市: [
        { name: "利州区", code: "510802", level: 3, firstChar: null, children: null },
        { name: "昭化区", code: "510811", level: 3, firstChar: null, children: null },
        { name: "朝天区", code: "510812", level: 3, firstChar: null, children: null },
        { name: "旺苍县", code: "510821", level: 3, firstChar: null, children: null },
        { name: "青川县", code: "510822", level: 3, firstChar: null, children: null },
        { name: "剑阁县", code: "510823", level: 3, firstChar: null, children: null },
        { name: "苍溪县", code: "510824", level: 3, firstChar: null, children: null },
      ],
      遂宁市: [
        { name: "船山区", code: "510903", level: 3, firstChar: null, children: null },
        { name: "安居区", code: "510904", level: 3, firstChar: null, children: null },
        { name: "蓬溪县", code: "510921", level: 3, firstChar: null, children: null },
        { name: "大英县", code: "510923", level: 3, firstChar: null, children: null },
        { name: "射洪市", code: "510981", level: 3, firstChar: null, children: null },
      ],
      内江市: [
        { name: "市中区", code: "511002", level: 3, firstChar: null, children: null },
        { name: "东兴区", code: "511011", level: 3, firstChar: null, children: null },
        { name: "威远县", code: "511024", level: 3, firstChar: null, children: null },
        { name: "资中县", code: "511025", level: 3, firstChar: null, children: null },
        { name: "内江经济开发区", code: "511071", level: 3, firstChar: null, children: null },
        { name: "隆昌市", code: "511083", level: 3, firstChar: null, children: null },
      ],
      乐山市: [
        { name: "市中区", code: "511102", level: 3, firstChar: null, children: null },
        { name: "沙湾区", code: "511111", level: 3, firstChar: null, children: null },
        { name: "五通桥区", code: "511112", level: 3, firstChar: null, children: null },
        { name: "金口河区", code: "511113", level: 3, firstChar: null, children: null },
        { name: "犍为县", code: "511123", level: 3, firstChar: null, children: null },
        { name: "井研县", code: "511124", level: 3, firstChar: null, children: null },
        { name: "夹江县", code: "511126", level: 3, firstChar: null, children: null },
        { name: "沐川县", code: "511129", level: 3, firstChar: null, children: null },
        { name: "峨边彝族自治县", code: "511132", level: 3, firstChar: null, children: null },
        { name: "马边彝族自治县", code: "511133", level: 3, firstChar: null, children: null },
        { name: "峨眉山市", code: "511181", level: 3, firstChar: null, children: null },
      ],
      南充市: [
        { name: "顺庆区", code: "511302", level: 3, firstChar: null, children: null },
        { name: "高坪区", code: "511303", level: 3, firstChar: null, children: null },
        { name: "嘉陵区", code: "511304", level: 3, firstChar: null, children: null },
        { name: "南部县", code: "511321", level: 3, firstChar: null, children: null },
        { name: "营山县", code: "511322", level: 3, firstChar: null, children: null },
        { name: "蓬安县", code: "511323", level: 3, firstChar: null, children: null },
        { name: "仪陇县", code: "511324", level: 3, firstChar: null, children: null },
        { name: "西充县", code: "511325", level: 3, firstChar: null, children: null },
        { name: "阆中市", code: "511381", level: 3, firstChar: null, children: null },
      ],
      眉山市: [
        { name: "东坡区", code: "511402", level: 3, firstChar: null, children: null },
        { name: "彭山区", code: "511403", level: 3, firstChar: null, children: null },
        { name: "仁寿县", code: "511421", level: 3, firstChar: null, children: null },
        { name: "洪雅县", code: "511423", level: 3, firstChar: null, children: null },
        { name: "丹棱县", code: "511424", level: 3, firstChar: null, children: null },
        { name: "青神县", code: "511425", level: 3, firstChar: null, children: null },
      ],
      宜宾市: [
        { name: "翠屏区", code: "511502", level: 3, firstChar: null, children: null },
        { name: "南溪区", code: "511503", level: 3, firstChar: null, children: null },
        { name: "叙州区", code: "511504", level: 3, firstChar: null, children: null },
        { name: "江安县", code: "511523", level: 3, firstChar: null, children: null },
        { name: "长宁县", code: "511524", level: 3, firstChar: null, children: null },
        { name: "高县", code: "511525", level: 3, firstChar: null, children: null },
        { name: "珙县", code: "511526", level: 3, firstChar: null, children: null },
        { name: "筠连县", code: "511527", level: 3, firstChar: null, children: null },
        { name: "兴文县", code: "511528", level: 3, firstChar: null, children: null },
        { name: "屏山县", code: "511529", level: 3, firstChar: null, children: null },
      ],
      广安市: [
        { name: "广安区", code: "511602", level: 3, firstChar: null, children: null },
        { name: "前锋区", code: "511603", level: 3, firstChar: null, children: null },
        { name: "岳池县", code: "511621", level: 3, firstChar: null, children: null },
        { name: "武胜县", code: "511622", level: 3, firstChar: null, children: null },
        { name: "邻水县", code: "511623", level: 3, firstChar: null, children: null },
        { name: "华蓥市", code: "511681", level: 3, firstChar: null, children: null },
      ],
      达州市: [
        { name: "通川区", code: "511702", level: 3, firstChar: null, children: null },
        { name: "达川区", code: "511703", level: 3, firstChar: null, children: null },
        { name: "宣汉县", code: "511722", level: 3, firstChar: null, children: null },
        { name: "开江县", code: "511723", level: 3, firstChar: null, children: null },
        { name: "大竹县", code: "511724", level: 3, firstChar: null, children: null },
        { name: "渠县", code: "511725", level: 3, firstChar: null, children: null },
        { name: "达州经济开发区", code: "511771", level: 3, firstChar: null, children: null },
        { name: "万源市", code: "511781", level: 3, firstChar: null, children: null },
      ],
      雅安市: [
        { name: "雨城区", code: "511802", level: 3, firstChar: null, children: null },
        { name: "名山区", code: "511803", level: 3, firstChar: null, children: null },
        { name: "荥经县", code: "511822", level: 3, firstChar: null, children: null },
        { name: "汉源县", code: "511823", level: 3, firstChar: null, children: null },
        { name: "石棉县", code: "511824", level: 3, firstChar: null, children: null },
        { name: "天全县", code: "511825", level: 3, firstChar: null, children: null },
        { name: "芦山县", code: "511826", level: 3, firstChar: null, children: null },
        { name: "宝兴县", code: "511827", level: 3, firstChar: null, children: null },
      ],
      巴中市: [
        { name: "巴州区", code: "511902", level: 3, firstChar: null, children: null },
        { name: "恩阳区", code: "511903", level: 3, firstChar: null, children: null },
        { name: "通江县", code: "511921", level: 3, firstChar: null, children: null },
        { name: "南江县", code: "511922", level: 3, firstChar: null, children: null },
        { name: "平昌县", code: "511923", level: 3, firstChar: null, children: null },
        { name: "巴中经济开发区", code: "511971", level: 3, firstChar: null, children: null },
      ],
      资阳市: [
        { name: "雁江区", code: "512002", level: 3, firstChar: null, children: null },
        { name: "安岳县", code: "512021", level: 3, firstChar: null, children: null },
        { name: "乐至县", code: "512022", level: 3, firstChar: null, children: null },
      ],
      阿坝藏族羌族自治州: [
        { name: "马尔康市", code: "513201", level: 3, firstChar: null, children: null },
        { name: "汶川县", code: "513221", level: 3, firstChar: null, children: null },
        { name: "理县", code: "513222", level: 3, firstChar: null, children: null },
        { name: "茂县", code: "513223", level: 3, firstChar: null, children: null },
        { name: "松潘县", code: "513224", level: 3, firstChar: null, children: null },
        { name: "九寨沟县", code: "513225", level: 3, firstChar: null, children: null },
        { name: "金川县", code: "513226", level: 3, firstChar: null, children: null },
        { name: "小金县", code: "513227", level: 3, firstChar: null, children: null },
        { name: "黑水县", code: "513228", level: 3, firstChar: null, children: null },
        { name: "壤塘县", code: "513230", level: 3, firstChar: null, children: null },
        { name: "阿坝县", code: "513231", level: 3, firstChar: null, children: null },
        { name: "若尔盖县", code: "513232", level: 3, firstChar: null, children: null },
        { name: "红原县", code: "513233", level: 3, firstChar: null, children: null },
      ],
      甘孜藏族自治州: [
        { name: "康定市", code: "513301", level: 3, firstChar: null, children: null },
        { name: "泸定县", code: "513322", level: 3, firstChar: null, children: null },
        { name: "丹巴县", code: "513323", level: 3, firstChar: null, children: null },
        { name: "九龙县", code: "513324", level: 3, firstChar: null, children: null },
        { name: "雅江县", code: "513325", level: 3, firstChar: null, children: null },
        { name: "道孚县", code: "513326", level: 3, firstChar: null, children: null },
        { name: "炉霍县", code: "513327", level: 3, firstChar: null, children: null },
        { name: "甘孜县", code: "513328", level: 3, firstChar: null, children: null },
        { name: "新龙县", code: "513329", level: 3, firstChar: null, children: null },
        { name: "德格县", code: "513330", level: 3, firstChar: null, children: null },
        { name: "白玉县", code: "513331", level: 3, firstChar: null, children: null },
        { name: "石渠县", code: "513332", level: 3, firstChar: null, children: null },
        { name: "色达县", code: "513333", level: 3, firstChar: null, children: null },
        { name: "理塘县", code: "513334", level: 3, firstChar: null, children: null },
        { name: "巴塘县", code: "513335", level: 3, firstChar: null, children: null },
        { name: "乡城县", code: "513336", level: 3, firstChar: null, children: null },
        { name: "稻城县", code: "513337", level: 3, firstChar: null, children: null },
        { name: "得荣县", code: "513338", level: 3, firstChar: null, children: null },
      ],
      凉山彝族自治州: [
        { name: "西昌市", code: "513401", level: 3, firstChar: null, children: null },
        { name: "木里藏族自治县", code: "513422", level: 3, firstChar: null, children: null },
        { name: "盐源县", code: "513423", level: 3, firstChar: null, children: null },
        { name: "德昌县", code: "513424", level: 3, firstChar: null, children: null },
        { name: "会理县", code: "513425", level: 3, firstChar: null, children: null },
        { name: "会东县", code: "513426", level: 3, firstChar: null, children: null },
        { name: "宁南县", code: "513427", level: 3, firstChar: null, children: null },
        { name: "普格县", code: "513428", level: 3, firstChar: null, children: null },
        { name: "布拖县", code: "513429", level: 3, firstChar: null, children: null },
        { name: "金阳县", code: "513430", level: 3, firstChar: null, children: null },
        { name: "昭觉县", code: "513431", level: 3, firstChar: null, children: null },
        { name: "喜德县", code: "513432", level: 3, firstChar: null, children: null },
        { name: "冕宁县", code: "513433", level: 3, firstChar: null, children: null },
        { name: "越西县", code: "513434", level: 3, firstChar: null, children: null },
        { name: "甘洛县", code: "513435", level: 3, firstChar: null, children: null },
        { name: "美姑县", code: "513436", level: 3, firstChar: null, children: null },
        { name: "雷波县", code: "513437", level: 3, firstChar: null, children: null },
      ],
    },
    贵州: {
      贵阳市: [
        { name: "南明区", code: "520102", level: 3, firstChar: null, children: null },
        { name: "云岩区", code: "520103", level: 3, firstChar: null, children: null },
        { name: "花溪区", code: "520111", level: 3, firstChar: null, children: null },
        { name: "乌当区", code: "520112", level: 3, firstChar: null, children: null },
        { name: "白云区", code: "520113", level: 3, firstChar: null, children: null },
        { name: "观山湖区", code: "520115", level: 3, firstChar: null, children: null },
        { name: "开阳县", code: "520121", level: 3, firstChar: null, children: null },
        { name: "息烽县", code: "520122", level: 3, firstChar: null, children: null },
        { name: "修文县", code: "520123", level: 3, firstChar: null, children: null },
        { name: "清镇市", code: "520181", level: 3, firstChar: null, children: null },
      ],
      六盘水市: [
        { name: "钟山区", code: "520201", level: 3, firstChar: null, children: null },
        { name: "六枝特区", code: "520203", level: 3, firstChar: null, children: null },
        { name: "水城县", code: "520221", level: 3, firstChar: null, children: null },
        { name: "盘州市", code: "520281", level: 3, firstChar: null, children: null },
      ],
      遵义市: [
        { name: "红花岗区", code: "520302", level: 3, firstChar: null, children: null },
        { name: "汇川区", code: "520303", level: 3, firstChar: null, children: null },
        { name: "播州区", code: "520304", level: 3, firstChar: null, children: null },
        { name: "桐梓县", code: "520322", level: 3, firstChar: null, children: null },
        { name: "绥阳县", code: "520323", level: 3, firstChar: null, children: null },
        { name: "正安县", code: "520324", level: 3, firstChar: null, children: null },
        { name: "道真仡佬族苗族自治县", code: "520325", level: 3, firstChar: null, children: null },
        { name: "务川仡佬族苗族自治县", code: "520326", level: 3, firstChar: null, children: null },
        { name: "凤冈县", code: "520327", level: 3, firstChar: null, children: null },
        { name: "湄潭县", code: "520328", level: 3, firstChar: null, children: null },
        { name: "余庆县", code: "520329", level: 3, firstChar: null, children: null },
        { name: "习水县", code: "520330", level: 3, firstChar: null, children: null },
        { name: "赤水市", code: "520381", level: 3, firstChar: null, children: null },
        { name: "仁怀市", code: "520382", level: 3, firstChar: null, children: null },
      ],
      安顺市: [
        { name: "西秀区", code: "520402", level: 3, firstChar: null, children: null },
        { name: "平坝区", code: "520403", level: 3, firstChar: null, children: null },
        { name: "普定县", code: "520422", level: 3, firstChar: null, children: null },
        { name: "镇宁布依族苗族自治县", code: "520423", level: 3, firstChar: null, children: null },
        { name: "关岭布依族苗族自治县", code: "520424", level: 3, firstChar: null, children: null },
        { name: "紫云苗族布依族自治县", code: "520425", level: 3, firstChar: null, children: null },
      ],
      毕节市: [
        { name: "七星关区", code: "520502", level: 3, firstChar: null, children: null },
        { name: "大方县", code: "520521", level: 3, firstChar: null, children: null },
        { name: "黔西县", code: "520522", level: 3, firstChar: null, children: null },
        { name: "金沙县", code: "520523", level: 3, firstChar: null, children: null },
        { name: "织金县", code: "520524", level: 3, firstChar: null, children: null },
        { name: "纳雍县", code: "520525", level: 3, firstChar: null, children: null },
        {
          name: "威宁彝族回族苗族自治县",
          code: "520526",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "赫章县", code: "520527", level: 3, firstChar: null, children: null },
      ],
      铜仁市: [
        { name: "碧江区", code: "520602", level: 3, firstChar: null, children: null },
        { name: "万山区", code: "520603", level: 3, firstChar: null, children: null },
        { name: "江口县", code: "520621", level: 3, firstChar: null, children: null },
        { name: "玉屏侗族自治县", code: "520622", level: 3, firstChar: null, children: null },
        { name: "石阡县", code: "520623", level: 3, firstChar: null, children: null },
        { name: "思南县", code: "520624", level: 3, firstChar: null, children: null },
        { name: "印江土家族苗族自治县", code: "520625", level: 3, firstChar: null, children: null },
        { name: "德江县", code: "520626", level: 3, firstChar: null, children: null },
        { name: "沿河土家族自治县", code: "520627", level: 3, firstChar: null, children: null },
        { name: "松桃苗族自治县", code: "520628", level: 3, firstChar: null, children: null },
      ],
      黔西南布依族苗族自治州: [
        { name: "兴义市", code: "522301", level: 3, firstChar: null, children: null },
        { name: "兴仁市", code: "522302", level: 3, firstChar: null, children: null },
        { name: "普安县", code: "522323", level: 3, firstChar: null, children: null },
        { name: "晴隆县", code: "522324", level: 3, firstChar: null, children: null },
        { name: "贞丰县", code: "522325", level: 3, firstChar: null, children: null },
        { name: "望谟县", code: "522326", level: 3, firstChar: null, children: null },
        { name: "册亨县", code: "522327", level: 3, firstChar: null, children: null },
        { name: "安龙县", code: "522328", level: 3, firstChar: null, children: null },
      ],
      黔东南苗族侗族自治州: [
        { name: "凯里市", code: "522601", level: 3, firstChar: null, children: null },
        { name: "黄平县", code: "522622", level: 3, firstChar: null, children: null },
        { name: "施秉县", code: "522623", level: 3, firstChar: null, children: null },
        { name: "三穗县", code: "522624", level: 3, firstChar: null, children: null },
        { name: "镇远县", code: "522625", level: 3, firstChar: null, children: null },
        { name: "岑巩县", code: "522626", level: 3, firstChar: null, children: null },
        { name: "天柱县", code: "522627", level: 3, firstChar: null, children: null },
        { name: "锦屏县", code: "522628", level: 3, firstChar: null, children: null },
        { name: "剑河县", code: "522629", level: 3, firstChar: null, children: null },
        { name: "台江县", code: "522630", level: 3, firstChar: null, children: null },
        { name: "黎平县", code: "522631", level: 3, firstChar: null, children: null },
        { name: "榕江县", code: "522632", level: 3, firstChar: null, children: null },
        { name: "从江县", code: "522633", level: 3, firstChar: null, children: null },
        { name: "雷山县", code: "522634", level: 3, firstChar: null, children: null },
        { name: "麻江县", code: "522635", level: 3, firstChar: null, children: null },
        { name: "丹寨县", code: "522636", level: 3, firstChar: null, children: null },
      ],
      黔南布依族苗族自治州: [
        { name: "都匀市", code: "522701", level: 3, firstChar: null, children: null },
        { name: "福泉市", code: "522702", level: 3, firstChar: null, children: null },
        { name: "荔波县", code: "522722", level: 3, firstChar: null, children: null },
        { name: "贵定县", code: "522723", level: 3, firstChar: null, children: null },
        { name: "瓮安县", code: "522725", level: 3, firstChar: null, children: null },
        { name: "独山县", code: "522726", level: 3, firstChar: null, children: null },
        { name: "平塘县", code: "522727", level: 3, firstChar: null, children: null },
        { name: "罗甸县", code: "522728", level: 3, firstChar: null, children: null },
        { name: "长顺县", code: "522729", level: 3, firstChar: null, children: null },
        { name: "龙里县", code: "522730", level: 3, firstChar: null, children: null },
        { name: "惠水县", code: "522731", level: 3, firstChar: null, children: null },
        { name: "三都水族自治县", code: "522732", level: 3, firstChar: null, children: null },
      ],
    },
    云南: {
      昆明市: [
        { name: "五华区", code: "530102", level: 3, firstChar: null, children: null },
        { name: "盘龙区", code: "530103", level: 3, firstChar: null, children: null },
        { name: "官渡区", code: "530111", level: 3, firstChar: null, children: null },
        { name: "西山区", code: "530112", level: 3, firstChar: null, children: null },
        { name: "东川区", code: "530113", level: 3, firstChar: null, children: null },
        { name: "呈贡区", code: "530114", level: 3, firstChar: null, children: null },
        { name: "晋宁区", code: "530115", level: 3, firstChar: null, children: null },
        { name: "富民县", code: "530124", level: 3, firstChar: null, children: null },
        { name: "宜良县", code: "530125", level: 3, firstChar: null, children: null },
        { name: "石林彝族自治县", code: "530126", level: 3, firstChar: null, children: null },
        { name: "嵩明县", code: "530127", level: 3, firstChar: null, children: null },
        { name: "禄劝彝族苗族自治县", code: "530128", level: 3, firstChar: null, children: null },
        { name: "寻甸回族彝族自治县", code: "530129", level: 3, firstChar: null, children: null },
        { name: "安宁市", code: "530181", level: 3, firstChar: null, children: null },
      ],
      曲靖市: [
        { name: "麒麟区", code: "530302", level: 3, firstChar: null, children: null },
        { name: "沾益区", code: "530303", level: 3, firstChar: null, children: null },
        { name: "马龙区", code: "530304", level: 3, firstChar: null, children: null },
        { name: "陆良县", code: "530322", level: 3, firstChar: null, children: null },
        { name: "师宗县", code: "530323", level: 3, firstChar: null, children: null },
        { name: "罗平县", code: "530324", level: 3, firstChar: null, children: null },
        { name: "富源县", code: "530325", level: 3, firstChar: null, children: null },
        { name: "会泽县", code: "530326", level: 3, firstChar: null, children: null },
        { name: "宣威市", code: "530381", level: 3, firstChar: null, children: null },
      ],
      玉溪市: [
        { name: "红塔区", code: "530402", level: 3, firstChar: null, children: null },
        { name: "江川区", code: "530403", level: 3, firstChar: null, children: null },
        { name: "通海县", code: "530423", level: 3, firstChar: null, children: null },
        { name: "华宁县", code: "530424", level: 3, firstChar: null, children: null },
        { name: "易门县", code: "530425", level: 3, firstChar: null, children: null },
        { name: "峨山彝族自治县", code: "530426", level: 3, firstChar: null, children: null },
        { name: "新平彝族傣族自治县", code: "530427", level: 3, firstChar: null, children: null },
        {
          name: "元江哈尼族彝族傣族自治县",
          code: "530428",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "澄江市", code: "530481", level: 3, firstChar: null, children: null },
      ],
      保山市: [
        { name: "隆阳区", code: "530502", level: 3, firstChar: null, children: null },
        { name: "施甸县", code: "530521", level: 3, firstChar: null, children: null },
        { name: "龙陵县", code: "530523", level: 3, firstChar: null, children: null },
        { name: "昌宁县", code: "530524", level: 3, firstChar: null, children: null },
        { name: "腾冲市", code: "530581", level: 3, firstChar: null, children: null },
      ],
      昭通市: [
        { name: "昭阳区", code: "530602", level: 3, firstChar: null, children: null },
        { name: "鲁甸县", code: "530621", level: 3, firstChar: null, children: null },
        { name: "巧家县", code: "530622", level: 3, firstChar: null, children: null },
        { name: "盐津县", code: "530623", level: 3, firstChar: null, children: null },
        { name: "大关县", code: "530624", level: 3, firstChar: null, children: null },
        { name: "永善县", code: "530625", level: 3, firstChar: null, children: null },
        { name: "绥江县", code: "530626", level: 3, firstChar: null, children: null },
        { name: "镇雄县", code: "530627", level: 3, firstChar: null, children: null },
        { name: "彝良县", code: "530628", level: 3, firstChar: null, children: null },
        { name: "威信县", code: "530629", level: 3, firstChar: null, children: null },
        { name: "水富市", code: "530681", level: 3, firstChar: null, children: null },
      ],
      丽江市: [
        { name: "古城区", code: "530702", level: 3, firstChar: null, children: null },
        { name: "玉龙纳西族自治县", code: "530721", level: 3, firstChar: null, children: null },
        { name: "永胜县", code: "530722", level: 3, firstChar: null, children: null },
        { name: "华坪县", code: "530723", level: 3, firstChar: null, children: null },
        { name: "宁蒗彝族自治县", code: "530724", level: 3, firstChar: null, children: null },
      ],
      普洱市: [
        { name: "思茅区", code: "530802", level: 3, firstChar: null, children: null },
        { name: "宁洱哈尼族彝族自治县", code: "530821", level: 3, firstChar: null, children: null },
        { name: "墨江哈尼族自治县", code: "530822", level: 3, firstChar: null, children: null },
        { name: "景东彝族自治县", code: "530823", level: 3, firstChar: null, children: null },
        { name: "景谷傣族彝族自治县", code: "530824", level: 3, firstChar: null, children: null },
        {
          name: "镇沅彝族哈尼族拉祜族自治县",
          code: "530825",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "江城哈尼族彝族自治县", code: "530826", level: 3, firstChar: null, children: null },
        {
          name: "孟连傣族拉祜族佤族自治县",
          code: "530827",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "澜沧拉祜族自治县", code: "530828", level: 3, firstChar: null, children: null },
        { name: "西盟佤族自治县", code: "530829", level: 3, firstChar: null, children: null },
      ],
      临沧市: [
        { name: "临翔区", code: "530902", level: 3, firstChar: null, children: null },
        { name: "凤庆县", code: "530921", level: 3, firstChar: null, children: null },
        { name: "云县", code: "530922", level: 3, firstChar: null, children: null },
        { name: "永德县", code: "530923", level: 3, firstChar: null, children: null },
        { name: "镇康县", code: "530924", level: 3, firstChar: null, children: null },
        {
          name: "双江拉祜族佤族布朗族傣族自治县",
          code: "530925",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "耿马傣族佤族自治县", code: "530926", level: 3, firstChar: null, children: null },
        { name: "沧源佤族自治县", code: "530927", level: 3, firstChar: null, children: null },
      ],
      楚雄彝族自治州: [
        { name: "楚雄市", code: "532301", level: 3, firstChar: null, children: null },
        { name: "双柏县", code: "532322", level: 3, firstChar: null, children: null },
        { name: "牟定县", code: "532323", level: 3, firstChar: null, children: null },
        { name: "南华县", code: "532324", level: 3, firstChar: null, children: null },
        { name: "姚安县", code: "532325", level: 3, firstChar: null, children: null },
        { name: "大姚县", code: "532326", level: 3, firstChar: null, children: null },
        { name: "永仁县", code: "532327", level: 3, firstChar: null, children: null },
        { name: "元谋县", code: "532328", level: 3, firstChar: null, children: null },
        { name: "武定县", code: "532329", level: 3, firstChar: null, children: null },
        { name: "禄丰县", code: "532331", level: 3, firstChar: null, children: null },
      ],
      红河哈尼族彝族自治州: [
        { name: "个旧市", code: "532501", level: 3, firstChar: null, children: null },
        { name: "开远市", code: "532502", level: 3, firstChar: null, children: null },
        { name: "蒙自市", code: "532503", level: 3, firstChar: null, children: null },
        { name: "弥勒市", code: "532504", level: 3, firstChar: null, children: null },
        { name: "屏边苗族自治县", code: "532523", level: 3, firstChar: null, children: null },
        { name: "建水县", code: "532524", level: 3, firstChar: null, children: null },
        { name: "石屏县", code: "532525", level: 3, firstChar: null, children: null },
        { name: "泸西县", code: "532527", level: 3, firstChar: null, children: null },
        { name: "元阳县", code: "532528", level: 3, firstChar: null, children: null },
        { name: "红河县", code: "532529", level: 3, firstChar: null, children: null },
        {
          name: "金平苗族瑶族傣族自治县",
          code: "532530",
          level: 3,
          firstChar: null,
          children: null,
        },
        { name: "绿春县", code: "532531", level: 3, firstChar: null, children: null },
        { name: "河口瑶族自治县", code: "532532", level: 3, firstChar: null, children: null },
      ],
      文山壮族苗族自治州: [
        { name: "文山市", code: "532601", level: 3, firstChar: null, children: null },
        { name: "砚山县", code: "532622", level: 3, firstChar: null, children: null },
        { name: "西畴县", code: "532623", level: 3, firstChar: null, children: null },
        { name: "麻栗坡县", code: "532624", level: 3, firstChar: null, children: null },
        { name: "马关县", code: "532625", level: 3, firstChar: null, children: null },
        { name: "丘北县", code: "532626", level: 3, firstChar: null, children: null },
        { name: "广南县", code: "532627", level: 3, firstChar: null, children: null },
        { name: "富宁县", code: "532628", level: 3, firstChar: null, children: null },
      ],
      西双版纳傣族自治州: [
        { name: "景洪市", code: "532801", level: 3, firstChar: null, children: null },
        { name: "勐海县", code: "532822", level: 3, firstChar: null, children: null },
        { name: "勐腊县", code: "532823", level: 3, firstChar: null, children: null },
      ],
      大理白族自治州: [
        { name: "大理市", code: "532901", level: 3, firstChar: null, children: null },
        { name: "漾濞彝族自治县", code: "532922", level: 3, firstChar: null, children: null },
        { name: "祥云县", code: "532923", level: 3, firstChar: null, children: null },
        { name: "宾川县", code: "532924", level: 3, firstChar: null, children: null },
        { name: "弥渡县", code: "532925", level: 3, firstChar: null, children: null },
        { name: "南涧彝族自治县", code: "532926", level: 3, firstChar: null, children: null },
        { name: "巍山彝族回族自治县", code: "532927", level: 3, firstChar: null, children: null },
        { name: "永平县", code: "532928", level: 3, firstChar: null, children: null },
        { name: "云龙县", code: "532929", level: 3, firstChar: null, children: null },
        { name: "洱源县", code: "532930", level: 3, firstChar: null, children: null },
        { name: "剑川县", code: "532931", level: 3, firstChar: null, children: null },
        { name: "鹤庆县", code: "532932", level: 3, firstChar: null, children: null },
      ],
      德宏傣族景颇族自治州: [
        { name: "瑞丽市", code: "533102", level: 3, firstChar: null, children: null },
        { name: "芒市", code: "533103", level: 3, firstChar: null, children: null },
        { name: "梁河县", code: "533122", level: 3, firstChar: null, children: null },
        { name: "盈江县", code: "533123", level: 3, firstChar: null, children: null },
        { name: "陇川县", code: "533124", level: 3, firstChar: null, children: null },
      ],
      怒江傈僳族自治州: [
        { name: "泸水市", code: "533301", level: 3, firstChar: null, children: null },
        { name: "福贡县", code: "533323", level: 3, firstChar: null, children: null },
        { name: "贡山独龙族怒族自治县", code: "533324", level: 3, firstChar: null, children: null },
        { name: "兰坪白族普米族自治县", code: "533325", level: 3, firstChar: null, children: null },
      ],
      迪庆藏族自治州: [
        { name: "香格里拉市", code: "533401", level: 3, firstChar: null, children: null },
        { name: "德钦县", code: "533422", level: 3, firstChar: null, children: null },
        { name: "维西傈僳族自治县", code: "533423", level: 3, firstChar: null, children: null },
      ],
    },
    西藏: {
      拉萨市: [
        { name: "城关区", code: "540102", level: 3, firstChar: null, children: null },
        { name: "堆龙德庆区", code: "540103", level: 3, firstChar: null, children: null },
        { name: "达孜区", code: "540104", level: 3, firstChar: null, children: null },
        { name: "林周县", code: "540121", level: 3, firstChar: null, children: null },
        { name: "当雄县", code: "540122", level: 3, firstChar: null, children: null },
        { name: "尼木县", code: "540123", level: 3, firstChar: null, children: null },
        { name: "曲水县", code: "540124", level: 3, firstChar: null, children: null },
        { name: "墨竹工卡县", code: "540127", level: 3, firstChar: null, children: null },
        { name: "格尔木藏青工业园区", code: "540171", level: 3, firstChar: null, children: null },
        { name: "拉萨经济技术开发区", code: "540172", level: 3, firstChar: null, children: null },
        { name: "西藏文化旅游创意园区", code: "540173", level: 3, firstChar: null, children: null },
        { name: "达孜工业园区", code: "540174", level: 3, firstChar: null, children: null },
      ],
      日喀则市: [
        { name: "桑珠孜区", code: "540202", level: 3, firstChar: null, children: null },
        { name: "南木林县", code: "540221", level: 3, firstChar: null, children: null },
        { name: "江孜县", code: "540222", level: 3, firstChar: null, children: null },
        { name: "定日县", code: "540223", level: 3, firstChar: null, children: null },
        { name: "萨迦县", code: "540224", level: 3, firstChar: null, children: null },
        { name: "拉孜县", code: "540225", level: 3, firstChar: null, children: null },
        { name: "昂仁县", code: "540226", level: 3, firstChar: null, children: null },
        { name: "谢通门县", code: "540227", level: 3, firstChar: null, children: null },
        { name: "白朗县", code: "540228", level: 3, firstChar: null, children: null },
        { name: "仁布县", code: "540229", level: 3, firstChar: null, children: null },
        { name: "康马县", code: "540230", level: 3, firstChar: null, children: null },
        { name: "定结县", code: "540231", level: 3, firstChar: null, children: null },
        { name: "仲巴县", code: "540232", level: 3, firstChar: null, children: null },
        { name: "亚东县", code: "540233", level: 3, firstChar: null, children: null },
        { name: "吉隆县", code: "540234", level: 3, firstChar: null, children: null },
        { name: "聂拉木县", code: "540235", level: 3, firstChar: null, children: null },
        { name: "萨嘎县", code: "540236", level: 3, firstChar: null, children: null },
        { name: "岗巴县", code: "540237", level: 3, firstChar: null, children: null },
      ],
      昌都市: [
        { name: "卡若区", code: "540302", level: 3, firstChar: null, children: null },
        { name: "江达县", code: "540321", level: 3, firstChar: null, children: null },
        { name: "贡觉县", code: "540322", level: 3, firstChar: null, children: null },
        { name: "类乌齐县", code: "540323", level: 3, firstChar: null, children: null },
        { name: "丁青县", code: "540324", level: 3, firstChar: null, children: null },
        { name: "察雅县", code: "540325", level: 3, firstChar: null, children: null },
        { name: "八宿县", code: "540326", level: 3, firstChar: null, children: null },
        { name: "左贡县", code: "540327", level: 3, firstChar: null, children: null },
        { name: "芒康县", code: "540328", level: 3, firstChar: null, children: null },
        { name: "洛隆县", code: "540329", level: 3, firstChar: null, children: null },
        { name: "边坝县", code: "540330", level: 3, firstChar: null, children: null },
      ],
      林芝市: [
        { name: "巴宜区", code: "540402", level: 3, firstChar: null, children: null },
        { name: "工布江达县", code: "540421", level: 3, firstChar: null, children: null },
        { name: "米林县", code: "540422", level: 3, firstChar: null, children: null },
        { name: "墨脱县", code: "540423", level: 3, firstChar: null, children: null },
        { name: "波密县", code: "540424", level: 3, firstChar: null, children: null },
        { name: "察隅县", code: "540425", level: 3, firstChar: null, children: null },
        { name: "朗县", code: "540426", level: 3, firstChar: null, children: null },
      ],
      山南市: [
        { name: "乃东区", code: "540502", level: 3, firstChar: null, children: null },
        { name: "扎囊县", code: "540521", level: 3, firstChar: null, children: null },
        { name: "贡嘎县", code: "540522", level: 3, firstChar: null, children: null },
        { name: "桑日县", code: "540523", level: 3, firstChar: null, children: null },
        { name: "琼结县", code: "540524", level: 3, firstChar: null, children: null },
        { name: "曲松县", code: "540525", level: 3, firstChar: null, children: null },
        { name: "措美县", code: "540526", level: 3, firstChar: null, children: null },
        { name: "洛扎县", code: "540527", level: 3, firstChar: null, children: null },
        { name: "加查县", code: "540528", level: 3, firstChar: null, children: null },
        { name: "隆子县", code: "540529", level: 3, firstChar: null, children: null },
        { name: "错那县", code: "540530", level: 3, firstChar: null, children: null },
        { name: "浪卡子县", code: "540531", level: 3, firstChar: null, children: null },
      ],
      那曲市: [
        { name: "色尼区", code: "540602", level: 3, firstChar: null, children: null },
        { name: "嘉黎县", code: "540621", level: 3, firstChar: null, children: null },
        { name: "比如县", code: "540622", level: 3, firstChar: null, children: null },
        { name: "聂荣县", code: "540623", level: 3, firstChar: null, children: null },
        { name: "安多县", code: "540624", level: 3, firstChar: null, children: null },
        { name: "申扎县", code: "540625", level: 3, firstChar: null, children: null },
        { name: "索县", code: "540626", level: 3, firstChar: null, children: null },
        { name: "班戈县", code: "540627", level: 3, firstChar: null, children: null },
        { name: "巴青县", code: "540628", level: 3, firstChar: null, children: null },
        { name: "尼玛县", code: "540629", level: 3, firstChar: null, children: null },
        { name: "双湖县", code: "540630", level: 3, firstChar: null, children: null },
      ],
      阿里地区: [
        { name: "普兰县", code: "542521", level: 3, firstChar: null, children: null },
        { name: "札达县", code: "542522", level: 3, firstChar: null, children: null },
        { name: "噶尔县", code: "542523", level: 3, firstChar: null, children: null },
        { name: "日土县", code: "542524", level: 3, firstChar: null, children: null },
        { name: "革吉县", code: "542525", level: 3, firstChar: null, children: null },
        { name: "改则县", code: "542526", level: 3, firstChar: null, children: null },
        { name: "措勤县", code: "542527", level: 3, firstChar: null, children: null },
      ],
    },
    陕西: {
      西安市: [
        { name: "新城区", code: "610102", level: 3, firstChar: null, children: null },
        { name: "碑林区", code: "610103", level: 3, firstChar: null, children: null },
        { name: "莲湖区", code: "610104", level: 3, firstChar: null, children: null },
        { name: "灞桥区", code: "610111", level: 3, firstChar: null, children: null },
        { name: "未央区", code: "610112", level: 3, firstChar: null, children: null },
        { name: "雁塔区", code: "610113", level: 3, firstChar: null, children: null },
        { name: "阎良区", code: "610114", level: 3, firstChar: null, children: null },
        { name: "临潼区", code: "610115", level: 3, firstChar: null, children: null },
        { name: "长安区", code: "610116", level: 3, firstChar: null, children: null },
        { name: "高陵区", code: "610117", level: 3, firstChar: null, children: null },
        { name: "鄠邑区", code: "610118", level: 3, firstChar: null, children: null },
        { name: "蓝田县", code: "610122", level: 3, firstChar: null, children: null },
        { name: "周至县", code: "610124", level: 3, firstChar: null, children: null },
      ],
      铜川市: [
        { name: "王益区", code: "610202", level: 3, firstChar: null, children: null },
        { name: "印台区", code: "610203", level: 3, firstChar: null, children: null },
        { name: "耀州区", code: "610204", level: 3, firstChar: null, children: null },
        { name: "宜君县", code: "610222", level: 3, firstChar: null, children: null },
      ],
      宝鸡市: [
        { name: "渭滨区", code: "610302", level: 3, firstChar: null, children: null },
        { name: "金台区", code: "610303", level: 3, firstChar: null, children: null },
        { name: "陈仓区", code: "610304", level: 3, firstChar: null, children: null },
        { name: "凤翔县", code: "610322", level: 3, firstChar: null, children: null },
        { name: "岐山县", code: "610323", level: 3, firstChar: null, children: null },
        { name: "扶风县", code: "610324", level: 3, firstChar: null, children: null },
        { name: "眉县", code: "610326", level: 3, firstChar: null, children: null },
        { name: "陇县", code: "610327", level: 3, firstChar: null, children: null },
        { name: "千阳县", code: "610328", level: 3, firstChar: null, children: null },
        { name: "麟游县", code: "610329", level: 3, firstChar: null, children: null },
        { name: "凤县", code: "610330", level: 3, firstChar: null, children: null },
        { name: "太白县", code: "610331", level: 3, firstChar: null, children: null },
      ],
      咸阳市: [
        { name: "秦都区", code: "610402", level: 3, firstChar: null, children: null },
        { name: "杨陵区", code: "610403", level: 3, firstChar: null, children: null },
        { name: "渭城区", code: "610404", level: 3, firstChar: null, children: null },
        { name: "三原县", code: "610422", level: 3, firstChar: null, children: null },
        { name: "泾阳县", code: "610423", level: 3, firstChar: null, children: null },
        { name: "乾县", code: "610424", level: 3, firstChar: null, children: null },
        { name: "礼泉县", code: "610425", level: 3, firstChar: null, children: null },
        { name: "永寿县", code: "610426", level: 3, firstChar: null, children: null },
        { name: "长武县", code: "610428", level: 3, firstChar: null, children: null },
        { name: "旬邑县", code: "610429", level: 3, firstChar: null, children: null },
        { name: "淳化县", code: "610430", level: 3, firstChar: null, children: null },
        { name: "武功县", code: "610431", level: 3, firstChar: null, children: null },
        { name: "兴平市", code: "610481", level: 3, firstChar: null, children: null },
        { name: "彬州市", code: "610482", level: 3, firstChar: null, children: null },
      ],
      渭南市: [
        { name: "临渭区", code: "610502", level: 3, firstChar: null, children: null },
        { name: "华州区", code: "610503", level: 3, firstChar: null, children: null },
        { name: "潼关县", code: "610522", level: 3, firstChar: null, children: null },
        { name: "大荔县", code: "610523", level: 3, firstChar: null, children: null },
        { name: "合阳县", code: "610524", level: 3, firstChar: null, children: null },
        { name: "澄城县", code: "610525", level: 3, firstChar: null, children: null },
        { name: "蒲城县", code: "610526", level: 3, firstChar: null, children: null },
        { name: "白水县", code: "610527", level: 3, firstChar: null, children: null },
        { name: "富平县", code: "610528", level: 3, firstChar: null, children: null },
        { name: "韩城市", code: "610581", level: 3, firstChar: null, children: null },
        { name: "华阴市", code: "610582", level: 3, firstChar: null, children: null },
      ],
      延安市: [
        { name: "宝塔区", code: "610602", level: 3, firstChar: null, children: null },
        { name: "安塞区", code: "610603", level: 3, firstChar: null, children: null },
        { name: "延长县", code: "610621", level: 3, firstChar: null, children: null },
        { name: "延川县", code: "610622", level: 3, firstChar: null, children: null },
        { name: "志丹县", code: "610625", level: 3, firstChar: null, children: null },
        { name: "吴起县", code: "610626", level: 3, firstChar: null, children: null },
        { name: "甘泉县", code: "610627", level: 3, firstChar: null, children: null },
        { name: "富县", code: "610628", level: 3, firstChar: null, children: null },
        { name: "洛川县", code: "610629", level: 3, firstChar: null, children: null },
        { name: "宜川县", code: "610630", level: 3, firstChar: null, children: null },
        { name: "黄龙县", code: "610631", level: 3, firstChar: null, children: null },
        { name: "黄陵县", code: "610632", level: 3, firstChar: null, children: null },
        { name: "子长市", code: "610681", level: 3, firstChar: null, children: null },
      ],
      汉中市: [
        { name: "汉台区", code: "610702", level: 3, firstChar: null, children: null },
        { name: "南郑区", code: "610703", level: 3, firstChar: null, children: null },
        { name: "城固县", code: "610722", level: 3, firstChar: null, children: null },
        { name: "洋县", code: "610723", level: 3, firstChar: null, children: null },
        { name: "西乡县", code: "610724", level: 3, firstChar: null, children: null },
        { name: "勉县", code: "610725", level: 3, firstChar: null, children: null },
        { name: "宁强县", code: "610726", level: 3, firstChar: null, children: null },
        { name: "略阳县", code: "610727", level: 3, firstChar: null, children: null },
        { name: "镇巴县", code: "610728", level: 3, firstChar: null, children: null },
        { name: "留坝县", code: "610729", level: 3, firstChar: null, children: null },
        { name: "佛坪县", code: "610730", level: 3, firstChar: null, children: null },
      ],
      榆林市: [
        { name: "榆阳区", code: "610802", level: 3, firstChar: null, children: null },
        { name: "横山区", code: "610803", level: 3, firstChar: null, children: null },
        { name: "府谷县", code: "610822", level: 3, firstChar: null, children: null },
        { name: "靖边县", code: "610824", level: 3, firstChar: null, children: null },
        { name: "定边县", code: "610825", level: 3, firstChar: null, children: null },
        { name: "绥德县", code: "610826", level: 3, firstChar: null, children: null },
        { name: "米脂县", code: "610827", level: 3, firstChar: null, children: null },
        { name: "佳县", code: "610828", level: 3, firstChar: null, children: null },
        { name: "吴堡县", code: "610829", level: 3, firstChar: null, children: null },
        { name: "清涧县", code: "610830", level: 3, firstChar: null, children: null },
        { name: "子洲县", code: "610831", level: 3, firstChar: null, children: null },
        { name: "神木市", code: "610881", level: 3, firstChar: null, children: null },
      ],
      安康市: [
        { name: "汉滨区", code: "610902", level: 3, firstChar: null, children: null },
        { name: "汉阴县", code: "610921", level: 3, firstChar: null, children: null },
        { name: "石泉县", code: "610922", level: 3, firstChar: null, children: null },
        { name: "宁陕县", code: "610923", level: 3, firstChar: null, children: null },
        { name: "紫阳县", code: "610924", level: 3, firstChar: null, children: null },
        { name: "岚皋县", code: "610925", level: 3, firstChar: null, children: null },
        { name: "平利县", code: "610926", level: 3, firstChar: null, children: null },
        { name: "镇坪县", code: "610927", level: 3, firstChar: null, children: null },
        { name: "旬阳县", code: "610928", level: 3, firstChar: null, children: null },
        { name: "白河县", code: "610929", level: 3, firstChar: null, children: null },
      ],
      商洛市: [
        { name: "商州区", code: "611002", level: 3, firstChar: null, children: null },
        { name: "洛南县", code: "611021", level: 3, firstChar: null, children: null },
        { name: "丹凤县", code: "611022", level: 3, firstChar: null, children: null },
        { name: "商南县", code: "611023", level: 3, firstChar: null, children: null },
        { name: "山阳县", code: "611024", level: 3, firstChar: null, children: null },
        { name: "镇安县", code: "611025", level: 3, firstChar: null, children: null },
        { name: "柞水县", code: "611026", level: 3, firstChar: null, children: null },
      ],
    },
    甘肃: {
      兰州市: [
        { name: "城关区", code: "620102", level: 3, firstChar: null, children: null },
        { name: "七里河区", code: "620103", level: 3, firstChar: null, children: null },
        { name: "西固区", code: "620104", level: 3, firstChar: null, children: null },
        { name: "安宁区", code: "620105", level: 3, firstChar: null, children: null },
        { name: "红古区", code: "620111", level: 3, firstChar: null, children: null },
        { name: "永登县", code: "620121", level: 3, firstChar: null, children: null },
        { name: "皋兰县", code: "620122", level: 3, firstChar: null, children: null },
        { name: "榆中县", code: "620123", level: 3, firstChar: null, children: null },
        { name: "兰州新区", code: "620171", level: 3, firstChar: null, children: null },
      ],
      嘉峪关市: [],
      金昌市: [
        { name: "金川区", code: "620302", level: 3, firstChar: null, children: null },
        { name: "永昌县", code: "620321", level: 3, firstChar: null, children: null },
      ],
      白银市: [
        { name: "白银区", code: "620402", level: 3, firstChar: null, children: null },
        { name: "平川区", code: "620403", level: 3, firstChar: null, children: null },
        { name: "靖远县", code: "620421", level: 3, firstChar: null, children: null },
        { name: "会宁县", code: "620422", level: 3, firstChar: null, children: null },
        { name: "景泰县", code: "620423", level: 3, firstChar: null, children: null },
      ],
      天水市: [
        { name: "秦州区", code: "620502", level: 3, firstChar: null, children: null },
        { name: "麦积区", code: "620503", level: 3, firstChar: null, children: null },
        { name: "清水县", code: "620521", level: 3, firstChar: null, children: null },
        { name: "秦安县", code: "620522", level: 3, firstChar: null, children: null },
        { name: "甘谷县", code: "620523", level: 3, firstChar: null, children: null },
        { name: "武山县", code: "620524", level: 3, firstChar: null, children: null },
        { name: "张家川回族自治县", code: "620525", level: 3, firstChar: null, children: null },
      ],
      武威市: [
        { name: "凉州区", code: "620602", level: 3, firstChar: null, children: null },
        { name: "民勤县", code: "620621", level: 3, firstChar: null, children: null },
        { name: "古浪县", code: "620622", level: 3, firstChar: null, children: null },
        { name: "天祝藏族自治县", code: "620623", level: 3, firstChar: null, children: null },
      ],
      张掖市: [
        { name: "甘州区", code: "620702", level: 3, firstChar: null, children: null },
        { name: "肃南裕固族自治县", code: "620721", level: 3, firstChar: null, children: null },
        { name: "民乐县", code: "620722", level: 3, firstChar: null, children: null },
        { name: "临泽县", code: "620723", level: 3, firstChar: null, children: null },
        { name: "高台县", code: "620724", level: 3, firstChar: null, children: null },
        { name: "山丹县", code: "620725", level: 3, firstChar: null, children: null },
      ],
      平凉市: [
        { name: "崆峒区", code: "620802", level: 3, firstChar: null, children: null },
        { name: "泾川县", code: "620821", level: 3, firstChar: null, children: null },
        { name: "灵台县", code: "620822", level: 3, firstChar: null, children: null },
        { name: "崇信县", code: "620823", level: 3, firstChar: null, children: null },
        { name: "庄浪县", code: "620825", level: 3, firstChar: null, children: null },
        { name: "静宁县", code: "620826", level: 3, firstChar: null, children: null },
        { name: "华亭市", code: "620881", level: 3, firstChar: null, children: null },
      ],
      酒泉市: [
        { name: "肃州区", code: "620902", level: 3, firstChar: null, children: null },
        { name: "金塔县", code: "620921", level: 3, firstChar: null, children: null },
        { name: "瓜州县", code: "620922", level: 3, firstChar: null, children: null },
        { name: "肃北蒙古族自治县", code: "620923", level: 3, firstChar: null, children: null },
        { name: "阿克塞哈萨克族自治县", code: "620924", level: 3, firstChar: null, children: null },
        { name: "玉门市", code: "620981", level: 3, firstChar: null, children: null },
        { name: "敦煌市", code: "620982", level: 3, firstChar: null, children: null },
      ],
      庆阳市: [
        { name: "西峰区", code: "621002", level: 3, firstChar: null, children: null },
        { name: "庆城县", code: "621021", level: 3, firstChar: null, children: null },
        { name: "环县", code: "621022", level: 3, firstChar: null, children: null },
        { name: "华池县", code: "621023", level: 3, firstChar: null, children: null },
        { name: "合水县", code: "621024", level: 3, firstChar: null, children: null },
        { name: "正宁县", code: "621025", level: 3, firstChar: null, children: null },
        { name: "宁县", code: "621026", level: 3, firstChar: null, children: null },
        { name: "镇原县", code: "621027", level: 3, firstChar: null, children: null },
      ],
      定西市: [
        { name: "安定区", code: "621102", level: 3, firstChar: null, children: null },
        { name: "通渭县", code: "621121", level: 3, firstChar: null, children: null },
        { name: "陇西县", code: "621122", level: 3, firstChar: null, children: null },
        { name: "渭源县", code: "621123", level: 3, firstChar: null, children: null },
        { name: "临洮县", code: "621124", level: 3, firstChar: null, children: null },
        { name: "漳县", code: "621125", level: 3, firstChar: null, children: null },
        { name: "岷县", code: "621126", level: 3, firstChar: null, children: null },
      ],
      陇南市: [
        { name: "武都区", code: "621202", level: 3, firstChar: null, children: null },
        { name: "成县", code: "621221", level: 3, firstChar: null, children: null },
        { name: "文县", code: "621222", level: 3, firstChar: null, children: null },
        { name: "宕昌县", code: "621223", level: 3, firstChar: null, children: null },
        { name: "康县", code: "621224", level: 3, firstChar: null, children: null },
        { name: "西和县", code: "621225", level: 3, firstChar: null, children: null },
        { name: "礼县", code: "621226", level: 3, firstChar: null, children: null },
        { name: "徽县", code: "621227", level: 3, firstChar: null, children: null },
        { name: "两当县", code: "621228", level: 3, firstChar: null, children: null },
      ],
      临夏回族自治州: [
        { name: "临夏市", code: "622901", level: 3, firstChar: null, children: null },
        { name: "临夏县", code: "622921", level: 3, firstChar: null, children: null },
        { name: "康乐县", code: "622922", level: 3, firstChar: null, children: null },
        { name: "永靖县", code: "622923", level: 3, firstChar: null, children: null },
        { name: "广河县", code: "622924", level: 3, firstChar: null, children: null },
        { name: "和政县", code: "622925", level: 3, firstChar: null, children: null },
        { name: "东乡族自治县", code: "622926", level: 3, firstChar: null, children: null },
        {
          name: "积石山保安族东乡族撒拉族自治县",
          code: "622927",
          level: 3,
          firstChar: null,
          children: null,
        },
      ],
      甘南藏族自治州: [
        { name: "合作市", code: "623001", level: 3, firstChar: null, children: null },
        { name: "临潭县", code: "623021", level: 3, firstChar: null, children: null },
        { name: "卓尼县", code: "623022", level: 3, firstChar: null, children: null },
        { name: "舟曲县", code: "623023", level: 3, firstChar: null, children: null },
        { name: "迭部县", code: "623024", level: 3, firstChar: null, children: null },
        { name: "玛曲县", code: "623025", level: 3, firstChar: null, children: null },
        { name: "碌曲县", code: "623026", level: 3, firstChar: null, children: null },
        { name: "夏河县", code: "623027", level: 3, firstChar: null, children: null },
      ],
    },
    青海: {
      西宁市: [
        { name: "城东区", code: "630102", level: 3, firstChar: null, children: null },
        { name: "城中区", code: "630103", level: 3, firstChar: null, children: null },
        { name: "城西区", code: "630104", level: 3, firstChar: null, children: null },
        { name: "城北区", code: "630105", level: 3, firstChar: null, children: null },
        { name: "湟中区", code: "630106", level: 3, firstChar: null, children: null },
        { name: "大通回族土族自治县", code: "630121", level: 3, firstChar: null, children: null },
        { name: "湟源县", code: "630123", level: 3, firstChar: null, children: null },
      ],
      海东市: [
        { name: "乐都区", code: "630202", level: 3, firstChar: null, children: null },
        { name: "平安区", code: "630203", level: 3, firstChar: null, children: null },
        { name: "民和回族土族自治县", code: "630222", level: 3, firstChar: null, children: null },
        { name: "互助土族自治县", code: "630223", level: 3, firstChar: null, children: null },
        { name: "化隆回族自治县", code: "630224", level: 3, firstChar: null, children: null },
        { name: "循化撒拉族自治县", code: "630225", level: 3, firstChar: null, children: null },
      ],
      海北藏族自治州: [
        { name: "门源回族自治县", code: "632221", level: 3, firstChar: null, children: null },
        { name: "祁连县", code: "632222", level: 3, firstChar: null, children: null },
        { name: "海晏县", code: "632223", level: 3, firstChar: null, children: null },
        { name: "刚察县", code: "632224", level: 3, firstChar: null, children: null },
      ],
      黄南藏族自治州: [
        { name: "同仁县", code: "632321", level: 3, firstChar: null, children: null },
        { name: "尖扎县", code: "632322", level: 3, firstChar: null, children: null },
        { name: "泽库县", code: "632323", level: 3, firstChar: null, children: null },
        { name: "河南蒙古族自治县", code: "632324", level: 3, firstChar: null, children: null },
      ],
      海南藏族自治州: [
        { name: "共和县", code: "632521", level: 3, firstChar: null, children: null },
        { name: "同德县", code: "632522", level: 3, firstChar: null, children: null },
        { name: "贵德县", code: "632523", level: 3, firstChar: null, children: null },
        { name: "兴海县", code: "632524", level: 3, firstChar: null, children: null },
        { name: "贵南县", code: "632525", level: 3, firstChar: null, children: null },
      ],
      果洛藏族自治州: [
        { name: "玛沁县", code: "632621", level: 3, firstChar: null, children: null },
        { name: "班玛县", code: "632622", level: 3, firstChar: null, children: null },
        { name: "甘德县", code: "632623", level: 3, firstChar: null, children: null },
        { name: "达日县", code: "632624", level: 3, firstChar: null, children: null },
        { name: "久治县", code: "632625", level: 3, firstChar: null, children: null },
        { name: "玛多县", code: "632626", level: 3, firstChar: null, children: null },
      ],
      玉树藏族自治州: [
        { name: "玉树市", code: "632701", level: 3, firstChar: null, children: null },
        { name: "杂多县", code: "632722", level: 3, firstChar: null, children: null },
        { name: "称多县", code: "632723", level: 3, firstChar: null, children: null },
        { name: "治多县", code: "632724", level: 3, firstChar: null, children: null },
        { name: "囊谦县", code: "632725", level: 3, firstChar: null, children: null },
        { name: "曲麻莱县", code: "632726", level: 3, firstChar: null, children: null },
      ],
      海西蒙古族藏族自治州: [
        { name: "格尔木市", code: "632801", level: 3, firstChar: null, children: null },
        { name: "德令哈市", code: "632802", level: 3, firstChar: null, children: null },
        { name: "茫崖市", code: "632803", level: 3, firstChar: null, children: null },
        { name: "乌兰县", code: "632821", level: 3, firstChar: null, children: null },
        { name: "都兰县", code: "632822", level: 3, firstChar: null, children: null },
        { name: "天峻县", code: "632823", level: 3, firstChar: null, children: null },
        { name: "大柴旦行政委员会", code: "632857", level: 3, firstChar: null, children: null },
      ],
    },
    宁夏: {
      银川市: [
        { name: "兴庆区", code: "640104", level: 3, firstChar: null, children: null },
        { name: "西夏区", code: "640105", level: 3, firstChar: null, children: null },
        { name: "金凤区", code: "640106", level: 3, firstChar: null, children: null },
        { name: "永宁县", code: "640121", level: 3, firstChar: null, children: null },
        { name: "贺兰县", code: "640122", level: 3, firstChar: null, children: null },
        { name: "灵武市", code: "640181", level: 3, firstChar: null, children: null },
      ],
      石嘴山市: [
        { name: "大武口区", code: "640202", level: 3, firstChar: null, children: null },
        { name: "惠农区", code: "640205", level: 3, firstChar: null, children: null },
        { name: "平罗县", code: "640221", level: 3, firstChar: null, children: null },
      ],
      吴忠市: [
        { name: "利通区", code: "640302", level: 3, firstChar: null, children: null },
        { name: "红寺堡区", code: "640303", level: 3, firstChar: null, children: null },
        { name: "盐池县", code: "640323", level: 3, firstChar: null, children: null },
        { name: "同心县", code: "640324", level: 3, firstChar: null, children: null },
        { name: "青铜峡市", code: "640381", level: 3, firstChar: null, children: null },
      ],
      固原市: [
        { name: "原州区", code: "640402", level: 3, firstChar: null, children: null },
        { name: "西吉县", code: "640422", level: 3, firstChar: null, children: null },
        { name: "隆德县", code: "640423", level: 3, firstChar: null, children: null },
        { name: "泾源县", code: "640424", level: 3, firstChar: null, children: null },
        { name: "彭阳县", code: "640425", level: 3, firstChar: null, children: null },
      ],
      中卫市: [
        { name: "沙坡头区", code: "640502", level: 3, firstChar: null, children: null },
        { name: "中宁县", code: "640521", level: 3, firstChar: null, children: null },
        { name: "海原县", code: "640522", level: 3, firstChar: null, children: null },
      ],
    },
    新疆: {
      乌鲁木齐市: [
        { name: "天山区", code: "650102", level: 3, firstChar: null, children: null },
        { name: "沙依巴克区", code: "650103", level: 3, firstChar: null, children: null },
        { name: "新市区", code: "650104", level: 3, firstChar: null, children: null },
        { name: "水磨沟区", code: "650105", level: 3, firstChar: null, children: null },
        { name: "头屯河区", code: "650106", level: 3, firstChar: null, children: null },
        { name: "达坂城区", code: "650107", level: 3, firstChar: null, children: null },
        { name: "米东区", code: "650109", level: 3, firstChar: null, children: null },
        { name: "乌鲁木齐县", code: "650121", level: 3, firstChar: null, children: null },
      ],
      克拉玛依市: [
        { name: "独山子区", code: "650202", level: 3, firstChar: null, children: null },
        { name: "克拉玛依区", code: "650203", level: 3, firstChar: null, children: null },
        { name: "白碱滩区", code: "650204", level: 3, firstChar: null, children: null },
        { name: "乌尔禾区", code: "650205", level: 3, firstChar: null, children: null },
      ],
      吐鲁番市: [
        { name: "高昌区", code: "650402", level: 3, firstChar: null, children: null },
        { name: "鄯善县", code: "650421", level: 3, firstChar: null, children: null },
        { name: "托克逊县", code: "650422", level: 3, firstChar: null, children: null },
      ],
      哈密市: [
        { name: "伊州区", code: "650502", level: 3, firstChar: null, children: null },
        { name: "巴里坤哈萨克自治县", code: "650521", level: 3, firstChar: null, children: null },
        { name: "伊吾县", code: "650522", level: 3, firstChar: null, children: null },
      ],
      昌吉回族自治州: [
        { name: "昌吉市", code: "652301", level: 3, firstChar: null, children: null },
        { name: "阜康市", code: "652302", level: 3, firstChar: null, children: null },
        { name: "呼图壁县", code: "652323", level: 3, firstChar: null, children: null },
        { name: "玛纳斯县", code: "652324", level: 3, firstChar: null, children: null },
        { name: "奇台县", code: "652325", level: 3, firstChar: null, children: null },
        { name: "吉木萨尔县", code: "652327", level: 3, firstChar: null, children: null },
        { name: "木垒哈萨克自治县", code: "652328", level: 3, firstChar: null, children: null },
      ],
      博尔塔拉蒙古自治州: [
        { name: "博乐市", code: "652701", level: 3, firstChar: null, children: null },
        { name: "阿拉山口市", code: "652702", level: 3, firstChar: null, children: null },
        { name: "精河县", code: "652722", level: 3, firstChar: null, children: null },
        { name: "温泉县", code: "652723", level: 3, firstChar: null, children: null },
      ],
      巴音郭楞蒙古自治州: [
        { name: "库尔勒市", code: "652801", level: 3, firstChar: null, children: null },
        { name: "轮台县", code: "652822", level: 3, firstChar: null, children: null },
        { name: "尉犁县", code: "652823", level: 3, firstChar: null, children: null },
        { name: "若羌县", code: "652824", level: 3, firstChar: null, children: null },
        { name: "且末县", code: "652825", level: 3, firstChar: null, children: null },
        { name: "焉耆回族自治县", code: "652826", level: 3, firstChar: null, children: null },
        { name: "和静县", code: "652827", level: 3, firstChar: null, children: null },
        { name: "和硕县", code: "652828", level: 3, firstChar: null, children: null },
        { name: "博湖县", code: "652829", level: 3, firstChar: null, children: null },
        { name: "库尔勒经济技术开发区", code: "652871", level: 3, firstChar: null, children: null },
      ],
      阿克苏地区: [
        { name: "阿克苏市", code: "652901", level: 3, firstChar: null, children: null },
        { name: "库车市", code: "652902", level: 3, firstChar: null, children: null },
        { name: "温宿县", code: "652922", level: 3, firstChar: null, children: null },
        { name: "沙雅县", code: "652924", level: 3, firstChar: null, children: null },
        { name: "新和县", code: "652925", level: 3, firstChar: null, children: null },
        { name: "拜城县", code: "652926", level: 3, firstChar: null, children: null },
        { name: "乌什县", code: "652927", level: 3, firstChar: null, children: null },
        { name: "阿瓦提县", code: "652928", level: 3, firstChar: null, children: null },
        { name: "柯坪县", code: "652929", level: 3, firstChar: null, children: null },
      ],
      克孜勒苏柯尔克孜自治州: [
        { name: "阿图什市", code: "653001", level: 3, firstChar: null, children: null },
        { name: "阿克陶县", code: "653022", level: 3, firstChar: null, children: null },
        { name: "阿合奇县", code: "653023", level: 3, firstChar: null, children: null },
        { name: "乌恰县", code: "653024", level: 3, firstChar: null, children: null },
      ],
      喀什地区: [
        { name: "喀什市", code: "653101", level: 3, firstChar: null, children: null },
        { name: "疏附县", code: "653121", level: 3, firstChar: null, children: null },
        { name: "疏勒县", code: "653122", level: 3, firstChar: null, children: null },
        { name: "英吉沙县", code: "653123", level: 3, firstChar: null, children: null },
        { name: "泽普县", code: "653124", level: 3, firstChar: null, children: null },
        { name: "莎车县", code: "653125", level: 3, firstChar: null, children: null },
        { name: "叶城县", code: "653126", level: 3, firstChar: null, children: null },
        { name: "麦盖提县", code: "653127", level: 3, firstChar: null, children: null },
        { name: "岳普湖县", code: "653128", level: 3, firstChar: null, children: null },
        { name: "伽师县", code: "653129", level: 3, firstChar: null, children: null },
        { name: "巴楚县", code: "653130", level: 3, firstChar: null, children: null },
        {
          name: "塔什库尔干塔吉克自治县",
          code: "653131",
          level: 3,
          firstChar: null,
          children: null,
        },
      ],
      和田地区: [
        { name: "和田市", code: "653201", level: 3, firstChar: null, children: null },
        { name: "和田县", code: "653221", level: 3, firstChar: null, children: null },
        { name: "墨玉县", code: "653222", level: 3, firstChar: null, children: null },
        { name: "皮山县", code: "653223", level: 3, firstChar: null, children: null },
        { name: "洛浦县", code: "653224", level: 3, firstChar: null, children: null },
        { name: "策勒县", code: "653225", level: 3, firstChar: null, children: null },
        { name: "于田县", code: "653226", level: 3, firstChar: null, children: null },
        { name: "民丰县", code: "653227", level: 3, firstChar: null, children: null },
      ],
      伊犁哈萨克自治州: [
        { name: "伊宁市", code: "654002", level: 3, firstChar: null, children: null },
        { name: "奎屯市", code: "654003", level: 3, firstChar: null, children: null },
        { name: "霍尔果斯市", code: "654004", level: 3, firstChar: null, children: null },
        { name: "伊宁县", code: "654021", level: 3, firstChar: null, children: null },
        { name: "察布查尔锡伯自治县", code: "654022", level: 3, firstChar: null, children: null },
        { name: "霍城县", code: "654023", level: 3, firstChar: null, children: null },
        { name: "巩留县", code: "654024", level: 3, firstChar: null, children: null },
        { name: "新源县", code: "654025", level: 3, firstChar: null, children: null },
        { name: "昭苏县", code: "654026", level: 3, firstChar: null, children: null },
        { name: "特克斯县", code: "654027", level: 3, firstChar: null, children: null },
        { name: "尼勒克县", code: "654028", level: 3, firstChar: null, children: null },
      ],
      塔城地区: [
        { name: "塔城市", code: "654201", level: 3, firstChar: null, children: null },
        { name: "乌苏市", code: "654202", level: 3, firstChar: null, children: null },
        { name: "额敏县", code: "654221", level: 3, firstChar: null, children: null },
        { name: "沙湾县", code: "654223", level: 3, firstChar: null, children: null },
        { name: "托里县", code: "654224", level: 3, firstChar: null, children: null },
        { name: "裕民县", code: "654225", level: 3, firstChar: null, children: null },
        { name: "和布克赛尔蒙古自治县", code: "654226", level: 3, firstChar: null, children: null },
      ],
      阿勒泰地区: [
        { name: "阿勒泰市", code: "654301", level: 3, firstChar: null, children: null },
        { name: "布尔津县", code: "654321", level: 3, firstChar: null, children: null },
        { name: "富蕴县", code: "654322", level: 3, firstChar: null, children: null },
        { name: "福海县", code: "654323", level: 3, firstChar: null, children: null },
        { name: "哈巴河县", code: "654324", level: 3, firstChar: null, children: null },
        { name: "青河县", code: "654325", level: 3, firstChar: null, children: null },
        { name: "吉木乃县", code: "654326", level: 3, firstChar: null, children: null },
      ],
      石河子市: [],
      阿拉尔市: [],
      图木舒克市: [],
      五家渠市: [],
      北屯市: [],
      铁门关市: [],
      双河市: [],
      可克达拉市: [],
      昆玉市: [],
      胡杨河市: [],
    },
    台湾: {
      台中市: [
        { name: "中区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "东区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "南区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "西区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "北区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "北屯区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "西屯区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "南屯区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "太平区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "大里区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "雾峰区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "乌日区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "丰原区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "后里区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "潭子区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "大雅区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "神冈区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "石冈区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "东势区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "新社区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "和平区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "大肚区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "沙鹿区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "龙井区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "梧栖区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "清水区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "大甲区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "外埔区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "大安区", code: "710000", level: 3, firstChar: null, children: null },
      ],
      高雄市: [
        { name: "楠梓区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "左营区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "鼓山区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "三民区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "盐埕区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "前金区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "新兴区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "苓雅区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "前镇区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "旗津区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "小港区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "凤山区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "大寮区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "鸟松区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "林园区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "仁武区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "大树区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "大社区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "冈山区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "路竹区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "桥头区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "梓官区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "弥陀区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "永安区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "燕巢区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "阿莲区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "茄萣区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "湖内区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "田寮区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "旗山区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "美浓区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "内门区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "杉林区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "甲仙区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "六龟区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "茂林区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "桃源区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "那玛夏区", code: "710000", level: 3, firstChar: null, children: null },
      ],
      新北市: [
        { name: "板桥区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "汐止区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "新店区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "永和区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "中和区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "土城区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "树林区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "三重区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "新庄区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "芦洲区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "瑞芳区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "三峡区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "莺歌区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "淡水区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "万里区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "金山区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "深坑区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "石碇区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "平溪区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "双溪区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "贡寮区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "坪林区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "乌来区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "泰山区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "林口区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "五股区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "八里区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "三芝区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "石门区", code: "710000", level: 3, firstChar: null, children: null },
      ],
      桃园市: [
        { name: "桃园区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "中坜区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "平镇区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "八德区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "杨梅区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "芦竹区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "大溪区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "龙潭区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "龟山区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "大园区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "观音区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "新屋区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "复兴区", code: "710000", level: 3, firstChar: null, children: null },
      ],
      台北市: [
        { name: "内湖区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "南港区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "中正区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "万华区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "大同区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "中山区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "松山区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "大安区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "信义区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "文山区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "士林区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "北投区", code: "710000", level: 3, firstChar: null, children: null },
      ],
      台南市: [
        { name: "中西区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "东区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "南区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "北区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "安平区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "安南区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "永康区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "归仁区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "新化区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "左镇区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "玉井区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "楠西区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "南化区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "仁德区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "关庙区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "龙崎区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "官田区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "麻豆区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "佳里区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "西港区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "七股区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "将军区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "学甲区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "北门区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "新营区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "后壁区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "白河区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "东山区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "六甲区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "下营区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "柳营区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "盐水区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "善化区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "大内区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "山上区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "新市区", code: "710000", level: 3, firstChar: null, children: null },
        { name: "安定区", code: "710000", level: 3, firstChar: null, children: null },
      ],
    },
    香港: {
      新界: [
        { name: "北区", code: "810000", level: 3, firstChar: null, children: null },
        { name: "大埔区", code: "810000", level: 3, firstChar: null, children: null },
        { name: "沙田区", code: "810000", level: 3, firstChar: null, children: null },
        { name: "西贡区", code: "810000", level: 3, firstChar: null, children: null },
        { name: "荃湾区", code: "810000", level: 3, firstChar: null, children: null },
        { name: "屯门区", code: "810000", level: 3, firstChar: null, children: null },
        { name: "元朗区", code: "810000", level: 3, firstChar: null, children: null },
        { name: "葵青区", code: "810000", level: 3, firstChar: null, children: null },
        { name: "离岛区", code: "810000", level: 3, firstChar: null, children: null },
      ],
      香港岛: [
        { name: "中西区", code: "810000", level: 3, firstChar: null, children: null },
        { name: "湾仔区", code: "810000", level: 3, firstChar: null, children: null },
        { name: "东区", code: "810000", level: 3, firstChar: null, children: null },
        { name: "南区", code: "810000", level: 3, firstChar: null, children: null },
      ],
      九龙半岛: [
        { name: "油尖旺区", code: "810000", level: 3, firstChar: null, children: null },
        { name: "深水埗区", code: "810000", level: 3, firstChar: null, children: null },
        { name: "九龙城区", code: "810000", level: 3, firstChar: null, children: null },
        { name: "黄大仙区", code: "810000", level: 3, firstChar: null, children: null },
        { name: "观塘区", code: "810000", level: 3, firstChar: null, children: null },
      ],
    },
    澳门: {
      澳门半岛: [
        { name: "花地玛堂区", code: "820000", level: 3, firstChar: null, children: null },
        { name: "圣安多尼堂区", code: "820000", level: 3, firstChar: null, children: null },
        { name: "大堂区", code: "820000", level: 3, firstChar: null, children: null },
        { name: "望德堂区", code: "820000", level: 3, firstChar: null, children: null },
        { name: "风顺堂区", code: "820000", level: 3, firstChar: null, children: null },
      ],
      路氹城: [{ name: "路氹城", code: "820000", level: 3, firstChar: null, children: null }],
      离岛: [
        { name: "嘉模堂区", code: "820000", level: 3, firstChar: null, children: null },
        { name: "圣方济各堂区", code: "820000", level: 3, firstChar: null, children: null },
      ],
    },
  },
};
