import React, { Component } from "react";
import {
    Row,
    Breadcrumb,
    Button,
    Form,
    message,
    DatePicker,
    Modal,
    Input,
    Select,
    Col,
    Radio,
    Popover,
    Table,
    InputNumber,
    Cascader,
    TreeSelect,
} from "antd";
import { AreaSelect, popoverTable } from "../../common";
import Filter from "../../common/filter";
import { connect } from "react-redux";
import { PlusOutlined, InfoCircleFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Axios } from "../../axios";
import moment from "moment";
const { TextArea } = Input;
const { confirm } = Modal;
const { Option } = Select;
const RangePicker = DatePicker.RangePicker;
let sendBody = {};
let StatuspageNum = 1;
class ElectronicContract extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tData: [],
            loading: false,
            totalNum: 0,
            ButtonLoading: false,
            signUrl: '',
            currentPage: window.routerCache.ElectronicContract.currentPage || 1,
        };
        sendBody = window.routerCache.ElectronicContract.sendBody || {};
    }

    // 获取表格数据
    refreshTableByData = (data, totalNum, currentPage) => {
        this.setState({
            tData: data,
            totalNum: totalNum,
            currentPage: currentPage,
        });
    };
    // 获取表格数据
    refreshTable = async () => {
        try {
            this.setState({
                loading: true,
            });
            let send = sendBody;
            // send.workType = window.routerCache.ElectronicContract.send_workType || '';
            send.pageNum = this.state.currentPage + "";
            send.pageSize = "10";
            let res = await Axios("get", "/esign/page", send);
            this.setState({
                loading: false,
                tData: res.data.dataList,
                totalNum: res.data.totalNum,
            });
        } catch (err) {
            // message.error(err);
            this.setState({ loading: false });
        }
    };

    handleTableChange = async (pagination) => {
        this.setState({ loading: true });
        try {
            let send = sendBody;
            send.pageNum = pagination.current + "";
            send.pageSize = "10";
            let res = await Axios("get", "/esign/page", send);
            this.setState(
                {
                    loading: false,
                    tData: res.data.dataList,
                    totalNum: res.data.totalNum,
                    currentPage: pagination.current,
                },
                this.polling
            );
        } catch (err) {
            // message.error(err.message);
            this.setState({ loading: false });
        }
    };

    // 组件渲染后获取外界数据(GET)
    componentDidMount () {
        this.refreshTable();
        window.winhcFn.changeDept = () => {
            this.setState(
                {
                    currentPage: 1,
                },
                () => {
                    this.refreshTable();
                }
            );
        };
    }
    showConfirm = async (id) => {
        let { signUrl, ButtonLoading } = this.state
        if (ButtonLoading) {
            return
        }
        message.loading('正在获取', 10)
        try {
            this.setState({ ButtonLoading: true });
            let res = await Axios("get", "/esign/signUrl/" + id, { id });
            if (res.code == '200') {
                const modal = Modal.info({
                    title: '合同催办',
                    icon: null,
                    width: 520,
                    maskClosable: true,
                    content: <div>
                        <p>1.合作商打开合同签署页面，以下是合同签署页面的链接，复制链接后在浏览器中打开即可（本链接不可发送给客户）</p>
                        <p>{res.data || '获取失败'}</p>
                        <Button disabled={!(res.data)} type="primary" onClick={() => {
                            navigator.clipboard.writeText(res.data).then(() => {
                                message.success('文本已经成功复制到剪切板');
                                modal.destroy();
                            }).catch(err => {
                                message.error('复制失败请手动复制');
                            });
                        }}>复制链接</Button>
                        <p>2.合作商打开合同签署页面后，在该页面合作商可以通过“催办”按钮给客户发送合同催办短信，也可以通过“复制链接”的形式，把催办链接复制后发送给客户</p>
                        <p>3.客户收到催办短信或者合作商发送的催办链接后，通过浏览器打开催办链接，签署合同即可</p>
                    </div>,
                    okText: '关闭',
                    okButtonProps: {
                        type: "",
                        // loading: this.state.ButtonLoading,
                    },
                    onOk () { },
                    onCancel () {
                        console.log('Cancel');
                    },
                });
            }
            message.destroy()
            this.setState({ ButtonLoading: false });
        } catch (err) {
            // message.error(err.message);
            this.setState({ ButtonLoading: false });
        }
    };
    componentWillUnmount () {
        window.routerCache.ElectronicContract.currentPage =
            this.state.currentPage;
        window.routerCache.ElectronicContract.sendBody = sendBody;
    }
    render () {
        const { refreshTable } = this;
        let styleS = {
            display: "flex",
            background: "red",
            color: "white",
            borderRadius: "50%",
            width: "18px",
            height: "18px",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "12px",
            marginTop: "-5px",
        };
        let columns = [
            popoverTable(8, {
                title: "合同名称",
                dataIndex: "contractName",
                fixed: "left",
            }),
            {
                title: "客户名称",
                dataIndex: "custName",
                // width: 210,
                fixed: "left",
                render: (text, r) => (
                    <div style={{ display: "flex", cursor: "pointer" }}>
                        {text && text.length > 11 ? (
                            <Popover
                                content={
                                    <div
                                        style={{
                                            padding: "10px",
                                            maxWidth: "500px",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {text}
                                    </div>
                                }
                            >
                                <span>{Filter.strLength(text, 11)}</span>
                            </Popover>
                        ) : (
                            text
                        )}{" "}
                        {r.transSign == "1" ? (
                            <span style={styleS}>转</span>
                        ) : (
                            ""
                        )}
                    </div>
                ),
            },
            {
                title: "套餐",
                dataIndex: "specification",
                // width: 120,
                render: (t) => Filter.legalspecification(t),
            },
            {
                title: "价格",
                dataIndex: "price",
                // width: 120,
                render: (t) => t + "元",
            },
            {
                title: "申请人",
                dataIndex: "applyName",
                // width: 120,
            },
            {
                title: "申请时间",
                dataIndex: "createdTime",
                // width: 120,
            },
            {
                title: "状态",
                dataIndex: "esignStatus",
                // width: 60,
                render: (t) => Filter.ElectroniCesignStatus(t),
            },
            {
                title: "操作",
                dataIndex: "id",
                // width: 50,
                fixed: "right",
                render: (text, record) => (
                    <span>
                        <Link to={"/ElectronicContractDetail/" + text}>查看</Link>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        {record.esignStatus == '1' ? <a onClick={() => this.showConfirm(text)}>合同催办</a> : '合同催办'}
                    </span>
                ),
            },
        ];
        return (
            <div className="pageContainer">
                <Row className="headNav">
                    <Col span={24}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>电子合同</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col className="title" span={24}>
                        <span className="name">电子合同</span>
                        <AddBroker refreshTable={this.refreshTable} />
                    </Col>
                </Row>
                <div className="scrollContainer">
                    <div className="scrollMain">
                        <Search refreshTableByData={this.refreshTableByData} />

                        <div style={{ backgroundColor: "#fff" }}>
                            <Row
                                type="flex"
                                justify="space-between"
                                align="middle"
                            ></Row>

                            <Table
                                scroll={{ x: 'max-content' }}
                                dataSource={this.state.tData}
                                columns={columns}
                                size="middle"
                                pagination={{
                                    size: "large",
                                    showQuickJumper: true,
                                    pageSize: 10,
                                    total: parseInt(this.state.totalNum),
                                    current: Number(this.state.currentPage),
                                }}
                                loading={this.state.loading}
                                onChange={this.handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return state;
    },
    (dispatch) => ({})
)(ElectronicContract);

class SearchM extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            productList: [],
        };
    }

    // 查询
    query = async () => {
        let _this = this;
        sendBody = this.formRef.current.getFieldsValue();
        _this.props.refreshTableByData([], 1, 1);
        sendBody.pageNum = "1";
        sendBody.pageSize = "10";
        if (sendBody.date1 && sendBody.date1.length) {
            sendBody.startTime = moment(sendBody.date1[0]).format("YYYY-MM-DD");
            sendBody.endTime = moment(sendBody.date1[1]).format("YYYY-MM-DD");
            delete sendBody.date1;
        } else {
            delete sendBody.endTime;
            delete sendBody.startTime;
            delete sendBody.date1;
        }
        try {
            let res = await Axios("get", "/esign/page", sendBody);
            _this.props.refreshTableByData(
                res.data.dataList,
                res.data.totalNum,
                1
            );
        } catch (err) {
            // message.error(err.message);
        }
    };
    clearOptions = async () => {
        this.formRef.current.resetFields();
        sendBody = {};
        sendBody.pageNum = "1";
        sendBody.pageSize = "10";
        // sendBody.workType = window.routerCache.ElectronicContract.send_workType || '';
        this.props.refreshTableByData([], 1, 1);

        try {
            let res = await Axios("get", "/esign/page", sendBody);

            this.props.refreshTableByData(
                res.data.dataList,
                res.data.totalNum,
                1
            );
        } catch (err) {
            // message.error(err.message);
        }
    };

    // 组件渲染后获取外界数据(GET)
    componentDidMount () {
        Axios("get", "/order/meal/list", {}).then((val) => {
            console.log(JSON.parse(val.body));
            this.setState({
                productList: JSON.parse(val.body),
            });
        });
    }
    render () {
        /*控制查询按钮状态*/
        const { productList, StatusList } = this.state;
        return (
            <div className="search_like_antD" style={{ marginTop: "-8px" }}>
                <Form
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    ref={this.formRef}
                >
                    <Row type="flex" align="middle">
                        <Col span="8">
                            <Form.Item
                                label="合同名称"
                                style={{ width: "100%" }}
                                name="contractName"
                                initialValue={sendBody.contractName}
                            >
                                <Input
                                    placeholder="请输入"
                                    type="text"
                                    style={{ width: "80%" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span="8">
                            <Form.Item
                                label="客户名称"
                                style={{ width: "100%" }}
                                name="custName"
                                initialValue={sendBody.custName}
                            >
                                <Input
                                    placeholder="请输入"
                                    type="text"
                                    style={{ width: "80%" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span="8">
                            <Form.Item
                                label="套餐"
                                style={{ width: "100%" }}
                                name="specification"
                                rules={[
                                    {
                                        required: false,
                                        message: "请选择套餐",
                                    },
                                ]}
                                initialValue={sendBody.specification}
                            >
                                <Select
                                    placeholder="请选择"
                                    allowClear
                                    style={{ width: "80%" }}
                                >
                                    {productList.map((item) => (
                                        <Option key={item.specification}>
                                            {item.productDetail}{" "}
                                            {item.serviceMode}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>{" "}
                    <Row type="flex" align="middle">
                        <Col span="8">
                            <Form.Item
                                label="价格"
                                style={{ width: "100%" }}
                                name="price"
                                initialValue={sendBody.price}
                            >
                                <Select
                                    placeholder="请选择"
                                    allowClear
                                    style={{ width: "80%" }}
                                >
                                    <Option key="3980">3980元</Option>
                                    <Option key="5980">5980元</Option>
                                    <Option key="7980">7980元</Option>
                                    <Option key="8800">8800元</Option>
                                    <Option key="8980">8980元</Option>
                                    <Option key="9800">9800元</Option>
                                    <Option key="10800">10800元</Option>
                                    <Option key="11980">11980元</Option>
                                    <Option key="19800">19800元</Option>
                                    <Option key="21800">21800元</Option>
                                    <Option key="23800">23800元</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span="8">
                            <Form.Item
                                label="申请时间"
                                style={{ width: "100%" }}
                                initialValue={sendBody.date1}
                                name="date1"
                            >
                                <RangePicker
                                    style={{ width: "80%" }}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                        <Col span="8">
                            <Form.Item
                                label="状态"
                                style={{ width: "100%" }}
                                name="esignStatus"
                            >
                                <Select
                                    placeholder="请选择"
                                    allowClear
                                    style={{ width: "80%" }}
                                >
                                    <Option key="1">签署中</Option>
                                    <Option key="2">已完成</Option>
                                    <Option key="3">已撤销</Option>
                                    <Option key="5">已过期</Option>
                                    <Option key="7">已拒签</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row type="flex" align="middle">
                        <Col span="8">
                            <Form.Item
                                label="申请人"
                                style={{ width: "100%" }}
                                name="applyName"
                            >
                                <Input
                                    placeholder="请输入"
                                    type="text"
                                    style={{ width: "80%" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span="8"></Col>
                        <Col span={8}>
                            <Row
                                type="flex"
                                justify="end"
                                align="middle"
                                style={{ width: "85%", height: "30px" }}
                            >
                                <Form.Item style={{ marginRight: "16px" }}>
                                    <Button type="primary" onClick={this.query}>
                                        查询
                                    </Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button onClick={this.clearOptions}>
                                        重置
                                    </Button>
                                </Form.Item>
                            </Row>
                        </Col>
                    </Row>
                    <div>
                        <Row
                            type="flex"
                            justify="space-between"
                            align="middle"
                            style={
                                window.routerCache.ElectronicContract
                                    .workTypeStr
                                    ? {
                                        paddingBottom: "10px",
                                    }
                                    : { paddingBottom: "40px" }
                            }
                        ></Row>
                    </div>
                </Form>
                <style>{`
               .search_like_antD .ant-form ant-form-inline .ant-row-flex .ant-row-flex-space-between .ant-row-flex-middle{
                    padding: 0px !important;
                }
                `}</style>
            </div>
        );
    }
}

let Search = connect(
    (state) => {
        return state;
    },
    (dispatch) => ({})
)(SearchM);
let timeout;
function fetch (value, callback, callback1) {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }

    async function fake () {
        let res = await Axios("get", "/order/detail/" + value, {});
        if (res.code === "200") {
            callback(res.data);
        } else {
            callback1();
        }
    }

    timeout = setTimeout(fake, 300);
}
class AddBrokerM extends Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            loading: false,
            confirmLoading: false,
            productList: [],
            PrecheckNum: 0,
            custName: '',
            priceData: {
                legal_basic: [8800, 9800, 10800],
                legal_honour: [19800, 21800, 23800],
                legal_normal: [3980],
                legal_honour_pro: [33800],
            },
            isDisabled: false,
        };
    }

    componentWillMount () { }

    // 单击确定按钮提交表单
    handleSubmit = (e) => {
        const { userRole } = this.props.operInfo;
        e.preventDefault();
        let arr = [
            "address",
            "contractName",
            "custName",
            "email",
            "mobileNo",
            "orderId",
            "price",
            "specification",
            "startDate",
            "linkman",
        ];
        this.formRef.current
            .validateFields(arr)
            .then(async (values) => {
                let sendData = values;
                if (sendData.startDate) {
                    sendData.startDate = moment(sendData.startDate).format(
                        "YYYY-MM-DD"
                    );
                }
                sendData.term = sendData.specification[1].split("-")[2];
                // sendData.price = sendData.specification[1].split("-")[0];
                sendData.specification =
                    sendData.specification[1].split("-")[1];
                if (sendData.price) {
                    sendData.price = sendData.price * 1;
                }
                if (sendData.specification && sendData.specification.includes('legal_customized')) {
                    this.onConfirm()
                    return
                }
                console.log(sendData, "sendDatasendData");
                try {
                    this.setState({ confirmLoading: true });
                    let res = await Axios("post", "/esign", sendData);
                    if (res.code == "200" || res.code == "00") {
                        this.props.refreshTable();
                        this.formRef.current.resetFields();
                        message.success("提交成功，请通知客户签章。");
                        this.setState({
                            visible: false,
                            confirmLoading: false,
                            isDisabled: false,
                            PrecheckNum: 0,
                        });
                    } else {
                        message.error(res.message);
                        this.setState({ confirmLoading: false });
                    }
                } catch (err) {
                    message.error(err.message);
                    this.setState({ confirmLoading: false });
                }
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    };
    // 弹出框设置
    showModal = () => {
        this.setState({ visible: true });
        Axios("get", "/order/product/group", {
            allSpec: true //传true任何情况都返回SaaS版
        }).then((val) => {
            console.log(val.data);
            val.data.map((v) => {
                v.name = v.name;
                v.specification = v.name;
                v.winCoinProductVOS.map((i) => {
                    i.name = `${i.productDetail} ${i.costAmt}元·${i.serviceMode}`;
                    i.specification =
                        i.costAmt + "-" + i.specification + "-" + i.serviceMode;
                });
                v.children = v.winCoinProductVOS;
            });
            this.setState({
                productList: val.data,
            });
        });
    };
    onConfirm = () => {
        confirm({
            width: 450,
            title: '暂不支持线上申请 [定制版] 云法务套餐电子合同',
            icon: <InfoCircleFilled style={{ color: '#1775FF' }} />,
            content: '[定制版] 云法务套餐属于一案一议，请向云法务运维经理申请纸质合同或电子合同',
            maskClosable: true,
            onOk () { },
            okText: '确定',
            onCancel () { },
        });
    }
    onChangePackage = (e) => {
        if (e.length) {
            if (e[0] == '定制版') {
                this.onConfirm()
                this.formRef.current.setFieldsValue({ specification: '', price: undefined });
                return
            } else {
                this.formRef.current.setFieldsValue({ price: e[1].split("-")[0] });
            }
        } else {
            this.formRef.current.setFieldsValue({ price: undefined });
        }
    };
    handleCancel = (e) => {
        this.setState({ visible: false });
    };
    handleSelectChange = (value) => {
        console.log("select=" + value);
    };
    getOrderId = (e) => {
        let value = e.target.value || 0;
        fetch(
            value,
            ({ customInfo = {}, orderInfo = {} }) => {
                this.setState({
                    isDisabled: true,
                });
                this.formRef.current.setFieldsValue({
                    custName: customInfo.companyName || undefined,
                    linkman: customInfo.linkman || undefined,
                    mobileNo: customInfo.mobileNo || undefined,
                    specification: orderInfo.specification
                        ? Filter.legalspeciName(
                            orderInfo.specification,
                            orderInfo.costAmt
                        )
                        : undefined,
                    price: orderInfo.costAmt || undefined,
                });
            },
            () => {
                this.setState({
                    isDisabled: false,
                });
            }
        );
    };
    ChangePrecheck = (custName) => {
        let This = this
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }

        async function fake () {
            let res = await Axios("get", "/esign/precheck", { custName });
            if (res.code === "200") {
                This.setState({ PrecheckNum: res.data || 0, custName })
            } else {
            }
        }

        timeout = setTimeout(fake, 300);
    }
    render () {
        const { userRole } = this.props.operInfo;
        const { type, record } = this.props;
        const { productList, custName, isDisabled, PrecheckNum } = this.state;
        return (
            <span>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    style={{ float: "right" }}
                    onClick={this.showModal}
                >
                    新增电子合同
                </Button>

                <Modal
                    title="新增电子合同"
                    open={this.state.visible}
                    onOk={this.handleSubmit}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={() => {
                        this.setState({ visible: false });
                    }}
                >
                    <Form
                        ref={this.formRef}
                        layout="horizontal"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 16 }}
                    >
                        <p style={{ fontSize: '14px', color: '#D9001B' }}>建议先新建云法务客户的订单，后关联电子合同</p>
                        <Form.Item
                            label="订单ID"
                            name="orderId"
                            rules={[
                                {
                                    required: false,
                                    message: "请输入订单ID",
                                },
                            ]}
                        >
                            <Input
                                placeholder="请输入订单ID"
                                type="text"
                                style={{ width: "100%" }}
                                onChange={this.getOrderId}
                            />
                        </Form.Item>{" "}
                        <Form.Item
                            label="合同名称"
                            name="contractName"
                            initialValue={"云法务服务合同"}
                            rules={[
                                {
                                    required: true,
                                    message: "请输入合同名称",
                                },
                                {
                                    pattern: /^((?!\s*$))/,
                                    message: "请输入合同名称",
                                },
                            ]}
                        >
                            <Input
                                maxLength={40}
                                placeholder="请输入合同名称"
                                type="text"
                                style={{ width: "100%" }}
                            />
                        </Form.Item>{" "}
                        <Form.Item
                            label="客户名称"
                            name="custName"
                            rules={[
                                {
                                    required: true,
                                    message: "请输入客户名称",
                                },
                                {
                                    pattern: /^((?!\s*$))/,
                                    message: "请输入客户名称",
                                },
                            ]}
                        >
                            <Input
                                disabled={isDisabled}
                                onChange={(e) => this.ChangePrecheck(e.target.value)}
                                maxLength={40}
                                placeholder="请输入客户名称"
                                type="text"
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                        {(PrecheckNum && custName.replace(/\s/g, "")) && <p style={{ fontSize: '12px', color: '#D9001B', marginLeft: '21%' }}>该企业目前已有 {PrecheckNum} 份电子合同，请核实信息，勿多次新增电子合同。</p> || ''}
                        <Form.Item
                            label="联系地址"
                            name="address"
                            rules={[
                                {
                                    required: true,
                                    message: "请输入联系地址",
                                    whitespace: true,
                                },
                            ]}
                        >
                            <TextArea
                                // rows={2}
                                maxLength={40}
                                autoSize={{ minRows: 2, maxRows: 2 }}
                                placeholder="请输入联系地址"
                                type="text"
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="联系人"
                            name="linkman"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: "请输入联系人",
                                },
                            ]}
                        >
                            <Input
                                disabled={isDisabled}
                                maxLength={40}
                                placeholder="请输入联系人"
                                type="text"
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="联系电话"
                            name="mobileNo"
                            rules={[
                                {
                                    required: true,
                                    message: "联系电话不能为空",
                                },
                                {
                                    message: "联系电话不合法",
                                    pattern: /^((1[0-9]{2})+\d{8})$/,
                                },
                            ]}
                        >
                            <InputNumber
                                disabled={isDisabled}
                                style={{ width: "100%" }}
                                maxLength={11}
                                placeholder="请输入联系电话"
                            />
                        </Form.Item>
                        <Form.Item
                            label="套餐"
                            style={{ width: "100%" }}
                            name="specification"
                            rules={[
                                {
                                    required: true,
                                    message: "请选择套餐",
                                },
                            ]}
                        >
                            <Cascader
                                disabled={isDisabled}
                                fieldNames={{
                                    label: "name",
                                    value: "specification",
                                    children: "children",
                                }}
                                options={productList}
                                onChange={this.onChangePackage}
                                placeholder="请选择"
                            />
                        </Form.Item>
                        <Form.Item
                            label="价格"
                            style={{ width: "100%" }}
                            name="price"
                            rules={[
                                {
                                    required: true,
                                    message: "请选择价格",
                                },
                            ]}
                        >
                            <Input
                                addonAfter="元"
                                disabled
                                style={{ width: "100%" }}
                                maxLength={11}
                                placeholder="请输入价格"
                            />
                        </Form.Item>
                        <Form.Item
                            label="合作日期："
                            name="startDate"
                            initialValue={moment(moment())}
                            rules={[
                                {
                                    required: true,
                                    message: "合作日期不能为空",
                                },
                            ]}
                        >
                            <DatePicker
                                placeholder="请选择合作日期"
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="邮箱"
                            name="email"
                            rules={[
                                {
                                    required: false,
                                    message: "邮箱不能为空",
                                },
                                {
                                    message: "邮箱格式不合法",
                                    pattern:
                                        /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
                                },
                            ]}
                        >
                            <Input
                                style={{ width: "100%" }}
                                placeholder="请输入邮箱"
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </span>
        );
    }
}

let AddBroker = connect(
    (state) => {
        return state;
    },
    (dispatch) => ({})
)(AddBrokerM);
