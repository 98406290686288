/**
 * Created by Administrator on 2016/7/2.
 */
// 初始化状态

let user = {};
if (localStorage.getItem('change_userInfo')) {
    user = JSON.parse(window.localStorage.getItem('change_userInfo'))
}
let userInfoState = {
    ...user
}

// console.log(JSON.parse(window.localStorage.getItem('operInfo')))
export function userInfo(state = userInfoState, action) {
    switch (action.type) {
        case 'change_userInfo':
            localStorage.setItem('legal_userInfo', JSON.stringify(action.operInfo))
            return {
                // ...state,   //三个点是展开符
                ...action.operInfo
            }
        default:
            return { ...state };
    }
}