import React from 'react';
import {Button} from 'antd';
import img404 from "../../assets/images/404.png"
import img403 from "../../assets/images/403.png"
import img500 from "../../assets/images/500.png"

class Err404 extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className={'errPage'}>
                <img src={img404} style={{width:350}}/>
                <div className='textCon' style={{width:280}}>
                    <div className="desc">404</div>
                    <div className='text'>抱歉，您访问的页面不存在</div>
                    <Button type="primary" onClick={()=>{
                        this.props.history.replace('/')
                    }}>返回首页</Button>
                </div>
            </div>
        );
    }
}
class Err403 extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className={'errPage'}>
                <img src={img403} style={{width:260}}/>
                <div className='textCon' style={{width:260}}>
                    <div className="desc">403</div>
                    <div className='text'>抱歉，你无权访问该页面</div>
                    <Button type="primary" onClick={()=>{
                        this.props.history.replace('/')
                    }}>返回首页</Button>
                </div>
            </div>
        );
    }
}
class Err500 extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className={'errPage'}>
                <img src={img500} style={{width:400}}/>
                <div className='textCon' style={{width:350}}>
                    <div className="desc">500</div>
                    <div className='text'>抱歉，服务器出错了</div>
                    <Button type="primary" onClick={()=>{
                        this.props.history.replace('/')
                    }}>返回首页</Button>
                </div>
            </div>
        );
    }
}
export {Err404,Err500,Err403}



