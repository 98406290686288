import { appChannels } from "./index.js";
import moment from "moment";

export default {
  urlGetFileType: url => {
    return url.slice(url.lastIndexOf(".") + 1).toLowerCase();
  },
  urlGetFileName: url => {
    return url.slice(url.lastIndexOf("/") + 1).toLowerCase();
  },
  NumberToFixed: (num, size) => {
    return num
      ? String(Number(num || 0).toFixed(size || 0)).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : "-";
  },
  RangePicker: (date, start, ent, type) => {
    if (date[start] && date[ent]) {
      return [moment(date[start], type), moment(date[ent], type)];
    } else {
      return undefined;
    }
  },
  DownloadImage: imgsrc => {
    //下载图片地址和图片名
    const name = imgsrc.split(".").pop().toLocaleLowerCase();
    const isJpgOrPng = name == "jpg" || name == "png" || name == "gif";
    if (isJpgOrPng) {
      var image = new Image();
      // 解决跨域 Canvas 污染问题,
      image.setAttribute("crossorigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //将图片格式转为base64
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "发票";
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc + "?time=" + Date.now(); //注意，这里是灵魂，否则依旧会产生跨域问题
    } else {
      window.open(imgsrc);
    }
  },
  checkOSSUrl: function (ossUrl) {
    // let old_winhc_oss_url = 'https://winhc.oss-cn-shanghai.aliyuncs.com/';
    // let winhc_oss_url = 'https://winhc.oss-cn-shanghai.aliyuncs.com/';
    //   var tempUrl = ossUrl.replace(window.winhc_oss_url, '').replace(window.old_winhc_oss_url, '');
    var tempUrl = ossUrl
      .replace(window.winhc_oss_url, "")
      .replace(window.old_winhc_oss_url, "")
      .replace("tiangong/", "")
      .replace("mingsheng/", "")
      .replace("crm/", "")
      .replace(/[A-Z|A-z|0-9]{10}\//, "");

    var filename = "";
    if (tempUrl.indexOf("YHC") === 0) {
      //最新直接解析出文件名
      filename = tempUrl.substr(tempUrl.lastIndexOf("/") + 1);
    } else {
      //兼容老版本
      filename = tempUrl.replace(/[A-Z|A-z|0-9]{10}/, "");
    }
    return filename;
  },
  // 计算文件大小函数(保留两位小数),Size为字节大小
  // size：初始文件大小
  getFileSize (size) {
    if (!size) return "";

    var num = 1024.0; //byte

    if (size < num) return size + "B";
    if (size < Math.pow(num, 2)) return (size / num).toFixed(2) + "K"; //kb
    if (size < Math.pow(num, 3)) return (size / Math.pow(num, 2)).toFixed(2) + "M"; //M
    if (size < Math.pow(num, 4)) return (size / Math.pow(num, 3)).toFixed(2) + "G"; //G
    return (size / Math.pow(num, 4)).toFixed(2) + "T"; //T
  },
  dateForDate: function (_date) {
    let str = "",
      _year = _date.getFullYear(),
      _mon = _date.getMonth() + 1 < 10 ? "0" + (_date.getMonth() + 1) : _date.getMonth() + 1,
      _day = _date.getDate() < 10 ? "0" + _date.getDate() : _date.getDate();
    str = _year + "-" + _mon + "-" + _day;

    return str;
  },
  dateForDateJudge: function (_date, num) {
    let str = "",
      _year = _date.getFullYear(),
      _mon = _date.getMonth() + 1 < 10 ? "0" + (_date.getMonth() + 1) : _date.getMonth() + 1,
      _day = _date.getDate() < 10 ? "0" + _date.getDate() : _date.getDate();
    if (num) {
      _year = _year - num;
    }
    str = _year + "-" + _mon + "-" + _day;

    return str;
  },
  date: function (input) {
    let str = "";
    if (input) {
      str = input.substring(0, 4) + "-" + input.substring(4, 6) + "-" + input.substring(6, 8);
    }
    return str;
  },
  dateTime: function (input) {
    let str = "";
    if (input) {
      str =
        input.substring(0, 4) +
        "-" +
        input.substring(4, 6) +
        "-" +
        input.substring(6, 8) +
        " " +
        input.substring(8, 10) +
        ":" +
        input.substring(10, 12) +
        ":" +
        input.substring(12, 14);
    }
    return str;
  },
  dateTime_HM: function (input) {
    let str = "";
    if (input) {
      str =
        input.substring(0, 4) +
        "-" +
        input.substring(4, 6) +
        "-" +
        input.substring(6, 8) +
        " " +
        input.substring(8, 10) +
        ":" +
        input.substring(10, 12);
    }
    return str;
  },
  time: function (input) {
    let str = "";
    if (input) {
      str = input.substring(0, 2) + ":" + input.substring(2, 4) + ":" + input.substring(4, 6);
    }
    return str;
  },
  time_HM: function (input) {
    let str = "";
    if (input) {
      str = input.substring(0, 2) + ":" + input.substring(2, 4);
    }
    return str;
  },
  useTime2hour: function (input, type) {
    let str = "";
    if (input) {
      input = Number(input);
      if (type == "ms") {
        input = input / 1000 / 60;
      } else if (type == "s") {
        input = input / 60;
      }
      if (input >= 60) {
        str = parseInt((input * 1) / 60) + "小时" + parseInt((input * 1) % 60) + "分钟";
      } else {
        str = parseInt(input) + "分钟";
      }
    }
    return str;
  },
  filterPho: function (pho) {
    //手机号3-4-4
    let formatPho = "";
    if (pho) {
      if (pho.length == 11) {
        formatPho = pho.substring(0, 3) + " " + pho.substring(3, 7) + " " + pho.substring(7, 11);
      }
    }
    return formatPho;
  },
  getCurNextDate: function (AddDayCount) {
    const week = [];
    let dd = new Date();
    dd.setDate(dd.getDate() + AddDayCount); //获取AddDayCount天后的日期
    let y = dd.getFullYear() + "-";
    let m = (dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1) + "-";
    let d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
    return y + m + d;
  },
  appChannel (t) {
    for (let i = 0; i < appChannels.length; i++) {
      if (appChannels[i].key == t) {
        return appChannels[i].val;
      }
    }
    return t || "";
  },
  timetrans: function (date, dayOrTime) {
    if (!date) {
      return "";
    }
    var _date = new Date(date); //如果date为13位不需要乘1000
    var Y = _date.getFullYear();
    var M = _date.getMonth() + 1 < 10 ? "0" + (_date.getMonth() + 1) : _date.getMonth() + 1;
    var D = _date.getDate() < 10 ? "0" + _date.getDate() : _date.getDate();
    var h = _date.getHours() < 10 ? "0" + _date.getHours() : _date.getHours();
    var m = _date.getMinutes() < 10 ? "0" + _date.getMinutes() : _date.getMinutes();
    var s = _date.getSeconds() < 10 ? "0" + _date.getSeconds() : _date.getSeconds();
    if (dayOrTime === "day") {
      return Y + "-" + M + "-" + D;
    } else if (dayOrTime === "pattern") {
      return "" + Y + "" + M + "" + D + "";
    } else if (dayOrTime === "time") {
      return h + ":" + m + ":" + s;
    } else if (dayOrTime === "time_HM") {
      return Y + "-" + M + "-" + D + " " + h + ":" + m;
    } else if (dayOrTime == "month") {
      return Y + M;
    } else {
      return Y + "-" + M + "-" + D + " " + h + ":" + m + ":" + s;
    }
  },
  timetransStr: function (date) {
    if (!date) {
      return "";
    }
    var _date = new Date(date); //如果date为13位不需要乘1000
    var Y = _date.getFullYear();
    var M = _date.getMonth() + 1 < 10 ? "0" + (_date.getMonth() + 1) : _date.getMonth() + 1;
    var D = _date.getDate() < 10 ? "0" + _date.getDate() : _date.getDate();
    var h = _date.getHours() < 10 ? "0" + _date.getHours() : _date.getHours();
    var m = _date.getMinutes() < 10 ? "0" + _date.getMinutes() : _date.getMinutes();
    var s = _date.getSeconds() < 10 ? "0" + _date.getSeconds() : _date.getSeconds();
    return {
      date: Y + "" + M + "" + D,
      time: h + "" + m + "" + s,
    };
  },
  getweekDays () {
    let lastDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    let nowNum = 0,
      total = 0;
    for (let i = 1; i <= lastDate.getDate(); i++) {
      const _d = new Date(new Date().getFullYear(), new Date().getMonth(), i);
      var has = false;
      for (var j = 0; j < window.globalData.freeDate.length; j++) {
        if (window.globalData.freeDate[j] == this.timetrans(_d, "day")) {
          has = true;
          break;
        }
      }
      if (!has) {
        var work = false;
        if (_d.getDay() == 0 || _d.getDay() == 6) {
          for (var j = 0; j < window.globalData.workDate.length; j++) {
            if (window.globalData.workDate[j] == this.timetrans(_d, "day")) {
              work = true;
              break;
            }
          }
        } else {
          work = true;
        }

        if (work) {
          total++;
          if (this.timetrans(_d, "pattern") * 1 <= this.timetrans(new Date(), "pattern") * 1) {
            nowNum++;
          }
        }
      }
    }
    return { nowNum, total };
  },
  date_Week: function (date) {
    let dateStr = date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6, 8);
    let numWeek = new Date(dateStr).getDay() + "";
    let str = "";
    switch (numWeek) {
      case "0":
        str = "星期日";
        break;
      case "1":
        str = "星期一";
        break;
      case "2":
        str = "星期二";
        break;
      case "3":
        str = "星期三";
        break;
      case "4":
        str = "星期四";
        break;
      case "5":
        str = "星期五";
        break;
      case "6":
        str = "星期六";
        break;
      default:
    }
    return str;
  },
  date_Month: function (date) {
    let dateStr = date.substring(4, 6);
    if (dateStr.substring(0, 1) == 0) {
      dateStr = dateStr.substring(1, 2);
    }
    let numMonth = dateStr + "";
    let str = "";
    switch (numMonth) {
      case "1":
        str = "一月";
        break;
      case "2":
        str = "二月";
        break;
      case "3":
        str = "三月";
        break;
      case "4":
        str = "四月";
        break;
      case "5":
        str = "五月";
        break;
      case "6":
        str = "六月";
        break;
      case "7":
        str = "七月";
        break;
      case "8":
        str = "八月";
        break;
      case "9":
        str = "九月";
        break;
      case "10":
        str = "十月";
        break;
      case "11":
        str = "十一月";
        break;
      case "12":
        str = "十二月";
        break;
      default:
    }
    return str;
  },

  caseType: function (type) {
    let str = "";
    switch (type) {
      case 0:
        str = "诉讼";
        break;
      case 1:
        str = "执行";
        break;
      case 2:
        str = "担保";
        break;
      case 3:
        str = "仲裁";
        break;
      default:
    }
    return str;
  },
  riskStatus: function (input) {
    let str = "";
    switch (input + "") {
      case "0":
        str = "智能风控根据标准无法判断";
        break;
      case "1":
        str = "已通过智能风控";
        break;
      case "2":
        str = "未通过智能风控";
        break;
      case "4":
        str = "优质案件";
        break;
      default:
    }
    return str;
  },
  appid: function (input) {
    let str = "";
    switch (input + "") {
      case "ANDUN":
        str = "安盾网";
        break;
      case "XC":
        str = "携船网";
        break;
      case "app":
        str = "APP";
        break;
      case "APP":
        str = "APP";
        break;
      case "FASAC":
        str = "法智特";
        break;
      case "MIS":
        str = "后台录入";
        break;
      case "WEB_SITE":
        str = "网站";
        break;
      case "WX_GZH":
        str = "微信";
        break;
      case "YHC_MP":
        str = "微信小程序";
        break;
      case "LS19423001":
        str = "法江湖";
        break;
      case "ONLINE":
        str = "线上其它";
        break;
      default:
    }
    return str;
  },
  appidOnlineType: function (input) {
    let str = "";
    switch (input + "") {
      case "ANDUN":
        str = "安盾网";
        break;
      case "XC":
        str = "携船网";
        break;
      case "app":
        str = "APP";
        break;
      case "APP":
        str = "APP";
        break;
      case "FASAC":
        str = "法智特";
        break;
      case "MIS":
        str = "后台录入";
        break;
      case "WEB_SITE":
        str = "网站";
        break;
      case "WX_GZH":
        str = "微信";
        break;
      case "YHC_MP":
        str = "微信小程序";
        break;
      case "LS19423001":
        str = "法江湖";
        break;
      case "ONLINE":
        str = "线上其它";
        break;
      default:
    }
    return str;
  },
  getDateYearSub: function (startDateStr, endDateStr) {
    let start = Number(startDateStr.substring(0, 4));
    let end = Number(endDateStr.substring(0, 4));
    return end - start;
  },

  /**
   ** 减法函数，用来得到精确的减法结果
   ** 说明：javascript的减法结果会有误差，在两个浮点数相减的时候会比较明显。这个函数返回较为精确的减法结果。
   ** 调用：accSub(arg1,arg2)
   ** 返回值：arg1加上arg2的精确结果
   **/
  accSub: function (arg1, arg2) {
    var r1, r2, m, n;
    try {
      r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2)); //last modify by deeka //动态控制精度长度
    n = r1 >= r2 ? r1 : r2;
    return ((arg1 * m - arg2 * m) / m).toFixed(n);
  },

  /**
   ** 乘法函数，用来得到精确的乘法结果
   ** 说明：javascript的减法结果会有乘法，在两个浮点数乘法的时候会比较明显。这个函数返回较为精确的乘法结果。
   ** 调用：accSub(arg1,arg2)
   ** 返回值：arg1加上arg2的精确结果
   **/

  accMul: function (arg1, arg2) {
    var m = 0,
      s1 = arg1.toString(),
      s2 = arg2.toString();
    try {
      m += s1.split(".")[1].length;
    } catch (e) { }
    try {
      m += s2.split(".")[1].length;
    } catch (e) { }
    return (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) / Math.pow(10, m);
  },

  accAdd: function (arg1, arg2) {
    var r1, r2, m;
    try {
      r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    return (arg1 * m + arg2 * m) / m;
  },
  CheckChinese (val) {
    // 正则
    var reg = new RegExp("[\\u4E00-\\u9FFF]+$", "g");
    // 验证
    if (!reg.test(val)) {
      return false; // 不纯中文
    } else {
      return true; // 纯中文
    }
  },
  //数组去除重
  uniqueArray: function (arr, attr) {
    let res = [];
    let json = {};
    for (let i = 0; i < arr.length; i++) {
      if (!json[arr[i][attr]]) {
        res.push(arr[i]);
        json[arr[i][attr]] = true;
      }
    }
    return res;
  },

  getChildren: function (deptId, list) {
    let _list = [];
    list.forEach(item => {
      if (item.parentId == deptId) {
        _list.push(item);
      }
    });
    return _list;
  },
  strLength: function (input, length) {
    let str = "";
    if (!input) {
      return "";
    }
    if (String(input).length > length) {
      str = String(input).slice(0, length) + "...";
    } else {
      str = String(input);
    }
    return str;
  },

  formatDateStr: function (input) {
    let _date = input,
      _year = _date.getFullYear(),
      _mon = _date.getMonth() + 1,
      _day = _date.getDate();
    return _year + "年" + _mon + "月" + _day + "日";
  },
  formatDate: function (input, sp) {
    let _date = input,
      _year = _date.getFullYear(),
      _mon = _date.getMonth() + 1 < 10 ? "0" + (_date.getMonth() + 1) : _date.getMonth() + 1,
      _day = _date.getDate() < 10 ? "0" + _date.getDate() : _date.getDate();
    return sp ? _year + sp + _mon + sp + _day : _year + "" + _mon + "" + _day;
  },
  formatDateTime: function (input) {
    let _date = input,
      _year = _date.getFullYear(),
      _mon = _date.getMonth() + 1 < 10 ? "0" + (_date.getMonth() + 1) : _date.getMonth() + 1,
      _day = _date.getDate() < 10 ? "0" + _date.getDate() : _date.getDate(),
      _getHours = _date.getHours() < 10 ? "0" + _date.getHours() : _date.getHours(),
      _getMinutes = _date.getMinutes() < 10 ? "0" + _date.getMinutes() : _date.getMinutes(),
      _getSeconds = _date.getSeconds() < 10 ? "0" + _date.getSeconds() : _date.getSeconds();
    return (
      _year + "-" + _mon + "-" + _day + " " + _getHours + ":" + _getMinutes + ":" + _getSeconds
    );
  },

  getTimeLength: input => {
    //单位 ： 秒
    let str = "";
    if (input) {
      let second = input * 1,
        minute = 0,
        hour = 0,
        day = 0;
      minute = parseInt(second / 60); //算出一共有多少分钟
      second %= 60; //算出有多少秒
      if (minute > 60) {
        //如果分钟大于60，计算出小时和分钟
        hour = parseInt(minute / 60);
        minute %= 60; //算出有多分钟
      }
      if (hour > 24) {
        //如果小时大于24，计算出天和小时
        day = parseInt(hour / 24);
        hour %= 24; //算出有多分钟
      }
      if (minute > 0) {
        str = minute + "分钟";
      } else {
        str = "1分钟";
      }
      if (hour > 0) {
        str = hour + "小时" + minute + "分钟";
      }
      if (day > 0) {
        str = day + "天" + hour + "小时" + minute + "分钟";
      }
    }
    return str;
  },

  ypesIdByName: function (list, id) {
    let name = "";
    for (let i = 0; i < list.length; i++) {
      if (list[i].clueTypeId == id) {
        name = list[i].clueTypeName;
        break;
      }
    }
    return name;
  },
  caseTypesIdByName: function (list, id) {
    let name = "";
    for (let i = 0; i < list.length; i++) {
      if (list[i].caseTypeId == id) {
        name = list[i].caseTypeName;
        break;
      }
    }
    return name;
  },

  docSize: function (size) {
    if (size && !isNaN(size)) {
      if (Number(size) < 1024) {
        return size + "B";
      } else if (Number(size / 1024) < 1024) {
        return Number(size / 1024).toFixed(2) + "KB";
      } else {
        return Number(size / 1024 / 1024).toFixed(2) + "MB";
      }
    }
    return "";
  },
  nameIsCompany: function (input) {
    var str = false;
    if (input && String(input).length > 5) {
      str = true;
    }
    return str;
  },
  datedifference: function (sDate1, sDate2) {
    //sDate1和sDate2是2006-12-18格式
    let dateSpan,
      iDays = "";
    if (sDate1 && sDate2) {
      sDate1 = Date.parse(sDate1);
      sDate2 = Date.parse(sDate2);
      dateSpan = sDate2 - sDate1;
      // dateSpan = Math.abs(dateSpan); //绝对值
      iDays = Math.ceil(dateSpan / (24 * 3600 * 1000)) + 1;
    }
    return iDays;
  },
  //浏览器类型及版本
  getBrowserInfo: function () {
    var agent = navigator.userAgent.toLowerCase();
    var regStr_ie = /msie [\d.]+;/gi;
    var regStr_ff = /firefox\/[\d.]+/gi;
    var regStr_chrome = /chrome\/[\d.]+/gi;
    var regStr_saf = /safari\/[\d.]+/gi;
    var isIE = agent.indexOf("compatible") > -1 && agent.indexOf("msie" > -1); //判断是否IE<11浏览器
    var isEdge = agent.indexOf("edge") > -1 && !isIE; //判断是否IE的Edge浏览器
    var isIE11 = agent.indexOf("trident") > -1 && agent.indexOf("rv:11.0") > -1;
    if (isIE) {
      var reIE = new RegExp("msie (\\d+\\.\\d+);");
      reIE.test(agent);
      var fIEVersion = parseFloat(RegExp["$1"]);
      if (fIEVersion == 7) {
        return "IE/7";
      } else if (fIEVersion == 8) {
        return "IE/8";
      } else if (fIEVersion == 9) {
        return "IE/9";
      } else if (fIEVersion == 10) {
        return "IE/10";
      }
    } //isIE end
    if (isIE11) {
      return "IE/11";
    }
    //firefox
    if (agent.indexOf("firefox") > 0) {
      return agent.match(regStr_ff);
    }
    //Safari
    if (agent.indexOf("safari") > 0 && agent.indexOf("chrome") < 0) {
      return agent.match(regStr_saf);
    }
    //Chrome
    if (agent.indexOf("chrome") > 0) {
      return agent.match(regStr_chrome);
    }
    // var b_name = (browser + "").replace(/[0-9./]/ig, "");//根据正则将所有数字、‘.’‘/’全部去掉，剩下浏览器名
    // var b_version = parseInt((browser + "").replace(/[^0-9.]/ig, ""));//根据正则将所有非数字全部去掉，剩下版本
  },
  customStatus: function (input) {
    let str = "";
    switch (input + "") {
      case "1":
        str = "开发中";
        break;
      case "2":
        str = "未签约";
        break;
      case "3":
        str = "已签约";
        break;
      default:
    }
    return str;
  },
  customGrade: function (input) {
    let str = "";
    switch (input + "") {
      case "1":
        str = "A(关键客户)";
        break;
      case "2":
        str = "B(重点客户)";
        break;
      case "3":
        str = "C(一般客户)";
        break;
      case "4":
        str = "D(低价值客户)";
        break;
      default:
    }
    return str;
  },
  custType: function (input) {
    let str = "";
    switch (input + "") {
      case "1":
        str = "企业客户";
        break;
      case "2":
        str = "律所客户";
        break;
      case "3":
        str = "商会协会";
        break;
      case "4":
        str = "机关单位";
        break;
      default:
    }
    return str;
  },
  brokeBelong: function (input) {
    let str = "";
    switch (input + "") {
      case "1":
        str = "城市合伙人";
        break;
      case "2":
        str = "居间商";
        break;
      case "3":
        str = "经销商";
        break;
      case "4":
        str = "城市代理商";
        break;
      case "5":
        str = "运营商";
        break;
      case "6":
        str = "区县运营商";
        break;
      default:
    }
    return str;
  },
  custSource: function (input) {
    let str = "";
    switch (input + "") {
      case "1":
        str = "电销进案";
        break;
      case "2":
        str = "电销预约";
        break;
      case "3":
        str = "独立开发";
        break;
      case "4":
        str = "居间商介绍";
        break;
      case "5":
        str = "客户介绍";
        break;
      case "6":
        str = "线上进案";
        break;
      case "9":
        str = "其他";
        break;
      default:
    }
    return str;
  },
  linkMode: function (input) {
    let str = "";
    switch (input + "") {
      case "1":
        str = "拜访";
        break;
      case "2":
        str = "电话联系";
        break;
      case "3":
        str = "微信联系";
        break;
      case "4":
        str = "其他方式联系";
        break;
      default:
    }
    return str;
  },
  caseResult: function (input) {
    let str = "";
    switch (input + "") {
      case "1":
        str = "有合作案件";
        break;
      case "2":
        str = "有诊断案件";
        break;
      case "3":
        str = "无案件";
        break;
      default:
    }
    return str;
  },
  signReasonId: function (input) {
    let str = "";
    switch (input + "") {
      case "1":
        str = "新客户拜访";
        break;
      case "2":
        str = "回访";
        break;
      case "3":
        str = "上门服务";
        break;
      default:
    }
    return str;
  },
  signStatus: function (input) {
    let str = "";
    switch (input + "") {
      case "1":
        str = "已签到，拜访中";
        break;
      case "2":
        str = "已签退";
        break;
      case "3":
        str = "未签退";
        break;
      default:
    }
    return str;
  },
  caseBizMode: function (input) {
    let str = "";
    switch (input + "") {
      case "1":
        str = "诉讼";
        break;
      case "3":
        str = "执行";
        break;
      case "5":
        str = "债权收购";
        break;
      case "6":
        str = "非诉清收";
        break;
      default:
    }
    return str;
  },
  case_status: function (input) {
    let str = "";
    //案件状态：1未提交；2已提交；3待安排风控；4风控审核通过；5已承接；0已关闭；A审核拒绝B待委托；C已委托
    //风险投状态：1合同未确认；2合同已确认；3合同已签约；4财务已审核；5财务已放款；6部分已回款；0已关闭；A审核拒绝
    switch (input) {
      case "1":
        str = "未提交";
        break;
      case "2":
        str = "已提交";
        break;
      case "3":
        str = "待安排风控";
        break;
      case "D":
        str = "风控审核中";
        break;
      case "4":
        str = "风控审核通过";
        break;
      case "5":
        str = "已承接";
        break;
      case "0":
        str = "已关闭";
        break;
      case "8":
        str = "异常结案";
        break;
      case "9":
        str = "已结案";
        break;
      case "A":
        str = "审核拒绝";
        break;
      case "B":
        str = "待委托";
        break;
      case "C":
        str = "已委托";
        break;
      case "G":
        str = "合同已签约";
        break;
      case "H":
        str = "诊断完成";
        break;
      default:
    }
    return str;
  },

  getAmt4Str: function (input) {
    let str = "";
    if (input) {
      str = input
        .replace("人民币", "")
        .replace("亿元", "0000")
        .replace("万元", "0000")
        .replace("万元", "0000")
        .replace("万美元", "")
        .replace("元", "");
    }
    return str;
  },
  getChinaMoney: function (input) {
    return [
      "一月",
      "二月",
      "三月",
      "四月",
      "五月",
      "六月",
      "七月",
      "八月",
      "九月",
      "十月",
      "十一月",
      "十二月",
    ][input];
  },

  filterProvince: function (key) {
    let provinceName = "";
    if (key) {
      if (key == "内蒙古自治区") {
        provinceName = "内蒙古";
      } else if (key == "广西壮族自治区") {
        provinceName = "广西";
      } else if (key == "西藏自治区") {
        provinceName = "西藏";
      } else if (key == "宁夏回族自治区") {
        provinceName = "宁夏";
      } else if (key == "新疆维吾尔自治区") {
        provinceName = "新疆";
      } else if (key == "香港特别行政区") {
        provinceName = "香港";
      } else if (key == "澳门特别行政区") {
        provinceName = "澳门";
      } else {
        provinceName = key.replace("省", "").replace("市", "");
      }
    }
    return provinceName;
  },
  getDesAndUrl: function (type) {
    let returnData = "";
    let byMyjobList = [
      { name: "新增客户数量", des: "新增客户数量", tip: "新增客户数" },
      { name: "预约总量", des: "预约总量", tip: "电销预约成功的客户数（包括新增+老客户）" },
      { name: "拜访总量", des: "拜访总量", tip: "签到的客户数量（包括新增+老客户）" },
      { name: "写跟进次数", des: "写跟进次数", tip: "跟进后记录的数量" },
      { name: "待联系客户", des: "待联系客户", tip: "今天应该联系的客户" },
      { name: "待联系新用户", des: "待联系新用户", tip: "今日新注册用户,请及时联系" },
      { name: "待联系的合作商申请", des: "待联系合作商", tip: "待联系的合作商申请" },
      { name: "待安排投资经理", des: "待安排投资经理", tip: "待安排投资经理" },
      { name: "待处理线上进案", des: "待处理线上进案", tip: "待处理线上进案" },
    ];
    for (let i = 0; i < byMyjobList.length; i++) {
      if (byMyjobList[i].name == type) {
        returnData = byMyjobList[i];
        break;
      }
    }
    return returnData;
  },
  getqueryCodeText: function (input) {
    let str = "";
    switch (input) {
      case "1":
        str = "新增客户数量";
        break;
      case "2":
        str = "预约总量";
        break;
      case "3":
        str = "拜访总量";
        break;
      case "4":
        str = "写跟进次数";
        break;
      case "5":
        str = "待联系客户";
        break;
      default:
    }
    return str;
  },
  getqueryCodeByText: function (input) {
    let str = "";
    switch (input) {
      case "新增客户数量":
        str = "1";
        break;
      case "预约总量":
        str = "2";
        break;
      case "拜访总量":
        str = "3";
        break;
      case "写跟进次数":
        str = "4";
        break;
      case "待联系客户":
        str = "5";
        break;
      default:
    }
    return str;
  },
  getTimeCodeText: function (input) {
    let str = "";
    switch (input) {
      case "1":
        str = "今天";
        break;
      case "2":
        str = "昨天";
        break;
      case "3":
        str = "本周";
        break;
      case "4":
        str = "上周";
        break;
      case "5":
        str = "本月";
        break;
      case "6":
        str = "上月";
        break;
      case "7":
        str = "本季度";
        break;
      case "8":
        str = "上季度";
        break;
      case "9":
        str = "今年";
        break;
      default:
    }
    return str;
  },
  getTimeCode: function (input) {
    let str = "";
    switch (input) {
      case "今天":
        str = "1";
        break;
      case "昨天":
        str = "2";
        break;
      case "本周":
        str = "3";
        break;
      case "上周":
        str = "4";
        break;
      case "本月":
        str = "5";
        break;
      case "上月":
        str = "6";
        break;
      case "本季度":
        str = "7";
        break;
      case "上季度":
        str = "8";
        break;
      case "今年":
        str = "9";
        break;
      default:
    }
    return str;
  },
  getCode: function (input) {
    let str = "";
    switch (input) {
      case "0":
        str = "全部";
        break;
      case "1":
        str = "诉讼";
        break;
      case "2":
        str = "执行";
        break;
      default:
    }
    return str;
  },
  Gradetext: function (input) {
    let str = "";
    switch (input + "") {
      case "1":
        str = "A";
        break;
      case "2":
        str = "B";
        break;
      case "3":
        str = "C";
        break;
      case "4":
        str = "D";
        break;
      default:
    }
    return str;
  },
  tagCodes: function (input) {
    let str = "";
    const tags = [
      { name: "老板", code: "BOSS" },
      { name: "诉讼部老总", code: "LAWSUIT_MANAGER" },
      { name: "执行部老总", code: "ENFORCE_MANAGER" },
      { name: "非诉部老总", code: "PROJECT_MANAGER" },
      { name: "诉讼投资部老总", code: "MARKET_MANAGER" },
      { name: "风控部老总", code: "RISK_MANAGER" },
      { name: "财务部老总", code: "FINANCE_MANAGER" },
      { name: "不良资产部老总", code: "NPA_MANAGER" },
      { name: "市场人员", code: "MARKET" },
      { name: "电销人员", code: "CALL_OPER" },
      { name: "投资经理", code: "SALE_OPER" },
      { name: "客服人员", code: "SERVICE_OPER" },
      { name: "律师", code: "LAWYER" },
      { name: "诉讼部人员", code: "LAWSUIT_OPER" },
      { name: "执行部人员", code: "ENFORCE_OPER" },
      { name: "非诉部人员", code: "PROJECT_OPER" },
      { name: "风控部人员", code: "RISK_OPER" },
      { name: "执行风控", code: "ENFORCE_RC" },
      { name: "不良资产部人员", code: "NPA_OPER" },
      { name: "法智特财务人员", code: "FASAC_FINANCE_OPER" },
      { name: "赢火虫财务人员", code: "WINHC_FINANCE_OPER" },
      { name: "律师协助诉讼管理员", code: "LAWYER_ASSIST_LAWSUIT_MANAGER" },
      { name: "律师协助执行管理员", code: "LAWYER_ASSIST_ENFORCE_MANAGER" },
      { name: "投后管理部人员", code: "LAWYER_COOPERATION" },
      { name: "进展汇报需监督", code: "REPORT_UNDER_CONTROL" },
      { name: "进展汇报审核人", code: "REPORT_APPROVER" },
      { name: "投后管理部主管", code: "LC_MANAGER" },
    ];
    for (let i = 0; i < tags.length; i++) {
      const ele = tags[i];
      if (tags[i].code == input) {
        str = tags[i].name;
        break;
      }
    }

    return str;
  },
  regChannel: function (input) {
    let str = "";
    switch (input + "") {
      case "1":
        str = "APP";
        break;
      case "2":
        str = "微信";
        break;
      case "3":
        str = "网站";
        break;
      case "4":
        str = "第三方";
        break;
      case "5":
        str = "后台管理";
        break;
      case "6":
        str = "赢火虫小程序";
        break;
      default:
    }
    return str;
  },
  OrderQuerystatus (caseStageId) {
    return (
      {
        0: "订单完成",
        1: "待支付",
        2: "支付成功",
        R: "需退款",
        D: "已删除",
        G: "已退款",
        C: "已关闭",
      }[caseStageId + ""] || "-"
    );
  },
  JobType (val, is) {
    return (
      {
        '2': is ? '员工' : "居间商",
        '4': is ? '老板' : "城市代理商",
        '5': is ? '老板' : "运营商",
        '6': is ? '老板' : "区县运营商",
        'senior': "高管",
        'director': "部门主管",
        'groupLeader': "部门组长",
        'worker': "员工",
      }[val + ""] || ""
    );
  },
  SingleStatus: function (val) {
    return (
      {
        0: "订单完成",
        1: "待支付",
        2: "支付成功",
        3: "待确认",
        'R': "需退款",
        'D': "已删除",
        'G': "已退款",
        'C': "已关闭",
      }[val + ""] || "-"
    );
  },
  FollowType: function (val) {
    return (
      {
        1: "电话",
        2: "微信",
        3: "拜访",
        4: "短信",
        5: "其他",
      }[val + ""] || "-"
    );
  },
  PayModeInfo (caseStageId) {
    return (
      {
        0: "赢币",
        1: "支付宝",
        2: "微信",
        3: "兑换券",
        4: "兑换券",
        5: "线下支付",
      }[caseStageId + ""] || "暂无"
    );
  },

  BusinessDomain (t) {
    return (
      {
        1: "劳动人事",
        2: "商务合同",
        3: "知识产权",
        4: "财税账款",
        5: "公司治理",
        6: "行政监管",
        7: "其他",
      }[t + ""] || "-"
    );
  },
  CompanySpec (t) {
    return (
      {
        1: "10人以下",
        2: "10-30人",
        3: "31-50人",
        4: "50人以上",
      }[t + ""] || "-"
    );
  },
  ElectroniCesignStatus (t) {
    return (
      {
        1: "签署中",
        2: "已完成",
        3: "已撤销",
        5: "已过期",
        7: "已拒签",
        0: "发起失败",
      }[t + ""] || "-"
    );
  },
  FsCaseStatus (t) {
    return (
      {
        1: "待接单",
        2: "进行中",
        3: "已完成",
        4: "已取消",
        5: "已拒绝",
      }[t + ""] || "--"
    );
  },
  RenewIdea (t) {
    return (
      {
        1: "沟通中",
        2: "续费中",
        3: "不续费",
        4: "有意愿",
      }[t + ""] || "-"
    );
  },
  publicStatus (t) {
    return (
      {
        0: "未公示",
        1: "审核中",
        2: "已驳回",
        3: "不公示",
        4: "公示中",
      }[t + ""] || "-"
    );
  },
  LegalServiceMode (t) {
    return (
      {
        1: "电话",
        2: "邮件",
        3: "微信",
        4: "现场",
        5: "其他",
      }[t + ""] || "-"
    );
  },
  caseKindTag (t) {
    return (
      {
        1: "一般债权",
        2: "知识产权",
        3: "执行",
        4: "债权收购",
        5: "安盾网",
        6: "居间",
      }[t + ""] || "-"
    );
  },
  docKind (t) {
    return (
      {
        1: "一般债权",
        2: "知识产权",
        3: "执行",
        4: "债权收购",
        5: "安盾网",
        6: "居间",
      }[t + ""] || "-"
    );
  },
  PayCompanyLevel: function (val) {
    return (
      {
        A: "A：优质客户，紧密跟进",
        B: "B：次优级客户，有可能续费，正常跟进",
        C: "C：一般客户，续费意愿一般",
        D: "D：非潜在客户，交付即可",
      }[val + ""] || "-"
    );
  },
  ExamType: function (val) {
    return {
      1: "劳动人事",
      2: "应收账款",
    }[val + ""] || '-';
  },
  InvoiceStatus (val, is) {
    return (
      {
        0: is ? "开票中" : "待开票",
        1: "已开票",
        2: "已作废",
        3: "开票失败",
        4: "发票撤销中",
        5: "已受理",
      }[val + ""] || val
    );
  },
  invoiceTarget: function (val) {
    return (
      {
        1: "客户",
        2: "律所",
        3: "居间商/合作商",
      }[val + ""] || "-"
    );
  },
  RelevanceOrderStatus (value) {
    return (
      {
        0: "订单完成",
        1: "待支付",
        2: "支付成功",
        R: "需退款",
        D: "已删除",
        G: "已退款",
        C: "已关闭",
      }[value + ""] || "暂无"
    );
  },
  SettleType: function (val) {
    return (
      {
        1: "月度分佣比例激励",
        2: "固定金额激励",
        3: "单项服务结算",
        4: "允许开普票扣减",
        5: "手动调账",
      }[val + ""] || "-"
    );
  },
  InvoiceType: function (val) {
    return (
      {
        1: "企业增值税专用发票",
        2: "增值税普通发票",
      }[val + ""] || "-"
    );
  },
  PayCompanyManageSourceType (value) {
    return (
      {
        1: "业务员分享链接",
        2: "后台开通",
        3: "APP首页申请",
        4: "扫码申请",
        5: "网页申请",
        6: "云法务SAAS",
        7: "经开区",
        8: "新春大礼包",
      }[value + ""] || "暂无"
    );
  },
  FirstDemandType (val) {
    return (
      {
        1: "应收帐款催款",
        2: "劳动人事咨询",
        3: "合同起草",
        4: "合同审核",
        5: "起草/发送律师函",
        6: "其他咨询",
        7: "其他",
        8: "应收账款催款",
        9: "代写起诉状/答辩状",
      }[val + ""] || "-"
    );
  },
  ServiceChargeMode: function (val) {
    return {
      1: "前期收费",
      2: "回款后收费",
      3: "前期收费+回款后收费",
    }[val + ""] || '-';
  },
  ifStatus (value) {
    if (value.winCoinInvoiceStatus == "0") {
      return "待开票";
    } else if (value.winCoinInvoiceStatus == "1") {
      if (value.invoiceStatus == "0") {
        return "开票中";
      } else if (value.invoiceStatus == "1") {
        return "已开票";
      } else if (value.invoiceStatus == "2") {
        return "已作废";
      } else if (value.invoiceStatus == "3") {
        return "开票失败";
      } else if (value.invoiceStatus == "4") {
        return "发票撤销中";
      } else if (value.invoiceStatus == "5") {
        return "已受理";
      }
    } else if (value.winCoinInvoiceStatus == "2") {
      return "已作废";
    }
  },
  docKindType (val) {
    return (
      {
        1: "业务培训",
        2: "员工招聘，薪酬，管理全流程",
        3: "员工日常工作管理表",
        4: "员工培训手册",
        5: "公司介绍，产品介绍",
        6: "法律常识",
        7: "案件全流程材料（含培训材料）",
        8: "企业背书",
        9: "云法务合同集",
        10: "非诉案件合同集",
        11: "诉讼案件合同集",
        12: "执行案件合同集",
        13: "公司装修文件",
        14: "线下传单，线上宣传卡",
        15: "活动易拉宝",
        16: "名片及工作证",
        17: "其他相关宣传",
        18: "财务基础资料",
        19: "活动方案模板手册",
        20: "园区合作样板材料集",
        21: "活动相关PPT",
        22: "项目介绍视频合集",
        23: "培训视频合集",
        24: "代理商材料库目录",
        25: "老板培训",
        26: "业务快速上手培训",
        27: "业务进阶培训",
        28: "六天财富私享会",
        29: "法律知识普及",
        30: "代理商五天培训计划",
      }[val + ""] || "-"
    );
  },
  InvestigateStatus (val) {
    return (
      {
        0: "未评价",
        1: "已评价",
        2: "已关闭",
      }[val + ""] || "-"
    );
  },
  FsCaseSource (t) {
    return (
      {
        0: "委托催收",
        1: "账款中心",
        2: "非公债务",
        3: "履债评估",
        4: "MIS",
      }[t + ""] || "-"
    );
  },
  UserFeedBackAppPackage: function (input) {
    let str = "";
    switch (input + "") {
      case "com.firefly.mobile.app":
        str = "赢火虫app";
        break;
      case "com.winhc.user.app":
        str = "赢律师法律咨询";
        break;
      case "com.winhc.user.mp":
        str = "赢律师小程序";
        break;
      case "com.winhc.user.mweb":
        str = "赢律师M站";
        break;
      case "com.winhc.user.pc":
        str = "赢火虫官网";
        break;
      case "com.winhc.legal.app":
        str = "赢火虫云法务app";
        break;
      case "com.winhc.partner.api":
        str = "合作方";
        break;
      case "com.winhc.repal.feishu":
        str = "回款宝飞书";
        break;
      case "com.winhc.repal.dingding":
        str = "回款宝钉钉";
        break;
      case "com.winhc.legalls.mp":
        str = "云法务小程序律师";
        break;
      case "com.winhc.legalkh.mp":
        str = "云法务小程序客户";
        break;
      case "com.winhc.legal.saas":
        str = "云法务SAAS";
        break;
      case "com.winhc.mis.web":
        str = "MIS";
        break;
      default:
    }
    return str || "-";
  },
  FsPackageName (t) {
    return (
      {
        "com.winhc.legal.saas_0": "云法务SaaS-委托催收",
        "com.winhc.legal.saas_1": "云法务SaaS-账款中心",
        "com.winhc.legal.saas_2": "云法务SaaS-非公债务",
        "com.winhc.legal.saas_3": "云法务SaaS-履债评估",
        "com.winhc.legal.app_0": "云法务App-委托催收",
        "com.winhc.legal.app_1": "云法务App-账款中心",
        "com.winhc.legal.app_2": "云法务App-非公债务",
        "com.winhc.legal.app_3": "云法务App-履债评估",
        _4: "MIS",
        "com.winhc.mis.web_4": "MIS",
      }[t + ""] || "-"
    );
  },
  PayCompanyManageEmploystatus (val) {
    return (
      {
        0: "待处理",
        1: "申请通过",
        2: "拒绝申请",
      }[val + ""] || "-"
    );
  },

  EmployCustomerLevel (val) {
    return (
      {
        A: "A（关键客户）",
        B: "B（重点客户）",
        C: "C（一般客户）",
        D: "D（低价值客户）",
      }[val + ""] || "-"
    );
  },
  tuYi: function (inp) {
    let str = "";
    let input = inp ? Number(inp) : 0;
    if (input / 10000 > 1) {
      str = (input / 10000).toFixed(2) + "亿";
    } else {
      str = input + "万元";
    }
    return str;
  },
  amtNew: function (i) {
    let str = "";
    if (i && !isNaN(i)) {
      i = i * 1;
      if (i >= 100000000) {
        str = (i / 100000000).toFixed(2) + " 亿元";
      } else if (i >= 10000 && i < 100000000) {
        str = (i / 10000).toFixed(2) + " 万元";
      } else if (i < 10000) {
        str = i.toFixed(2) + " 元";
      }
    } else {
      str = "0";
    }
    return str;
  },
  legalspecification (val) {
    return (
      {
        legal_basic: "优享版 1年",
        legal_park: "园区版 1年",
        legal_basic_2y: "优享版 2年",
        legal_basic_3y: "优享版 3年",
        legal_customized: "定制版 1年",
        legal_zhenghe: "政和版 1年",
        legal_wisdom: "智享版 1年",
        legal_wisdom_2y: "智享版 2年",
        legal_wisdom_3y: "智享版 3年",
        legal_cloud: "法务版",
        legal_legal: "法务版 1年",
        legal_legal_2y: "法务版 2年",
        legal_legal_3y: "法务版 3年",
        legal_honour: "尊享版 1年",
        legal_honour_pro: "尊享版 3年",
        legal_normal: "创享版 1年",
        legal_spark: "星火版 1年",
        general: "旧 版 1年",
        contract_approve: "合同审核 1次",
        contract_draft: "合同拟定 1次",
        lawyer_letter: "律师函服务 1次",
        collection_letter: "催款函 1次",
        indictment_bill: "起诉状/答辩状 1次",
        litigation_guide: "诉讼指导 1次",
        bill_guide_register: "诉状+指导+代立案 1次",
        register_agent: "网上代立案 1次",
        indictment_bill_2nd: "二审上诉状/答辩状 1次",
        enforce: "强制执行/恢复执行申请书 1次",
        legal_saas: "SaaS版 1年"
      }[val + ""] || "-"
    );
  },
  legalspeciName (t, m) {
    return (
      {
        legal_basic: ["优享版", m + "-" + t + "-1年"],
        legal_basic_2y: ["优享版", m + "-" + t + "-2年"],
        legal_basic_3y: ["优享版", m + "-" + t + "-3年"],
        legal_park: ["园区版", m + "-" + t + "-1年"],
        legal_zhenghe: ["政和版", m + "-" + t + "-1年"],
        legal_customized: ["定制版", m + "-" + t + "-1年"],
        legal_wisdom: ["智享版", m + "-" + t + "-1年"],
        legal_wisdom_2y: ["智享版", m + "-" + t + "-2年"],
        legal_wisdom_3y: ["智享版", m + "-" + t + "-3年"],
        legal_cloud: "法务版",
        legal_legal: ["法务版", m + "-" + t + "-1年"],
        legal_legal_2y: ["法务版", m + "-" + t + "-2年"],
        legal_legal_3y: ["法务版", m + "-" + t + "-3年"],
        legal_honour: ["尊享版", m + "-" + t + "-1年"],
        legal_honour_pro: ["尊享版", m + "-" + t + "-3年"],
        legal_normal: ["创享版", m + "-" + t + "-1年"],
        legal_spark: ["星火版", m + "-" + t + "-1年"],
        general: ["旧 版", m + "-" + t + "-1年"],
        contract_approve: ["单项服务", m + "-" + t + "-1次"], //合同审核
        contract_draft: ["单项服务", m + "-" + t + "-1次"], //合同拟定
        lawyer_letter: ["单项服务", m + "-" + t + "-1次"], //律师函服务
        collection_letter: ["单项服务", m + "-" + t + "-1次"], //催款函
        indictment_bill: ["单项服务", m + "-" + t + "-1次"], //起诉状/答辩状
        litigation_guide: ["单项服务", m + "-" + t + "-1次"], //诉讼指导
        bill_guide_register: ["单项服务", m + "-" + t + "-1次"], //诉状+指导+代立案
        register_agent: ["单项服务", m + "-" + t + "-1次"], //网上代立案
        indictment_bill_2nd: ["单项服务", m + "-" + t + "-1次"], //二审上诉状/答辩状
        enforce: ["单项服务", m + "-" + t + "-1次"], //强制执行/恢复执行申请书
        legal_saas: ["SaaS版", m + "-" + t + "-1年"],
      }[t + ""] || "-"
    );
  },

  getUrlParam: function (name) {
    var reg = new RegExp("(^|\\?|&)" + name + "=([^&]*)(\\s|&|$)", "i");
    if (reg.test(window.location.href)) return unescape(RegExp.$2.replace(/\+/g, " "));
    return "";
  },
};
