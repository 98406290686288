import React, { Component } from "react";
import {
    Row,
    Col,
    Breadcrumb,
    message,
    Button,
    Form,
    Popconfirm,
    Input,
    Select,
    Tooltip,
    Table,
    Popover,
    TreeSelect,
} from "antd";
import { Ossupload } from "../../common/upLoad";
import { FlieList, popoverTable } from "../../common";
import { Axios } from "../../axios";
import { AreaSelect, Imgbox } from "../../common";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Filter from "../../common/filter";
import filter from "../../common/filter";

const { Option } = Select;

export default class CustomerDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            brokerDetails: {},
            tData: [],
            loading: false,
            totalNum: 0,
            currentPage: 1,
            comtotalNum: 0,
            InformationList: {},
        };
    }

    getDet = async () => {
        try {
            let res = await Axios(
                "get",
                "/brokeruser/operateList/" + window.brokerId,
                {}
            );
            if (res.code == "200") {
                console.log(res);
                this.setState({
                    InformationList: res.data,
                });
            } else {
                message.error(res.message);
            }
        } catch (err) {
            console.log(err);
        }
    };
    handleChange = async (value) => {
        try {
            let res = await Axios(
                "get",
                "/brokeruser/operateList/" + window.brokerId + "/list",
                { brokerId: window.brokerId, year: value, }
            );
            if (res.code == "200") {
                console.log(res);
                this.setState({
                    tData: res.data,
                });
            } else {
                message.error(res.message);
            }
        } catch (err) {
            console.log(err);
        }
    };
    componentDidMount() {
        window.brokerId = this.props.match.params.id;
        this.getDet();
        this.handleChange(new Date().getFullYear());
    }

    render() {
        const brokerDetails = {};
        const companyInfo = {};
        const { InformationList } = this.state;
        const cStyle = {
            padding: "12px 24px 12px 24px",
            marginBottom: "15px",
            borderRadius: "4px",
            background: "#fff",
            fontSize: "14px",
        };
        const tStyle = {
            color: "#222",
            lineHeight: "30px",
            fontWeight: "650",
            fontSize: "16px",
            margin: "0px -24px 15px -24px",
            borderBottom: "1px solid #efe5e5",
            padding: "0 24px 8px 24px",
        };
        const dStyle = {
            color: "#222",
            lineHeight: "30px",
            margin: "10px 0px 0px 0px",
        };
        return (
            <div className="pageContainer">
                <Row className="headNav" type="flex" justify="space-between">
                    <Col span={24}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>
                                <Link to="/OperatorManage">运营商管理</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>运营商详情</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={24} className="title">
                        <span className="name">运营商详情</span>
                    </Col>

                </Row>
                <div className="scrollContainer">
                    <div style={cStyle}>
                        <div style={cStyle}>
                            <div style={tStyle}>运营商基本信息</div>
                            <Row style={dStyle}>
                                <Col span={8}>
                                    企业名称：
                                    {InformationList.brokerName || "暂无"}
                                </Col>
                                <Col span={8}>
                                    联系人：{InformationList.linkMan || "暂无"}
                                </Col>
                                <Col span={8}>
                                    联系电话：{InformationList.mobileNo || "暂无"}
                                </Col>
                            </Row>
                            <Row style={dStyle}>
                                <Col span={8}>
                                    地区： {InformationList.province || "暂无"}{" "}
                                    {InformationList.city || ""}{" "}
                                    {InformationList.district || ""}{" "}
                                </Col>
                                <Col span={8}>
                                    推荐人：{InformationList.brokerName || "暂无"}
                                </Col>
                                <Col span={8}>
                                    类型：
                                    {Filter.brokeBelong(
                                        InformationList.brokerType
                                    ) || "暂无"}
                                </Col>
                            </Row>
                            <Row style={dStyle}>
                                <Col span={8}>
                                    合作开始时间：
                                    {Filter.date(InformationList.cooperationDate) ||
                                        "暂无"}
                                </Col>

                                <Col span={16}>
                                    合作结束时间：
                                    {Filter.date(InformationList.endDate) || "暂无"}
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div style={cStyle}>
                        <div style={cStyle}>
                            <div style={tStyle}>
                                运营商客户订单（订单是客户付费订单）
                            </div>
                            <div>
                                <Select
                                    placeholder="请选择"
                                    allowClear
                                    style={{ width: "10%" }}
                                    onChange={this.handleChange}
                                    defaultValue={new Date().getFullYear() + "年"}
                                >
                                    <Option key="2023">2023年</Option>
                                    <Option key="2022">2022年</Option>
                                    <Option key="2021">2021年</Option>
                                </Select>
                            </div>
                            <Table
                                style={{ alignItems: "center" }}
                                dataSource={this.state.tData}
                                rowKey="orderId"
                                columns={[
                                    {
                                        title: "月份",
                                        dataIndex: "month",
                                        width: 180,
                                        render: (t) => t + '月'
                                    },
                                    {
                                        title: "订单数（直客+关联订单）",
                                        dataIndex: "orderNum",
                                        width: 120,
                                    },
                                    {
                                        title: "预估成交金额（元）",
                                        dataIndex: "sumAmt",
                                        width: 120,
                                        render: (t) => t || 0

                                    },
                                ]}
                                size="middle"
                                pagination={{
                                    size: "large",

                                    showTotal: (total) => {
                                        return "共 " + total + " 条记录 ";
                                    },
                                }}
                                loading={this.state.loading}
                                onChange={this.handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
