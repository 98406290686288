import React from 'react';
import { HashRouter, Route, Switch, Link } from 'react-router-dom';
import {
  Layout,
  Menu,
  Tooltip,
  Avatar,
  Spin,
  Popover,
  Tabs,
  Dropdown,
  Modal,
  Drawer,
  Table,
  message,
  Badge,
  Empty
} from 'antd';
import routers from './routes/router.js';
import './App.scss';
import { Axios } from './axios';
import { SettingOutlined, MenuUnfoldOutlined, MenuFoldOutlined, LoadingOutlined, DownloadOutlined, BellOutlined, CommentOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change_operAuth, change_operInfo } from './redux/actions/operInfo';
import { change_userInfo } from "./redux/actions/userInfo";
import { change_brokerId, change_brokerType, change_job } from './redux/actions/brokerId';
import headPhoto from "./assets/images/headPhoto.png"
import downImg from "./assets/images/down.png"
import logoImg from "./assets/images/logo.png"
import Filter from "./common/filter";
import { Feedback } from "./common/index";
import axios from 'axios';
import SockJsClient from 'react-stomp';

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;
const { TabPane } = Tabs;
let loading_download = false, message_loading = '';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isGetToken: true,
      selectedKey: "",
      openKey: "",
      comLoading: false,
      downloadVisible: false,
      brokerList: [],
      reports: {
        tData: [],
        totalNum: 0,
        currentPage: 1,
      },
      MessageData: {
        messageNum: 0, // 消息数量
        currentPage: '1',
        totalPage: "",
        totalNum: 0,
        pageSize: 4,
        dataList: [],
      },
      loading: false,
      popoverVisible: false,

    };
  }

  componentDidUpdate (prevProps, prevState, snapshot) {

    if (prevProps.location.pathname.slice(0, 5) != this.props.location.pathname.slice(0, 5)) {
      // this.setState({
      //     selectedKey: this.props.location.pathname
      // })
      this.redirectRoute()
    }
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,

    });
  };

  redirectRoute = () => {
    const { location, history } = this.props
    let pathname = location.pathname;
    // console.log("location.pathname==",location.pathname)
    if (pathname == '/') {
      history.replace('/workTable');
      this.setState({
        selectedKey: "/workTable",
        openKey: "工作台"
      })
      return;
    }
    if (pathname && pathname.split("/").length >= 3) {
      pathname = pathname.split("/")[1].replace(/Detail/g, "")
      pathname = "/" + pathname
    }
    //根据地址判断
    let routes = routers[4].routes;
    let selectedKey = "", openKey = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].path) {
        if (routes[i].path == pathname) {
          selectedKey = pathname;
          openKey = routes[i].name;
          break;
        }
      } else {
        let childRoutes = routes[i].routes;
        for (let j = 0; j < childRoutes.length; j++) {
          if (childRoutes[j].path == pathname) {
            selectedKey = pathname;
            openKey = routes[i].name;
            break;
          }
        }
      }
    }
    this.setState({
      selectedKey,
      openKey
    })
  }
  showModalEdit = async () => {
    try {
      let res = await Axios("get", "/brokeruser/identityList", {});
      if (res.code === "200") {
        this.setState({
          brokerList: res.data
        }, () => {
          let arr = (this.state.brokerList || []).sort((a, b) => b.name - a.name)
          let brokerId = localStorage.getItem('brokerId')
          let ojb = arr.find(v => v.brokerId == brokerId) || {}
          if (!ojb.brokerId) {
            ojb = arr.find(v => v.brokerType == 4) || {}
            if (!ojb.brokerId) ojb = (arr[0] || {})
            if (arr.length) this.setData(ojb)
          }
          if (ojb.brokerId) this.setState({
            deptName: Filter.brokeBelong(ojb.brokerType) + '~' + ojb.brokerName + '~' + (ojb.province || '') + (ojb.city || '') + (ojb.district || '')
          })
        })

        localStorage.setItem("brokerList", JSON.stringify(res.data));

      }
    } catch (err) {
      // message.error(err.message);
    }
  };
  setData = (index) => {
    this.props.history.replace('/workTable');
    this.setState({
      deptName: Filter.brokeBelong(index.brokerType) + '~' + index.brokerName + '~' + (index.province || '') + (index.city || '') + (index.district || '')
    })
    window.globalData.brokerType = index.brokerType;
    window.globalData.headDeptId = index.brokerId;
    this.props.change_brokerId(index.brokerId);
    this.props.change_userInfo(index);
    this.props.change_brokerType(index.brokerType);
    if (window.winhcFn.changeDept) {
      window.winhcFn.changeDept();
    }
  }
  getUser = async () => {
    try {
      const res = await Axios("get", "/brokeruser", {});
      if (res.code === "200") {
        this.props.change_userInfo(res.data);
        this.props.change_job(res.data.job || "");
      } else {
        if (res.code == '500' && res.message == '账号不存在！') {
          localStorage.clear()
          this.props.history.replace('/login');
        }
        message.destroy()
        message.error(res.message);
      }
    } catch (err) {
      console.log(err);
    }
  };
  componentDidMount = async () => {
    if (localStorage.getItem('sessionId')) {
      const job = window.globalData.job
      if (window.globalData.job) {
      }
      this.getUser()
      this.redirectRoute()
      this.showModalEdit()
    } else {
      this.props.history.replace('/login');
    }
    window.winhcFn.redirectRoute = this.redirectRoute; //把左侧选中发送到全局；
    // window.winhcFn.getMessagecount = this.getMessagecount  // 获取数量发送到全局；

    // setTimeout(()=>{
    //     this.getMessagecount()
    // },400)
  }


  menuClick = (e) => {
    console.log(e)
    this.setState({
      selectedKey: e.key
    })
  }
  openMenu = (v) => {
    this.setState({
      openKey: v[v.length - 1]
    })
  }

  componentWillMount () {
  }

  componentWillReceiveProps (nextProps) {
  }

  handleTableChange = async (pagination) => {
    try {
      let res = await Axios('get', "/brokeruser/poi/List", {
        pageNum: pagination.current + "",
        pageSize: '10',
        loginId: JSON.parse(localStorage.getItem("legal_operInfo")).userId,
        systemName: 'brokercrm'
      });
      this.setState({
        reports: {
          tData: res.data.dataList,
          totalNum: res.data.totalNum,
          currentPage: res.data.pageNum,
        }

      })
    } catch (err) {
      console.log(err)
      message.error(err.message);
    }
  }
  getMessagecount = async () => {
    try {
      let res = await Axios('get', "/msg/count", {});
      console.log("res==", res)
      this.setState({
        MessageData: {
          ...this.state.MessageData,
          messageNum: res.data
        }
      })

      this.queryMessageList(1, "init")
    } catch (err) {
      console.log(err)
      // message.error(err.message);
    }

  }
  //消息触发
  handleClickChange = (e) => {
    this.setState({
      popoverVisible: e
    })
  }
  queryMessageList = async (currentPage, type) => {
    try {
      let msg = await Axios('get', "/msg", {
        pageNum: currentPage,
        pageSize: this.state.MessageData.pageSize
      });

      if (type == "init") {
        this.setState({
          MessageData: {
            ...this.state.MessageData,
            dataList: msg.data.dataList,
            totalNum: msg.data.totalNum,
            totalPage: msg.data.totalPage,
            currentPage: msg.data.pageNum,
          }
        })
      } else {
        this.setState({
          MessageData: {
            ...this.state.MessageData,
            dataList: [...this.state.MessageData.dataList, ...msg.data.dataList],
            totalNum: msg.data.totalNum,
            totalPage: msg.data.totalPage,
            currentPage: msg.data.pageNum,
          }
        })
      }
    } catch (err) {
      console.log(err)
      // message.error(err.message);
    }
  }
  //查看更多
  checkMoreEvent = () => {
    let currentPage = Number(this.state.MessageData.currentPage) + 1

    this.queryMessageList(currentPage, "add")
  }

  goToCustomerDetails (val) {
    this.setState({
      popoverVisible: false
    })
    // if(/\/app\/project\/customerDetail\/\d+/.test(window.location.hash)){
    window.HashHistory.replace("/block");
    setTimeout(() => {
      window.HashHistory.push('/customerDetail/' + val.custId)
    })
    // }else{
    //     window.HashHistory.push('/customerDetail/'+val.custId)
    // }
  }


  sendMessage = (msg) => {   // websocket 消息触发
    this.clientRef.sendMessage('/topics/all', msg);
  }

  logout = () => {
    localStorage.removeItem('sessionId')
    localStorage.removeItem('brokerId')
    localStorage.removeItem('brokerType')
    localStorage.removeItem('brokerList')
    localStorage.removeItem('job')
    localStorage.removeItem('legal_userInfo')
    this.props.history.replace('/login');
  }
  goSet = () => {
    window.HashHistory.push('/system')
  }
  render () {
    const { routes = [] } = this.props;
    let MessageData = this.state.MessageData;
    let showMore = Number(MessageData.currentPage) * Number(MessageData.pageSize) < Number(MessageData.totalNum)

    console.log(this.props, '代理商管理系统代理商管理系统代理商管理系统');


    // 下载的表格列表
    const columnsDownload = [{
      title: '下载记录编号',
      dataIndex: 'serialId',
      key: '1',
      render: (t, r, i) => {
        // return <span>{"XZ-"+t.substring(0, 8)}</span>
        return <span>{t}</span>
      }
    }, {
      title: '文件名',
      dataIndex: 'fileName',
      key: '2',
      render: (t, r, i) => {
        // let text = r.serialId + "." + r.fileType;
        return <span>{t ? t : "---"}</span>
      }
    }, {
      title: '开始时间',
      dataIndex: 'startTime',
      key: '7',
    }, {
      title: '结束时间',
      dataIndex: 'finishTime',
      key: '3',
    }, {
      title: '生成时间',
      dataIndex: 'timeInterval',
      key: '8',
    }, {
      title: '状态',
      dataIndex: 'fileStatus',
      key: '4',
      render: (t, r, i) => {
        let text = r.fileStatus == "S" ? "生成完毕" : r.fileStatus == "P" ? "生成中" : "生成失败";
        return <span>{text}</span>
      }
    },
    // {
    //     title: '下载次数',
    //     dataIndex: 'downCnt',
    //     key: '5',
    //     align: "center",
    //     width: 100,
    //     render: (t) => {
    //         let text = t ? t : 0;
    //         return <span>{text}</span>
    //     }
    // },
    {
      title: '操作',
      dataIndex: 'fileUrl',
      key: '6',
      width: 70,
      render: (t, r, i) => {
        return <span>
          <a target="_blank" href={t}>下载</a>
        </span>
      }
    }];
    const messageHtml = <div style={{ width: '350px' }} className="messageBox">
      <Tabs defaultActiveKey="1" onChange={() => {
      }}>
        <TabPane tab={<span>消息提醒{MessageData.messageNum}</span>} key="1">
          <div style={{ maxHeight: "450px", overflowY: "auto" }}>
            {MessageData.dataList.map((val, key) => {
              return <div className={'reminders'} onClick={() => {
                this.goToCustomerDetails(val)
              }} key={key}>
                {/* <div className="close"><Icon type="close" /></div>  */}
                <div className="title">
                  {Filter.dateForDate(new Date()) > Filter.date(val.nextTrackDate) ? "过期需联系客户" : "今天需联系客户"}
                </div>
                <div
                  className='desc'>{"你的客户" + val.custName + "需要你进行跟进" + Filter.date(val.nextTrackDate)}</div>
              </div>
            })}
            {MessageData.messageNum ? showMore ?
              <div className="checkMore" onClick={this.checkMoreEvent}>查看更多<Spin
                spinning={this.state.loading}
                indicator={<LoadingOutlined style={{ fontSize: 16, marginLeft: 15 }} />} /></div> :
              <div className="checkMore">没有更多了</div> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          </div>

          <style>{`
                    .messageBox  .ant-tabs-tab-active {
                        margin-left: 120px;   
                    }
                  `}</style>


        </TabPane>
      </Tabs>
    </div>

    const operInfo = this.props.operInfo;
    const userInfo = this.props.userInfo;
    let dataConstraint = '全部', dataConstraint_dept = '';
    if (operInfo.dataConstraint) {
      dataConstraint = '全部'
      if (operInfo.dataConstraint.ROLE_CustomInfoQuery) {
        if (JSON.parse(operInfo.dataConstraint.ROLE_CustomInfoQuery)[0].type == 'dept') {
          dataConstraint = '部门';
          dataConstraint_dept = operInfo.deptName == '诉讼投资部' ? '上海公司' : operInfo.deptName
        } else if (JSON.parse(operInfo.dataConstraint.ROLE_CustomInfoQuery)[0].type == 'self') {
          dataConstraint = '个人'
        }
      }
    }
    const brokerType = window.globalData.brokerType
    const job = window.globalData.job
    console.log(window.globalData, 'window.globalData');
    return (
      <Layout style={{ width: '100%', height: '100%' }}>
        <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
          <div className="logo">
            <img src={logoImg} style={{ position: 'relative', top: '-2px' }} />
            <span style={this.state.collapsed ? { display: 'none' } : { paddingLeft: '10px' }}>赢火虫云法务</span>
          </div>
          <Menu theme="dark"
            style={{
              height: '80%',
              overflowX: 'auto'
            }}
            mode="inline"
            selectedKeys={[this.state.selectedKey]}
            openKeys={[this.state.openKey]}
            onClick={this.menuClick}
            onOpenChange={this.openMenu}
            className="globalMenu">
            {routes.map((item, key) => {
              if (item.path && item.name) {
                if (item.roles === 'all' || item.roles.includes(brokerType) || item.roles.includes(job)) {
                  return <Menu.Item key={item.path}>
                    <Link to={item.path}>{item.icon}
                      <span>{item.name}</span>
                    </Link>
                  </Menu.Item>
                }

              } else if (item.routes && item.routes.length > 0) {
                let num = 0;
                let _list = item.routes.map((sub, index) => {
                  if (!sub.onlyRouter && sub.path && sub.name) {
                    if (sub.roles === 'all' || sub.roles.includes(brokerType) || sub.roles.includes(job)) {
                      num++
                      return <Menu.Item key={sub.path}><Link
                        to={sub.path}>{sub.name}</Link></Menu.Item>
                    }
                  }
                })
                if (num) {
                  return <SubMenu key={item.name}
                    title={<span
                      style={{ color: '#fff' }}>{item.icon}<span>{item.name}</span></span>}>
                    {_list}
                  </SubMenu>
                }
              }
            })}
          </Menu>
          <div className='bottomMenu' style={!this.state.collapsed ? { width: 200, background: 'rgb(0, 21, 41)' } : { width: 80, background: 'rgb(0, 21, 41)' }} onClick={() => this.goSet()}>
            {!this.state.collapsed ? (
              <>
                <SettingOutlined style={{ marginRight: 10 }} />
                设置
              </>
            ) : (
              <Tooltip placement='right' title='设置'>
                <SettingOutlined style={{ marginRight: 10 }} />

              </Tooltip>
            )}
          </div>
        </Sider>
        <Layout style={{ zIndex: 2 }}>
          <Header style={{ background: '#fff', padding: '0 30px 0 15px' }}>
            {this.state.collapsed ? <MenuUnfoldOutlined className="trigger"
              onClick={this.toggle}
              style={{ verticalAlign: 'middle' }} /> : <MenuFoldOutlined className="trigger"
                onClick={this.toggle}
                style={{ verticalAlign: 'middle' }} />}
            {operInfo.userRole !== '2' && this.state.brokerList.length !== 0 ? <Dropdown overlay={<Menu>
              {
                this.state.brokerList.map((index, value) => <Menu.Item value={index.brokerId}
                  style={{ color: '#000' }}
                  onClick={() => this.setData(index)}>
                  {Filter.brokeBelong(index.brokerType)}
                  ~
                  {index.brokerName} ~ {index.province || ''} {index.city || ''} {index.district || ''}
                </Menu.Item>
                )
              }


            </Menu>}>
              <div style={dataConstraint == '全部' ? {
                marginLeft: 30,
                verticalAlign: 'middle',
                display: 'inline-block',
                cursor: 'pointer',
                lineHeight: '30px',
                color: '#000'
              }
                : { display: 'none' }}>
                <img src={downImg} style={{ verticalAlign: 'middle', width: 30 }} />&nbsp;
                <div style={{
                  verticalAlign: 'middle',
                  display: 'inline-block'
                }}>{this.state.deptName}</div>
              </div>
            </Dropdown> : ''}


            <div style={dataConstraint == '部门' ? {
              marginLeft: 30,
              verticalAlign: 'middle',
              display: 'inline-block',
              lineHeight: '30px',
              color: '#000'
            }
              : { display: 'none' }}>
              {dataConstraint_dept}
            </div>
            <div className="navRight">
              <Feedback />
              <Tooltip title="IM群聊">
                <CommentOutlined style={{ 'fontSize': '22px', cursor: 'pointer', marginRight: '20px' }}
                  onClick={
                    () => {
                      window.open(`${window.imUrl}login?token=${localStorage.getItem('sessionId')}&from=broker`)
                    }
                  } />
              </Tooltip>
              <Tooltip title="下载中心">
                <DownloadOutlined style={{ 'fontSize': '22px', cursor: 'pointer', marginRight: '20px' }}
                  onClick={async () => {
                    try {
                      if (loading_download) {
                        return;
                      }
                      loading_download = true;
                      if (!message_loading) {
                        message_loading = message.loading('加载中', 0);
                      }
                      let res = await Axios('get', '/brokeruser/poi/List', {
                        pageNum: '1',
                        pageSize: '10',
                        loginId: JSON.parse(localStorage.getItem("legal_operInfo")).userId,
                        systemName: 'brokercrm'
                      });
                      console.log("res=报表", res)
                      loading_download = false;
                      if (message_loading) {
                        message_loading();
                        message_loading = ''
                      }
                      if (res.code == '200') {
                        this.setState({
                          downloadVisible: true,
                          reports: {
                            tData: res.data.dataList,
                            totalNum: res.data.totalNum,
                            currentPage: res.data.pageNum,
                          }
                        })
                      }
                    } catch (err) {
                      loading_download = false;
                      if (message_loading) {
                        message_loading();
                        message_loading = ''
                      }
                      message.error(err.message);
                    }
                  }} />
              </Tooltip>
              <Popover content={messageHtml}
                placement="bottomRight"
                trigger="click"
                visible={this.state.popoverVisible}
                onVisibleChange={this.handleClickChange}
              >
                <Tooltip title="消息提醒">
                  <Badge count={this.state.MessageData.messageNum}>
                    <BellOutlined style={{ 'fontSize': '22px', cursor: 'pointer' }}
                      onClick={() => {
                      }} />
                  </Badge>
                </Tooltip>
              </Popover>
              <Dropdown overlay={<Menu>
                <Menu.Item onClick={this.logout}>
                  退出登录
                </Menu.Item>
              </Menu>}>
                <div style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>
                  <Avatar size={40} style={{ fontSize: '16px' }} src={operInfo.avatar || headPhoto}>
                    {userInfo.userName ? userInfo.userName.substr(0, 1) : ''}
                  </Avatar>&nbsp;&nbsp;
                  <span style={{ lineHeight: '21px' }}>{userInfo.userName || userInfo.brokerName || ''} <br /> <span style={{ color: '#808080' }}>{
                    Filter.JobType(userInfo.brokerType || userInfo.job, 1)}</span> </span>
                </div>
              </Dropdown>
              <Modal
                title="下载报表"
                footer={null}
                open={this.state.downloadVisible}
                onOk={() => {
                  this.setState({ downloadVisible: false })
                }}
                onCancel={() => {
                  this.setState({ downloadVisible: false })
                }}
                width={1200}
                bodyStyle={{ maxHeight: document.body.clientHeight - 260 + 'px', overflow: 'auto' }}
                confirmLoading={this.state.confirmLoading}>
                <Table
                  dataSource={this.state.reports.tData}
                  columns={columnsDownload}
                  size="large"
                  pagination={{
                    showQuickJumper: true,
                    pageSize: 10,
                    total: parseInt(this.state.reports.totalNum),
                    current: Number(this.state.reports.currentPage),
                    showTotal: (total) => {
                      return `共 ${total} 条记录`;
                    }
                  }}
                  onChange={this.handleTableChange}
                />
              </Modal>
            </div>
          </Header>
          <Content
            style={{
              minHeight: 280,
              position: 'relative'
            }}>
            <div style={this.state.comLoading ? {
              position: 'absolute',
              zIndex: '999',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              paddingTop: '280px',
              textAlign: 'center'
            } : { display: 'none' }}>
              <Spin size="large" />
            </div>
            <Switch>
              {routes.map((item, key) => {
                if (item.path) {
                  if (item.roles === 'all' || item.roles.includes(window.globalData.brokerType) || item.roles.includes(window.globalData.job)) {
                    return <Route path={item.path} exact={item.exact} component={item.component}
                      key={item.path + key}></Route>
                  }
                } else if (item.routes && item.routes.length > 0) {
                  return item.routes.map((sub, index) => {
                    if (sub.path) {
                      if (sub.roles === 'all' || sub.roles.includes(window.globalData.brokerType) || sub.roles.includes(window.globalData.job)) {
                        return <Route path={sub.path} exact={sub.exact}
                          component={sub.component} key={sub.path + key}></Route>
                      }
                    }
                  })
                }
              })}
            </Switch>
          </Content>
        </Layout>
      </Layout>
    )
  }
}

export default connect((state) => {
  return state
},
  (dispatch) => ({
    change_operInfo: bindActionCreators(change_operInfo, dispatch),
    change_brokerId: bindActionCreators(change_brokerId, dispatch),
    change_operAuth: bindActionCreators(change_operAuth, dispatch),
    change_userInfo: bindActionCreators(change_userInfo, dispatch),
    change_brokerType: bindActionCreators(change_brokerType, dispatch),
    change_job: bindActionCreators(change_job, dispatch),

  })
)(App)

