import React, { useState, useEffect } from 'react';
import { Button, Table, Row, Col, Breadcrumb, Form, Input, Radio, DatePicker, Select, message } from 'antd';
import { Axios } from "../../axios";
import { Link } from "react-router-dom";
import moment from 'moment';
import filter from "../../common/filter";
import CareaSelect from "../../common/CareaSelect";
import loge1 from "../../assets/images/loge1.png"
import { cityByCodeList } from "../../common/cityByCodeList";
import G2 from "@antv/g2";
const { RangePicker } = DatePicker;
const { Option } = Select;
let directoryList = [
    { id: "#overview", name: "概览" },
    { id: "#sumup", name: "总结分析" },
    { id: "#trailing ", name: "后续建议" },
    { id: "#returned", name: "回款明细" },
    { id: "#followup", name: "跟进记录" },
]
let columns = [
    {
        title: "ID",
        dataIndex: "id",
    },
    {
        title: "联系人",
        dataIndex: "companyName",
    },
    {
        title: "联系电话",
        dataIndex: "followUserName",
    },
    {
        title: "申诉人",
        dataIndex: "brokerUserName",
    },
    {
        title: "操作",
        dataIndex: "operate",
        fixed: "right",
        render: (text, record) => (
            <Link to={"/RenewCompanyManageDetail/" + record.id}>
                查看
            </Link>
        ),
    },
];

const QuarterlyReport = () => {
    const [loading, setLoading] = useState(false);
    const [corpInfo, setCorpInfo] = useState({});
    const refreshTable = async (pageNum, pageSize) => {
        try {
            setLoading(true)
            let res = await Axios("get", "/brokeruser/payCompany/list", {});
            if (res.code == '200') {
                setCorpInfo(res.data.dataList)
            } else { message.error(res.message) }
            setLoading(false)
        } catch (err) {
            // message.error(err.message)
            setLoading(false)
        }
    };
    useEffect(() => {
        // refreshTable()
        initChart1()
    }, []);
    const exportOrders = (slt) => {
        window.open(corpInfo.reportUrl)
    };
    const initChart1 = (list) => {
        document.querySelector("#chart1").innerHTML = "";
        const data = [
            { item: '事例一', count: 40, percent: 0.4 },
            { item: '事例二', count: 21, percent: 0.21 },
            { item: '事例三', count: 17, percent: 0.17 },
            { item: '事例四', count: 13, percent: 0.13 },
            { item: '事例五', count: 9, percent: 0.09 }
        ];
        const chart = new G2.Chart({
            container: 'chart1',
            forceFit: true,
            height: 500,
            animate: false
        });
        chart.source(data, {
            percent: {
                formatter: val => {
                    val = (val * 100) + '%';
                    return val;
                }
            }
        });
        chart.coord('theta', {
            radius: 0.8,
            innerRadius: 0.7
        });
        chart.legend({
            position: 'right-center',
            offsetX: -100
        });
        chart.tooltip({
            showTitle: false,
            itemTpl: '<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
        });
        // 辅助文本
        chart.guide().html({
            position: ['50%', '50%'],
            html: '<div style="color:#8c8c8c;font-size: 24px;text-align: center;width: 10em;">主机<br><span style="color:#8c8c8c;font-size:20px">200</span>台</div>',
            alignX: 'middle',
            alignY: 'middle'
        });
        const interval = chart.intervalStack()
            .position('percent')
            .color('item')
            .label('percent', {
                formatter: (val, item) => {
                    return item.point.item + ': ' + val;
                }
            })
            .tooltip('item*percent', (item, percent) => {
                percent = percent * 100 + '%';
                return {
                    name: item,
                    value: percent
                };
            })
            .style({
                lineWidth: 1,
                stroke: '#fff'
            });
        chart.render();
    };
    return (
        <div className="pageContainer">
            <Row className="headNav">
                <Col span={24}>
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item>季度服务报告</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col span={24} className="title">
                    <span className="name">季度服务报告详情</span>
                </Col>
            </Row>
            <div className="scrollContainer">
                <div id="FinalReport" style={{ padding: "0 10px", width: "100%", height: "100%", overflow: "auto" }}>
                    <div style={{ width: '80%' }}>
                        <div className="nav">
                            <img src={loge1} /> <p>季度服务报告</p>
                            <Button type="primary" onClick={exportOrders}>下载</Button>
                        </div>
                        <div className="centre">
                            <span className="xian" ></span>
                            <span className="xian1" ></span>
                            <div className="title" id="overview">概览</div>
                            <Row type="flex">
                                <Col span={3} order={1}>企业名称</Col>
                                <Col span={9} order={2}>{corpInfo.creditorCompanyName || "-"}</Col>
                                <Col span={3} order={3}>联系人</Col>
                                <Col span={9} order={4}>{corpInfo.creditorCompanyName || "-"}</Col>
                            </Row>
                            <Row type="flex">
                                <Col span={3} order={1}>联系电话</Col>
                                <Col span={9} order={2}>{corpInfo.payerName || "-"}</Col>
                                <Col span={3} order={3}>所在地区</Col>
                                <Col span={9} order={4}>{corpInfo.payerName || "-"}</Col>
                            </Row>
                            <Row type="flex" style={{ borderBottom: '1px solid #e6f0ff' }}>
                                <Col span={3} order={1}>所属行业</Col>
                                <Col span={9} order={2}>{corpInfo.creditorName || "-"}</Col>
                                <Col span={3} order={3}>代理商</Col>
                                <Col span={9} order={4}>{corpInfo.confirmTime || "-"}</Col>
                            </Row>
                            <div className="title" id="overview">服务周期</div>
                            <Row type="flex" style={{ borderBottom: '1px solid #e6f0ff' }}>
                                <Col span={3} order={1}>服务周期</Col>
                                <Col span={21} order={2}>{corpInfo.creditorCompanyName || "-"}</Col>
                            </Row>
                            <div className="tableDiv">
                                <div className="title" id="returned">服务律师法务团成员</div>
                                <Table
                                    style={{ width: '50%' }}
                                    dataSource={[]}
                                    rowKey={record => record.id + '' + record.timeCreated}
                                    columns={[{
                                        title: '律师法务团成员',
                                        dataIndex: 'id',
                                        render: (text, record, index) => index + 1
                                    }, {
                                        title: '职位',
                                        dataIndex: 'returnAmt',
                                    }]}
                                    size="middle"
                                    pagination={false}
                                    bordered={true}
                                />
                            </div>
                            <div className="tableDiv">
                                <div className="title" id="returned">服务明细</div>
                                <div id="chart1"></div>
                                <Table
                                    dataSource={[]}
                                    rowKey={record => record.id + '' + record.timeCreated}
                                    columns={[{
                                        title: '序号',
                                        dataIndex: 'id',
                                        render: (text, record, index) => index + 1
                                    }, {
                                        title: '回款金额（元）',
                                        dataIndex: 'returnAmt',
                                    }, {
                                        title: '添加人',
                                        dataIndex: 'insertMan',
                                        render: (t, r) => <span>{t || '-'}</span>
                                    }, {
                                        title: '添加渠道',
                                        dataIndex: 'source',
                                        render: (text, record) => (record.packageName && text) ? filter.hkSource(record.packageName + '_' + text) :
                                            text ? filter.FsCaseSource(text) : filter.UserFeedBackAppPackage(record.packageName)
                                    },
                                    {
                                        title: '回款时间',
                                        dataIndex: 'returnDate',
                                        render: (t) => (t || '').slice(0, 10)
                                    },
                                    {
                                        title: '添加时间',
                                        dataIndex: 'timeCreated',
                                        render: (t) => t || '-'

                                    }]}
                                    size="middle"
                                    pagination={false}
                                    bordered={true}
                                />
                            </div>
                            <div className="text">
                                <div className="title" id="sumup">服务总结</div>
                                <div className="texts" >  {corpInfo.summarize || '-'}  </div>
                            </div>
                            <div className="text">
                                <div className="title" id="sumup">服务总结 </div>
                                <div className="texts" >
                                    服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结
                                    服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结
                                    服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结
                                    服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结
                                    服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结
                                    服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结
                                    服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结
                                    服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结
                                    服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结
                                    服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结
                                    服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结
                                    服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结
                                    服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结
                                    服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结服务总结
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="catalogue">
                        <div className="biaot">目录</div>
                        <div className="cata">
                            {/* {directoryList.map((e) => {
                                return (
                                    <div
                                        className={e.name === this.state.scrollName ? "bg" : ''}
                                        key={e.name}
                                        onClick={() => {
                                            this.setState({
                                                scrollName: e.name,
                                            });
                                            window._mis_eleSorollToTarget(
                                                "#FinalReport",
                                                document.querySelector(e.id)
                                                    .offsetTop,
                                                300
                                            );
                                        }}
                                    >
                                        {e.name}
                                    </div>
                                );
                            })} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default QuarterlyReport;